// Date fromat from  Fri Dec 20 2024 13:36:00 GMT+0530  to 12/20/24 


export function getFormatedDate(strDate, fullYear = false) {
    const date = new Date(strDate); // Convert the input string to a Date object
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month and pad with 0 if needed
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with 0 if needed
    const year = fullYear ? date.getFullYear() : date.getFullYear().toString().slice(-2); // Get the last two digits of the year

    return `${month}/${day}/${year}`;
}

// // Date fromat from 12/20/24 to Fri ,Dec 20 
export function formatToShortDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
        throw new Error("Invalid date string");
    }
    const currentYear = new Date().getFullYear();

    return date.toLocaleDateString("en-US", {
        weekday: "short", // Abbreviation for the day of the week
        month: "short",   // Abbreviation for the month
        day: "numeric",   // Numeric day of the month
        ...(date.getFullYear() !== currentYear && { year: "numeric" })
    });
}

// 12/20/24 to next and prev date (12/19/24  and 12/21/24)
export function getPrevAndNextDatesWithFormats(dateString) {
    const inputDate = new Date(dateString);
    if (isNaN(inputDate)) {
        throw new Error("Invalid date string");
    }

    const currentYear = new Date().getFullYear();

    const formatShortDate = (date) =>
        date.toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
            ...(date.getFullYear() !== currentYear && { year: "numeric" }),
        });

    const formatNumericDate = (date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear().toString().slice(-2);
        return `${month}/${day}/${year}`;
    };

    const prevDate = new Date(inputDate);
    prevDate.setDate(inputDate.getDate() - 1);

    const nextDate = new Date(inputDate);
    nextDate.setDate(inputDate.getDate() + 1);

    return {
        prev: {
            numeric: formatNumericDate(prevDate), // 12/19/24
            short: formatShortDate(prevDate), //Fri, Dec 20
        },
        current: {
            numeric: formatNumericDate(inputDate), //12/19/24
            short: formatShortDate(inputDate), //Fri, Dec 20
        },
        next: {
            numeric: formatNumericDate(nextDate), //12/19/24
            short: formatShortDate(nextDate), //Fri, Dec 20
        },
    };
}