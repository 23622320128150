import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  CardBody,
  Alert,
  Badge,
} from "reactstrap";
import { Snackbar, useMediaQuery } from "@material-ui/core";
import "./Flights.css";
import airportData from "./../data/airport";
import { FaChevronRight, FaPencilAlt, FaTrash } from "react-icons/fa";
import LoginAndSignUp from "../components/LoginAndSignUp";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useFlightData } from "../data/FlightData";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import NinjaOverlay from "../assets/images/icons/cameraOverlay.png";
import PlansPopup from "../pages/PlansPopup";
import { notification } from "antd";
import DuffelBooking from "./DuffelBooking";
import BannerBottom from "../assets/images/backgroundYelloPlane2.png";
function FlightList({ flightData, page, setFlightId }) {
  const [user] = useLocalStorage("auth", {});
  const { joinFlightApi, GetMyFlightPrice, FlightClickTrack } = useFlightData();
  const [isFlightJoined, SetIsFlightJoined] = useState(false);
  const [confirmDelete, SetConfirmDelete] = useState(false);
  const [deleteFlightid, SetDeleteFlightId] = useState(0);
  const [isFlightGetPrice, SetIsFlightGetPrice] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const isDesktop = useMediaQuery('(min-width:768px)');
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const history = useHistory();
  function DeleteFlight(flightid) {
    SetDeleteFlightId(flightid);
    SetConfirmDelete(true);
  }

  function getCodeFromCity(city) {
    return airportData.find(
      (item) =>
        item.city.toString().toUpperCase() === city.toString().toUpperCase()
    ).IATA;
  }

  async function JoinFlight(flight) {
    const joinFlightData = {
      name: user.name,
      mobile: user.mobile,
      userid: user.userid,
      email: user.email,
      flightid: flight.id,
      from_city: flight.from_city,
      to_city: flight.to_city,
      from_airport: flight.from_airport,
      to_airport: flight.to_airport,
      price: flight.total_price,
      plane_type: flight.plane_type,
      op_id: flight.op_id,
      passenger: flight.available_seats,
      dep_date: flight.dep_date,
      dep_time: flight.dep_time,
      is_flyeasy: flight.flyeasy_id ? true : false,
      is_duffel: flight.is_duffel,
      flyeasy_id: flight.flyeasy_id || null,
      flight_source: flight.flight_source,
      is_empty_leg: flight.is_empty_leg,
    };
    if (user.subscription_plan_id != 1 || (flight.userid == 55) || (flight.userid == 1000) || (flight.userid == 929 && !flight.is_charter)) {
      const response = await joinFlightApi(joinFlightData, user);
      if (response.status) {
        SetIsFlightJoined(true);
        setTimeout(function () {
          SetIsFlightJoined(false);
        }, 4500);
      }


      localStorage.setItem("ModalData", JSON.stringify(flight))
      const iUserID = flight.userid
      if (flight.userid != 929 && flight.userid != 55 && flight.userid != 1000 && flight.userid != 1287) openInNewTab(`/chat/${iUserID}`);
    } else {
      if (flight.userid !== 459 &&
        flight.userid !== 468 &&
        flight.userid !== 659 &&
        flight.userid !== 853 &&
        flight.userid !== 473 &&
        flight.userid !== 929 &&
        flight.userid !== 1287 &&
        flight.userid !== 1000) {
        handleShowPopup();
      }
    }
  }

  function YesConfirm(isconfirm) {
    if (isconfirm) {
      setFlightId(deleteFlightid);
    }
    SetDeleteFlightId(0);
    SetConfirmDelete(false);
  }

  async function FlightClickTrackApi(flight) {
    const flight_data = {
      userid: user.userid,
      flightid: flight.id,
    };
    const response = await FlightClickTrack(flight_data);
  }

  async function GetFlightPrice(flight) {
    const flightData = {
      name: user.name,
      email: user.email,
      mobile: flight.mobile,
      dep_date: flight.dep_date,
      from_city: flight.from_city,
      from_airport: flight.from_airport,
      to_city: flight.to_city,
      to_airport: flight.to_airport,
      is_flexible: flight.isflexible,
      passenger: flight.passenger,
      is_pet: flight.is_pet,
      pet_type: flight.pet_type,
    };
    const response = await GetMyFlightPrice(flightData);
    if (response.status) {
      SetIsFlightGetPrice(true);
    }
  }
  if (isFlightJoined) {
    notification.success({
      message: 'Member Notified',
      description: `The Member has been notified about this Flight. FlyKatana will be in touch in the next hour (during business hours) regarding next steps.`,
      placement: 'topRight',
    });

  }

  const [isDuffelModalOpen, setIsDuffelModalOpen] = useState(false);
  const [selectedDuffelFlight, setSelectedDuffelFlight] = useState(null);
  const [passengers, setPassengers] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
    }
  ]);
  const [bookingError, setBookingError] = useState("");

  const toggleDuffelModal = () => {
    setIsDuffelModalOpen(!isDuffelModalOpen);
    setBookingError("");
    setPassengers([{ firstName: "", lastName: "", email: "", phone: "", dateOfBirth: "" }]);
  };

  const handleDuffelBookNow = (flight) => {
    setSelectedDuffelFlight(flight);
    toggleDuffelModal();
  };

  return (
    <>
      <PlansPopup show={showPopup} handleClose={handleClosePopup} />
      {confirmDelete && (
        <SweetAlert
          custom
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="btnYellow"
          cancelBtnBsStyle="btnYellow"
          onConfirm={(e) => YesConfirm(true)}
          onCancel={(e) => YesConfirm(false)}
          focusCancelBtn>
          Are you sure, You want to delete flight?
        </SweetAlert>
      )}
      {isFlightGetPrice && (
        <Snackbar
          open={isFlightGetPrice}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetIsFlightGetPrice(!isFlightGetPrice)}>
          <Alert className="alertYellowInverse">
            Katana will be in touch shortly
          </Alert>
        </Snackbar>
      )}
      {page == "MyFlight" && flightData.length < 1 && (
        <Alert className="alertYellow">
          You haven't added any flights, please head to{" "}
          <Link to="/" style={{ color: "black", textDecoration: "underline" }}>
            Home
          </Link>{" "}
          to add a flight.
        </Alert>
      )}
      {/* {isFlightJoined && (
        <Alert className="alertYellow">
          The Member has been notified about this Flight. FlyKatana will be in
          touch in the next hour (during business hours) regarding next steps.
          Katana will be in touch shortly
        </Alert>
      )} */}
      <Card style={{ width: isDesktop && page === "MyFlight" ? "90%" : "", margin: isDesktop && page === "MyFlight" ? "auto" : "" }}>
        {/* {page !== "MyFlight" &&
          flightData.filter((x) => x.is_posted === true).length > 0 && (
            <Row className="">
              <Col lg="6" md="6" sm="12" xs="12" className=" text-center">
                <Card style={{ border: "none" }}>
                  <CardBody className="listHeader p-0 mx-2">
                    <h2 className="p-1">Seats Available</h2>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )} */}
        {/* {page !== "MyFlight" &&
          flightData && (
            <Box sx={{ bgcolor: 'background.paper' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="All" />
              <Tab label="Seats Available" />
              <Tab label="Charters" />
              <Tab label="Flight Interests" />              
            </Tabs>
          </Box>
          )} */}
        {page === "MyFlight" &&
          flightData.filter((x) => x.is_posted === true).length > 0 && (
            <Row className="">
              <Col lg="6" md="6" sm="12" xs="12" className=" text-center">
                <Card style={{ border: "none" }}>
                  <CardBody className="listHeader p-0 mx-2">
                    <h2 className="p-1">Seats for Sale</h2>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        <Row>
          {flightData.length > 0 &&
            flightData
              .filter((x) => x.is_posted === true)
              .map((flight, index) => {
                return (
                  <Col lg="6" md="6" sm="12" xs="12" key={index}>
                    <Card className="mt-3 mr-2 ml-2 flightPosted">
                      <CardBody className="py-0">
                        <Row key={flight}>
                          <Col lg="12" md="12" sm="12" xs="12">
                            <Row>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="pl-0 py-2 text-left">
                                {typeof user.userid !== "undefined" &&
                                  flight.isfriend &&
                                  flight.profile_pic_path && (
                                    <img
                                      alt="Image Here"
                                      style={{ height: "auto", width: "60px", }}
                                      src={BannerBottom}
                                    />
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  flight.isfriend &&
                                  !flight.profile_pic_path && (
                                    <div
                                      className="bg-white pr-0 text-center"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        border: "3px solid black",
                                        borderRadius: "50%",
                                      }}>
                                      <h2 className="textInitial">
                                        {flight?.name?.split(" ").length > 1
                                          ? flight?.name?.split(" ")[0]?.substring(0, 1) +
                                          flight?.name?.split(" ")[1]?.substring(0, 1)
                                          : flight?.name?.substring(0, 2)}
                                      </h2>
                                    </div>
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  !flight.isgroup &&
                                  !flight.profile_pic_path &&
                                  !flight.group_profile_pic_path && (
                                    <img
                                      style={{ width: "50px", height: "50px" }}
                                      src={
                                        require("../assets/images/icons/ninja1.png")
                                          .default
                                      }
                                    />
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  flight.isgroup &&
                                  flight.group_profile_pic_path && (
                                    <>
                                      <img
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                        src={flight.group_profile_pic_path}
                                      />
                                    </>
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  flight.isgroup &&
                                  !flight.group_profile_pic_path && (
                                    <div
                                      className="bg-white pr-0 text-center"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        border: "3px solid black",
                                        borderRadius: "50%",
                                      }}>
                                      <h2 className="textInitial">
                                        {flight?.group_name?.split(" ").length > 1
                                          ? flight?.group_name?.split(" ")[0]?.substring(0, 1) +
                                          flight?.group_name?.split(" ")[1]?.substring(0, 1)
                                          : flight?.group_name?.substring(0, 2)}
                                      </h2>
                                    </div>
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  !flight.isgroup &&
                                  (flight.userid == 459 ||
                                    flight.userid == 468 ||
                                    flight.userid == 473 ||
                                    flight.userid == 659 ||
                                    flight.userid == 853 ||
                                    flight.userid == 929 ||
                                    flight.userid == 1287 ||
                                    flight.userid == 1000 ||
                                    flight.subscription_plan_id == 3) &&
                                  flight.profile_pic_path && (
                                    <>
                                      <img
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          borderRadius: "50%",
                                        }}
                                        src={flight.profile_pic_path}
                                      />
                                    </>
                                  )}

                                {/* {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  !flight.isgroup &&
                                  flight.userid == 468 &&
                                  flight.profile_pic_path && (
                                    <>
                                      <img
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          borderRadius: "50%",
                                        }}
                                        src={flight.profile_pic_path}
                                      />                                      
                                    </>
                                  )} */}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  !flight.isgroup &&
                                  flight.userid != 459 &&
                                  flight.userid != 468 &&
                                  flight.userid != 853 &&
                                  flight.userid != 473 &&
                                  flight.userid != 659 &&
                                  flight.userid != 929 &&
                                  flight.userid != 1287 &&
                                  flight.userid != 1000 &&
                                  flight.subscription_plan_id != 3 &&
                                  flight.profile_pic_path && (
                                    <>
                                      <img
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          borderRadius: "50%",
                                        }}
                                        src={flight.profile_pic_path}
                                      />
                                      <img
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          position: "absolute",
                                          left: "-2px",
                                          top: "7px",
                                        }}
                                        src={NinjaOverlay}
                                      />
                                    </>
                                  )}

                                {typeof user.userid === "undefined" && (
                                  <div className="text-center" style={{ margin: "auto", paddingRight: "6px" }}>
                                    <img src={BannerBottom} style={{ height: "auto", width: "65px", }} />
                                  </div>
                                )}

                                {flight.airlines_name && (
                                  <div >
                                    <h6 className="m-0">
                                      {flight.airlines_name}

                                    </h6>
                                  </div>)}
                              </Col>

                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="my-1 p-0">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Departs</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>{flight.from_airport}</h4>
                                <h6 className="m-0 textCity" style={{ color: "#3B5E75" }}>
                                  {flight?.from_city?.substring(0, 13)}
                                </h6>
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>{flight.dep_date}</h6>
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>{flight.dep_time}</h6>
                              </Col>
                              <Col
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                className="mt-2 p-0 text-center">
                                <h6 className="my-0">
                                  <FaChevronRight
                                    style={{
                                      height: "40px",
                                      fontWeight: "bold",
                                    }}
                                    color="rgb(255, 230, 0)"
                                  />
                                  <FaChevronRight
                                    style={{
                                      height: "40px",
                                      fontWeight: "bold",
                                    }}
                                    color="rgb(255, 230, 0)"
                                  />
                                  <FaChevronRight
                                    style={{
                                      height: "40px",
                                      fontWeight: "bold",
                                    }}
                                    color="rgb(255, 230, 0)"
                                  />
                                </h6>
                              </Col>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="my-1 p-0 text-right">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Arrives</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>{flight.to_airport}</h4>
                                <h6 className="m-0 textCity" style={{ color: "#3B5E75" }}>
                                  {flight?.to_city?.substring(0, 13)}
                                </h6>
                              </Col>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="my-1 pr-0 text-center">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Seats</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>
                                  {flight.available_seats || 'N/A'}
                                </h4>
                              </Col>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="my-1 pr-0">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Price</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>
                                  {flight.total_price && flight.total_price != 0
                                    ? "$" + flight.total_price
                                    : "Upon Request"}
                                </h4>
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>
                                  {flight.is_charter ? "Charter" : "Per seat"}
                                </h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="p-1 text-left">
                                {/* <img
                                  style={{ width: "25px", height: "auto" }}
                                  src={
                                    require("../assets/images/PlaneNotPosted.png")
                                      .default
                                  }
                                  className="my-2"
                                /> */}
                                <p className="my-2" style={{ height: "20px", color: "#3B5E75" }}>
                                  {/* MODEL: */}
                                </p>
                              </Col>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                style={{ marginTop: '19px' }}
                                className="p-0">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>{flight.plane_type}</h6>
                              </Col>
                              <Col
                                lg="4"
                                md="4"
                                sm="4"
                                xs="4"
                                className="mt-1 pr-0"></Col>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="mt-1 p-0">
                                {typeof user.userid !== "undefined" &&

                                  page !== "MyFlight" &&
                                  (flight.userid == 459 ||
                                    flight.userid == 468 ||
                                    flight.userid == 659 ||
                                    flight.userid == 853 ||
                                    flight.userid == 473 ||
                                    flight.userid == 929 ||
                                    flight.userid == 1287 ||
                                    flight.userid == 1000) && (
                                    <Button
                                      className={
                                        "btn-sm btn-block " +
                                        // (flight.inquiries > 0
                                        //   ? flight.inquiries <= 2
                                        //     ? "btn-yellow"
                                        //     : flight.inquiries > 2 &&
                                        //       flight.inquiries <= 3
                                        //       ? "btn-orange"
                                        //       : "btn-red" :
                                        "btnDarkBlueFlightLIst"
                                      }
                                      onClick={() => {
                                        if (flight.flyxo_bookingurl) openInNewTab(flight.flyxo_bookingurl);
                                        if (flight.userid == 1000 && (flight?.duffle_limit >= flight?.total_price?.toFixed(2))) return handleDuffelBookNow(flight);
                                        else {
                                          FlightClickTrackApi(flight);
                                          JoinFlight(flight)
                                        }
                                        if (flight.userid == 929) JoinFlight(flight)
                                      }}
                                      style={{ padding: "2px" }}>
                                      Book Now&nbsp;
                                      {/* {flight.inquiries > 0 && (
                                        <span
                                          color={
                                            flight.inquiries <= 2
                                              ? "yellow"
                                              : flight.inquiries > 2 &&
                                                flight.inquiries <= 3
                                                ? "orange"
                                                : "red"
                                          }>
                                          {flight.inquiries}
                                        </span>
                                      )} */}
                                    </Button>
                                  )}
                                {typeof user.userid !== "undefined" &&
                                  page !== "MyFlight" &&
                                  flight.userid != 459 &&
                                  flight.userid != 468 &&
                                  flight.userid != 659 &&
                                  flight.userid != 853 &&
                                  flight.userid != 473 &&
                                  flight.userid != 929 &&
                                  flight.userid != 1287 &&
                                  flight.userid != 1000 && (

                                    <Button
                                      className={
                                        "btn-sm btn-block " +
                                        (flight.inquiries > 0
                                          ? flight.inquiries <= 2
                                            ? "btn-yellow"
                                            : flight.inquiries > 2 &&
                                              flight.inquiries <= 3
                                              ? "btn-orange"
                                              : "btn-red"
                                          : "btnDarkBlueFlightLIst")
                                      }
                                      onClick={(e) => JoinFlight(flight)}
                                      style={{ padding: "2px" }}>
                                      Inquire&nbsp;
                                      {flight.inquiries > 0 && (
                                        <span
                                          color={
                                            flight.inquiries <= 2
                                              ? "yellow"
                                              : flight.inquiries > 2 &&
                                                flight.inquiries <= 3
                                                ? "orange"
                                                : "red"
                                          }>
                                          {flight.inquiries}
                                        </span>
                                      )}
                                    </Button>
                                  )}
                                {typeof user.userid === "undefined" &&
                                  page != "MyFlight" && (
                                    <LoginAndSignUp
                                      ButtonMode="Join"
                                      flight={flight}
                                      openmode="SignUp"
                                    />
                                  )}
                                {typeof user.userid !== "undefined" &&
                                  page === "MyFlight" && (
                                    <>
                                      {!flight.is_posted ? (
                                        <>
                                          <Button
                                            className="btn-sm btn-block btnYellow ml-0"
                                            onClick={(e) =>
                                              DeleteFlight(flight.id)
                                            }>
                                            Delete
                                          </Button>
                                          {flight.inquiries > 0 && (
                                            <Badge
                                              color={
                                                flight.inquiries <= 2
                                                  ? "yellow"
                                                  : flight.inquiries > 2 &&
                                                    flight.inquiries <= 3
                                                    ? "orange"
                                                    : "red"
                                              }>
                                              {flight.inquiries}
                                            </Badge>
                                          )}
                                          <Button
                                            className="btn-sm btn-block btnGray m-0 my-1  mr-0"
                                            onClick={(e) =>
                                              GetFlightPrice(flight)
                                            }>
                                            Get Price
                                          </Button>
                                        </>
                                      ) : (
                                        <div className="mt-1">
                                          <Button
                                            className="btn-sm btnYellow px-1 py-0"
                                            onClick={(e) =>
                                              history.push("/Edit/" + flight.id)
                                            }>
                                            <FaPencilAlt />
                                          </Button>
                                          <Button
                                            className="btn-sm btnYellow px-1 py-0"
                                            onClick={(e) =>
                                              DeleteFlight(flight.id)
                                            }>
                                            <FaTrash />
                                          </Button>
                                          {flight.inquiries > 0 && (
                                            <Badge
                                              color={
                                                flight.inquiries <= 2
                                                  ? "yellow"
                                                  : flight.inquiries > 2 &&
                                                    flight.inquiries <= 3
                                                    ? "orange"
                                                    : "red"
                                              }>
                                              {flight.inquiries}
                                            </Badge>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
        </Row>
        <br />
        {/* {flightData.filter((x) => x.is_posted === false).length > 0 && (
          <Row className="">
            <Col lg="6" md="6" sm="12" xs="12" className="text-center">
              <Card style={{ border: "none" }}>
                <CardBody className="listHeader p-0 mx-2">
                  <h2>Flight Interest</h2>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )} */}
        {/* )} */}
        <Row>
          {flightData.length > 0 &&
            flightData
              .filter((x) => x.is_posted === false)
              .map((flight, index) => {
                return (
                  <Col lg="6" md="6" sm="12" xs="12" key={index}>
                    <Card className="mt-3 mr-2 ml-2  flightNotPosted">
                      <CardBody className="py-0">
                        <Row key={flight}>
                          <Col lg="12" md="12" sm="12" xs="12">
                            <Row>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="pl-0 py-2 text-left">
                                {typeof user.userid !== "undefined" &&
                                  flight.isfriend &&
                                  flight.profile_pic_path && (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "auto",
                                        borderRadius: "50%",
                                      }}
                                      src={flight.profile_pic_path}
                                      alt="img"
                                    />
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  flight.isfriend &&
                                  !flight.profile_pic_path && (
                                    <div
                                      className="bg-white pr-0 text-center"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        border: "3px solid black",
                                        borderRadius: "50%",
                                      }}>
                                      <h2 className="textInitial">
                                        {flight.name.split(" ").length > 1
                                          ? flight.name
                                            .split(" ")[0]
                                            .substring(0, 1) +
                                          flight.name
                                            .split(" ")[1]
                                            .substring(0, 1)
                                          : flight.name.substring(0, 2)}
                                      </h2>
                                    </div>
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  flight.isgroup &&
                                  !flight.group_profile_pic_path && (
                                    <div
                                      className="bg-white pr-0 text-center"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        border: "3px solid black",
                                        borderRadius: "50%",
                                      }}>
                                      <h2 className="textInitial">
                                        {flight?.group_name?.split(" ")?.length > 1
                                          ? flight?.group_name?.split(" ")[0]?.substring(0, 1) +
                                          flight?.group_name?.split(" ")[1]?.substring(0, 1)
                                          : flight?.group_name?.substring(0, 2)}
                                      </h2>
                                    </div>
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  flight.isgroup &&
                                  flight.group_profile_pic_path && (
                                    <img
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                      }}
                                      src={flight.group_profile_pic_path}
                                    />
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  !flight.isgroup &&
                                  !flight.profile_pic_path &&
                                  !flight.group_profile_pic_path && (
                                    <img
                                      style={{ width: "50px", height: "auto" }}
                                      src={
                                        require("../assets/images/icons/ninja1.png")
                                          .default
                                      }
                                    />
                                  )}

                                {typeof user.userid !== "undefined" &&
                                  !flight.isfriend &&
                                  !flight.isgroup &&
                                  flight.profile_pic_path && (
                                    <>
                                      <img
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          borderRadius: "50%",
                                        }}
                                        src={flight.profile_pic_path}
                                      />
                                      <img
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          position: "absolute",
                                          left: "-2px",
                                          top: "7px",
                                        }}
                                        src={NinjaOverlay}
                                      />
                                    </>
                                  )}

                                {typeof user.userid === "undefined" && (
                                  <img
                                    style={{ width: "50px", height: "auto" }}
                                    src={
                                      require("../assets/images/icons/ninja1.png")
                                        .default
                                    }
                                  />
                                )}
                              </Col>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="my-1 p-0">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Departs</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>{flight.from_airport}</h4>
                                <h6 className="m-0 textCity" style={{ color: "#3B5E75" }}>
                                  {flight.from_city?.substring(0, 10)}
                                </h6>
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>{flight.dep_date}</h6>
                              </Col>
                              <Col
                                lg="1"
                                md="1"
                                sm="1"
                                xs="1"
                                className="mt-2 p-0 text-center">
                                <h6 className="my-0">
                                  <FaChevronRight
                                    style={{
                                      height: "40px",
                                      fontWeight: "bold",
                                    }}
                                    color="rgb(255, 230, 0)"
                                  />
                                  <FaChevronRight
                                    style={{
                                      height: "40px",
                                      fontWeight: "bold",
                                    }}
                                    color="rgb(255, 230, 0)"
                                  />
                                  <FaChevronRight
                                    style={{
                                      height: "40px",
                                      fontWeight: "bold",
                                    }}
                                    color="rgb(255, 230, 0)"
                                  />
                                </h6>
                              </Col>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="my-1 p-0 text-right">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Arrives</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>{flight.to_airport}</h4>
                                <h6 className="m-0 textCity" style={{ color: "#3B5E75" }}>
                                  {flight.to_city?.substring(0, 10)}
                                </h6>
                              </Col>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="my-1 p-0 text-center">
                                <h6 className="m-0" style={{ color: "#3B5E75" }}>Pax</h6>
                                <h4 className="m-0" style={{ color: "#3B5E75" }}>{flight.passenger}</h4>
                              </Col>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="mt-1 p-0">
                                {typeof user.userid !== "undefined" &&
                                  page !== "MyFlight" && (
                                    <div className="mt-3">
                                      <Button
                                        className={
                                          "btn-sm btn-block " +
                                          (flight.inquiries > 0
                                            ? flight.inquiries <= 2
                                              ? "btn-yellow"
                                              : flight.inquiries > 2 &&
                                                flight.inquiries <= 3
                                                ? "btn-orange"
                                                : "btn-red"
                                            : "btnGray")
                                        }
                                        onClick={(e) => JoinFlight(flight)}
                                        style={{ padding: "2px" }}>
                                        Inquire&nbsp;
                                        {flight.inquiries > 0 && (
                                          <span
                                            color={
                                              flight.inquiries <= 2
                                                ? "yellow"
                                                : flight.inquiries > 2 &&
                                                  flight.inquiries <= 3
                                                  ? "orange"
                                                  : "red"
                                            }>
                                            {flight.inquiries}
                                          </span>
                                        )}
                                      </Button>
                                    </div>
                                  )}
                                {typeof user.userid === "undefined" &&
                                  page != "MyFlight" && (
                                    <div className="mt-3">
                                      <LoginAndSignUp
                                        ButtonMode="Join"
                                        flight={flight}
                                        openmode="SignUp"
                                      />
                                    </div>
                                  )}
                                {typeof user.userid !== "undefined" &&
                                  page === "MyFlight" && (
                                    <>
                                      <Button
                                        className="btn-sm btnGray px-1 py-0"
                                        onClick={(e) =>
                                          history.push("/Edit/" + flight.id)
                                        }>
                                        <FaPencilAlt />
                                      </Button>
                                      <Button
                                        className="btn-sm btnGray px-1 py-0"
                                        onClick={(e) =>
                                          DeleteFlight(flight.id)
                                        }>
                                        <FaTrash />
                                      </Button>
                                      {flight.inquiries > 0 && (
                                        <Badge
                                          style={{ margin: "0" }}
                                          color={
                                            flight.inquiries <= 2
                                              ? "yellow"
                                              : flight.inquiries > 2 &&
                                                flight.inquiries <= 3
                                                ? "orange"
                                                : "red"
                                          }>
                                          {flight.inquiries}
                                        </Badge>
                                      )}
                                      <Button
                                        className="btn-sm btn-block btnGray m-0 my-1 px-1 py-0"
                                        onClick={(e) => GetFlightPrice(flight)}>
                                        Get Price
                                      </Button>
                                    </>
                                  )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
        </Row>
        <br />
        {page !== "MyFlight" && (
          <Alert className="alertWhite">
            No matches? To see all seats/flights from Origin you may leave
            Destination and/or Date blank. Otherwise, Press Add Interest so
            others can see or Get Price to solicit pricing.
          </Alert>
        )}
      </Card>
      <DuffelBooking
        isOpen={isDuffelModalOpen}
        setIsOpen={setIsDuffelModalOpen}
        toggle={toggleDuffelModal}
        selectedFlight={selectedDuffelFlight}
        user={user}
      />
    </>
  );
}

export default FlightList;

