import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { getFormatedDate, getPrevAndNextDatesWithFormats } from "../../utils/FormatedDate";

const FilterFlightDate = ({ dateString, setFilterValue, filterValue }) => {

    // const inputDate = new Date(dateString); // Convert input string to Date object
    const datesArray = [];
    if (getFormatedDate(dateString) === getFormatedDate(new Date())) {
        // Case 1: If `dateString` is today, show today + next two days
        for (let i = 0; i < 3; i++) {
            const nextDate = new Date();
            nextDate.setDate(nextDate.getDate() + i);

            const { numeric, short } = getPrevAndNextDatesWithFormats(getFormatedDate(nextDate)).current;
            datesArray.push({
                label: short,
                value: numeric,
            });
        }
    } else {
        // Case 2: If `dateString` is not today, show prev day, today, and next day
        const { prev, current, next } = getPrevAndNextDatesWithFormats(dateString);

        datesArray.push(
            { label: prev.short, value: prev.numeric },
            { label: current.short, value: current.numeric },
            { label: next.short, value: next.numeric }
        );
    }
    return (
        <div>
            <ToggleButtonGroup
                color="primary"
                value={filterValue?.date}
                exclusive
                onChange={(e) => setFilterValue((prev) => ({
                    ...prev,
                    date: e?.target?.value,
                }))}
                aria-label="date filter"
                className="date-filter-button"
            >
                <ToggleButton value="All"
                    sx={{
                        px: { xs: 1, md: 4.2 }
                    }}>
                    All
                </ToggleButton>
                {datesArray.map(({ label, value }, index) => (
                    <ToggleButton key={index} value={value} sx={{
                        px: { xs: 1, md: 2.2 }
                    }}>
                        {label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div >
    );
};

export default FilterFlightDate;
