import { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Grid, Card, Select, MenuItem, useMediaQuery } from "@material-ui/core";
import {
  Col,
  Row,
} from "reactstrap";
import { useLocalStorage } from "../../utils/useLocalStorage";
import PathArea from "../../components/PathArea";
import Search from "../../components/HomePage/SearchSEO";
import Book from "../../components/HomePage/Book";
import FlightList from "../../components/FlightList";
import RouteDescription from "./RouteDescription";
import "./../HomePage.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import { HiChevronDown } from 'react-icons/hi';
import BannerBottom from "../../assets/images/backgroundPlane.png";
import FilterFlightDate from "../../components/HomePage/filterFlightDate";
import { getFormatedDate } from "../../utils/FormatedDate";
import { Button } from "antd";

function HomePageNYtoSF() {
  const { route } = useParams();
  const location = useLocation();
  const [user] = useLocalStorage("auth", {});
  const [pathType, setPathType] = useState("search");
  const [unregistedFlightsData, setFlightData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateString, setDateString] = useState(getFormatedDate(new Date()))
  const [filterValue, setFilterValue] = useState({ value: 0, date: "All", timePrice: "Departure_Time" })
  const [sidebarTop, setSidebarTop] = useState(undefined);
  var [headerHeight, setHeaderHeight] = useState(125);
  const [from_airport, setFromAirport] = useState(null);
  const [to_airport, setToAirport] = useState(null);
  const [fromCity, setFromCity] = useState(null);
  const [toCity, setToCity] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [pageDescription, setPageDescription] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const isLgScreen = useMediaQuery('(min-width:768px)'); // Bootstrap's lg breakpoint
  const isDesktop = useMediaQuery('(min-width:992px)'); // Bootstrap's lg breakpoint
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;
    if (card && card.scrollHeight > card.clientHeight) {
      card.style.height = "auto";
    }
  }, []); // E

  const fetchRoutesfromParameter = (route) => {
    switch (route) {
      case "new-york-south-florida":
        setFromAirport("NYALL");
        console.log("SET FROM " + from_airport);
        setToAirport("SFALL");
        setFromCity("New York");
        setToCity("South Florida");
        setPageTitle("Shared Private Jet Flights From New York to South Florida");
        setPageDescription("Fly in ultimate comfort from New York to South Florida on a shared private jet flight. Luxury, convenience, and affordability combined. Book now!");
        break;
      case "new-york-aspen":
        setFromAirport("NYALL");
        console.log("SET FROM " + from_airport);
        setToAirport("ASE");
        setFromCity("New York");
        setToCity("Aspen");
        setPageTitle("Shared Private Jet Flights From New York to Aspen");
        setPageDescription("Fly in ultimate comfort from New York to Aspen on a shared private jet flight. Luxury, convenience, and affordability combined. Book now!");
        break;
      case "south-florida-new-york":
        setFromAirport("SFALL");
        setToAirport("NYALL");
        setFromCity("South Florida");
        setToCity("New York");
        setPageTitle("Shared Private Jet Flights From South Florida to New York");
        setPageDescription("Fly in comfort from South Florida to New York on our shared private jet flights. Experience convenience and luxury like never before. Book now!");
        break;
      case "teterboro-palm-beach":
        setFromAirport("TEB");
        setToAirport("PBI");
        setFromCity("Teterboro");
        setToCity("Palm Beach");
        setPageTitle("Shared Private Charter Jet Flights Teterboro to Palm Beach");
        setPageDescription("Book a private jet charter flight from Teterboro Airport (TEB) to Palm Beach. Luxury, convenience, and affordability combined with Katana.");
        break;
      case "palm-beach-teterboro":
        setFromAirport("PBI");
        setToAirport("TEB");
        setFromCity("Palm Beach");
        setToCity("Teterboro");
        setPageTitle("Shared Private Charter Jet Flights Palm Beach to Teterboro");
        setPageDescription("Book a shared private jet flight from Palm Beach to Teterboro Airport (TEB). Fly in style and convenience, sharing the cost with fellow travelers.");
        break;
      case "westchester-palm-beach":
        setFromAirport("HPN");
        setToAirport("PBI");
        setFromCity("Westchester");
        setToCity("Palm Beach");
        setPageTitle("Shared Private Jet Flights Westchester to West Palm Beach");
        setPageDescription("Fly in comfort from Westchester to West Palm Beach on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "palm-beach-westchester":
        setFromAirport("PBI");
        setToAirport("HPN");
        setFromCity("Palm Beach");
        setToCity("Westchester");
        setPageTitle("Shared Private Jet Flights West Palm Beach to Westchester");
        setPageDescription("Enjoy exclusive shared private jet flights from West Palm Beach to Westchester. Experience luxury travel and convenience, while sharing the cost.");
        break;
      case "los-angeles-aspen":
        setFromAirport("LOSALL");
        setToAirport("ASE");
        setFromCity("Los Angeles");
        setToCity("Aspen");
        setPageTitle("Shared Private Jet Flights From Los Angeles to Aspen");
        setPageDescription("Fly in comfort from Los Angeles to Aspen on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "aspen-los-angeles":
        setFromAirport("ASE");
        setToAirport("LOSALL");
        setFromCity("Aspen");
        setToCity("Los Angeles");
        setPageTitle("Shared Private Jet Flights From Aspen to Los Angeles");
        setPageDescription("Shared private jet flights from Aspen to Los Angeles. Fly in style and convenience, sharing the cost with fellow travelers. Book your flight now!");
        break;
      case "aspen-dallas":
        setFromAirport("ASE");
        setToAirport("DALALL");
        setFromCity("Aspen");
        setToCity("Dallas");
        setPageTitle("Shared Private Jet Flights From Aspen to Dallas | Fly Katana");
        setPageDescription("Enjoy exclusive shared private jet flights from Aspen to Dallas. Experience luxury travel and convenience, while sharing the cost.");
        break;
      case "dallas-aspen":
        setFromAirport("DALALL");
        setToAirport("ASE");
        setFromCity("Dallas");
        setToCity("Aspen");
        setPageTitle("Shared Private Jet Flights From Dallas to Aspen | Fly Katana");
        setPageDescription("Indulge in private jet flights from Dallas to Aspen and share the cost. Experience unmatched luxury and convenience as you soar to your mountain escape.");
        break;
      case "los-angeles-cabo-san-lucas":
        setFromAirport("LOSALL");
        setToAirport("CSLALL");
        setFromCity("Los Angeles");
        setToCity("Cabo San Lucas");
        setPageTitle("Shared Private Jet Flights Los Angeles to Cabo San Lucas");
        setPageDescription("Fly in comfort from Los Angeles to Cabo San Lucas on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "cabo-san-lucas-los-angeles":
        setFromAirport("CSLALL");
        setToAirport("LOSALL");
        setFromCity("Cabo San Lucas");
        setToCity("Los Angeles");
        setPageTitle("Shared Private Jet Flights Cabo San Lucas to Los Angeles");
        setPageDescription("Book a shared private jet flight from Cabo San Lucas to Los Angeles. Fly in style and convenience, sharing the cost with fellow travelers.");
        break;
      case "san-francisco-cabo-san-lucas":
        setFromAirport("SFO");
        setToAirport("CSLALL");
        setFromCity("San Francisco");
        setToCity("Cabo San Lucas");
        setPageTitle("Shared Private Jet Flights San Francisco to Cabo San Lucas");
        setPageDescription("Escape to paradise with shared private jet flights from San Francisco to Cabo San Lucas. Experience ultimate luxury and discover the beauty of Baja. Book now!");
        break;
      case "cabo-san-lucas-san-francisco":
        setFromAirport("CSLALL");
        setToAirport("SFO");
        setFromCity("Cabo San Lucas");
        setToCity("San Francisco");
        setPageTitle("Shared Private Jet Flights Cabo San Lucas to San Francisco");
        setPageDescription("Enjoy exclusive shared private jet flights from Cabo San Lucas to San Francisco. Experience luxury travel and convenience, while sharing the cost.");
        break;
      case "fort-lauderdale-westchester":
        setFromAirport("FLL");
        setToAirport("HPN");
        setFromCity("Fort Lauderdale");
        setToCity("Westchester");
        setPageTitle("Shared Private Jet Flights Fort Lauderdale to Westchester");
        setPageDescription("Fly in comfort from Fort Lauderdale to Westchester on a shared private jet. Luxury, convenience, and affordability combined. Book your flight today!");
        break;
      case "westchester-fort-lauderdale":
        setFromAirport("HPN");
        setToAirport("FLL");
        setFromCity("Westchester");
        setToCity("Fort Lauderdale");
        setPageTitle("Shared Private Jet Flights Westchester to Fort Lauderdale");
        setPageDescription("Book a shared private jet flight from Westchester to Fort Lauderdale. Fly in style and convenience, sharing the cost with fellow travelers.");
        break;
      default:
        const [from, to] = route.split('-');
        console.log(from, to, "check");
        if (from) {
          setFromAirport(from);
          if (to != "null") setToAirport(to);
          setPageTitle("Empty Legs");
          const queryParams = new URLSearchParams(location?.search);
          const date = queryParams.get("date");
          if (date) setSearchDate(date);
          // Update the URL without reloading the page
          window?.history?.replaceState({}, document.title, '/');
        } else {
          setFromCity("New York");
          setToCity("South Florida");
          setPageTitle("Shared Private Jet Flights From South Florida to New York");
          setPageDescription("Fly in comfort from South Florida to New York on our shared private jet flights. Experience convenience and luxury like never before. Book now!");
        }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const chatEl = document.querySelector('.sticky-component').getBoundingClientRect();
    setSidebarTop(chatEl.top);

  }, [from_airport]);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };

  }, [sidebarTop]);

  const isSticky = (e) => {
    const chatEl = document.querySelector('.sticky-component');
    const scrollTop = window.scrollY;
    setHeaderHeight(document.getElementById('top-header').offsetHeight);
    //      console.log("HeadHeight: "+document.getElementById('top-header').offsetHeight);
    headerHeight = document.getElementById('top-header').offsetHeight;
    //  console.log("scrollTop: "+scrollTop);
    //  console.log("sidebarTop: "+sidebarTop);
    //  console.log("Header height: "+headerHeight);

    if (scrollTop >= sidebarTop - headerHeight) {
      document.documentElement.style.setProperty('--sticky-top', headerHeight + 'px');
      chatEl.classList.add('is-sticky');
    } else {
      chatEl.classList.remove('is-sticky');
    }
  };

  useEffect(() => {
    //Fetch from and to city from route names
    console.log("Route: " + route);
    fetchRoutesfromParameter(route);
    console.log(`From: ${from_airport}, To: ${to_airport}`);
  }
    , [from_airport, to_airport])

  useEffect(() => {
    handleFilterValueChange()
  }, [filterValue])

  useEffect(() => {
    setFilteredData(unregistedFlightsData);
  }, [unregistedFlightsData]);
  const handleFilterValueChange = () => {

    let newFilteredData = [...unregistedFlightsData];
    // conditon for filter when tab change seat =1 , Chater=2 ,interest=3
    switch (filterValue?.value) {
      case 1:
        newFilteredData = newFilteredData.filter(
          (x) => x?.is_posted === true && x?.is_charter === false
        );
        break;
      case 2:
        newFilteredData = newFilteredData.filter((x) => x?.is_charter === true);
        break;
      case 3:
        newFilteredData = newFilteredData.filter((x) => x?.is_posted === false);
        break;
      default:
        // Case 0 or undefined fallback
        break;
    }
    // filter function for Departure_Time ,Price_Highest, Price lowest

    switch (filterValue?.timePrice) {
      case "Departure_Time":
        newFilteredData.sort((a, b) => {
          // // Push items with total_price === 0 to the end
          // if (a?.total_price === 0 && b?.total_price !== 0) return 1;
          // if (b?.total_price === 0 && a?.total_price !== 0) return -1;

          // sort by departure date and time
          const dateA = new Date(`${a?.dep_date} ${a?.dep_time || "00:00"}`);
          const dateB = new Date(`${b?.dep_date} ${b?.dep_time || "00:00"}`);
          return dateA - dateB; // Sort by earliest departure time
        });
        break;
      case "Price_Highest":
        newFilteredData.sort((a, b) => (b?.total_price) - (a?.total_price))
        break;
      case "Price_Lowest":
        newFilteredData.sort((a, b) => (a?.total_price) - (b?.total_price));
        break;
      default:
        return 0;
    }
    // filter change for date
    if (filterValue?.date && filterValue?.date !== "All") {
      // get date 1/16/2025 to 2025-01-15
      const filterDateValue = new Date(filterValue?.date).toISOString().split("T")[0];
      newFilteredData = newFilteredData.filter((x) => {
        const depDate = new Date(x?.dep_date).toISOString().split("T")[0];
        return filterDateValue === depDate;
      });
    }
    newFilteredData.sort((a, b) => (a?.total_price === 0 ? 1 : b?.total_price === 0 ? -1 : 0))
    setFilteredData(newFilteredData);
  };


  return (
    <>
      {fromCity && toCity && pageTitle && pageDescription && (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <link
            rel="canonical"
            href={`https://www.flykatana.com/routes/${route}`}
          ></link>
        </Helmet>
      )}
      {pathType === "book" && (
        <>
          <Row style={{ marginTop: "-2px" }}>
            <Col
              lg={{ size: 12 }}
              md={{ size: 12 }}
              sm="12"
              className="text-center"
            >
              <Grid className="imgBackground">
                <img className="BannerBottom-image" src={BannerBottom} alt="image" />
                <div className={window?.location?.pathname === "/" ? "fullWidthHomeSearch m-3" : "fullWidthHomeSearchSell m-3"}>
                  <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                    <Col
                      lg={{ size: "7", }}
                      md={{ size: "7", }}
                      sm="12"
                    >
                      <Card style={{ backgroundColor: "#ffffffde" }} className="">
                        {typeof user.userid !== "undefined" && (
                          <Row className="">
                            <Col lg="12" md="12" sm="12">
                              <PathArea
                                pathType={pathType}
                                setPathType={setPathType}
                                user={user}
                              />
                            </Col>
                          </Row>
                        )}
                        <Book />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Grid>
            </Col>
          </Row>
        </>
      )}
      {pathType === "search" && (
        <>
          <Grid style={{ marginTop: "-2px" }} className="imgBackground" ref={cardRef}>
            <img className="BannerBottom-image" src={BannerBottom} alt="image" />
            <div className="fullWidthHomeSearch HomePage-Bannersection m-3 mb-6">
              {(fromCity && toCity) ? (
                <h1
                  className="text-center"
                  style={{ marginTop: "0.5rem", color: "white" }}
                >
                  Shared private flights from {fromCity} to {toCity}
                </h1>
              ) :
                (<br />)}
              <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                <Col
                  lg={{ size: "8", }}
                  md={{ size: "10" }}
                  sm="12"
                >
                  <Card
                    className="pb-3 overflow-visible"
                    style={{ opacity: "1 !important", backgroundColor: "#ffffffde" }}
                  >
                    {/* {typeof user.userid !== "undefined" && ( */}
                    <Row className="mt-2">
                      <Col lg="12" md="12" sm="12">
                        <PathArea
                          pathType={pathType}
                          user={user}
                          setPathType={setPathType}
                        />
                      </Col>
                    </Row>
                    {/* )} */}
                    {from_airport && (
                      <Search
                        unregistedFlightsData={unregistedFlightsData}
                        setFlightData={setFlightData}
                        fromAirport={from_airport}
                        toAirport={to_airport}
                        searchDate={searchDate}
                        setDateString={setDateString}
                        setFilterValue={setFilterValue}
                        IsFilterAirportDataByAll={true}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
          </Grid>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-3"></Col>
            <Col lg="12" md="12" sm="12" className="mb-3" style={{ paddingLeft: isLgScreen ? "5.5rem" : "", paddingRight: isLgScreen ? "5.5rem" : "" }}>
              <div className="sticky-component">
                <Box sx={{ bgcolor: "background.paper", display: "flex", justifyContent: "space-between", flexDirection: isLgScreen ? "row" : "column-reverse" }}>
                  <Tabs
                    value={filterValue?.value}
                    onChange={(event, newValue) => {
                      setFilterValue((prev) => ({
                        ...prev,
                        value: newValue,
                      }));
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="#FFE600"
                    aria-label="scrollable auto tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#FFE600",
                        height: 3,
                        textColor: "#FFE600",
                      },
                    }}
                  >
                    {["All", "Seats", "Charters", "Interests"].map((label, index) => (
                      <Tab
                        key={index}
                        label={label}
                        sx={{
                          px: { xs: 0.6, md: 4.2 },
                          "&.Mui-selected": {
                            bgcolor: "#1976d214",
                            color: "#1976d2"
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                  <div className="Filter-by-div">
                    <div>Sort by
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        className="ml-2"
                        value={filterValue.timePrice}
                        onChange={(e) => {
                          setFilterValue((prev) => ({
                            ...prev, timePrice: e?.target?.value
                          }))
                        }}
                        label="Departure_Time"
                        IconComponent={HiChevronDown}
                      >
                        <MenuItem value="Departure_Time">Departure Time</MenuItem>
                        <MenuItem value="Price_Highest">Price (Highest First)</MenuItem>
                        <MenuItem value="Price_Lowest">Price (Lowest First)</MenuItem>
                      </Select>
                    </div>
                    <div>
                      <Button className="border-dark" onClick={() => {
                        setFilterValue({ value: 0, date: "All", timePrice: "Departure_Time" })
                      }}>Clear FILTERS</Button>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="date-filter-div">
                <FilterFlightDate dateString={dateString} setFilterValue={setFilterValue} filterValue={filterValue} />
                <div><Button className="border-dark" onClick={() => {
                  setFilterValue({ value: 0, date: "All", timePrice: "Departure_Time" })
                }}>Clear FILTERS</Button>
                </div>
              </div>
              <FlightList
                flightData={filteredData}
                // setFlightData={setFilteredData}
                page="Home"
              />
              {from_airport && to_airport && (
                <RouteDescription fromCity={fromCity} toCity={toCity} />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default HomePageNYtoSF
