export default
  [
    {
      "city": "108 Mile Ranch",
      "country": "Canada",
      "IATA": "ZMH",
      "icao_code": "CZML",
      "airport_name": "South Cariboo Regional Airport"
    },
    {
      "city": "ANDERSON",
      "country": "United States",
      "IATA": "AID",
      "icao_code": "KAID",
      "airport_name": "Anderson Municipal Airport "
    },
    {
      "city": "Aachen",
      "country": "Germany",
      "IATA": "AAH",
      "icao_code": "EDKA",
      "airport_name": " Merzbrueck Airport"
    },
    {
      "city": "Aalborg",
      "country": "Denmark",
      "IATA": "AAL",
      "icao_code": "EKYT",
      "airport_name": "Aalborg Airport"
    },
    {
      "city": "Aarhus",
      "country": "Denmark",
      "IATA": "AAR",
      "icao_code": "EKAH",
      "airport_name": "Aarhus Airport"
    },
    {
      "city": "Aasiaat",
      "country": "Greenland",
      "IATA": "JEG",
      "icao_code": "BGEM",
      "airport_name": "Aasiaat Airport"
    },
    {
      "city": "Abadan",
      "country": "Iran",
      "IATA": "ABD",
      "icao_code": "OIAA",
      "airport_name": "Abadan International Airport "
    },
    {
      "city": "Abaiang Atoll",
      "country": "Kiribati",
      "IATA": "ABF",
      "airport_name": "Abaiang Atoll Airport"
    },
    {
      "city": "Abakan",
      "country": "Russia",
      "IATA": "ABA",
      "icao_code": "UNAA",
      "airport_name": "Abakan International Airport"
    },
    {
      "city": "Abbeville",
      "country": "France",
      "IATA": "XAB",
      "airport_name": "Abbeville Airport"
    },
    {
      "city": "Abbotsford",
      "country": "Canada",
      "IATA": "YXX",
      "icao_code": "CYXX",
      "airport_name": "Abbotsford International Airport"
    },
    {
      "city": "Abeche",
      "country": "Chad",
      "IATA": "AEH",
      "icao_code": "FTTC",
      "airport_name": "Abéché Airport"
    },
    {
      "city": "Abemama",
      "country": "Kiribati",
      "IATA": "AEA",
      "icao_code": "NGTB",
      "airport_name": "Abemama Atoll Airport"
    },
    {
      "city": "Aberdeen",
      "country": "United States",
      "IATA": "ABR",
      "icao_code": "KABR",
      "airport_name": "Aberdeen Regional Airport"
    },
    {
      "city": "Aberdeen",
      "country": "United States",
      "IATA": "APG",
      "airport_name": "Phillips Army Airfield"
    },
    {
      "city": "Aberdeen",
      "country": "United Kingdom",
      "IATA": "ABZ",
      "icao_code": "EGPD",
      "airport_name": "Aberdeen International Airport"
    },
    {
      "city": "Abha",
      "country": "Saudi Arabia",
      "IATA": "AHB",
      "icao_code": "OEAB",
      "airport_name": "Abha Regional Airport"
    },
    {
      "city": "Abidjan",
      "country": "Cote d'Ivoire",
      "IATA": "ABJ",
      "icao_code": "DIAP",
      "airport_name": "Port Bouet Airport"
    },
    {
      "city": "Abilene",
      "country": "United States",
      "IATA": "ABI",
      "icao_code": "KABI",
      "airport_name": "Abilene Regional Airport"
    },
    {
      "city": "Abilene",
      "country": "United States",
      "IATA": "DYS",
      "airport_name": "Dyess Air Force Base"
    },
    {
      "city": "Ablow",
      "country": "Vanuatu",
      "IATA": "MTV",
      "icao_code": "NVSA",
      "airport_name": "Mota Lava Airport"
    },
    {
      "city": "Abu Dhabi",
      "country": "United Arab Emirates",
      "IATA": "DHF",
      "airport_name": "Al Dhafra Air Base"
    },
    {
      "city": "Abu Dhabi",
      "country": "United Arab Emirates",
      "IATA": "AZI",
      "icao_code": "OMAD",
      "airport_name": "Al Bateen Executive Airport"
    },
    {
      "city": "Abu Dhabi",
      "country": "United Arab Emirates",
      "IATA": "AUH",
      "icao_code": "OMAA",
      "airport_name": "Abu Dhabi International Airport"
    },
    {
      "city": "Abu Simbel",
      "country": "Egypt",
      "IATA": "ABS",
      "icao_code": "HEBL",
      "airport_name": "Abu Simbel Airport"
    },
    {
      "city": "Abuja",
      "country": "Nigeria",
      "IATA": "ABV",
      "icao_code": "DNAA",
      "airport_name": "Nnamdi Azikiwe International Airport"
    },
    {
      "city": "Abumusa I.",
      "country": "Iran",
      "IATA": "AEU",
      "icao_code": "OIBA",
      "airport_name": "Abumusa Island Airport"
    },
    {
      "city": "Acandi",
      "country": "Colombia",
      "IATA": "ACD",
      "icao_code": "SKAD",
      "airport_name": "Alcides Fernandez Airport"
    },
    {
      "city": "Acapulco",
      "country": "Mexico",
      "IATA": "ACA",
      "icao_code": "MMAA",
      "airport_name": "General Juan N. Álvarez International Airport"
    },
    {
      "city": "Acarigua",
      "country": "Venezuela",
      "IATA": "AGV",
      "icao_code": "SVAC",
      "airport_name": "Oswaldo Guevara Mujica Airport"
    },
    {
      "city": "Accra",
      "country": "Ghana",
      "IATA": "ACC",
      "icao_code": "DGAA",
      "airport_name": "Kotoka International Airport"
    },
    {
      "city": "Achinsk",
      "country": "Russia",
      "IATA": "ACS",
      "icao_code": "UNKS",
      "airport_name": "Achinsk Airport"
    },
    {
      "city": "Ada",
      "country": "United States",
      "IATA": "ADT",
      "icao_code": "KADH",
      "airport_name": "Ada Municipal Airport "
    },
    {
      "city": "Adak Island",
      "country": "United States",
      "IATA": "ADK",
      "icao_code": "PADK",
      "airport_name": "Adak Airport"
    },
    {
      "city": "Adampur",
      "country": "India",
      "IATA": "AIP",
      "airport_name": "Ailinglaplap Airport"
    },
    {
      "city": "Adana",
      "country": "Turkey",
      "IATA": "UAB",
      "airport_name": "Incirlik Air Base"
    },
    {
      "city": "Adana",
      "country": "Turkey",
      "IATA": "ADA",
      "icao_code": "LTAF",
      "airport_name": "Adana Şakirpaşa Airport"
    },
    {
      "city": "Addis Ababa",
      "country": "Ethiopia",
      "IATA": "ADD",
      "icao_code": "HAAB",
      "airport_name": "Addis Ababa Bole International Airport"
    },
    {
      "city": "Addison",
      "country": "United States",
      "IATA": "ADS",
      "icao_code": "KADS",
      "airport_name": "Addison Airport"
    },
    {
      "city": "Adelaide",
      "country": "Australia",
      "IATA": "ADL",
      "icao_code": "YPAD",
      "airport_name": "Adelaide International Airport"
    },
    {
      "city": "Aden",
      "country": "Yemen",
      "IATA": "ADE",
      "icao_code": "OYAA",
      "airport_name": "Aden International Airport"
    },
    {
      "city": "Adiyaman",
      "country": "Turkey",
      "IATA": "ADF",
      "icao_code": "LTCP",
      "airport_name": "Adiyaman Airport"
    },
    {
      "city": "Adrar",
      "country": "Algeria",
      "IATA": "AZR",
      "icao_code": "DAUA",
      "airport_name": "Touat Cheikh Sidi Mohamed Belkebir Airport"
    },
    {
      "city": "Afutara",
      "country": "Solomon Islands",
      "IATA": "AFT",
      "icao_code": "AGAF",
      "airport_name": "Afutara Airport"
    },
    {
      "city": "Afyon",
      "country": "Turkey",
      "IATA": "AFY",
      "icao_code": "LTAH",
      "airport_name": "Afyon Airport"
    },
    {
      "city": "Agadez",
      "country": "Niger",
      "IATA": "AJY",
      "icao_code": "DRZA",
      "airport_name": "Mano Dayak International Airport"
    },
    {
      "city": "Agadir",
      "country": "Morocco",
      "IATA": "AGA",
      "icao_code": "GMAD",
      "airport_name": "Agadir–Al Massira Airport "
    },
    {
      "city": "Agana",
      "country": "Guam",
      "IATA": "GUM",
      "icao_code": "PGUM",
      "airport_name": "Antonio B. Won Pat International Airport "
    },
    {
      "city": "Agartala",
      "country": "India",
      "IATA": "IXA",
      "icao_code": "VEAT",
      "airport_name": "Agartala Airport "
    },
    {
      "city": "Agatti Island",
      "country": "India",
      "IATA": "AGX",
      "icao_code": "VOAT",
      "airport_name": "Agatti Airport"
    },
    {
      "city": "Agen",
      "country": "France",
      "IATA": "AGF",
      "icao_code": "LFBA",
      "airport_name": "Agen La Garenne Airport"
    },
    {
      "city": "Aggeneys",
      "country": "South Africa",
      "IATA": "AGZ",
      "icao_code": "FAAG",
      "airport_name": "Aggeneys Airport"
    },
    {
      "city": "Aghajari",
      "country": "Iran",
      "IATA": "AKW",
      "airport_name": "Klawock Airport"
    },
    {
      "city": "Agra",
      "country": "India",
      "IATA": "AGR",
      "icao_code": "VIAG",
      "airport_name": "Agra Airport"
    },
    {
      "city": "Agri",
      "country": "Turkey",
      "IATA": "AJI",
      "icao_code": "LTCO",
      "airport_name": "Ağrı Airport"
    },
    {
      "city": "Agrinion",
      "country": "Greece",
      "IATA": "AGQ",
      "airport_name": "Agrinion Airport"
    },
    {
      "city": "Aguadilla",
      "country": "Puerto Rico",
      "IATA": "BQN",
      "icao_code": "TJBQ",
      "airport_name": "Rafael Hernández Airport"
    },
    {
      "city": "Aguascalientes",
      "country": "Mexico",
      "IATA": "AGU",
      "icao_code": "MMAS",
      "airport_name": "Lic. Jesús Terán Peredo International Airport"
    },
    {
      "city": "Aguni",
      "country": "Japan",
      "IATA": "AGJ",
      "icao_code": "RORA",
      "airport_name": "Aguni Airport"
    },
    {
      "city": "Ahe",
      "country": "French Polynesia",
      "IATA": "AHE",
      "icao_code": "NTHE",
      "airport_name": "Ahe Airport"
    },
    {
      "city": "Ahmed Al Jaber AB",
      "country": "Kuwait",
      "IATA": "XIJ",
      "airport_name": "Ahmed Al Jaber Air Base"
    },
    {
      "city": "Ahmedabad",
      "country": "India",
      "IATA": "AMD",
      "icao_code": "VAAH",
      "airport_name": "Sardar Vallabhbhai Patel International Airport"
    },
    {
      "city": "Ahuas",
      "country": "Honduras",
      "IATA": "AHS",
      "icao_code": "MHAH",
      "airport_name": "Ahuas Airport"
    },
    {
      "city": "Ahwaz",
      "country": "Iran",
      "IATA": "AWZ",
      "icao_code": "OIAW",
      "airport_name": "Ahvaz International Airport"
    },
    {
      "city": "Aiken",
      "country": "United States",
      "IATA": "AIK",
      "icao_code": "KAIK",
      "airport_name": "Aiken Municipal Airport"
    },
    {
      "city": "Aioun El Atrouss",
      "country": "Mauritania",
      "IATA": "AEO",
      "icao_code": "GQNA",
      "airport_name": "Aioun el Atrouss Airport"
    },
    {
      "city": "Airlie Beach",
      "country": "Australia",
      "IATA": "WSY",
      "airport_name": "Whitsunday Airport"
    },
    {
      "city": "Aitutaki",
      "country": "Cook Islands",
      "IATA": "AIT",
      "icao_code": "NCAI",
      "airport_name": "Aitutaki Island Airport"
    },
    {
      "city": "Aizwal",
      "country": "India",
      "IATA": "AJL",
      "icao_code": "VEAZ",
      "airport_name": "Lengpui Airport"
    },
    {
      "city": "Ajaccio",
      "country": "France",
      "IATA": "MFX",
      "airport_name": "Meribel Airport"
    },
    {
      "city": "Ajaccio",
      "country": "France",
      "IATA": "AJA",
      "icao_code": "LFKJ",
      "airport_name": "Ajaccio Napoleon Bonaparte Airport"
    },
    {
      "city": "Ajmer",
      "country": "India",
      "IATA": "KQH",
      "icao_code": "VIKG",
      "airport_name": "Kishangarh Airport"
    },
    {
      "city": "Akhiok",
      "country": "United States",
      "IATA": "AKK",
      "icao_code": "PAKH",
      "airport_name": "Akhiok Airport"
    },
    {
      "city": "Akiak",
      "country": "United States",
      "IATA": "AKI",
      "icao_code": "PFAK",
      "airport_name": "Akiak Airport"
    },
    {
      "city": "Akita",
      "country": "Japan",
      "IATA": "AXT",
      "icao_code": "RJSK",
      "airport_name": "Akita Airport"
    },
    {
      "city": "Aklavik",
      "country": "Canada",
      "IATA": "LAK",
      "icao_code": "CYKD",
      "airport_name": "Aklavik/Freddie Carmichael Airport"
    },
    {
      "city": "Akola",
      "country": "India",
      "IATA": "AKD",
      "icao_code": "VAAK",
      "airport_name": "Akola Airport"
    },
    {
      "city": "Akron",
      "country": "United States",
      "IATA": "AKO",
      "icao_code": "KAKO",
      "airport_name": "Colorado Plains Regional Airport"
    },
    {
      "city": "Akron",
      "country": "United States",
      "IATA": "CAK",
      "icao_code": "KCAK",
      "airport_name": "Akron-Canton  Airport"
    },
    {
      "city": "Akron",
      "country": "United States",
      "IATA": "AKC",
      "icao_code": "KAKR",
      "airport_name": "Akron Fulton International Airport "
    },
    {
      "city": "Akrotiri",
      "country": "Cyprus",
      "IATA": "AKT",
      "icao_code": "LCRA",
      "airport_name": "Royal Air Force Akrotiri"
    },
    {
      "city": "Aksu",
      "country": "China",
      "IATA": "AKU",
      "icao_code": "ZWAK",
      "airport_name": "Aksu Airport"
    },
    {
      "city": "Aktau",
      "country": "Kazakhstan",
      "IATA": "SCO",
      "icao_code": "UATE",
      "airport_name": "Aktau International Airport"
    },
    {
      "city": "Aktyubinsk",
      "country": "Kazakhstan",
      "IATA": "AKX",
      "icao_code": "UATT",
      "airport_name": "Aktobe Airport"
    },
    {
      "city": "Akulivik",
      "country": "Canada",
      "IATA": "AKV",
      "icao_code": "CYKO",
      "airport_name": "Akulivik Airport"
    },
    {
      "city": "Akure",
      "country": "Nigeria",
      "IATA": "AKR",
      "icao_code": "DNAK",
      "airport_name": "Akure Airport"
    },
    {
      "city": "Akureyri",
      "country": "Iceland",
      "IATA": "AEY",
      "icao_code": "BIAR",
      "airport_name": "Akureyri Airport"
    },
    {
      "city": "Akutan",
      "country": "United States",
      "IATA": "KQA",
      "airport_name": "Akutan Seaplane Base"
    },
    {
      "city": "Al Ain",
      "country": "United Arab Emirates",
      "IATA": "AAN",
      "icao_code": "OMAL",
      "airport_name": "Al Ain International Airport"
    },
    {
      "city": "Al Asad",
      "country": "Iraq",
      "IATA": "IQA",
      "icao_code": "CYFB",
      "airport_name": "Al Asad Airbase"
    },
    {
      "city": "Al Bakr",
      "country": "Iraq",
      "IATA": "XQC",
      "airport_name": "Balad Airport"
    },
    {
      "city": "Al Bayda'",
      "country": "Libya",
      "IATA": "LAQ",
      "icao_code": "HLLQ",
      "airport_name": "Al Abraq International Airport"
    },
    {
      "city": "Al Ghaidah Intl",
      "country": "Yemen",
      "IATA": "AAY",
      "icao_code": "OYGD",
      "airport_name": "Al Ghaydah Airport"
    },
    {
      "city": "Al Hociema",
      "country": "Morocco",
      "IATA": "AHU",
      "icao_code": "GMTA",
      "airport_name": "Charif Al ldrissi Airport"
    },
    {
      "city": "Al Kharj",
      "country": "Saudi Arabia",
      "IATA": "AKH",
      "icao_code": "OEPS",
      "airport_name": "Prince Sultan Air Base"
    },
    {
      "city": "Al Masna'ah",
      "country": "Oman",
      "IATA": "MNH",
      "airport_name": "Hingurakgoda Airport"
    },
    {
      "city": "Al Taqaddum",
      "country": "Iraq",
      "IATA": "TQD",
      "airport_name": "Al Taqaddum Air Base"
    },
    {
      "city": "Al-Jawf",
      "country": "Saudi Arabia",
      "IATA": "AJF",
      "icao_code": "OESK",
      "airport_name": "Al-Jawf Domestic Airport"
    },
    {
      "city": "Al-Ula",
      "country": "Saudi Arabia",
      "IATA": "ULH",
      "icao_code": "OEAO",
      "airport_name": "Prince Abdul Majeed bin Abdulaziz Domestic Airport"
    },
    {
      "city": "Al-ahsa",
      "country": "Saudi Arabia",
      "IATA": "HOF",
      "icao_code": "OEAH",
      "airport_name": "Al-Ahsa International Airport"
    },
    {
      "city": "Alakanuk",
      "country": "United States",
      "IATA": "AUK",
      "icao_code": "PAUK",
      "airport_name": "Alakanuk Airport"
    },
    {
      "city": "Alameda",
      "country": "United States",
      "IATA": "NGZ",
      "airport_name": "Alameda Naval Air Station"
    },
    {
      "city": "Alamogordo",
      "country": "United States",
      "IATA": "ALM",
      "icao_code": "KALM",
      "airport_name": "Alamogordo-White Sands Regional Airport"
    },
    {
      "city": "Alamogordo",
      "country": "United States",
      "IATA": "HMN",
      "airport_name": "Holloman Air Force Base"
    },
    {
      "city": "Alamosa",
      "country": "United States",
      "IATA": "ALS",
      "icao_code": "KALS",
      "airport_name": "San Luis Valley Regional Airport"
    },
    {
      "city": "Alanya",
      "country": "Turkey",
      "IATA": "GZP",
      "icao_code": "LTFG",
      "airport_name": "Gazipaşa-Alanya Airport "
    },
    {
      "city": "Albacete",
      "country": "Spain",
      "IATA": "ABC",
      "icao_code": "LEAB",
      "airport_name": "Albacete Airport"
    },
    {
      "city": "Albany",
      "country": "Australia",
      "IATA": "ALH",
      "icao_code": "YABA",
      "airport_name": "Albany Regional Airport"
    },
    {
      "city": "Albany",
      "country": "United States",
      "IATA": "ABY",
      "icao_code": "KABY",
      "airport_name": "Southwest Georgia Regional Airport"
    },
    {
      "city": "Albany",
      "country": "United States",
      "IATA": "ALB",
      "icao_code": "KALB",
      "airport_name": "Albany International Airport"
    },
    {
      "city": "Albenga",
      "country": "Italy",
      "IATA": "ALL",
      "icao_code": "LIMG",
      "airport_name": "Albenga Airport"
    },
    {
      "city": "Albert",
      "country": "France",
      "IATA": "BYF",
      "airport_name": "Albert – Picardie Airport"
    },
    {
      "city": "Albi",
      "country": "France",
      "IATA": "LBI",
      "icao_code": "LFCI",
      "airport_name": " airfield of Albi - Le Sequestre"
    },
    {
      "city": "Albina",
      "country": "Suriname",
      "IATA": "ABN",
      "airport_name": "Albina Airport"
    },
    {
      "city": "Albuquerque",
      "country": "United States",
      "IATA": "ABQ",
      "icao_code": "KABQ",
      "airport_name": "Albuquerque International Sunport"
    },
    {
      "city": "Albury",
      "country": "Australia",
      "IATA": "ABX",
      "icao_code": "YMAY",
      "airport_name": "Albury Airport"
    },
    {
      "city": "Aldan",
      "country": "Russia",
      "IATA": "ADH",
      "icao_code": "UEEA",
      "airport_name": "Aldan Airport"
    },
    {
      "city": "Alderney",
      "country": "Guernsey",
      "IATA": "ACI",
      "icao_code": "EGJA",
      "airport_name": "Alderney Airport"
    },
    {
      "city": "Aleknagik",
      "country": "United States",
      "IATA": "WKK",
      "airport_name": "Aleknagik Airport"
    },
    {
      "city": "Aleppo",
      "country": "Syria",
      "IATA": "ALP",
      "icao_code": "OSAP",
      "airport_name": "Aleppo International Airport "
    },
    {
      "city": "Alert",
      "country": "Canada",
      "IATA": "YLT",
      "icao_code": "CYLT",
      "airport_name": "Alert Airport"
    },
    {
      "city": "Alert Bay",
      "country": "Canada",
      "IATA": "YAL",
      "icao_code": "CYAL",
      "airport_name": "Alert Bay Airport"
    },
    {
      "city": "Alesund",
      "country": "Norway",
      "IATA": "AES",
      "icao_code": "ENAL",
      "airport_name": "Ålesund Airport, Vigra"
    },
    {
      "city": "Alexander Bay",
      "country": "South Africa",
      "IATA": "ALJ",
      "icao_code": "FAAB",
      "airport_name": "Alexander Bay Airport"
    },
    {
      "city": "Alexander City",
      "country": "United States",
      "IATA": "ALX",
      "icao_code": "ALX_",
      "airport_name": "Thomas C. Russell Field"
    },
    {
      "city": "Alexandra",
      "country": "New Zealand",
      "IATA": "ALR",
      "icao_code": "NZLX",
      "airport_name": "Alexandra Airport"
    },
    {
      "city": "Alexandria",
      "country": "United States",
      "IATA": "AXN",
      "icao_code": "KAXN",
      "airport_name": "Alexandria Municipal Airport"
    },
    {
      "city": "Alexandria",
      "country": "United States",
      "IATA": "AEX",
      "icao_code": "KAEX",
      "airport_name": "Alexandria International Airport"
    },
    {
      "city": "Alexandria",
      "country": "United States",
      "IATA": "ESF",
      "icao_code": "KESF",
      "airport_name": "Esler Regional Airport"
    },
    {
      "city": "Alexandria",
      "country": "Egypt",
      "IATA": "HBE",
      "icao_code": "HEBA",
      "airport_name": "Borg El Arab Airport"
    },
    {
      "city": "Alexandria",
      "country": "Egypt",
      "IATA": "ALY",
      "icao_code": "HEAX",
      "airport_name": "Alexandria International Airport"
    },
    {
      "city": "Alexandroupolis",
      "country": "Greece",
      "IATA": "AXD",
      "icao_code": "LGAL",
      "airport_name": "Alexandroupolis Airport"
    },
    {
      "city": "Algeciras",
      "country": "Spain",
      "IATA": "AEI",
      "airport_name": "Algeciras Heliport"
    },
    {
      "city": "Alghero",
      "country": "Italy",
      "IATA": "AHO",
      "icao_code": "LIEA",
      "airport_name": "Alghero - Riviera del Corallo Airport"
    },
    {
      "city": "Algier",
      "country": "Algeria",
      "IATA": "ALG",
      "icao_code": "DAAG",
      "airport_name": "Houari Boumedienne Airport"
    },
    {
      "city": "Alicante",
      "country": "Spain",
      "IATA": "ALC",
      "icao_code": "LEAL",
      "airport_name": "Alicante–Elche Airport"
    },
    {
      "city": "Alice",
      "country": "United States",
      "IATA": "ALI",
      "icao_code": "KALI",
      "airport_name": "Alice International Airport"
    },
    {
      "city": "Alice Springs",
      "country": "Australia",
      "IATA": "ASP",
      "icao_code": "YBAS",
      "airport_name": "Alice Springs Airport"
    },
    {
      "city": "Alice Town",
      "country": "Bahamas",
      "IATA": "BIM",
      "icao_code": "MYBS",
      "airport_name": "South Bimini Airport"
    },
    {
      "city": "Allahabad",
      "country": "India",
      "IATA": "IXD",
      "icao_code": "VIAL",
      "airport_name": "Allahabad Airport / Bamrauli Airport"
    },
    {
      "city": "Allakaket",
      "country": "United States",
      "IATA": "AET",
      "icao_code": "PFAL",
      "airport_name": "Allakaket Airport "
    },
    {
      "city": "Alldays",
      "country": "South Africa",
      "IATA": "ADY",
      "icao_code": "FAAL",
      "airport_name": "Alldays Airport"
    },
    {
      "city": "Allentown",
      "country": "United States",
      "IATA": "ABE",
      "icao_code": "KABE",
      "airport_name": "Lehigh Valley International Airport"
    },
    {
      "city": "Alliance",
      "country": "United States",
      "IATA": "AIA",
      "icao_code": "KAIA",
      "airport_name": "Alliance Municipal Airport"
    },
    {
      "city": "Alluitsup Paa",
      "country": "Greenland",
      "IATA": "LLU",
      "icao_code": "BGAP",
      "airport_name": "Alluitsup Paa Heliport"
    },
    {
      "city": "Alma",
      "country": "Canada",
      "IATA": "YTF",
      "icao_code": "CYTF",
      "airport_name": "Alma Airport"
    },
    {
      "city": "Alma-ata",
      "country": "Kazakhstan",
      "IATA": "ALA",
      "icao_code": "UAAA",
      "airport_name": "Almaty International Airport"
    },
    {
      "city": "Almeirim",
      "country": "Brazil",
      "IATA": "MEU",
      "icao_code": "SBMD",
      "airport_name": "Monte Dourado Airport"
    },
    {
      "city": "Almeria",
      "country": "Spain",
      "IATA": "LEI",
      "icao_code": "LEAM",
      "airport_name": "Almería Airport"
    },
    {
      "city": "Alofi",
      "country": "Niue",
      "IATA": "IUE",
      "icao_code": "NIUE",
      "airport_name": "Niue International Airport"
    },
    {
      "city": "Along",
      "country": "India",
      "IATA": "IXV",
      "icao_code": "VEAN",
      "airport_name": "Along Airport"
    },
    {
      "city": "Alor Island",
      "country": "Indonesia",
      "IATA": "ARD",
      "icao_code": "WATM",
      "airport_name": "Alor Island Airport"
    },
    {
      "city": "Alor Setar",
      "country": "Malaysia",
      "IATA": "AOR",
      "icao_code": "WMKA",
      "airport_name": "Sultan Abdul Halim Airport"
    },
    {
      "city": "Alpena",
      "country": "United States",
      "IATA": "APN",
      "icao_code": "KAPN",
      "airport_name": "Alpena County Regional Airport"
    },
    {
      "city": "Alpha",
      "country": "Australia",
      "IATA": "ABH",
      "icao_code": "YAPH",
      "airport_name": "Alpha Airport"
    },
    {
      "city": "Alpine",
      "country": "United States",
      "IATA": "ALE",
      "icao_code": "KE38",
      "airport_name": "Alpine-Casparis Municipal Airport "
    },
    {
      "city": "Alta",
      "country": "Norway",
      "IATA": "ALF",
      "icao_code": "ENAT",
      "airport_name": "Alta Airport"
    },
    {
      "city": "Alta Floresta",
      "country": "Brazil",
      "IATA": "AFL",
      "icao_code": "SBAT",
      "airport_name": "Alta Floresta Airport"
    },
    {
      "city": "Altai",
      "country": "Mongolia",
      "IATA": "LTI",
      "icao_code": "ZMAT",
      "airport_name": "Altai Airport"
    },
    {
      "city": "Altamira",
      "country": "Brazil",
      "IATA": "ATM",
      "icao_code": "SBHT",
      "airport_name": "Altamira Airport"
    },
    {
      "city": "Altay",
      "country": "China",
      "IATA": "AAT",
      "icao_code": "ZWAT",
      "airport_name": "Altay Airport"
    },
    {
      "city": "Altenburg",
      "country": "Germany",
      "IATA": "AOC",
      "icao_code": "EDAC",
      "airport_name": "Leipzig-Altenburg Airport"
    },
    {
      "city": "Altenrhein",
      "country": "Switzerland",
      "IATA": "ACH",
      "icao_code": "LSZR",
      "airport_name": "St. Gallen Altenrhein Airport"
    },
    {
      "city": "Alto Rio Senguer",
      "country": "Argentina",
      "IATA": "ARR",
      "icao_code": "SAVR",
      "airport_name": "Alto Río Senguer Airport"
    },
    {
      "city": "Alton/St Louis",
      "country": "United States",
      "IATA": "ALN",
      "icao_code": "KALN",
      "airport_name": "St. Louis Regional Airport"
    },
    {
      "city": "Altoona",
      "country": "United States",
      "IATA": "AOO",
      "icao_code": "KAOO",
      "airport_name": "Altoona-Blair County Airport"
    },
    {
      "city": "Altus",
      "country": "United States",
      "IATA": "LTS",
      "airport_name": "Altus Air Force Base"
    },
    {
      "city": "Alula",
      "country": "Somalia",
      "IATA": "ALU",
      "icao_code": "HCMA",
      "airport_name": "Alula Airport"
    },
    {
      "city": "Alverca",
      "country": "Portugal",
      "IATA": "AVR",
      "airport_name": "Alverca Air Base"
    },
    {
      "city": "Alxa Left Banner",
      "country": "China",
      "IATA": "AXF",
      "icao_code": "ZBAL",
      "airport_name": "Alxa Left Banner Bayanhot Airport"
    },
    {
      "city": "Alxa Right Banner",
      "country": "China",
      "IATA": "RHT",
      "airport_name": "Alxa Right Banner Badanjilin Airport"
    },
    {
      "city": "Am Timan",
      "country": "Chad",
      "IATA": "AMC",
      "icao_code": "FTTN",
      "airport_name": "Am Timan Airport"
    },
    {
      "city": "Amakusa",
      "country": "Japan",
      "IATA": "AXJ",
      "icao_code": "RJDA",
      "airport_name": "Amakusa Airfield "
    },
    {
      "city": "Amami",
      "country": "Japan",
      "IATA": "ASJ",
      "icao_code": "RJKA",
      "airport_name": "Amami Airport"
    },
    {
      "city": "Amarillo",
      "country": "United States",
      "IATA": "AMA",
      "icao_code": "KAMA",
      "airport_name": "Rick Husband Amarillo International Airport"
    },
    {
      "city": "Amata",
      "country": "Australia",
      "IATA": "AMT",
      "icao_code": "YAMT",
      "airport_name": "Amata Airport"
    },
    {
      "city": "Ambato",
      "country": "Ecuador",
      "IATA": "ATF",
      "icao_code": "SEAM",
      "airport_name": "Chachoan Airport"
    },
    {
      "city": "Ambatondrazaka",
      "country": "Madagascar",
      "IATA": "WAM",
      "icao_code": "FMMZ",
      "airport_name": "Ambatondrazaka Airport"
    },
    {
      "city": "Amberley",
      "country": "Australia",
      "IATA": "ABM",
      "icao_code": "YBAM",
      "airport_name": "Northern Peninsula Airport"
    },
    {
      "city": "Ambilobe",
      "country": "Madagascar",
      "IATA": "AMB",
      "airport_name": "Ambilobe Airport"
    },
    {
      "city": "Ambler",
      "country": "United States",
      "IATA": "ABL",
      "icao_code": "PAFM",
      "airport_name": "Ambler Airport "
    },
    {
      "city": "Amboseli National Park",
      "country": "Kenya",
      "IATA": "ASV",
      "icao_code": "HKAM",
      "airport_name": "Amboseli Airport"
    },
    {
      "city": "Ambryn Island",
      "country": "Vanuatu",
      "IATA": "ULB",
      "icao_code": "NVSU",
      "airport_name": "Ulei Airport"
    },
    {
      "city": "Amderma",
      "country": "Russia",
      "IATA": "AMV",
      "icao_code": "ULDD",
      "airport_name": "Amderma Airport"
    },
    {
      "city": "Amilcar Cabral",
      "country": "Cape Verde",
      "IATA": "SID",
      "icao_code": "GVAC",
      "airport_name": "Amílcar Cabral International Airport"
    },
    {
      "city": "Amman",
      "country": "Jordan",
      "IATA": "ADJ",
      "icao_code": "OJAM",
      "airport_name": "Amman Civil Airport"
    },
    {
      "city": "Amman",
      "country": "Jordan",
      "IATA": "AMM",
      "icao_code": "OJAI",
      "airport_name": "Queen Alia International Airport"
    },
    {
      "city": "Amos",
      "country": "Canada",
      "IATA": "YEY",
      "icao_code": "CYEY",
      "airport_name": "Amos/Magny Airport"
    },
    {
      "city": "Ampampamena",
      "country": "Madagascar",
      "IATA": "IVA",
      "airport_name": "Ambanja Airport"
    },
    {
      "city": "Amritsar",
      "country": "India",
      "IATA": "ATQ",
      "icao_code": "VIAR",
      "airport_name": "Sri Guru Ram Dass Jee International Airport"
    },
    {
      "city": "Amsterdam",
      "country": "Netherlands",
      "IATA": "AMS",
      "icao_code": "EHAM",
      "airport_name": "Amsterdam Airport Schiphol"
    },
    {
      "city": "Anaa",
      "country": "French Polynesia",
      "IATA": "AAA",
      "icao_code": "NTGA",
      "airport_name": "Anaa Airport"
    },
    {
      "city": "Anaco",
      "country": "Venezuela",
      "IATA": "AAO",
      "airport_name": "Anaco Airport"
    },
    {
      "city": "Anadyr",
      "country": "Russia",
      "IATA": "DYR",
      "icao_code": "UHMA",
      "airport_name": "Ugolny Airport"
    },
    {
      "city": "Anahim Lake",
      "country": "Canada",
      "IATA": "YAA",
      "icao_code": "CAJ4",
      "airport_name": "Anahim Lake Airport"
    },
    {
      "city": "Anaktuvuk Pass",
      "country": "United States",
      "IATA": "AKP",
      "icao_code": "PAKP",
      "airport_name": "Anaktuvuk Pass Airport"
    },
    {
      "city": "Analalava",
      "country": "Madagascar",
      "IATA": "HVA",
      "icao_code": "FMNL",
      "airport_name": "Analalava Airport"
    },
    {
      "city": "Anambas Islands",
      "country": "Indonesia",
      "IATA": "MWK",
      "icao_code": "WIOM",
      "airport_name": "Matak Airport"
    },
    {
      "city": "Anapa",
      "country": "Russia",
      "IATA": "AAQ",
      "icao_code": "URKA",
      "airport_name": "Anapa Airport"
    },
    {
      "city": "Anchorage",
      "country": "United States",
      "IATA": "ANC",
      "icao_code": "PANC",
      "airport_name": "Ted Stevens Anchorage International Airport"
    },
    {
      "city": "Anchorage",
      "country": "United States",
      "IATA": "EDF",
      "airport_name": "Elmendorf Air Force Base"
    },
    {
      "city": "Anchorage",
      "country": "United States",
      "IATA": "MRI",
      "airport_name": "Merrill Field Airport"
    },
    {
      "city": "Ancona",
      "country": "Italy",
      "IATA": "AOI",
      "icao_code": "LIPY",
      "airport_name": "Ancona Falconara Airport"
    },
    {
      "city": "Andahuaylas",
      "country": "Peru",
      "IATA": "ANS",
      "icao_code": "SPHY",
      "airport_name": "Andahuaylas Airport"
    },
    {
      "city": "Andapa",
      "country": "Madagascar",
      "IATA": "ZWA",
      "icao_code": "FMND",
      "airport_name": "Andapa Airport"
    },
    {
      "city": "Andersen",
      "country": "United States",
      "IATA": "AND",
      "icao_code": "KAND",
      "airport_name": "Anderson Regional Airport"
    },
    {
      "city": "Andersen",
      "country": "Guam",
      "IATA": "UAM",
      "icao_code": "PGUA",
      "airport_name": "Andersen Air Force Base"
    },
    {
      "city": "Andizhan",
      "country": "Uzbekistan",
      "IATA": "AZN",
      "icao_code": "UTKA",
      "airport_name": "Andizhan Airport"
    },
    {
      "city": "Andoas",
      "country": "Peru",
      "IATA": "AOP",
      "airport_name": "Alferez FAP Alfredo Vladimir Sara Bauer Airport"
    },
    {
      "city": "Andoya",
      "country": "Norway",
      "IATA": "ANX",
      "icao_code": "ENAN",
      "airport_name": "Andøya Airport, Andenes"
    },
    {
      "city": "Andravida",
      "country": "Greece",
      "IATA": "PYR",
      "icao_code": "LGAD",
      "airport_name": "Andravida Air Base"
    },
    {
      "city": "Andros",
      "country": "Bahamas",
      "IATA": "COX",
      "airport_name": "South Andros Airport"
    },
    {
      "city": "Andros Town",
      "country": "Bahamas",
      "IATA": "ASD",
      "icao_code": "MYAF",
      "airport_name": "Andros Town International Airport"
    },
    {
      "city": "Anegada",
      "country": "British Virgin Islands",
      "IATA": "NGD",
      "airport_name": "Auguste George Airport"
    },
    {
      "city": "Anelghowhat",
      "country": "Vanuatu",
      "IATA": "AUY",
      "icao_code": "NVVA",
      "airport_name": "Anatom Airport"
    },
    {
      "city": "Angeles City",
      "country": "Philippines",
      "IATA": "CRK",
      "icao_code": "RPLC",
      "airport_name": "Clark International Airport"
    },
    {
      "city": "Angelsey",
      "country": "United Kingdom",
      "IATA": "VLY",
      "icao_code": "EGOV",
      "airport_name": "Anglesey Airport"
    },
    {
      "city": "Angers/Marcé",
      "country": "France",
      "IATA": "ANE",
      "icao_code": "LFJR",
      "airport_name": "Angers – Loire Airport"
    },
    {
      "city": "Angleton",
      "country": "United States",
      "IATA": "LJN",
      "icao_code": "KLBX",
      "airport_name": "Texas Gulf Coast Regional Airport"
    },
    {
      "city": "Angling Lake",
      "country": "Canada",
      "IATA": "YAX",
      "icao_code": "CKB6",
      "airport_name": "Lac Du Bonnet Airport"
    },
    {
      "city": "Angola",
      "country": "United States",
      "IATA": "ANQ",
      "icao_code": "KANQ",
      "airport_name": "Tri-State Steuben County Airport"
    },
    {
      "city": "Angoon",
      "country": "United States",
      "IATA": "AGN",
      "icao_code": "PAGN",
      "airport_name": "Angoon Seaplane Base"
    },
    {
      "city": "Angouleme",
      "country": "France",
      "IATA": "ANG",
      "icao_code": "LFBU",
      "airport_name": "Angoulême – Cognac International Airport"
    },
    {
      "city": "Aniak",
      "country": "United States",
      "IATA": "ANI",
      "icao_code": "PANI",
      "airport_name": "Aniak Airport"
    },
    {
      "city": "Aniwa",
      "country": "Vanuatu",
      "IATA": "AWD",
      "icao_code": "NVVB",
      "airport_name": "Aniwa Airport"
    },
    {
      "city": "Anjouan",
      "country": "Comoros",
      "IATA": "AJN",
      "icao_code": "FMCV",
      "airport_name": "Ouani Airport"
    },
    {
      "city": "Ankang",
      "country": "China",
      "IATA": "AKA",
      "icao_code": "ZLAK",
      "airport_name": "Ankang Wulipu Airport"
    },
    {
      "city": "Ankara",
      "country": "Turkey",
      "IATA": "ANK",
      "icao_code": "LTAD",
      "airport_name": "Etimesgut Air Base"
    },
    {
      "city": "Ankara",
      "country": "Turkey",
      "IATA": "ESB",
      "icao_code": "LTAC",
      "airport_name": "Esenboğa International Airport"
    },
    {
      "city": "Ankavandra",
      "country": "Madagascar",
      "IATA": "JVA",
      "airport_name": "Ankavandra Airport"
    },
    {
      "city": "Ann",
      "country": "Burma",
      "IATA": "VBA",
      "airport_name": "Ann Airport"
    },
    {
      "city": "Ann Arbor",
      "country": "United States",
      "IATA": "ARB",
      "icao_code": "KARB",
      "airport_name": "Ann Arbor Municipal Airport"
    },
    {
      "city": "Annaba",
      "country": "Algeria",
      "IATA": "AAE",
      "icao_code": "DABB",
      "airport_name": "Rabah Bitat Airport "
    },
    {
      "city": "Annai",
      "country": "Guyana",
      "IATA": "NAI",
      "icao_code": "SYAN",
      "airport_name": "Annai Airport"
    },
    {
      "city": "Annapolis",
      "country": "United States",
      "IATA": "ANP",
      "icao_code": "KANP",
      "airport_name": "Lee Airport"
    },
    {
      "city": "Annecy",
      "country": "France",
      "IATA": "NCY",
      "icao_code": "LFLP",
      "airport_name": "Annecy – Haute-Savoie – Mont Blanc Airport"
    },
    {
      "city": "Annemasse",
      "country": "France",
      "IATA": "QNJ",
      "icao_code": "LFLI",
      "airport_name": "Annemasse Airport"
    },
    {
      "city": "Annette Island",
      "country": "United States",
      "IATA": "ANN",
      "icao_code": "PANT",
      "airport_name": "Annette Island Airport"
    },
    {
      "city": "Anniston",
      "country": "United States",
      "IATA": "ANB",
      "icao_code": "KANB",
      "airport_name": "Anniston Regional Airport"
    },
    {
      "city": "Anqing",
      "country": "China",
      "IATA": "AQG",
      "icao_code": "ZSAQ",
      "airport_name": "Anqing Tianzhushan Airport"
    },
    {
      "city": "Anshan",
      "country": "China",
      "IATA": "AOG",
      "icao_code": "ZYAS",
      "airport_name": "Anshan Teng'ao Airport"
    },
    {
      "city": "Anshun",
      "country": "China",
      "IATA": "AVA",
      "icao_code": "ZUAS",
      "airport_name": "Anshun Huangguoshu Airport"
    },
    {
      "city": "Anta",
      "country": "Peru",
      "IATA": "ATA",
      "icao_code": "SPHZ",
      "airport_name": "Comandante FAP Germán Arias Graziani Airport"
    },
    {
      "city": "Antalaha",
      "country": "Madagascar",
      "IATA": "ANM",
      "icao_code": "FMNH",
      "airport_name": "Antsirabato Airport"
    },
    {
      "city": "Antalya",
      "country": "Turkey",
      "IATA": "AYT",
      "icao_code": "LTAI",
      "airport_name": "Antalya Airport"
    },
    {
      "city": "Antananarivo",
      "country": "Madagascar",
      "IATA": "TNR",
      "icao_code": "FMMI",
      "airport_name": "Ivato International Airport"
    },
    {
      "city": "Antigua",
      "country": "Antigua and Barbuda",
      "IATA": "ANU",
      "icao_code": "TAPA",
      "airport_name": "V.C. Bird International Airport"
    },
    {
      "city": "Antofagasta",
      "country": "Chile",
      "IATA": "ANF",
      "icao_code": "SCFA",
      "airport_name": "Cerro Moreno International Airport"
    },
    {
      "city": "Antsalova",
      "country": "Madagascar",
      "IATA": "WAQ",
      "airport_name": "Antsalova Airport"
    },
    {
      "city": "Antsirabe",
      "country": "Madagascar",
      "IATA": "ATJ",
      "icao_code": "FMME",
      "airport_name": "Antsirabe Airport"
    },
    {
      "city": "Antsiranana",
      "country": "Madagascar",
      "IATA": "DIE",
      "icao_code": "FMNA",
      "airport_name": "Arrachart Airport"
    },
    {
      "city": "Antsohihy",
      "country": "Madagascar",
      "IATA": "WAI",
      "airport_name": "Ambalabe Airport"
    },
    {
      "city": "Antwerp",
      "country": "Belgium",
      "IATA": "ANR",
      "icao_code": "EBAW",
      "airport_name": "Antwerp International Airport"
    },
    {
      "city": "Anuradhapura",
      "country": "Sri Lanka",
      "IATA": "ACJ",
      "icao_code": "VCCA",
      "airport_name": "Anuradhapura Airport"
    },
    {
      "city": "Anvik",
      "country": "United States",
      "IATA": "ANV",
      "icao_code": "PANV",
      "airport_name": "Anvik Airport"
    },
    {
      "city": "Aomori",
      "country": "Japan",
      "IATA": "AOJ",
      "icao_code": "RJSA",
      "airport_name": "Aomori Airport"
    },
    {
      "city": "Aosta",
      "country": "Italy",
      "IATA": "AOT",
      "icao_code": "LIMW",
      "airport_name": "Aosta Valley Airport"
    },
    {
      "city": "Apalachicola",
      "country": "United States",
      "IATA": "AAF",
      "icao_code": "KAAF",
      "airport_name": "Apalachicola Regional Airport"
    },
    {
      "city": "Apataki",
      "country": "French Polynesia",
      "IATA": "APK",
      "icao_code": "NTGD",
      "airport_name": "Apataki Airport"
    },
    {
      "city": "Apatity",
      "country": "Russia",
      "IATA": "KVK",
      "icao_code": "ULMK",
      "airport_name": "Kirovsk-Apatity Airport"
    },
    {
      "city": "Apia",
      "country": "Samoa",
      "IATA": "FGI",
      "icao_code": "NSAP",
      "airport_name": "Fagali'i Airport"
    },
    {
      "city": "Apiay",
      "country": "Colombia",
      "IATA": "API",
      "airport_name": "Captain Luis F. Gómez Niño Air Base"
    },
    {
      "city": "Appleton",
      "country": "United States",
      "IATA": "ATW",
      "icao_code": "KATW",
      "airport_name": "Appleton International Airport"
    },
    {
      "city": "Aqaba",
      "country": "Jordan",
      "IATA": "AQJ",
      "icao_code": "OJAQ",
      "airport_name": "King Hussein International Airport"
    },
    {
      "city": "Aracaju",
      "country": "Brazil",
      "IATA": "AJU",
      "icao_code": "SBAR",
      "airport_name": "Santa Maria Airport"
    },
    {
      "city": "Aracatuba",
      "country": "Brazil",
      "IATA": "ARU",
      "icao_code": "SBAU",
      "airport_name": "Dario Guarita–Araçatuba State Airport "
    },
    {
      "city": "Arad",
      "country": "Romania",
      "IATA": "ARW",
      "icao_code": "LRAR",
      "airport_name": "Arad International Airport"
    },
    {
      "city": "Araguaina",
      "country": "Brazil",
      "IATA": "AUX",
      "icao_code": "SWGN",
      "airport_name": "Araguaína Airport"
    },
    {
      "city": "Arak",
      "country": "Iran",
      "IATA": "AJK",
      "icao_code": "OIHR",
      "airport_name": "Arak Airport"
    },
    {
      "city": "Arandis",
      "country": "Namibia",
      "IATA": "ADI",
      "icao_code": "FYAR",
      "airport_name": "Arandis Airport"
    },
    {
      "city": "Arapiraca",
      "country": "Brazil",
      "IATA": "APQ",
      "icao_code": "SNAL",
      "airport_name": "Arapiraca Airport"
    },
    {
      "city": "Arar",
      "country": "Saudi Arabia",
      "IATA": "RAE",
      "icao_code": "OERR",
      "airport_name": "Arar Domestic Airport"
    },
    {
      "city": "Araracuara",
      "country": "Colombia",
      "IATA": "ACR",
      "icao_code": "SKAC",
      "airport_name": "Araracuara Airport"
    },
    {
      "city": "Araracuara",
      "country": "Brazil",
      "IATA": "AQA",
      "icao_code": "SBAQ",
      "airport_name": "Bartolomeu de Gusmão–Araraquara State Airport"
    },
    {
      "city": "Arathusa",
      "country": "South Africa",
      "IATA": "ASS",
      "airport_name": "Arathusa Safari Lodge Airport"
    },
    {
      "city": "Arauca",
      "country": "Colombia",
      "IATA": "AUC",
      "icao_code": "SKUC",
      "airport_name": "Santiago Pérez Quiroz  Airport"
    },
    {
      "city": "Araxa",
      "country": "Brazil",
      "IATA": "AAX",
      "icao_code": "SBAX",
      "airport_name": "Araxa Airport "
    },
    {
      "city": "Arba Minch",
      "country": "Ethiopia",
      "IATA": "AMH",
      "icao_code": "HAAM",
      "airport_name": "Arba Minch Airport"
    },
    {
      "city": "Arcata CA",
      "country": "United States",
      "IATA": "ACV",
      "icao_code": "KACV",
      "airport_name": "Arcata-Eureka Airport"
    },
    {
      "city": "Arctic Bay",
      "country": "Canada",
      "IATA": "YAB",
      "airport_name": "Arctic Bay Airport"
    },
    {
      "city": "Arctic Village",
      "country": "United States",
      "IATA": "ARC",
      "icao_code": "PARC",
      "airport_name": "Arctic Village Airport"
    },
    {
      "city": "Ardabil",
      "country": "Iran",
      "IATA": "ADU",
      "icao_code": "OITL",
      "airport_name": "Ardabil Airport"
    },
    {
      "city": "Ardmore",
      "country": "United States",
      "IATA": "ADM",
      "icao_code": "KADM",
      "airport_name": "Ardmore Municipal Airport"
    },
    {
      "city": "Ardmore",
      "country": "New Zealand",
      "IATA": "AMZ",
      "icao_code": "NZAR",
      "airport_name": "Ardmore Airport"
    },
    {
      "city": "Arecibo",
      "country": "Puerto Rico",
      "IATA": "ARE",
      "icao_code": "TJAB",
      "airport_name": "Antonio (Nery) Juarbe Pol Airport"
    },
    {
      "city": "Arequipa",
      "country": "Peru",
      "IATA": "AQP",
      "icao_code": "SPQU",
      "airport_name": "Rodríguez Ballón International Airport"
    },
    {
      "city": "Argyle",
      "country": "Australia",
      "IATA": "PUG",
      "icao_code": "YPAG",
      "airport_name": "Port Augusta Airport"
    },
    {
      "city": "Argyle",
      "country": "Australia",
      "IATA": "GYL",
      "icao_code": "YARG",
      "airport_name": "Argyle Airport"
    },
    {
      "city": "Arica",
      "country": "Chile",
      "IATA": "ARI",
      "icao_code": "SCAR",
      "airport_name": "Chacalluta International Airport"
    },
    {
      "city": "Aripuana",
      "country": "Brazil",
      "IATA": "AIR",
      "icao_code": "SWRP",
      "airport_name": "Aripuanã Airport"
    },
    {
      "city": "Arkalyk",
      "country": "Kazakhstan",
      "IATA": "AYK",
      "icao_code": "UAUR",
      "airport_name": "Arkalyk Airport"
    },
    {
      "city": "Arkhangelsk",
      "country": "Russia",
      "IATA": "LDG",
      "icao_code": "ULAL",
      "airport_name": "Leshukonskoye Airport"
    },
    {
      "city": "Arkhangelsk",
      "country": "Russia",
      "IATA": "ARH",
      "icao_code": "ULAA",
      "airport_name": "Talagi Airport"
    },
    {
      "city": "Armenia",
      "country": "Colombia",
      "IATA": "AXM",
      "icao_code": "SKAR",
      "airport_name": "El Edén International Airport"
    },
    {
      "city": "Armidale",
      "country": "Australia",
      "IATA": "ARM",
      "icao_code": "YARM",
      "airport_name": "Armidale Airport"
    },
    {
      "city": "Armstrong",
      "country": "Canada",
      "IATA": "YYW",
      "icao_code": "CYYW",
      "airport_name": "Armstrong Airport"
    },
    {
      "city": "Arorae",
      "country": "Kiribati",
      "IATA": "AIS",
      "icao_code": "NGTR",
      "airport_name": "Arorae Island Airport"
    },
    {
      "city": "Arrecife",
      "country": "Spain",
      "IATA": "ACE",
      "icao_code": "GCRR",
      "airport_name": "Lanzarote Airport"
    },
    {
      "city": "Arthur's Town",
      "country": "Bahamas",
      "IATA": "ATC",
      "icao_code": "MYCA",
      "airport_name": "Arthur's Town Airport"
    },
    {
      "city": "Artigas",
      "country": "Uruguay",
      "IATA": "ATI",
      "icao_code": "SUAG",
      "airport_name": "Artigas International Airport"
    },
    {
      "city": "Arua",
      "country": "Uganda",
      "IATA": "RUA",
      "icao_code": "HUAR",
      "airport_name": "Arua Airport"
    },
    {
      "city": "Arusha",
      "country": "Tanzania",
      "IATA": "ARK",
      "icao_code": "HTAR",
      "airport_name": "Arusha Airport"
    },
    {
      "city": "Arutua",
      "country": "French Polynesia",
      "IATA": "AXR",
      "icao_code": "NTGU",
      "airport_name": "Arutua Airport"
    },
    {
      "city": "Arvaikheer",
      "country": "Mongolia",
      "IATA": "AVK",
      "icao_code": "ZMAH",
      "airport_name": "Arvaikheer Airport"
    },
    {
      "city": "Arvidsjaur",
      "country": "Sweden",
      "IATA": "AJR",
      "icao_code": "ESNX",
      "airport_name": "Arvidsjaur Airport"
    },
    {
      "city": "Arxan",
      "country": "China",
      "IATA": "YIE",
      "airport_name": "Arxan Yi'ershi Airport"
    },
    {
      "city": "Asaba",
      "country": "Nigeria",
      "IATA": "ABB",
      "icao_code": "DNAS",
      "airport_name": "Royal Air Force Abingdon"
    },
    {
      "city": "Asahikawa",
      "country": "Japan",
      "IATA": "AKJ",
      "icao_code": "RJEC",
      "airport_name": "Asahikawa Airport"
    },
    {
      "city": "Asheville",
      "country": "United States",
      "IATA": "AVL",
      "icao_code": "KAVL",
      "airport_name": "Asheville Regional Airport"
    },
    {
      "city": "Ashkhabad",
      "country": "Turkmenistan",
      "IATA": "ASB",
      "icao_code": "UTAA",
      "airport_name": "Ashgabat International  Airport"
    },
    {
      "city": "Asmara",
      "country": "Eritrea",
      "IATA": "ASM",
      "icao_code": "HHAS",
      "airport_name": "Asmara International Airport"
    },
    {
      "city": "Asosa",
      "country": "Ethiopia",
      "IATA": "ASO",
      "icao_code": "HASO",
      "airport_name": "Asosa Airport"
    },
    {
      "city": "Aspen",
      "country": "United States",
      "IATA": "ASE",
      "icao_code": "KASE",
      "airport_name": "Aspen-Pitkin County Airport "
    },
    {
      "city": "Assab",
      "country": "Eritrea",
      "IATA": "ASA",
      "icao_code": "HHSB",
      "airport_name": "Assab International Airport"
    },
    {
      "city": "Astoria",
      "country": "United States",
      "IATA": "AST",
      "icao_code": "KAST",
      "airport_name": "Astoria Regional Airport"
    },
    {
      "city": "Astrakhan",
      "country": "Russia",
      "IATA": "ASF",
      "icao_code": "URWA",
      "airport_name": "Narimanovo Airport "
    },
    {
      "city": "Astypalaia",
      "country": "Greece",
      "IATA": "JTY",
      "icao_code": "LGPL",
      "airport_name": "Astypalaia Island National Airport "
    },
    {
      "city": "Asuncion",
      "country": "Paraguay",
      "IATA": "ASU",
      "icao_code": "SGAS",
      "airport_name": "Silvio Pettirossi International Airport"
    },
    {
      "city": "Aswan",
      "country": "Egypt",
      "IATA": "ASW",
      "icao_code": "HESN",
      "airport_name": "Aswan International Airport"
    },
    {
      "city": "Asyut",
      "country": "Egypt",
      "IATA": "ATZ",
      "icao_code": "HEAT",
      "airport_name": "Assiut Airport"
    },
    {
      "city": "Ataq",
      "country": "Yemen",
      "IATA": "AXK",
      "icao_code": "ODAT",
      "airport_name": "Ataq Airport"
    },
    {
      "city": "Atar",
      "country": "Mauritania",
      "IATA": "ATR",
      "icao_code": "GQPA",
      "airport_name": "Atar International Airport"
    },
    {
      "city": "Atbara",
      "country": "Sudan",
      "IATA": "ATB",
      "icao_code": "HSAT",
      "airport_name": "Atbara Airport"
    },
    {
      "city": "Athens",
      "country": "United States",
      "IATA": "MMI",
      "icao_code": "KMMI",
      "airport_name": "McMinn County Airport"
    },
    {
      "city": "Athens",
      "country": "United States",
      "IATA": "ATO",
      "airport_name": "Gordon K. Bush Airport"
    },
    {
      "city": "Athens",
      "country": "United States",
      "IATA": "AHN",
      "icao_code": "KAHN",
      "airport_name": "Athens-Ben Epps Airport"
    },
    {
      "city": "Athens",
      "country": "Greece",
      "IATA": "HEW",
      "airport_name": "Ellinikon International Airport"
    },
    {
      "city": "Athens",
      "country": "Greece",
      "IATA": "ATH",
      "icao_code": "LGAV",
      "airport_name": "Athens International Airport \"Eleftherios Venizelos\""
    },
    {
      "city": "Atikokan",
      "country": "Canada",
      "IATA": "YIB",
      "icao_code": "CYIB",
      "airport_name": "Atikokan Municipal Airport"
    },
    {
      "city": "Atiu Island",
      "country": "Cook Islands",
      "IATA": "AIU",
      "icao_code": "NCAT",
      "airport_name": "Enua Airport"
    },
    {
      "city": "Atka",
      "country": "United States",
      "IATA": "AKB",
      "icao_code": "PAAK",
      "airport_name": "Atka Airport "
    },
    {
      "city": "Atlanta",
      "country": "United States",
      "IATA": "PDK",
      "icao_code": "KPDK",
      "airport_name": "DeKalb-Peachtree Airport"
    },
    {
      "city": "Atlanta",
      "country": "United States",
      "IATA": "FTY",
      "icao_code": "KFTY",
      "airport_name": "Fulton County Airport "
    },
    {
      "city": "Atlanta",
      "country": "United States",
      "IATA": "ATL",
      "icao_code": "KATL",
      "airport_name": "Hartsfield-Jackson Atlanta International Airport"
    },
    {
      "city": "Atlantic City",
      "country": "United States",
      "IATA": "ACY",
      "icao_code": "KACY",
      "airport_name": "Atlantic City International Airport"
    },
    {
      "city": "Atoifi",
      "country": "Solomon Islands",
      "IATA": "ATD",
      "icao_code": "AGAT",
      "airport_name": "Uru Harbour Airport"
    },
    {
      "city": "Atqasuk",
      "country": "United States",
      "IATA": "ATK",
      "icao_code": "PATQ",
      "airport_name": "Atqasuk Edward Burnell Sr. Memorial Airport"
    },
    {
      "city": "Atsugi",
      "country": "Japan",
      "IATA": "NJA",
      "icao_code": "RJTA",
      "airport_name": "Atsugi Naval Air Facility"
    },
    {
      "city": "Attawapiskat",
      "country": "Canada",
      "IATA": "YAT",
      "icao_code": "CYAT",
      "airport_name": "Attawapiskat Airport"
    },
    {
      "city": "Attopeu",
      "country": "Laos",
      "IATA": "AOU",
      "icao_code": "VLAP",
      "airport_name": "Attopeu Airport"
    },
    {
      "city": "Attu",
      "country": "Greenland",
      "IATA": "QGQ",
      "airport_name": "Attu Heliport"
    },
    {
      "city": "Atyrau",
      "country": "Kazakhstan",
      "IATA": "GUW",
      "icao_code": "UATG",
      "airport_name": "ATMA Atyrau Airport"
    },
    {
      "city": "Aubenas-vals-lanas",
      "country": "France",
      "IATA": "OBS",
      "icao_code": "LFHO",
      "airport_name": "Aubenas Aerodrome"
    },
    {
      "city": "Auburn",
      "country": "United States",
      "IATA": "AUO",
      "icao_code": "KAUO",
      "airport_name": "Auburn University Regional Airport"
    },
    {
      "city": "Auckland",
      "country": "New Zealand",
      "IATA": "AKL",
      "icao_code": "NZAA",
      "airport_name": "Auckland Airport"
    },
    {
      "city": "Augsburg",
      "country": "Germany",
      "IATA": "AGB",
      "icao_code": "EDMA",
      "airport_name": "Augsburg Airport"
    },
    {
      "city": "Augusta",
      "country": "United States",
      "IATA": "DNL",
      "icao_code": "KDNL",
      "airport_name": "Daniel Field "
    },
    {
      "city": "Augusta",
      "country": "United States",
      "IATA": "AUG",
      "icao_code": "KAUG",
      "airport_name": "Augusta State Airport"
    },
    {
      "city": "Auki",
      "country": "Solomon Islands",
      "IATA": "AKS",
      "icao_code": "AGGA",
      "airport_name": "Auki Gwaunaru'u Airport"
    },
    {
      "city": "Aupaluk",
      "country": "Canada",
      "IATA": "YPJ",
      "icao_code": "CYLA",
      "airport_name": "Aupaluk Airport"
    },
    {
      "city": "Aurangabad",
      "country": "India",
      "IATA": "IXU",
      "icao_code": "VAAU",
      "airport_name": "Aurangabad Airport "
    },
    {
      "city": "Aurillac",
      "country": "France",
      "IATA": "AUR",
      "icao_code": "LFLW",
      "airport_name": "Aurillac – Tronquières Airport"
    },
    {
      "city": "Aurukun",
      "country": "Australia",
      "IATA": "AUU",
      "icao_code": "YAUR",
      "airport_name": "Aurukun Airport"
    },
    {
      "city": "Austin",
      "country": "United States",
      "IATA": "AUS",
      "icao_code": "KAUS",
      "airport_name": "Austin-Bergstrom International Airport"
    },
    {
      "city": "Auxerre",
      "country": "France",
      "IATA": "AUF",
      "icao_code": "LFLA",
      "airport_name": "Auxerre – Branches Aerodrome"
    },
    {
      "city": "Avalon",
      "country": "Australia",
      "IATA": "AVV",
      "icao_code": "YMAV",
      "airport_name": "Avalon Airport"
    },
    {
      "city": "Avarua",
      "country": "Cook Islands",
      "IATA": "RAR",
      "icao_code": "NCRG",
      "airport_name": "Rarotonga International Airport"
    },
    {
      "city": "Aviano",
      "country": "Italy",
      "IATA": "AVB",
      "icao_code": "LIPA",
      "airport_name": "Aviano Air Base"
    },
    {
      "city": "Avignon",
      "country": "France",
      "IATA": "AVN",
      "icao_code": "LFMV",
      "airport_name": "Avignon – Provence Airport"
    },
    {
      "city": "Aviles",
      "country": "Spain",
      "IATA": "OVD",
      "icao_code": "LEAS",
      "airport_name": "Asturias Airport "
    },
    {
      "city": "Avon Park",
      "country": "United States",
      "IATA": "AVO",
      "icao_code": "KAVO",
      "airport_name": "Avon Park Executive Airport"
    },
    {
      "city": "Awasa",
      "country": "Ethiopia",
      "IATA": "AWA",
      "icao_code": "HALA",
      "airport_name": "Awasa Airport"
    },
    {
      "city": "Axum",
      "country": "Ethiopia",
      "IATA": "AXU",
      "icao_code": "HAAX",
      "airport_name": "Axum Airport"
    },
    {
      "city": "Ayacucho",
      "country": "Peru",
      "IATA": "AYP",
      "icao_code": "SPHO",
      "airport_name": "Coronel FAP Alfredo Mendivil Duarte Airport"
    },
    {
      "city": "Ayawasi",
      "country": "Indonesia",
      "IATA": "AYW",
      "airport_name": "Ayawasi Airport"
    },
    {
      "city": "Ayolas",
      "country": "Paraguay",
      "IATA": "AYO",
      "airport_name": "Juan De Ayolas Airport"
    },
    {
      "city": "BLUMENAU",
      "country": "Brazil",
      "IATA": "BNU",
      "icao_code": "SSBL",
      "airport_name": "Blumenau Airport"
    },
    {
      "city": "BRISTOL",
      "country": "United States",
      "IATA": "TRI",
      "icao_code": "KTRI",
      "airport_name": "Tri-Cities Regional Airport"
    },
    {
      "city": "Babelthuap",
      "country": "Palau",
      "IATA": "ROR",
      "icao_code": "PTRO",
      "airport_name": "Roman Tmetuchl International Airport"
    },
    {
      "city": "Babo",
      "country": "Indonesia",
      "IATA": "BXB",
      "icao_code": "WASO",
      "airport_name": "Babo Airport"
    },
    {
      "city": "Bacau",
      "country": "Romania",
      "IATA": "BCM",
      "icao_code": "LRBC",
      "airport_name": "George Enescu International Airport"
    },
    {
      "city": "Baco",
      "country": "Ethiopia",
      "IATA": "BCO",
      "icao_code": "HABC",
      "airport_name": "Baco Airport "
    },
    {
      "city": "Bacolod",
      "country": "Philippines",
      "IATA": "BCD",
      "icao_code": "RPVB",
      "airport_name": "Bacolod–Silay International Airport"
    },
    {
      "city": "Badajoz",
      "country": "Spain",
      "IATA": "BJZ",
      "icao_code": "LEBZ",
      "airport_name": "Badajoz Airport "
    },
    {
      "city": "Badu Island",
      "country": "Australia",
      "IATA": "BDD",
      "icao_code": "YBAU",
      "airport_name": "Badu Island Airport"
    },
    {
      "city": "Bafoussam",
      "country": "Cameroon",
      "IATA": "BFX",
      "icao_code": "FKKU",
      "airport_name": "Bafoussam Airport"
    },
    {
      "city": "Bagan",
      "country": "Burma",
      "IATA": "BPE",
      "airport_name": "Bagan Airport"
    },
    {
      "city": "Bagan",
      "country": "Burma",
      "IATA": "NYU",
      "icao_code": "VBNU",
      "airport_name": "Nyaung U Airport"
    },
    {
      "city": "Bage",
      "country": "Brazil",
      "IATA": "BGX",
      "icao_code": "SBBG",
      "airport_name": "Comandante Gustavo Kraemer International Airport"
    },
    {
      "city": "Baghdad",
      "country": "Iraq",
      "IATA": "BGW",
      "icao_code": "ORBI",
      "airport_name": "Baghdad International Airport"
    },
    {
      "city": "Baghdogra",
      "country": "India",
      "IATA": "IXB",
      "icao_code": "VEBD",
      "airport_name": "Bagdogra Airport"
    },
    {
      "city": "Baglung",
      "country": "Nepal",
      "IATA": "BGL",
      "icao_code": "VNBL",
      "airport_name": "Baglung Airport"
    },
    {
      "city": "Bagotville",
      "country": "Canada",
      "IATA": "YBG",
      "icao_code": "CYBG",
      "airport_name": "Canadian Forces Base Bagotville"
    },
    {
      "city": "Baguio",
      "country": "Philippines",
      "IATA": "BAG",
      "icao_code": "RPUB",
      "airport_name": "Loakan Airport"
    },
    {
      "city": "Bahar Dar",
      "country": "Ethiopia",
      "IATA": "BJR",
      "icao_code": "HABD",
      "airport_name": "Bahir Dar Airport"
    },
    {
      "city": "Bahawalpur",
      "country": "Pakistan",
      "IATA": "BHV",
      "icao_code": "OPBW",
      "airport_name": "Bahawalpur Airport"
    },
    {
      "city": "Bahia Blanca",
      "country": "Argentina",
      "IATA": "BHI",
      "icao_code": "SAZB",
      "airport_name": "Comandante Espora Airport"
    },
    {
      "city": "Bahia Solano",
      "country": "Colombia",
      "IATA": "BSC",
      "icao_code": "SKBS",
      "airport_name": "José Celestino Mutis Airport"
    },
    {
      "city": "Bahrain",
      "country": "Bahrain",
      "IATA": "BAH",
      "icao_code": "OBBI",
      "airport_name": "Bahrain International Airport"
    },
    {
      "city": "Baia Mare",
      "country": "Romania",
      "IATA": "BAY",
      "icao_code": "LRBM",
      "airport_name": "Baia Mare Airport "
    },
    {
      "city": "Baicheng",
      "country": "China",
      "IATA": "DBC",
      "icao_code": "ZYBA",
      "airport_name": "Baicheng Chang'an Airport"
    },
    {
      "city": "Baidoa",
      "country": "Somalia",
      "IATA": "BIB",
      "icao_code": "HCMB",
      "airport_name": "Baidoa Airport"
    },
    {
      "city": "Baie Comeau",
      "country": "Canada",
      "IATA": "YBC",
      "icao_code": "CYBC",
      "airport_name": "Baie-Comeau Airport"
    },
    {
      "city": "Baikonur",
      "country": "Kazakhstan",
      "IATA": "BXY",
      "airport_name": "Krayniy Airport"
    },
    {
      "city": "Baimuru",
      "country": "Papua New Guinea",
      "IATA": "VMU",
      "airport_name": "Baimuru Airport"
    },
    {
      "city": "Bainbridge",
      "country": "United States",
      "IATA": "BGE",
      "icao_code": "KBGE",
      "airport_name": "Decatur County Industrial Air Park"
    },
    {
      "city": "Bairnsdale",
      "country": "Australia",
      "IATA": "BSJ",
      "icao_code": "YBNS",
      "airport_name": "Bairnsdale Airport"
    },
    {
      "city": "Baise",
      "country": "China",
      "IATA": "AEB",
      "airport_name": "Baise (Bose) Bama Airport"
    },
    {
      "city": "Baishan",
      "country": "China",
      "IATA": "NBS",
      "airport_name": "Changbaishan Airport"
    },
    {
      "city": "Bajawa",
      "country": "Indonesia",
      "IATA": "BJW",
      "icao_code": "WATB",
      "airport_name": "Bajawa Airport"
    },
    {
      "city": "Bajhang",
      "country": "Nepal",
      "IATA": "BJH",
      "icao_code": "VNBG",
      "airport_name": "Bajhang Airport"
    },
    {
      "city": "Bajura",
      "country": "Nepal",
      "IATA": "BJU",
      "icao_code": "VNBR",
      "airport_name": "Bajura Airport"
    },
    {
      "city": "Bakalalan",
      "country": "Malaysia",
      "IATA": "BKM",
      "icao_code": "WBGQ",
      "airport_name": "Ba'kelalan Airport"
    },
    {
      "city": "Bakel",
      "country": "Senegal",
      "IATA": "BXE",
      "airport_name": "Bakel Airport"
    },
    {
      "city": "Baker City",
      "country": "United States",
      "IATA": "BKE",
      "icao_code": "KBKE",
      "airport_name": "Baker City Municipal Airport"
    },
    {
      "city": "Baker Lake",
      "country": "Canada",
      "IATA": "YBK",
      "icao_code": "CYBK",
      "airport_name": "Baker Lake Airport"
    },
    {
      "city": "Bakersfield",
      "country": "United States",
      "IATA": "BFL",
      "icao_code": "KBFL",
      "airport_name": "Meadows Field Airport"
    },
    {
      "city": "Bakhtaran",
      "country": "Iran",
      "IATA": "KSH",
      "icao_code": "OICC",
      "airport_name": "Shahid Ashrafi Esfahani Airport"
    },
    {
      "city": "Baku",
      "country": "Azerbaijan",
      "IATA": "ZXT",
      "airport_name": "Zabrat Airport"
    },
    {
      "city": "Baku",
      "country": "Azerbaijan",
      "IATA": "GYD",
      "icao_code": "UBBB",
      "airport_name": "Heydar Aliyev International Airport "
    },
    {
      "city": "Balakovo",
      "country": "Russia",
      "IATA": "BWO",
      "icao_code": "UWSB",
      "airport_name": "Balakovo Airport"
    },
    {
      "city": "Baler",
      "country": "Philippines",
      "IATA": "BQA",
      "icao_code": "RPUR",
      "airport_name": "Dr. Juan C. Angara Airport"
    },
    {
      "city": "Balikesir",
      "country": "Turkey",
      "IATA": "BZI",
      "icao_code": "LTBF",
      "airport_name": "Balikesir Airport "
    },
    {
      "city": "Balikesir Korfez",
      "country": "Turkey",
      "IATA": "EDO",
      "icao_code": "LTFD",
      "airport_name": "Balıkesir Koca Seyit Airport"
    },
    {
      "city": "Balikpapan",
      "country": "Indonesia",
      "IATA": "BPN",
      "icao_code": "WALL",
      "airport_name": "Sultan Aji Muhammad Sulaiman Airport"
    },
    {
      "city": "Balimo",
      "country": "Papua New Guinea",
      "IATA": "OPU",
      "airport_name": "Balimo Airport"
    },
    {
      "city": "Balkhash",
      "country": "Kazakhstan",
      "IATA": "BXH",
      "airport_name": "Balkhash Airport"
    },
    {
      "city": "Ballalae",
      "country": "Solomon Islands",
      "IATA": "BAS",
      "icao_code": "AGGE",
      "airport_name": "Ballalae Airport"
    },
    {
      "city": "Ballera",
      "country": "Australia",
      "IATA": "BBL",
      "icao_code": "YLLE",
      "airport_name": "Babolsar Airport"
    },
    {
      "city": "Ballina Byron Bay",
      "country": "Australia",
      "IATA": "BNK",
      "icao_code": "YBNA",
      "airport_name": "Ballina Byron Gateway  Airport"
    },
    {
      "city": "Balmaceda",
      "country": "Chile",
      "IATA": "BBA",
      "icao_code": "SCBA",
      "airport_name": "Balmaceda Airport"
    },
    {
      "city": "Baltimore",
      "country": "United States",
      "IATA": "MTN",
      "icao_code": "KMTN",
      "airport_name": "Martin State Airport"
    },
    {
      "city": "Baltimore",
      "country": "United States",
      "IATA": "BWI",
      "icao_code": "KBWI",
      "airport_name": "Baltimore/Washington International Thurgood Marshall Airport"
    },
    {
      "city": "Baltrum",
      "country": "Germany",
      "IATA": "BMR",
      "airport_name": "Airfield Baltrum"
    },
    {
      "city": "Bam",
      "country": "Iran",
      "IATA": "BXR",
      "icao_code": "OIKM",
      "airport_name": "Bam Airport"
    },
    {
      "city": "Bamako",
      "country": "Mali",
      "IATA": "BKO",
      "icao_code": "GABS",
      "airport_name": "Bamako–Sénou International Airport"
    },
    {
      "city": "Bamenda",
      "country": "Cameroon",
      "IATA": "BPC",
      "icao_code": "FKKV",
      "airport_name": "Bamenda Airport"
    },
    {
      "city": "Bamyan",
      "country": "Afghanistan",
      "IATA": "BIN",
      "icao_code": "OABN",
      "airport_name": "Bamyan Airport"
    },
    {
      "city": "Banda Aceh",
      "country": "Indonesia",
      "IATA": "BTJ",
      "icao_code": "WITT",
      "airport_name": "Sultan Iskandar Muda International Airport "
    },
    {
      "city": "Bandar Abbas",
      "country": "Iran",
      "IATA": "HDR",
      "icao_code": "OIKP",
      "airport_name": "Havadarya Airport"
    },
    {
      "city": "Bandar Abbas",
      "country": "Iran",
      "IATA": "BND",
      "icao_code": "OIKB",
      "airport_name": "Bandar Abbas International Airport"
    },
    {
      "city": "Bandar Lampung-Sumatra Island",
      "country": "Indonesia",
      "IATA": "TKG",
      "icao_code": "WIIT",
      "airport_name": "Radin Inten II  Airport"
    },
    {
      "city": "Bandar Lengeh",
      "country": "Iran",
      "IATA": "BDH",
      "icao_code": "OIBL",
      "airport_name": "Bandar Lengeh Airport"
    },
    {
      "city": "Bandar Mahshahr",
      "country": "Iran",
      "IATA": "MRX",
      "icao_code": "OIAM",
      "airport_name": "Mahshahr Airport"
    },
    {
      "city": "Bandar Seri Begawan",
      "country": "Brunei",
      "IATA": "BWN",
      "icao_code": "WBSB",
      "airport_name": "Brunei International Airport"
    },
    {
      "city": "Bandirma",
      "country": "Turkey",
      "IATA": "BDM",
      "icao_code": "LTBG",
      "airport_name": "Bandirma Airport"
    },
    {
      "city": "Bandoundu",
      "country": "Congo (Kinshasa)",
      "IATA": "FDU",
      "icao_code": "FZBO",
      "airport_name": "Bandundu Airport"
    },
    {
      "city": "Bandung",
      "country": "Indonesia",
      "IATA": "BDO",
      "icao_code": "WIIB",
      "airport_name": "Husein Sastranegara International Airport "
    },
    {
      "city": "Banff",
      "country": "Canada",
      "IATA": "YBA",
      "icao_code": "CYBA",
      "airport_name": "Banff Airport"
    },
    {
      "city": "Bangalore",
      "country": "India",
      "IATA": "BLR",
      "icao_code": "VOBL",
      "airport_name": "Kempegowda International Airport"
    },
    {
      "city": "Bangda",
      "country": "China",
      "IATA": "BPX",
      "icao_code": "ZUBD",
      "airport_name": "Qamdo Bangda Airport"
    },
    {
      "city": "Bangkok",
      "country": "Thailand",
      "IATA": "BKK",
      "icao_code": "VTBS",
      "airport_name": "Suvarnabhumi Airport"
    },
    {
      "city": "Bangkok",
      "country": "Thailand",
      "IATA": "DMK",
      "icao_code": "VTBD",
      "airport_name": "Don Mueang International Airport"
    },
    {
      "city": "Bangor",
      "country": "United States",
      "IATA": "BGR",
      "icao_code": "KBGR",
      "airport_name": "Bangor International Airport"
    },
    {
      "city": "Bangui",
      "country": "Central African Republic",
      "IATA": "BGF",
      "icao_code": "FEFF",
      "airport_name": "Bangui M'Poko International Airport"
    },
    {
      "city": "Banja Luka",
      "country": "Bosnia and Herzegovina",
      "IATA": "BNX",
      "icao_code": "LQBK",
      "airport_name": "Banja Luka International Airport"
    },
    {
      "city": "Banjarmasin",
      "country": "Indonesia",
      "IATA": "BDJ",
      "icao_code": "WRBB",
      "airport_name": "Syamsudin Noor International Airport"
    },
    {
      "city": "Banjul",
      "country": "Gambia",
      "IATA": "BJL",
      "icao_code": "GBYD",
      "airport_name": "Banjul International Airport"
    },
    {
      "city": "Banmaw",
      "country": "Burma",
      "IATA": "BMO",
      "icao_code": "VYBM",
      "airport_name": "Bhamo Airport"
    },
    {
      "city": "Banning",
      "country": "United States",
      "IATA": "BNG",
      "icao_code": "KBNG",
      "airport_name": "Banning Municipal Airport"
    },
    {
      "city": "Bannu",
      "country": "Pakistan",
      "IATA": "BNP",
      "airport_name": "Bannu Airport"
    },
    {
      "city": "Bantry",
      "country": "Ireland",
      "IATA": "BYT",
      "icao_code": "EIBN",
      "airport_name": "Bantry Aerodrome"
    },
    {
      "city": "Banyuwangi",
      "country": "Indonesia",
      "IATA": "BWX",
      "icao_code": "WARB",
      "airport_name": "Banyuwangi International Airport"
    },
    {
      "city": "Baoshan",
      "country": "China",
      "IATA": "BSD",
      "icao_code": "ZPBS",
      "airport_name": "Baoshan Yunduan Airport"
    },
    {
      "city": "Baotou",
      "country": "China",
      "IATA": "BAV",
      "icao_code": "ZBOW",
      "airport_name": "Baotou Airport"
    },
    {
      "city": "Bar Harbor",
      "country": "United States",
      "IATA": "BHB",
      "icao_code": "KBHB",
      "airport_name": "Hancock County-Bar Harbor Airport"
    },
    {
      "city": "Baracoa Playa",
      "country": "Cuba",
      "IATA": "UPB",
      "icao_code": "MUPB",
      "airport_name": "Playa Baracoa Airport"
    },
    {
      "city": "Baracoa Playa",
      "country": "Cuba",
      "IATA": "BCA",
      "icao_code": "MUBA",
      "airport_name": "Gustavo Rizo Airport"
    },
    {
      "city": "Barahona",
      "country": "Dominican Republic",
      "IATA": "BRX",
      "icao_code": "MDBH",
      "airport_name": "Maria Montez International Airport"
    },
    {
      "city": "Barcaldine",
      "country": "Australia",
      "IATA": "BCI",
      "icao_code": "YBAR",
      "airport_name": "Barcaldine Airport"
    },
    {
      "city": "Barcelona",
      "country": "Venezuela",
      "IATA": "BLA",
      "icao_code": "SVBC",
      "airport_name": "General José Antonio Anzoátegui International Airport"
    },
    {
      "city": "Barcelona",
      "country": "Spain",
      "IATA": "BCN",
      "icao_code": "LEBL",
      "airport_name": "Barcelona Airport"
    },
    {
      "city": "Barcelos",
      "country": "Brazil",
      "IATA": "BAZ",
      "icao_code": "SWBC",
      "airport_name": "Barcelos Airport"
    },
    {
      "city": "Bardufoss",
      "country": "Norway",
      "IATA": "BDU",
      "icao_code": "ENDU",
      "airport_name": "Bardufoss Airport"
    },
    {
      "city": "Bareilly",
      "country": "India",
      "IATA": "BEK",
      "airport_name": "Bareilly Airport"
    },
    {
      "city": "Bari",
      "country": "Italy",
      "IATA": "BRI",
      "icao_code": "LIBD",
      "airport_name": "Bari Karol Wojtyła Airport"
    },
    {
      "city": "Barimunya",
      "country": "Australia",
      "IATA": "BYP",
      "icao_code": "YBRY",
      "airport_name": "Barimunya Airport"
    },
    {
      "city": "Barinas",
      "country": "Venezuela",
      "IATA": "BNS",
      "icao_code": "SVBI",
      "airport_name": "Barinas Airport"
    },
    {
      "city": "Bario",
      "country": "Malaysia",
      "IATA": "BBN",
      "icao_code": "WBGZ",
      "airport_name": "Bario Airport"
    },
    {
      "city": "Barisal",
      "country": "Bangladesh",
      "IATA": "BZL",
      "icao_code": "VGBR",
      "airport_name": "Barisal Airport"
    },
    {
      "city": "Barking Sands",
      "country": "United States",
      "IATA": "BKH",
      "icao_code": "PHBK",
      "airport_name": "Pacific Missile Range Facility"
    },
    {
      "city": "Barnaul",
      "country": "Russia",
      "IATA": "BAX",
      "icao_code": "UNBB",
      "airport_name": "Barnaul Airport"
    },
    {
      "city": "Barnstable",
      "country": "United States",
      "IATA": "HYA",
      "icao_code": "KHYA",
      "airport_name": "Barnstable Municipal Airport "
    },
    {
      "city": "Baroda",
      "country": "India",
      "IATA": "BDQ",
      "icao_code": "VABO",
      "airport_name": "Vadodara Airport"
    },
    {
      "city": "Barquisimeto",
      "country": "Venezuela",
      "IATA": "BRM",
      "icao_code": "SVBM",
      "airport_name": "Jacinto Lara International Airport"
    },
    {
      "city": "Barra",
      "country": "United Kingdom",
      "IATA": "BRR",
      "icao_code": "EGPR",
      "airport_name": "Barra Airport "
    },
    {
      "city": "Barra Do Garcas",
      "country": "Brazil",
      "IATA": "BPG",
      "icao_code": "SBBW",
      "airport_name": "Barra do Garças Airport"
    },
    {
      "city": "Barrancabermeja",
      "country": "Colombia",
      "IATA": "EJA",
      "icao_code": "SKEJ",
      "airport_name": "Yariguíes Airport"
    },
    {
      "city": "Barranquilla",
      "country": "Colombia",
      "IATA": "BAQ",
      "icao_code": "SKBQ",
      "airport_name": "Ernesto Cortissoz International Airport"
    },
    {
      "city": "Barreiras",
      "country": "Brazil",
      "IATA": "BRA",
      "icao_code": "SNBR",
      "airport_name": "Barreiras Airport"
    },
    {
      "city": "Barretos",
      "country": "Brazil",
      "IATA": "BAT",
      "icao_code": "SNBA",
      "airport_name": "Chafei Amsei Airport"
    },
    {
      "city": "Barrie-Orillia",
      "country": "Canada",
      "IATA": "YLK",
      "icao_code": "CYLS",
      "airport_name": "Yelahanka Air Force Station"
    },
    {
      "city": "Barrow",
      "country": "United States",
      "IATA": "BRW",
      "icao_code": "PABR",
      "airport_name": "Wiley Post-Will Rogers Memorial Airport"
    },
    {
      "city": "Barrow Island",
      "country": "Australia",
      "IATA": "BWB",
      "icao_code": "YBWX",
      "airport_name": "Barrow Island Airport"
    },
    {
      "city": "Barrow Island",
      "country": "United Kingdom",
      "IATA": "BWF",
      "icao_code": "EGNL",
      "airport_name": "Barrow/Walney Island Airfield"
    },
    {
      "city": "Barter Island",
      "country": "United States",
      "IATA": "BTI",
      "icao_code": "PABA",
      "airport_name": "Barter Island LRRS Airport"
    },
    {
      "city": "Barth",
      "country": "Germany",
      "IATA": "BBH",
      "icao_code": "EDBH",
      "airport_name": "Stralsund–Barth Airport"
    },
    {
      "city": "Bartica",
      "country": "Guyana",
      "IATA": "GFO",
      "icao_code": "SYBT",
      "airport_name": "Bartica Airport"
    },
    {
      "city": "Bartow",
      "country": "United States",
      "IATA": "BOW",
      "icao_code": "KBOW",
      "airport_name": "Bartow Municipal Airport"
    },
    {
      "city": "Barysiai",
      "country": "Lithuania",
      "IATA": "HLJ",
      "airport_name": "Barysiai Airport "
    },
    {
      "city": "Basankusu",
      "country": "Congo (Kinshasa)",
      "IATA": "BSU",
      "icao_code": "FZEN",
      "airport_name": "Basankusu Airport"
    },
    {
      "city": "Basco",
      "country": "Philippines",
      "IATA": "BSO",
      "icao_code": "RPUO",
      "airport_name": "Basco Airport"
    },
    {
      "city": "Basrah",
      "country": "Iraq",
      "IATA": "BSR",
      "icao_code": "ORMM",
      "airport_name": "Basrah International Airport"
    },
    {
      "city": "Basse Terre",
      "country": "Guadeloupe",
      "IATA": "BBR",
      "airport_name": "Baillif Airport"
    },
    {
      "city": "Basse Terre",
      "country": "Saint Kitts and Nevis",
      "IATA": "SKB",
      "icao_code": "TKPK",
      "airport_name": "Robert L. Bradshaw International Airport"
    },
    {
      "city": "Bastia",
      "country": "France",
      "IATA": "BIA",
      "icao_code": "LFKB",
      "airport_name": "Bastia – Poretta Airport"
    },
    {
      "city": "Bata",
      "country": "Equatorial Guinea",
      "IATA": "BSG",
      "icao_code": "FGBT",
      "airport_name": "Bata Airport"
    },
    {
      "city": "Batagay",
      "country": "Russia",
      "IATA": "BQJ",
      "icao_code": "UEBB",
      "airport_name": "Batagay Airport"
    },
    {
      "city": "Batagay-Alyta",
      "country": "Russia",
      "IATA": "SUK",
      "airport_name": "Sakkyryr Airport"
    },
    {
      "city": "Batajnica",
      "country": "Serbia",
      "IATA": "BJY",
      "icao_code": "LYBT",
      "airport_name": "Batajnica Air Base"
    },
    {
      "city": "Batam",
      "country": "Indonesia",
      "IATA": "BTH",
      "icao_code": "WIKB",
      "airport_name": "Hang Nadim International Airport"
    },
    {
      "city": "Bathurst",
      "country": "Australia",
      "IATA": "BHS",
      "icao_code": "YBTH",
      "airport_name": "Bathurst Airport"
    },
    {
      "city": "Bathurst",
      "country": "Canada",
      "IATA": "ZBF",
      "icao_code": "CZBF",
      "airport_name": "Bathurst Airport"
    },
    {
      "city": "Bathurst Island",
      "country": "Australia",
      "IATA": "BRT",
      "icao_code": "YBTI",
      "airport_name": "Bathurst Island Airport"
    },
    {
      "city": "Batman",
      "country": "Turkey",
      "IATA": "BAL",
      "icao_code": "LTCJ",
      "airport_name": "Batman Airport"
    },
    {
      "city": "Batna",
      "country": "Algeria",
      "IATA": "BLJ",
      "icao_code": "DABT",
      "airport_name": "Mostépha Ben Boulaid Airport"
    },
    {
      "city": "Baton Rouge",
      "country": "United States",
      "IATA": "BTR",
      "icao_code": "KBTR",
      "airport_name": "Baton Rouge Metropolitan Airport "
    },
    {
      "city": "Batsfjord",
      "country": "Norway",
      "IATA": "BJF",
      "icao_code": "ENBS",
      "airport_name": "Båtsfjord Airport"
    },
    {
      "city": "Battambang",
      "country": "Cambodia",
      "IATA": "BBM",
      "icao_code": "VDBG",
      "airport_name": "Battambang Airport"
    },
    {
      "city": "Batticaloa",
      "country": "Sri Lanka",
      "IATA": "BTC",
      "icao_code": "VCCB",
      "airport_name": "Batticaloa Airport"
    },
    {
      "city": "Battle Creek",
      "country": "United States",
      "IATA": "BTL",
      "icao_code": "KBTL",
      "airport_name": "W. K. Kellogg Airport"
    },
    {
      "city": "Batu Licin",
      "country": "Indonesia",
      "IATA": "BTW",
      "icao_code": "WAOC",
      "airport_name": "Batu Licin Airport"
    },
    {
      "city": "Batumi",
      "country": "Georgia",
      "IATA": "BUS",
      "icao_code": "UGSB",
      "airport_name": "Batumi International Airport"
    },
    {
      "city": "Batuna",
      "country": "Solomon Islands",
      "IATA": "BPF",
      "icao_code": "AGBT",
      "airport_name": "Batuna Aerodrome Airport"
    },
    {
      "city": "Bau-Bau",
      "country": "Indonesia",
      "IATA": "BUW",
      "icao_code": "WAWB",
      "airport_name": "Betoambari Airport "
    },
    {
      "city": "Baucau",
      "country": "East Timor",
      "IATA": "BCH",
      "icao_code": "WPEC",
      "airport_name": "Baucau Airport"
    },
    {
      "city": "Bauchi",
      "country": "Nigeria",
      "IATA": "BCU",
      "airport_name": "Sir Abubakar Tafawa Balewa Airport"
    },
    {
      "city": "Baudette",
      "country": "United States",
      "IATA": "BDE",
      "icao_code": "KBDE",
      "airport_name": "Baudette International Airport"
    },
    {
      "city": "Bauru",
      "country": "Brazil",
      "IATA": "JTC",
      "icao_code": "SJTC",
      "airport_name": "Moussa Nakhl Tobias–Bauru/Arealva State Airport "
    },
    {
      "city": "Bauru",
      "country": "Brazil",
      "IATA": "BAU",
      "icao_code": "SBBU",
      "airport_name": "Bauru Airport"
    },
    {
      "city": "Bay City",
      "country": "United States",
      "IATA": "BBC",
      "icao_code": "KBYY",
      "airport_name": "Bay City Municipal Airport "
    },
    {
      "city": "Bayamo",
      "country": "Cuba",
      "IATA": "BYM",
      "icao_code": "MUBY",
      "airport_name": "Carlos Manuel de Céspedes Airport"
    },
    {
      "city": "Bayankhongor",
      "country": "Mongolia",
      "IATA": "BYN",
      "icao_code": "ZMBH",
      "airport_name": "Bayankhongor Airport"
    },
    {
      "city": "Bayannur",
      "country": "China",
      "IATA": "RLK",
      "airport_name": "Bayannur Tianjitai Airport"
    },
    {
      "city": "Bayreuth",
      "country": "Germany",
      "IATA": "BYU",
      "icao_code": "EDQD",
      "airport_name": "Bindlacher Berg Airport"
    },
    {
      "city": "Bearskin Lake",
      "country": "Canada",
      "IATA": "XBE",
      "icao_code": "CNE3",
      "airport_name": "Bearskin Lake Airport"
    },
    {
      "city": "Beaufort",
      "country": "United States",
      "IATA": "BFT",
      "icao_code": "KBFT",
      "airport_name": "Beaufort County Airport "
    },
    {
      "city": "Beaumont",
      "country": "United States",
      "IATA": "BMT",
      "icao_code": "KBMT",
      "airport_name": "Beaumont Municipal Airport"
    },
    {
      "city": "Beaumont",
      "country": "United States",
      "IATA": "BPT",
      "icao_code": "KBPT",
      "airport_name": "Jack Brooks Regional Airport"
    },
    {
      "city": "Beauvais",
      "country": "France",
      "IATA": "BVA",
      "icao_code": "LFOB",
      "airport_name": "Beauvais–Tillé Airport"
    },
    {
      "city": "Beaver",
      "country": "United States",
      "IATA": "WBQ",
      "icao_code": "PAWB",
      "airport_name": "Beaver Airport"
    },
    {
      "city": "Beaver Creek",
      "country": "Canada",
      "IATA": "YXQ",
      "icao_code": "CYXQ",
      "airport_name": "Beaver Creek Airport"
    },
    {
      "city": "Beaver Falls",
      "country": "United States",
      "IATA": "BFP",
      "icao_code": "KBVI",
      "airport_name": "Beaver County Airport "
    },
    {
      "city": "Beckley",
      "country": "United States",
      "IATA": "BKW",
      "icao_code": "KBKW",
      "airport_name": "Raleigh County Memorial Airport"
    },
    {
      "city": "Bedford",
      "country": "United States",
      "IATA": "BED",
      "icao_code": "KBED",
      "airport_name": "Hanscom Field"
    },
    {
      "city": "Bedourie",
      "country": "Australia",
      "IATA": "BEU",
      "icao_code": "YBIE",
      "airport_name": "Bedourie Airport"
    },
    {
      "city": "Bedwell Harbour",
      "country": "Canada",
      "IATA": "YBW",
      "icao_code": "CYBW",
      "airport_name": "Springbank Airport"
    },
    {
      "city": "Beer-sheba",
      "country": "Israel",
      "IATA": "BEV",
      "icao_code": "LLBS",
      "airport_name": "Be'er Sheva (Teyman) Airport"
    },
    {
      "city": "Beica",
      "country": "Ethiopia",
      "IATA": "BEI",
      "icao_code": "HABE",
      "airport_name": "Beica Airport"
    },
    {
      "city": "Beihai",
      "country": "China",
      "IATA": "BHY",
      "icao_code": "ZGBH",
      "airport_name": "Beihai Fucheng Airport"
    },
    {
      "city": "Beihan",
      "country": "Yemen",
      "IATA": "BHN",
      "icao_code": "OYBN",
      "airport_name": "Beihan Airport"
    },
    {
      "city": "Beijing",
      "country": "China",
      "IATA": "PKX",
      "icao_code": "ZBAD",
      "airport_name": "Beijing Daxing International Airport"
    },
    {
      "city": "Beijing",
      "country": "China",
      "IATA": "NAY",
      "icao_code": "ZBBB",
      "airport_name": "Beijing Nanyuan Airport"
    },
    {
      "city": "Beijing",
      "country": "China",
      "IATA": "PEK",
      "icao_code": "ZBAA",
      "airport_name": "Beijing Capital International Airport"
    },
    {
      "city": "Beira",
      "country": "Mozambique",
      "IATA": "BEW",
      "icao_code": "FQBR",
      "airport_name": "Beira Airport"
    },
    {
      "city": "Beirut",
      "country": "Lebanon",
      "IATA": "BEY",
      "icao_code": "OLBA",
      "airport_name": "Beirut-Rafic Hariri International Airport"
    },
    {
      "city": "Beja (madeira)",
      "country": "Portugal",
      "IATA": "BYJ",
      "icao_code": "LPBJ",
      "airport_name": "Beja  Airport"
    },
    {
      "city": "Bejaja",
      "country": "Algeria",
      "IATA": "BJA",
      "icao_code": "DAAE",
      "airport_name": "Soummam – Abane Ramdane Airport"
    },
    {
      "city": "Bekily",
      "country": "Madagascar",
      "IATA": "OVA",
      "airport_name": "Bekily Airport"
    },
    {
      "city": "Belaga",
      "country": "Malaysia",
      "IATA": "BLG",
      "icao_code": "WBGC",
      "airport_name": "Belaga Airport"
    },
    {
      "city": "Belem",
      "country": "Brazil",
      "IATA": "BEL",
      "icao_code": "SBBE",
      "airport_name": "Val de Cans–Júlio Cezar Ribeiro International Airport"
    },
    {
      "city": "Belfast",
      "country": "United Kingdom",
      "IATA": "BHD",
      "icao_code": "EGAC",
      "airport_name": "George Best Belfast City Airport"
    },
    {
      "city": "Belfast",
      "country": "United Kingdom",
      "IATA": "BFS",
      "icao_code": "EGAA",
      "airport_name": "Belfast International Airport"
    },
    {
      "city": "Belfort",
      "country": "France",
      "IATA": "BOR",
      "airport_name": "Belfort - Fontaine Airport"
    },
    {
      "city": "Belgaum",
      "country": "India",
      "IATA": "IXG",
      "icao_code": "VABM",
      "airport_name": "Belgaum Airport"
    },
    {
      "city": "Belgorod",
      "country": "Russia",
      "IATA": "EGO",
      "icao_code": "UUOB",
      "airport_name": "Belgorod International Airport"
    },
    {
      "city": "Belgrade",
      "country": "Serbia",
      "IATA": "BEG",
      "icao_code": "LYBE",
      "airport_name": "Belgrade Nikola Tesla Airport"
    },
    {
      "city": "Belize City",
      "country": "Belize",
      "IATA": "BZE",
      "icao_code": "MZBZ",
      "airport_name": "Philip S. W. Goldson International Airport"
    },
    {
      "city": "Bella Coola",
      "country": "Canada",
      "IATA": "QBC",
      "icao_code": "CYBD",
      "airport_name": "Bella Coola Airport"
    },
    {
      "city": "Bellary",
      "country": "India",
      "IATA": "BEP",
      "icao_code": "VOBI",
      "airport_name": "Bellary Airport"
    },
    {
      "city": "Belleville",
      "country": "United States",
      "IATA": "BLV",
      "icao_code": "KBLV",
      "airport_name": "MidAmerica St. Louis Airport"
    },
    {
      "city": "Bellingham",
      "country": "United States",
      "IATA": "BLI",
      "icao_code": "KBLI",
      "airport_name": "Bellingham International Airport"
    },
    {
      "city": "Belo Horizonte",
      "country": "Brazil",
      "IATA": "CNF",
      "icao_code": "SBCF",
      "airport_name": "Tancredo Neves International Airport "
    },
    {
      "city": "Belo Horizonte",
      "country": "Brazil",
      "IATA": "PLU",
      "icao_code": "SBBH",
      "airport_name": "Carlos Drummond de Andrade Airport"
    },
    {
      "city": "Belo sur Tsiribihina",
      "country": "Madagascar",
      "IATA": "BMD",
      "icao_code": "FMML",
      "airport_name": "Belo sur Tsiribihina Airport"
    },
    {
      "city": "Beloyarsky",
      "country": "Russia",
      "IATA": "EYK",
      "icao_code": "USHQ",
      "airport_name": "Beloyarsk Airport"
    },
    {
      "city": "Bembridge",
      "country": "United Kingdom",
      "IATA": "BBP",
      "icao_code": "EGHJ",
      "airport_name": "Bembridge Airport"
    },
    {
      "city": "Bemidji",
      "country": "United States",
      "IATA": "BJI",
      "icao_code": "KBJI",
      "airport_name": "Bemidji Regional Airport"
    },
    {
      "city": "Ben Slimane",
      "country": "Morocco",
      "IATA": "GMD",
      "icao_code": "GMMB",
      "airport_name": "Ben Slimane Airport"
    },
    {
      "city": "Benbecula",
      "country": "United Kingdom",
      "IATA": "BEB",
      "icao_code": "EGPL",
      "airport_name": "Benbecula Airport"
    },
    {
      "city": "Bendigo",
      "country": "Australia",
      "IATA": "BXG",
      "icao_code": "YBDG",
      "airport_name": "Bendigo Airport"
    },
    {
      "city": "Bengbu",
      "country": "China",
      "IATA": "BFU",
      "icao_code": "ZSBB",
      "airport_name": "Bengbu Airport"
    },
    {
      "city": "Benghazi",
      "country": "Libya",
      "IATA": "BEN",
      "icao_code": "HLLB",
      "airport_name": "Benina International Airport"
    },
    {
      "city": "Bengkulu",
      "country": "Indonesia",
      "IATA": "BKS",
      "icao_code": "WIPL",
      "airport_name": "Fatmawati Soekarno Airport"
    },
    {
      "city": "Benguela",
      "country": "Angola",
      "IATA": "BUG",
      "icao_code": "FNBG",
      "airport_name": "Benguela Airport "
    },
    {
      "city": "Beni",
      "country": "Congo (Kinshasa)",
      "IATA": "BNC",
      "icao_code": "FZNP",
      "airport_name": "Beni Airport"
    },
    {
      "city": "Beni Mellal",
      "country": "Morocco",
      "IATA": "BEM",
      "airport_name": "Bossembélé Airport"
    },
    {
      "city": "Benin",
      "country": "Nigeria",
      "IATA": "BNI",
      "icao_code": "DNBE",
      "airport_name": "Benin City Airport"
    },
    {
      "city": "Benson",
      "country": "United Kingdom",
      "IATA": "BEX",
      "icao_code": "EGUB",
      "airport_name": "Royal Air Force Benson"
    },
    {
      "city": "Bentonville",
      "country": "United States",
      "IATA": "XNA",
      "icao_code": "KXNA",
      "airport_name": "Northwest Arkansas Regional Airport"
    },
    {
      "city": "Bequia",
      "country": "Saint Vincent and the Grenadines",
      "IATA": "BQU",
      "icao_code": "TVSB",
      "airport_name": "J.F. Mitchell Airport"
    },
    {
      "city": "Berbera",
      "country": "Somalia",
      "IATA": "BBO",
      "icao_code": "HCMI",
      "airport_name": "Berbera Airport"
    },
    {
      "city": "Berberati",
      "country": "Central African Republic",
      "IATA": "BBT",
      "icao_code": "FEFT",
      "airport_name": "Berbérati Airport"
    },
    {
      "city": "Berdyansk",
      "country": "Ukraine",
      "IATA": "ERD",
      "icao_code": "UKDB",
      "airport_name": "Berdyansk Airport"
    },
    {
      "city": "Berens River",
      "country": "Canada",
      "IATA": "YBV",
      "icao_code": "CYBV",
      "airport_name": "Berens River Airport"
    },
    {
      "city": "Berezovo",
      "country": "Russia",
      "IATA": "EZV",
      "icao_code": "USHB",
      "airport_name": "Berezovo Airport"
    },
    {
      "city": "Bergamo",
      "country": "Italy",
      "IATA": "BGY",
      "icao_code": "LIME",
      "airport_name": "Il Caravaggio International Airport"
    },
    {
      "city": "Bergen",
      "country": "Norway",
      "IATA": "BGO",
      "icao_code": "ENBR",
      "airport_name": "Bergen  Airport, Flesland"
    },
    {
      "city": "Bergerac",
      "country": "France",
      "IATA": "EGC",
      "icao_code": "LFBE",
      "airport_name": "Bergerac Dordogne Périgord Airport"
    },
    {
      "city": "Berlevag",
      "country": "Norway",
      "IATA": "BVG",
      "icao_code": "ENBV",
      "airport_name": "Berlevåg Airport"
    },
    {
      "city": "Berlin",
      "country": "Germany",
      "IATA": "TXL",
      "icao_code": "EDDT",
      "airport_name": "Berlin Tegel Airport"
    },
    {
      "city": "Berlin",
      "country": "Germany",
      "IATA": "THF",
      "airport_name": "Tempelhof Airport"
    },
    {
      "city": "Berlin",
      "country": "Germany",
      "IATA": "SXF",
      "icao_code": "EDDB",
      "airport_name": "Berlin-Schonefeld International Airport"
    },
    {
      "city": "Bermejo",
      "country": "Bolivia",
      "IATA": "BJO",
      "icao_code": "SLBJ",
      "airport_name": "Bermejo Airport"
    },
    {
      "city": "Bermuda",
      "country": "Bermuda",
      "IATA": "BDA",
      "icao_code": "TXKF",
      "airport_name": "L.F. Wade International Airport"
    },
    {
      "city": "Bern",
      "country": "Switzerland",
      "IATA": "BRN",
      "icao_code": "LSZB",
      "airport_name": "Bern Airport"
    },
    {
      "city": "Beru Island",
      "country": "Kiribati",
      "IATA": "BEZ",
      "icao_code": "NGBR",
      "airport_name": "Beru Airport"
    },
    {
      "city": "Besalampy",
      "country": "Madagascar",
      "IATA": "BPY",
      "icao_code": "FMNQ",
      "airport_name": "Besalampy Airport"
    },
    {
      "city": "Beslan",
      "country": "Russia",
      "IATA": "OGZ",
      "icao_code": "URMO",
      "airport_name": "Beslan Airport"
    },
    {
      "city": "Bethel",
      "country": "United States",
      "IATA": "BET",
      "icao_code": "PABE",
      "airport_name": "Bethel Airport"
    },
    {
      "city": "Bettles",
      "country": "United States",
      "IATA": "BTT",
      "icao_code": "PABT",
      "airport_name": "Bettles Airport"
    },
    {
      "city": "Beverly",
      "country": "United States",
      "IATA": "BVY",
      "icao_code": "KBVY",
      "airport_name": "Beverly Regional Airport"
    },
    {
      "city": "Beziers",
      "country": "France",
      "IATA": "BZR",
      "icao_code": "LFMU",
      "airport_name": "Béziers Cap d'Agde Airport"
    },
    {
      "city": "Bhagtanwala",
      "country": "Pakistan",
      "IATA": "BHW",
      "airport_name": "Bhagatanwala Airport"
    },
    {
      "city": "Bhairawa",
      "country": "Nepal",
      "IATA": "BWA",
      "icao_code": "VNBW",
      "airport_name": "Gautam Buddha Airport"
    },
    {
      "city": "Bharatpur",
      "country": "Nepal",
      "IATA": "BHR",
      "icao_code": "VNBP",
      "airport_name": "Bharatpur Airport"
    },
    {
      "city": "Bhatinda",
      "country": "India",
      "IATA": "BUP",
      "icao_code": "VIBT",
      "airport_name": "Bhatinda Airport"
    },
    {
      "city": "Bhaunagar",
      "country": "India",
      "IATA": "BHU",
      "icao_code": "VABV",
      "airport_name": "Bhavnagar Airport"
    },
    {
      "city": "Bhojpur",
      "country": "Nepal",
      "IATA": "BHP",
      "icao_code": "VNBJ",
      "airport_name": "Bhojpur Airport"
    },
    {
      "city": "Bhopal",
      "country": "India",
      "IATA": "BHO",
      "icao_code": "VABP",
      "airport_name": "Raja Bhoj Airport"
    },
    {
      "city": "Bhubaneswar",
      "country": "India",
      "IATA": "BBI",
      "icao_code": "VEBS",
      "airport_name": "Biju Patnaik International Airport"
    },
    {
      "city": "Bhuj",
      "country": "India",
      "IATA": "BHJ",
      "icao_code": "VABJ",
      "airport_name": " Bhuj Rudra Mata Air Force Base"
    },
    {
      "city": "Biak",
      "country": "Indonesia",
      "IATA": "BIK",
      "icao_code": "WABB",
      "airport_name": "Frans Kaisiepo International Airport"
    },
    {
      "city": "Biarritz-bayonne",
      "country": "France",
      "IATA": "BIQ",
      "icao_code": "LFBZ",
      "airport_name": "Biarritz Pays Basque Airport"
    },
    {
      "city": "Biała Podlaska",
      "country": "Poland",
      "IATA": "BXP",
      "icao_code": "EPBP",
      "airport_name": "Biała Podlaska Airport"
    },
    {
      "city": "Bielefeld",
      "country": "Germany",
      "IATA": "BFE",
      "icao_code": "EDLI",
      "airport_name": "Bielefeld Airport"
    },
    {
      "city": "Big Mountain",
      "country": "United States",
      "IATA": "BMX",
      "airport_name": "Big Mountain Air Force Station "
    },
    {
      "city": "Big Piney",
      "country": "United States",
      "IATA": "BPI",
      "icao_code": "KBPI",
      "airport_name": "Big Piney-Marbleton Airport"
    },
    {
      "city": "Big Trout Lake",
      "country": "Canada",
      "IATA": "YTL",
      "icao_code": "CYTL",
      "airport_name": "Big Trout Lake Airport"
    },
    {
      "city": "Biggin Hill",
      "country": "United Kingdom",
      "IATA": "BQH",
      "icao_code": "EGKB",
      "airport_name": "London Biggin Hill Airport"
    },
    {
      "city": "Bijie",
      "country": "China",
      "IATA": "BFJ",
      "icao_code": "ZUBJ",
      "airport_name": "Ba Airport"
    },
    {
      "city": "Bikaner",
      "country": "India",
      "IATA": "BKB",
      "icao_code": "VIBK",
      "airport_name": "Nal Airport"
    },
    {
      "city": "Bilaspur",
      "country": "India",
      "IATA": "PAB",
      "icao_code": "VABI",
      "airport_name": "Bilaspur Airport"
    },
    {
      "city": "Bilbao",
      "country": "Spain",
      "IATA": "BIO",
      "icao_code": "LEBB",
      "airport_name": "Bilbao Airport"
    },
    {
      "city": "Bildudalur",
      "country": "Iceland",
      "IATA": "BIU",
      "icao_code": "BIBD",
      "airport_name": "Bíldudalur Airport"
    },
    {
      "city": "Billings",
      "country": "United States",
      "IATA": "BIL",
      "icao_code": "KBIL",
      "airport_name": "Billings Logan International Airport"
    },
    {
      "city": "Billund",
      "country": "Denmark",
      "IATA": "BLL",
      "icao_code": "EKBI",
      "airport_name": "Billund Airport"
    },
    {
      "city": "Biloela",
      "country": "Australia",
      "IATA": "THG",
      "icao_code": "YTNG",
      "airport_name": "Thangool Airport"
    },
    {
      "city": "Biloxi",
      "country": "United States",
      "IATA": "BIX",
      "airport_name": "Keesler Air Force Base"
    },
    {
      "city": "Bima",
      "country": "Indonesia",
      "IATA": "BMU",
      "icao_code": "WRRB",
      "airport_name": "Sultan Muhammad Salahudin Airport"
    },
    {
      "city": "Binghamton",
      "country": "United States",
      "IATA": "BGM",
      "icao_code": "KBGM",
      "airport_name": "Greater Binghamton Airport "
    },
    {
      "city": "Bingol",
      "country": "Turkey",
      "IATA": "BGG",
      "airport_name": "Bongouanou Airport"
    },
    {
      "city": "Bintulu",
      "country": "Malaysia",
      "IATA": "BTU",
      "icao_code": "WBGB",
      "airport_name": "Bintulu Airport"
    },
    {
      "city": "Bintuni",
      "country": "Indonesia",
      "IATA": "NTI",
      "icao_code": "WASB",
      "airport_name": "Stenkol Airport"
    },
    {
      "city": "Biratnagar",
      "country": "Nepal",
      "IATA": "BIR",
      "icao_code": "VNVT",
      "airport_name": "Biratnagar Airport"
    },
    {
      "city": "Birburg",
      "country": "Germany",
      "IATA": "BBJ",
      "icao_code": "EDAB",
      "airport_name": "Bitburg Airport "
    },
    {
      "city": "Bird Island",
      "country": "Seychelles",
      "IATA": "BDI",
      "icao_code": "FSSB",
      "airport_name": "Bird Island Airport"
    },
    {
      "city": "Birdsville",
      "country": "Australia",
      "IATA": "BVI",
      "icao_code": "YBDV",
      "airport_name": "Birdsville Airport"
    },
    {
      "city": "Birjand",
      "country": "Iran",
      "IATA": "XBJ",
      "icao_code": "OIMB",
      "airport_name": "Birjand International Airport"
    },
    {
      "city": "Birmingham",
      "country": "United States",
      "IATA": "BHM",
      "icao_code": "KBHM",
      "airport_name": "Birmingham-Shuttlesworth International Airport"
    },
    {
      "city": "Birmingham",
      "country": "United Kingdom",
      "IATA": "BHX",
      "icao_code": "EGBB",
      "airport_name": "Birmingham International Airport"
    },
    {
      "city": "Bisha",
      "country": "Saudi Arabia",
      "IATA": "BHH",
      "icao_code": "OEBH",
      "airport_name": "Bisha Airport"
    },
    {
      "city": "Bishkek",
      "country": "Kyrgyzstan",
      "IATA": "FRU",
      "icao_code": "UAFM",
      "airport_name": "Manas International Airport"
    },
    {
      "city": "Bisho",
      "country": "South Africa",
      "IATA": "BIY",
      "icao_code": "FABE",
      "airport_name": "Bisho Airport"
    },
    {
      "city": "Bishop",
      "country": "United States",
      "IATA": "BIH",
      "icao_code": "KBIH",
      "airport_name": "Eastern Sierra Regional Airport"
    },
    {
      "city": "Biskra",
      "country": "Algeria",
      "IATA": "BSK",
      "icao_code": "DAUB",
      "airport_name": "Mohamed Khider Airport"
    },
    {
      "city": "Bismarck",
      "country": "United States",
      "IATA": "BIS",
      "icao_code": "KBIS",
      "airport_name": "Bismarck Municipal Airport"
    },
    {
      "city": "Bissau",
      "country": "Guinea-Bissau",
      "IATA": "OXB",
      "icao_code": "GGOV",
      "airport_name": "Osvaldo Vieira International Airport"
    },
    {
      "city": "Bitam",
      "country": "Gabon",
      "IATA": "BMM",
      "icao_code": "FOOB",
      "airport_name": "Bitam Airport"
    },
    {
      "city": "Black Tickle",
      "country": "Canada",
      "IATA": "YBI",
      "airport_name": "Black Tickle Airport"
    },
    {
      "city": "Blackall",
      "country": "Australia",
      "IATA": "BKQ",
      "icao_code": "YBCK",
      "airport_name": "Blackall Airport"
    },
    {
      "city": "Blackbushe",
      "country": "United Kingdom",
      "IATA": "BBS",
      "icao_code": "EGLK",
      "airport_name": "Blackbushe Airport"
    },
    {
      "city": "Blackpool",
      "country": "United Kingdom",
      "IATA": "BLK",
      "icao_code": "EGNH",
      "airport_name": "Blackpool Airport"
    },
    {
      "city": "Blackwater",
      "country": "Australia",
      "IATA": "BLT",
      "icao_code": "YBTR",
      "airport_name": "Blackwater Airport"
    },
    {
      "city": "Blagoveschensk",
      "country": "Russia",
      "IATA": "BQS",
      "icao_code": "UHBB",
      "airport_name": "Ignatyevo Airport"
    },
    {
      "city": "Blantyre",
      "country": "Malawi",
      "IATA": "BLZ",
      "icao_code": "FWCL",
      "airport_name": "Chileka International Airport"
    },
    {
      "city": "Blida",
      "country": "Algeria",
      "IATA": "QLD",
      "airport_name": "Blida Airport"
    },
    {
      "city": "Block Island",
      "country": "United States",
      "IATA": "BID",
      "icao_code": "KBID",
      "airport_name": "Block Island State Airport"
    },
    {
      "city": "Bloemfontein",
      "country": "South Africa",
      "IATA": "BFN",
      "icao_code": "FABL",
      "airport_name": "Bloemfontein Airport"
    },
    {
      "city": "Bloomington",
      "country": "United States",
      "IATA": "BMG",
      "icao_code": "KBMG",
      "airport_name": "Greater Rochester International Airport"
    },
    {
      "city": "Bloomington",
      "country": "United States",
      "IATA": "BMI",
      "icao_code": "KBMI",
      "airport_name": "Central Illinois Regional Airport"
    },
    {
      "city": "Bluefield",
      "country": "United States",
      "IATA": "BLF",
      "airport_name": "Mercer County Airport (West Virginia)"
    },
    {
      "city": "Bluefields",
      "country": "Nicaragua",
      "IATA": "BEF",
      "icao_code": "MNBL",
      "airport_name": "Bluefields International Airport"
    },
    {
      "city": "Blythe",
      "country": "United States",
      "IATA": "BLH",
      "icao_code": "KBLH",
      "airport_name": "Blythe Airport"
    },
    {
      "city": "Blytheville",
      "country": "United States",
      "IATA": "BYH",
      "icao_code": "KBYH",
      "airport_name": "Arkansas International Airport"
    },
    {
      "city": "Bo",
      "country": "Sierra Leone",
      "IATA": "KBS",
      "icao_code": "GFBO",
      "airport_name": "Bo Airport"
    },
    {
      "city": "Boa Vista",
      "country": "Brazil",
      "IATA": "BVB",
      "icao_code": "SBBV",
      "airport_name": "Boa Vista-Atlas Brasil Cantanhede International Airport "
    },
    {
      "city": "Boa Vista",
      "country": "Cape Verde",
      "IATA": "BVC",
      "icao_code": "GVBA",
      "airport_name": "Aristides Pereira International Airport"
    },
    {
      "city": "Bob Quinn Lake",
      "country": "Canada",
      "IATA": "YBO",
      "airport_name": "Bob Quinn Lake Airport"
    },
    {
      "city": "Bobo-dioulasso",
      "country": "Burkina Faso",
      "IATA": "BOY",
      "icao_code": "DFOO",
      "airport_name": "Bobo Dioulasso Airport"
    },
    {
      "city": "South Florida, All Airports",
      "country": "United States",
      "IATA": "SFALL",
      "airport_name": "South Florida Regional Airports"
    },
    {
      "city": "South Florida, Boca Raton",
      "country": "United States",
      "IATA": "BCT",
      "icao_code": "KBCT",
      "airport_name": "Boca Raton Airport"
    },
    {
      "city": "Bocas Del Toro",
      "country": "Panama",
      "IATA": "BOC",
      "icao_code": "MPBO",
      "airport_name": "Bocas del Toro \"Isla Colon\" International Airport"
    },
    {
      "city": "Bodaibo",
      "country": "Russia",
      "IATA": "ODO",
      "airport_name": "Bodaybo Airport"
    },
    {
      "city": "Bodinumu",
      "country": "Papua New Guinea",
      "IATA": "BNM",
      "airport_name": "Bodinumu Airport"
    },
    {
      "city": "Bodo",
      "country": "Norway",
      "IATA": "BOO",
      "icao_code": "ENBO",
      "airport_name": "Bodø Airport"
    },
    {
      "city": "Bodrum",
      "country": "Turkey",
      "IATA": "BJV",
      "icao_code": "LTFE",
      "airport_name": "Milas-Bodrum Airport"
    },
    {
      "city": "Bodrum",
      "country": "Turkey",
      "IATA": "BXN",
      "icao_code": "LTBV",
      "airport_name": "Bodrum-Imsik Airport"
    },
    {
      "city": "Boende",
      "country": "Congo (Kinshasa)",
      "IATA": "BNB",
      "icao_code": "FZGN",
      "airport_name": "Boende Airport"
    },
    {
      "city": "Bogorodskoe",
      "country": "Russia",
      "IATA": "BQG",
      "airport_name": "Bogorodskoye Airport"
    },
    {
      "city": "Bogota",
      "country": "Colombia",
      "IATA": "BOG",
      "icao_code": "SKBO",
      "airport_name": "El Dorado International Airport"
    },
    {
      "city": "Boigu",
      "country": "Australia",
      "IATA": "GIC",
      "icao_code": "YBOI",
      "airport_name": "Boigu Island Airport"
    },
    {
      "city": "Bointo",
      "country": "Brazil",
      "IATA": "BYO",
      "icao_code": "SJDB",
      "airport_name": "Bonito Airport"
    },
    {
      "city": "Boise",
      "country": "United States",
      "IATA": "BOI",
      "icao_code": "KBOI",
      "airport_name": "Boise Air Terminal Airport"
    },
    {
      "city": "Bojnourd",
      "country": "Iran",
      "IATA": "BJB",
      "icao_code": "OIMN",
      "airport_name": "Bojnord Airport"
    },
    {
      "city": "Bokepyin",
      "country": "Burma",
      "IATA": "VBP",
      "airport_name": "Bokpyin Airport"
    },
    {
      "city": "Bokondini-Papua Island",
      "country": "Indonesia",
      "IATA": "BUI",
      "icao_code": "WAJB",
      "airport_name": "Bokondini Airport"
    },
    {
      "city": "Bole",
      "country": "China",
      "IATA": "BPL",
      "icao_code": "ZWBL",
      "airport_name": "Bole Alashankou Airport"
    },
    {
      "city": "Bologna",
      "country": "Italy",
      "IATA": "BLQ",
      "icao_code": "LIPE",
      "airport_name": "Bologna Guglielmo Marconi Airport"
    },
    {
      "city": "Bolzano",
      "country": "Italy",
      "IATA": "BZO",
      "icao_code": "LIPB",
      "airport_name": "Bolzano Airport"
    },
    {
      "city": "Bom Jesus Da Lapa",
      "country": "Brazil",
      "IATA": "LAZ",
      "icao_code": "SBLP",
      "airport_name": "Bom Jesus da Lapa Airport"
    },
    {
      "city": "Boma",
      "country": "Congo (Kinshasa)",
      "IATA": "BOA",
      "icao_code": "FZAJ",
      "airport_name": "Boma Airport"
    },
    {
      "city": "Bonanza",
      "country": "Nicaragua",
      "IATA": "BZA",
      "icao_code": "MNBZ",
      "airport_name": "San Pedro Airport"
    },
    {
      "city": "Bonaventure",
      "country": "Canada",
      "IATA": "YVB",
      "icao_code": "CYVB",
      "airport_name": "Bonaventure Airport"
    },
    {
      "city": "Bonnyville",
      "country": "Canada",
      "IATA": "YBY",
      "icao_code": "CYBF",
      "airport_name": "Bonnyville Airport"
    },
    {
      "city": "Bonthe",
      "country": "Sierra Leone",
      "IATA": "BTE",
      "icao_code": "GFBN",
      "airport_name": "Sherbro International Airport"
    },
    {
      "city": "Bor",
      "country": "Russia",
      "IATA": "TGP",
      "icao_code": "UNIP",
      "airport_name": "Podkamennaya Tunguska Airport"
    },
    {
      "city": "Bora Bora",
      "country": "French Polynesia",
      "IATA": "BOB",
      "icao_code": "NTTB",
      "airport_name": "Bora Bora Airport "
    },
    {
      "city": "Borba",
      "country": "Brazil",
      "IATA": "RBB",
      "icao_code": "SWBR",
      "airport_name": "Borba Airport"
    },
    {
      "city": "Bordeaux",
      "country": "France",
      "IATA": "BOD",
      "icao_code": "LFBD",
      "airport_name": "Bordeaux-Mérignac Airport"
    },
    {
      "city": "Bordj Badji Mokhtar",
      "country": "Algeria",
      "IATA": "BMW",
      "icao_code": "DATM",
      "airport_name": "Bordj Mokhtar Airport"
    },
    {
      "city": "Borger",
      "country": "United States",
      "IATA": "BGD",
      "icao_code": "KBGD",
      "airport_name": "Hutchinson County Airport"
    },
    {
      "city": "Borkum",
      "country": "Germany",
      "IATA": "BMK",
      "airport_name": "Borkum Airfield"
    },
    {
      "city": "Borlange",
      "country": "Sweden",
      "IATA": "BLE",
      "icao_code": "ESSD",
      "airport_name": "Borlänge Airport "
    },
    {
      "city": "Borroloola",
      "country": "Australia",
      "IATA": "BOX",
      "icao_code": "BOCH",
      "airport_name": "Borroloola Airport"
    },
    {
      "city": "Bosaso",
      "country": "Somalia",
      "IATA": "BSA",
      "icao_code": "HCMF",
      "airport_name": "Bosaso Airport"
    },
    {
      "city": "Boston",
      "country": "United States",
      "IATA": "BOS",
      "icao_code": "KBOS",
      "airport_name": "Logan International Airport"
    },
    {
      "city": "Botucatu",
      "country": "Brazil",
      "IATA": "QCJ",
      "airport_name": "Botucatu Airport"
    },
    {
      "city": "Bou Saada",
      "country": "Algeria",
      "IATA": "BUJ",
      "icao_code": "DAAD",
      "airport_name": "Bou Saada Airport"
    },
    {
      "city": "Bouake",
      "country": "Cote d'Ivoire",
      "IATA": "BYK",
      "icao_code": "DIBK",
      "airport_name": "Bouaké Airport"
    },
    {
      "city": "Bouarfa",
      "country": "Morocco",
      "IATA": "UAR",
      "airport_name": "Bouarfa Airport"
    },
    {
      "city": "Boulder",
      "country": "United States",
      "IATA": "WBU",
      "icao_code": "KBDU",
      "airport_name": "Boulder Municipal Airport"
    },
    {
      "city": "Boulia",
      "country": "Australia",
      "IATA": "BQL",
      "icao_code": "YBOU",
      "airport_name": "Boulia Airport"
    },
    {
      "city": "Boundary Bay",
      "country": "Canada",
      "IATA": "YDT",
      "icao_code": "CZBB",
      "airport_name": "Boundary Bay Airport"
    },
    {
      "city": "Bourgas",
      "country": "Bulgaria",
      "IATA": "BOJ",
      "icao_code": "LBBG",
      "airport_name": "Burgas Airport"
    },
    {
      "city": "Bourges",
      "country": "France",
      "IATA": "BOU",
      "icao_code": "LFLD",
      "airport_name": "Bourges Airport"
    },
    {
      "city": "Bourke",
      "country": "Australia",
      "IATA": "BRK",
      "icao_code": "YBKE",
      "airport_name": "Bourke Airport"
    },
    {
      "city": "Bournemouth",
      "country": "United Kingdom",
      "IATA": "BOH",
      "icao_code": "EGHH",
      "airport_name": "Bournemouth Airport "
    },
    {
      "city": "Boven Digoel",
      "country": "Indonesia",
      "IATA": "TMH",
      "icao_code": "WAKT",
      "airport_name": "Tanah Merah Airport"
    },
    {
      "city": "Bowen",
      "country": "Australia",
      "IATA": "ZBO",
      "icao_code": "YBWN",
      "airport_name": "Bowen Airport"
    },
    {
      "city": "Bowling Green",
      "country": "United States",
      "IATA": "BWG",
      "icao_code": "KBWG",
      "airport_name": "Bowling Green-Warren County Regional Airport"
    },
    {
      "city": "Bozeman",
      "country": "United States",
      "IATA": "BZN",
      "icao_code": "KBZN",
      "airport_name": "Bozeman Yellowstone International Airport"
    },
    {
      "city": "Brac",
      "country": "Croatia",
      "IATA": "BWK",
      "icao_code": "LDSB",
      "airport_name": "Brač Airport"
    },
    {
      "city": "Bradford",
      "country": "United States",
      "IATA": "BFD",
      "icao_code": "KBFD",
      "airport_name": "Bradford Regional Airport"
    },
    {
      "city": "Bradshaw Field",
      "country": "United States",
      "IATA": "BSF",
      "airport_name": "Bradshaw Aaf Airport"
    },
    {
      "city": "Brady",
      "country": "United States",
      "IATA": "BBD",
      "icao_code": "KBBD",
      "airport_name": "Curtis Field"
    },
    {
      "city": "Braga",
      "country": "Portugal",
      "IATA": "BGZ",
      "icao_code": "LPBR",
      "airport_name": "Braga Airport"
    },
    {
      "city": "Braganca",
      "country": "Portugal",
      "IATA": "BGC",
      "icao_code": "LPBG",
      "airport_name": "Bragança Airport"
    },
    {
      "city": "Braganca Paulista",
      "country": "Brazil",
      "IATA": "BJP",
      "icao_code": "SBBP",
      "airport_name": "Bragança Paulista Airport"
    },
    {
      "city": "Brainerd",
      "country": "United States",
      "IATA": "BRD",
      "icao_code": "KBRD",
      "airport_name": "Brainerd Lakes Regional Airport"
    },
    {
      "city": "Brampton Island",
      "country": "Australia",
      "IATA": "BMP",
      "icao_code": "YBPI",
      "airport_name": "Brampton Island Airport"
    },
    {
      "city": "Brandon",
      "country": "Canada",
      "IATA": "YBR",
      "icao_code": "CYBR",
      "airport_name": "Brandon Municipal Airport"
    },
    {
      "city": "Branson",
      "country": "United States",
      "IATA": "BKG",
      "icao_code": "KBBG",
      "airport_name": "Branson Airport "
    },
    {
      "city": "Brasilia",
      "country": "Brazil",
      "IATA": "BSB",
      "icao_code": "SBBR",
      "airport_name": "Brasília International Airport"
    },
    {
      "city": "Bratislava",
      "country": "Slovakia",
      "IATA": "BTS",
      "icao_code": "LZIB",
      "airport_name": "M. R. Štefánik Airport"
    },
    {
      "city": "Bratsk",
      "country": "Russia",
      "IATA": "BTK",
      "icao_code": "UIBB",
      "airport_name": "Bratsk Airport"
    },
    {
      "city": "Braunschweig",
      "country": "Germany",
      "IATA": "BWE",
      "icao_code": "EDVE",
      "airport_name": "Braunschweig Airport"
    },
    {
      "city": "Brazzaville",
      "country": "Congo (Brazzaville)",
      "IATA": "BZV",
      "icao_code": "FCBB",
      "airport_name": "Maya Maya Airport"
    },
    {
      "city": "Breckenridge",
      "country": "United States",
      "IATA": "BKD",
      "airport_name": "Stephens County Airport"
    },
    {
      "city": "Bremen",
      "country": "Germany",
      "IATA": "BRE",
      "icao_code": "EDDW",
      "airport_name": "Bremen Airport"
    },
    {
      "city": "Bremerhaven",
      "country": "Germany",
      "IATA": "BRV",
      "airport_name": "Bremerhaven Airport"
    },
    {
      "city": "Bremerton",
      "country": "United States",
      "IATA": "PWT",
      "icao_code": "KPWT",
      "airport_name": "Bremerton National Airport"
    },
    {
      "city": "Brescia",
      "country": "Italy",
      "IATA": "VBS",
      "icao_code": "LIPO",
      "airport_name": "Brescia \"Gabriele D'Annunzio\" Airport"
    },
    {
      "city": "Brest",
      "country": "Belarus",
      "IATA": "BQT",
      "icao_code": "UMBB",
      "airport_name": "Brest Airport"
    },
    {
      "city": "Brest",
      "country": "France",
      "IATA": "BES",
      "icao_code": "LFRB",
      "airport_name": "Brest Bretagne Airport"
    },
    {
      "city": "Breves",
      "country": "Brazil",
      "IATA": "BVS",
      "icao_code": "SNVS",
      "airport_name": "Breves Airport"
    },
    {
      "city": "Brevig Mission",
      "country": "United States",
      "IATA": "KTS",
      "icao_code": "PFKT",
      "airport_name": "Brevig Mission Airport"
    },
    {
      "city": "Bridgetown",
      "country": "Barbados",
      "IATA": "BGI",
      "icao_code": "TBPB",
      "airport_name": "Grantley Adams International Airport"
    },
    {
      "city": "Brigham City",
      "country": "United States",
      "IATA": "BMC",
      "icao_code": "KBMC",
      "airport_name": "Brigham City Airport"
    },
    {
      "city": "Brindisi",
      "country": "Italy",
      "IATA": "BDS",
      "icao_code": "LIBR",
      "airport_name": "Brindisi – Salento Airport"
    },
    {
      "city": "Brisbane",
      "country": "Australia",
      "IATA": "BNE",
      "icao_code": "YBBN",
      "airport_name": "Brisbane Airport"
    },
    {
      "city": "Brisbane",
      "country": "Australia",
      "IATA": "ACF",
      "airport_name": "Brisbane Archerfield Airport"
    },
    {
      "city": "Bristol",
      "country": "United Kingdom",
      "IATA": "FZO",
      "airport_name": "Bristol Filton Airport"
    },
    {
      "city": "Bristol",
      "country": "United Kingdom",
      "IATA": "BRS",
      "icao_code": "EGGD",
      "airport_name": "Bristol International Airport"
    },
    {
      "city": "Brive",
      "country": "France",
      "IATA": "BVE",
      "icao_code": "LFBV",
      "airport_name": "Brive - La Roche Airport"
    },
    {
      "city": "Brize Norton",
      "country": "United Kingdom",
      "IATA": "BZZ",
      "icao_code": "EGVN",
      "airport_name": "Royal Air Force Brize Norton"
    },
    {
      "city": "Brno",
      "country": "Czech Republic",
      "IATA": "BRQ",
      "icao_code": "LKTB",
      "airport_name": "Brno-Turany Airport"
    },
    {
      "city": "Brochet",
      "country": "Canada",
      "IATA": "YBT",
      "icao_code": "CYBT",
      "airport_name": "Brochet Airport"
    },
    {
      "city": "Brockman",
      "country": "Australia",
      "IATA": "OCM",
      "icao_code": "YBGD",
      "airport_name": "Coondewanna Airport"
    },
    {
      "city": "Broken Hill",
      "country": "Australia",
      "IATA": "BHQ",
      "icao_code": "YBHI",
      "airport_name": "Broken Hill Airport"
    },
    {
      "city": "Bromont",
      "country": "Canada",
      "IATA": "ZBM",
      "icao_code": "CZBM",
      "airport_name": "Roland-Désourdy Airport"
    },
    {
      "city": "Bronnoysund",
      "country": "Norway",
      "IATA": "BNN",
      "icao_code": "ENBN",
      "airport_name": "Brønnøysund Airport, Brønnøy"
    },
    {
      "city": "Broome",
      "country": "Australia",
      "IATA": "BME",
      "icao_code": "YBRM",
      "airport_name": "Broome International Airport"
    },
    {
      "city": "Broomfield-CO",
      "country": "United States",
      "IATA": "BJC",
      "icao_code": "KBJC",
      "airport_name": "Rocky Mountain Metropolitan Airport"
    },
    {
      "city": "Broughton Island",
      "country": "Canada",
      "IATA": "YVM",
      "icao_code": "CYVM",
      "airport_name": "Qikiqtarjuaq Airport"
    },
    {
      "city": "Brownsville",
      "country": "United States",
      "IATA": "BRO",
      "icao_code": "KBRO",
      "airport_name": "Brownsville/South Padre Island International Airport"
    },
    {
      "city": "Brunswick",
      "country": "United States",
      "IATA": "NHZ",
      "icao_code": "KNHZ",
      "airport_name": "Naval Air Station Brunswick"
    },
    {
      "city": "Brunswick",
      "country": "United States",
      "IATA": "SSI",
      "icao_code": "KSSI",
      "airport_name": "Malcolm McKinnon Airport"
    },
    {
      "city": "Brunswick",
      "country": "United States",
      "IATA": "BQK",
      "icao_code": "KBQK",
      "airport_name": "Brunswick Golden Isles Airport"
    },
    {
      "city": "Brus Laguna",
      "country": "Honduras",
      "IATA": "BHG",
      "icao_code": "MHBL",
      "airport_name": "Brus Laguna Airport"
    },
    {
      "city": "Brussels",
      "country": "Belgium",
      "IATA": "BRU",
      "icao_code": "EBBR",
      "airport_name": "Brussels National Airport "
    },
    {
      "city": "Brusselton",
      "country": "Australia",
      "IATA": "BQB",
      "airport_name": "Busselton Regional Airport"
    },
    {
      "city": "Bryan",
      "country": "United States",
      "IATA": "CFD",
      "airport_name": "Coulter Field"
    },
    {
      "city": "Bryansk",
      "country": "Russia",
      "IATA": "BZK",
      "icao_code": "UUBP",
      "airport_name": "Bryansk International Airport"
    },
    {
      "city": "Bryce Canyon",
      "country": "United States",
      "IATA": "BCE",
      "icao_code": "KBCE",
      "airport_name": "Bryce Canyon Airport"
    },
    {
      "city": "Buariki",
      "country": "Kiribati",
      "IATA": "AAK",
      "icao_code": "NGUK",
      "airport_name": "Aranuka Airport"
    },
    {
      "city": "Bubaque",
      "country": "Guinea-Bissau",
      "IATA": "BQE",
      "icao_code": "GGBU",
      "airport_name": "Bubaque Airport"
    },
    {
      "city": "Bucaramanga",
      "country": "Colombia",
      "IATA": "BGA",
      "icao_code": "SKBG",
      "airport_name": "Palonegro International  Airport"
    },
    {
      "city": "Bucharest",
      "country": "Romania",
      "IATA": "OTP",
      "icao_code": "LROP",
      "airport_name": "Henri Coanda International Airport"
    },
    {
      "city": "Bucharest",
      "country": "Romania",
      "IATA": "BBU",
      "icao_code": "LRBS",
      "airport_name": "Bucharest Aurel Vlaicu Airport"
    },
    {
      "city": "Buckeye",
      "country": "United States",
      "IATA": "BXK",
      "icao_code": "KBXK",
      "airport_name": "Buckeye Municipal Airport"
    },
    {
      "city": "Buckland",
      "country": "United States",
      "IATA": "BKC",
      "icao_code": "PABL",
      "airport_name": "Buckland Airport "
    },
    {
      "city": "Buckley",
      "country": "United States",
      "IATA": "BFK",
      "airport_name": "Buckley Air Force Base"
    },
    {
      "city": "Budapest",
      "country": "Hungary",
      "IATA": "BUD",
      "icao_code": "LHBP",
      "airport_name": "Budapest Ferenc Liszt International Airport"
    },
    {
      "city": "Buenaventura",
      "country": "Colombia",
      "IATA": "BUN",
      "icao_code": "SKBU",
      "airport_name": "Gerardo Tobar López Airport"
    },
    {
      "city": "Buenos Aires",
      "country": "Argentina",
      "IATA": "EZE",
      "icao_code": "SAEZ",
      "airport_name": "Ministro Pistarini International Airport"
    },
    {
      "city": "Buenos Aires",
      "country": "Argentina",
      "IATA": "AEP",
      "icao_code": "SABE",
      "airport_name": "Jorge Newbery Airfield"
    },
    {
      "city": "Buenos Aires",
      "country": "Costa Rica",
      "IATA": "BAI",
      "icao_code": "MRBA",
      "airport_name": "Ministro Pistarini International Airport"
    },
    {
      "city": "Buffalo",
      "country": "United States",
      "IATA": "BUF",
      "icao_code": "KBUF",
      "airport_name": "Buffalo Niagara International Airport"
    },
    {
      "city": "Buffalo Narrows",
      "country": "Canada",
      "IATA": "YVT",
      "icao_code": "CYVT",
      "airport_name": "Buffalo Narrows Airport"
    },
    {
      "city": "Bugulma",
      "country": "Russia",
      "IATA": "UUA",
      "icao_code": "UWKB",
      "airport_name": "Bugulma Airport"
    },
    {
      "city": "Bujumbura",
      "country": "Burundi",
      "IATA": "BJM",
      "icao_code": "HBBA",
      "airport_name": "Bujumbura International Airport"
    },
    {
      "city": "Buka Island",
      "country": "Papua New Guinea",
      "IATA": "BUA",
      "icao_code": "AYBK",
      "airport_name": "Buka Island Airport"
    },
    {
      "city": "Bukavu/kavumu",
      "country": "Congo (Kinshasa)",
      "IATA": "BKY",
      "icao_code": "FZMA",
      "airport_name": "Kavumu Airport"
    },
    {
      "city": "Bukhara",
      "country": "Uzbekistan",
      "IATA": "BHK",
      "icao_code": "UTSB",
      "airport_name": "Bukhara International Airport"
    },
    {
      "city": "Bukoba",
      "country": "Tanzania",
      "IATA": "BKZ",
      "icao_code": "HTBU",
      "airport_name": "Bukoba Airport"
    },
    {
      "city": "Bulawayo",
      "country": "Zimbabwe",
      "IATA": "BUQ",
      "icao_code": "FVBU",
      "airport_name": "Joshua Mqabuko Nkomo International Airport"
    },
    {
      "city": "Bulgan",
      "country": "Mongolia",
      "IATA": "UGA",
      "icao_code": "ZMBN",
      "airport_name": "Bulgan Airport"
    },
    {
      "city": "Bullocks Harbour",
      "country": "Bahamas",
      "IATA": "GHC",
      "icao_code": "MYBG",
      "airport_name": "Great Harbour Cay Airport"
    },
    {
      "city": "Bulolo",
      "country": "Papua New Guinea",
      "IATA": "BUL",
      "icao_code": "AYBU",
      "airport_name": "Bulolo Airport"
    },
    {
      "city": "Bumba",
      "country": "Congo (Kinshasa)",
      "IATA": "BMB",
      "icao_code": "FZFU",
      "airport_name": "Bumbar Airport"
    },
    {
      "city": "Bumi Hills",
      "country": "Zimbabwe",
      "IATA": "BZH",
      "airport_name": "Bumi Hills Airstrip"
    },
    {
      "city": "Bunbury",
      "country": "Australia",
      "IATA": "BUY",
      "icao_code": "KBUY",
      "airport_name": "Bunbury Airport"
    },
    {
      "city": "Bundaberg",
      "country": "Australia",
      "IATA": "BDB",
      "icao_code": "YBUD",
      "airport_name": "Bundaberg Airport"
    },
    {
      "city": "Bunia",
      "country": "Congo (Kinshasa)",
      "IATA": "BUX",
      "icao_code": "FZKA",
      "airport_name": "Bunia Airport"
    },
    {
      "city": "Bunyu",
      "country": "Indonesia",
      "IATA": "BYQ",
      "icao_code": "WALV",
      "airport_name": "Bunyu Airport"
    },
    {
      "city": "Buochs",
      "country": "Switzerland",
      "IATA": "BXO",
      "icao_code": "LSZC",
      "airport_name": "Buochs Airport"
    },
    {
      "city": "Buol",
      "country": "Indonesia",
      "IATA": "UOL",
      "icao_code": "WAMQ",
      "airport_name": "Buol Airport"
    },
    {
      "city": "Buonmethuot",
      "country": "Vietnam",
      "IATA": "BMV",
      "icao_code": "VVBM",
      "airport_name": "Buon Ma Thuot Airport"
    },
    {
      "city": "Burao",
      "country": "Somalia",
      "IATA": "BUO",
      "icao_code": "HCMV",
      "airport_name": "Burao Airport"
    },
    {
      "city": "Los Angeles, All Airports",
      "country": "United States",
      "IATA": "LOSALL",
      "airport_name": "Los Angeles Area Airports"
    },
    {
      "city": "Los Angeles, Burbank",
      "country": "United States",
      "IATA": "BUR",
      "icao_code": "KBUR",
      "airport_name": "Bob Hope Airport"
    },
    {
      "city": "Burg Feuerstein",
      "country": "Germany",
      "IATA": "URD",
      "airport_name": "Burg Feuerstein Airport"
    },
    {
      "city": "Burgos",
      "country": "Spain",
      "IATA": "RGS",
      "icao_code": "LEBG",
      "airport_name": "Burgos Airport"
    },
    {
      "city": "Buri Ram",
      "country": "Thailand",
      "IATA": "BFV",
      "icao_code": "VTUO",
      "airport_name": "Buriram Airport"
    },
    {
      "city": "Burketown",
      "country": "Australia",
      "IATA": "BUC",
      "icao_code": "YBKT",
      "airport_name": "Burketown Airport"
    },
    {
      "city": "Burley",
      "country": "United States",
      "IATA": "BYI",
      "icao_code": "KBYI",
      "airport_name": "Burley Municipal Airport"
    },
    {
      "city": "Burlington",
      "country": "United States",
      "IATA": "BRL",
      "icao_code": "KBRL",
      "airport_name": "Southeast Iowa Regional Airport"
    },
    {
      "city": "Burlington",
      "country": "United States",
      "IATA": "BTV",
      "icao_code": "KBTV",
      "airport_name": "Burlington International Airport"
    },
    {
      "city": "Burnie",
      "country": "Australia",
      "IATA": "BWT",
      "icao_code": "YBUI",
      "airport_name": "Burnie Airport"
    },
    {
      "city": "Burns",
      "country": "United States",
      "IATA": "BNO",
      "icao_code": "KBNO",
      "airport_name": "Burns Municipal Airport"
    },
    {
      "city": "Burqin",
      "country": "China",
      "IATA": "KJI",
      "icao_code": "ZWKN",
      "airport_name": "Kanas Airport"
    },
    {
      "city": "Bursa",
      "country": "Turkey",
      "IATA": "BTZ",
      "airport_name": "Bursa Airport"
    },
    {
      "city": "Buru Island",
      "country": "Indonesia",
      "IATA": "NRE",
      "icao_code": "WAPG",
      "airport_name": "Namrole Airport"
    },
    {
      "city": "Burwash",
      "country": "Canada",
      "IATA": "YDB",
      "icao_code": "CYDB",
      "airport_name": "Burwash Airport"
    },
    {
      "city": "Busan",
      "country": "South Korea",
      "IATA": "PUS",
      "icao_code": "RKPK",
      "airport_name": "Gimhae International Airport"
    },
    {
      "city": "Bush Field",
      "country": "United States",
      "IATA": "AGS",
      "icao_code": "KAGS",
      "airport_name": "Augusta Regional Airport "
    },
    {
      "city": "Bushehr",
      "country": "Iran",
      "IATA": "BUZ",
      "icao_code": "OIBB",
      "airport_name": "Bushehr Airport"
    },
    {
      "city": "Busuanga",
      "country": "Philippines",
      "IATA": "USU",
      "icao_code": "RPVV",
      "airport_name": "Francisco B. Reyes Airport"
    },
    {
      "city": "Buta Zega",
      "country": "Congo (Kinshasa)",
      "IATA": "BZU",
      "icao_code": "FZKJ",
      "airport_name": "Buta Zega Airport"
    },
    {
      "city": "Butaritari",
      "country": "Kiribati",
      "IATA": "BBG",
      "icao_code": "NGTU",
      "airport_name": "Butaritari Atoll Airport"
    },
    {
      "city": "Butte",
      "country": "United States",
      "IATA": "BTM",
      "icao_code": "KBTM",
      "airport_name": "Bert Mooney Airport"
    },
    {
      "city": "Butterworth",
      "country": "Malaysia",
      "IATA": "BWH",
      "icao_code": "WMKB",
      "airport_name": "RMAF Butterworth"
    },
    {
      "city": "Butuan",
      "country": "Philippines",
      "IATA": "BXU",
      "icao_code": "RPWE",
      "airport_name": "Bancasi Airport"
    },
    {
      "city": "Buzios",
      "country": "Brazil",
      "IATA": "BZC",
      "icao_code": "SBBZ",
      "airport_name": "Umberto Modiano Airport"
    },
    {
      "city": "Bydgoszcz",
      "country": "Poland",
      "IATA": "BZG",
      "icao_code": "EPBY",
      "airport_name": "Bydgoszcz Ignacy Jan Paderewski Airport"
    },
    {
      "city": "Béchar",
      "country": "Algeria",
      "IATA": "CBH",
      "icao_code": "DAOR",
      "airport_name": "Boudghene Ben Ali Lotfi Airport"
    },
    {
      "city": "Büsum",
      "country": "Germany",
      "IATA": "HEI",
      "airport_name": "Heide–Büsum Airport"
    },
    {
      "city": "COSTA MARQUES",
      "country": "Brazil",
      "IATA": "CQS",
      "icao_code": "SWCQ",
      "airport_name": "Costa Marques Airport"
    },
    {
      "city": "Ca Mau",
      "country": "Vietnam",
      "IATA": "CAH",
      "icao_code": "VVCM",
      "airport_name": "Cà Mau Airport"
    },
    {
      "city": "Cabinda",
      "country": "Angola",
      "IATA": "CAB",
      "icao_code": "FNCA",
      "airport_name": "Cabinda Airport"
    },
    {
      "city": "Cabo Frio",
      "country": "Brazil",
      "IATA": "CFB",
      "icao_code": "SBCB",
      "airport_name": "Cabo Frio International Airport"
    },
    {
      "city": "Cabo Rojo",
      "country": "Dominican Republic",
      "IATA": "CBJ",
      "airport_name": "Cabo Rojo Airport"
    },
    {
      "city": "Cacador",
      "country": "Brazil",
      "IATA": "CFC",
      "icao_code": "SBCD",
      "airport_name": "Caçador Airport"
    },
    {
      "city": "Caceres",
      "country": "Brazil",
      "IATA": "CCX",
      "airport_name": "Cáceres Airport"
    },
    {
      "city": "Cacoal",
      "country": "Brazil",
      "IATA": "OAL",
      "icao_code": "SSKW",
      "airport_name": "Cacoal Airport"
    },
    {
      "city": "Caen",
      "country": "France",
      "IATA": "CFR",
      "icao_code": "LFRK",
      "airport_name": "Caen - Carpiquet Airport"
    },
    {
      "city": "Cagayan de Oro City",
      "country": "Philippines",
      "IATA": "CGY",
      "icao_code": "RPML",
      "airport_name": "Lumbia Air Base"
    },
    {
      "city": "Cagliari",
      "country": "Italy",
      "IATA": "CAG",
      "icao_code": "LIEE",
      "airport_name": "Cagliari Elmas Airport"
    },
    {
      "city": "Cahors",
      "country": "France",
      "IATA": "ZAO",
      "icao_code": "LFCC",
      "airport_name": "aerodrome of Cahors - Lalbenque "
    },
    {
      "city": "Caicara De Orinoco",
      "country": "Venezuela",
      "IATA": "CXA",
      "icao_code": "SVCD",
      "airport_name": "Caicara de Orinoco Airport"
    },
    {
      "city": "Cairns",
      "country": "Australia",
      "IATA": "CNS",
      "icao_code": "YBCS",
      "airport_name": "Cairns Airport"
    },
    {
      "city": "Cairo",
      "country": "Egypt",
      "IATA": "CWE",
      "airport_name": "Cairo West Air Base"
    },
    {
      "city": "Cairo",
      "country": "Egypt",
      "IATA": "CAI",
      "icao_code": "HECA",
      "airport_name": "Cairo International Airport"
    },
    {
      "city": "Cajamarca",
      "country": "Peru",
      "IATA": "CJA",
      "icao_code": "SPJB",
      "airport_name": "Major General FAP Armando Revoredo Iglesias Airport"
    },
    {
      "city": "Calabar",
      "country": "Nigeria",
      "IATA": "CBQ",
      "icao_code": "DNCA",
      "airport_name": "Margaret Ekpo International Airport"
    },
    {
      "city": "Calabozo",
      "country": "Venezuela",
      "IATA": "CLZ",
      "icao_code": "SVCL",
      "airport_name": "Calabozo Airport"
    },
    {
      "city": "Calais",
      "country": "France",
      "IATA": "CQF",
      "icao_code": "LFAC",
      "airport_name": "Calais - Dunkerque Airport"
    },
    {
      "city": "Calama",
      "country": "Chile",
      "IATA": "CJC",
      "icao_code": "SCCF",
      "airport_name": "El Loa Airport"
    },
    {
      "city": "Calbayog City",
      "country": "Philippines",
      "IATA": "CYP",
      "icao_code": "RPVC",
      "airport_name": "Calbayog Airport"
    },
    {
      "city": "Caldas Novas",
      "country": "Brazil",
      "IATA": "CLV",
      "icao_code": "SBCN",
      "airport_name": "Caldas Novas Airport"
    },
    {
      "city": "Caldwell",
      "country": "United States",
      "IATA": "CDW",
      "icao_code": "KCDW",
      "airport_name": "Essex County Airport"
    },
    {
      "city": "Calexico",
      "country": "United States",
      "IATA": "CXL",
      "icao_code": "KCXL",
      "airport_name": "Calexico International Airport"
    },
    {
      "city": "Calgary",
      "country": "Canada",
      "IATA": "YYC",
      "icao_code": "CYYC",
      "airport_name": "Calgary International Airport"
    },
    {
      "city": "Cali",
      "country": "Colombia",
      "IATA": "CLO",
      "icao_code": "SKCL",
      "airport_name": "Alfonso Bonilla Aragón International Airport"
    },
    {
      "city": "Calicut",
      "country": "India",
      "IATA": "CCJ",
      "icao_code": "VOCL",
      "airport_name": "Calicut International Airport"
    },
    {
      "city": "Caloundra",
      "country": "Australia",
      "IATA": "CUD",
      "icao_code": "YCDR",
      "airport_name": "Caloundra Airport"
    },
    {
      "city": "Calvi",
      "country": "France",
      "IATA": "CLY",
      "icao_code": "LFKC",
      "airport_name": "Calvi - Sainte-Catherine Airport"
    },
    {
      "city": "Camaguey",
      "country": "Cuba",
      "IATA": "CMW",
      "icao_code": "MUCM",
      "airport_name": "Ignacio Agramonte International Airport"
    },
    {
      "city": "Cambridge",
      "country": "United Kingdom",
      "IATA": "CBG",
      "icao_code": "EGSC",
      "airport_name": "Cambridge Airport"
    },
    {
      "city": "Cambridge Bay",
      "country": "Canada",
      "IATA": "YCB",
      "icao_code": "CYCB",
      "airport_name": "Cambridge Bay Airport"
    },
    {
      "city": "Camden",
      "country": "United States",
      "IATA": "CDN",
      "airport_name": "Woodward Field (airport)"
    },
    {
      "city": "Camden",
      "country": "Australia",
      "IATA": "CDU",
      "icao_code": "YSCN",
      "airport_name": "Camden Airport"
    },
    {
      "city": "Camiguin",
      "country": "Philippines",
      "IATA": "CGM",
      "airport_name": "Camiguin Airport"
    },
    {
      "city": "Camp Bastion",
      "country": "Afghanistan",
      "IATA": "OAZ",
      "icao_code": "OAZJ",
      "airport_name": "Camp Bastion Airport"
    },
    {
      "city": "Camp Douglas",
      "country": "United States",
      "IATA": "VOK",
      "icao_code": "KVOK",
      "airport_name": "Volk Field Air National Guard Base"
    },
    {
      "city": "Camp Springs",
      "country": "United States",
      "IATA": "ADW",
      "airport_name": "Andrews Field"
    },
    {
      "city": "Campbell River",
      "country": "Canada",
      "IATA": "YBL",
      "icao_code": "CYBL",
      "airport_name": "Campbell River Airport"
    },
    {
      "city": "Campbeltown",
      "country": "United Kingdom",
      "IATA": "CAL",
      "icao_code": "EGEC",
      "airport_name": "Campbeltown Airport "
    },
    {
      "city": "Campeche",
      "country": "Mexico",
      "IATA": "CPE",
      "icao_code": "MMCP",
      "airport_name": "Ing. Alberto Acuña Ongay International Airport"
    },
    {
      "city": "Campina Grande",
      "country": "Brazil",
      "IATA": "CPV",
      "icao_code": "SBKG",
      "airport_name": "Presidente João Suassuna Airport"
    },
    {
      "city": "Campinas",
      "country": "Brazil",
      "IATA": "CPQ",
      "icao_code": "SDAM",
      "airport_name": "Campo dos Amarais Airport"
    },
    {
      "city": "Campinas",
      "country": "Brazil",
      "IATA": "VCP",
      "icao_code": "SBKP",
      "airport_name": "Viracopos-Campinas International Airport"
    },
    {
      "city": "Campo Grande",
      "country": "Brazil",
      "IATA": "CGR",
      "icao_code": "SBCG",
      "airport_name": "Campo Grande International Airport"
    },
    {
      "city": "Campos",
      "country": "Brazil",
      "IATA": "CAW",
      "icao_code": "SBCP",
      "airport_name": "Campos–Bartolomeu Lysandro Airport "
    },
    {
      "city": "Can Tho",
      "country": "Vietnam",
      "IATA": "VCA",
      "icao_code": "VVCT",
      "airport_name": "Can Tho International Airport"
    },
    {
      "city": "Canaima",
      "country": "Venezuela",
      "IATA": "CAJ",
      "icao_code": "SVCN",
      "airport_name": "Canaima Airport"
    },
    {
      "city": "Canakkale",
      "country": "Turkey",
      "IATA": "CKZ",
      "icao_code": "LTBH",
      "airport_name": "Çanakkale Airport"
    },
    {
      "city": "Canarana",
      "country": "Brazil",
      "IATA": "CQA",
      "icao_code": "SWEK",
      "airport_name": "Canarana Airport"
    },
    {
      "city": "Canberra",
      "country": "Australia",
      "IATA": "CBR",
      "icao_code": "YSCB",
      "airport_name": "Canberra International Airport"
    },
    {
      "city": "Cancun",
      "country": "Mexico",
      "IATA": "CUN",
      "icao_code": "MMUN",
      "airport_name": "Cancun International Airport"
    },
    {
      "city": "Canefield",
      "country": "Dominica",
      "IATA": "DCF",
      "icao_code": "TDCF",
      "airport_name": "Canefield Airport"
    },
    {
      "city": "Cangyuan",
      "country": "China",
      "IATA": "CWJ",
      "icao_code": "ZPCW",
      "airport_name": "Cangyuan Washan Airport"
    },
    {
      "city": "Cannes",
      "country": "France",
      "IATA": "CEQ",
      "icao_code": "LFMD",
      "airport_name": "Cannes - Mandelieu Airport"
    },
    {
      "city": "Canouan Island",
      "country": "Saint Vincent and the Grenadines",
      "IATA": "CIW",
      "icao_code": "TVSC",
      "airport_name": "Canouan Airport"
    },
    {
      "city": "Canton Island",
      "country": "Kiribati",
      "IATA": "CIS",
      "icao_code": "PCIS",
      "airport_name": "Canton Island Airport"
    },
    {
      "city": "Cap Haitien",
      "country": "Haiti",
      "IATA": "CAP",
      "icao_code": "MTCH",
      "airport_name": "Hugo Chávez International Airport"
    },
    {
      "city": "Cap Skiring",
      "country": "Senegal",
      "IATA": "CSK",
      "icao_code": "GOGS",
      "airport_name": "Cap Skirring Airport"
    },
    {
      "city": "Cape Dorset",
      "country": "Canada",
      "IATA": "YTE",
      "icao_code": "CYTE",
      "airport_name": "Cape Dorset Airport"
    },
    {
      "city": "Cape Girardeau",
      "country": "United States",
      "IATA": "CGI",
      "icao_code": "KCGI",
      "airport_name": "Cape Girardeau Regional Airport"
    },
    {
      "city": "Cape Lisburne",
      "country": "United States",
      "IATA": "LUR",
      "icao_code": "PALU",
      "airport_name": "Cape Lisburne LRRS Airport"
    },
    {
      "city": "Cape Newenham",
      "country": "United States",
      "IATA": "EHM",
      "icao_code": "PAEH",
      "airport_name": "Cape Newenham LRRS Airport"
    },
    {
      "city": "Cape Romanzof",
      "country": "United States",
      "IATA": "CZF",
      "icao_code": "PACZ",
      "airport_name": "Cape Romanzof LRRS Airport"
    },
    {
      "city": "Cape Town",
      "country": "South Africa",
      "IATA": "CPT",
      "icao_code": "FACT",
      "airport_name": "Cape Town International Airport"
    },
    {
      "city": "Capurgana",
      "country": "Colombia",
      "IATA": "CPB",
      "icao_code": "SKCA",
      "airport_name": "Capurgana Airport"
    },
    {
      "city": "Caracas",
      "country": "Venezuela",
      "IATA": "CCS",
      "icao_code": "SVMI",
      "airport_name": "Simón Bolívar International Airport"
    },
    {
      "city": "Caransebes",
      "country": "Romania",
      "IATA": "CSB",
      "icao_code": "LRCS",
      "airport_name": "Caransebes Airport"
    },
    {
      "city": "Carauari",
      "country": "Brazil",
      "IATA": "CAF",
      "icao_code": "SWCA",
      "airport_name": "Carauari Airport"
    },
    {
      "city": "Caravelas",
      "country": "Brazil",
      "IATA": "CRQ",
      "icao_code": "SBCV",
      "airport_name": "Caravelas Airport"
    },
    {
      "city": "Carbondale/Murphysboro",
      "country": "United States",
      "IATA": "MDH",
      "icao_code": "KMDH",
      "airport_name": "Southern Illinois Airport"
    },
    {
      "city": "Carcassonne",
      "country": "France",
      "IATA": "CCF",
      "icao_code": "LFMK",
      "airport_name": "Carcassonne Airport"
    },
    {
      "city": "Cardiff",
      "country": "United Kingdom",
      "IATA": "CWL",
      "icao_code": "EGFF",
      "airport_name": "Cardiff Airport"
    },
    {
      "city": "Carepa",
      "country": "Colombia",
      "IATA": "APO",
      "icao_code": "SKLC",
      "airport_name": "Antonio Roldán Betancourt Airport"
    },
    {
      "city": "Caribou",
      "country": "United States",
      "IATA": "CAR",
      "icao_code": "KCAR",
      "airport_name": "Caribou Municipal Airport"
    },
    {
      "city": "Carlisle",
      "country": "United Kingdom",
      "IATA": "CAX",
      "icao_code": "EGNC",
      "airport_name": "Carlisle Lake District Airport"
    },
    {
      "city": "Carlsbad",
      "country": "United States",
      "IATA": "CLD",
      "icao_code": "KCRQ",
      "airport_name": "McClellan-Palomar Airport "
    },
    {
      "city": "Carlsbad",
      "country": "United States",
      "IATA": "CNM",
      "icao_code": "KCNM",
      "airport_name": "Cavern City Air Terminal"
    },
    {
      "city": "Carnarvon",
      "country": "Australia",
      "IATA": "CVQ",
      "icao_code": "YCAR",
      "airport_name": "Carnarvon Airport"
    },
    {
      "city": "Carnicobar",
      "country": "India",
      "IATA": "CBD",
      "airport_name": "Car Nicobar Air Force Base"
    },
    {
      "city": "Carolina",
      "country": "Brazil",
      "IATA": "CLN",
      "icao_code": "SBCI",
      "airport_name": "Brig. Lysias Augusto Rodrigues Airport"
    },
    {
      "city": "Carora",
      "country": "Venezuela",
      "IATA": "VCR",
      "icao_code": "SVCO",
      "airport_name": "Carora Airport"
    },
    {
      "city": "Carrillo",
      "country": "Costa Rica",
      "IATA": "PLD",
      "icao_code": "MRSR",
      "airport_name": "Playa Samara Airport"
    },
    {
      "city": "Cartagena",
      "country": "Colombia",
      "IATA": "CTG",
      "icao_code": "SKCG",
      "airport_name": "Rafael Núñez International Airport"
    },
    {
      "city": "Cartago",
      "country": "Colombia",
      "IATA": "CRC",
      "icao_code": "SKGO",
      "airport_name": "Santa Ana Airport"
    },
    {
      "city": "Cartwright",
      "country": "Canada",
      "IATA": "YRF",
      "icao_code": "CYCA",
      "airport_name": "Cartwright Airport"
    },
    {
      "city": "Caruaru",
      "country": "Brazil",
      "IATA": "CAU",
      "icao_code": "SNRU",
      "airport_name": "Caruaru Airport"
    },
    {
      "city": "Carupano",
      "country": "Venezuela",
      "IATA": "CUP",
      "icao_code": "SVCP",
      "airport_name": "General José Francisco Bermúdez Airport"
    },
    {
      "city": "Casa Grande",
      "country": "United States",
      "IATA": "CGZ",
      "icao_code": "KCGZ",
      "airport_name": "Casa Grande Municipal Airport"
    },
    {
      "city": "Casablanca",
      "country": "Morocco",
      "IATA": "CMN",
      "icao_code": "GMMN",
      "airport_name": "Mohammed V International Airport"
    },
    {
      "city": "Cascais",
      "country": "Portugal",
      "IATA": "CAT",
      "airport_name": "New Bight Airport"
    },
    {
      "city": "Cascavel",
      "country": "Brazil",
      "IATA": "CAC",
      "icao_code": "SBCA",
      "airport_name": "Adalberto Mendes da Silva Airport"
    },
    {
      "city": "Casper",
      "country": "United States",
      "IATA": "CPR",
      "icao_code": "KCPR",
      "airport_name": "Casper-Natrona County International Airport"
    },
    {
      "city": "Castellón de la Plana",
      "country": "Spain",
      "IATA": "CDT",
      "icao_code": "LECN",
      "airport_name": "Castellón–Costa Azahar Airport"
    },
    {
      "city": "Castlegar",
      "country": "Canada",
      "IATA": "YCG",
      "icao_code": "CYCG",
      "airport_name": "West Kootenay Regional Airport"
    },
    {
      "city": "Castres",
      "country": "France",
      "IATA": "DCM",
      "icao_code": "LFCK",
      "airport_name": "Castres-Mazamet Airport"
    },
    {
      "city": "Castries",
      "country": "Saint Lucia",
      "IATA": "SLU",
      "icao_code": "TLPC",
      "airport_name": "George F. L. Charles Airport"
    },
    {
      "city": "Castro",
      "country": "Chile",
      "IATA": "MHC",
      "icao_code": "SCPQ",
      "airport_name": "Mocopulli Airport"
    },
    {
      "city": "Cat Island",
      "country": "Bahamas",
      "IATA": "TBI",
      "icao_code": "MYCB",
      "airport_name": "New Bight Airport"
    },
    {
      "city": "Cat Lake",
      "country": "Canada",
      "IATA": "YAC",
      "icao_code": "CYAC",
      "airport_name": "Cat Lake Airport"
    },
    {
      "city": "Catalina Island",
      "country": "United States",
      "IATA": "AVX",
      "icao_code": "KAVX",
      "airport_name": "Pebbly Beach Seaplane Base"
    },
    {
      "city": "Catamarca",
      "country": "Argentina",
      "IATA": "CTC",
      "icao_code": "SANC",
      "airport_name": "Coronel Felipe Varela International Airport"
    },
    {
      "city": "Catania",
      "country": "Italy",
      "IATA": "CTA",
      "icao_code": "LICC",
      "airport_name": "Catania-Fontanarossa Airport"
    },
    {
      "city": "Catarman",
      "country": "Philippines",
      "IATA": "CRM",
      "icao_code": "RPVF",
      "airport_name": "Catarman National Airport"
    },
    {
      "city": "Caticlan",
      "country": "Philippines",
      "IATA": "MPH",
      "icao_code": "RPWY",
      "airport_name": "Godofredo P. Ramos Airport "
    },
    {
      "city": "Catumbela",
      "country": "Angola",
      "IATA": "CBT",
      "icao_code": "FNCM",
      "airport_name": "Catumbela Airport"
    },
    {
      "city": "Cauayan",
      "country": "Philippines",
      "IATA": "CYZ",
      "icao_code": "RPUY",
      "airport_name": "Cauayan Airport"
    },
    {
      "city": "Caucasia",
      "country": "Colombia",
      "IATA": "CAQ",
      "icao_code": "SKCU",
      "airport_name": "Juan H. White Airport"
    },
    {
      "city": "Caxias Do Sul",
      "country": "Brazil",
      "IATA": "CXJ",
      "icao_code": "SBCX",
      "airport_name": "Hugo Cantergiani Regional Airport"
    },
    {
      "city": "Cayenne",
      "country": "French Guiana",
      "IATA": "CAY",
      "icao_code": "SOCA",
      "airport_name": "Cayenne – Félix Eboué Airport"
    },
    {
      "city": "Cayes",
      "country": "Haiti",
      "IATA": "CYA",
      "airport_name": "Antoine-Simon Airport"
    },
    {
      "city": "Cayman Brac",
      "country": "Cayman Islands",
      "IATA": "CYB",
      "icao_code": "MWCB",
      "airport_name": "Sir Captain Charles Kirkconnell International Airport"
    },
    {
      "city": "Cayo Largo del Sur",
      "country": "Cuba",
      "IATA": "CYO",
      "icao_code": "MUCL",
      "airport_name": "Vilo Acuña Airport"
    },
    {
      "city": "Cayo Santa Maria",
      "country": "Cuba",
      "IATA": "BWW",
      "icao_code": "MUBR",
      "airport_name": "Las Brujas Airport"
    },
    {
      "city": "Cebu",
      "country": "Philippines",
      "IATA": "CEB",
      "icao_code": "RPMC",
      "airport_name": "Mactan-Cebu International Airport"
    },
    {
      "city": "Cedar City",
      "country": "United States",
      "IATA": "CDC",
      "icao_code": "KCDC",
      "airport_name": "Cedar City Regional Airport"
    },
    {
      "city": "Cedar Rapids",
      "country": "United States",
      "IATA": "CID",
      "icao_code": "KCID",
      "airport_name": "The Eastern Iowa Airport"
    },
    {
      "city": "Ceduna",
      "country": "Australia",
      "IATA": "CED",
      "icao_code": "YCDU",
      "airport_name": "Ceduna Airport"
    },
    {
      "city": "Ceiba",
      "country": "Puerto Rico",
      "IATA": "NRR",
      "icao_code": "TJNR",
      "airport_name": "José Aponte de la Torre Airport"
    },
    {
      "city": "Celaya",
      "country": "Mexico",
      "IATA": "CYW",
      "icao_code": "MMCY",
      "airport_name": "Captain Rogelio Castillo National Airport"
    },
    {
      "city": "Celle",
      "country": "Germany",
      "IATA": "ZCN",
      "airport_name": "Celle Air Base"
    },
    {
      "city": "Central",
      "country": "United States",
      "IATA": "CEM",
      "icao_code": "PACE",
      "airport_name": "Central Airport"
    },
    {
      "city": "Centralia",
      "country": "Canada",
      "IATA": "YCE",
      "icao_code": "CYCE",
      "airport_name": "Centralia/James T. Field Memorial Aerodrome"
    },
    {
      "city": "Cessnock",
      "country": "Australia",
      "IATA": "CES",
      "icao_code": "YCNK",
      "airport_name": "Cessnock Airport"
    },
    {
      "city": "Chacarita",
      "country": "Costa Rica",
      "IATA": "JAP",
      "airport_name": "Chacarita Airport"
    },
    {
      "city": "Chachapoyas",
      "country": "Peru",
      "IATA": "CHH",
      "icao_code": "SPPY",
      "airport_name": "Chachapoyas Airport"
    },
    {
      "city": "Chadron",
      "country": "United States",
      "IATA": "CDR",
      "icao_code": "KCDR",
      "airport_name": "Chadron Municipal Airport"
    },
    {
      "city": "Chaghcharan",
      "country": "Afghanistan",
      "IATA": "CCN",
      "icao_code": "OACC",
      "airport_name": "Chaghcharan Airport"
    },
    {
      "city": "Chah Bahar",
      "country": "Iran",
      "IATA": "ZBR",
      "icao_code": "OIZC",
      "airport_name": "Konarak Airport"
    },
    {
      "city": "Chaiten",
      "country": "Chile",
      "IATA": "WCH",
      "icao_code": "SCTN",
      "airport_name": "Chaiten Airport"
    },
    {
      "city": "Chalkyitsik",
      "country": "United States",
      "IATA": "CIK",
      "icao_code": "PACI",
      "airport_name": "Chalkyitsik Airport"
    },
    {
      "city": "Chalons",
      "country": "France",
      "IATA": "XCR",
      "icao_code": "LFOK",
      "airport_name": "Châlons Vatry Airport"
    },
    {
      "city": "Chambery",
      "country": "France",
      "IATA": "CMF",
      "icao_code": "LFLB",
      "airport_name": "Chambéry Airport"
    },
    {
      "city": "Champaign",
      "country": "United States",
      "IATA": "CMI",
      "icao_code": "KCMI",
      "airport_name": "University of Illinois Willard Airport"
    },
    {
      "city": "Chandigarh",
      "country": "India",
      "IATA": "IXC",
      "icao_code": "VICG",
      "airport_name": "Chandigarh International  Airport"
    },
    {
      "city": "Chandragarhi",
      "country": "Nepal",
      "IATA": "BDP",
      "icao_code": "VNCG",
      "airport_name": "Bhadrapur Airport "
    },
    {
      "city": "Changcha",
      "country": "China",
      "IATA": "CSX",
      "icao_code": "ZGHA",
      "airport_name": "Changsha Huanghua International Airport"
    },
    {
      "city": "Changchun",
      "country": "China",
      "IATA": "CGQ",
      "icao_code": "ZYCC",
      "airport_name": "Changchun Longjia International Airport"
    },
    {
      "city": "Changde",
      "country": "China",
      "IATA": "CGD",
      "icao_code": "ZGCD",
      "airport_name": "Changde Taohuayuan Airport"
    },
    {
      "city": "Changhai",
      "country": "China",
      "IATA": "CNI",
      "icao_code": "KCNI",
      "airport_name": "Shanghai Pudong International Airport"
    },
    {
      "city": "Changuinola",
      "country": "Panama",
      "IATA": "CHX",
      "icao_code": "MPCH",
      "airport_name": "Changuinola \"Capitán Manuel Niño\" International Airport"
    },
    {
      "city": "Changzhi",
      "country": "China",
      "IATA": "CIH",
      "icao_code": "ZBCZ",
      "airport_name": "Changzhi Wangcun Airport"
    },
    {
      "city": "Changzhou",
      "country": "China",
      "IATA": "CZX",
      "icao_code": "ZSCG",
      "airport_name": "Changzhou Benniu Airport"
    },
    {
      "city": "Chania",
      "country": "Greece",
      "IATA": "CHQ",
      "icao_code": "LGSA",
      "airport_name": "Chania International Airport "
    },
    {
      "city": "Chanute",
      "country": "United States",
      "IATA": "CNU",
      "icao_code": "KCNU",
      "airport_name": "Chanute Martin Johnson Airport"
    },
    {
      "city": "Chaoyang",
      "country": "China",
      "IATA": "CHG",
      "icao_code": "ZYCY",
      "airport_name": "Chaoyang Airport"
    },
    {
      "city": "Chapacura",
      "country": "Bolivia",
      "IATA": "CCA",
      "airport_name": "Chimore Airport"
    },
    {
      "city": "Chapeco",
      "country": "Brazil",
      "IATA": "XAP",
      "icao_code": "SBCH",
      "airport_name": "Chapecó Airport"
    },
    {
      "city": "Chapleau",
      "country": "Canada",
      "IATA": "YLD",
      "icao_code": "CYLD",
      "airport_name": "Chapleau Airport"
    },
    {
      "city": "Chardzhou",
      "country": "Turkmenistan",
      "IATA": "CRZ",
      "airport_name": "Turkmenabat Airport"
    },
    {
      "city": "Charleroi",
      "country": "Belgium",
      "IATA": "CRL",
      "icao_code": "EBCI",
      "airport_name": "Brussels South Charleroi Airport"
    },
    {
      "city": "Charles City",
      "country": "United States",
      "IATA": "CCY",
      "icao_code": "KCCY",
      "airport_name": "Northeast Iowa Regional Airport"
    },
    {
      "city": "Charleston",
      "country": "United States",
      "IATA": "CRW",
      "icao_code": "KCRW",
      "airport_name": "Yeager Airport"
    },
    {
      "city": "Charleston",
      "country": "United States",
      "IATA": "CHS",
      "icao_code": "KCHS",
      "airport_name": "Charleston International Airport"
    },
    {
      "city": "Charlestown",
      "country": "Saint Kitts and Nevis",
      "IATA": "NEV",
      "icao_code": "TKPN",
      "airport_name": "Vance W. Amory International Airport"
    },
    {
      "city": "Charleville",
      "country": "France",
      "IATA": "XCZ",
      "airport_name": "Charleville-Mézières Airport"
    },
    {
      "city": "Charlevoix",
      "country": "Canada",
      "IATA": "YML",
      "icao_code": "CYML",
      "airport_name": "Charlevoix Airport"
    },
    {
      "city": "Charlieville",
      "country": "Australia",
      "IATA": "CTL",
      "icao_code": "YBCV",
      "airport_name": "Charleville Airport"
    },
    {
      "city": "Charlo",
      "country": "Canada",
      "IATA": "YCL",
      "icao_code": "CYCL",
      "airport_name": "Charlo Airport"
    },
    {
      "city": "Charlotte",
      "country": "United States",
      "IATA": "CLT",
      "icao_code": "KCLT",
      "airport_name": "Charlotte Douglas International Airport"
    },
    {
      "city": "Charlotte Amalie",
      "country": "Virgin Islands",
      "IATA": "SPB",
      "airport_name": "Charlotte Amalie Harbor Seaplane Base "
    },
    {
      "city": "Charlottesville VA",
      "country": "United States",
      "IATA": "CHO",
      "icao_code": "KCHO",
      "airport_name": "Charlottesville-Albemarle Airport"
    },
    {
      "city": "Charlottetown",
      "country": "Canada",
      "IATA": "YYG",
      "icao_code": "CYYG",
      "airport_name": "Charlottetown Airport"
    },
    {
      "city": "Chateauroux",
      "country": "France",
      "IATA": "CHR",
      "icao_code": "LFLX",
      "airport_name": "Châteauroux-Centre \"Marcel Dassault\" Airport"
    },
    {
      "city": "Chatham",
      "country": "Canada",
      "IATA": "YCH",
      "icao_code": "CYCH",
      "airport_name": "Miramichi Airport"
    },
    {
      "city": "Chatham Island",
      "country": "New Zealand",
      "IATA": "CHT",
      "icao_code": "NZCI",
      "airport_name": "Chatham Islands / Tuuta Airport"
    },
    {
      "city": "Chatsworth",
      "country": "Australia",
      "IATA": "CWT",
      "icao_code": "YCWR",
      "airport_name": "Cowra Airport"
    },
    {
      "city": "Chattanooga",
      "country": "United States",
      "IATA": "CHA",
      "icao_code": "KCHA",
      "airport_name": "Chattanooga Metropolitan Airport "
    },
    {
      "city": "Chañaral",
      "country": "Chile",
      "IATA": "CNR",
      "icao_code": "SCRA",
      "airport_name": "Chañaral Airport"
    },
    {
      "city": "Cheboksary",
      "country": "Russia",
      "IATA": "CSY",
      "icao_code": "UWKS",
      "airport_name": "Cheboksary Airport"
    },
    {
      "city": "Chefornak",
      "country": "United States",
      "IATA": "CYF",
      "icao_code": "PACK",
      "airport_name": "Chefornak Airport "
    },
    {
      "city": "Chehalis",
      "country": "United States",
      "IATA": "CLS",
      "airport_name": "Chehalis-Centralia Airport"
    },
    {
      "city": "Cheju",
      "country": "South Korea",
      "IATA": "CJU",
      "icao_code": "RKPC",
      "airport_name": "Jeju International Airport"
    },
    {
      "city": "Chelyabinsk",
      "country": "Russia",
      "IATA": "CEK",
      "icao_code": "USCC",
      "airport_name": "Chelyabinsk Airport"
    },
    {
      "city": "Chenega",
      "country": "United States",
      "IATA": "NCN",
      "icao_code": "PFCB",
      "airport_name": "Chenega Bay Airport "
    },
    {
      "city": "Chengde",
      "country": "China",
      "IATA": "CDE",
      "airport_name": "Cape Decision Coast Guard Heliport"
    },
    {
      "city": "Chengdu",
      "country": "China",
      "IATA": "CTU",
      "icao_code": "ZUUU",
      "airport_name": "Chengdu Shuangliu International Airport"
    },
    {
      "city": "Cheraw",
      "country": "United States",
      "IATA": "HCW",
      "icao_code": "KCQW",
      "airport_name": "Cheraw Municipal Airport "
    },
    {
      "city": "Cherbourg",
      "country": "France",
      "IATA": "CER",
      "icao_code": "LFRC",
      "airport_name": "Cherbourg - Maupertus Airport"
    },
    {
      "city": "Cherepovets",
      "country": "Russia",
      "IATA": "CEE",
      "icao_code": "ULBC",
      "airport_name": "Cherepovets Airport"
    },
    {
      "city": "Cherkassy",
      "country": "Ukraine",
      "IATA": "CKC",
      "icao_code": "UKKE",
      "airport_name": "Cherkassy Airport"
    },
    {
      "city": "Chernigov",
      "country": "Ukraine",
      "IATA": "CEJ",
      "airport_name": "Chernihiv Shestovitsa Airport"
    },
    {
      "city": "Chernovtsk",
      "country": "Ukraine",
      "IATA": "CWC",
      "icao_code": "UKLN",
      "airport_name": "Chernivtsi International Airport"
    },
    {
      "city": "Cherskiy",
      "country": "Russia",
      "IATA": "CYX",
      "icao_code": "UESS",
      "airport_name": "Cherskiy Airport"
    },
    {
      "city": "Chesterfield Inlet",
      "country": "Canada",
      "IATA": "YCS",
      "icao_code": "CYCS",
      "airport_name": "Chesterfield Inlet Airport"
    },
    {
      "city": "Chetumal",
      "country": "Mexico",
      "IATA": "CTM",
      "icao_code": "MMCM",
      "airport_name": "Chetumal International Airport"
    },
    {
      "city": "Chetwynd",
      "country": "Canada",
      "IATA": "YCQ",
      "icao_code": "CYCQ",
      "airport_name": "Chetwynd Airport"
    },
    {
      "city": "Chevak",
      "country": "United States",
      "IATA": "VAK",
      "icao_code": "PAVA",
      "airport_name": "Chevak Airport"
    },
    {
      "city": "Chevery",
      "country": "Canada",
      "IATA": "YHR",
      "icao_code": "CYHR",
      "airport_name": "Chevery Airport"
    },
    {
      "city": "Cheyenne",
      "country": "United States",
      "IATA": "CYS",
      "icao_code": "KCYS",
      "airport_name": "Cheyenne Regional Airport "
    },
    {
      "city": "Chiang Mai",
      "country": "Thailand",
      "IATA": "CNX",
      "icao_code": "VTCC",
      "airport_name": "Chiang Mai International Airport"
    },
    {
      "city": "Chiang Rai",
      "country": "Thailand",
      "IATA": "CEI",
      "icao_code": "VTCT",
      "airport_name": "Mae Fah Luang–Chiang Rai International Airport"
    },
    {
      "city": "Chiayi",
      "country": "Taiwan",
      "IATA": "CYI",
      "icao_code": "RCKU",
      "airport_name": "Chiayi Airport"
    },
    {
      "city": "Chibougamau",
      "country": "Canada",
      "IATA": "YMT",
      "icao_code": "CYMT",
      "airport_name": "Chibougamau/Chapais Airport"
    },
    {
      "city": "Chicago, All Airports",
      "country": "United States",
      "IATA": "CGXALL",
      "airport_name": "Chicago Area Airports"
    },
    {
      "city": "Chicago",
      "country": "United States",
      "IATA": "CGX",
      "airport_name": "Merrill C. Meigs Field"
    },
    {
      "city": "Chicago",
      "country": "United States",
      "IATA": "ORD",
      "icao_code": "KORD",
      "airport_name": "O'Hare International Airport "
    },
    {
      "city": "Chicago",
      "country": "United States",
      "IATA": "UGN",
      "icao_code": "KUGN",
      "airport_name": "Waukegan National Airport"
    },
    {
      "city": "Chicago",
      "country": "United States",
      "IATA": "MDW",
      "icao_code": "KMDW",
      "airport_name": "Midway International Airport"
    },
    {
      "city": "Chicago-Wheeling",
      "country": "United States",
      "IATA": "PWK",
      "icao_code": "KPWK",
      "airport_name": "Chicago Executive Airport "
    },
    {
      "city": "Chichen Itza",
      "country": "Mexico",
      "IATA": "CZA",
      "icao_code": "MMCT",
      "airport_name": "Chichen Itza International Airport"
    },
    {
      "city": "Chiclayo",
      "country": "Peru",
      "IATA": "CIX",
      "icao_code": "SPHI",
      "airport_name": "FAP Captain José Abelardo Quiñones Gonzáles International Airport"
    },
    {
      "city": "Chico",
      "country": "United States",
      "IATA": "CIC",
      "icao_code": "KCIC",
      "airport_name": "Chico Municipal Airport"
    },
    {
      "city": "Chicopee Falls",
      "country": "United States",
      "IATA": "CEF",
      "icao_code": "KCEF",
      "airport_name": "Westover Metropolitan Airport"
    },
    {
      "city": "Chifeng",
      "country": "China",
      "IATA": "CIF",
      "icao_code": "ZBCF",
      "airport_name": "Chifeng Yulong Airport"
    },
    {
      "city": "Chihuahua",
      "country": "Mexico",
      "IATA": "CUU",
      "icao_code": "MMCU",
      "airport_name": "General Roberto Fierro Villalobos International Airport"
    },
    {
      "city": "Childress",
      "country": "United States",
      "IATA": "CDS",
      "icao_code": "KCDS",
      "airport_name": "Childress Municipal Airport"
    },
    {
      "city": "Chile Chico",
      "country": "Chile",
      "IATA": "CCH",
      "icao_code": "SCCC",
      "airport_name": "Chile Chico Airport"
    },
    {
      "city": "Chillan",
      "country": "Chile",
      "IATA": "YAI",
      "icao_code": "SCCH",
      "airport_name": "General Bernardo O'Higgins Airport"
    },
    {
      "city": "Chilliwack",
      "country": "Canada",
      "IATA": "YCW",
      "icao_code": "CYCW",
      "airport_name": "Chilliwack Airport"
    },
    {
      "city": "Chimbote",
      "country": "Peru",
      "IATA": "CHM",
      "icao_code": "SPEO",
      "airport_name": "Tnte. FAP Jaime Montreuil Morales Airport"
    },
    {
      "city": "Chimkent",
      "country": "Kazakhstan",
      "IATA": "CIT",
      "icao_code": "UAII",
      "airport_name": "Shymkent International  Airport"
    },
    {
      "city": "Chimoio",
      "country": "Mozambique",
      "IATA": "VPY",
      "icao_code": "FQCH",
      "airport_name": "Chimoio Airport"
    },
    {
      "city": "Chinchilla",
      "country": "Australia",
      "IATA": "CCL",
      "icao_code": "YCCA",
      "airport_name": "Chinchilla Airport"
    },
    {
      "city": "Chinhae",
      "country": "South Korea",
      "IATA": "CHF",
      "icao_code": "RKPE",
      "airport_name": "Jinhae Airport"
    },
    {
      "city": "Chino",
      "country": "United States",
      "IATA": "CNO",
      "icao_code": "KCNO",
      "airport_name": "Chino Airport"
    },
    {
      "city": "Chios",
      "country": "Greece",
      "IATA": "JKH",
      "icao_code": "LGHI",
      "airport_name": "Chios Island National Airport"
    },
    {
      "city": "Chipata",
      "country": "Zambia",
      "IATA": "CIP",
      "icao_code": "FLCP",
      "airport_name": "Chipata Airport"
    },
    {
      "city": "Chiredzi",
      "country": "Zimbabwe",
      "IATA": "BFO",
      "icao_code": "FVCZ",
      "airport_name": "Buffalo Range Airport"
    },
    {
      "city": "Chisasibi",
      "country": "Canada",
      "IATA": "YKU",
      "icao_code": "CSU2",
      "airport_name": "Chisasibi Airport"
    },
    {
      "city": "Chisinau",
      "country": "Moldova",
      "IATA": "KIV",
      "icao_code": "LUKK",
      "airport_name": "Chisinau International Airport"
    },
    {
      "city": "Chita",
      "country": "Russia",
      "IATA": "HTA",
      "icao_code": "UIAA",
      "airport_name": "CHITA-Kadala International Airport "
    },
    {
      "city": "Chitral",
      "country": "Pakistan",
      "IATA": "CJL",
      "icao_code": "OPCH",
      "airport_name": "Chitral Airport"
    },
    {
      "city": "Chitré",
      "country": "Panama",
      "IATA": "CTD",
      "airport_name": "Chitré Alonso Valderrama Airport"
    },
    {
      "city": "Chittagong",
      "country": "Bangladesh",
      "IATA": "CGP",
      "icao_code": "VGEG",
      "airport_name": "Shah Amanat International Airport"
    },
    {
      "city": "Chizhou",
      "country": "China",
      "IATA": "JUH",
      "airport_name": "Chizhou Jiuhuashan Airport"
    },
    {
      "city": "Choibalsan",
      "country": "Mongolia",
      "IATA": "COQ",
      "icao_code": "ZMCD",
      "airport_name": "Choibalsan Airport"
    },
    {
      "city": "Choiseul Bay",
      "country": "Solomon Islands",
      "IATA": "CHY",
      "icao_code": "AGGC",
      "airport_name": "Choiseul Bay Airport"
    },
    {
      "city": "Chokurdah",
      "country": "Russia",
      "IATA": "CKH",
      "icao_code": "UESO",
      "airport_name": "Chokurdah Airport"
    },
    {
      "city": "Cholet",
      "country": "France",
      "IATA": "CET",
      "airport_name": "Cholet Le Pontreau Airport"
    },
    {
      "city": "Chongjin",
      "country": "North Korea",
      "IATA": "RGO",
      "airport_name": "Chongjin Airport"
    },
    {
      "city": "Chongju",
      "country": "South Korea",
      "IATA": "CJJ",
      "icao_code": "RKTU",
      "airport_name": "Cheong Ju International Airport"
    },
    {
      "city": "Chongqing",
      "country": "China",
      "IATA": "CKG",
      "icao_code": "ZUCK",
      "airport_name": "Chongqing Jiangbei International Airport"
    },
    {
      "city": "Chosmadal",
      "country": "Argentina",
      "IATA": "HOS",
      "airport_name": "Chos Malal Airport "
    },
    {
      "city": "Christchurch",
      "country": "New Zealand",
      "IATA": "CHC",
      "icao_code": "NZCH",
      "airport_name": "Christchurch International Airport"
    },
    {
      "city": "Christmas Island",
      "country": "Christmas Island",
      "IATA": "XCH",
      "icao_code": "YPXM",
      "airport_name": "Christmas Island Airport"
    },
    {
      "city": "Chu Lai",
      "country": "Vietnam",
      "IATA": "VCL",
      "airport_name": "Chu Lai International Airport "
    },
    {
      "city": "Chuathbaluk",
      "country": "United States",
      "IATA": "CHU",
      "icao_code": "PACH",
      "airport_name": "Chuathbaluk Airport "
    },
    {
      "city": "Chub Cay",
      "country": "Bahamas",
      "IATA": "CCZ",
      "icao_code": "MYBC",
      "airport_name": "Chub Cay International Airport"
    },
    {
      "city": "Chumphon",
      "country": "Thailand",
      "IATA": "CJM",
      "icao_code": "VTSE",
      "airport_name": "Chumphon Airport"
    },
    {
      "city": "Churchill",
      "country": "Canada",
      "IATA": "YYQ",
      "icao_code": "CYYQ",
      "airport_name": "Churchill Airport"
    },
    {
      "city": "Churchill Falls",
      "country": "Canada",
      "IATA": "ZUM",
      "icao_code": "CZUM",
      "airport_name": "Churchill Falls Airport"
    },
    {
      "city": "Chuuk",
      "country": "Micronesia",
      "IATA": "TKK",
      "icao_code": "PTKK",
      "airport_name": "Chuuk International Airport"
    },
    {
      "city": "Cicia",
      "country": "Fiji",
      "IATA": "ICI",
      "icao_code": "NFCI",
      "airport_name": "Cicia Airport"
    },
    {
      "city": "Ciego De Avila",
      "country": "Cuba",
      "IATA": "AVI",
      "icao_code": "MUCA",
      "airport_name": "Máximo Gómez Airport"
    },
    {
      "city": "Cienfuegos",
      "country": "Cuba",
      "IATA": "CFG",
      "icao_code": "MUCF",
      "airport_name": "Jaime González International Airport"
    },
    {
      "city": "Cilacap",
      "country": "Indonesia",
      "IATA": "CXP",
      "airport_name": "Tunggul Wulung Airport"
    },
    {
      "city": "Cimei",
      "country": "Taiwan",
      "IATA": "CMJ",
      "icao_code": "RCCM",
      "airport_name": "Cimei Airport"
    },
    {
      "city": "Cincinnati",
      "country": "United States",
      "IATA": "LUK",
      "icao_code": "KLUK",
      "airport_name": "Cincinnati Municipal Lunken Airport"
    },
    {
      "city": "Cincinnati",
      "country": "United States",
      "IATA": "CVG",
      "icao_code": "KCVG",
      "airport_name": "Cincinnati/Northern Kentucky International Airport"
    },
    {
      "city": "Circle",
      "country": "United States",
      "IATA": "IRC",
      "icao_code": "SCIR",
      "airport_name": "Circle City Airport"
    },
    {
      "city": "Cirebon",
      "country": "Indonesia",
      "IATA": "CBN",
      "icao_code": "WICD",
      "airport_name": "Penggung Airport"
    },
    {
      "city": "Ciudad Acuna",
      "country": "Mexico",
      "IATA": "ACN",
      "icao_code": "MMCC",
      "airport_name": "Ciudad Acuña International Airport"
    },
    {
      "city": "Ciudad Bolivar",
      "country": "Venezuela",
      "IATA": "CBL",
      "icao_code": "SVCB",
      "airport_name": "Tomás de Heres Airport"
    },
    {
      "city": "Ciudad Constitución",
      "country": "Mexico",
      "IATA": "CUA",
      "icao_code": "MMDA",
      "airport_name": "Ciudad Constitución  Airport"
    },
    {
      "city": "Ciudad Del Carmen",
      "country": "Mexico",
      "IATA": "CME",
      "icao_code": "MMCE",
      "airport_name": "Ciudad del Carmen International Airport"
    },
    {
      "city": "Ciudad Juarez",
      "country": "Mexico",
      "IATA": "CJS",
      "icao_code": "MMCS",
      "airport_name": "Abraham Gonzalez International Airport"
    },
    {
      "city": "Ciudad Obregon",
      "country": "Mexico",
      "IATA": "CEN",
      "icao_code": "MMCN",
      "airport_name": "Ciudad Obregón International Airport"
    },
    {
      "city": "Ciudad Real",
      "country": "Spain",
      "IATA": "CQM",
      "icao_code": "LERL",
      "airport_name": "Ciudad Real Central Airport"
    },
    {
      "city": "Ciudad Victoria",
      "country": "Mexico",
      "IATA": "CVM",
      "icao_code": "MMCV",
      "airport_name": "General Pedro J. Méndez International Airport"
    },
    {
      "city": "Ciudad del Este",
      "country": "Paraguay",
      "IATA": "AGT",
      "icao_code": "SGES",
      "airport_name": "Guaraní International Airport "
    },
    {
      "city": "Cizre",
      "country": "Turkey",
      "IATA": "NKT",
      "icao_code": "LTCV",
      "airport_name": "Marine Corps Air Station Cherry Point"
    },
    {
      "city": "Clarence Bain",
      "country": "Bahamas",
      "IATA": "MAY",
      "airport_name": "Clarence A. Bain Airport"
    },
    {
      "city": "Claris",
      "country": "New Zealand",
      "IATA": "GBZ",
      "icao_code": "NZGB",
      "airport_name": "Great Barrier Aerodrome "
    },
    {
      "city": "Clarks Point",
      "country": "United States",
      "IATA": "CLP",
      "icao_code": "PFCL",
      "airport_name": "Clarks Point Airport"
    },
    {
      "city": "Clarksburg",
      "country": "United States",
      "IATA": "CKB",
      "icao_code": "KCKB",
      "airport_name": "North Central West Virginia Airport"
    },
    {
      "city": "Clarksville",
      "country": "United States",
      "IATA": "CKV",
      "icao_code": "KCKV",
      "airport_name": "Clarksville-Montgomery County Regional Airport"
    },
    {
      "city": "Clearwater",
      "country": "United States",
      "IATA": "CLW",
      "icao_code": "KCLW",
      "airport_name": "Clearwater Air Park"
    },
    {
      "city": "Clemson",
      "country": "United States",
      "IATA": "CEU",
      "icao_code": "KCEU",
      "airport_name": "Oconee County Regional Airport"
    },
    {
      "city": "Clermont",
      "country": "Australia",
      "IATA": "CMQ",
      "icao_code": "YCMT",
      "airport_name": "Clermont Airport"
    },
    {
      "city": "Clermont-Ferrand",
      "country": "France",
      "IATA": "CFE",
      "icao_code": "LFLC",
      "airport_name": "Clermont-Ferrand Auvergne Airport"
    },
    {
      "city": "Cleveland",
      "country": "United States",
      "IATA": "HDI",
      "airport_name": "Hardwick Field"
    },
    {
      "city": "Cleveland",
      "country": "United States",
      "IATA": "BKL",
      "icao_code": "KBKL",
      "airport_name": "Cleveland Burke Lakefront Airport"
    },
    {
      "city": "Cleveland",
      "country": "United States",
      "IATA": "CLE",
      "icao_code": "KCLE",
      "airport_name": "Cleveland Hopkins International Airport"
    },
    {
      "city": "Clinton",
      "country": "United States",
      "IATA": "CSM",
      "icao_code": "KCSM",
      "airport_name": "Clinton-Sherman Industrial Airpark"
    },
    {
      "city": "Clinton",
      "country": "United States",
      "IATA": "CWI",
      "icao_code": "KCWI",
      "airport_name": "Clinton Municipal Airport"
    },
    {
      "city": "Cloncurry",
      "country": "Australia",
      "IATA": "CNJ",
      "icao_code": "YCCY",
      "airport_name": "Cloncurry Airport"
    },
    {
      "city": "Cloudbreak",
      "country": "Australia",
      "IATA": "KFE",
      "airport_name": "Fortescue - Dave Forrest Aerodrome"
    },
    {
      "city": "Clovis",
      "country": "United States",
      "IATA": "CVN",
      "icao_code": "KCVN",
      "airport_name": "Clovis Municipal Airport"
    },
    {
      "city": "Clovis",
      "country": "United States",
      "IATA": "CVS",
      "airport_name": "Cannon Air Force Base"
    },
    {
      "city": "Club Makokola",
      "country": "Malawi",
      "IATA": "CMK",
      "icao_code": "FWCM",
      "airport_name": "Club Makokola Airport"
    },
    {
      "city": "Cluj-napoca",
      "country": "Romania",
      "IATA": "CLJ",
      "icao_code": "LRCL",
      "airport_name": "Avram Iancu Cluj International Airport"
    },
    {
      "city": "Clyde River",
      "country": "Canada",
      "IATA": "YCY",
      "icao_code": "CYCY",
      "airport_name": "Clyde River Airport"
    },
    {
      "city": "Coari",
      "country": "Brazil",
      "IATA": "CIZ",
      "icao_code": "SWKO",
      "airport_name": "Coari Airport"
    },
    {
      "city": "Coatesville",
      "country": "United States",
      "IATA": "CTH",
      "icao_code": "KMQS",
      "airport_name": "Chester County G. O. Carlson Airport "
    },
    {
      "city": "Coban",
      "country": "Guatemala",
      "IATA": "CBV",
      "icao_code": "MGCB",
      "airport_name": "Cobán Airport "
    },
    {
      "city": "Cobar",
      "country": "Australia",
      "IATA": "CAZ",
      "icao_code": "YCBA",
      "airport_name": "Cobar Airport"
    },
    {
      "city": "Cobija",
      "country": "Bolivia",
      "IATA": "CIJ",
      "icao_code": "SLCO",
      "airport_name": "Captain Aníbal Arab Airport"
    },
    {
      "city": "Coca",
      "country": "Ecuador",
      "IATA": "OCC",
      "icao_code": "SECO",
      "airport_name": "Francisco de Orellana Airport"
    },
    {
      "city": "Cochabamba",
      "country": "Bolivia",
      "IATA": "CBB",
      "icao_code": "SLCB",
      "airport_name": "Jorge Wilstermann International Airport"
    },
    {
      "city": "Cochrane",
      "country": "Canada",
      "IATA": "YCN",
      "icao_code": "CYCN",
      "airport_name": "Cochrane Airport"
    },
    {
      "city": "Cochstedt",
      "country": "Germany",
      "IATA": "CSO",
      "airport_name": "Magdeburg/Cochstedt Airport"
    },
    {
      "city": "Cockburn Town",
      "country": "Turks and Caicos Islands",
      "IATA": "GDT",
      "icao_code": "MBGT",
      "airport_name": "JAGS McCartney International Airport"
    },
    {
      "city": "Cockburn Town",
      "country": "Bahamas",
      "IATA": "ZSA",
      "icao_code": "MYSM",
      "airport_name": "San Salvador Airport"
    },
    {
      "city": "Coco Beach",
      "country": "United States",
      "IATA": "COF",
      "airport_name": "Patrick Air Force Base"
    },
    {
      "city": "Coconut Island",
      "country": "Australia",
      "IATA": "CNC",
      "icao_code": "YCCT",
      "airport_name": "Coconut Island Airport"
    },
    {
      "city": "Cocos Keeling Island",
      "country": "Cocos (Keeling) Islands",
      "IATA": "CCK",
      "icao_code": "YPCC",
      "airport_name": "Cocos (Keeling) Islands Airport"
    },
    {
      "city": "Codrington",
      "country": "Antigua and Barbuda",
      "IATA": "BBQ",
      "airport_name": "Barbuda Codrington Airport"
    },
    {
      "city": "Cody",
      "country": "United States",
      "IATA": "COD",
      "icao_code": "KCOD",
      "airport_name": "Yellowstone Regional Airport"
    },
    {
      "city": "Coen",
      "country": "Australia",
      "IATA": "CUQ",
      "icao_code": "YCOE",
      "airport_name": "Coen Airport"
    },
    {
      "city": "Coeur d'Alene",
      "country": "United States",
      "IATA": "COE",
      "icao_code": "KCOE",
      "airport_name": "Coeur d'Alene Airport"
    },
    {
      "city": "Coff's Harbour",
      "country": "Australia",
      "IATA": "CFS",
      "icao_code": "YSCH",
      "airport_name": "Coffs Harbour Airport"
    },
    {
      "city": "Coffeyville",
      "country": "United States",
      "IATA": "CFV",
      "icao_code": "KCFV",
      "airport_name": "Coffeyville Municipal Airport"
    },
    {
      "city": "Cognac",
      "country": "France",
      "IATA": "CNG",
      "icao_code": "LFBG",
      "airport_name": "Cognac – Châteaubernard Air Base"
    },
    {
      "city": "Coimbatore",
      "country": "India",
      "IATA": "CJB",
      "icao_code": "VOCB",
      "airport_name": "Coimbatore International Airport"
    },
    {
      "city": "Cold Bay",
      "country": "United States",
      "IATA": "PML",
      "icao_code": "PAAL",
      "airport_name": "Port Moller Airport "
    },
    {
      "city": "Cold Bay",
      "country": "United States",
      "IATA": "CDB",
      "icao_code": "PACD",
      "airport_name": "Cold Bay Airport"
    },
    {
      "city": "Cold Lake",
      "country": "Canada",
      "IATA": "YOD",
      "icao_code": "CEN5",
      "airport_name": "Canadian Forces Base Cold Lake"
    },
    {
      "city": "Colima",
      "country": "Mexico",
      "IATA": "CLQ",
      "icao_code": "MMIA",
      "airport_name": "Licenciado Miguel de la Madrid Airport"
    },
    {
      "city": "College Station",
      "country": "United States",
      "IATA": "CLL",
      "icao_code": "KCLL",
      "airport_name": "Easterwood Airport "
    },
    {
      "city": "Colmar",
      "country": "France",
      "IATA": "CMR",
      "icao_code": "LFGA",
      "airport_name": "Colmar - Houssen Airport"
    },
    {
      "city": "Cologne",
      "country": "Germany",
      "IATA": "CGN",
      "icao_code": "EDDK",
      "airport_name": "Cologne Bonn Airport "
    },
    {
      "city": "Colombo",
      "country": "Sri Lanka",
      "IATA": "RML",
      "icao_code": "VCCC",
      "airport_name": "Ratmalana Airport"
    },
    {
      "city": "Colombo",
      "country": "Sri Lanka",
      "IATA": "CMB",
      "icao_code": "VCBI",
      "airport_name": "Bandaranaike International Airport"
    },
    {
      "city": "Colombus",
      "country": "United States",
      "IATA": "CBM",
      "airport_name": "Columbus Air Force Base"
    },
    {
      "city": "Colonel Hill",
      "country": "Bahamas",
      "IATA": "CRI",
      "icao_code": "MYCI",
      "airport_name": "Colonel Hill Airport "
    },
    {
      "city": "Colonel Suarez",
      "country": "Argentina",
      "IATA": "CSZ",
      "airport_name": "Brigadier Hector Eduardo Ruiz Airport"
    },
    {
      "city": "Colonia",
      "country": "Uruguay",
      "IATA": "CYR",
      "airport_name": "Laguna de Los Patos International Airport"
    },
    {
      "city": "Colonsay",
      "country": "United Kingdom",
      "IATA": "CSA",
      "icao_code": "EGEY",
      "airport_name": "Colonsay Airport"
    },
    {
      "city": "Colorado Springs",
      "country": "United States",
      "IATA": "COS",
      "icao_code": "KCOS",
      "airport_name": "Colorado Springs Airport"
    },
    {
      "city": "Columbia",
      "country": "United States",
      "IATA": "CUB",
      "icao_code": "KCUB",
      "airport_name": "Jim Hamilton–L.B. Owens Airport"
    },
    {
      "city": "Columbia",
      "country": "United States",
      "IATA": "COU",
      "icao_code": "KCOU",
      "airport_name": "Columbia Regional Airport"
    },
    {
      "city": "Columbia",
      "country": "United States",
      "IATA": "CAE",
      "icao_code": "KCAE",
      "airport_name": "Columbia Metropolitan Airport"
    },
    {
      "city": "Columbus",
      "country": "United States",
      "IATA": "CLU",
      "icao_code": "KBAK",
      "airport_name": "Columbus Municipal Airport "
    },
    {
      "city": "Columbus",
      "country": "United States",
      "IATA": "TZR",
      "airport_name": "Bolton Field"
    },
    {
      "city": "Columbus",
      "country": "United States",
      "IATA": "OSU",
      "icao_code": "KOSU",
      "airport_name": "Ohio State University Airport"
    },
    {
      "city": "Columbus",
      "country": "United States",
      "IATA": "CSG",
      "icao_code": "KCSG",
      "airport_name": "Columbus Metropolitan Airport"
    },
    {
      "city": "Columbus",
      "country": "United States",
      "IATA": "CMH",
      "icao_code": "KCMH",
      "airport_name": " Port Columbus International Airport"
    },
    {
      "city": "Columbus",
      "country": "United States",
      "IATA": "LCK",
      "icao_code": "KLCK",
      "airport_name": "Rickenbacker International Airport"
    },
    {
      "city": "Columbus Mississippi",
      "country": "United States",
      "IATA": "GTR",
      "icao_code": "KGTR",
      "airport_name": "Golden Triangle Regional Airport"
    },
    {
      "city": "Colville Lake",
      "country": "Canada",
      "IATA": "YCK",
      "airport_name": "Colville Lake/Tommy Kochon Aerodrome"
    },
    {
      "city": "Colón",
      "country": "Panama",
      "IATA": "ONX",
      "airport_name": "Enrique Adolfo Jiménez Airport"
    },
    {
      "city": "Comayagua",
      "country": "Honduras",
      "IATA": "XPL",
      "airport_name": "Soto Cano Air Base"
    },
    {
      "city": "Comiso",
      "country": "Italy",
      "IATA": "CIY",
      "icao_code": "LICB",
      "airport_name": "Comiso Airport"
    },
    {
      "city": "Comodoro Rivadavia",
      "country": "Argentina",
      "IATA": "CRD",
      "icao_code": "SAVC",
      "airport_name": "General Enrique Mosconi International Airport"
    },
    {
      "city": "Comox",
      "country": "Canada",
      "IATA": "YQQ",
      "icao_code": "CYQQ",
      "airport_name": "Canadian Forces Base Comox"
    },
    {
      "city": "Conakry",
      "country": "Guinea",
      "IATA": "CKY",
      "icao_code": "GUCY",
      "airport_name": "Gbessia International  Airport"
    },
    {
      "city": "Conceicao Do Araguaia",
      "country": "Brazil",
      "IATA": "CDJ",
      "icao_code": "SBAA",
      "airport_name": "Conceição do Araguaia Airport"
    },
    {
      "city": "Concepcion",
      "country": "Chile",
      "IATA": "CCP",
      "icao_code": "SCIE",
      "airport_name": "Carriel Sur International Airport"
    },
    {
      "city": "Conception",
      "country": "Paraguay",
      "IATA": "CIO",
      "icao_code": "SGCO",
      "airport_name": "Teniente Coronel Carmelo Peralta Airport"
    },
    {
      "city": "Concord",
      "country": "United States",
      "IATA": "CCR",
      "icao_code": "KCCR",
      "airport_name": "Buchanan Field Airport "
    },
    {
      "city": "Concord",
      "country": "United States",
      "IATA": "USA",
      "icao_code": "KJQF",
      "airport_name": "Concord-Padgett Regional Airport"
    },
    {
      "city": "Concord NH",
      "country": "United States",
      "IATA": "CON",
      "airport_name": "Concord Municipal Airport"
    },
    {
      "city": "Concordia",
      "country": "Brazil",
      "IATA": "CCI",
      "icao_code": "SSCK",
      "airport_name": "Concórdia Airport"
    },
    {
      "city": "Concordia",
      "country": "Argentina",
      "IATA": "COC",
      "icao_code": "SAAC",
      "airport_name": "Concordia Airport "
    },
    {
      "city": "Condoto",
      "country": "Colombia",
      "IATA": "COG",
      "icao_code": "SKCD",
      "airport_name": "Mandinga Airport"
    },
    {
      "city": "Confresa",
      "country": "Brazil",
      "IATA": "CFO",
      "icao_code": "SJHG",
      "airport_name": "Confresa Airport"
    },
    {
      "city": "Coningsby",
      "country": "United Kingdom",
      "IATA": "QCY",
      "airport_name": "RAF Coningsby"
    },
    {
      "city": "Connaught",
      "country": "Ireland",
      "IATA": "NOC",
      "icao_code": "EIKN",
      "airport_name": "Ireland West Airport Knock"
    },
    {
      "city": "Conroe",
      "country": "United States",
      "IATA": "CXO",
      "icao_code": "KCXO",
      "airport_name": "Conroe-North Houston Regional Airport"
    },
    {
      "city": "Conson",
      "country": "Vietnam",
      "IATA": "VCS",
      "icao_code": "VVCS",
      "airport_name": "Con Dao Airport"
    },
    {
      "city": "Constanta",
      "country": "Romania",
      "IATA": "CND",
      "icao_code": "LRCK",
      "airport_name": "Mihail Kogălniceanu International Airport"
    },
    {
      "city": "Constantine",
      "country": "Algeria",
      "IATA": "CZL",
      "icao_code": "DABC",
      "airport_name": "Mohamed Boudiaf International Airport"
    },
    {
      "city": "Constanza",
      "country": "Dominican Republic",
      "IATA": "COZ",
      "airport_name": "Constanza Airport"
    },
    {
      "city": "Coober Pedy",
      "country": "Australia",
      "IATA": "CPD",
      "icao_code": "YCBP",
      "airport_name": "Coober Pedy Airport"
    },
    {
      "city": "Cooch-behar",
      "country": "India",
      "IATA": "COH",
      "icao_code": "VECO",
      "airport_name": "Cooch Behar Airport"
    },
    {
      "city": "Cooinda",
      "country": "Australia",
      "IATA": "CDA",
      "icao_code": "YCOO",
      "airport_name": "Cooinda Airport"
    },
    {
      "city": "Cooktown",
      "country": "Australia",
      "IATA": "CTN",
      "icao_code": "YCKN",
      "airport_name": "Cooktown Airport"
    },
    {
      "city": "Coolangatta",
      "country": "Australia",
      "IATA": "OOL",
      "icao_code": "YBCG",
      "airport_name": "Gold Coast Airport"
    },
    {
      "city": "Cooma",
      "country": "Australia",
      "IATA": "OOM",
      "icao_code": "YCOM",
      "airport_name": "Cooma Snowy Mountains Airport"
    },
    {
      "city": "Coonabarabran",
      "country": "Australia",
      "IATA": "COJ",
      "icao_code": "YCBB",
      "airport_name": "Coonabarabran Airport"
    },
    {
      "city": "Coonamble",
      "country": "Australia",
      "IATA": "CNB",
      "icao_code": "YCNM",
      "airport_name": "Coonamble Airport"
    },
    {
      "city": "Coondewanna",
      "country": "Australia",
      "IATA": "CJF",
      "icao_code": "YCWA",
      "airport_name": "Coondewanna Airport"
    },
    {
      "city": "Copenhagen",
      "country": "Denmark",
      "IATA": "RKE",
      "icao_code": "EKRK",
      "airport_name": "Copenhagen Airport, Roskilde"
    },
    {
      "city": "Copenhagen",
      "country": "Denmark",
      "IATA": "CPH",
      "icao_code": "EKCH",
      "airport_name": "Copenhagen Airport"
    },
    {
      "city": "Copiapo",
      "country": "Chile",
      "IATA": "CPO",
      "icao_code": "SCHA",
      "airport_name": "Chamonate Airport"
    },
    {
      "city": "Coppermine",
      "country": "Canada",
      "IATA": "YCO",
      "icao_code": "CYCO",
      "airport_name": "Kugluktuk Airport"
    },
    {
      "city": "Coral Harbour",
      "country": "Canada",
      "IATA": "YZS",
      "icao_code": "CYZS",
      "airport_name": "Coral Harbour Airport"
    },
    {
      "city": "Cordoba",
      "country": "Argentina",
      "IATA": "COR",
      "icao_code": "SACO",
      "airport_name": "Ingeniero Aeronáutico Ambrosio L.V. Taravella International Airport"
    },
    {
      "city": "Cordoba",
      "country": "Spain",
      "IATA": "ODB",
      "icao_code": "LEBA",
      "airport_name": "Córdoba Airport"
    },
    {
      "city": "Cordova",
      "country": "United States",
      "IATA": "CDV",
      "icao_code": "PACV",
      "airport_name": "Merle K. (Mudhole) Smith Airport"
    },
    {
      "city": "Cork",
      "country": "Ireland",
      "IATA": "ORK",
      "icao_code": "EICK",
      "airport_name": "Cork Airport"
    },
    {
      "city": "Corn Island",
      "country": "Nicaragua",
      "IATA": "RNI",
      "icao_code": "MNCI",
      "airport_name": "Corn Island International Airport"
    },
    {
      "city": "Cornwall",
      "country": "Canada",
      "IATA": "YCC",
      "icao_code": "CYCC",
      "airport_name": "Cornwall Regional Airport"
    },
    {
      "city": "Coro",
      "country": "Venezuela",
      "IATA": "CZE",
      "icao_code": "SVCR",
      "airport_name": "José Leonardo Chirinos Airport"
    },
    {
      "city": "Coronation",
      "country": "Canada",
      "IATA": "YCT",
      "icao_code": "CYCT",
      "airport_name": "Coronation Airport"
    },
    {
      "city": "Corozal",
      "country": "Colombia",
      "IATA": "CZU",
      "icao_code": "SKCZ",
      "airport_name": "Las Brujas Airport"
    },
    {
      "city": "Corpus Christi",
      "country": "United States",
      "IATA": "CRP",
      "icao_code": "KCRP",
      "airport_name": "Corpus Christi International Airport"
    },
    {
      "city": "Corrientes",
      "country": "Argentina",
      "IATA": "CNQ",
      "icao_code": "SARC",
      "airport_name": "Doctor Fernando Piragine Niveyro International Airport"
    },
    {
      "city": "Cortez",
      "country": "United States",
      "IATA": "CEZ",
      "icao_code": "KCEZ",
      "airport_name": "Cortez Municipal Airport"
    },
    {
      "city": "Corumba",
      "country": "Brazil",
      "IATA": "CMG",
      "icao_code": "SBCR",
      "airport_name": "Corumbá International Airport"
    },
    {
      "city": "Corvallis",
      "country": "United States",
      "IATA": "CVO",
      "icao_code": "KCVO",
      "airport_name": "Corvallis Municipal Airport"
    },
    {
      "city": "Corvo",
      "country": "Portugal",
      "IATA": "CVU",
      "icao_code": "LPCR",
      "airport_name": "Corvo Island Airport"
    },
    {
      "city": "Cotabato",
      "country": "Philippines",
      "IATA": "CBO",
      "icao_code": "RPWC",
      "airport_name": "Awang Airport"
    },
    {
      "city": "Coto 47",
      "country": "Costa Rica",
      "IATA": "OTR",
      "icao_code": "MRCC",
      "airport_name": "Coto 47 Airport"
    },
    {
      "city": "Cotonou",
      "country": "Benin",
      "IATA": "COO",
      "icao_code": "DBBB",
      "airport_name": "Cotonou International Airport"
    },
    {
      "city": "Cottbus",
      "country": "Germany",
      "IATA": "CBU",
      "airport_name": "Cottbus-Drewitz Airport"
    },
    {
      "city": "Cotulla",
      "country": "United States",
      "IATA": "COT",
      "icao_code": "KCOT",
      "airport_name": "Cotulla-La Salle County Airport"
    },
    {
      "city": "Council Bluffs",
      "country": "United States",
      "IATA": "CBF",
      "icao_code": "KCBF",
      "airport_name": "Council Bluffs Municipal Airport"
    },
    {
      "city": "Courcheval",
      "country": "France",
      "IATA": "CVF",
      "airport_name": "Courchevel Aerodrome"
    },
    {
      "city": "Coventry",
      "country": "United Kingdom",
      "IATA": "CVT",
      "icao_code": "EGBE",
      "airport_name": "Coventry Airport"
    },
    {
      "city": "Coveñas",
      "country": "Colombia",
      "IATA": "CVE",
      "airport_name": "Coveñas Airport"
    },
    {
      "city": "Cox's Bazar",
      "country": "Bangladesh",
      "IATA": "CXB",
      "icao_code": "VGCB",
      "airport_name": "Cox's Bazar Airport"
    },
    {
      "city": "Coyhaique",
      "country": "Chile",
      "IATA": "GXQ",
      "icao_code": "SCCY",
      "airport_name": "Teniente Vidal Airfield"
    },
    {
      "city": "Cozumel",
      "country": "Mexico",
      "IATA": "CZM",
      "icao_code": "MMCZ",
      "airport_name": "Cozumel International Airport"
    },
    {
      "city": "Craig Cove",
      "country": "Vanuatu",
      "IATA": "CCV",
      "icao_code": "NVSF",
      "airport_name": "Craig Cove Airport"
    },
    {
      "city": "Craiova",
      "country": "Romania",
      "IATA": "CRA",
      "icao_code": "LRCV",
      "airport_name": "Craiova International Airport"
    },
    {
      "city": "Cranbrook",
      "country": "Canada",
      "IATA": "YXC",
      "icao_code": "CYXC",
      "airport_name": "Cranbrook/Canadian Rockies International Airport"
    },
    {
      "city": "Creil",
      "country": "France",
      "IATA": "CSF",
      "airport_name": "Creil Airport"
    },
    {
      "city": "Crescent City",
      "country": "United States",
      "IATA": "CEC",
      "icao_code": "KCEC",
      "airport_name": "Del Norte County Regional Airport"
    },
    {
      "city": "Crestview",
      "country": "United States",
      "IATA": "EGI",
      "airport_name": "Duke Field "
    },
    {
      "city": "Crestview",
      "country": "United States",
      "IATA": "CEW",
      "icao_code": "KCEW",
      "airport_name": "Bob Sikes Airport"
    },
    {
      "city": "Criciuma",
      "country": "Brazil",
      "IATA": "CCM",
      "icao_code": "SBCM",
      "airport_name": "Diomício Freitas Airport"
    },
    {
      "city": "Croker Island",
      "country": "Australia",
      "IATA": "CKI",
      "icao_code": "YCKI",
      "airport_name": "Croker Island Airport"
    },
    {
      "city": "Cross City",
      "country": "United States",
      "IATA": "CTY",
      "icao_code": "KCTY",
      "airport_name": "Cross City Airport"
    },
    {
      "city": "Cross Lake",
      "country": "Canada",
      "IATA": "YCR",
      "icao_code": "CYCR",
      "airport_name": "Cross Lake (Charlie Sinclair Memorial) Airport"
    },
    {
      "city": "Crossville",
      "country": "United States",
      "IATA": "CSV",
      "icao_code": "KCSV",
      "airport_name": "Crossville Memorial/Whitson Field Airport"
    },
    {
      "city": "Crotone",
      "country": "Italy",
      "IATA": "CRV",
      "icao_code": "LIBC",
      "airport_name": "Crotone Airport"
    },
    {
      "city": "Cruzeiro do Sul",
      "country": "Brazil",
      "IATA": "CZS",
      "icao_code": "SBCZ",
      "airport_name": "Cruzeiro do Sul International Airport"
    },
    {
      "city": "Cuamba",
      "country": "Mozambique",
      "IATA": "FXO",
      "icao_code": "FQCB",
      "airport_name": "Cuamba Airport"
    },
    {
      "city": "Cucuta",
      "country": "Colombia",
      "IATA": "CUC",
      "icao_code": "SKCC",
      "airport_name": "Camilo Daza International Airport"
    },
    {
      "city": "Cuddapah",
      "country": "India",
      "IATA": "CDP",
      "icao_code": "VOCP",
      "airport_name": "Kadapa Airport"
    },
    {
      "city": "Cuenca",
      "country": "Ecuador",
      "IATA": "CUE",
      "icao_code": "SECU",
      "airport_name": "Mariscal Lamar International  Airport"
    },
    {
      "city": "Cuernavaca",
      "country": "Mexico",
      "IATA": "CVJ",
      "icao_code": "MMCB",
      "airport_name": "General Mariano Matamoros Airport"
    },
    {
      "city": "Cuiaba",
      "country": "Brazil",
      "IATA": "CGB",
      "icao_code": "SBCY",
      "airport_name": "Marechal Rondon International  Airport"
    },
    {
      "city": "Culebra Island",
      "country": "Puerto Rico",
      "IATA": "CPX",
      "icao_code": "TJCP",
      "airport_name": "Benjamín Rivera Noriega Airport"
    },
    {
      "city": "Culiacan",
      "country": "Mexico",
      "IATA": "CUL",
      "icao_code": "MMCL",
      "airport_name": "Federal of Bachigualato International Airport "
    },
    {
      "city": "Cumana",
      "country": "Venezuela",
      "IATA": "CUM",
      "icao_code": "SVCU",
      "airport_name": "Antonio José de Sucre Airport"
    },
    {
      "city": "Cumberland",
      "country": "United States",
      "IATA": "CBE",
      "icao_code": "KCBE",
      "airport_name": "Greater Cumberland Regional Airport "
    },
    {
      "city": "Cunagua",
      "country": "Cuba",
      "IATA": "CCC",
      "icao_code": "MUCC",
      "airport_name": "Jardines del Rey Airport"
    },
    {
      "city": "Cuneo",
      "country": "Italy",
      "IATA": "CUF",
      "icao_code": "LIMZ",
      "airport_name": "Cuneo International Airport"
    },
    {
      "city": "Cunnamulla",
      "country": "Australia",
      "IATA": "CMA",
      "icao_code": "YCMU",
      "airport_name": "Cunnamulla Airport"
    },
    {
      "city": "Curitiba",
      "country": "Brazil",
      "IATA": "CWB",
      "icao_code": "SBCT",
      "airport_name": "Afonso Pena International Airport"
    },
    {
      "city": "Curitiba",
      "country": "Brazil",
      "IATA": "BFH",
      "icao_code": "SBBI",
      "airport_name": "Bacacheri Airport"
    },
    {
      "city": "Curuzu Cuatia",
      "country": "Argentina",
      "IATA": "UZU",
      "icao_code": "SATU",
      "airport_name": "Curuzu Cuatia Airport"
    },
    {
      "city": "Cushing",
      "country": "United States",
      "IATA": "CUH",
      "icao_code": "KCUH",
      "airport_name": "Cushing Municipal Airport"
    },
    {
      "city": "Cutbank",
      "country": "United States",
      "IATA": "CTB",
      "icao_code": "KCTB",
      "airport_name": "Cut Bank Municipal Airport"
    },
    {
      "city": "Cutralco",
      "country": "Argentina",
      "IATA": "CUT",
      "icao_code": "SAZW",
      "airport_name": "Cutral-Co Airport"
    },
    {
      "city": "Cuyo",
      "country": "Philippines",
      "IATA": "CYU",
      "icao_code": "RPLO",
      "airport_name": "Cuyo Airport"
    },
    {
      "city": "Cuzco",
      "country": "Peru",
      "IATA": "CUZ",
      "icao_code": "SPZO",
      "airport_name": "Alejandro Velasco Astete International Airport"
    },
    {
      "city": "Cyclades Islands",
      "country": "Greece",
      "IATA": "JNX",
      "icao_code": "LGNX",
      "airport_name": "Naxos Island National Airport"
    },
    {
      "city": "DHARAVANDHOO",
      "country": "Maldives",
      "IATA": "DRV",
      "airport_name": "Dharavandhoo Airport"
    },
    {
      "city": "Dabaa City",
      "country": "Egypt",
      "IATA": "DBB",
      "icao_code": "HEAL",
      "airport_name": "El Alamein International Airport"
    },
    {
      "city": "Daet",
      "country": "Philippines",
      "IATA": "DTE",
      "icao_code": "RPUD",
      "airport_name": "Bagasbas Airport"
    },
    {
      "city": "Daggett",
      "country": "United States",
      "IATA": "DAG",
      "icao_code": "KDAG",
      "airport_name": "Barstow-Daggett Airport"
    },
    {
      "city": "Dakar",
      "country": "Senegal",
      "IATA": "DKR",
      "icao_code": "GOOY",
      "airport_name": "Léopold Sédar Senghor International Airport"
    },
    {
      "city": "Dakhla",
      "country": "Western Sahara",
      "IATA": "VIL",
      "icao_code": "GMMH",
      "airport_name": "Dakhla Airport"
    },
    {
      "city": "Dalaman",
      "country": "Turkey",
      "IATA": "DLM",
      "icao_code": "LTBS",
      "airport_name": "Dalaman Airport"
    },
    {
      "city": "Dalanzadgad",
      "country": "Mongolia",
      "IATA": "DLZ",
      "icao_code": "ZMDZ",
      "airport_name": "Dalanzadgad Airport"
    },
    {
      "city": "Dalat",
      "country": "Vietnam",
      "IATA": "DLI",
      "icao_code": "VVDL",
      "airport_name": "Lien Khuong Airport"
    },
    {
      "city": "Dalbandin",
      "country": "Pakistan",
      "IATA": "DBA",
      "icao_code": "OPDB",
      "airport_name": "Dalbandin Airport"
    },
    {
      "city": "Dalhart",
      "country": "United States",
      "IATA": "DHT",
      "icao_code": "KDHT",
      "airport_name": "Dalhart Municipal Airport"
    },
    {
      "city": "Dali",
      "country": "China",
      "IATA": "DLU",
      "icao_code": "ZPDL",
      "airport_name": "Dali Airport"
    },
    {
      "city": "Dalian",
      "country": "China",
      "IATA": "DLC",
      "icao_code": "ZYTL",
      "airport_name": "Dalian Zhoushuizi International Airport"
    },
    {
      "city": "Dallas, All Airports",
      "country": "United States",
      "IATA": "DALALL",
      "airport_name": "Dallas Area Airports"
    },
    {
      "city": "Dallas",
      "country": "United States",
      "IATA": "RBD",
      "icao_code": "KRBD",
      "airport_name": "Dallas Executive Airport"
    },
    {
      "city": "Dallas",
      "country": "United States",
      "IATA": "FWH",
      "airport_name": "Naval Air Station Joint Reserve Base Fort Worth"
    },
    {
      "city": "Dallas",
      "country": "United States",
      "IATA": "DAL",
      "icao_code": "KDAL",
      "airport_name": "Dallas Love Field"
    },
    {
      "city": "Dallas-Fort Worth",
      "country": "United States",
      "IATA": "DFW",
      "icao_code": "KDFW",
      "airport_name": "Dallas-Fort Worth International Airport"
    },
    {
      "city": "Daloa",
      "country": "Cote d'Ivoire",
      "IATA": "DJO",
      "icao_code": "DIDL",
      "airport_name": "Daloa Airport"
    },
    {
      "city": "Dalton",
      "country": "United States",
      "IATA": "DNN",
      "icao_code": "KDNN",
      "airport_name": "Dalton Municipal Airport"
    },
    {
      "city": "Daman",
      "country": "India",
      "IATA": "NMB",
      "icao_code": "VADN",
      "airport_name": "Daman Airport"
    },
    {
      "city": "Damascus",
      "country": "Syria",
      "IATA": "DAM",
      "icao_code": "OSDI",
      "airport_name": "Damascus International Airport"
    },
    {
      "city": "Damazin",
      "country": "Sudan",
      "IATA": "RSS",
      "airport_name": "Damazin Airport"
    },
    {
      "city": "Dammam",
      "country": "Saudi Arabia",
      "IATA": "DMM",
      "icao_code": "OEDF",
      "airport_name": "King Fahd International Airport"
    },
    {
      "city": "Danang",
      "country": "Vietnam",
      "IATA": "DAD",
      "icao_code": "VVDN",
      "airport_name": "Đà Nẵng International Airport"
    },
    {
      "city": "Danbury",
      "country": "United States",
      "IATA": "DXR",
      "icao_code": "KDXR",
      "airport_name": "Danbury Municipal Airport"
    },
    {
      "city": "Dandong",
      "country": "China",
      "IATA": "DDG",
      "icao_code": "ZYDD",
      "airport_name": "Dandong  Airport"
    },
    {
      "city": "Dang",
      "country": "Nepal",
      "IATA": "DNP",
      "icao_code": "VNDG",
      "airport_name": "Tribhuvannagar Airport "
    },
    {
      "city": "Danville",
      "country": "United States",
      "IATA": "DAN",
      "icao_code": "KDAN",
      "airport_name": "Danville Regional Airport"
    },
    {
      "city": "Danville",
      "country": "United States",
      "IATA": "DNV",
      "icao_code": "KDNV",
      "airport_name": "Vermilion Regional Airport"
    },
    {
      "city": "Daocheng",
      "country": "China",
      "IATA": "DCY",
      "airport_name": "Daocheng Yading Airport"
    },
    {
      "city": "Daqing",
      "country": "China",
      "IATA": "DQA",
      "icao_code": "ZYDQ",
      "airport_name": "Daqing Sartu Airport"
    },
    {
      "city": "Dar Es Salaam",
      "country": "Tanzania",
      "IATA": "DAR",
      "icao_code": "HTDA",
      "airport_name": "Julius Nyerere International Airport"
    },
    {
      "city": "Daran",
      "country": "Iran",
      "IATA": "IFH",
      "icao_code": "OIFE",
      "airport_name": "Hesa Airport"
    },
    {
      "city": "Darnley Island",
      "country": "Australia",
      "IATA": "NLF",
      "icao_code": "YDNI",
      "airport_name": "Darnley Island Airport"
    },
    {
      "city": "Daru",
      "country": "Papua New Guinea",
      "IATA": "DAU",
      "icao_code": "AYDU",
      "airport_name": "Daru Airport"
    },
    {
      "city": "Darwin",
      "country": "Australia",
      "IATA": "DRW",
      "icao_code": "YPDN",
      "airport_name": "Darwin International Airport"
    },
    {
      "city": "Dasht-e-naz",
      "country": "Iran",
      "IATA": "SRY",
      "icao_code": "OINS",
      "airport_name": "Sari Airport "
    },
    {
      "city": "Dasoguz",
      "country": "Turkmenistan",
      "IATA": "TAZ",
      "icao_code": "UTAT",
      "airport_name": "Taylorville Municipal Airport"
    },
    {
      "city": "Datadawai-Borneo Island",
      "country": "Indonesia",
      "IATA": "DTD",
      "icao_code": "WALJ",
      "airport_name": "Datadawai Airport"
    },
    {
      "city": "Datong",
      "country": "China",
      "IATA": "DAT",
      "icao_code": "ZBDT",
      "airport_name": "Datong Yungang Airport"
    },
    {
      "city": "Daugavpils",
      "country": "Latvia",
      "IATA": "DGP",
      "icao_code": "EVDA",
      "airport_name": "Daugavpils International Airport"
    },
    {
      "city": "Dauphin",
      "country": "Canada",
      "IATA": "YDN",
      "icao_code": "CYDN",
      "airport_name": "Lt. Col W.G. (Billy) Barker VC Airport"
    },
    {
      "city": "Davao",
      "country": "Philippines",
      "IATA": "DVO",
      "icao_code": "RPMD",
      "airport_name": "Francisco Bangoy International Airport"
    },
    {
      "city": "David",
      "country": "Panama",
      "IATA": "DAV",
      "icao_code": "MPDA",
      "airport_name": "Enrique Malek International Airport"
    },
    {
      "city": "Dawei",
      "country": "Burma",
      "IATA": "TVY",
      "icao_code": "VBTV",
      "airport_name": "Dawei Airport"
    },
    {
      "city": "Dawson",
      "country": "Canada",
      "IATA": "YDA",
      "icao_code": "CYDA",
      "airport_name": "Dawson City Airport"
    },
    {
      "city": "Dawson Creek",
      "country": "Canada",
      "IATA": "YDQ",
      "icao_code": "CYDQ",
      "airport_name": "Dawson Creek Airport"
    },
    {
      "city": "Dayong",
      "country": "China",
      "IATA": "DYG",
      "icao_code": "ZGDY",
      "airport_name": "Zhangjiajie Hehua Airport"
    },
    {
      "city": "Dayton",
      "country": "United States",
      "IATA": "MGY",
      "icao_code": "KMGY",
      "airport_name": "Dayton-Wright Brothers Airport"
    },
    {
      "city": "Dayton",
      "country": "United States",
      "IATA": "FFO",
      "airport_name": "Wright-Patterson Air Force Base"
    },
    {
      "city": "Dayton",
      "country": "United States",
      "IATA": "DAY",
      "icao_code": "KDAY",
      "airport_name": "James M. Cox Dayton International Airport"
    },
    {
      "city": "Daytona Beach",
      "country": "United States",
      "IATA": "DAB",
      "icao_code": "KDAB",
      "airport_name": "Daytona Beach International Airport"
    },
    {
      "city": "Dazhou",
      "country": "China",
      "IATA": "DAX",
      "icao_code": "ZUDX",
      "airport_name": "Dazhou Heshi Airport"
    },
    {
      "city": "De Kooy",
      "country": "Netherlands",
      "IATA": "DHR",
      "icao_code": "EHKD",
      "airport_name": "De Kooy Airfield"
    },
    {
      "city": "Dead Man's Cay",
      "country": "Bahamas",
      "IATA": "LGI",
      "icao_code": "MYLD",
      "airport_name": "Deadman's Cay Airport"
    },
    {
      "city": "Deadhorse",
      "country": "United States",
      "IATA": "SCC",
      "icao_code": "PASC",
      "airport_name": "Deadhorse Airport"
    },
    {
      "city": "Dease Lake",
      "country": "Canada",
      "IATA": "YDL",
      "icao_code": "CYDL",
      "airport_name": "Dease Lake Airport"
    },
    {
      "city": "Deauville",
      "country": "France",
      "IATA": "DOL",
      "icao_code": "LFRG",
      "airport_name": "Deauville – Normandie Airport"
    },
    {
      "city": "Debre Marqos",
      "country": "Ethiopia",
      "IATA": "DBM",
      "icao_code": "HADM",
      "airport_name": "Debre Marqos Airport"
    },
    {
      "city": "Debre Tabor",
      "country": "Ethiopia",
      "IATA": "DBT",
      "icao_code": "HADT",
      "airport_name": "Debre Tabor Airport"
    },
    {
      "city": "Debre Zeyit",
      "country": "Ethiopia",
      "IATA": "QHR",
      "airport_name": "Harar Meda Airport"
    },
    {
      "city": "Debrecen",
      "country": "Hungary",
      "IATA": "DEB",
      "icao_code": "LHDC",
      "airport_name": "Debrecen International Airport"
    },
    {
      "city": "Decatur",
      "country": "United States",
      "IATA": "DCU",
      "icao_code": "KDCU",
      "airport_name": "Pryor Field Regional Airport"
    },
    {
      "city": "Decatur",
      "country": "United States",
      "IATA": "DEC",
      "icao_code": "KDEC",
      "airport_name": "Decatur Airport"
    },
    {
      "city": "Decimomannu",
      "country": "Italy",
      "IATA": "DCI",
      "airport_name": "Decimomannu Air Base"
    },
    {
      "city": "Deer Lake",
      "country": "Canada",
      "IATA": "YVZ",
      "icao_code": "CYVZ",
      "airport_name": "Deer Lake Airport"
    },
    {
      "city": "Deer Lake",
      "country": "Canada",
      "IATA": "YDF",
      "icao_code": "CYDF",
      "airport_name": "Deer Lake Airport"
    },
    {
      "city": "Deering",
      "country": "United States",
      "IATA": "DRG",
      "icao_code": "PADE",
      "airport_name": "Deering Airport "
    },
    {
      "city": "Dehra Dun",
      "country": "India",
      "IATA": "DED",
      "icao_code": "VIDN",
      "airport_name": "Jolly Grant Airport"
    },
    {
      "city": "Deire Zor",
      "country": "Syria",
      "IATA": "DEZ",
      "icao_code": "OSDZ",
      "airport_name": "Deir ez-Zor Airport "
    },
    {
      "city": "Dekai",
      "country": "Indonesia",
      "IATA": "DEX",
      "icao_code": "WAVD",
      "airport_name": "Nop Goliat Dekai Airport"
    },
    {
      "city": "Del Bajio",
      "country": "Mexico",
      "IATA": "BJX",
      "icao_code": "MMLO",
      "airport_name": "Guanajuato International Airport"
    },
    {
      "city": "Del Rio",
      "country": "United States",
      "IATA": "DRT",
      "icao_code": "KDRT",
      "airport_name": "Del Rio International Airport"
    },
    {
      "city": "Del Rio",
      "country": "United States",
      "IATA": "DLF",
      "airport_name": "Laughlin Air Force Base"
    },
    {
      "city": "Delhi",
      "country": "India",
      "IATA": "DEL",
      "icao_code": "VIDP",
      "airport_name": "Indira Gandhi International Airport"
    },
    {
      "city": "Deline",
      "country": "Canada",
      "IATA": "YWJ",
      "icao_code": "CYWJ",
      "airport_name": "Deline Airport"
    },
    {
      "city": "Delta",
      "country": "United States",
      "IATA": "DTA",
      "icao_code": "KDTA",
      "airport_name": "Delta Municipal Airport"
    },
    {
      "city": "Delta Junction",
      "country": "United States",
      "IATA": "BIG",
      "icao_code": "PABI",
      "airport_name": "Allen Army Airfield "
    },
    {
      "city": "Dembidollo",
      "country": "Ethiopia",
      "IATA": "DEM",
      "icao_code": "HADD",
      "airport_name": "Dembidolo Airport"
    },
    {
      "city": "Deming",
      "country": "United States",
      "IATA": "DMN",
      "icao_code": "KDMN",
      "airport_name": "Deming Municipal Airport"
    },
    {
      "city": "Deniliquin",
      "country": "Australia",
      "IATA": "DNQ",
      "icao_code": "YDLQ",
      "airport_name": "Deniliquin Airport"
    },
    {
      "city": "Denizli",
      "country": "Turkey",
      "IATA": "DNZ",
      "icao_code": "LTAY",
      "airport_name": "Denizli Çardak Airport"
    },
    {
      "city": "Denpasar",
      "country": "Indonesia",
      "IATA": "DPS",
      "icao_code": "WADD",
      "airport_name": "Ngurah Rai International Airport"
    },
    {
      "city": "Denver",
      "country": "United States",
      "IATA": "APA",
      "icao_code": "KAPA",
      "airport_name": "Centennial Airport"
    },
    {
      "city": "Denver",
      "country": "United States",
      "IATA": "DEN",
      "icao_code": "KDEN",
      "airport_name": "Denver International Airport "
    },
    {
      "city": "Dera Ghazi Khan",
      "country": "Pakistan",
      "IATA": "DEA",
      "icao_code": "OPDG",
      "airport_name": "Dera Ghazi Khan International Airport"
    },
    {
      "city": "Dera Ismael Khan",
      "country": "Pakistan",
      "IATA": "DSK",
      "icao_code": "OPDI",
      "airport_name": "Dera Ismail Khan Airport"
    },
    {
      "city": "Derby",
      "country": "Australia",
      "IATA": "DCN",
      "icao_code": "YCIN",
      "airport_name": "RAAF Base Curtin"
    },
    {
      "city": "Derby",
      "country": "Australia",
      "IATA": "DRB",
      "icao_code": "YDBY",
      "airport_name": "Derby Airport"
    },
    {
      "city": "Deridder",
      "country": "United States",
      "IATA": "DRI",
      "icao_code": "KDRI",
      "airport_name": "Beauregard Regional Airport"
    },
    {
      "city": "Des Moines",
      "country": "United States",
      "IATA": "DSM",
      "icao_code": "KDSM",
      "airport_name": "Des Moines International Airport"
    },
    {
      "city": "Desroches",
      "country": "Seychelles",
      "IATA": "DES",
      "icao_code": "FSDR",
      "airport_name": "Desroches Airport"
    },
    {
      "city": "Dessie",
      "country": "Ethiopia",
      "IATA": "DSE",
      "icao_code": "HADC",
      "airport_name": "Combolcha Airport"
    },
    {
      "city": "Destin",
      "country": "United States",
      "IATA": "DSI",
      "airport_name": "Destin-Fort Walton Beach Airport "
    },
    {
      "city": "Detroit",
      "country": "United States",
      "IATA": "YIP",
      "icao_code": "KYIP",
      "airport_name": "Willow Run Airport"
    },
    {
      "city": "Detroit",
      "country": "United States",
      "IATA": "DET",
      "icao_code": "KDET",
      "airport_name": "Coleman A. Young International Airport"
    },
    {
      "city": "Detroit",
      "country": "United States",
      "IATA": "DTW",
      "icao_code": "KDTW",
      "airport_name": "Detroit Metropolitan Wayne County Airport"
    },
    {
      "city": "Devils Lake",
      "country": "United States",
      "IATA": "DVL",
      "icao_code": "KDVL",
      "airport_name": "Devils Lake Regional Airport"
    },
    {
      "city": "Devonport",
      "country": "Australia",
      "IATA": "DPO",
      "icao_code": "YDPO",
      "airport_name": "Devonport Airport"
    },
    {
      "city": "Dezful",
      "country": "Iran",
      "IATA": "DEF",
      "icao_code": "OIAD",
      "airport_name": "Dezful Airport"
    },
    {
      "city": "Dhahran",
      "country": "Saudi Arabia",
      "IATA": "DHA",
      "icao_code": "OEDR",
      "airport_name": "King Abdulaziz Air Base"
    },
    {
      "city": "Dhaka",
      "country": "Bangladesh",
      "IATA": "DAC",
      "icao_code": "VGZR",
      "airport_name": "Hazrat Shahjalal International Airport"
    },
    {
      "city": "Dhanbad",
      "country": "India",
      "IATA": "DBD",
      "icao_code": "VEDB",
      "airport_name": "Dhanbad Airport"
    },
    {
      "city": "Dhangarhi",
      "country": "Nepal",
      "IATA": "DHI",
      "icao_code": "VNDH",
      "airport_name": "Dhangarhi Airport"
    },
    {
      "city": "Diamantina",
      "country": "Brazil",
      "IATA": "DTI",
      "icao_code": "SNDT",
      "airport_name": "Diamantina Airport"
    },
    {
      "city": "Diamantino",
      "country": "Brazil",
      "IATA": "DMT",
      "icao_code": "SWDM",
      "airport_name": "Diamantino Airport"
    },
    {
      "city": "Diass",
      "country": "Senegal",
      "IATA": "DSS",
      "icao_code": "GOBD",
      "airport_name": "Blaise Diagne International Airport"
    },
    {
      "city": "Dickinson",
      "country": "United States",
      "IATA": "DIK",
      "icao_code": "KDIK",
      "airport_name": "Dickinson Municipal Airport"
    },
    {
      "city": "Diego Garcia Island",
      "country": "British Indian Ocean Territory",
      "IATA": "NKW",
      "airport_name": "Diego Garcia Naval Support Facility"
    },
    {
      "city": "Dienbienphu",
      "country": "Vietnam",
      "IATA": "DIN",
      "icao_code": "VVDB",
      "airport_name": "Dien Bien Phu Airport"
    },
    {
      "city": "Digby",
      "country": "Canada",
      "IATA": "YDG",
      "icao_code": "CYID",
      "airport_name": "Digby / Annapolis Regional Airport"
    },
    {
      "city": "Dijon",
      "country": "France",
      "IATA": "DIJ",
      "icao_code": "LFSD",
      "airport_name": "Dijon-Longvic Air Base"
    },
    {
      "city": "Dikson",
      "country": "Russia",
      "IATA": "DKS",
      "icao_code": "UODD",
      "airport_name": "Dikson Airport"
    },
    {
      "city": "Dili",
      "country": "East Timor",
      "IATA": "DIL",
      "icao_code": "WPDL",
      "airport_name": "Presidente Nicolau Lobato International Airport"
    },
    {
      "city": "Dillingham",
      "country": "United States",
      "IATA": "HDH",
      "airport_name": "Dillingham Airfield"
    },
    {
      "city": "Dillingham",
      "country": "United States",
      "IATA": "DLG",
      "icao_code": "PADL",
      "airport_name": "Dillingham Airport"
    },
    {
      "city": "Dillon's Bay",
      "country": "Vanuatu",
      "IATA": "DLY",
      "icao_code": "NVVD",
      "airport_name": "Dillon's Bay Airport"
    },
    {
      "city": "Dimapur",
      "country": "India",
      "IATA": "DMU",
      "icao_code": "VEMR",
      "airport_name": "Dimapur Airport"
    },
    {
      "city": "Dinard",
      "country": "France",
      "IATA": "DNR",
      "icao_code": "LFRD",
      "airport_name": "Dinard - Pleurtuit - Saint-Malo Airport"
    },
    {
      "city": "Dipolog",
      "country": "Philippines",
      "IATA": "DPL",
      "icao_code": "RPMG",
      "airport_name": "Dipolog Airport"
    },
    {
      "city": "Dire Dawa",
      "country": "Ethiopia",
      "IATA": "DIR",
      "icao_code": "HADR",
      "airport_name": "Aba Tenna Dejazmach Yilma International Airport"
    },
    {
      "city": "Diu",
      "country": "India",
      "IATA": "DIU",
      "icao_code": "VA1P",
      "airport_name": "Diu Airport"
    },
    {
      "city": "Divinopolis",
      "country": "Brazil",
      "IATA": "DIQ",
      "icao_code": "SNDV",
      "airport_name": "Divinópolis Airport"
    },
    {
      "city": "Diyabakir",
      "country": "Turkey",
      "IATA": "DIY",
      "icao_code": "LTCC",
      "airport_name": "Diyarbakır Airport"
    },
    {
      "city": "Djanet",
      "country": "Algeria",
      "IATA": "DJG",
      "icao_code": "DAAJ",
      "airport_name": "Tiska Djanet Airport"
    },
    {
      "city": "Djelfa",
      "country": "Algeria",
      "IATA": "QDJ",
      "airport_name": "Tsletsi Airport"
    },
    {
      "city": "Djerba",
      "country": "Tunisia",
      "IATA": "DJE",
      "icao_code": "DTTJ",
      "airport_name": "Djerba–Zarzis International Airport"
    },
    {
      "city": "Djibouti",
      "country": "Djibouti",
      "IATA": "JIB",
      "icao_code": "HDAM",
      "airport_name": "Djibouti–Ambouli International Airport"
    },
    {
      "city": "Dnepropetrovsk",
      "country": "Ukraine",
      "IATA": "DNK",
      "icao_code": "UKDD",
      "airport_name": "Dnipropetrovsk International Airport"
    },
    {
      "city": "Dobo",
      "country": "Indonesia",
      "IATA": "DOB",
      "icao_code": "WAPD",
      "airport_name": "Dobo Airport"
    },
    {
      "city": "Docker River",
      "country": "Australia",
      "IATA": "DKV",
      "icao_code": "YDVR",
      "airport_name": "Docker River Airport"
    },
    {
      "city": "Dodge City",
      "country": "United States",
      "IATA": "DDC",
      "icao_code": "KDDC",
      "airport_name": "Dodge City Regional Airport"
    },
    {
      "city": "Dodoma",
      "country": "Tanzania",
      "IATA": "DOD",
      "icao_code": "HTDO",
      "airport_name": "Dodoma Airport"
    },
    {
      "city": "Doha",
      "country": "Qatar",
      "IATA": "DOH",
      "icao_code": "OTBD",
      "airport_name": "Hamad International Airport"
    },
    {
      "city": "Doha",
      "country": "Qatar",
      "IATA": "XJD",
      "icao_code": "OTBH",
      "airport_name": "Al Udeid Air Base"
    },
    {
      "city": "Doha",
      "country": "Qatar",
      "IATA": "DIA",
      "airport_name": "Doha International Airport"
    },
    {
      "city": "Dolbeau-St-Félicien",
      "country": "Canada",
      "IATA": "YDO",
      "icao_code": "CYDO",
      "airport_name": "Dolbeau-Saint-Félicien Airport"
    },
    {
      "city": "Dole",
      "country": "France",
      "IATA": "DLE",
      "icao_code": "LFGJ",
      "airport_name": "Dole–Jura Airport"
    },
    {
      "city": "Dolpa",
      "country": "Nepal",
      "IATA": "DOP",
      "icao_code": "VNDP",
      "airport_name": "Dolpa Airport"
    },
    {
      "city": "Dominica",
      "country": "Dominica",
      "IATA": "DOM",
      "icao_code": "TDPD",
      "airport_name": "Douglas–Charles Airport"
    },
    {
      "city": "Donaueschingen",
      "country": "Germany",
      "IATA": "ZQL",
      "airport_name": "Donaueschingen-Villingen Airport"
    },
    {
      "city": "Donetsk",
      "country": "Ukraine",
      "IATA": "DOK",
      "icao_code": "UKCC",
      "airport_name": "Donetsk Sergey Prokofiev International Airport"
    },
    {
      "city": "Dong Hoi",
      "country": "Vietnam",
      "IATA": "VDH",
      "airport_name": "Dong Hoi Airport"
    },
    {
      "city": "Dongloe",
      "country": "Ireland",
      "IATA": "CFN",
      "icao_code": "EIDL",
      "airport_name": "Donegal Airport"
    },
    {
      "city": "Dongola",
      "country": "Sudan",
      "IATA": "DOG",
      "icao_code": "HSDN",
      "airport_name": "Dongola Airport"
    },
    {
      "city": "Dongsheng",
      "country": "China",
      "IATA": "DSN",
      "icao_code": "ZBDS",
      "airport_name": "Ordos Ejin Horo Airport"
    },
    {
      "city": "Dongying",
      "country": "China",
      "IATA": "DOY",
      "icao_code": "ZSDY",
      "airport_name": "Dongying Shengli Airport"
    },
    {
      "city": "Doomadgee",
      "country": "Australia",
      "IATA": "DMD",
      "icao_code": "YDMG",
      "airport_name": "Doomadgee Airport"
    },
    {
      "city": "Doris Lake",
      "country": "Canada",
      "IATA": "JOJ",
      "airport_name": "Doris Lake Aerodrome"
    },
    {
      "city": "Dortmund",
      "country": "Germany",
      "IATA": "DTM",
      "icao_code": "EDLW",
      "airport_name": "Dortmund Airport"
    },
    {
      "city": "Dothan",
      "country": "United States",
      "IATA": "DHN",
      "icao_code": "KDHN",
      "airport_name": "Dothan Regional Airport"
    },
    {
      "city": "Douala",
      "country": "Cameroon",
      "IATA": "DLA",
      "icao_code": "FKKD",
      "airport_name": "Douala International Airport"
    },
    {
      "city": "Douglas",
      "country": "United States",
      "IATA": "DGL",
      "icao_code": "KDGL",
      "airport_name": "Douglas Municipal Airport"
    },
    {
      "city": "Douglas",
      "country": "United States",
      "IATA": "DUG",
      "icao_code": "KDUG",
      "airport_name": "Bisbee-Douglas International Airport"
    },
    {
      "city": "Dourados",
      "country": "Brazil",
      "IATA": "DOU",
      "icao_code": "SSDO",
      "airport_name": "Dourados Airport"
    },
    {
      "city": "Dover",
      "country": "United States",
      "IATA": "DOV",
      "icao_code": "KDOV",
      "airport_name": "Dover Air Force Base"
    },
    {
      "city": "Doylestown",
      "country": "United States",
      "IATA": "DYL",
      "icao_code": "KDYL",
      "airport_name": "Doylestown Airport"
    },
    {
      "city": "Dresden",
      "country": "Germany",
      "IATA": "DRS",
      "icao_code": "EDDC",
      "airport_name": "Dresden Airport"
    },
    {
      "city": "Drietabbetje",
      "country": "Suriname",
      "IATA": "DRJ",
      "airport_name": "Drietabbetje Airport"
    },
    {
      "city": "Drummond Island",
      "country": "United States",
      "IATA": "DRE",
      "icao_code": "KDRM",
      "airport_name": "Drummond Island Airport "
    },
    {
      "city": "Dryden",
      "country": "Canada",
      "IATA": "YHD",
      "icao_code": "CYHD",
      "airport_name": "Dryden Regional Airport"
    },
    {
      "city": "Du Bois",
      "country": "United States",
      "IATA": "DUJ",
      "icao_code": "KDUJ",
      "airport_name": "DuBois Regional Airport "
    },
    {
      "city": "Dubai",
      "country": "United Arab Emirates",
      "IATA": "DWC",
      "airport_name": "Al Maktoum International Airport"
    },
    {
      "city": "Dubai",
      "country": "United Arab Emirates",
      "IATA": "DXB",
      "icao_code": "OMDB",
      "airport_name": "Dubai International Airport"
    },
    {
      "city": "Dubbo",
      "country": "Australia",
      "IATA": "DBO",
      "icao_code": "YSDU",
      "airport_name": "Dubbo City Regional Airport"
    },
    {
      "city": "Dublin",
      "country": "United States",
      "IATA": "DBN",
      "icao_code": "KDBN",
      "airport_name": "W. H. 'Bud' Barron Airport"
    },
    {
      "city": "Dublin",
      "country": "Ireland",
      "IATA": "DUB",
      "icao_code": "EIDW",
      "airport_name": "Dublin Airport"
    },
    {
      "city": "Dubrovnik",
      "country": "Croatia",
      "IATA": "DBV",
      "icao_code": "LDDU",
      "airport_name": "Dubrovnik Airport"
    },
    {
      "city": "Dubuque IA",
      "country": "United States",
      "IATA": "DBQ",
      "icao_code": "KDBQ",
      "airport_name": "Dubuque Regional Airport"
    },
    {
      "city": "Duesseldorf",
      "country": "Germany",
      "IATA": "DUS",
      "icao_code": "EDDL",
      "airport_name": "Dusseldorf International Airport"
    },
    {
      "city": "Dulkaninna",
      "country": "Australia",
      "IATA": "DLK",
      "icao_code": "YDLK",
      "airport_name": "Dulkaninna Airport"
    },
    {
      "city": "Duluth",
      "country": "United States",
      "IATA": "DLH",
      "icao_code": "KDLH",
      "airport_name": "Duluth International Airport"
    },
    {
      "city": "Dumaguete",
      "country": "Philippines",
      "IATA": "DGT",
      "icao_code": "RPVD",
      "airport_name": "Sibulan  Airport"
    },
    {
      "city": "Dumai",
      "country": "Indonesia",
      "IATA": "DUM",
      "icao_code": "WIBD",
      "airport_name": "Pinang Kampai Airport"
    },
    {
      "city": "Duncan",
      "country": "United States",
      "IATA": "DUC",
      "icao_code": "KDUC",
      "airport_name": "Halliburton Field"
    },
    {
      "city": "Duncan Town",
      "country": "Bahamas",
      "IATA": "DCT",
      "icao_code": "MYRD",
      "airport_name": "Duncan Town Airport"
    },
    {
      "city": "Dundee",
      "country": "United Kingdom",
      "IATA": "DND",
      "icao_code": "EGPN",
      "airport_name": "Dundee Airport"
    },
    {
      "city": "Dundo",
      "country": "Angola",
      "IATA": "DUE",
      "icao_code": "FNDU",
      "airport_name": "Dundo Airport"
    },
    {
      "city": "Dunedin",
      "country": "New Zealand",
      "IATA": "DUD",
      "icao_code": "NZDN",
      "airport_name": "Dunedin International Airport"
    },
    {
      "city": "Dunhuang",
      "country": "China",
      "IATA": "DNH",
      "icao_code": "ZLDH",
      "airport_name": "Dunhuang Airport"
    },
    {
      "city": "Dunk Island",
      "country": "Australia",
      "IATA": "DKI",
      "icao_code": "YDKI",
      "airport_name": "Dunk Island Airport"
    },
    {
      "city": "Dunkirk",
      "country": "United States",
      "IATA": "DKK",
      "icao_code": "KDKK",
      "airport_name": "Chautauqua County/Dunkirk Airport"
    },
    {
      "city": "Dunnville",
      "country": "Canada",
      "IATA": "DU9",
      "airport_name": "Dunnville Airport"
    },
    {
      "city": "Duqm",
      "country": "Oman",
      "IATA": "DQM",
      "icao_code": "OODQ",
      "airport_name": "Duqm Jaaluni Airport"
    },
    {
      "city": "Durango",
      "country": "United States",
      "IATA": "DRO",
      "icao_code": "KDRO",
      "airport_name": "Durango-La Plata County Airport"
    },
    {
      "city": "Durango",
      "country": "Mexico",
      "IATA": "DGO",
      "icao_code": "MMDO",
      "airport_name": "Guadalupe Victoria Durango International Airport"
    },
    {
      "city": "Durazno",
      "country": "Uruguay",
      "IATA": "DZO",
      "icao_code": "SUDU",
      "airport_name": "Santa Bernardina International Airport"
    },
    {
      "city": "Durban",
      "country": "South Africa",
      "IATA": "VIR",
      "icao_code": "FAVG",
      "airport_name": "Virginia Airport"
    },
    {
      "city": "Durban",
      "country": "South Africa",
      "IATA": "DUR",
      "icao_code": "FALE",
      "airport_name": "King Shaka International Airport"
    },
    {
      "city": "Durgapur",
      "country": "India",
      "IATA": "RDP",
      "icao_code": "VEDG",
      "airport_name": "Kazi Nazrul Islam Airport"
    },
    {
      "city": "Dushanbe",
      "country": "Tajikistan",
      "IATA": "DYU",
      "icao_code": "UTDD",
      "airport_name": "Dushanbe International Airport"
    },
    {
      "city": "Duxford",
      "country": "United Kingdom",
      "IATA": "QFO",
      "airport_name": "Duxford Airport"
    },
    {
      "city": "Dwangwa",
      "country": "Malawi",
      "IATA": "DWA",
      "icao_code": "KDWA",
      "airport_name": "Dwangwa Airport"
    },
    {
      "city": "Dzaoudzi",
      "country": "Mayotte",
      "IATA": "DZA",
      "icao_code": "FMCZ",
      "airport_name": "Dzaoudzi Pamandzi International Airport"
    },
    {
      "city": "Dzhambul",
      "country": "Kazakhstan",
      "IATA": "DMB",
      "icao_code": "UADD",
      "airport_name": "Taraz Airport "
    },
    {
      "city": "ELLIOT LAKE",
      "country": "Canada",
      "IATA": "YEL",
      "icao_code": "CYEL",
      "airport_name": "Elliot Lake Municipal Airport"
    },
    {
      "city": "Eagle",
      "country": "United States",
      "IATA": "EAA",
      "icao_code": "PAEA",
      "airport_name": "Eagle Airport"
    },
    {
      "city": "Eagle River",
      "country": "United States",
      "IATA": "EGV",
      "icao_code": "KEGV",
      "airport_name": "Eagle River Union Airport"
    },
    {
      "city": "Earlton",
      "country": "Canada",
      "IATA": "YXR",
      "icao_code": "CYXR",
      "airport_name": "Earlton (Timiskaming Regional) Airport"
    },
    {
      "city": "East London",
      "country": "South Africa",
      "IATA": "ELS",
      "icao_code": "FAEL",
      "airport_name": "East London Airport"
    },
    {
      "city": "East Midlands",
      "country": "United Kingdom",
      "IATA": "EMA",
      "icao_code": "EGNX",
      "airport_name": "East Midlands Airport"
    },
    {
      "city": "East Tawas",
      "country": "United States",
      "IATA": "ECA",
      "airport_name": "Iosco County Airport-6d9"
    },
    {
      "city": "Easter Island",
      "country": "Chile",
      "IATA": "IPC",
      "icao_code": "SCIP",
      "airport_name": "Mataveri International Airport"
    },
    {
      "city": "Eastmain River",
      "country": "Canada",
      "IATA": "ZEM",
      "icao_code": "CZEM",
      "airport_name": "Eastmain River Airport"
    },
    {
      "city": "Easton",
      "country": "United States",
      "IATA": "ESN",
      "icao_code": "KESN",
      "airport_name": "Easton Airport"
    },
    {
      "city": "Eastover",
      "country": "United States",
      "IATA": "MMT",
      "airport_name": "McEntire Joint National Guard Base"
    },
    {
      "city": "Eastsound",
      "country": "United States",
      "IATA": "ESD",
      "icao_code": "KORS",
      "airport_name": "Orcas Island Airport "
    },
    {
      "city": "Eau Claire",
      "country": "United States",
      "IATA": "EAU",
      "icao_code": "KEAU",
      "airport_name": "Chippewa Valley Regional Airport"
    },
    {
      "city": "Ech-cheliff",
      "country": "Algeria",
      "IATA": "CFK",
      "icao_code": "DAOI",
      "airport_name": "Chlef International Airport"
    },
    {
      "city": "Eday",
      "country": "United Kingdom",
      "IATA": "EOI",
      "icao_code": "EGED",
      "airport_name": "Eday Airport"
    },
    {
      "city": "Eden Prairie",
      "country": "United States",
      "IATA": "FCM",
      "icao_code": "KFCM",
      "airport_name": "Flying Cloud Airport"
    },
    {
      "city": "Edinburgh",
      "country": "United Kingdom",
      "IATA": "EDI",
      "icao_code": "EGPH",
      "airport_name": "Edinburgh Airport"
    },
    {
      "city": "Edmonton",
      "country": "Canada",
      "IATA": "YXD",
      "airport_name": "Edmonton City Centre  Airport"
    },
    {
      "city": "Edmonton",
      "country": "Canada",
      "IATA": "YEG",
      "icao_code": "CYEG",
      "airport_name": "Edmonton International Airport"
    },
    {
      "city": "Edson",
      "country": "Canada",
      "IATA": "YET",
      "icao_code": "CYET",
      "airport_name": "Edson Airport"
    },
    {
      "city": "Edwards Afb",
      "country": "United States",
      "IATA": "EDW",
      "airport_name": "Edwards Air Force Base"
    },
    {
      "city": "Eek",
      "country": "United States",
      "IATA": "EEK",
      "icao_code": "PAEE",
      "airport_name": "Eek Airport"
    },
    {
      "city": "Efogi",
      "country": "Papua New Guinea",
      "IATA": "EFG",
      "airport_name": "Efogi Airport"
    },
    {
      "city": "Egegik",
      "country": "United States",
      "IATA": "EGX",
      "icao_code": "PAII",
      "airport_name": "Egegik Airport "
    },
    {
      "city": "Egelsbach",
      "country": "Germany",
      "IATA": "QEF",
      "icao_code": "EDFE",
      "airport_name": "Frankfurt Egelsbach Airport"
    },
    {
      "city": "Egilsstadir",
      "country": "Iceland",
      "IATA": "EGS",
      "icao_code": "BIEG",
      "airport_name": "Egilsstaðir Airport"
    },
    {
      "city": "Eilat",
      "country": "Israel",
      "IATA": "ETM",
      "icao_code": "LLER",
      "airport_name": "Ramon Airport"
    },
    {
      "city": "Eindhoven",
      "country": "Netherlands",
      "IATA": "EIN",
      "icao_code": "EHEH",
      "airport_name": "Eindhoven Airport"
    },
    {
      "city": "Eirunepe",
      "country": "Brazil",
      "IATA": "ERN",
      "icao_code": "SWEI",
      "airport_name": "Eirunepé Airport"
    },
    {
      "city": "Eisenach",
      "country": "Germany",
      "IATA": "EIB",
      "airport_name": "Eisenach-Kindel Airport"
    },
    {
      "city": "Ejin Banner",
      "country": "China",
      "IATA": "EJN",
      "airport_name": "Ejin Banner Taolai Airport"
    },
    {
      "city": "Ekati",
      "country": "Canada",
      "IATA": "YOA",
      "airport_name": "Ekati Airport"
    },
    {
      "city": "Ekibastuz",
      "country": "Kazakhstan",
      "IATA": "EKB",
      "icao_code": "UASB",
      "airport_name": "Ekibastuz Airport"
    },
    {
      "city": "El Aaiún",
      "country": "Western Sahara",
      "IATA": "EUN",
      "icao_code": "GMML",
      "airport_name": "Hassan I Airport"
    },
    {
      "city": "El Arish",
      "country": "Egypt",
      "IATA": "AAC",
      "icao_code": "HEAR",
      "airport_name": "El Arish International Airport"
    },
    {
      "city": "El Aroui",
      "country": "Morocco",
      "IATA": "NDR",
      "icao_code": "GMFN",
      "airport_name": "Nador International Airport"
    },
    {
      "city": "El Bagre",
      "country": "Colombia",
      "IATA": "EBG",
      "airport_name": "El Bagre Airport"
    },
    {
      "city": "El Banco",
      "country": "Colombia",
      "IATA": "ELB",
      "icao_code": "SKBC",
      "airport_name": "Las Flores Airport"
    },
    {
      "city": "El Bayadh",
      "country": "Algeria",
      "IATA": "EBH",
      "icao_code": "DAOY",
      "airport_name": "El Bayadh Airport"
    },
    {
      "city": "El Bolson",
      "country": "Argentina",
      "IATA": "EHL",
      "icao_code": "SAVB",
      "airport_name": "El Bolson Airport"
    },
    {
      "city": "El Borma",
      "country": "Tunisia",
      "IATA": "EBM",
      "icao_code": "DTTR",
      "airport_name": "El Borma Airport"
    },
    {
      "city": "El Cajon",
      "country": "United States",
      "IATA": "SEE",
      "icao_code": "KSEE",
      "airport_name": "Gillespie Field"
    },
    {
      "city": "El Calafate",
      "country": "Argentina",
      "IATA": "ING",
      "icao_code": "SAWA",
      "airport_name": "Lago Argentino Airport "
    },
    {
      "city": "El Calafate",
      "country": "Argentina",
      "IATA": "FTE",
      "icao_code": "SAWC",
      "airport_name": "Comandante Armando Tola International Airport"
    },
    {
      "city": "El Centro",
      "country": "United States",
      "IATA": "NJK",
      "icao_code": "KNJK",
      "airport_name": "Naval Air Facility El Centro  "
    },
    {
      "city": "El Dorado",
      "country": "United States",
      "IATA": "ELD",
      "icao_code": "KELD",
      "airport_name": "South Arkansas Regional Airport at Goodwin Field"
    },
    {
      "city": "El Dorado",
      "country": "Venezuela",
      "IATA": "EOR",
      "icao_code": "SVED",
      "airport_name": "El Dorado Airport"
    },
    {
      "city": "El Dorado",
      "country": "Argentina",
      "IATA": "ELO",
      "icao_code": "SATD",
      "airport_name": "Eldorado Airport"
    },
    {
      "city": "El Fasher",
      "country": "Sudan",
      "IATA": "ELF",
      "icao_code": "HSFS",
      "airport_name": "El Fasher Airport"
    },
    {
      "city": "El Golea",
      "country": "Algeria",
      "IATA": "ELG",
      "icao_code": "DAUE",
      "airport_name": "El Golea Airport"
    },
    {
      "city": "El Gorah",
      "country": "Egypt",
      "IATA": "EGH",
      "airport_name": "El Gora Airport"
    },
    {
      "city": "El Monte",
      "country": "United States",
      "IATA": "EMT",
      "icao_code": "KEMT",
      "airport_name": "San Gabriel Valley Airport"
    },
    {
      "city": "El Obeid",
      "country": "Sudan",
      "IATA": "EBD",
      "icao_code": "HSOB",
      "airport_name": "El Obeid Airport"
    },
    {
      "city": "El Palomar",
      "country": "Argentina",
      "IATA": "EPA",
      "icao_code": "SADP",
      "airport_name": "El Palomar Airport"
    },
    {
      "city": "El Paso",
      "country": "United States",
      "IATA": "BIF",
      "icao_code": "KBIF",
      "airport_name": "Biggs Army Airfield"
    },
    {
      "city": "El Paso",
      "country": "United States",
      "IATA": "ELP",
      "icao_code": "KELP",
      "airport_name": "El Paso International Airport"
    },
    {
      "city": "El Salvador",
      "country": "Chile",
      "IATA": "ESR",
      "icao_code": "SCES",
      "airport_name": "Ricardo García Posada Airport"
    },
    {
      "city": "El Vigía",
      "country": "Venezuela",
      "IATA": "VIG",
      "icao_code": "SVVG",
      "airport_name": "Juan Pablo Perez Alfonso Airport"
    },
    {
      "city": "El-baha",
      "country": "Saudi Arabia",
      "IATA": "ABT",
      "icao_code": "OEBA",
      "airport_name": "Al-Baha Domestic Airport"
    },
    {
      "city": "El-tor",
      "country": "Egypt",
      "IATA": "ELT",
      "icao_code": "HETR",
      "airport_name": "Tour Sinai City Airport"
    },
    {
      "city": "Elat",
      "country": "Israel",
      "IATA": "ETH",
      "icao_code": "LLET",
      "airport_name": "Eilat Airport"
    },
    {
      "city": "Elazig",
      "country": "Turkey",
      "IATA": "EZS",
      "icao_code": "LTCA",
      "airport_name": "Elazığ Airport"
    },
    {
      "city": "Elcho Island",
      "country": "Australia",
      "IATA": "ELC",
      "icao_code": "YELD",
      "airport_name": "Elcho Island Airport"
    },
    {
      "city": "Eldoret",
      "country": "Kenya",
      "IATA": "EDL",
      "icao_code": "HKED",
      "airport_name": "Eldoret International Airport"
    },
    {
      "city": "Elfin Cove",
      "country": "United States",
      "IATA": "ELV",
      "icao_code": "PAEL",
      "airport_name": "Elfin Cove Seaplane Base"
    },
    {
      "city": "Elim",
      "country": "United States",
      "IATA": "ELI",
      "icao_code": "PFEL",
      "airport_name": "Elim Airport"
    },
    {
      "city": "Elista",
      "country": "Russia",
      "IATA": "ESL",
      "icao_code": "URWI",
      "airport_name": "Elista Airport"
    },
    {
      "city": "Elizabeth City",
      "country": "United States",
      "IATA": "ECG",
      "icao_code": "KECG",
      "airport_name": "Elizabeth City Regional Airport"
    },
    {
      "city": "Elkhart",
      "country": "United States",
      "IATA": "EKI",
      "airport_name": "Elkhart Municipal Airport "
    },
    {
      "city": "Elkins",
      "country": "United States",
      "IATA": "EKN",
      "icao_code": "KEKN",
      "airport_name": "Elkins-Randolph Co-Jennings Randolph Field Airport"
    },
    {
      "city": "Elko",
      "country": "United States",
      "IATA": "EKO",
      "icao_code": "KEKO",
      "airport_name": "Elko Regional Airport"
    },
    {
      "city": "Elmira",
      "country": "United States",
      "IATA": "ELM",
      "icao_code": "KELM",
      "airport_name": "Elmira Corning Regional Airport"
    },
    {
      "city": "Elorza",
      "country": "Venezuela",
      "IATA": "EOZ",
      "airport_name": "Elorza Airport"
    },
    {
      "city": "Ely",
      "country": "United States",
      "IATA": "ELY",
      "icao_code": "KELY",
      "airport_name": "Ely Airport"
    },
    {
      "city": "Ely",
      "country": "United States",
      "IATA": "LYU",
      "icao_code": "KELO",
      "airport_name": "Ely Municipal Airport "
    },
    {
      "city": "Emam Shahr",
      "country": "Iran",
      "IATA": "RUD",
      "icao_code": "OIMJ",
      "airport_name": "Shahid Sadooghi Airport"
    },
    {
      "city": "Emden",
      "country": "Germany",
      "IATA": "EME",
      "airport_name": "Emden Airport"
    },
    {
      "city": "Emerald",
      "country": "Australia",
      "IATA": "EMD",
      "icao_code": "YEML",
      "airport_name": "Emerald Airport"
    },
    {
      "city": "Emmen",
      "country": "Switzerland",
      "IATA": "EML",
      "airport_name": "Emmen Air Base"
    },
    {
      "city": "Emmonak",
      "country": "United States",
      "IATA": "EMK",
      "icao_code": "PAEM",
      "airport_name": "Emmonak Airport "
    },
    {
      "city": "Encarnacion",
      "country": "Paraguay",
      "IATA": "ENO",
      "icao_code": "SGEN",
      "airport_name": "Teniente Amin Ayub Gonzalez Airport"
    },
    {
      "city": "Ende",
      "country": "Indonesia",
      "IATA": "ENE",
      "icao_code": "WATE",
      "airport_name": "H. Hasan Aroeboesman Airport"
    },
    {
      "city": "Enfidha",
      "country": "Tunisia",
      "IATA": "NBE",
      "icao_code": "DTNZ",
      "airport_name": "Enfidha-Hammamet International Airport"
    },
    {
      "city": "Enid",
      "country": "United States",
      "IATA": "END",
      "airport_name": "Vance Air Force Base"
    },
    {
      "city": "Eniwetok Atoll",
      "country": "Marshall Islands",
      "IATA": "ENT",
      "airport_name": "Enewetak Auxiliary Airfield"
    },
    {
      "city": "Enniskillen",
      "country": "United Kingdom",
      "IATA": "ENK",
      "icao_code": "EGAB",
      "airport_name": "Enniskillen/St Angelo Airport"
    },
    {
      "city": "Enontekio",
      "country": "Finland",
      "IATA": "ENF",
      "icao_code": "EFET",
      "airport_name": "Enontekiö Airport"
    },
    {
      "city": "Enschede",
      "country": "Netherlands",
      "IATA": "ENS",
      "icao_code": "EHTW",
      "airport_name": "Enschede Airport Twente"
    },
    {
      "city": "Ensenada",
      "country": "Mexico",
      "IATA": "ESE",
      "airport_name": "Ensenada Airport"
    },
    {
      "city": "Enshi",
      "country": "China",
      "IATA": "ENH",
      "icao_code": "ZHES",
      "airport_name": "Enshi Xujiaping Airport"
    },
    {
      "city": "Entebbe",
      "country": "Uganda",
      "IATA": "EBB",
      "icao_code": "HUEN",
      "airport_name": "Entebbe International Airport"
    },
    {
      "city": "Enterprise",
      "country": "United States",
      "IATA": "ETS",
      "icao_code": "KEDN",
      "airport_name": "Enterprise Municipal Airport "
    },
    {
      "city": "Enugu",
      "country": "Nigeria",
      "IATA": "ENU",
      "icao_code": "DNEN",
      "airport_name": "Akanu Ibiam International Airport"
    },
    {
      "city": "Epinal",
      "country": "France",
      "IATA": "EPL",
      "icao_code": "LFSG",
      "airport_name": "Épinal - Mirecourt Airport"
    },
    {
      "city": "Eqalugaarsuit",
      "country": "Greenland",
      "IATA": "QFG",
      "airport_name": "Eqalugaarsuit Heliport"
    },
    {
      "city": "Er-rachidia",
      "country": "Morocco",
      "IATA": "ERH",
      "icao_code": "GMFK",
      "airport_name": "Moulay Ali Cherif Airport"
    },
    {
      "city": "Erbil",
      "country": "Iraq",
      "IATA": "EBL",
      "icao_code": "ORER",
      "airport_name": "Erbil International Airport"
    },
    {
      "city": "Erechim",
      "country": "Brazil",
      "IATA": "ERM",
      "icao_code": "SSER",
      "airport_name": "Erechim Airport"
    },
    {
      "city": "Erenhot",
      "country": "China",
      "IATA": "ERL",
      "icao_code": "ZBER",
      "airport_name": "Erenhot Saiwusu International Airport"
    },
    {
      "city": "Erfurt",
      "country": "Germany",
      "IATA": "ERF",
      "icao_code": "EDDE",
      "airport_name": "Erfurt–Weimar Airport"
    },
    {
      "city": "Erie",
      "country": "United States",
      "IATA": "ERI",
      "icao_code": "KERI",
      "airport_name": "Erie International Airport "
    },
    {
      "city": "Erldunda",
      "country": "Australia",
      "IATA": "EDD",
      "airport_name": "Erldunda Airport"
    },
    {
      "city": "Erzincan",
      "country": "Turkey",
      "IATA": "ERC",
      "icao_code": "LTCD",
      "airport_name": "Erzincan Airport"
    },
    {
      "city": "Erzurum",
      "country": "Turkey",
      "IATA": "ERZ",
      "icao_code": "LTCE",
      "airport_name": "Erzurum Airport"
    },
    {
      "city": "Esbjerg",
      "country": "Denmark",
      "IATA": "EBJ",
      "icao_code": "EKEB",
      "airport_name": "Esbjerg Airport"
    },
    {
      "city": "Escanaba",
      "country": "United States",
      "IATA": "ESC",
      "icao_code": "KESC",
      "airport_name": "Delta County Airport"
    },
    {
      "city": "Esfahan",
      "country": "Iran",
      "IATA": "IFN",
      "icao_code": "OIFM",
      "airport_name": "Isfahan International Airport"
    },
    {
      "city": "Eskilstuna",
      "country": "Sweden",
      "IATA": "EKT",
      "icao_code": "ESSU",
      "airport_name": "Eskilstuna Airport"
    },
    {
      "city": "Eskimo Point",
      "country": "Canada",
      "IATA": "YEK",
      "icao_code": "CYEK",
      "airport_name": "Arviat Airport"
    },
    {
      "city": "Eskisehir",
      "country": "Turkey",
      "IATA": "ESK",
      "icao_code": "LTBI",
      "airport_name": "Eskişehir Airport"
    },
    {
      "city": "Eskissehir",
      "country": "Turkey",
      "IATA": "AOE",
      "icao_code": "LTBY",
      "airport_name": "Eskişehir Hasan Polatkan Airport"
    },
    {
      "city": "Esmeraldas",
      "country": "Ecuador",
      "IATA": "ESM",
      "icao_code": "SEES",
      "airport_name": "Colonel Carlos Concha Torres Airport"
    },
    {
      "city": "Esperance",
      "country": "Australia",
      "IATA": "EPR",
      "icao_code": "YESP",
      "airport_name": "Esperance Airport"
    },
    {
      "city": "Esquel",
      "country": "Argentina",
      "IATA": "EQS",
      "icao_code": "SAVE",
      "airport_name": "Esquel Airport"
    },
    {
      "city": "Essadouira",
      "country": "Morocco",
      "IATA": "ESU",
      "icao_code": "GMMI",
      "airport_name": "Essaouira-Mogador Airport"
    },
    {
      "city": "Essen",
      "country": "Germany",
      "IATA": "ESS",
      "icao_code": "EDLE",
      "airport_name": "Essen/Mülheim Airport "
    },
    {
      "city": "Estevan",
      "country": "Canada",
      "IATA": "YEN",
      "icao_code": "CYEN",
      "airport_name": "Estevan Airport"
    },
    {
      "city": "Eua Island",
      "country": "Tonga",
      "IATA": "EUA",
      "icao_code": "NFTE",
      "airport_name": "Eua Airport"
    },
    {
      "city": "Eufala",
      "country": "United States",
      "IATA": "EUF",
      "airport_name": "Weedon Field"
    },
    {
      "city": "Eugene",
      "country": "United States",
      "IATA": "EUG",
      "icao_code": "KEUG",
      "airport_name": "Eugene Airport"
    },
    {
      "city": "Eureka",
      "country": "United States",
      "IATA": "EKA",
      "icao_code": "KEKA",
      "airport_name": "Murray Field"
    },
    {
      "city": "Eureka",
      "country": "Canada",
      "IATA": "YEU",
      "icao_code": "CYEU",
      "airport_name": "Eureka Aerodrome"
    },
    {
      "city": "Evanston",
      "country": "United States",
      "IATA": "EVW",
      "icao_code": "KEVW",
      "airport_name": "Evanston-Uinta County Burns Field"
    },
    {
      "city": "Evansville",
      "country": "United States",
      "IATA": "EVV",
      "icao_code": "KEVV",
      "airport_name": "Evansville Regional Airport"
    },
    {
      "city": "Everett",
      "country": "United States",
      "IATA": "PAE",
      "icao_code": "KPAE",
      "airport_name": "Snohomish County Airport "
    },
    {
      "city": "Evreux",
      "country": "France",
      "IATA": "EVX",
      "airport_name": "Évreux-Fauville Air Base"
    },
    {
      "city": "Exeter",
      "country": "United Kingdom",
      "IATA": "EXT",
      "icao_code": "EGTE",
      "airport_name": "Exeter International Airport"
    },
    {
      "city": "Eyn-yahav",
      "country": "Israel",
      "IATA": "EIY",
      "icao_code": "LLEY",
      "airport_name": "Ein Yahav Airfield"
    },
    {
      "city": "Eysk",
      "country": "Russia",
      "IATA": "EIK",
      "airport_name": "Yeysk Airport"
    },
    {
      "city": "Faaite",
      "country": "French Polynesia",
      "IATA": "FAC",
      "icao_code": "NTKF",
      "airport_name": "Faaite Airport"
    },
    {
      "city": "Fagernes",
      "country": "Norway",
      "IATA": "VDB",
      "icao_code": "ENFG",
      "airport_name": "Fagernes Airport, Leirin"
    },
    {
      "city": "Fair Isle",
      "country": "United Kingdom",
      "IATA": "FIE",
      "icao_code": "EGEF",
      "airport_name": "Fair Isle Airport"
    },
    {
      "city": "Fairbanks",
      "country": "United States",
      "IATA": "FAI",
      "icao_code": "PAFA",
      "airport_name": "Fairbanks International Airport"
    },
    {
      "city": "Fairbanks",
      "country": "United States",
      "IATA": "EIL",
      "airport_name": "Eielson Air Force Base"
    },
    {
      "city": "Fairfield",
      "country": "United States",
      "IATA": "SUU",
      "icao_code": "KSUU",
      "airport_name": "Travis Air Force Base"
    },
    {
      "city": "Fairford",
      "country": "United Kingdom",
      "IATA": "FFD",
      "airport_name": "Royal Air Force Fairford "
    },
    {
      "city": "Faisalabad",
      "country": "Pakistan",
      "IATA": "LYP",
      "icao_code": "OPFA",
      "airport_name": "Faisalabad International Airport"
    },
    {
      "city": "Faizabad",
      "country": "Afghanistan",
      "IATA": "FBD",
      "icao_code": "OAFZ",
      "airport_name": "Fayzabad Airport "
    },
    {
      "city": "Fajardo",
      "country": "Puerto Rico",
      "IATA": "FAJ",
      "icao_code": "TJFA",
      "airport_name": "Diego Jiménez Torres Airport"
    },
    {
      "city": "Fak Fak",
      "country": "Indonesia",
      "IATA": "FKQ",
      "icao_code": "WASF",
      "airport_name": "Fakfak Torea Airport"
    },
    {
      "city": "Fakarava",
      "country": "French Polynesia",
      "IATA": "FAV",
      "icao_code": "NTGF",
      "airport_name": "Fakarava Airport"
    },
    {
      "city": "Faleolo",
      "country": "Samoa",
      "IATA": "APW",
      "icao_code": "NSFA",
      "airport_name": "Faleolo International Airport"
    },
    {
      "city": "Fallon",
      "country": "United States",
      "IATA": "NFL",
      "icao_code": "KNFL",
      "airport_name": "Naval Air Station Fallon"
    },
    {
      "city": "Falmouth",
      "country": "United States",
      "IATA": "FMH",
      "airport_name": "Otis Air National Guard Base"
    },
    {
      "city": "False Pass",
      "country": "United States",
      "IATA": "KFP",
      "icao_code": "PAKF",
      "airport_name": "False Pass Airport"
    },
    {
      "city": "Fangatau",
      "country": "French Polynesia",
      "IATA": "FGU",
      "icao_code": "NTGB",
      "airport_name": "Fangatau Airport"
    },
    {
      "city": "Farafangana",
      "country": "Madagascar",
      "IATA": "RVA",
      "icao_code": "FMSG",
      "airport_name": "Farafangana Airport"
    },
    {
      "city": "Farah",
      "country": "Afghanistan",
      "IATA": "FAH",
      "icao_code": "OAFR",
      "airport_name": "Farah Airport"
    },
    {
      "city": "Faranah",
      "country": "Guinea",
      "IATA": "FAA",
      "icao_code": "GUFH",
      "airport_name": "Faranah Airport"
    },
    {
      "city": "Fargo",
      "country": "United States",
      "IATA": "FAR",
      "icao_code": "KFAR",
      "airport_name": "Hector International Airport"
    },
    {
      "city": "Farmington",
      "country": "United States",
      "IATA": "FMN",
      "icao_code": "KFMN",
      "airport_name": "Four Corners Regional Airport"
    },
    {
      "city": "Farnborough",
      "country": "United Kingdom",
      "IATA": "FAB",
      "icao_code": "EGLF",
      "airport_name": "Farnborough Airport"
    },
    {
      "city": "Faro",
      "country": "Portugal",
      "IATA": "FAO",
      "icao_code": "LPFR",
      "airport_name": "Faro Airport"
    },
    {
      "city": "Faro",
      "country": "Canada",
      "IATA": "ZFA",
      "icao_code": "CZFA",
      "airport_name": "Faro Airport"
    },
    {
      "city": "Farsund",
      "country": "Norway",
      "IATA": "FAN",
      "airport_name": "Farsund Airport, Lista"
    },
    {
      "city": "Fasa",
      "country": "Iran",
      "IATA": "FAZ",
      "icao_code": "OISF",
      "airport_name": "Fasa Airport"
    },
    {
      "city": "Faya-largeau",
      "country": "Chad",
      "IATA": "FYT",
      "icao_code": "FTTY",
      "airport_name": "Faya-Largeau Airport"
    },
    {
      "city": "Fayetteville",
      "country": "United States",
      "IATA": "FAY",
      "icao_code": "KFAY",
      "airport_name": "Fayetteville Regional Airport "
    },
    {
      "city": "Fayetteville",
      "country": "United States",
      "IATA": "FYV",
      "icao_code": "KFYV",
      "airport_name": "Drake Field"
    },
    {
      "city": "Fengnin",
      "country": "Taiwan",
      "IATA": "TTT",
      "icao_code": "RCQS",
      "airport_name": "Taitung Airport"
    },
    {
      "city": "Fera Island",
      "country": "Solomon Islands",
      "IATA": "FRE",
      "icao_code": "AGGF",
      "airport_name": "Fera Airport "
    },
    {
      "city": "Fergana",
      "country": "Uzbekistan",
      "IATA": "FEG",
      "icao_code": "UTKF",
      "airport_name": "Fergana International Airport"
    },
    {
      "city": "Fernando Do Noronha",
      "country": "Brazil",
      "IATA": "FEN",
      "icao_code": "SBFN",
      "airport_name": "Fernando de Noronha Airport"
    },
    {
      "city": "Fes",
      "country": "Morocco",
      "IATA": "FEZ",
      "icao_code": "GMFF",
      "airport_name": "Fes–Saïss Airport"
    },
    {
      "city": "Fianarantsoa",
      "country": "Madagascar",
      "IATA": "WFI",
      "icao_code": "FMSF",
      "airport_name": "Fianarantsoa Airport"
    },
    {
      "city": "Ficksburg",
      "country": "South Africa",
      "IATA": "FCB",
      "airport_name": "Ficksburg Sentra Oes Airport"
    },
    {
      "city": "Figari",
      "country": "France",
      "IATA": "FSC",
      "icao_code": "LFKF",
      "airport_name": "Figari–Sud Corse Airport"
    },
    {
      "city": "Findley",
      "country": "United States",
      "IATA": "FDY",
      "icao_code": "KFDY",
      "airport_name": "Findlay Airport"
    },
    {
      "city": "Finke",
      "country": "Australia",
      "IATA": "FIK",
      "icao_code": "YFNE",
      "airport_name": "Finke Airport"
    },
    {
      "city": "Fira",
      "country": "Guinea",
      "IATA": "FIG",
      "icao_code": "GUFA",
      "airport_name": "Fria Airport"
    },
    {
      "city": "Fiti'uta",
      "country": "American Samoa",
      "IATA": "FTI",
      "icao_code": "NSFQ",
      "airport_name": "Fitiuta Airport "
    },
    {
      "city": "Fitzroy Crossing",
      "country": "Australia",
      "IATA": "FIZ",
      "icao_code": "YFTZ",
      "airport_name": "Fitzroy Crossing Airport"
    },
    {
      "city": "Flagstaff",
      "country": "United States",
      "IATA": "FLG",
      "icao_code": "KFLG",
      "airport_name": "Flagstaff Pulliam Airport"
    },
    {
      "city": "Flensburg",
      "country": "Germany",
      "IATA": "FLF",
      "icao_code": "EDXF",
      "airport_name": " Flensburg Schaferhaus Airport"
    },
    {
      "city": "Flin Flon",
      "country": "Canada",
      "IATA": "YFO",
      "icao_code": "CYFO",
      "airport_name": "Flin Flon Airport"
    },
    {
      "city": "Flinders Island",
      "country": "Australia",
      "IATA": "FLS",
      "icao_code": "YFLI",
      "airport_name": "Flinders Island Airport"
    },
    {
      "city": "Flint",
      "country": "United States",
      "IATA": "FNT",
      "icao_code": "KFNT",
      "airport_name": "Bishop International Airport"
    },
    {
      "city": "Flippin",
      "country": "United States",
      "IATA": "FLP",
      "icao_code": "KFLP",
      "airport_name": "Marion County Regional Airport"
    },
    {
      "city": "Florence",
      "country": "United States",
      "IATA": "FLO",
      "icao_code": "KFLO",
      "airport_name": "Florence Regional Airport"
    },
    {
      "city": "Florence",
      "country": "Italy",
      "IATA": "FLR",
      "icao_code": "LIRQ",
      "airport_name": "Florence Airport, Peretola"
    },
    {
      "city": "Florencia",
      "country": "Colombia",
      "IATA": "FLA",
      "icao_code": "SKFL",
      "airport_name": "Gustavo Artunduaga Paredes Airport"
    },
    {
      "city": "Flores",
      "country": "Guatemala",
      "IATA": "FRS",
      "icao_code": "MGTK",
      "airport_name": "Mundo Maya International Airport"
    },
    {
      "city": "Flores",
      "country": "Portugal",
      "IATA": "FLW",
      "icao_code": "LPFL",
      "airport_name": "Flores Airport"
    },
    {
      "city": "Floriano",
      "country": "Brazil",
      "IATA": "FLB",
      "icao_code": "SNQG",
      "airport_name": "Cangapara Airport"
    },
    {
      "city": "Florianopolis",
      "country": "Brazil",
      "IATA": "FLN",
      "icao_code": "SBFL",
      "airport_name": "Florianópolis-Hercílio Luz International Airport"
    },
    {
      "city": "Floro",
      "country": "Norway",
      "IATA": "FRO",
      "icao_code": "ENFL",
      "airport_name": "Florø Airport"
    },
    {
      "city": "Foggia",
      "country": "Italy",
      "IATA": "FOG",
      "icao_code": "LIBF",
      "airport_name": "Gino Lisa Airport"
    },
    {
      "city": "Fond du Lac",
      "country": "United States",
      "IATA": "FLD",
      "icao_code": "KFLD",
      "airport_name": "Fond du Lac County Airport"
    },
    {
      "city": "Fond-Du-Lac",
      "country": "Canada",
      "IATA": "ZFD",
      "icao_code": "CZFD",
      "airport_name": "Fond-du-Lac Airport"
    },
    {
      "city": "Fonte Boa",
      "country": "Brazil",
      "IATA": "FBA",
      "icao_code": "SWOB",
      "airport_name": "Fonte Boa Airport"
    },
    {
      "city": "Forbes",
      "country": "Australia",
      "IATA": "FRB",
      "icao_code": "YFBS",
      "airport_name": "Forbes Airport"
    },
    {
      "city": "Forestville",
      "country": "Canada",
      "IATA": "YFE",
      "icao_code": "CYFE",
      "airport_name": "Forestville Airport"
    },
    {
      "city": "Forli",
      "country": "Italy",
      "IATA": "FRL",
      "icao_code": "LIPK",
      "airport_name": "Forlì International Airport"
    },
    {
      "city": "Formosa",
      "country": "Argentina",
      "IATA": "FMA",
      "icao_code": "SARF",
      "airport_name": "Formosa International Airport "
    },
    {
      "city": "Forrest",
      "country": "Australia",
      "IATA": "FOS",
      "icao_code": "YPFT",
      "airport_name": "Forrest Airport"
    },
    {
      "city": "Fort Albany",
      "country": "Canada",
      "IATA": "YFA",
      "icao_code": "CYFA",
      "airport_name": "Fort Albany Airport"
    },
    {
      "city": "Fort Belvoir",
      "country": "United States",
      "IATA": "DAA",
      "airport_name": "Davison Army Airfield"
    },
    {
      "city": "Fort Benning",
      "country": "United States",
      "IATA": "LSF",
      "icao_code": "KLSF",
      "airport_name": "Lawson Army Airfield"
    },
    {
      "city": "Fort Bragg",
      "country": "United States",
      "IATA": "POB",
      "airport_name": "Pope Field"
    },
    {
      "city": "Fort Bridger",
      "country": "United States",
      "IATA": "FBR",
      "airport_name": "Fort Bridger Airport"
    },
    {
      "city": "Fort Carson",
      "country": "United States",
      "IATA": "FCS",
      "icao_code": "KFCS",
      "airport_name": "Butts Army Airfield "
    },
    {
      "city": "Fort Chipewyan",
      "country": "Canada",
      "IATA": "YPY",
      "airport_name": "Fort Chipewyan Airport"
    },
    {
      "city": "Fort Collins",
      "country": "United States",
      "IATA": "FNL",
      "icao_code": "KFNL",
      "airport_name": "Northern Colorado Regional Airport"
    },
    {
      "city": "Fort Dodge",
      "country": "United States",
      "IATA": "FOD",
      "icao_code": "KFOD",
      "airport_name": "Fort Dodge Regional Airport"
    },
    {
      "city": "Fort Eustis",
      "country": "United States",
      "IATA": "FAF",
      "icao_code": "KFAF",
      "airport_name": "Felker Army Air Field"
    },
    {
      "city": "Fort Frances",
      "country": "Canada",
      "IATA": "YAG",
      "icao_code": "CYAG",
      "airport_name": "Fort Frances Municipal Airport"
    },
    {
      "city": "Fort Good Hope",
      "country": "Canada",
      "IATA": "YGH",
      "icao_code": "CYGH",
      "airport_name": "Fort Good Hope Airport"
    },
    {
      "city": "Fort Hood",
      "country": "United States",
      "IATA": "HLR",
      "airport_name": "Hood Army Airfield"
    },
    {
      "city": "Fort Hope",
      "country": "Canada",
      "IATA": "YFH",
      "icao_code": "CYFH",
      "airport_name": "Fort Hope Airport"
    },
    {
      "city": "Fort Huachuca",
      "country": "United States",
      "IATA": "FHU",
      "icao_code": "KFHU",
      "airport_name": "Sierra Vista Municipal Airport"
    },
    {
      "city": "Fort Irwin",
      "country": "United States",
      "IATA": "BYS",
      "icao_code": "KBYS",
      "airport_name": "Bicycle Lake Army Airfield "
    },
    {
      "city": "Fort Knox",
      "country": "United States",
      "IATA": "FTK",
      "icao_code": "KFTK",
      "airport_name": "Godman Army Airfield"
    },
    {
      "city": "South Florida, Fort Lauderdale",
      "country": "United States",
      "IATA": "FLL",
      "icao_code": "KFLL",
      "airport_name": "Fort Lauderdale-Hollywood International Airport"
    },
    {
      "city": "Fort Lauderdale",
      "country": "United States",
      "IATA": "FXE",
      "icao_code": "KFXE",
      "airport_name": "Fort Lauderdale Executive Airport"
    },
    {
      "city": "Fort Leavenworth",
      "country": "United States",
      "IATA": "FLV",
      "airport_name": "Sherman Army Airfield"
    },
    {
      "city": "Fort Leonardwood",
      "country": "United States",
      "IATA": "TBN",
      "icao_code": "KTBN",
      "airport_name": "Waynesville-St. Robert Regional Airport "
    },
    {
      "city": "Fort Lewis",
      "country": "United States",
      "IATA": "GRF",
      "airport_name": "Gray Army Airfield "
    },
    {
      "city": "Fort Liard",
      "country": "Canada",
      "IATA": "YJF",
      "icao_code": "CYJF",
      "airport_name": "Fort Liard Airport"
    },
    {
      "city": "Fort Mcmurray",
      "country": "Canada",
      "IATA": "YMM",
      "icao_code": "CYMM",
      "airport_name": "Fort McMurray International Airport"
    },
    {
      "city": "Fort Mcpherson",
      "country": "Canada",
      "IATA": "ZFM",
      "icao_code": "CZFM",
      "airport_name": "Fort McPherson Airport"
    },
    {
      "city": "Fort Meade",
      "country": "United States",
      "IATA": "FME",
      "icao_code": "KFME",
      "airport_name": "Tipton Airport "
    },
    {
      "city": "Fort Myers",
      "country": "United States",
      "IATA": "RSW",
      "icao_code": "KRSW",
      "airport_name": "Southwest Florida International Airport"
    },
    {
      "city": "Fort Myers",
      "country": "United States",
      "IATA": "FMY",
      "icao_code": "KFMY",
      "airport_name": "Page Field"
    },
    {
      "city": "Fort Nelson",
      "country": "Canada",
      "IATA": "YYE",
      "icao_code": "CYYE",
      "airport_name": "Northern Rockies Regional Airport"
    },
    {
      "city": "Fort Ord",
      "country": "United States",
      "IATA": "OAR",
      "icao_code": "KOAR",
      "airport_name": "Marina Municipal Airport"
    },
    {
      "city": "Fort Pierce",
      "country": "United States",
      "IATA": "FPR",
      "icao_code": "KFPR",
      "airport_name": "Treasure Coast International Airport"
    },
    {
      "city": "Fort Polk",
      "country": "United States",
      "IATA": "POE",
      "airport_name": "Polk Army Airfield"
    },
    {
      "city": "Fort Resolution",
      "country": "Canada",
      "IATA": "YFR",
      "icao_code": "CYFR",
      "airport_name": "Fort Resolution Airport"
    },
    {
      "city": "Fort Richardson",
      "country": "United States",
      "IATA": "FRN",
      "airport_name": "Bryant Army Heliport "
    },
    {
      "city": "Fort Riley",
      "country": "United States",
      "IATA": "FRI",
      "airport_name": "Marshall Army Airfield"
    },
    {
      "city": "Fort Rucker/Ozark",
      "country": "United States",
      "IATA": "OZR",
      "airport_name": "Cairns Army Airfield "
    },
    {
      "city": "Fort Saint John",
      "country": "Canada",
      "IATA": "YXJ",
      "icao_code": "CYXJ",
      "airport_name": "Fort St. John Airport"
    },
    {
      "city": "Fort Severn",
      "country": "Canada",
      "IATA": "YER",
      "icao_code": "CYER",
      "airport_name": "Fort Severn Airport"
    },
    {
      "city": "Fort Sill",
      "country": "United States",
      "IATA": "FSI",
      "icao_code": "KFSI",
      "airport_name": "Henry Post Army Airfield "
    },
    {
      "city": "Fort Simpson",
      "country": "Canada",
      "IATA": "YFS",
      "icao_code": "CYFS",
      "airport_name": "Fort Simpson Airport"
    },
    {
      "city": "Fort Smith",
      "country": "United States",
      "IATA": "FSM",
      "icao_code": "KFSM",
      "airport_name": "Fort Smith Regional Airport"
    },
    {
      "city": "Fort Smith",
      "country": "Canada",
      "IATA": "YSM",
      "icao_code": "CYSM",
      "airport_name": "Fort Smith Regional Airport"
    },
    {
      "city": "Fort Stockton",
      "country": "United States",
      "IATA": "FST",
      "icao_code": "KFST",
      "airport_name": "Fort Stockton-Pecos County Airport"
    },
    {
      "city": "Fort Wainwright",
      "country": "United States",
      "IATA": "FBK",
      "airport_name": "Ladd Army Airfield"
    },
    {
      "city": "Fort Wayne",
      "country": "United States",
      "IATA": "FWA",
      "icao_code": "KFWA",
      "airport_name": "Fort Wayne International Airport"
    },
    {
      "city": "Fort Wayne IN",
      "country": "United States",
      "IATA": "SMD",
      "airport_name": "Smith Field Airport"
    },
    {
      "city": "Fort Worth",
      "country": "United States",
      "IATA": "AFW",
      "icao_code": "KAFW",
      "airport_name": "Fort Worth Alliance Airport"
    },
    {
      "city": "Fort Worth",
      "country": "United States",
      "IATA": "FTW",
      "icao_code": "KFTW",
      "airport_name": "Fort Worth Meacham International Airport"
    },
    {
      "city": "Fort Yukon",
      "country": "United States",
      "IATA": "FYU",
      "icao_code": "PFYU",
      "airport_name": "Fort Yukon Airport"
    },
    {
      "city": "Fort-de-france",
      "country": "Martinique",
      "IATA": "FDF",
      "icao_code": "TFFF",
      "airport_name": "Martinique Aimé Césaire International Airport"
    },
    {
      "city": "Fortaleza",
      "country": "Brazil",
      "IATA": "FOR",
      "icao_code": "SBFZ",
      "airport_name": "Pinto Martins International Airport"
    },
    {
      "city": "Foshan",
      "country": "China",
      "IATA": "FUO",
      "airport_name": "Foshan Shadi Airport"
    },
    {
      "city": "Foumban",
      "country": "Cameroon",
      "IATA": "FOM",
      "icao_code": "FKKM",
      "airport_name": "Foumban Nkounja Airport"
    },
    {
      "city": "Foz Do Iguacu",
      "country": "Brazil",
      "IATA": "IGU",
      "icao_code": "SBFI",
      "airport_name": "Foz do Iguaçu International Airport"
    },
    {
      "city": "Franca",
      "country": "Brazil",
      "IATA": "FRC",
      "icao_code": "SIMK",
      "airport_name": "Franca Airport"
    },
    {
      "city": "Franceville",
      "country": "Gabon",
      "IATA": "MVB",
      "icao_code": "FOON",
      "airport_name": "M'Vengue El Hadj Omar Bongo Ondimba International Airport"
    },
    {
      "city": "Francisco Beltrao",
      "country": "Brazil",
      "IATA": "FBE",
      "icao_code": "SSFB",
      "airport_name": "Francisco Beltrão Airport"
    },
    {
      "city": "Francistown",
      "country": "Botswana",
      "IATA": "FRW",
      "icao_code": "FBFT",
      "airport_name": "Francistown Airport"
    },
    {
      "city": "Frankfort",
      "country": "United States",
      "IATA": "FFT",
      "icao_code": "KFFT",
      "airport_name": "Capital City Airport "
    },
    {
      "city": "Frankfurt",
      "country": "Germany",
      "IATA": "FRA",
      "icao_code": "EDDF",
      "airport_name": "Frankfurt International Airport"
    },
    {
      "city": "Franklin",
      "country": "United States",
      "IATA": "FKL",
      "icao_code": "KFKL",
      "airport_name": "Venango Regional Airport "
    },
    {
      "city": "Fraser Island",
      "country": "Australia",
      "IATA": "OCN",
      "icao_code": "KOKB",
      "airport_name": "Oceanside Municipal Airport "
    },
    {
      "city": "Fredericksburg",
      "country": "United States",
      "IATA": "FBG",
      "icao_code": "KFBG",
      "airport_name": "Simmons Army Airfield"
    },
    {
      "city": "Fredericton",
      "country": "Canada",
      "IATA": "YFC",
      "icao_code": "CYFC",
      "airport_name": "Fredericton International Airport"
    },
    {
      "city": "Freeport",
      "country": "Bahamas",
      "IATA": "FPO",
      "icao_code": "MYGF",
      "airport_name": "Grand Bahama International Airport"
    },
    {
      "city": "Freetown",
      "country": "Sierra Leone",
      "IATA": "HGS",
      "icao_code": "GFHA",
      "airport_name": "Hastings Airport"
    },
    {
      "city": "Freetown",
      "country": "Sierra Leone",
      "IATA": "FNA",
      "icao_code": "GFLL",
      "airport_name": "Lungi International Airport"
    },
    {
      "city": "Frejus",
      "country": "France",
      "IATA": "FRJ",
      "airport_name": "Frejus Airport"
    },
    {
      "city": "Fremont",
      "country": "United States",
      "IATA": "FET",
      "icao_code": "KFET",
      "airport_name": "Fremont Municipal Airport"
    },
    {
      "city": "Frenchville",
      "country": "United States",
      "IATA": "WFK",
      "airport_name": "Northern Aroostook Regional Airport"
    },
    {
      "city": "Fresno",
      "country": "United States",
      "IATA": "FAT",
      "icao_code": "KFAT",
      "airport_name": "Fresno Yosemite International Airport"
    },
    {
      "city": "Friday Harbor",
      "country": "United States",
      "IATA": "FRD",
      "icao_code": "KFHR",
      "airport_name": "Friday Harbor Airport "
    },
    {
      "city": "Friedrichshafen",
      "country": "Germany",
      "IATA": "FDH",
      "icao_code": "EDNY",
      "airport_name": "Friedrichshafen Airport "
    },
    {
      "city": "Fritzlar",
      "country": "Germany",
      "IATA": "FRZ",
      "icao_code": "ETHF",
      "airport_name": "Fritzlar Air Base"
    },
    {
      "city": "Fryeburg",
      "country": "United States",
      "IATA": "FRY",
      "airport_name": "Eastern Slopes Regional Airport "
    },
    {
      "city": "Fuerstenfeldbruck",
      "country": "Germany",
      "IATA": "FEL",
      "icao_code": "ETSF",
      "airport_name": "Fürstenfeldbruck Air Base"
    },
    {
      "city": "Fuerte Gral Roca",
      "country": "Argentina",
      "IATA": "GNR",
      "icao_code": "SAHR",
      "airport_name": "Dr. Arturo Umberto Illia Airport"
    },
    {
      "city": "Fuerteventura",
      "country": "Spain",
      "IATA": "FUE",
      "icao_code": "GCFV",
      "airport_name": "El Matorral Airport"
    },
    {
      "city": "Fujeirah",
      "country": "United Arab Emirates",
      "IATA": "FJR",
      "icao_code": "OMFJ",
      "airport_name": "Fujairah International Airport"
    },
    {
      "city": "Fukue",
      "country": "Japan",
      "IATA": "FUJ",
      "icao_code": "RJFE",
      "airport_name": "Fukue Airport"
    },
    {
      "city": "Fukui",
      "country": "Japan",
      "IATA": "FKJ",
      "icao_code": "RJNF",
      "airport_name": "Fukui Airport"
    },
    {
      "city": "Fukuoka",
      "country": "Japan",
      "IATA": "FUK",
      "icao_code": "RJFF",
      "airport_name": "Fukuoka Airport "
    },
    {
      "city": "Fukushima",
      "country": "Japan",
      "IATA": "FKS",
      "icao_code": "RJSF",
      "airport_name": "Fukushima Airport"
    },
    {
      "city": "Fullerton",
      "country": "United States",
      "IATA": "FUL",
      "icao_code": "KFUL",
      "airport_name": "Fullerton Municipal Airport"
    },
    {
      "city": "Funafuti",
      "country": "Tuvalu",
      "IATA": "FUN",
      "icao_code": "NGFU",
      "airport_name": "Funafuti International Airport"
    },
    {
      "city": "Funchal",
      "country": "Portugal",
      "IATA": "FNC",
      "icao_code": "LPMA",
      "airport_name": "Madeira Airport"
    },
    {
      "city": "Funter Bay",
      "country": "United States",
      "IATA": "FNR",
      "icao_code": "PANR",
      "airport_name": "Funter Bay Seaplane Base"
    },
    {
      "city": "Futaleufu",
      "country": "Chile",
      "IATA": "FFU",
      "icao_code": "SCFT",
      "airport_name": "Futaleufú Airfield"
    },
    {
      "city": "Futuna Island",
      "country": "Vanuatu",
      "IATA": "FTA",
      "icao_code": "NVVF",
      "airport_name": "Futuna Airport"
    },
    {
      "city": "Futuna Island",
      "country": "Wallis and Futuna",
      "IATA": "FUT",
      "icao_code": "NLWF",
      "airport_name": "Pointe Vele Airport"
    },
    {
      "city": "Fuyang",
      "country": "China",
      "IATA": "FUG",
      "icao_code": "ZSFY",
      "airport_name": "Fuyang Xiguan Airport"
    },
    {
      "city": "Fuyuan",
      "country": "China",
      "IATA": "FYJ",
      "icao_code": "ZYFY",
      "airport_name": "Fuyuan Dongji Airport"
    },
    {
      "city": "Fuyun",
      "country": "China",
      "IATA": "FYN",
      "icao_code": "ZWFY",
      "airport_name": "Fuyun Koktokay Airport"
    },
    {
      "city": "Fuzhou",
      "country": "China",
      "IATA": "FOC",
      "icao_code": "ZSFZ",
      "airport_name": "Fuzhou Changle International Airport"
    },
    {
      "city": "GAYLORD",
      "country": "United States",
      "IATA": "GLR",
      "icao_code": "KGLR",
      "airport_name": "Gaylord Regional Airport"
    },
    {
      "city": "Gaberone",
      "country": "Botswana",
      "IATA": "GBE",
      "icao_code": "FBSK",
      "airport_name": "Sir Seretse Khama International Airport"
    },
    {
      "city": "Gabes",
      "country": "Tunisia",
      "IATA": "GAE",
      "airport_name": "Gabès - Matmata International Airport"
    },
    {
      "city": "Gachsaran",
      "country": "Iran",
      "IATA": "GCH",
      "airport_name": "Gachsaran Airport"
    },
    {
      "city": "Gadsden",
      "country": "United States",
      "IATA": "GAD",
      "icao_code": "KGAD",
      "airport_name": "Northeast Alabama Regional Airport"
    },
    {
      "city": "Gafsa",
      "country": "Tunisia",
      "IATA": "GAF",
      "icao_code": "DTTF",
      "airport_name": "Gafsa - Ksar International Airport"
    },
    {
      "city": "Gainesville",
      "country": "United States",
      "IATA": "GVL",
      "icao_code": "KGVL",
      "airport_name": "Lee Gilmer Memorial Airport"
    },
    {
      "city": "Gainesville",
      "country": "United States",
      "IATA": "GNV",
      "icao_code": "KGNV",
      "airport_name": "Gainesville Regional Airport"
    },
    {
      "city": "Gaithersburg",
      "country": "United States",
      "IATA": "GAI",
      "icao_code": "KGAI",
      "airport_name": "Montgomery County Airpark"
    },
    {
      "city": "Galapagos",
      "country": "Ecuador",
      "IATA": "GPS",
      "icao_code": "SEGS",
      "airport_name": "Seymour Airport"
    },
    {
      "city": "Galcaio",
      "country": "Somalia",
      "IATA": "GLK",
      "icao_code": "HCMR",
      "airport_name": "Abdullahi Yusuf Airport "
    },
    {
      "city": "Galena",
      "country": "United States",
      "IATA": "GAL",
      "icao_code": "PAGA",
      "airport_name": "Edward G. Pitka Sr. Airport"
    },
    {
      "city": "Galesburg",
      "country": "United States",
      "IATA": "GBG",
      "icao_code": "KGBG",
      "airport_name": "Galesburg Municipal Airport"
    },
    {
      "city": "Galion",
      "country": "United States",
      "IATA": "GQQ",
      "icao_code": "KGQQ",
      "airport_name": "Galion Municipal Airport"
    },
    {
      "city": "Gallivare",
      "country": "Sweden",
      "IATA": "GEV",
      "icao_code": "ESNG",
      "airport_name": "Gällivare Lapland Airport"
    },
    {
      "city": "Gallup",
      "country": "United States",
      "IATA": "GUP",
      "icao_code": "KGUP",
      "airport_name": "Gallup Municipal Airport"
    },
    {
      "city": "Galoya",
      "country": "Sri Lanka",
      "IATA": "ADP",
      "airport_name": "Anuradhapura Airport"
    },
    {
      "city": "Galveston",
      "country": "United States",
      "IATA": "GLS",
      "icao_code": "KGLS",
      "airport_name": "Scholes International Airport at Galveston"
    },
    {
      "city": "Galway",
      "country": "Ireland",
      "IATA": "GWY",
      "icao_code": "EICM",
      "airport_name": "Galway Airport"
    },
    {
      "city": "Gambell",
      "country": "United States",
      "IATA": "GAM",
      "icao_code": "PAGM",
      "airport_name": "Gambell Airport"
    },
    {
      "city": "Gambella",
      "country": "Ethiopia",
      "IATA": "GMB",
      "icao_code": "HAGM",
      "airport_name": "Gambela Airport"
    },
    {
      "city": "Gamètì",
      "country": "Canada",
      "IATA": "YRA",
      "icao_code": "CEV4",
      "airport_name": "Gamètì/Rae Lakes Airport"
    },
    {
      "city": "Gan Island",
      "country": "Maldives",
      "IATA": "GAN",
      "icao_code": "VRMG",
      "airport_name": "Gan International Airport"
    },
    {
      "city": "Gander",
      "country": "Canada",
      "IATA": "YQX",
      "icao_code": "CYQX",
      "airport_name": "Gander International Airport"
    },
    {
      "city": "Ganges",
      "country": "Canada",
      "IATA": "YGG",
      "airport_name": "Ganges Water Aerodrome"
    },
    {
      "city": "Ganja",
      "country": "Azerbaijan",
      "IATA": "KVD",
      "icao_code": "UBBG",
      "airport_name": "Ganja International Airport"
    },
    {
      "city": "Ganzhou",
      "country": "China",
      "IATA": "KOW",
      "icao_code": "ZSGZ",
      "airport_name": "Ganzhou Huangjin Airport"
    },
    {
      "city": "Gao",
      "country": "Mali",
      "IATA": "GAQ",
      "icao_code": "GAGO",
      "airport_name": "Gao International Airport"
    },
    {
      "city": "Garden City",
      "country": "United States",
      "IATA": "GCK",
      "icao_code": "KGCK",
      "airport_name": "Garden City Regional Airport"
    },
    {
      "city": "Garissa",
      "country": "Kenya",
      "IATA": "GAS",
      "icao_code": "HKGA",
      "airport_name": "Garissa Airport"
    },
    {
      "city": "Garoua",
      "country": "Cameroon",
      "IATA": "GOU",
      "icao_code": "FKKR",
      "airport_name": "Garoua International Airport"
    },
    {
      "city": "Gary",
      "country": "United States",
      "IATA": "GYY",
      "icao_code": "KGYY",
      "airport_name": "Gary/Chicago International Airport"
    },
    {
      "city": "Gasan",
      "country": "Philippines",
      "IATA": "MRQ",
      "icao_code": "RPUW",
      "airport_name": "Marinduque Airport"
    },
    {
      "city": "Gaspe",
      "country": "Canada",
      "IATA": "YGP",
      "icao_code": "CYGP",
      "airport_name": "Michel-Pouliot Gaspé  Airport"
    },
    {
      "city": "Gassim",
      "country": "Saudi Arabia",
      "IATA": "ELQ",
      "icao_code": "OEGS",
      "airport_name": "Prince Nayef bin Abdulaziz International Airport"
    },
    {
      "city": "Gatineau",
      "country": "Canada",
      "IATA": "YND",
      "icao_code": "CYND",
      "airport_name": "Gatineau-Ottawa Executive Airport"
    },
    {
      "city": "Gaua Island",
      "country": "Vanuatu",
      "IATA": "ZGU",
      "icao_code": "NVSQ",
      "airport_name": "Gaua Airport"
    },
    {
      "city": "Gavle",
      "country": "Sweden",
      "IATA": "GVX",
      "icao_code": "ESSK",
      "airport_name": "Gävle-Sandviken Airport "
    },
    {
      "city": "Gaya",
      "country": "India",
      "IATA": "GAY",
      "icao_code": "VEGY",
      "airport_name": "Gaya Airport"
    },
    {
      "city": "Gayndah",
      "country": "Australia",
      "IATA": "GAH",
      "icao_code": "YGAY",
      "airport_name": "Gayndah Airport"
    },
    {
      "city": "Gaza",
      "country": "Palestine",
      "IATA": "GZA",
      "airport_name": "Yasser Arafat International Airport"
    },
    {
      "city": "Gaziantep",
      "country": "Turkey",
      "IATA": "GZT",
      "icao_code": "LTAJ",
      "airport_name": "Gaziantep Oğuzeli International Airport"
    },
    {
      "city": "Gbadolite",
      "country": "Congo (Kinshasa)",
      "IATA": "BDT",
      "icao_code": "FZFD",
      "airport_name": "Gbadolite Airport"
    },
    {
      "city": "Gbangbatok",
      "country": "Sierra Leone",
      "IATA": "GBK",
      "icao_code": "GFGK",
      "airport_name": "Gbangbatok Airport"
    },
    {
      "city": "Gdansk",
      "country": "Poland",
      "IATA": "GDN",
      "icao_code": "EPGD",
      "airport_name": "Gdansk Lech Walesa Airport "
    },
    {
      "city": "Gdynia",
      "country": "Poland",
      "IATA": "QYD",
      "airport_name": "Gdynia-Kosakowo Airport"
    },
    {
      "city": "Geelong",
      "country": "Australia",
      "IATA": "GEX",
      "icao_code": "YGLG",
      "airport_name": "Geelong Airport"
    },
    {
      "city": "Geilenkirchen",
      "country": "Germany",
      "IATA": "GKE",
      "icao_code": "ETNG",
      "airport_name": "NATO Air Base Geilenkirchen"
    },
    {
      "city": "Geilo",
      "country": "Norway",
      "IATA": "DLD",
      "airport_name": "Geilo Airport, Dagali"
    },
    {
      "city": "Geita",
      "country": "Tanzania",
      "IATA": "GIT",
      "airport_name": "Geita Airport"
    },
    {
      "city": "Gelendzhik",
      "country": "Russia",
      "IATA": "GDZ",
      "airport_name": "Gelendzhik Airport"
    },
    {
      "city": "Gelephu",
      "country": "Bhutan",
      "IATA": "GLU",
      "airport_name": "Gelephu Airport"
    },
    {
      "city": "Gemena",
      "country": "Congo (Kinshasa)",
      "IATA": "GMA",
      "icao_code": "FZFK",
      "airport_name": "Gemena Airport"
    },
    {
      "city": "Geneina",
      "country": "Sudan",
      "IATA": "EGN",
      "icao_code": "HSGN",
      "airport_name": "Geneina Airport"
    },
    {
      "city": "General Pico",
      "country": "Argentina",
      "IATA": "GPO",
      "icao_code": "SAZG",
      "airport_name": "General Pico Airport"
    },
    {
      "city": "Geneva",
      "country": "Switzerland",
      "IATA": "GVA",
      "icao_code": "LSGG",
      "airport_name": "Geneva International Airport"
    },
    {
      "city": "Genoa",
      "country": "Italy",
      "IATA": "GOA",
      "icao_code": "LIMJ",
      "airport_name": "Genoa Airport"
    },
    {
      "city": "George",
      "country": "South Africa",
      "IATA": "GRJ",
      "icao_code": "FAGG",
      "airport_name": "George Airport"
    },
    {
      "city": "Georgetown",
      "country": "United States",
      "IATA": "GGE",
      "icao_code": "KGGE",
      "airport_name": "Georgetown County Airport"
    },
    {
      "city": "Georgetown",
      "country": "United States",
      "IATA": "GED",
      "icao_code": "KGED",
      "airport_name": "Delaware Coastal  Airport"
    },
    {
      "city": "Georgetown",
      "country": "Guyana",
      "IATA": "OGL",
      "airport_name": "Eugene F. Correira International Airport "
    },
    {
      "city": "Georgetown",
      "country": "Guyana",
      "IATA": "GEO",
      "icao_code": "SYCJ",
      "airport_name": "Cheddi Jagan International Airport "
    },
    {
      "city": "Georgetown",
      "country": "Cayman Islands",
      "IATA": "GCM",
      "icao_code": "MWCR",
      "airport_name": "Owen Roberts International Airport"
    },
    {
      "city": "Geralds",
      "country": "Montserrat",
      "IATA": "MNI",
      "airport_name": "John A. Osborne Airport"
    },
    {
      "city": "Geraldton",
      "country": "Australia",
      "IATA": "GET",
      "icao_code": "YGEL",
      "airport_name": "Geraldton Airport"
    },
    {
      "city": "Geraldton",
      "country": "Canada",
      "IATA": "YGQ",
      "icao_code": "CYGQ",
      "airport_name": "Geraldton  Airport"
    },
    {
      "city": "Gerona",
      "country": "Spain",
      "IATA": "GRO",
      "icao_code": "LEGE",
      "airport_name": "Girona-Costa Brava Airport"
    },
    {
      "city": "Geçitkale",
      "country": "Cyprus",
      "IATA": "GEC",
      "airport_name": "Geçitkale Airport"
    },
    {
      "city": "Ghadames",
      "country": "Libya",
      "IATA": "LTD",
      "icao_code": "HLTD",
      "airport_name": "Ghadames Airport"
    },
    {
      "city": "Ghanzi",
      "country": "Botswana",
      "IATA": "GNZ",
      "icao_code": "FBGZ",
      "airport_name": "Ghanzi Airport"
    },
    {
      "city": "Ghardaia",
      "country": "Algeria",
      "IATA": "GHA",
      "icao_code": "DAUG",
      "airport_name": "Noumérat – Moufdi Zakaria Airport"
    },
    {
      "city": "Ghat",
      "country": "Libya",
      "IATA": "GHT",
      "icao_code": "HLGT",
      "airport_name": "Ghat Airport"
    },
    {
      "city": "Ghazni",
      "country": "Afghanistan",
      "IATA": "GZI",
      "airport_name": "Ghazni Airport"
    },
    {
      "city": "Ghazvin",
      "country": "Iran",
      "IATA": "GZW",
      "airport_name": "Ghazvin Airport"
    },
    {
      "city": "Ghriss",
      "country": "Algeria",
      "IATA": "MUW",
      "icao_code": "DAOV",
      "airport_name": "Ghriss Airport"
    },
    {
      "city": "Gibraltar",
      "country": "Gibraltar",
      "IATA": "GIB",
      "icao_code": "LXGB",
      "airport_name": "Gibraltar International Airport"
    },
    {
      "city": "Giebelstadt",
      "country": "Germany",
      "IATA": "GHF",
      "icao_code": "ETEU",
      "airport_name": "Giebelstadt Airport"
    },
    {
      "city": "Gifu",
      "country": "Japan",
      "IATA": "QGU",
      "airport_name": "Gifu Airport"
    },
    {
      "city": "Gilgit",
      "country": "Pakistan",
      "IATA": "GIL",
      "icao_code": "OPGT",
      "airport_name": "Gilgit Airport"
    },
    {
      "city": "Gillam",
      "country": "Canada",
      "IATA": "YGX",
      "icao_code": "CYGX",
      "airport_name": "Gillam Airport"
    },
    {
      "city": "Gillette",
      "country": "United States",
      "IATA": "GCC",
      "icao_code": "KGCC",
      "airport_name": "Gillette-Campbell County Airport"
    },
    {
      "city": "Gilze-rijen",
      "country": "Netherlands",
      "IATA": "GLZ",
      "icao_code": "EHGR",
      "airport_name": "Gilze-Rijen Air Base"
    },
    {
      "city": "Gimli",
      "country": "Canada",
      "IATA": "YGM",
      "icao_code": "CYGM",
      "airport_name": "Gimli Industrial Park Airport"
    },
    {
      "city": "Girardot",
      "country": "Colombia",
      "IATA": "GIR",
      "icao_code": "SKGI",
      "airport_name": "Santiago Vila Airport"
    },
    {
      "city": "Girua",
      "country": "Papua New Guinea",
      "IATA": "PNP",
      "icao_code": "AYGR",
      "airport_name": "Girua Airport"
    },
    {
      "city": "Gisborne",
      "country": "New Zealand",
      "IATA": "GIS",
      "icao_code": "NZGS",
      "airport_name": "Gisborne Airport"
    },
    {
      "city": "Gisenyi",
      "country": "Rwanda",
      "IATA": "GYI",
      "icao_code": "HRYG",
      "airport_name": "Gisenyi Airport"
    },
    {
      "city": "Gitega",
      "country": "Burundi",
      "IATA": "GID",
      "airport_name": "Gitega Airport"
    },
    {
      "city": "Gizan",
      "country": "Saudi Arabia",
      "IATA": "GIZ",
      "icao_code": "OEGN",
      "airport_name": "Jizan Regional Airport"
    },
    {
      "city": "Gizo",
      "country": "Solomon Islands",
      "IATA": "GZO",
      "icao_code": "AGGN",
      "airport_name": "Nusatupe Airport"
    },
    {
      "city": "Gjoa Haven",
      "country": "Canada",
      "IATA": "YHK",
      "icao_code": "CYHK",
      "airport_name": "Gjoa Haven Airport"
    },
    {
      "city": "Gjogur",
      "country": "Iceland",
      "IATA": "GJR",
      "icao_code": "BIGJ",
      "airport_name": "Gjögur Airport"
    },
    {
      "city": "Gladstone",
      "country": "Australia",
      "IATA": "GLT",
      "icao_code": "YGLA",
      "airport_name": "Gladstone Airport"
    },
    {
      "city": "Gladwin",
      "country": "United States",
      "IATA": "GDW",
      "icao_code": "KGDW",
      "airport_name": "Gladwin Zettel Memorial Airport"
    },
    {
      "city": "Glasgow",
      "country": "United States",
      "IATA": "GLW",
      "icao_code": "KGLW",
      "airport_name": "Glasgow Municipal Airport"
    },
    {
      "city": "Glasgow",
      "country": "United States",
      "IATA": "GGW",
      "icao_code": "KGGW",
      "airport_name": "Glasgow Airport "
    },
    {
      "city": "Glasgow",
      "country": "United Kingdom",
      "IATA": "GLA",
      "icao_code": "EGPF",
      "airport_name": "Glasgow International Airport"
    },
    {
      "city": "Glen Innes",
      "country": "Australia",
      "IATA": "GLI",
      "icao_code": "YGLI",
      "airport_name": "Glen Innes Airport"
    },
    {
      "city": "Glendive",
      "country": "United States",
      "IATA": "GDV",
      "icao_code": "KGDV",
      "airport_name": "Dawson Community Airport"
    },
    {
      "city": "Glentanner",
      "country": "New Zealand",
      "IATA": "GTN",
      "icao_code": "NZMC",
      "airport_name": "Glentanner Aerodrome"
    },
    {
      "city": "Goa",
      "country": "India",
      "IATA": "GOI",
      "icao_code": "VAGO",
      "airport_name": "Goa International Airport"
    },
    {
      "city": "Goba",
      "country": "Ethiopia",
      "IATA": "GOB",
      "icao_code": "HAGB",
      "airport_name": "Robe Airport"
    },
    {
      "city": "Gobernador Gregores",
      "country": "Argentina",
      "IATA": "GGS",
      "icao_code": "SAWR",
      "airport_name": "Gobernador Gregores Airport"
    },
    {
      "city": "Gode",
      "country": "Ethiopia",
      "IATA": "GDE",
      "icao_code": "HAGO",
      "airport_name": "Gode Airport "
    },
    {
      "city": "Gods Lake Narrows",
      "country": "Canada",
      "IATA": "YGO",
      "icao_code": "CYGO",
      "airport_name": "Gods Lake Narrows Airport"
    },
    {
      "city": "Gods River",
      "country": "Canada",
      "IATA": "ZGI",
      "icao_code": "CZGI",
      "airport_name": "Gods River Airport"
    },
    {
      "city": "Godthaab",
      "country": "Greenland",
      "IATA": "GOH",
      "icao_code": "BGGH",
      "airport_name": "Nuuk Airport "
    },
    {
      "city": "Goiania",
      "country": "Brazil",
      "IATA": "GYN",
      "icao_code": "SBGO",
      "airport_name": "Santa Genoveva International Airport"
    },
    {
      "city": "Goldsboro",
      "country": "United States",
      "IATA": "GSB",
      "airport_name": "Seymour Johnson Air Force Base"
    },
    {
      "city": "Golfito",
      "country": "Costa Rica",
      "IATA": "GLF",
      "icao_code": "MRGF",
      "airport_name": "Golfito Airport"
    },
    {
      "city": "Golmud",
      "country": "China",
      "IATA": "GOQ",
      "icao_code": "ZLGM",
      "airport_name": "Golmud Airport"
    },
    {
      "city": "Golog",
      "country": "China",
      "IATA": "GMQ",
      "icao_code": "ZLGL",
      "airport_name": "Golog Maqin Airport"
    },
    {
      "city": "Golouchestershire",
      "country": "United Kingdom",
      "IATA": "GLO",
      "icao_code": "EGBJ",
      "airport_name": "Gloucestershire Airport"
    },
    {
      "city": "Golovin",
      "country": "United States",
      "IATA": "GLV",
      "icao_code": "PAGL",
      "airport_name": "Golovin Airport"
    },
    {
      "city": "Goma",
      "country": "Congo (Kinshasa)",
      "IATA": "GOM",
      "icao_code": "FZNA",
      "airport_name": "Goma International Airport"
    },
    {
      "city": "Gombe",
      "country": "Nigeria",
      "IATA": "GMO",
      "icao_code": "DNGO",
      "airport_name": "Gombe Lawanti International Airport"
    },
    {
      "city": "Gomel",
      "country": "Belarus",
      "IATA": "GME",
      "icao_code": "UMGG",
      "airport_name": "Gomel Airport"
    },
    {
      "city": "Gondar",
      "country": "Ethiopia",
      "IATA": "GDQ",
      "icao_code": "HAGN",
      "airport_name": "Gondar Airport"
    },
    {
      "city": "Goodland",
      "country": "United States",
      "IATA": "GLD",
      "icao_code": "KGLD",
      "airport_name": "Goodland Municipal Airport "
    },
    {
      "city": "Goodwood",
      "country": "United Kingdom",
      "IATA": "QUG",
      "icao_code": "EGHR",
      "airport_name": "Chichester/Goodwood Airport"
    },
    {
      "city": "Goodyear",
      "country": "United States",
      "IATA": "GYR",
      "icao_code": "KGYR",
      "airport_name": "Phoenix Goodyear Airport"
    },
    {
      "city": "Goondiwindi",
      "country": "Australia",
      "IATA": "GOO",
      "icao_code": "YGDI",
      "airport_name": "Goondiwindi Airport"
    },
    {
      "city": "Goose Bay",
      "country": "Canada",
      "IATA": "YYR",
      "icao_code": "CYYR",
      "airport_name": "Goose Bay Airport"
    },
    {
      "city": "Gorakhpur",
      "country": "India",
      "IATA": "GOP",
      "icao_code": "VEGK",
      "airport_name": "Shri Mahayogi Gorakhnath Airport"
    },
    {
      "city": "Gore",
      "country": "Ethiopia",
      "IATA": "GOR",
      "icao_code": "HAGR",
      "airport_name": "Gore Airport"
    },
    {
      "city": "Gore Bay",
      "country": "Canada",
      "IATA": "YZE",
      "icao_code": "CYZE",
      "airport_name": "Gore Bay-Manitoulin Airport"
    },
    {
      "city": "Gorgan",
      "country": "Iran",
      "IATA": "GBT",
      "icao_code": "OING",
      "airport_name": "Gorgan Airport"
    },
    {
      "city": "Gorna Orechovica",
      "country": "Bulgaria",
      "IATA": "GOZ",
      "icao_code": "LBGO",
      "airport_name": "Gorna Oryahovitsa Airport"
    },
    {
      "city": "Gorno-Altaysk",
      "country": "Russia",
      "IATA": "RGK",
      "icao_code": "UNBG",
      "airport_name": "Gorno-Altaysk Airport"
    },
    {
      "city": "Goroka",
      "country": "Papua New Guinea",
      "IATA": "GKA",
      "icao_code": "AYGA",
      "airport_name": "Goroka Airport"
    },
    {
      "city": "Gorontalo",
      "country": "Indonesia",
      "IATA": "GTO",
      "icao_code": "WAMG",
      "airport_name": "Jalaluddin Airport"
    },
    {
      "city": "Gothenborg",
      "country": "Sweden",
      "IATA": "GSE",
      "icao_code": "ESGP",
      "airport_name": "Göteborg City Airport"
    },
    {
      "city": "Gothenborg",
      "country": "Sweden",
      "IATA": "GOT",
      "icao_code": "ESGG",
      "airport_name": "Göteborg Landvetter Airport"
    },
    {
      "city": "Goulburn",
      "country": "Australia",
      "IATA": "GUL",
      "icao_code": "YGLB",
      "airport_name": "Goulburn Airport"
    },
    {
      "city": "Gove",
      "country": "Australia",
      "IATA": "GOV",
      "icao_code": "YPGV",
      "airport_name": "Gove Airport"
    },
    {
      "city": "Governador Valadares",
      "country": "Brazil",
      "IATA": "GVR",
      "icao_code": "SNGV",
      "airport_name": "Governador Valadares Airport"
    },
    {
      "city": "Governor's Harbor",
      "country": "Bahamas",
      "IATA": "GHB",
      "icao_code": "MYEM",
      "airport_name": "Governor's Harbour Airport"
    },
    {
      "city": "Goya",
      "country": "Argentina",
      "IATA": "OYA",
      "icao_code": "SATG",
      "airport_name": "Goya Airport"
    },
    {
      "city": "Gozo",
      "country": "Malta",
      "IATA": "GZM",
      "airport_name": "Xewkija Heliport "
    },
    {
      "city": "Graciosa Island",
      "country": "Portugal",
      "IATA": "GRW",
      "icao_code": "LPGR",
      "airport_name": "Graciosa Island Airport"
    },
    {
      "city": "Grafton",
      "country": "Australia",
      "IATA": "GFN",
      "icao_code": "YGFN",
      "airport_name": "Clarence Valley Regional Airport"
    },
    {
      "city": "Gran Canaria",
      "country": "Spain",
      "IATA": "LPA",
      "icao_code": "GCLP",
      "airport_name": "Gran Canaria Airport"
    },
    {
      "city": "Granada",
      "country": "Spain",
      "IATA": "GRX",
      "icao_code": "LEGR",
      "airport_name": "Federico García Lorca Airport"
    },
    {
      "city": "Grand Bourg",
      "country": "Guadeloupe",
      "IATA": "GBJ",
      "airport_name": "Marie Galante Airport "
    },
    {
      "city": "Grand Canyon",
      "country": "United States",
      "IATA": "GCN",
      "icao_code": "KGCN",
      "airport_name": "Grand Canyon National Park Airport"
    },
    {
      "city": "Grand Forks",
      "country": "Canada",
      "IATA": "ZGF",
      "icao_code": "CZGF",
      "airport_name": "Grand Forks Airport"
    },
    {
      "city": "Grand Forks",
      "country": "United States",
      "IATA": "GFK",
      "icao_code": "KGFK",
      "airport_name": "Grand Forks International Airport"
    },
    {
      "city": "Grand Island",
      "country": "United States",
      "IATA": "GRI",
      "icao_code": "KGRI",
      "airport_name": "Central Nebraska Regional Airport"
    },
    {
      "city": "Grand Junction",
      "country": "United States",
      "IATA": "GJT",
      "icao_code": "KGJT",
      "airport_name": "Grand Junction Regional Airport "
    },
    {
      "city": "Grand Marais",
      "country": "United States",
      "IATA": "GRM",
      "icao_code": "KCKC",
      "airport_name": "Grand Marais/Cook County Airport "
    },
    {
      "city": "Grand Rapids",
      "country": "United States",
      "IATA": "GRR",
      "icao_code": "KGRR",
      "airport_name": "Gerald R. Ford International Airport"
    },
    {
      "city": "Grand Rapids MN",
      "country": "United States",
      "IATA": "GPZ",
      "icao_code": "KGPZ",
      "airport_name": "Grand Rapids-Itasca County Airport "
    },
    {
      "city": "Grand-Santi",
      "country": "French Guiana",
      "IATA": "GSI",
      "airport_name": "Guadalcanal Airport"
    },
    {
      "city": "Grande Anse",
      "country": "Guadeloupe",
      "IATA": "DSD",
      "airport_name": " La Désirade Airport"
    },
    {
      "city": "Grande Prairie",
      "country": "Canada",
      "IATA": "YQU",
      "icao_code": "CYQU",
      "airport_name": "Grande Prairie Airport"
    },
    {
      "city": "Grant County Airport",
      "country": "United States",
      "IATA": "MWH",
      "icao_code": "KMWH",
      "airport_name": "Grant County International Airport"
    },
    {
      "city": "Grants",
      "country": "United States",
      "IATA": "GNT",
      "icao_code": "KGNT",
      "airport_name": "Grants-Milan Municipal Airport"
    },
    {
      "city": "Granville",
      "country": "France",
      "IATA": "GFR",
      "airport_name": "Granville Airport"
    },
    {
      "city": "Graz",
      "country": "Austria",
      "IATA": "GRZ",
      "icao_code": "LOWG",
      "airport_name": "Graz Airport "
    },
    {
      "city": "Great Bend",
      "country": "United States",
      "IATA": "GBD",
      "icao_code": "KGBD",
      "airport_name": "Great Bend Municipal Airport"
    },
    {
      "city": "Great Exuma",
      "country": "Bahamas",
      "IATA": "GGT",
      "icao_code": "MYEF",
      "airport_name": "Exuma International Airport"
    },
    {
      "city": "Great Falls",
      "country": "United States",
      "IATA": "GTF",
      "icao_code": "KGTF",
      "airport_name": "Great Falls International Airport"
    },
    {
      "city": "Great Keppel Island",
      "country": "Australia",
      "IATA": "GKL",
      "airport_name": "Great Keppel Island Airport"
    },
    {
      "city": "Greeley",
      "country": "United States",
      "IATA": "GXY",
      "icao_code": "KGXY",
      "airport_name": "Greeley-Weld County Airport"
    },
    {
      "city": "Green Bay",
      "country": "United States",
      "IATA": "GRB",
      "icao_code": "KGRB",
      "airport_name": "Austin Straubel International Airport"
    },
    {
      "city": "Green Island",
      "country": "Taiwan",
      "IATA": "GNI",
      "icao_code": "RCGI",
      "airport_name": "Lyudao Airport "
    },
    {
      "city": "Greensboro",
      "country": "United States",
      "IATA": "GSO",
      "icao_code": "KGSO",
      "airport_name": "Piedmont Triad International Airport"
    },
    {
      "city": "Greenvile",
      "country": "United States",
      "IATA": "GVT",
      "icao_code": "KGVT",
      "airport_name": "Majors Airport"
    },
    {
      "city": "Greenville",
      "country": "United States",
      "IATA": "GMU",
      "icao_code": "KGMU",
      "airport_name": "Greenville Downtown Airport"
    },
    {
      "city": "Greenville",
      "country": "United States",
      "IATA": "GDC",
      "icao_code": "KGYH",
      "airport_name": "Donaldson Center Airport "
    },
    {
      "city": "Greenville",
      "country": "Liberia",
      "IATA": "CPA",
      "icao_code": "GLCP",
      "airport_name": "Cape Palmas Airport "
    },
    {
      "city": "Greenville",
      "country": "United States",
      "IATA": "PGV",
      "icao_code": "KPGV",
      "airport_name": "Pitt-Greenville Airport"
    },
    {
      "city": "Greenville",
      "country": "United States",
      "IATA": "GLH",
      "icao_code": "KGLH",
      "airport_name": "Mid-Delta Regional Airport"
    },
    {
      "city": "Greenville",
      "country": "United States",
      "IATA": "GSP",
      "icao_code": "KGSP",
      "airport_name": "Greenville-Spartanburg International Airport"
    },
    {
      "city": "Greenwood",
      "country": "United States",
      "IATA": "GWO",
      "icao_code": "KGWO",
      "airport_name": "Greenwood-Leflore Airport"
    },
    {
      "city": "Greenwood",
      "country": "Canada",
      "IATA": "YZX",
      "icao_code": "CYZX",
      "airport_name": "Indy South Greenwood Airport"
    },
    {
      "city": "Grenchen",
      "country": "Switzerland",
      "IATA": "ZHI",
      "airport_name": "Grenchen Airport"
    },
    {
      "city": "Grenoble",
      "country": "France",
      "IATA": "GNB",
      "icao_code": "LFLS",
      "airport_name": "Grenoble–Isère Airport"
    },
    {
      "city": "Griffith",
      "country": "Australia",
      "IATA": "GFF",
      "icao_code": "YGTH",
      "airport_name": "Griffith Airport"
    },
    {
      "city": "Grise Fiord",
      "country": "Canada",
      "IATA": "YGZ",
      "icao_code": "CYGZ",
      "airport_name": "Grise Fiord Airport"
    },
    {
      "city": "Groningen",
      "country": "Netherlands",
      "IATA": "GRQ",
      "icao_code": "EHGG",
      "airport_name": "Groningen Airport Eelde"
    },
    {
      "city": "Groote Eylandt",
      "country": "Australia",
      "IATA": "GTE",
      "icao_code": "YGTE",
      "airport_name": "Groote Eylandt Airport"
    },
    {
      "city": "Grootfontein",
      "country": "Namibia",
      "IATA": "GFY",
      "icao_code": "FYGF",
      "airport_name": "Grootfontein Air Base"
    },
    {
      "city": "Grosseto",
      "country": "Italy",
      "IATA": "GRS",
      "icao_code": "LIRS",
      "airport_name": "Grosseto Airport "
    },
    {
      "city": "Groton CT",
      "country": "United States",
      "IATA": "GON",
      "icao_code": "KGON",
      "airport_name": "Groton-New London Airport"
    },
    {
      "city": "Grottaglie",
      "country": "Italy",
      "IATA": "TAR",
      "icao_code": "LIBG",
      "airport_name": "Taranto-Grottaglie \"Marcello Arlotta\" Airport "
    },
    {
      "city": "Grundarfjordur",
      "country": "Iceland",
      "IATA": "GUU",
      "icao_code": "BIGF",
      "airport_name": "Grundarfjörður Airport"
    },
    {
      "city": "Grímsey",
      "country": "Iceland",
      "IATA": "GRY",
      "icao_code": "BIGR",
      "airport_name": "Grímsey Airport"
    },
    {
      "city": "Guadalajara",
      "country": "Mexico",
      "IATA": "GDL",
      "icao_code": "MMGL",
      "airport_name": "Guadalajara International Airport"
    },
    {
      "city": "Guajara-mirim",
      "country": "Brazil",
      "IATA": "GJM",
      "icao_code": "SBGM",
      "airport_name": "Guajará-Mirim Airport"
    },
    {
      "city": "Gualeguaychu",
      "country": "Argentina",
      "IATA": "GHU",
      "icao_code": "SAAG",
      "airport_name": "Gualeguaychu Airport"
    },
    {
      "city": "Guanaja",
      "country": "Honduras",
      "IATA": "GJA",
      "icao_code": "MHNJ",
      "airport_name": "Guanaja Airport"
    },
    {
      "city": "Guanambi",
      "country": "Brazil",
      "IATA": "GNM",
      "icao_code": "SNGI",
      "airport_name": "Guanambi Airport"
    },
    {
      "city": "Guanare",
      "country": "Venezuela",
      "IATA": "GUQ",
      "icao_code": "SVGU",
      "airport_name": "Guanare Airport"
    },
    {
      "city": "Guanghua",
      "country": "China",
      "IATA": "LHK",
      "icao_code": "ZHGH",
      "airport_name": "Guanghua Airport"
    },
    {
      "city": "Guangyuan",
      "country": "China",
      "IATA": "GYS",
      "icao_code": "ZUGU",
      "airport_name": "Guangyuan Panlong Airport"
    },
    {
      "city": "Guangzhou",
      "country": "China",
      "IATA": "CAN",
      "icao_code": "ZGGG",
      "airport_name": "Guangzhou Baiyun International Airport"
    },
    {
      "city": "Guantanamo",
      "country": "Cuba",
      "IATA": "GAO",
      "icao_code": "MUGT",
      "airport_name": "Mariana Grajales Airport "
    },
    {
      "city": "Guantanamo",
      "country": "Cuba",
      "IATA": "NBW",
      "icao_code": "MUGM",
      "airport_name": "Naval Station Guantanamo Bay"
    },
    {
      "city": "Guapi",
      "country": "Colombia",
      "IATA": "GPI",
      "icao_code": "SKGP",
      "airport_name": "Guapi Airport"
    },
    {
      "city": "Guapiles",
      "country": "Costa Rica",
      "IATA": "GPL",
      "icao_code": "MRGP",
      "airport_name": "Guápiles Airport"
    },
    {
      "city": "Guarapari",
      "country": "Brazil",
      "IATA": "GUZ",
      "icao_code": "SNGA",
      "airport_name": "Guarapari Airport"
    },
    {
      "city": "Guarapuava",
      "country": "Brazil",
      "IATA": "GPB",
      "icao_code": "SSUW",
      "airport_name": "Guarapuava Airport"
    },
    {
      "city": "Guaratingueta",
      "country": "Brazil",
      "IATA": "GUJ",
      "icao_code": "SBGW",
      "airport_name": "Guaratingueta Airport"
    },
    {
      "city": "Guasdualito",
      "country": "Venezuela",
      "IATA": "GDO",
      "icao_code": "SVGD",
      "airport_name": "Guasdualito Airport "
    },
    {
      "city": "Guatemala City",
      "country": "Guatemala",
      "IATA": "GUA",
      "icao_code": "MGGT",
      "airport_name": "La Aurora International Airport"
    },
    {
      "city": "Guayana",
      "country": "Venezuela",
      "IATA": "PZO",
      "icao_code": "SVPR",
      "airport_name": "Manuel Carlos Piar Guayana International Airport"
    },
    {
      "city": "Guayaquil",
      "country": "Ecuador",
      "IATA": "GYE",
      "icao_code": "SEGU",
      "airport_name": "José Joaquín de Olmedo International Airport"
    },
    {
      "city": "Guayaramerín",
      "country": "Bolivia",
      "IATA": "GYA",
      "icao_code": "SLGY",
      "airport_name": "Guayaramerín Airport"
    },
    {
      "city": "Guaymas",
      "country": "Mexico",
      "IATA": "GYM",
      "icao_code": "MMGM",
      "airport_name": "General José María Yáñez International Airport"
    },
    {
      "city": "Guemar",
      "country": "Algeria",
      "IATA": "ELU",
      "icao_code": "DAUO",
      "airport_name": "Guemar Airport"
    },
    {
      "city": "Guernsey",
      "country": "Guernsey",
      "IATA": "GCI",
      "icao_code": "EGJB",
      "airport_name": "Guernsey Airport"
    },
    {
      "city": "Guerrero Negro",
      "country": "Mexico",
      "IATA": "GUB",
      "icao_code": "MMGR",
      "airport_name": "Guerrero Negro Airport"
    },
    {
      "city": "Guetersloh",
      "country": "Germany",
      "IATA": "GUT",
      "icao_code": "ETUO",
      "airport_name": "Royal Air Force Station Gütersloh"
    },
    {
      "city": "Guilin",
      "country": "China",
      "IATA": "KWL",
      "icao_code": "ZGKL",
      "airport_name": "Guilin Liangjiang International Airport"
    },
    {
      "city": "Guiria",
      "country": "Venezuela",
      "IATA": "GUI",
      "icao_code": "SVGI",
      "airport_name": "Guiria Airport"
    },
    {
      "city": "Guiyang",
      "country": "China",
      "IATA": "KWE",
      "icao_code": "ZUGY",
      "airport_name": "Guiyang Longdongbao International Airport"
    },
    {
      "city": "Gulf Shores",
      "country": "United States",
      "IATA": "GUF",
      "icao_code": "KJKA",
      "airport_name": "Jack Edwards Airport "
    },
    {
      "city": "Gulfport",
      "country": "United States",
      "IATA": "GPT",
      "icao_code": "KGPT",
      "airport_name": "Gulfport-Biloxi International Airport"
    },
    {
      "city": "Gulkana",
      "country": "United States",
      "IATA": "GKN",
      "icao_code": "PAGK",
      "airport_name": "Gulkana Airport"
    },
    {
      "city": "Gulu",
      "country": "Uganda",
      "IATA": "ULU",
      "icao_code": "HUGU",
      "airport_name": "Gulu Airport"
    },
    {
      "city": "Guna",
      "country": "India",
      "IATA": "GUX",
      "icao_code": "VAGN",
      "airport_name": "Guna Airport"
    },
    {
      "city": "Gunnison",
      "country": "United States",
      "IATA": "GUC",
      "icao_code": "KGUC",
      "airport_name": "Gunnison-Crested Butte Regional Airport"
    },
    {
      "city": "Gunung Sitoli",
      "country": "Indonesia",
      "IATA": "GNS",
      "icao_code": "WIMB",
      "airport_name": "Binaka Airport"
    },
    {
      "city": "Guriat",
      "country": "Saudi Arabia",
      "IATA": "URY",
      "icao_code": "OEGT",
      "airport_name": "Gurayat Domestic Airport"
    },
    {
      "city": "Gurney",
      "country": "Papua New Guinea",
      "IATA": "GUR",
      "icao_code": "AYGN",
      "airport_name": "Gurney Airport"
    },
    {
      "city": "Gurupi",
      "country": "Brazil",
      "IATA": "GRP",
      "icao_code": "SWGI",
      "airport_name": "Gurupi Airport"
    },
    {
      "city": "Gustavia",
      "country": "France",
      "IATA": "SBH",
      "icao_code": "TFFJ",
      "airport_name": "Gustaf III Airport"
    },
    {
      "city": "Gustavus",
      "country": "United States",
      "IATA": "GST",
      "icao_code": "PAGS",
      "airport_name": "Gustavus Airport"
    },
    {
      "city": "Guwahati",
      "country": "India",
      "IATA": "GAU",
      "icao_code": "VEGT",
      "airport_name": "Lokpriya Gopinath Bordoloi International Airport"
    },
    {
      "city": "Guymon",
      "country": "United States",
      "IATA": "GUY",
      "icao_code": "KGUY",
      "airport_name": "Guymon Municipal Airport"
    },
    {
      "city": "Guyuan",
      "country": "China",
      "IATA": "GYU",
      "airport_name": "Guyuan Liupanshan Airport"
    },
    {
      "city": "Gwadar",
      "country": "Pakistan",
      "IATA": "GWD",
      "icao_code": "OPGD",
      "airport_name": "Gwadar International Airport"
    },
    {
      "city": "Gwalior",
      "country": "India",
      "IATA": "GWL",
      "icao_code": "VIGR",
      "airport_name": "Rajmata Vijaya Raje Scindia Air Terminal"
    },
    {
      "city": "Gwert",
      "country": "Zimbabwe",
      "IATA": "GWE",
      "icao_code": "FVTL",
      "airport_name": "Gweru-Thornhill Air Base"
    },
    {
      "city": "Gwinn",
      "country": "United States",
      "IATA": "MQT",
      "icao_code": "KSAW",
      "airport_name": "Sawyer International Airport"
    },
    {
      "city": "Gyumri",
      "country": "Armenia",
      "IATA": "LWN",
      "icao_code": "UDSG",
      "airport_name": "Shirak International Airport"
    },
    {
      "city": "Haa Dhaalu Atoll",
      "country": "Maldives",
      "IATA": "HAQ",
      "icao_code": "VRMH",
      "airport_name": "Hanimaadhoo International Airport "
    },
    {
      "city": "Hachijojima",
      "country": "Japan",
      "IATA": "HAC",
      "icao_code": "RJTH",
      "airport_name": "Hachijojima Airport "
    },
    {
      "city": "Hachinoe",
      "country": "Japan",
      "IATA": "HHE",
      "airport_name": "JMSDF Hachinohe Air Base"
    },
    {
      "city": "Hafr Al-batin",
      "country": "Saudi Arabia",
      "IATA": "AQI",
      "icao_code": "OEPA",
      "airport_name": "Al Qaisumah/Hafr Al Batin Airport"
    },
    {
      "city": "Hagerstown",
      "country": "United States",
      "IATA": "HGR",
      "icao_code": "KHGR",
      "airport_name": "Hagerstown Regional Airport "
    },
    {
      "city": "Hagfors",
      "country": "Sweden",
      "IATA": "HFS",
      "icao_code": "ESOH",
      "airport_name": "Hagfors Airport"
    },
    {
      "city": "Hahn",
      "country": "Germany",
      "IATA": "HHN",
      "icao_code": "EDFH",
      "airport_name": "Frankfurt-Hahn Airport"
    },
    {
      "city": "Haifa",
      "country": "Israel",
      "IATA": "HFA",
      "icao_code": "LLHA",
      "airport_name": "Haifa International Airport "
    },
    {
      "city": "Haikou",
      "country": "China",
      "IATA": "HAK",
      "icao_code": "ZJHK",
      "airport_name": "Haikou Meilan International Airport"
    },
    {
      "city": "Hail",
      "country": "Saudi Arabia",
      "IATA": "HAS",
      "icao_code": "OEHL",
      "airport_name": "Ha'il Regional Airport"
    },
    {
      "city": "Hailar",
      "country": "China",
      "IATA": "HLD",
      "icao_code": "ZBLA",
      "airport_name": "Hulunbuir Hailar Airport"
    },
    {
      "city": "Sun Valley, Idaho",
      "country": "United States",
      "IATA": "SUN",
      "icao_code": "KSUN",
      "airport_name": "Friedman Memorial Airport"
    },
    {
      "city": "Haines",
      "country": "United States",
      "IATA": "HNS",
      "icao_code": "KHNS",
      "airport_name": "Haines Airport"
    },
    {
      "city": "Haines Junction",
      "country": "Canada",
      "IATA": "YHT",
      "icao_code": "CYHT",
      "airport_name": "Haines Junction Airport"
    },
    {
      "city": "Haiphong",
      "country": "Vietnam",
      "IATA": "HPH",
      "icao_code": "VVCI",
      "airport_name": "Cat Bi International Airport "
    },
    {
      "city": "Haixi",
      "country": "China",
      "IATA": "HXD",
      "icao_code": "ZLDL",
      "airport_name": "Hilton Head Airport"
    },
    {
      "city": "Hakkari",
      "country": "Turkey",
      "IATA": "YKO",
      "icao_code": "LTCW",
      "airport_name": "Hakkari–Yüksekova Airport"
    },
    {
      "city": "Hakodate",
      "country": "Japan",
      "IATA": "HKD",
      "icao_code": "RJCH",
      "airport_name": "Hakodate Airport"
    },
    {
      "city": "Half Moon Bay",
      "country": "United States",
      "IATA": "HAF",
      "icao_code": "KHAF",
      "airport_name": "Half Moon Bay Airport"
    },
    {
      "city": "Halifax",
      "country": "Canada",
      "IATA": "YHZ",
      "icao_code": "CYHZ",
      "airport_name": "Halifax Stanfield International Airport"
    },
    {
      "city": "Hall Beach",
      "country": "Canada",
      "IATA": "YUX",
      "icao_code": "CYUX",
      "airport_name": "Hall Beach Airport"
    },
    {
      "city": "Halli",
      "country": "Finland",
      "IATA": "KEV",
      "icao_code": "EFHA",
      "airport_name": "Halli Airport"
    },
    {
      "city": "Halls Creek",
      "country": "Australia",
      "IATA": "HCQ",
      "icao_code": "YHLC",
      "airport_name": "Halls Creek Airport"
    },
    {
      "city": "Halmstad",
      "country": "Sweden",
      "IATA": "HAD",
      "icao_code": "ESMT",
      "airport_name": "Halmstad Airport"
    },
    {
      "city": "Hamadan",
      "country": "Iran",
      "IATA": "HDM",
      "icao_code": "OIHS",
      "airport_name": "Hamedan International  Airport"
    },
    {
      "city": "Hamar",
      "country": "Norway",
      "IATA": "HMR",
      "icao_code": "ENHA",
      "airport_name": "Hamar Airport, Stafsberg"
    },
    {
      "city": "Hamburg",
      "country": "Germany",
      "IATA": "XFW",
      "icao_code": "EDHI",
      "airport_name": "Hamburg Finkenwerder Airport"
    },
    {
      "city": "Hamburg",
      "country": "Germany",
      "IATA": "HAM",
      "icao_code": "EDDH",
      "airport_name": "Hamburg Airport "
    },
    {
      "city": "Hamhung",
      "country": "North Korea",
      "IATA": "DSO",
      "airport_name": "Sondok Airport"
    },
    {
      "city": "Hami",
      "country": "China",
      "IATA": "HMI",
      "icao_code": "ZWHM",
      "airport_name": "Hami Airport"
    },
    {
      "city": "Hamilton",
      "country": "United States",
      "IATA": "HAO",
      "airport_name": "Butler County Regional Airport"
    },
    {
      "city": "Hamilton",
      "country": "Australia",
      "IATA": "HLT",
      "icao_code": "YHML",
      "airport_name": "Hamilton Airport (Victoria)"
    },
    {
      "city": "Hamilton",
      "country": "New Zealand",
      "IATA": "HLZ",
      "icao_code": "NZHN",
      "airport_name": "Hamilton  Airport"
    },
    {
      "city": "Hamilton",
      "country": "Canada",
      "IATA": "YHM",
      "icao_code": "CYHM",
      "airport_name": "John C. Munro Hamilton International Airport"
    },
    {
      "city": "Hamilton Island",
      "country": "Australia",
      "IATA": "HTI",
      "icao_code": "YBHM",
      "airport_name": "Great Barrier Reef Airport"
    },
    {
      "city": "Hammerfest",
      "country": "Norway",
      "IATA": "HFT",
      "icao_code": "ENHF",
      "airport_name": "Hammerfest Airport"
    },
    {
      "city": "Hampton",
      "country": "United States",
      "IATA": "LFI",
      "airport_name": "Langley Field"
    },
    {
      "city": "Hana",
      "country": "United States",
      "IATA": "HNM",
      "icao_code": "PHHN",
      "airport_name": "Hana Airport"
    },
    {
      "city": "Hanamaki",
      "country": "Japan",
      "IATA": "HNA",
      "icao_code": "RJSI",
      "airport_name": "Hanamaki Airport"
    },
    {
      "city": "Hanau",
      "country": "Germany",
      "IATA": "ZNF",
      "airport_name": "Hanau Army Airfield"
    },
    {
      "city": "Hancock",
      "country": "United States",
      "IATA": "CMX",
      "icao_code": "KCMX",
      "airport_name": "Houghton County Memorial Airport"
    },
    {
      "city": "Handan",
      "country": "China",
      "IATA": "HDG",
      "icao_code": "ZBHD",
      "airport_name": "Handan Airport"
    },
    {
      "city": "Hangzhou",
      "country": "China",
      "IATA": "HGH",
      "icao_code": "ZSHC",
      "airport_name": "Hangzhou Xiaoshan International Airport"
    },
    {
      "city": "Hannover",
      "country": "Germany",
      "IATA": "HAJ",
      "icao_code": "EDDV",
      "airport_name": "Hannover-Langenhagen Airport"
    },
    {
      "city": "Hanoi",
      "country": "Vietnam",
      "IATA": "HAN",
      "icao_code": "VVNB",
      "airport_name": "Nội Bài International Airport "
    },
    {
      "city": "Hanzhong",
      "country": "China",
      "IATA": "HZG",
      "icao_code": "ZLHZ",
      "airport_name": "Hanzhong Xiguan Airport"
    },
    {
      "city": "Hao Island",
      "country": "French Polynesia",
      "IATA": "HOI",
      "icao_code": "NTTO",
      "airport_name": "Hao Airport"
    },
    {
      "city": "Harare",
      "country": "Zimbabwe",
      "IATA": "HRE",
      "icao_code": "FVHA",
      "airport_name": "Harare International Airport"
    },
    {
      "city": "Harbin",
      "country": "China",
      "IATA": "HRB",
      "icao_code": "ZYHB",
      "airport_name": "Harbin Taiping International Airport"
    },
    {
      "city": "Hargeisa",
      "country": "Somalia",
      "IATA": "HGA",
      "icao_code": "HCMH",
      "airport_name": "Hargeisa Egal International Airport"
    },
    {
      "city": "Harlingen",
      "country": "United States",
      "IATA": "HRL",
      "icao_code": "KHRL",
      "airport_name": "Valley International Airport"
    },
    {
      "city": "Harrisburg",
      "country": "United States",
      "IATA": "HAR",
      "icao_code": "KCXY",
      "airport_name": "Capital City Airport "
    },
    {
      "city": "Harrisburg",
      "country": "United States",
      "IATA": "MDT",
      "icao_code": "KMDT",
      "airport_name": "Harrisburg International Airport"
    },
    {
      "city": "Harrismith",
      "country": "South Africa",
      "IATA": "HRS",
      "airport_name": "Harrismith Airport"
    },
    {
      "city": "Harrison",
      "country": "United States",
      "IATA": "HRO",
      "icao_code": "KHRO",
      "airport_name": "Boone County Regional Airport"
    },
    {
      "city": "Hartford",
      "country": "United States",
      "IATA": "HFD",
      "icao_code": "KHFD",
      "airport_name": "Hartford-Brainard Airport"
    },
    {
      "city": "Hartsville",
      "country": "United States",
      "IATA": "HVS",
      "icao_code": "KHVS",
      "airport_name": "Hartsville Regional Airport"
    },
    {
      "city": "Hassi Messaoud",
      "country": "Algeria",
      "IATA": "HME",
      "icao_code": "DAUH",
      "airport_name": "Oued Irara–Krim Belkacem Airport"
    },
    {
      "city": "Hasvik",
      "country": "Norway",
      "IATA": "HAA",
      "icao_code": "ENHK",
      "airport_name": "Hasvik Airport"
    },
    {
      "city": "Hat Yai",
      "country": "Thailand",
      "IATA": "HDY",
      "icao_code": "VTSS",
      "airport_name": "Hat Yai International Airport"
    },
    {
      "city": "Hatay",
      "country": "Turkey",
      "IATA": "HTY",
      "icao_code": "LTDA",
      "airport_name": "Hatay Airport"
    },
    {
      "city": "Hattiesburg",
      "country": "United States",
      "IATA": "HBG",
      "icao_code": "KHBG",
      "airport_name": "Hattiesburg Bobby L. Chain Municipal Airpor"
    },
    {
      "city": "Hattiesburg/Laurel",
      "country": "United States",
      "IATA": "PIB",
      "icao_code": "KPIB",
      "airport_name": "Hattiesburg-Laurel Regional Airport"
    },
    {
      "city": "Haugesund",
      "country": "Norway",
      "IATA": "HAU",
      "icao_code": "ENHD",
      "airport_name": "Haugesund Airport, Karmøy"
    },
    {
      "city": "Havana",
      "country": "Cuba",
      "IATA": "HAV",
      "icao_code": "MUHA",
      "airport_name": "Jose Marti­ International Airport"
    },
    {
      "city": "Haverfordwest",
      "country": "United Kingdom",
      "IATA": "HAW",
      "icao_code": "EGFE",
      "airport_name": "Haverfordwest Airport"
    },
    {
      "city": "Havre",
      "country": "United States",
      "IATA": "HVR",
      "icao_code": "KHVR",
      "airport_name": "Havre City–County Airport"
    },
    {
      "city": "Havre-Saint-Pierre",
      "country": "Canada",
      "IATA": "YGV",
      "icao_code": "CYGV",
      "airport_name": "Havre St-Pierre Airport"
    },
    {
      "city": "Hawarden",
      "country": "United Kingdom",
      "IATA": "CEG",
      "icao_code": "EGNR",
      "airport_name": "Hawarden Airport "
    },
    {
      "city": "Hawthorne",
      "country": "United States",
      "IATA": "HHR",
      "airport_name": "Hawthorne Municipal Airport "
    },
    {
      "city": "Hay River",
      "country": "Canada",
      "IATA": "YHY",
      "icao_code": "CYHY",
      "airport_name": "Hay River/Merlyn Carter Airport"
    },
    {
      "city": "Hayden",
      "country": "United States",
      "IATA": "HDN",
      "icao_code": "KHDN",
      "airport_name": "Yampa Valley Airport"
    },
    {
      "city": "Hays",
      "country": "United States",
      "IATA": "HYS",
      "icao_code": "KHYS",
      "airport_name": "Hays Regional Airport"
    },
    {
      "city": "Hayward",
      "country": "United States",
      "IATA": "HYR",
      "airport_name": "Sawyer County Airport"
    },
    {
      "city": "Hayward",
      "country": "United States",
      "IATA": "HWD",
      "icao_code": "KHWD",
      "airport_name": "Hayward Executive Airport"
    },
    {
      "city": "Hazleton",
      "country": "United States",
      "IATA": "HZL",
      "icao_code": "KHZL",
      "airport_name": "Hazleton Municipal Airport"
    },
    {
      "city": "Hearst",
      "country": "Canada",
      "IATA": "YHF",
      "icao_code": "CYHF",
      "airport_name": "Hearst (René Fontaine) Municipal Airport"
    },
    {
      "city": "Hechi",
      "country": "China",
      "IATA": "HCJ",
      "icao_code": "ZGHC",
      "airport_name": "Hechi Jinchengjiang Airport"
    },
    {
      "city": "Hefei",
      "country": "China",
      "IATA": "HFE",
      "icao_code": "ZSOF",
      "airport_name": "Hefei Luogang International Airport"
    },
    {
      "city": "Heho",
      "country": "Burma",
      "IATA": "HEH",
      "icao_code": "VYHH",
      "airport_name": "Heho Airport"
    },
    {
      "city": "Heihe",
      "country": "China",
      "IATA": "HEK",
      "icao_code": "ZYHE",
      "airport_name": "Heihe Airport"
    },
    {
      "city": "Helena",
      "country": "United States",
      "IATA": "HLN",
      "icao_code": "KHLN",
      "airport_name": "Helena Regional Airport"
    },
    {
      "city": "Helgoland",
      "country": "Germany",
      "IATA": "HGL",
      "icao_code": "EDXH",
      "airport_name": "Heligoland Airport "
    },
    {
      "city": "Helsinki",
      "country": "Finland",
      "IATA": "HEL",
      "icao_code": "EFHK",
      "airport_name": "Helsinki-Vantaa Airport"
    },
    {
      "city": "Helsinki",
      "country": "Finland",
      "IATA": "HEM",
      "icao_code": "EFHF",
      "airport_name": "Helsinki-Malmi Airport"
    },
    {
      "city": "Hemavan",
      "country": "Sweden",
      "IATA": "HMV",
      "icao_code": "ESUT",
      "airport_name": "Hemavan Tärnaby Airport"
    },
    {
      "city": "Henderson",
      "country": "United States",
      "IATA": "HSH",
      "icao_code": "KHND",
      "airport_name": "Henderson Executive Airport "
    },
    {
      "city": "Hengchun",
      "country": "Taiwan",
      "IATA": "HCN",
      "icao_code": "RCKW",
      "airport_name": "Hengchun Airport"
    },
    {
      "city": "Hengyang",
      "country": "China",
      "IATA": "HNY",
      "icao_code": "ZGHY",
      "airport_name": "Hengyang Nanyue Airport"
    },
    {
      "city": "Heraklion",
      "country": "Greece",
      "IATA": "HER",
      "icao_code": "LGIR",
      "airport_name": "Heraklion International Airport, “Nikos Kazantzakis”"
    },
    {
      "city": "Herat",
      "country": "Afghanistan",
      "IATA": "HEA",
      "icao_code": "OAHR",
      "airport_name": "Herat International Airport"
    },
    {
      "city": "Heringsdorf",
      "country": "Germany",
      "IATA": "HDF",
      "icao_code": "EDAH",
      "airport_name": "Heringsdorf Airport"
    },
    {
      "city": "Hermannsburg",
      "country": "Australia",
      "IATA": "HMG",
      "icao_code": "YHMB",
      "airport_name": "Hermannsburg Airport"
    },
    {
      "city": "Hermosillo",
      "country": "Mexico",
      "IATA": "HMO",
      "icao_code": "MMHO",
      "airport_name": "General Ignacio Pesqueira Garcia International Airport"
    },
    {
      "city": "Hervey Bay",
      "country": "Australia",
      "IATA": "HVB",
      "icao_code": "YHBA",
      "airport_name": "Hervey Bay Airport"
    },
    {
      "city": "Hewandorra",
      "country": "Saint Lucia",
      "IATA": "UVF",
      "icao_code": "TLPL",
      "airport_name": "Hewanorra International Airport"
    },
    {
      "city": "Hibbing",
      "country": "United States",
      "IATA": "HIB",
      "icao_code": "KHIB",
      "airport_name": "Chisholm-Hibbing Municipal Airport"
    },
    {
      "city": "Hickory",
      "country": "United States",
      "IATA": "HKY",
      "icao_code": "KHKY",
      "airport_name": "Hickory Regional Airport"
    },
    {
      "city": "Hierro",
      "country": "Spain",
      "IATA": "VDE",
      "icao_code": "GCHI",
      "airport_name": "El Hierro Airport"
    },
    {
      "city": "High Level",
      "country": "Canada",
      "IATA": "YOJ",
      "icao_code": "CYOJ",
      "airport_name": "High Level Airport"
    },
    {
      "city": "Higuerote",
      "country": "Venezuela",
      "IATA": "HGE",
      "airport_name": "Higuerote Airport"
    },
    {
      "city": "Hikueru",
      "country": "French Polynesia",
      "IATA": "HHZ",
      "icao_code": "NTGH",
      "airport_name": "Hikueru Airport"
    },
    {
      "city": "Hillsboro",
      "country": "United States",
      "IATA": "HIO",
      "icao_code": "KHIO",
      "airport_name": "Hillsboro Airport"
    },
    {
      "city": "Hilo",
      "country": "United States",
      "IATA": "ITO",
      "icao_code": "PHTO",
      "airport_name": "Hilo International Airport"
    },
    {
      "city": "Hilton Head Island",
      "country": "United States",
      "IATA": "HHH",
      "icao_code": "KHXD",
      "airport_name": "Hilton Head Airport "
    },
    {
      "city": "Hinton",
      "country": "Canada",
      "IATA": "YJP",
      "airport_name": "Jasper-Hinton Airport"
    },
    {
      "city": "Hiroshima",
      "country": "Japan",
      "IATA": "HIW",
      "icao_code": "RJBH",
      "airport_name": "Hiroshima–Nishi Airport"
    },
    {
      "city": "Hiroshima",
      "country": "Japan",
      "IATA": "HIJ",
      "icao_code": "RJOA",
      "airport_name": "Hiroshima Airport"
    },
    {
      "city": "Hissar",
      "country": "India",
      "IATA": "HSS",
      "icao_code": "VIHR",
      "airport_name": "Hissar Airport"
    },
    {
      "city": "Hiva-oa",
      "country": "French Polynesia",
      "IATA": "AUQ",
      "icao_code": "NTMN",
      "airport_name": "Atuona Airport"
    },
    {
      "city": "Ho Chi Minh City",
      "country": "Vietnam",
      "IATA": "SGN",
      "icao_code": "VVTS",
      "airport_name": "Tân Sơn Nhất International Airport"
    },
    {
      "city": "Hobart",
      "country": "United States",
      "IATA": "HBR",
      "icao_code": "KHBR",
      "airport_name": "Hobart Regional Airport"
    },
    {
      "city": "Hobart",
      "country": "Australia",
      "IATA": "HBA",
      "icao_code": "YMHB",
      "airport_name": "Hobart International Airport"
    },
    {
      "city": "Hobbs",
      "country": "United States",
      "IATA": "HOB",
      "icao_code": "KHOB",
      "airport_name": "Lea County Regional Airport"
    },
    {
      "city": "Hodeidah",
      "country": "Yemen",
      "IATA": "HOD",
      "icao_code": "OYHD",
      "airport_name": "Hodeida International Airport"
    },
    {
      "city": "Hoedspruit",
      "country": "South Africa",
      "IATA": "HDS",
      "icao_code": "FAHS",
      "airport_name": "Air Force Base Hoedspruit "
    },
    {
      "city": "Hof",
      "country": "Germany",
      "IATA": "HOQ",
      "icao_code": "EDQM",
      "airport_name": "Hof–Plauen Airport"
    },
    {
      "city": "Hofn",
      "country": "Iceland",
      "IATA": "HFN",
      "icao_code": "BIHN",
      "airport_name": "Hornafjörður Airport"
    },
    {
      "city": "Hohenems",
      "country": "Austria",
      "IATA": "HOH",
      "icao_code": "LOIH",
      "airport_name": "Hohenems-Dornbirn Airport"
    },
    {
      "city": "Hohhot",
      "country": "China",
      "IATA": "HET",
      "icao_code": "ZBHH",
      "airport_name": "Hohhot Baita International Airport"
    },
    {
      "city": "Hokitika",
      "country": "New Zealand",
      "IATA": "HKK",
      "icao_code": "NZHK",
      "airport_name": "Hokitika Airport"
    },
    {
      "city": "Hola",
      "country": "Kenya",
      "IATA": "HOA",
      "icao_code": "HKHO",
      "airport_name": "Hola Airport"
    },
    {
      "city": "Holdredge",
      "country": "United States",
      "IATA": "HDE",
      "icao_code": "KHDE",
      "airport_name": "Brewster Field"
    },
    {
      "city": "Holguin",
      "country": "Cuba",
      "IATA": "HOG",
      "icao_code": "MUHG",
      "airport_name": "Frank País Airport"
    },
    {
      "city": "Holingol",
      "country": "China",
      "IATA": "HUO",
      "icao_code": "ZBHZ",
      "airport_name": "Holingol Huolinhe Airport"
    },
    {
      "city": "Hollister",
      "country": "United States",
      "IATA": "HLI",
      "icao_code": "KCVH",
      "airport_name": "Hollister Municipal Airport "
    },
    {
      "city": "Hollywood",
      "country": "United States",
      "IATA": "HWO",
      "icao_code": "KHWO",
      "airport_name": "North Perry Airport"
    },
    {
      "city": "Holman Island",
      "country": "Canada",
      "IATA": "YHI",
      "icao_code": "CYHI",
      "airport_name": "Ulukhaktok/Holman Airport"
    },
    {
      "city": "Holy Cross",
      "country": "United States",
      "IATA": "HCR",
      "icao_code": "PAHC",
      "airport_name": "Holy Cross Airport "
    },
    {
      "city": "Homer",
      "country": "United States",
      "IATA": "HOM",
      "icao_code": "PAHO",
      "airport_name": "Homer Airport"
    },
    {
      "city": "Homestead",
      "country": "United States",
      "IATA": "HST",
      "airport_name": "Homestead Air Reserve Base"
    },
    {
      "city": "Hommalin",
      "country": "Burma",
      "IATA": "HOX",
      "airport_name": "Hommalinn Airport"
    },
    {
      "city": "Hon",
      "country": "Libya",
      "IATA": "HUQ",
      "icao_code": "HLON",
      "airport_name": "Houn Airport"
    },
    {
      "city": "Hong Kong",
      "country": "Hong Kong",
      "IATA": "HHP",
      "airport_name": "Shun Tak Heliport"
    },
    {
      "city": "Hong Kong",
      "country": "Hong Kong",
      "IATA": "HKG",
      "icao_code": "VHHH",
      "airport_name": "Hong Kong International Airport"
    },
    {
      "city": "Honiara",
      "country": "Solomon Islands",
      "IATA": "HIR",
      "icao_code": "AGGH",
      "airport_name": "Honiara International Airport"
    },
    {
      "city": "Honington",
      "country": "United Kingdom",
      "IATA": "BEQ",
      "airport_name": "RAF Honington"
    },
    {
      "city": "Honningsvag",
      "country": "Norway",
      "IATA": "HVG",
      "icao_code": "ENHV",
      "airport_name": "Honningsvåg Airport, Valan"
    },
    {
      "city": "Honolulu",
      "country": "United States",
      "IATA": "HNL",
      "icao_code": "PHNL",
      "airport_name": "Honolulu International Airport"
    },
    {
      "city": "Honuu",
      "country": "Russia",
      "IATA": "MQJ",
      "airport_name": "Indianapolis Regional Airport"
    },
    {
      "city": "Hooker Creek",
      "country": "Australia",
      "IATA": "HOK",
      "icao_code": "YHOO",
      "airport_name": "Hooker Creek Airport"
    },
    {
      "city": "Hoonah",
      "country": "United States",
      "IATA": "HNH",
      "icao_code": "PAOH",
      "airport_name": "Hoonah Airport"
    },
    {
      "city": "Hooper Bay",
      "country": "United States",
      "IATA": "HPB",
      "icao_code": "PAHP",
      "airport_name": "Hooper Bay Airport"
    },
    {
      "city": "Hope",
      "country": "Canada",
      "IATA": "YHE",
      "airport_name": "Hope Aerodrome"
    },
    {
      "city": "Hopedale",
      "country": "Canada",
      "IATA": "YHO",
      "icao_code": "CYHO",
      "airport_name": "Hopedale Airport"
    },
    {
      "city": "Hopkinsville",
      "country": "United States",
      "IATA": "HOP",
      "icao_code": "KHOP",
      "airport_name": "Campbell Army Airfield"
    },
    {
      "city": "Hoquiam",
      "country": "United States",
      "IATA": "HQM",
      "airport_name": "Bowerman Airport"
    },
    {
      "city": "Horn Island",
      "country": "Australia",
      "IATA": "HID",
      "icao_code": "YHID",
      "airport_name": "Horn Island Airport"
    },
    {
      "city": "Hornepayne",
      "country": "Canada",
      "IATA": "YHN",
      "icao_code": "CYHN",
      "airport_name": "Hornepayne Municipal Airport"
    },
    {
      "city": "Horsham",
      "country": "Australia",
      "IATA": "HSM",
      "icao_code": "YHSM",
      "airport_name": "Horsham Airport"
    },
    {
      "city": "Horta",
      "country": "Portugal",
      "IATA": "HOR",
      "icao_code": "LPHR",
      "airport_name": "Horta International Airport"
    },
    {
      "city": "Hoskins",
      "country": "Papua New Guinea",
      "IATA": "HKN",
      "icao_code": "AYHK",
      "airport_name": "Kimbe Airport"
    },
    {
      "city": "Hot Springs",
      "country": "United States",
      "IATA": "HOT",
      "icao_code": "KHOT",
      "airport_name": "Memorial Field Airport"
    },
    {
      "city": "Hotan",
      "country": "China",
      "IATA": "HTN",
      "icao_code": "ZWTN",
      "airport_name": "Hotan Airport"
    },
    {
      "city": "Houghton Lake",
      "country": "United States",
      "IATA": "HTL",
      "icao_code": "KHTL",
      "airport_name": "Roscommon County–Blodgett Memorial Airport "
    },
    {
      "city": "Houlton",
      "country": "United States",
      "IATA": "HUL",
      "icao_code": "KHUL",
      "airport_name": "Houlton International Airport"
    },
    {
      "city": "Houston",
      "country": "United States",
      "IATA": "AAP",
      "icao_code": "WALS",
      "airport_name": "Aappilattoq Heliport "
    },
    {
      "city": "Houston",
      "country": "United States",
      "IATA": "IWS",
      "icao_code": "KIWS",
      "airport_name": "West Houston Airport"
    },
    {
      "city": "Houston",
      "country": "United States",
      "IATA": "DWH",
      "icao_code": "KDWH",
      "airport_name": "David Wayne Hooks Memorial Airport"
    },
    {
      "city": "Houston",
      "country": "United States",
      "IATA": "EFD",
      "icao_code": "KEFD",
      "airport_name": "Ellington Field Joint Reserve Base"
    },
    {
      "city": "Houston",
      "country": "United States",
      "IATA": "HOU",
      "icao_code": "KHOU",
      "airport_name": "William P. Hobby Airport"
    },
    {
      "city": "Houston",
      "country": "United States",
      "IATA": "IAH",
      "icao_code": "KIAH",
      "airport_name": "George Bush Intercontinental Airport"
    },
    {
      "city": "Howard",
      "country": "Panama",
      "IATA": "BLB",
      "airport_name": "Howard Airport "
    },
    {
      "city": "Hpa-an",
      "country": "Burma",
      "IATA": "PAA",
      "airport_name": "Hpa-An Airport"
    },
    {
      "city": "Hrodna",
      "country": "Belarus",
      "IATA": "GNA",
      "icao_code": "UMMG",
      "airport_name": "Hrodna Airport"
    },
    {
      "city": "Hsinchu",
      "country": "Taiwan",
      "IATA": "HSZ",
      "airport_name": "Hsinchu Air Base"
    },
    {
      "city": "Huahine Island",
      "country": "French Polynesia",
      "IATA": "HUH",
      "icao_code": "NTTH",
      "airport_name": "Huahine – Fare  Airport"
    },
    {
      "city": "Huai An",
      "country": "China",
      "IATA": "HIA",
      "icao_code": "ZSSH",
      "airport_name": "Huai'an Lianshui Airport "
    },
    {
      "city": "Hualien",
      "country": "Taiwan",
      "IATA": "HUN",
      "icao_code": "RCYU",
      "airport_name": "Hualien Airport"
    },
    {
      "city": "Huambo",
      "country": "Angola",
      "IATA": "NOV",
      "icao_code": "FNHU",
      "airport_name": "Nova Lisboa Airport"
    },
    {
      "city": "Huangshan",
      "country": "China",
      "IATA": "TXN",
      "icao_code": "ZSTX",
      "airport_name": "Huangshan Tunxi International Airport"
    },
    {
      "city": "Huangyan",
      "country": "China",
      "IATA": "HYN",
      "icao_code": "ZSLQ",
      "airport_name": "Taizhou Luqiao Airport"
    },
    {
      "city": "Huatulco",
      "country": "Mexico",
      "IATA": "HUX",
      "icao_code": "MMBT",
      "airport_name": "Bahías de Huatulco International Airport"
    },
    {
      "city": "Huay Xai",
      "country": "Laos",
      "IATA": "HOE",
      "airport_name": "Houeisay Airport"
    },
    {
      "city": "Hubli",
      "country": "India",
      "IATA": "HBX",
      "icao_code": "VAHB",
      "airport_name": "Hubballi Airport"
    },
    {
      "city": "Hudiksvall",
      "country": "Sweden",
      "IATA": "HUV",
      "airport_name": "Hudiksvall Airport"
    },
    {
      "city": "Hudson Bay",
      "country": "Canada",
      "IATA": "YHB",
      "icao_code": "CYHB",
      "airport_name": "Hudson Bay Airport"
    },
    {
      "city": "Hue",
      "country": "Vietnam",
      "IATA": "HUI",
      "icao_code": "VVPB",
      "airport_name": "Phu Bai International Airport"
    },
    {
      "city": "Huesca",
      "country": "Spain",
      "IATA": "HSK",
      "icao_code": "LEHC",
      "airport_name": "Huesca-Pirineos Airport"
    },
    {
      "city": "Hughenden",
      "country": "Australia",
      "IATA": "HGD",
      "icao_code": "YHUG",
      "airport_name": "Hughenden Airport"
    },
    {
      "city": "Hughes",
      "country": "United States",
      "IATA": "HUS",
      "icao_code": "PAHU",
      "airport_name": "Hughes Airport"
    },
    {
      "city": "Huizhou",
      "country": "China",
      "IATA": "HUZ",
      "airport_name": "Huizhou Pingtan Airport"
    },
    {
      "city": "Hultsfred",
      "country": "Sweden",
      "IATA": "HLF",
      "icao_code": "ESSF",
      "airport_name": "Hultsfred Airport"
    },
    {
      "city": "Humaita",
      "country": "Brazil",
      "IATA": "HUW",
      "icao_code": "SWHT",
      "airport_name": "Humaitá Airport"
    },
    {
      "city": "Humberside",
      "country": "United Kingdom",
      "IATA": "HUY",
      "icao_code": "EGNJ",
      "airport_name": "Humberside Airport"
    },
    {
      "city": "Humera",
      "country": "Ethiopia",
      "IATA": "HUE",
      "icao_code": "HAHU",
      "airport_name": "Humera Airport"
    },
    {
      "city": "Hunter Aaf",
      "country": "United States",
      "IATA": "SVN",
      "icao_code": "KSVN",
      "airport_name": "Hunter Army Airfield"
    },
    {
      "city": "Huntington",
      "country": "United States",
      "IATA": "HTS",
      "icao_code": "KHTS",
      "airport_name": "Tri-State Airport "
    },
    {
      "city": "Huntsville",
      "country": "United States",
      "IATA": "HTV",
      "airport_name": "Huntsville Regional Airport"
    },
    {
      "city": "Huntsville",
      "country": "United States",
      "IATA": "HSV",
      "icao_code": "KHSV",
      "airport_name": "Huntsville International Airport "
    },
    {
      "city": "Hurghada",
      "country": "Egypt",
      "IATA": "HRG",
      "icao_code": "HEGN",
      "airport_name": "Hurghada International Airport"
    },
    {
      "city": "Huron",
      "country": "United States",
      "IATA": "HON",
      "icao_code": "KHON",
      "airport_name": "Huron Regional Airport"
    },
    {
      "city": "Husavik",
      "country": "Iceland",
      "IATA": "HZK",
      "icao_code": "BIHU",
      "airport_name": "Húsavík Airport"
    },
    {
      "city": "Huslia",
      "country": "United States",
      "IATA": "HSL",
      "icao_code": "PAHS",
      "airport_name": "Huslia Airport "
    },
    {
      "city": "Hutchinson",
      "country": "United States",
      "IATA": "HUT",
      "icao_code": "KHUT",
      "airport_name": "Hutchinson Municipal Airport"
    },
    {
      "city": "Huánuco",
      "country": "Peru",
      "IATA": "HUU",
      "icao_code": "SPNC",
      "airport_name": "Alférez FAP David Figueroa Fernandini Airport"
    },
    {
      "city": "Hwange",
      "country": "Zimbabwe",
      "IATA": "WKI",
      "icao_code": "FVWT",
      "airport_name": "Hwange National Park Airport"
    },
    {
      "city": "Hwange National Park",
      "country": "Zimbabwe",
      "IATA": "HWN",
      "icao_code": "FVWN",
      "airport_name": "Hwange National Park Airport"
    },
    {
      "city": "Hydaburg",
      "country": "United States",
      "IATA": "HYG",
      "icao_code": "PAHY",
      "airport_name": "Hydaburg Seaplane Base"
    },
    {
      "city": "Hyderabad",
      "country": "India",
      "IATA": "HYD",
      "icao_code": "VOHS",
      "airport_name": "Rajiv Gandhi International Airport"
    },
    {
      "city": "Hyderabad",
      "country": "Pakistan",
      "IATA": "HDD",
      "icao_code": "OPKD",
      "airport_name": "Hyderabad Airport"
    },
    {
      "city": "Hyderabad",
      "country": "India",
      "IATA": "BPM",
      "icao_code": "VOHY",
      "airport_name": "Bagram AFB Airport"
    },
    {
      "city": "Hyeres",
      "country": "France",
      "IATA": "TLN",
      "icao_code": "LFTH",
      "airport_name": "Toulon–Hyères Airport"
    },
    {
      "city": "Hyvinkaa",
      "country": "Finland",
      "IATA": "HYV",
      "icao_code": "EFHV",
      "airport_name": "Hyvinkää Airfield"
    },
    {
      "city": "Iasi",
      "country": "Romania",
      "IATA": "IAS",
      "icao_code": "LRIA",
      "airport_name": "Iasi International Airport"
    },
    {
      "city": "Ibadan",
      "country": "Nigeria",
      "IATA": "IBA",
      "icao_code": "DNIB",
      "airport_name": "Ibadan Airport"
    },
    {
      "city": "Ibague",
      "country": "Colombia",
      "IATA": "IBE",
      "icao_code": "SKIB",
      "airport_name": "Perales Airport"
    },
    {
      "city": "Ibaraki",
      "country": "Japan",
      "IATA": "IBR",
      "icao_code": "RJAH",
      "airport_name": "Ibaraki Airport"
    },
    {
      "city": "Iberia",
      "country": "Peru",
      "IATA": "IBP",
      "icao_code": "SPBR",
      "airport_name": "Iberia Airport"
    },
    {
      "city": "Ibiza",
      "country": "Spain",
      "IATA": "IBZ",
      "icao_code": "LEIB",
      "airport_name": "Ibiza Airport"
    },
    {
      "city": "Idaho Falls",
      "country": "United States",
      "IATA": "IDA",
      "icao_code": "KIDA",
      "airport_name": "Idaho Falls Regional Airport"
    },
    {
      "city": "Ie",
      "country": "Japan",
      "IATA": "IEJ",
      "icao_code": "RORE",
      "airport_name": "Iejima Airport"
    },
    {
      "city": "Ifuru",
      "country": "Maldives",
      "IATA": "IFU",
      "icao_code": "VREI",
      "airport_name": "Ifuru Airport"
    },
    {
      "city": "Igarka",
      "country": "Russia",
      "IATA": "IAA",
      "icao_code": "UOII",
      "airport_name": "Igarka Airport"
    },
    {
      "city": "Igdir",
      "country": "Turkey",
      "IATA": "IGD",
      "airport_name": "Iğdır Airport"
    },
    {
      "city": "Igiugig",
      "country": "United States",
      "IATA": "IGG",
      "icao_code": "PAIG",
      "airport_name": "Igiugig Airport"
    },
    {
      "city": "Igloolik",
      "country": "Canada",
      "IATA": "YGT",
      "icao_code": "CYGT",
      "airport_name": "Igloolik Airport"
    },
    {
      "city": "Ignace",
      "country": "Canada",
      "IATA": "ZUC",
      "icao_code": "CZUC",
      "airport_name": "Ignace Municipal Airport"
    },
    {
      "city": "Iguatu",
      "country": "Brazil",
      "IATA": "QIG",
      "airport_name": "Iguatu Airport"
    },
    {
      "city": "Iguazu Falls",
      "country": "Argentina",
      "IATA": "IGR",
      "icao_code": "SARI",
      "airport_name": "Cataratas Del Iguazu International Airport"
    },
    {
      "city": "Ikamiut",
      "country": "Greenland",
      "IATA": "QJI",
      "airport_name": "Ikamiut Heliport"
    },
    {
      "city": "Ikaria",
      "country": "Greece",
      "IATA": "JIK",
      "icao_code": "LGIK",
      "airport_name": "Ikaria Island National Airport"
    },
    {
      "city": "Ikela",
      "country": "Congo (Kinshasa)",
      "IATA": "IKL",
      "icao_code": "FZGV",
      "airport_name": "Ikela Airport"
    },
    {
      "city": "Ikerasaarsuk",
      "country": "Greenland",
      "IATA": "QRY",
      "airport_name": "Ikerasaarsuk Heliport"
    },
    {
      "city": "Iki",
      "country": "Japan",
      "IATA": "IKI",
      "icao_code": "RJDB",
      "airport_name": "Iki Airport"
    },
    {
      "city": "Ilam",
      "country": "Iran",
      "IATA": "IIL",
      "icao_code": "OICI",
      "airport_name": "Ilam Airport"
    },
    {
      "city": "Ilebo",
      "country": "Congo (Kinshasa)",
      "IATA": "PFR",
      "icao_code": "FZVS",
      "airport_name": "Ilebo Airport"
    },
    {
      "city": "Iles De La Madeleine",
      "country": "Canada",
      "IATA": "YGR",
      "icao_code": "CYGR",
      "airport_name": "Îles-de-la-Madeleine Airport"
    },
    {
      "city": "Ilford",
      "country": "Canada",
      "IATA": "ILF",
      "icao_code": "CZBD",
      "airport_name": "Ilford Airport"
    },
    {
      "city": "Ilheus",
      "country": "Brazil",
      "IATA": "IOS",
      "icao_code": "SBIL",
      "airport_name": "Ilhéus Jorge Amado Airport"
    },
    {
      "city": "Iliamna",
      "country": "United States",
      "IATA": "ILI",
      "icao_code": "PAIL",
      "airport_name": "Iliamna Airport"
    },
    {
      "city": "Ilimanaq",
      "country": "Greenland",
      "IATA": "XIQ",
      "airport_name": "Ilimanaq Heliport"
    },
    {
      "city": "Illaga",
      "country": "Indonesia",
      "IATA": "ILA",
      "icao_code": "WABL",
      "airport_name": "Illaga Airport"
    },
    {
      "city": "Illizi",
      "country": "Algeria",
      "IATA": "VVZ",
      "icao_code": "DAAP",
      "airport_name": "Illizi Takhamalt Airport"
    },
    {
      "city": "Ilo",
      "country": "Peru",
      "IATA": "ILQ",
      "airport_name": "Ilo Airport"
    },
    {
      "city": "Iloilo",
      "country": "Philippines",
      "IATA": "ILO",
      "icao_code": "RPVI",
      "airport_name": "Iloilo International Airport"
    },
    {
      "city": "Ilorin",
      "country": "Nigeria",
      "IATA": "ILR",
      "icao_code": "DNIL",
      "airport_name": "Ilorin International Airport"
    },
    {
      "city": "Ilulissat",
      "country": "Greenland",
      "IATA": "JAV",
      "icao_code": "BGJN",
      "airport_name": "Ilulissat Airport"
    },
    {
      "city": "Imbaimadai",
      "country": "Guyana",
      "IATA": "IMB",
      "icao_code": "SYIB",
      "airport_name": "Imbaimadai Airport"
    },
    {
      "city": "Immokalee",
      "country": "United States",
      "IATA": "IMM",
      "icao_code": "KIMM",
      "airport_name": "Immokalee Regional Airport"
    },
    {
      "city": "Imo",
      "country": "Nigeria",
      "IATA": "QOW",
      "icao_code": "DNIM",
      "airport_name": "Sam Mbakwe International Cargo Airport"
    },
    {
      "city": "Imperatriz",
      "country": "Brazil",
      "IATA": "IMP",
      "icao_code": "SBIZ",
      "airport_name": "Imperatriz Airport"
    },
    {
      "city": "Imperial",
      "country": "United States",
      "IATA": "IPL",
      "icao_code": "KIPL",
      "airport_name": "Imperial County Airport"
    },
    {
      "city": "Impfondo",
      "country": "Congo (Brazzaville)",
      "IATA": "ION",
      "airport_name": "Impfondo Airport"
    },
    {
      "city": "Imphal",
      "country": "India",
      "IATA": "IMF",
      "icao_code": "VEIM",
      "airport_name": "Bir Tikendrajit International Airport"
    },
    {
      "city": "In Salah",
      "country": "Algeria",
      "IATA": "INZ",
      "icao_code": "DAUI",
      "airport_name": "In Salah Airport"
    },
    {
      "city": "Independence",
      "country": "United States",
      "IATA": "IDP",
      "icao_code": "KIDP",
      "airport_name": "Independence Municipal Airport"
    },
    {
      "city": "Indian Mountains",
      "country": "United States",
      "IATA": "UTO",
      "icao_code": "PAIM",
      "airport_name": "Indian Mountain LRRS Airport"
    },
    {
      "city": "Indian Springs",
      "country": "United States",
      "IATA": "INS",
      "airport_name": "Creech Air Force Base"
    },
    {
      "city": "Indianapolis",
      "country": "United States",
      "IATA": "IND",
      "icao_code": "KIND",
      "airport_name": "Indianapolis International Airport"
    },
    {
      "city": "Indore",
      "country": "India",
      "IATA": "IDR",
      "icao_code": "VAID",
      "airport_name": "Devi Ahilyabai Holkar Airport"
    },
    {
      "city": "Indreabhan",
      "country": "Ireland",
      "IATA": "NNR",
      "icao_code": "EICA",
      "airport_name": "Connemara  Airport"
    },
    {
      "city": "Ingeniero Jacobacci",
      "country": "Argentina",
      "IATA": "IGB",
      "icao_code": "SAVJ",
      "airport_name": "Capitan FAA H. R. Borden Airport"
    },
    {
      "city": "Ingolstadt",
      "country": "Germany",
      "IATA": "IGS",
      "icao_code": "INGS",
      "airport_name": "Ingolstadt Manching Airport"
    },
    {
      "city": "Inhaca",
      "country": "Mozambique",
      "IATA": "IHC",
      "icao_code": "FQIA",
      "airport_name": "Inhaca Airport"
    },
    {
      "city": "Inhambane",
      "country": "Mozambique",
      "IATA": "INH",
      "icao_code": "FQIN",
      "airport_name": "Inhambane Airport"
    },
    {
      "city": "Inis Mor",
      "country": "Ireland",
      "IATA": "IOR",
      "icao_code": "EIIM",
      "airport_name": "Inishmore Aerodrome "
    },
    {
      "city": "Inisheer",
      "country": "Ireland",
      "IATA": "INQ",
      "icao_code": "EIIR",
      "airport_name": "Inisheer Airport"
    },
    {
      "city": "Inishmaan",
      "country": "Ireland",
      "IATA": "IIA",
      "airport_name": "Inishmaan Aerodrome"
    },
    {
      "city": "Innisfail",
      "country": "Australia",
      "IATA": "IFL",
      "icao_code": "YIFL",
      "airport_name": "Innisfail Airport"
    },
    {
      "city": "Innsbruck",
      "country": "Austria",
      "IATA": "INN",
      "icao_code": "LOWI",
      "airport_name": "Innsbruck Airport "
    },
    {
      "city": "Inongo",
      "country": "Congo (Kinshasa)",
      "IATA": "INO",
      "icao_code": "FZBA",
      "airport_name": "Inongo Airport"
    },
    {
      "city": "Inta",
      "country": "Russia",
      "IATA": "INA",
      "icao_code": "UUYI",
      "airport_name": "Inta Airport"
    },
    {
      "city": "Interlaken",
      "country": "Switzerland",
      "IATA": "ZIN",
      "airport_name": "Interlaken Airport"
    },
    {
      "city": "International Falls",
      "country": "United States",
      "IATA": "INL",
      "icao_code": "KINL",
      "airport_name": "Falls International Airport"
    },
    {
      "city": "Inukjuak",
      "country": "Canada",
      "IATA": "YPH",
      "icao_code": "CYPH",
      "airport_name": "Inukjuak Airport"
    },
    {
      "city": "Inuvik",
      "country": "Canada",
      "IATA": "YEV",
      "icao_code": "CYEV",
      "airport_name": "Inuvik (Mike Zubko) Airport"
    },
    {
      "city": "Invercargill",
      "country": "New Zealand",
      "IATA": "IVC",
      "icao_code": "NZNV",
      "airport_name": "Invercargill Airport"
    },
    {
      "city": "Inverell",
      "country": "Australia",
      "IATA": "IVR",
      "icao_code": "YIVL",
      "airport_name": "Inverell Airport"
    },
    {
      "city": "Inverness",
      "country": "United Kingdom",
      "IATA": "INV",
      "icao_code": "EGPE",
      "airport_name": "Inverness Airport"
    },
    {
      "city": "Inyokern",
      "country": "United States",
      "IATA": "IYK",
      "icao_code": "KIYK",
      "airport_name": "Inyokern Airport"
    },
    {
      "city": "Ioannina",
      "country": "Greece",
      "IATA": "IOA",
      "icao_code": "LGIO",
      "airport_name": "Ioannina National Airport"
    },
    {
      "city": "Iowa City",
      "country": "United States",
      "IATA": "IOW",
      "icao_code": "KIOW",
      "airport_name": "Iowa City Municipal Airport"
    },
    {
      "city": "Ipatinga",
      "country": "Brazil",
      "IATA": "IPN",
      "icao_code": "SBIP",
      "airport_name": "Usiminas Airport"
    },
    {
      "city": "Ipiales",
      "country": "Colombia",
      "IATA": "IPI",
      "icao_code": "SKIP",
      "airport_name": "San Luis Airport"
    },
    {
      "city": "Ipoh",
      "country": "Malaysia",
      "IATA": "IPH",
      "icao_code": "WMKI",
      "airport_name": "Sultan Azlan Shah Airport"
    },
    {
      "city": "Ipota",
      "country": "Vanuatu",
      "IATA": "IPA",
      "icao_code": "NVVI",
      "airport_name": "Ipota Airport"
    },
    {
      "city": "Iqaluit",
      "country": "Canada",
      "IATA": "YFB",
      "icao_code": "CYFB",
      "airport_name": "Iqaluit Airport"
    },
    {
      "city": "Iquique",
      "country": "Chile",
      "IATA": "IQQ",
      "icao_code": "SCDA",
      "airport_name": "Diego Aracena International Airport"
    },
    {
      "city": "Iquitos",
      "country": "Peru",
      "IATA": "IQT",
      "icao_code": "SPQT",
      "airport_name": "Coronel FAP Francisco Secada Vignetta International Airport"
    },
    {
      "city": "Iraan",
      "country": "United States",
      "IATA": "IRB",
      "airport_name": "Iraan Municipal Airport "
    },
    {
      "city": "Iran Shahr",
      "country": "Iran",
      "IATA": "IHR",
      "icao_code": "OIZI",
      "airport_name": "Iranshahr Airport"
    },
    {
      "city": "Iringa",
      "country": "Tanzania",
      "IATA": "IRI",
      "icao_code": "HTIR",
      "airport_name": "Iringa Airport"
    },
    {
      "city": "Irkutsk",
      "country": "Russia",
      "IATA": "IKT",
      "icao_code": "UIII",
      "airport_name": "Irkutsk International Airport"
    },
    {
      "city": "Iron Mountain",
      "country": "United States",
      "IATA": "IMT",
      "icao_code": "KIMT",
      "airport_name": "Ford Airport"
    },
    {
      "city": "Isabela",
      "country": "Ecuador",
      "IATA": "IBB",
      "airport_name": "General Villamil Airport"
    },
    {
      "city": "Isafjordur",
      "country": "Iceland",
      "IATA": "IFJ",
      "icao_code": "BIIS",
      "airport_name": "Ísafjörður Airport"
    },
    {
      "city": "Ishigaki",
      "country": "Japan",
      "IATA": "ISG",
      "icao_code": "ROIG",
      "airport_name": "Ishigaki Airport"
    },
    {
      "city": "Ishurdi",
      "country": "Bangladesh",
      "IATA": "IRD",
      "icao_code": "VGIS",
      "airport_name": "Ishwardi Airport"
    },
    {
      "city": "Isiro",
      "country": "Congo (Kinshasa)",
      "IATA": "IRP",
      "icao_code": "FZJH",
      "airport_name": "Matari Airport"
    },
    {
      "city": "Isla De Coche",
      "country": "Venezuela",
      "IATA": "ICC",
      "airport_name": "Andres Miguel Salazar Marcano Airport"
    },
    {
      "city": "Isla Mujeres",
      "country": "Mexico",
      "IATA": "ISJ",
      "icao_code": "MMIM",
      "airport_name": "Isla Mujeres Airport"
    },
    {
      "city": "Isla Rey Jorge",
      "country": "Antarctica",
      "IATA": "TNM",
      "icao_code": "SCRM",
      "airport_name": "Teniente R. Marsh Airport"
    },
    {
      "city": "Islamabad",
      "country": "Pakistan",
      "IATA": "ISB",
      "icao_code": "OPRN",
      "airport_name": "Benazir Bhutto International Airport "
    },
    {
      "city": "Island Lake",
      "country": "Canada",
      "IATA": "YIV",
      "airport_name": "Island Lake Airport"
    },
    {
      "city": "Islay",
      "country": "United Kingdom",
      "IATA": "ILY",
      "icao_code": "EGPI",
      "airport_name": "Islay Airport"
    },
    {
      "city": "Isle Of Man",
      "country": "Isle of Man",
      "IATA": "IOM",
      "icao_code": "EGNS",
      "airport_name": "Isle of Man Airport"
    },
    {
      "city": "Isparta",
      "country": "Turkey",
      "IATA": "ISE",
      "icao_code": "LTFC",
      "airport_name": "Isparta Süleyman Demirel Airport"
    },
    {
      "city": "Istanbul",
      "country": "Turkey",
      "IATA": "IST",
      "icao_code": "LTBA",
      "airport_name": "Istanbul Airport"
    },
    {
      "city": "Istanbul",
      "country": "Turkey",
      "IATA": "SAW",
      "icao_code": "LTFJ",
      "airport_name": "Sabiha Gökçen International Airport"
    },
    {
      "city": "Istanbul",
      "country": "Turkey",
      "IATA": "ISL",
      "airport_name": "Istanbul Atatürk International Airport "
    },
    {
      "city": "Itaituba",
      "country": "Brazil",
      "IATA": "ITB",
      "icao_code": "SBIH",
      "airport_name": "Itaituba Airport"
    },
    {
      "city": "Itaituba",
      "country": "Brazil",
      "IATA": "ITA",
      "icao_code": "SBIC",
      "airport_name": "Itacoiatiara Airport"
    },
    {
      "city": "Itaperuna",
      "country": "Brazil",
      "IATA": "ITP",
      "airport_name": "Itaperuna Airport"
    },
    {
      "city": "Ithaca",
      "country": "United States",
      "IATA": "ITH",
      "icao_code": "KITH",
      "airport_name": "Ithaca Tompkins Regional Airport"
    },
    {
      "city": "Ittoqqortoormiit",
      "country": "Greenland",
      "IATA": "OBY",
      "icao_code": "BGSC",
      "airport_name": "Ittoqqortoormiit Heliport"
    },
    {
      "city": "Itumbiara",
      "country": "Brazil",
      "IATA": "ITR",
      "icao_code": "SBIT",
      "airport_name": "Itumbiara Airport"
    },
    {
      "city": "Ivalo",
      "country": "Finland",
      "IATA": "IVL",
      "icao_code": "EFIV",
      "airport_name": "Ivalo Airport"
    },
    {
      "city": "Ivano-Frankivsk",
      "country": "Ukraine",
      "IATA": "IFO",
      "icao_code": "UKLI",
      "airport_name": "Ivano-Frankivsk International Airport"
    },
    {
      "city": "Ivanovo",
      "country": "Russia",
      "IATA": "IWA",
      "icao_code": "UUBI",
      "airport_name": "Ivanovo Yuzhny Airport"
    },
    {
      "city": "Ivujivik",
      "country": "Canada",
      "IATA": "YIK",
      "icao_code": "CYIK",
      "airport_name": "Ivujivik Airport"
    },
    {
      "city": "Iwakuni",
      "country": "Japan",
      "IATA": "IWK",
      "airport_name": "Marine Corps Air Station Iwakuni"
    },
    {
      "city": "Iwami",
      "country": "Japan",
      "IATA": "IWJ",
      "icao_code": "RJOW",
      "airport_name": "Iwami Airport "
    },
    {
      "city": "Iwojima",
      "country": "Japan",
      "IATA": "IWO",
      "icao_code": "RJAW",
      "airport_name": "Central Field (Iwo Jima)"
    },
    {
      "city": "Izhevsk",
      "country": "Russia",
      "IATA": "IJK",
      "icao_code": "USII",
      "airport_name": "Izhevsk Airport"
    },
    {
      "city": "Izmir",
      "country": "Turkey",
      "IATA": "IGL",
      "icao_code": "LTBL",
      "airport_name": "Çiğli Air Base"
    },
    {
      "city": "Izmir",
      "country": "Turkey",
      "IATA": "ADB",
      "icao_code": "LTBJ",
      "airport_name": "Adnan Menderes Airport"
    },
    {
      "city": "Iztepec",
      "country": "Mexico",
      "IATA": "IZT",
      "airport_name": "Ixtepec Airport"
    },
    {
      "city": "Izumo",
      "country": "Japan",
      "IATA": "IZO",
      "icao_code": "RJOC",
      "airport_name": "Izumo Airport"
    },
    {
      "city": "Jabalpur",
      "country": "India",
      "IATA": "JLR",
      "icao_code": "VAJB",
      "airport_name": "Jabalpur Airport"
    },
    {
      "city": "Jabiru",
      "country": "Australia",
      "IATA": "JAB",
      "icao_code": "YJAB",
      "airport_name": "Jabiru Airport"
    },
    {
      "city": "Jacare-acanga",
      "country": "Brazil",
      "IATA": "JCR",
      "icao_code": "SBEK",
      "airport_name": "Jacareacanga Airport"
    },
    {
      "city": "Jackson Hole",
      "country": "United States",
      "IATA": "JAC",
      "icao_code": "KJAC",
      "airport_name": "Jackson Hole Airport"
    },
    {
      "city": "Jackson",
      "country": "United States",
      "IATA": "JXN",
      "icao_code": "KJXN",
      "airport_name": "Jackson County Airport "
    },
    {
      "city": "Jackson",
      "country": "United States",
      "IATA": "MKL",
      "icao_code": "KMKL",
      "airport_name": "McKellar-Sipes Regional Airport"
    },
    {
      "city": "Jackson",
      "country": "United States",
      "IATA": "JAN",
      "icao_code": "KJAN",
      "airport_name": "Jackson–Medgar Wiley Evers International Airport "
    },
    {
      "city": "Jacksonville",
      "country": "United States",
      "IATA": "CRG",
      "icao_code": "KCRG",
      "airport_name": "Jacksonville Executive at Craig Airport"
    },
    {
      "city": "Jacksonville",
      "country": "United States",
      "IATA": "VQQ",
      "airport_name": "Cecil Airport"
    },
    {
      "city": "Jacksonville",
      "country": "United States",
      "IATA": "JAX",
      "icao_code": "KJAX",
      "airport_name": "Jacksonville International Airport"
    },
    {
      "city": "Jacksonville",
      "country": "United States",
      "IATA": "NIP",
      "icao_code": "KNIP",
      "airport_name": "Naval Air Station Jacksonville"
    },
    {
      "city": "Jacksonville",
      "country": "United States",
      "IATA": "LRF",
      "icao_code": "KLRF",
      "airport_name": "Little Rock Air Force Base"
    },
    {
      "city": "Jacksonville NC",
      "country": "United States",
      "IATA": "OAJ",
      "icao_code": "KOAJ",
      "airport_name": "Albert J. Ellis Airport"
    },
    {
      "city": "Jacmel",
      "country": "Haiti",
      "IATA": "JAK",
      "airport_name": "Jacmel Airport"
    },
    {
      "city": "Jacobsbad",
      "country": "Pakistan",
      "IATA": "JAG",
      "icao_code": "OPJA",
      "airport_name": "PAF Base Shahbaz"
    },
    {
      "city": "Jaffna",
      "country": "Sri Lanka",
      "IATA": "JAF",
      "icao_code": "VCCJ",
      "airport_name": "Jaffna Airport "
    },
    {
      "city": "Jaguaruna",
      "country": "Brazil",
      "IATA": "JJG",
      "icao_code": "SBJA",
      "airport_name": "Humberto Ghizzo Bortoluzzi Airport"
    },
    {
      "city": "Jahrom",
      "country": "Iran",
      "IATA": "JAR",
      "airport_name": "Jahrom Airport"
    },
    {
      "city": "Jaipur",
      "country": "India",
      "IATA": "JAI",
      "icao_code": "VIJP",
      "airport_name": "Jaipur International Airport"
    },
    {
      "city": "Jaisalmer",
      "country": "India",
      "IATA": "JSA",
      "icao_code": "VIJR",
      "airport_name": "Jaisalmer Airport"
    },
    {
      "city": "Jakar",
      "country": "Bhutan",
      "IATA": "BUT",
      "icao_code": "VQBT",
      "airport_name": "Burtonwood Airport "
    },
    {
      "city": "Jakarta",
      "country": "Indonesia",
      "IATA": "HLP",
      "icao_code": "WIIH",
      "airport_name": "Halim Perdanakusuma International Airport"
    },
    {
      "city": "Jakarta",
      "country": "Indonesia",
      "IATA": "PCB",
      "icao_code": "WIHP",
      "airport_name": "Pondok Cabe Airport"
    },
    {
      "city": "Jakarta",
      "country": "Indonesia",
      "IATA": "CGK",
      "icao_code": "WIII",
      "airport_name": "Soekarno-Hatta International Airport"
    },
    {
      "city": "Jalalabad",
      "country": "Afghanistan",
      "IATA": "JAA",
      "icao_code": "OAJL",
      "airport_name": "Jalalabad Airport"
    },
    {
      "city": "Jalapa",
      "country": "Mexico",
      "IATA": "JAL",
      "icao_code": "MMJA",
      "airport_name": "El Lencero Airport"
    },
    {
      "city": "Jambi",
      "country": "Indonesia",
      "IATA": "DJB",
      "icao_code": "WIPA",
      "airport_name": "Sultan Thaha Airport "
    },
    {
      "city": "Jamestown",
      "country": "United States",
      "IATA": "JHW",
      "icao_code": "KJHW",
      "airport_name": "Chautauqua County-Jamestown Airport"
    },
    {
      "city": "Jamestown",
      "country": "United States",
      "IATA": "JMS",
      "icao_code": "KJMS",
      "airport_name": "Jamestown Regional Airport"
    },
    {
      "city": "Jammu",
      "country": "India",
      "IATA": "IXJ",
      "icao_code": "VIJU",
      "airport_name": "Jammu Airport "
    },
    {
      "city": "Jamnagar",
      "country": "India",
      "IATA": "JGA",
      "icao_code": "VAJM",
      "airport_name": "Jamnagar Airport"
    },
    {
      "city": "Jamshedpur",
      "country": "India",
      "IATA": "IXW",
      "icao_code": "VEJS",
      "airport_name": "Sonari Airport"
    },
    {
      "city": "Janakpur",
      "country": "Nepal",
      "IATA": "JKR",
      "icao_code": "VNJP",
      "airport_name": "Janakpur Airport"
    },
    {
      "city": "Janesville",
      "country": "United States",
      "IATA": "JVL",
      "icao_code": "KJVL",
      "airport_name": "Southern Wisconsin Regional Airport"
    },
    {
      "city": "Jaqué",
      "country": "Panama",
      "IATA": "JQE",
      "icao_code": "MPJE",
      "airport_name": " Jaqué Airport"
    },
    {
      "city": "Jask",
      "country": "Iran",
      "IATA": "JSK",
      "airport_name": "St. Cloud Municipal Heliport"
    },
    {
      "city": "Jasper",
      "country": "United States",
      "IATA": "APT",
      "airport_name": "Dunnellon/Marion County Airport"
    },
    {
      "city": "Jasper",
      "country": "United States",
      "IATA": "JAS",
      "airport_name": "Jasper County Airport "
    },
    {
      "city": "Jauja",
      "country": "Peru",
      "IATA": "JAU",
      "icao_code": "SPJJ",
      "airport_name": "Francisco Carle Airport"
    },
    {
      "city": "Jayapura",
      "country": "Indonesia",
      "IATA": "DJJ",
      "icao_code": "WAJJ",
      "airport_name": "Sentani International Airport"
    },
    {
      "city": "Jaén",
      "country": "Peru",
      "IATA": "JAE",
      "airport_name": "Shumba Airport"
    },
    {
      "city": "Jeddah",
      "country": "Saudi Arabia",
      "IATA": "JED",
      "icao_code": "OEJN",
      "airport_name": "King Abdulaziz International Airport"
    },
    {
      "city": "Jefferson City",
      "country": "United States",
      "IATA": "JEF",
      "icao_code": "KJEF",
      "airport_name": "Jefferson City Memorial Airport"
    },
    {
      "city": "Jenpeg",
      "country": "Canada",
      "IATA": "ZJG",
      "icao_code": "CZJG",
      "airport_name": "Jenpeg Airport"
    },
    {
      "city": "Jeremie",
      "country": "Haiti",
      "IATA": "JEE",
      "icao_code": "MTJE",
      "airport_name": "Jérémie Airport"
    },
    {
      "city": "Jerez",
      "country": "Spain",
      "IATA": "XRY",
      "icao_code": "LEJR",
      "airport_name": "Jerez Airport"
    },
    {
      "city": "Jersey",
      "country": "Jersey",
      "IATA": "JER",
      "icao_code": "EGJJ",
      "airport_name": "Jersey Airport"
    },
    {
      "city": "Jessore",
      "country": "Bangladesh",
      "IATA": "JSR",
      "icao_code": "VGJR",
      "airport_name": "Jessore Airport"
    },
    {
      "city": "Jeypore",
      "country": "India",
      "IATA": "PYB",
      "icao_code": "VEJP",
      "airport_name": "Jeypore Airport"
    },
    {
      "city": "Jhunju",
      "country": "South Korea",
      "IATA": "CHN",
      "icao_code": "RKJU",
      "airport_name": "Chonju Airport"
    },
    {
      "city": "Ji-Paraná",
      "country": "Brazil",
      "IATA": "JPR",
      "icao_code": "SWJI",
      "airport_name": "Ji-Paraná Airport"
    },
    {
      "city": "Jiagedaqi District",
      "country": "China",
      "IATA": "JGD",
      "airport_name": "Jiagedaqi Airport"
    },
    {
      "city": "Jiamusi",
      "country": "China",
      "IATA": "JMU",
      "icao_code": "ZYJM",
      "airport_name": "Jiamusi Dongjiao Airport"
    },
    {
      "city": "Jian",
      "country": "China",
      "IATA": "JGS",
      "icao_code": "ZSJA",
      "airport_name": "Jinggangshan Airport"
    },
    {
      "city": "Jiansanjiang",
      "country": "China",
      "IATA": "JSJ",
      "icao_code": "ZYJS",
      "airport_name": "Jiansanjiang Shidi Airport"
    },
    {
      "city": "Jiayuguan",
      "country": "China",
      "IATA": "JGN",
      "icao_code": "ZLJQ",
      "airport_name": "Jiayuguan Airport"
    },
    {
      "city": "Jijel",
      "country": "Algeria",
      "IATA": "GJL",
      "icao_code": "DAAV",
      "airport_name": "Jijel Ferhat Abbas Airport"
    },
    {
      "city": "Jijiga",
      "country": "Ethiopia",
      "IATA": "JIJ",
      "icao_code": "HAJJ",
      "airport_name": "Wilwal International Airport"
    },
    {
      "city": "Jimma",
      "country": "Ethiopia",
      "IATA": "JIM",
      "icao_code": "HAJM",
      "airport_name": "Aba Segud Airport"
    },
    {
      "city": "Jinan",
      "country": "China",
      "IATA": "TNA",
      "icao_code": "ZSJN",
      "airport_name": "Jinan Yaoqiang International Airport"
    },
    {
      "city": "Jinchuan",
      "country": "China",
      "IATA": "JIC",
      "airport_name": "Jinchuan Airport"
    },
    {
      "city": "Jingdezhen",
      "country": "China",
      "IATA": "JDZ",
      "icao_code": "ZSJD",
      "airport_name": "Jingdezhen Luojia Airport"
    },
    {
      "city": "Jinghonggasa",
      "country": "China",
      "IATA": "JHG",
      "airport_name": "Xishuangbanna Gasa International Airport"
    },
    {
      "city": "Jining",
      "country": "China",
      "IATA": "JNG",
      "airport_name": "Jining Qufu Airport"
    },
    {
      "city": "Jinzhou",
      "country": "China",
      "IATA": "JNZ",
      "icao_code": "ZYJZ",
      "airport_name": "Jinzhou Xiaolingzi Airport"
    },
    {
      "city": "Jiroft",
      "country": "Iran",
      "IATA": "JYR",
      "icao_code": "OIKJ",
      "airport_name": "Jiroft Airport"
    },
    {
      "city": "Jiujiang",
      "country": "China",
      "IATA": "JIU",
      "icao_code": "ZSJJ",
      "airport_name": "Jiujiang Lushan Airport"
    },
    {
      "city": "Jiuzhaigou",
      "country": "China",
      "IATA": "JZH",
      "icao_code": "ZUJZ",
      "airport_name": "Jiuzhai Huanglong Airport "
    },
    {
      "city": "Jiwani",
      "country": "Pakistan",
      "IATA": "JIW",
      "icao_code": "OPJI",
      "airport_name": "Jiwani Airport"
    },
    {
      "city": "Jixi",
      "country": "China",
      "IATA": "JXA",
      "icao_code": "ZYJX",
      "airport_name": "Jixi Xingkaihu Airport"
    },
    {
      "city": "Joacaba",
      "country": "Brazil",
      "IATA": "JCB",
      "icao_code": "SSJA",
      "airport_name": "Joaçaba Airport"
    },
    {
      "city": "Joao Pessoa",
      "country": "Brazil",
      "IATA": "JPA",
      "icao_code": "SBJP",
      "airport_name": "Presidente Castro Pinto International Airport"
    },
    {
      "city": "Jodhpur",
      "country": "India",
      "IATA": "JDH",
      "icao_code": "VIJO",
      "airport_name": "Jodhpur Airport"
    },
    {
      "city": "Joenkoeping",
      "country": "Sweden",
      "IATA": "JKG",
      "icao_code": "ESGJ",
      "airport_name": "Jönköping Airport"
    },
    {
      "city": "Joensuu",
      "country": "Finland",
      "IATA": "JOE",
      "icao_code": "EFJO",
      "airport_name": "Joensuu Airport"
    },
    {
      "city": "Johannesburg",
      "country": "South Africa",
      "IATA": "QRA",
      "icao_code": "FAGM",
      "airport_name": "Rand Airport"
    },
    {
      "city": "Johannesburg",
      "country": "South Africa",
      "IATA": "HLA",
      "icao_code": "FALA",
      "airport_name": "Lanseria International Airport"
    },
    {
      "city": "Johannesburg",
      "country": "South Africa",
      "IATA": "JNB",
      "icao_code": "FAJS",
      "airport_name": "O. R. Tambo International Airport"
    },
    {
      "city": "Johannesburg",
      "country": "South Africa",
      "IATA": "GCJ",
      "icao_code": "FAGC",
      "airport_name": "Grand Central Airport"
    },
    {
      "city": "Johnston Island",
      "country": "Johnston Atoll",
      "IATA": "JON",
      "airport_name": "Johnston Atoll Airport"
    },
    {
      "city": "Johnstown",
      "country": "United States",
      "IATA": "JST",
      "icao_code": "KJST",
      "airport_name": "John Murtha Johnstown–Cambria County Airpor"
    },
    {
      "city": "Johor Bahru",
      "country": "Malaysia",
      "IATA": "JHB",
      "icao_code": "WMKJ",
      "airport_name": "Senai International Airport"
    },
    {
      "city": "Joinville",
      "country": "Brazil",
      "IATA": "JOI",
      "icao_code": "SBJV",
      "airport_name": "Joinville-Lauro Carneiro de Loyola Airport "
    },
    {
      "city": "Joliet",
      "country": "United States",
      "IATA": "JOT",
      "icao_code": "KJOT",
      "airport_name": "Joliet Regional Airport"
    },
    {
      "city": "Jolo",
      "country": "Philippines",
      "IATA": "JOL",
      "airport_name": "Jolo Airport"
    },
    {
      "city": "Jomsom",
      "country": "Nepal",
      "IATA": "JMO",
      "icao_code": "VNJS",
      "airport_name": "Jomsom Airport"
    },
    {
      "city": "Jonesboro",
      "country": "United States",
      "IATA": "JBR",
      "icao_code": "KJBR",
      "airport_name": "Jonesboro Municipal Airport"
    },
    {
      "city": "Joplin",
      "country": "United States",
      "IATA": "JLN",
      "icao_code": "KJLN",
      "airport_name": "Joplin Regional Airport"
    },
    {
      "city": "Jorhat",
      "country": "India",
      "IATA": "JRH",
      "icao_code": "VEJT",
      "airport_name": "Jorhat Airport"
    },
    {
      "city": "Jos",
      "country": "Nigeria",
      "IATA": "JOS",
      "icao_code": "DNJO",
      "airport_name": "Yakubu Gowon Airport"
    },
    {
      "city": "Jose de San Martin",
      "country": "Argentina",
      "IATA": "JSM",
      "icao_code": "SAWS",
      "airport_name": "Jose De San Martin Airport"
    },
    {
      "city": "Juanjui",
      "country": "Peru",
      "IATA": "JJI",
      "icao_code": "SPJI",
      "airport_name": "Juanjuí Airport"
    },
    {
      "city": "Juara",
      "country": "Brazil",
      "IATA": "JUA",
      "icao_code": "SIZX",
      "airport_name": "Juara Airport"
    },
    {
      "city": "Juazeiro Do Norte",
      "country": "Brazil",
      "IATA": "JDO",
      "icao_code": "SNQY",
      "airport_name": "Juazeiro do Norte  Airport"
    },
    {
      "city": "Juba",
      "country": "South Sudan",
      "IATA": "JUB",
      "icao_code": "HSSJ",
      "airport_name": "Juba International Airport"
    },
    {
      "city": "Jubail",
      "country": "Saudi Arabia",
      "IATA": "QJB",
      "airport_name": "Jubail Airport"
    },
    {
      "city": "Juina",
      "country": "Brazil",
      "IATA": "JIA",
      "icao_code": "SWJN",
      "airport_name": "Juina Airport"
    },
    {
      "city": "Juist",
      "country": "Germany",
      "IATA": "JUI",
      "icao_code": "EDWJ",
      "airport_name": "Airfield Juist"
    },
    {
      "city": "Juiz De Fora",
      "country": "Brazil",
      "IATA": "JDF",
      "icao_code": "SBJF",
      "airport_name": "Francisco Álvares de Assis Airport"
    },
    {
      "city": "Juiz de Fora",
      "country": "Brazil",
      "IATA": "IZA",
      "icao_code": "SDZY",
      "airport_name": "Presidente Itamar Franco Airport"
    },
    {
      "city": "Jujuy",
      "country": "Argentina",
      "IATA": "JUJ",
      "icao_code": "SASJ",
      "airport_name": "Gobernador Horacio Guzman International Airport"
    },
    {
      "city": "Julia Creek",
      "country": "Australia",
      "IATA": "JCK",
      "icao_code": "YJLC",
      "airport_name": "Julia Creek Airport"
    },
    {
      "city": "Juliaca",
      "country": "Peru",
      "IATA": "JUL",
      "icao_code": "SPJL",
      "airport_name": "Inca Manco Cápac International Airport"
    },
    {
      "city": "Jumla",
      "country": "Nepal",
      "IATA": "JUM",
      "icao_code": "VNJL",
      "airport_name": "Jumla Airport"
    },
    {
      "city": "Junction",
      "country": "United States",
      "IATA": "JCT",
      "airport_name": "Kimble County Airport"
    },
    {
      "city": "Juneau",
      "country": "United States",
      "IATA": "JNU",
      "icao_code": "PAJN",
      "airport_name": "Juneau International Airport"
    },
    {
      "city": "Junin",
      "country": "Argentina",
      "IATA": "JNI",
      "icao_code": "SAAJ",
      "airport_name": "Junin Airport"
    },
    {
      "city": "Juruena",
      "country": "Brazil",
      "IATA": "JRN",
      "icao_code": "SWJU",
      "airport_name": "Juruena Airport"
    },
    {
      "city": "Jwaneng",
      "country": "Botswana",
      "IATA": "JWA",
      "icao_code": "FBJW",
      "airport_name": "Jwaneng Airport"
    },
    {
      "city": "Jyvaskyla",
      "country": "Finland",
      "IATA": "JYV",
      "icao_code": "EFJY",
      "airport_name": "Jyväskylä Airport"
    },
    {
      "city": "Kaadedhdhoo",
      "country": "Maldives",
      "IATA": "KDM",
      "icao_code": "VRMT",
      "airport_name": "Kaadedhdhoo Airport"
    },
    {
      "city": "Kabinda",
      "country": "Congo (Kinshasa)",
      "IATA": "KBN",
      "icao_code": "FZWT",
      "airport_name": "Tunta Airport"
    },
    {
      "city": "Kabri Dehar",
      "country": "Ethiopia",
      "IATA": "ABK",
      "icao_code": "HADK",
      "airport_name": "Kabri Dahar Airport"
    },
    {
      "city": "Kabul",
      "country": "Afghanistan",
      "IATA": "OAI",
      "icao_code": "OAIX",
      "airport_name": "Bagram Airfield"
    },
    {
      "city": "Kabul",
      "country": "Afghanistan",
      "IATA": "KBL",
      "icao_code": "OAKB",
      "airport_name": "Hamid Karzai International Airport"
    },
    {
      "city": "Kadanwari",
      "country": "Pakistan",
      "IATA": "KCF",
      "icao_code": "OPKW",
      "airport_name": "Kadanwari Airport"
    },
    {
      "city": "Kadena",
      "country": "Japan",
      "IATA": "DNA",
      "icao_code": "RODN",
      "airport_name": "Kadena Air Base"
    },
    {
      "city": "Kadugli",
      "country": "Sudan",
      "IATA": "KDX",
      "airport_name": "Kadugli Airport"
    },
    {
      "city": "Kaduna",
      "country": "Nigeria",
      "IATA": "KAD",
      "icao_code": "DNKA",
      "airport_name": "Kaduna International Airport"
    },
    {
      "city": "Kaedi",
      "country": "Mauritania",
      "IATA": "KED",
      "icao_code": "GQNK",
      "airport_name": "Kaédi Airport"
    },
    {
      "city": "Kagau Island",
      "country": "Solomon Islands",
      "IATA": "KGE",
      "icao_code": "AGKG",
      "airport_name": "Kagau Island Airport"
    },
    {
      "city": "Kagi",
      "country": "Papua New Guinea",
      "IATA": "KGW",
      "airport_name": "Kagi Airport"
    },
    {
      "city": "Kagoshima",
      "country": "Japan",
      "IATA": "KOJ",
      "icao_code": "RJFK",
      "airport_name": "Kagoshima Airport"
    },
    {
      "city": "Kahramanmaras",
      "country": "Turkey",
      "IATA": "KCM",
      "icao_code": "LTCN",
      "airport_name": "Kahramanmaras Airport"
    },
    {
      "city": "Kahului",
      "country": "United States",
      "IATA": "OGG",
      "icao_code": "PHOG",
      "airport_name": "Kahului Airport "
    },
    {
      "city": "Kaieteur",
      "country": "Guyana",
      "IATA": "KAI",
      "icao_code": "PKSA",
      "airport_name": "Kaieteur International Airport"
    },
    {
      "city": "Kaikoura",
      "country": "New Zealand",
      "IATA": "KBZ",
      "icao_code": "NZKI",
      "airport_name": "Kaikoura Airport"
    },
    {
      "city": "Kailashahar",
      "country": "India",
      "IATA": "IXH",
      "icao_code": "VEKR",
      "airport_name": "Kailashahar Airport"
    },
    {
      "city": "Kaili",
      "country": "China",
      "IATA": "KJH",
      "icao_code": "ZUKJ",
      "airport_name": "Huangping Airport"
    },
    {
      "city": "Kaimana",
      "country": "Indonesia",
      "IATA": "KNG",
      "icao_code": "WASK",
      "airport_name": "Kaimana Airport"
    },
    {
      "city": "Kaiser Lake Ozark",
      "country": "United States",
      "IATA": "AIZ",
      "icao_code": "KAIZ",
      "airport_name": "Lee C. Fine Memorial Airport"
    },
    {
      "city": "Kaitaia",
      "country": "New Zealand",
      "IATA": "KAT",
      "icao_code": "NZKT",
      "airport_name": "Kaitaia Airport"
    },
    {
      "city": "Kajaani",
      "country": "Finland",
      "IATA": "KAJ",
      "icao_code": "EFKI",
      "airport_name": "Kajaani Airport"
    },
    {
      "city": "Kakamega",
      "country": "Kenya",
      "IATA": "GGM",
      "icao_code": "HKKG",
      "airport_name": "Kakamega Airport"
    },
    {
      "city": "Kalaleh",
      "country": "Iran",
      "IATA": "KLM",
      "airport_name": "Kalaleh Airport"
    },
    {
      "city": "Kalamata",
      "country": "Greece",
      "IATA": "KLX",
      "icao_code": "LGKL",
      "airport_name": "Kalamata International Airport"
    },
    {
      "city": "Kalamazoo",
      "country": "United States",
      "IATA": "AZO",
      "icao_code": "KAZO",
      "airport_name": "Kalamazoo/Battle Creek International Airport"
    },
    {
      "city": "Kalbarri",
      "country": "Australia",
      "IATA": "KAX",
      "icao_code": "YKBR",
      "airport_name": "Kalbarri Airport"
    },
    {
      "city": "Kalemie",
      "country": "Congo (Kinshasa)",
      "IATA": "FMI",
      "icao_code": "FZRF",
      "airport_name": "Kalemie Airport"
    },
    {
      "city": "Kalemyo",
      "country": "Myanmar",
      "IATA": "KMV",
      "icao_code": "VYKL",
      "airport_name": "Kalaymyo Airport"
    },
    {
      "city": "Kalgoorlie",
      "country": "Australia",
      "IATA": "KGI",
      "icao_code": "YPKG",
      "airport_name": "Kalgoorlie-Boulder Airport"
    },
    {
      "city": "Kalibo",
      "country": "Philippines",
      "IATA": "KLO",
      "icao_code": "RPVK",
      "airport_name": "Kalibo International Airport"
    },
    {
      "city": "Kaliningrad",
      "country": "Russia",
      "IATA": "KGD",
      "icao_code": "UMKK",
      "airport_name": "Khrabrovo Airport"
    },
    {
      "city": "Kalispell",
      "country": "United States",
      "IATA": "FCA",
      "icao_code": "KGPI",
      "airport_name": "Glacier Park International Airport "
    },
    {
      "city": "Kalkgurung",
      "country": "Australia",
      "IATA": "KFG",
      "icao_code": "YKKG",
      "airport_name": "Kalkurung Airport"
    },
    {
      "city": "Kalkmar",
      "country": "Sweden",
      "IATA": "KLR",
      "icao_code": "ESMQ",
      "airport_name": "Kalmar Airport"
    },
    {
      "city": "Kalskag",
      "country": "United States",
      "IATA": "KLG",
      "icao_code": "PALG",
      "airport_name": "Kalskag Airport"
    },
    {
      "city": "Kaltag",
      "country": "United States",
      "IATA": "KAL",
      "icao_code": "PAKV",
      "airport_name": "Kaltag Airport"
    },
    {
      "city": "Kaluga",
      "country": "Russia",
      "IATA": "KLF",
      "icao_code": "UUBC",
      "airport_name": "Kaluga (Grabtsevo) Airport"
    },
    {
      "city": "Kalymnos",
      "country": "Greece",
      "IATA": "JKL",
      "icao_code": "LGKY",
      "airport_name": "Kalymnos National Airport"
    },
    {
      "city": "Kamarang",
      "country": "Guyana",
      "IATA": "KAR",
      "icao_code": "SYKM",
      "airport_name": "Kamarang Airport"
    },
    {
      "city": "Kamembe",
      "country": "Rwanda",
      "IATA": "KME",
      "icao_code": "HRZA",
      "airport_name": "Kamembe International Airport"
    },
    {
      "city": "Kamina Base",
      "country": "Congo (Kinshasa)",
      "IATA": "KMN",
      "icao_code": "FZSA",
      "airport_name": "Kamina Air Base"
    },
    {
      "city": "Kamishly",
      "country": "Syria",
      "IATA": "KAC",
      "icao_code": "OSKL",
      "airport_name": "Qamishli Airport"
    },
    {
      "city": "Kamloops",
      "country": "Canada",
      "IATA": "AMN",
      "icao_code": "KAMN",
      "airport_name": "Gratiot Community Airport"
    },
    {
      "city": "Kamloops",
      "country": "Canada",
      "IATA": "YKA",
      "icao_code": "CYKA",
      "airport_name": "Kamloops Airport"
    },
    {
      "city": "Kamra",
      "country": "Pakistan",
      "IATA": "ATG",
      "airport_name": " Teniente General Gerardo Pérez Pinedo Airport"
    },
    {
      "city": "Kamuela",
      "country": "United States",
      "IATA": "MUE",
      "icao_code": "PHMU",
      "airport_name": "Waimea-Kohala Airport"
    },
    {
      "city": "Kananga",
      "country": "Congo (Kinshasa)",
      "IATA": "KGA",
      "icao_code": "FZUA",
      "airport_name": "Kananga Airport"
    },
    {
      "city": "Kanazawa",
      "country": "Japan",
      "IATA": "KMQ",
      "icao_code": "RJNK",
      "airport_name": "Komatsu Airport "
    },
    {
      "city": "Kandahar",
      "country": "Afghanistan",
      "IATA": "KDH",
      "icao_code": "OAKN",
      "airport_name": "Kandahar International Airport"
    },
    {
      "city": "Kandi",
      "country": "Benin",
      "IATA": "KDC",
      "icao_code": "DBBK",
      "airport_name": "Kandi Airport"
    },
    {
      "city": "Kandla",
      "country": "India",
      "IATA": "IXY",
      "icao_code": "VAKE",
      "airport_name": "Kandla Airport "
    },
    {
      "city": "Kaneohe Bay",
      "country": "United States",
      "IATA": "NGF",
      "icao_code": "PHNG",
      "airport_name": "Marine Corps Air Station Kaneohe Bay"
    },
    {
      "city": "Kangaatsiaq",
      "country": "Greenland",
      "IATA": "QPW",
      "airport_name": "Kangaatsiaq Heliport"
    },
    {
      "city": "Kangan",
      "country": "Iran",
      "IATA": "KNR",
      "airport_name": "Jam Airport"
    },
    {
      "city": "Kangding",
      "country": "China",
      "IATA": "KGT",
      "icao_code": "ZUKD",
      "airport_name": "Kangding Airport"
    },
    {
      "city": "Kangiqsualujjuaq",
      "country": "Canada",
      "IATA": "XGR",
      "icao_code": "CYLU",
      "airport_name": "Kangiqsualujjuaq (Georges River) Airport"
    },
    {
      "city": "Kangiqsujuaq",
      "country": "Canada",
      "IATA": "YWB",
      "icao_code": "CYKG",
      "airport_name": "Kangiqsujuaq (Wakeham Bay) Airport"
    },
    {
      "city": "Kangirsuk",
      "country": "Canada",
      "IATA": "YKG",
      "icao_code": "CYAS",
      "airport_name": "Kangirsuk Airport"
    },
    {
      "city": "Kangnung",
      "country": "South Korea",
      "IATA": "KAG",
      "icao_code": "RKNN",
      "airport_name": "Gangneung Airbase"
    },
    {
      "city": "Kangra",
      "country": "India",
      "IATA": "DHM",
      "icao_code": "VIGG",
      "airport_name": "Kangra Airport"
    },
    {
      "city": "Kankakee",
      "country": "United States",
      "IATA": "IKK",
      "icao_code": "KIKK",
      "airport_name": "Greater Kankakee Airport"
    },
    {
      "city": "Kannur",
      "country": "India",
      "IATA": "CNN",
      "icao_code": "VOKN",
      "airport_name": "Kannur International Airport"
    },
    {
      "city": "Kano",
      "country": "Nigeria",
      "IATA": "KAN",
      "icao_code": "DNKN",
      "airport_name": "Mallam Aminu Kano International Airport"
    },
    {
      "city": "Kanpur",
      "country": "India",
      "IATA": "KNU",
      "icao_code": "VIKA",
      "airport_name": "Kanpur Airport"
    },
    {
      "city": "Kansas City",
      "country": "United States",
      "IATA": "MKC",
      "icao_code": "KMKC",
      "airport_name": "Charles B. Wheeler Downtown Airport"
    },
    {
      "city": "Kansas City",
      "country": "United States",
      "IATA": "MCI",
      "icao_code": "KMCI",
      "airport_name": "Kansas City International Airport"
    },
    {
      "city": "Kao",
      "country": "Indonesia",
      "IATA": "KAZ",
      "icao_code": "WAMK",
      "airport_name": "Kao Airport"
    },
    {
      "city": "Kaohsiung",
      "country": "Taiwan",
      "IATA": "KHH",
      "icao_code": "RCKH",
      "airport_name": "Kaohsiung International Airport"
    },
    {
      "city": "Kaolack",
      "country": "Senegal",
      "IATA": "KLC",
      "icao_code": "GOOK",
      "airport_name": "Kaolack Airport"
    },
    {
      "city": "Kapanda",
      "country": "Angola",
      "IATA": "KNP",
      "icao_code": "FNCP",
      "airport_name": "Capanda Airport"
    },
    {
      "city": "Kapolei",
      "country": "United States",
      "IATA": "JRF",
      "airport_name": "Kalaeloa Airport"
    },
    {
      "city": "Kapuskasing",
      "country": "Canada",
      "IATA": "YYU",
      "icao_code": "CYYU",
      "airport_name": "Kapuskasing Airport"
    },
    {
      "city": "Karachi",
      "country": "Pakistan",
      "IATA": "KHI",
      "icao_code": "OPKC",
      "airport_name": "Jinnah International Airport "
    },
    {
      "city": "Karaganda",
      "country": "Kazakhstan",
      "IATA": "KGF",
      "icao_code": "UAKK",
      "airport_name": "Sary-Arka Airport"
    },
    {
      "city": "Karaj",
      "country": "Iran",
      "IATA": "PYK",
      "airport_name": "Payam International Airport"
    },
    {
      "city": "Karamay",
      "country": "China",
      "IATA": "KRY",
      "icao_code": "ZWKM",
      "airport_name": "Karamay Airport"
    },
    {
      "city": "Kardla",
      "country": "Estonia",
      "IATA": "KDL",
      "icao_code": "EEKA",
      "airport_name": "Kärdla Airport"
    },
    {
      "city": "Kariba",
      "country": "Zimbabwe",
      "IATA": "KAB",
      "icao_code": "FVKB",
      "airport_name": "Kariba Airport"
    },
    {
      "city": "Karimunjawa",
      "country": "Indonesia",
      "IATA": "KWB",
      "icao_code": "WICM",
      "airport_name": "Karimunjawa Airport"
    },
    {
      "city": "Karlovy Vary",
      "country": "Czech Republic",
      "IATA": "KLV",
      "icao_code": "LKKV",
      "airport_name": "Karlovy Vary Airport"
    },
    {
      "city": "Karlskoga",
      "country": "Sweden",
      "IATA": "KSK",
      "airport_name": "Karlskoga Airport"
    },
    {
      "city": "Karlsruhe/Baden-Baden",
      "country": "Germany",
      "IATA": "FKB",
      "icao_code": "EDSB",
      "airport_name": "Karlsruhe/Baden-Baden Airport"
    },
    {
      "city": "Karlstad",
      "country": "Sweden",
      "IATA": "KSD",
      "icao_code": "ESOK",
      "airport_name": "Karlstad Airport"
    },
    {
      "city": "Karluk",
      "country": "United States",
      "IATA": "KYK",
      "icao_code": "PAKY",
      "airport_name": "Karluk Airport"
    },
    {
      "city": "Karonga",
      "country": "Malawi",
      "IATA": "KGJ",
      "icao_code": "FWKA",
      "airport_name": "Karonga Airport"
    },
    {
      "city": "Karpathos",
      "country": "Greece",
      "IATA": "AOK",
      "icao_code": "LGKP",
      "airport_name": "Karpathos Island National Airport"
    },
    {
      "city": "Karratha",
      "country": "Australia",
      "IATA": "KTA",
      "icao_code": "YPKA",
      "airport_name": "Karratha Airport"
    },
    {
      "city": "Kars",
      "country": "Turkey",
      "IATA": "KSY",
      "icao_code": "LTCF",
      "airport_name": "Kars Harakani Airport"
    },
    {
      "city": "Karumba",
      "country": "Australia",
      "IATA": "KRB",
      "icao_code": "YKMB",
      "airport_name": "Karumba Airport"
    },
    {
      "city": "Karup",
      "country": "Denmark",
      "IATA": "KRP",
      "icao_code": "EKKA",
      "airport_name": "Midtjyllands Airport"
    },
    {
      "city": "Kasaba Bay",
      "country": "Zambia",
      "IATA": "ZKB",
      "icao_code": "FLKY",
      "airport_name": "Kasaba Bay Airport"
    },
    {
      "city": "Kasabonika",
      "country": "Canada",
      "IATA": "XKS",
      "icao_code": "CYAQ",
      "airport_name": "Kasabonika Airport"
    },
    {
      "city": "Kasama",
      "country": "Zambia",
      "IATA": "KAA",
      "icao_code": "FLKS",
      "airport_name": "Kasama Airport"
    },
    {
      "city": "Kasane",
      "country": "Botswana",
      "IATA": "BBK",
      "icao_code": "FBKE",
      "airport_name": "Kasane Airport"
    },
    {
      "city": "Kasese",
      "country": "Uganda",
      "IATA": "KSE",
      "icao_code": "HUKS",
      "airport_name": "Kasese Airport"
    },
    {
      "city": "Kashan",
      "country": "Iran",
      "IATA": "KKS",
      "airport_name": "Kashan Airport"
    },
    {
      "city": "Kashechewan",
      "country": "Canada",
      "IATA": "ZKE",
      "icao_code": "CZKE",
      "airport_name": "Kashechewan Airport"
    },
    {
      "city": "Kashi",
      "country": "China",
      "IATA": "KHG",
      "icao_code": "ZWSH",
      "airport_name": "Kashi Airport"
    },
    {
      "city": "Kasigluk",
      "country": "United States",
      "IATA": "KUK",
      "icao_code": "PFKA",
      "airport_name": "Kasigluk Airport "
    },
    {
      "city": "Kasompe",
      "country": "Zambia",
      "IATA": "CGJ",
      "airport_name": "Kasompe Airport"
    },
    {
      "city": "Kasos",
      "country": "Greece",
      "IATA": "KSJ",
      "icao_code": "LGKS",
      "airport_name": "Kasos Island Public Airport"
    },
    {
      "city": "Kassala",
      "country": "Sudan",
      "IATA": "KSL",
      "icao_code": "HSKA",
      "airport_name": "Kassala Airport"
    },
    {
      "city": "Kassel",
      "country": "Germany",
      "IATA": "KSF",
      "icao_code": "EDVK",
      "airport_name": "Kassel Airport"
    },
    {
      "city": "Kastamonu",
      "country": "Turkey",
      "IATA": "KFS",
      "icao_code": "LTAL",
      "airport_name": "Kastamonu Airport"
    },
    {
      "city": "Kastelorizo",
      "country": "Greece",
      "IATA": "KZS",
      "icao_code": "LGKJ",
      "airport_name": "Kastellorizo Airport "
    },
    {
      "city": "Kastoria",
      "country": "Greece",
      "IATA": "KSO",
      "icao_code": "LGKA",
      "airport_name": "Kastoria National Airport"
    },
    {
      "city": "Kasungu",
      "country": "Malawi",
      "IATA": "KBQ",
      "icao_code": "FWKG",
      "airport_name": "Kasungu Airport"
    },
    {
      "city": "Katherine",
      "country": "Australia",
      "IATA": "KTR",
      "icao_code": "YPTN",
      "airport_name": "RAAF Base Tindal"
    },
    {
      "city": "Kathmandu",
      "country": "Nepal",
      "IATA": "KTM",
      "icao_code": "VNKT",
      "airport_name": "Tribhuvan International Airport"
    },
    {
      "city": "Katowice",
      "country": "Poland",
      "IATA": "KTW",
      "icao_code": "EPKT",
      "airport_name": "Katowice International Airport "
    },
    {
      "city": "Kauehi",
      "country": "French Polynesia",
      "IATA": "KHZ",
      "icao_code": "NTKA",
      "airport_name": "Kauehi Airport"
    },
    {
      "city": "Kauhajoki",
      "country": "Finland",
      "IATA": "KHJ",
      "icao_code": "EFKJ",
      "airport_name": "Kauhajoki Airport"
    },
    {
      "city": "Kauhava",
      "country": "Finland",
      "IATA": "KAU",
      "icao_code": "EFKA",
      "airport_name": "Kauhava Airport"
    },
    {
      "city": "Kaukura Atoll",
      "country": "French Polynesia",
      "IATA": "KKR",
      "icao_code": "NTGK",
      "airport_name": "Kaukura  Airport"
    },
    {
      "city": "Kaunas",
      "country": "Lithuania",
      "IATA": "KUN",
      "icao_code": "EYKA",
      "airport_name": "Kaunas International Airport"
    },
    {
      "city": "Kautokeino",
      "country": "Norway",
      "IATA": "QKX",
      "airport_name": "Kautokeino Air Base"
    },
    {
      "city": "Kavala",
      "country": "Greece",
      "IATA": "KVA",
      "icao_code": "LGKV",
      "airport_name": "Kavala International Airport"
    },
    {
      "city": "Kavalerovo",
      "country": "Russia",
      "IATA": "KVR",
      "airport_name": "Kavalerovo Airport"
    },
    {
      "city": "Kavieng",
      "country": "Papua New Guinea",
      "IATA": "KVG",
      "icao_code": "AYKV",
      "airport_name": "Kavieng Airport"
    },
    {
      "city": "Kawama",
      "country": "Cuba",
      "IATA": "VRO",
      "airport_name": "Kawama Airport"
    },
    {
      "city": "Kawthoung",
      "country": "Burma",
      "IATA": "KAW",
      "icao_code": "VYKT",
      "airport_name": "Kawthaung Airport"
    },
    {
      "city": "Kayes",
      "country": "Mali",
      "IATA": "KYS",
      "icao_code": "GAKY",
      "airport_name": "Kayes Airport "
    },
    {
      "city": "Kayseri",
      "country": "Turkey",
      "IATA": "ASR",
      "icao_code": "LTAU",
      "airport_name": "Erkilet International Airport"
    },
    {
      "city": "Kazan",
      "country": "Russia",
      "IATA": "KZN",
      "icao_code": "UWKD",
      "airport_name": "Kazan International Airport"
    },
    {
      "city": "Kedougou",
      "country": "Senegal",
      "IATA": "KGG",
      "icao_code": "GOTK",
      "airport_name": "Kédougou Airport"
    },
    {
      "city": "Keene",
      "country": "United States",
      "IATA": "EEN",
      "icao_code": "KEEN",
      "airport_name": "Dillant-Hopkins Airport"
    },
    {
      "city": "Keetmanshoop",
      "country": "Namibia",
      "IATA": "KMP",
      "icao_code": "FYKT",
      "airport_name": "Keetmanshoop Airport"
    },
    {
      "city": "Keewaywin",
      "country": "Canada",
      "IATA": "KEW",
      "icao_code": "CPV8",
      "airport_name": "Keewaywin Airport "
    },
    {
      "city": "Keffallinia",
      "country": "Greece",
      "IATA": "EFL",
      "icao_code": "LGKF",
      "airport_name": "Cephalonia International Airport"
    },
    {
      "city": "Keflavik",
      "country": "Iceland",
      "IATA": "KEF",
      "icao_code": "BIKF",
      "airport_name": "Keflavik International Airport"
    },
    {
      "city": "Kegaska",
      "country": "Canada",
      "IATA": "ZKG",
      "icao_code": "CTK6",
      "airport_name": "Kegaska Airport"
    },
    {
      "city": "Kelowna",
      "country": "Canada",
      "IATA": "YLW",
      "icao_code": "CYLW",
      "airport_name": "Kelowna International Airport"
    },
    {
      "city": "Kelsey",
      "country": "Canada",
      "IATA": "KES",
      "icao_code": "CZEE",
      "airport_name": "Kelsey Airport"
    },
    {
      "city": "Kelso",
      "country": "United States",
      "IATA": "KLS",
      "icao_code": "KKLS",
      "airport_name": "Southwest Washington Regional Airport"
    },
    {
      "city": "Kemi",
      "country": "Finland",
      "IATA": "KEM",
      "icao_code": "EFKE",
      "airport_name": "Kemi-Tornio Airport"
    },
    {
      "city": "Kemorovo",
      "country": "Russia",
      "IATA": "KEJ",
      "icao_code": "UNEE",
      "airport_name": "Kemerovo International  Airport"
    },
    {
      "city": "Kempten",
      "country": "Germany",
      "IATA": "EMP",
      "airport_name": "Emporia Municipal Airport"
    },
    {
      "city": "Kenai",
      "country": "United States",
      "IATA": "ENA",
      "icao_code": "PAEN",
      "airport_name": "Kenai Municipal Airport"
    },
    {
      "city": "South Florida, Kendall-tamiami",
      "country": "United States",
      "IATA": "TMB",
      "icao_code": "KTMB",
      "airport_name": "Miami Executive Airport"
    },
    {
      "city": "Kendari",
      "country": "Indonesia",
      "IATA": "KDI",
      "icao_code": "WAAU",
      "airport_name": "Haluoleo Airport"
    },
    {
      "city": "Kenema",
      "country": "Sierra Leone",
      "IATA": "KEN",
      "icao_code": "GFKE",
      "airport_name": "Kenema Airport"
    },
    {
      "city": "Kengtung",
      "country": "Burma",
      "IATA": "KET",
      "icao_code": "VYKG",
      "airport_name": "Kengtung Airport"
    },
    {
      "city": "Kenora",
      "country": "Canada",
      "IATA": "YQK",
      "icao_code": "CYQK",
      "airport_name": "Kenora Airport"
    },
    {
      "city": "Kenosha",
      "country": "United States",
      "IATA": "ENW",
      "icao_code": "KENW",
      "airport_name": "Kenosha Regional Airport"
    },
    {
      "city": "Kentira",
      "country": "Morocco",
      "IATA": "NNA",
      "icao_code": "GMMY",
      "airport_name": "Kenitra Air Base"
    },
    {
      "city": "Keokuk",
      "country": "United States",
      "IATA": "EOK",
      "icao_code": "KEOK",
      "airport_name": "Keokuk Municipal Airport"
    },
    {
      "city": "Kerama",
      "country": "Japan",
      "IATA": "KJP",
      "icao_code": "ROKR",
      "airport_name": "Kerama Airport"
    },
    {
      "city": "Kerch",
      "country": "Ukraine",
      "IATA": "KHC",
      "icao_code": "UKFK",
      "airport_name": "Kerch Airport "
    },
    {
      "city": "Kerema",
      "country": "Papua New Guinea",
      "IATA": "KMA",
      "icao_code": "AYKM",
      "airport_name": "Kerema Airport"
    },
    {
      "city": "Kericho",
      "country": "Kenya",
      "IATA": "KEY",
      "icao_code": "HKKR",
      "airport_name": "Kericho Airport"
    },
    {
      "city": "Kerikeri",
      "country": "New Zealand",
      "IATA": "KKE",
      "icao_code": "NZKK",
      "airport_name": "Kerikeri Airport"
    },
    {
      "city": "Kerinci Regency",
      "country": "Indonesia",
      "IATA": "KRC",
      "icao_code": "WIPH",
      "airport_name": "Kerinci Airport"
    },
    {
      "city": "Kerkyra/corfu",
      "country": "Greece",
      "IATA": "CFU",
      "icao_code": "LGKR",
      "airport_name": "Loannis Kapodistrias International Airport"
    },
    {
      "city": "Kerman",
      "country": "Iran",
      "IATA": "KER",
      "icao_code": "OIKK",
      "airport_name": "Kerman International Airport"
    },
    {
      "city": "Kerrville",
      "country": "United States",
      "IATA": "ERV",
      "airport_name": "Kerrville Municipal Airport "
    },
    {
      "city": "Kerry",
      "country": "Ireland",
      "IATA": "KIR",
      "icao_code": "EIKY",
      "airport_name": "Kerry Airport"
    },
    {
      "city": "Kerteh",
      "country": "Malaysia",
      "IATA": "KTE",
      "icao_code": "WMKE",
      "airport_name": "Kerteh Airport"
    },
    {
      "city": "Keshod",
      "country": "India",
      "IATA": "IXK",
      "icao_code": "VAKS",
      "airport_name": "Junagadh Airport "
    },
    {
      "city": "Ketapang",
      "country": "Indonesia",
      "IATA": "KTG",
      "icao_code": "WIOK",
      "airport_name": "Rahadi Usman Airport "
    },
    {
      "city": "Ketchikan",
      "country": "United States",
      "IATA": "KTN",
      "icao_code": "PAKT",
      "airport_name": "Ketchikan International Airport"
    },
    {
      "city": "Key Lake",
      "country": "Canada",
      "IATA": "YKJ",
      "airport_name": "Key Lake Airport"
    },
    {
      "city": "Key West",
      "country": "United States",
      "IATA": "EYW",
      "icao_code": "KEYW",
      "airport_name": "Key West International Airport"
    },
    {
      "city": "Key West",
      "country": "United States",
      "IATA": "NQX",
      "airport_name": "Naval Air Station Key West"
    },
    {
      "city": "Khabarovsk",
      "country": "Russia",
      "IATA": "KHV",
      "icao_code": "UHHH",
      "airport_name": "Khabarovsk Novy Airport"
    },
    {
      "city": "Khajuraho",
      "country": "India",
      "IATA": "HJR",
      "icao_code": "VAKJ",
      "airport_name": "Khajuraho Airport"
    },
    {
      "city": "Khalije Fars",
      "country": "Iran",
      "IATA": "PGU",
      "icao_code": "OIBP",
      "airport_name": "Persian Gulf Airport"
    },
    {
      "city": "Khamti",
      "country": "Burma",
      "IATA": "KHM",
      "icao_code": "VYKI",
      "airport_name": "Khamti Airport"
    },
    {
      "city": "Khanabad",
      "country": "Uzbekistan",
      "IATA": "KSQ",
      "icao_code": "UTSL",
      "airport_name": "Karshi Khanabad Airport"
    },
    {
      "city": "Khandyga",
      "country": "Russia",
      "IATA": "KDY",
      "airport_name": "Khandyga Airport"
    },
    {
      "city": "Khanty-Mansiysk",
      "country": "Russia",
      "IATA": "HMA",
      "icao_code": "USHH",
      "airport_name": "Khanty Mansiysk Airport"
    },
    {
      "city": "Khark Island",
      "country": "Iran",
      "IATA": "KHK",
      "airport_name": "Kharg Airport"
    },
    {
      "city": "Kharkov",
      "country": "Ukraine",
      "IATA": "HRK",
      "icao_code": "UKHH",
      "airport_name": "Kharkiv International Airport "
    },
    {
      "city": "Khartoum",
      "country": "Sudan",
      "IATA": "KRT",
      "icao_code": "HSSS",
      "airport_name": "Khartoum International Airport"
    },
    {
      "city": "Khasab",
      "country": "Oman",
      "IATA": "KHS",
      "icao_code": "OOKB",
      "airport_name": "Khasab Airport"
    },
    {
      "city": "Khatanga",
      "country": "Russia",
      "IATA": "HTG",
      "icao_code": "UOHH",
      "airport_name": "Khatanga Airport"
    },
    {
      "city": "Kherson",
      "country": "Ukraine",
      "IATA": "KHE",
      "icao_code": "UKOH",
      "airport_name": "Kherson International Airport"
    },
    {
      "city": "Khmeinitskiy",
      "country": "Ukraine",
      "IATA": "HMJ",
      "icao_code": "UKLH",
      "airport_name": "Khmelnitskiy Airport"
    },
    {
      "city": "Khon Kaen",
      "country": "Thailand",
      "IATA": "KKC",
      "icao_code": "VTUK",
      "airport_name": "Khon Kaen Airport"
    },
    {
      "city": "Khorram Abad",
      "country": "Iran",
      "IATA": "KHD",
      "icao_code": "OICK",
      "airport_name": "Khorramabad International Airport"
    },
    {
      "city": "Khost",
      "country": "Afghanistan",
      "IATA": "KHT",
      "airport_name": "Khost Airfield"
    },
    {
      "city": "Khovd",
      "country": "Mongolia",
      "IATA": "HVD",
      "icao_code": "ZMKD",
      "airport_name": "Khovd Airport"
    },
    {
      "city": "Khoy",
      "country": "Iran",
      "IATA": "KHY",
      "icao_code": "OITK",
      "airport_name": "Khoy Airport"
    },
    {
      "city": "Khudzhand",
      "country": "Tajikistan",
      "IATA": "LBD",
      "icao_code": "UTDL",
      "airport_name": "Khujand International Airport"
    },
    {
      "city": "Khuzdar",
      "country": "Pakistan",
      "IATA": "KDD",
      "icao_code": "OPKH",
      "airport_name": "Khuzdar Airport"
    },
    {
      "city": "Khwai River",
      "country": "Botswana",
      "IATA": "KHW",
      "icao_code": "FBKR",
      "airport_name": "Khwai River Lodge Airport"
    },
    {
      "city": "Kiana",
      "country": "United States",
      "IATA": "IAN",
      "icao_code": "PAIK",
      "airport_name": "Bob Baker Memorial Airport"
    },
    {
      "city": "Kiel",
      "country": "Germany",
      "IATA": "KEL",
      "icao_code": "EDHK",
      "airport_name": "Kiel Airport"
    },
    {
      "city": "Kieta",
      "country": "Papua New Guinea",
      "IATA": "KZF",
      "airport_name": "Kaintiba Airport"
    },
    {
      "city": "Kiev",
      "country": "Ukraine",
      "IATA": "GML",
      "icao_code": "UKKM",
      "airport_name": "Hostomel Airport"
    },
    {
      "city": "Kiev",
      "country": "Ukraine",
      "IATA": "IEV",
      "icao_code": "UKKK",
      "airport_name": "Kyiv International Airport(Zhuliany)"
    },
    {
      "city": "Kiev",
      "country": "Ukraine",
      "IATA": "KBP",
      "icao_code": "UKBB",
      "airport_name": "Boryspil International Airport"
    },
    {
      "city": "Kiffa",
      "country": "Mauritania",
      "IATA": "KFA",
      "icao_code": "GQNF",
      "airport_name": "Kiffa Airport"
    },
    {
      "city": "Kigali",
      "country": "Rwanda",
      "IATA": "KGL",
      "icao_code": "HRYR",
      "airport_name": "Kigali International Airport "
    },
    {
      "city": "Kigoma",
      "country": "Tanzania",
      "IATA": "TKQ",
      "icao_code": "HTKA",
      "airport_name": "Kigoma Airport"
    },
    {
      "city": "Kikai",
      "country": "Japan",
      "IATA": "KKX",
      "icao_code": "RJKI",
      "airport_name": "Kikai Airport"
    },
    {
      "city": "Kikori",
      "country": "Papua New Guinea",
      "IATA": "KRI",
      "icao_code": "AYKK",
      "airport_name": "Kikori Airport"
    },
    {
      "city": "Kikwit",
      "country": "Congo (Kinshasa)",
      "IATA": "KKW",
      "icao_code": "FZCA",
      "airport_name": "Kikwit Airport"
    },
    {
      "city": "Kilaguni",
      "country": "Kenya",
      "IATA": "ILU",
      "icao_code": "HKKL",
      "airport_name": "Kilaguni Airport"
    },
    {
      "city": "Kili Island",
      "country": "Marshall Islands",
      "IATA": "KIO",
      "airport_name": "Kili Airport "
    },
    {
      "city": "Kilimanjaro",
      "country": "Tanzania",
      "IATA": "JRO",
      "icao_code": "HTKJ",
      "airport_name": "Kilimanjaro International Airport"
    },
    {
      "city": "Kill Devil Hills",
      "country": "United States",
      "IATA": "FFA",
      "icao_code": "KFFA",
      "airport_name": "First Flight Airport"
    },
    {
      "city": "Killeen",
      "country": "United States",
      "IATA": "GRK",
      "icao_code": "KGRK",
      "airport_name": "Killeen-Fort Hood Regional Airport "
    },
    {
      "city": "Kilwa Masoko",
      "country": "Tanzania",
      "IATA": "KIY",
      "icao_code": "HTKI",
      "airport_name": "Kilwa Masoko Airport"
    },
    {
      "city": "Kimberley",
      "country": "South Africa",
      "IATA": "KIM",
      "icao_code": "FAKM",
      "airport_name": "Kimberley Airport"
    },
    {
      "city": "Kimmirut",
      "country": "Canada",
      "IATA": "YLC",
      "icao_code": "CYLC",
      "airport_name": "Kimmirut Airport"
    },
    {
      "city": "Kindersley",
      "country": "Canada",
      "IATA": "YKY",
      "icao_code": "CYKY",
      "airport_name": "Kindersley Regional Airport"
    },
    {
      "city": "Kindu",
      "country": "Congo (Kinshasa)",
      "IATA": "KND",
      "icao_code": "FZOA",
      "airport_name": "Kindu Airport"
    },
    {
      "city": "King Cove",
      "country": "United States",
      "IATA": "KVC",
      "icao_code": "PAVC",
      "airport_name": "King Cove Airport"
    },
    {
      "city": "King Island",
      "country": "Australia",
      "IATA": "KNS",
      "icao_code": "YKII",
      "airport_name": "King Island Airport"
    },
    {
      "city": "King Khalid Mil.city",
      "country": "Saudi Arabia",
      "IATA": "KMC",
      "icao_code": "OEKK",
      "airport_name": "King Khaled Military City Airport"
    },
    {
      "city": "King Salmon",
      "country": "United States",
      "IATA": "AKN",
      "icao_code": "PAKN",
      "airport_name": "King Salmon Airport"
    },
    {
      "city": "Kingfisher Lake",
      "country": "Canada",
      "IATA": "KIF",
      "icao_code": "CNM5",
      "airport_name": "Kingfisher Lake Airport"
    },
    {
      "city": "Kingscote",
      "country": "Australia",
      "IATA": "KGC",
      "icao_code": "YKSC",
      "airport_name": "Kingscote Airport"
    },
    {
      "city": "Kingston",
      "country": "Jamaica",
      "IATA": "KTP",
      "airport_name": "Tinson Pen Aerodrome"
    },
    {
      "city": "Kingston",
      "country": "Jamaica",
      "IATA": "KIN",
      "icao_code": "MKJP",
      "airport_name": "Norman Manley International Airport"
    },
    {
      "city": "Kingston",
      "country": "Canada",
      "IATA": "YGK",
      "icao_code": "CYGK",
      "airport_name": "Kingston/Norman Rogers Airport"
    },
    {
      "city": "Kingstown",
      "country": "Saint Vincent and the Grenadines",
      "IATA": "SVD",
      "icao_code": "TVSV",
      "airport_name": "E.T. Joshua Airport"
    },
    {
      "city": "Kingsville",
      "country": "United States",
      "IATA": "NQI",
      "icao_code": "KNQI",
      "airport_name": "Naval Air Station Kingsville"
    },
    {
      "city": "Kinloss",
      "country": "United Kingdom",
      "IATA": "FSS",
      "icao_code": "EGQK",
      "airport_name": "Kinloss Barracks"
    },
    {
      "city": "Kinmen",
      "country": "Taiwan",
      "IATA": "KNH",
      "icao_code": "RCBS",
      "airport_name": "Kinmen Airport"
    },
    {
      "city": "Kinshasa",
      "country": "Congo (Kinshasa)",
      "IATA": "NLO",
      "icao_code": "FZAB",
      "airport_name": "Ndolo Airport"
    },
    {
      "city": "Kinshasa",
      "country": "Congo (Kinshasa)",
      "IATA": "FIH",
      "icao_code": "FZAA",
      "airport_name": "N'Djili International Airport"
    },
    {
      "city": "Kinston",
      "country": "United States",
      "IATA": "ISO",
      "airport_name": "Kinston Regional Jetport"
    },
    {
      "city": "Kipnuk",
      "country": "United States",
      "IATA": "KPN",
      "icao_code": "PAKI",
      "airport_name": "Kipnuk Airport "
    },
    {
      "city": "Kirakira",
      "country": "Solomon Islands",
      "IATA": "IRA",
      "icao_code": "AGGK",
      "airport_name": "Kirakira Airport"
    },
    {
      "city": "Kirensk",
      "country": "Russia",
      "IATA": "KCK",
      "airport_name": "Fairfax Airport "
    },
    {
      "city": "Kiri",
      "country": "Congo (Kinshasa)",
      "IATA": "KRZ",
      "icao_code": "FZBT",
      "airport_name": "Kiri Airport "
    },
    {
      "city": "Kiritimati",
      "country": "Kiribati",
      "IATA": "CXI",
      "icao_code": "PLCH",
      "airport_name": "Cassidy International Airport"
    },
    {
      "city": "Kirkenes",
      "country": "Norway",
      "IATA": "KKN",
      "icao_code": "ENKR",
      "airport_name": "Kirkenes Airport, Høybuktmoen"
    },
    {
      "city": "Kirkland Lake",
      "country": "Canada",
      "IATA": "YKX",
      "icao_code": "CYKX",
      "airport_name": "Kirkland Lake Airport"
    },
    {
      "city": "Kirksville",
      "country": "United States",
      "IATA": "IRK",
      "icao_code": "KIRK",
      "airport_name": "Kirksville Regional Airport"
    },
    {
      "city": "Kirkuk",
      "country": "Iraq",
      "IATA": "KIK",
      "icao_code": "ORKK",
      "airport_name": " Al Hurriya Air Base "
    },
    {
      "city": "Kirkwall",
      "country": "United Kingdom",
      "IATA": "KOI",
      "icao_code": "EGPA",
      "airport_name": "Kirkwall Airport"
    },
    {
      "city": "Kirov",
      "country": "Russia",
      "IATA": "KVX",
      "icao_code": "USKK",
      "airport_name": "Pobedilovo Airport"
    },
    {
      "city": "Kirovograd",
      "country": "Ukraine",
      "IATA": "KGO",
      "icao_code": "UKKG",
      "airport_name": "Kirovograd Airport"
    },
    {
      "city": "Kiruna",
      "country": "Sweden",
      "IATA": "KRN",
      "icao_code": "ESNQ",
      "airport_name": "Kiruna Airport"
    },
    {
      "city": "Kisangani",
      "country": "Congo (Kinshasa)",
      "IATA": "FKI",
      "icao_code": "FZIC",
      "airport_name": "Bangoka International Airport"
    },
    {
      "city": "Kish Island",
      "country": "Iran",
      "IATA": "KIH",
      "icao_code": "OIBK",
      "airport_name": "Kish International Airport"
    },
    {
      "city": "Kismayu",
      "country": "Somalia",
      "IATA": "KMU",
      "icao_code": "HCMK",
      "airport_name": "Kismayo Airport"
    },
    {
      "city": "Kissidougou",
      "country": "Guinea",
      "IATA": "KSI",
      "icao_code": "GUKU",
      "airport_name": "Kissidougou Airport"
    },
    {
      "city": "Kissimmee",
      "country": "United States",
      "IATA": "ISM",
      "icao_code": "KISM",
      "airport_name": "Kissimmee Municipal  Airport"
    },
    {
      "city": "Kisumu",
      "country": "Kenya",
      "IATA": "KIS",
      "icao_code": "HKKI",
      "airport_name": "Kisumu International Airport"
    },
    {
      "city": "Kitadaito",
      "country": "Japan",
      "IATA": "KTD",
      "icao_code": "RORK",
      "airport_name": "Kitadaito Airport"
    },
    {
      "city": "Kitakyushu",
      "country": "Japan",
      "IATA": "KKJ",
      "icao_code": "RJFR",
      "airport_name": "Kokura Airport"
    },
    {
      "city": "Kitale",
      "country": "Kenya",
      "IATA": "KTL",
      "icao_code": "HKKT",
      "airport_name": "Kitale Airport"
    },
    {
      "city": "Kitee",
      "country": "Finland",
      "IATA": "KTQ",
      "icao_code": "EFIT",
      "airport_name": "Kitee Airport"
    },
    {
      "city": "Kithira",
      "country": "Greece",
      "IATA": "KIT",
      "icao_code": "LGKC",
      "airport_name": "Kithira Island National Airport"
    },
    {
      "city": "Kitsissuarsuit",
      "country": "Greenland",
      "IATA": "QJE",
      "airport_name": "Kitsissuarsuit Heliport"
    },
    {
      "city": "Kittila",
      "country": "Finland",
      "IATA": "KTT",
      "icao_code": "EFKT",
      "airport_name": "Kittilä Airport"
    },
    {
      "city": "Kitzingen",
      "country": "Germany",
      "IATA": "KZG",
      "icao_code": "ETIN",
      "airport_name": "Kitzingen Airport"
    },
    {
      "city": "Kiunga",
      "country": "Papua New Guinea",
      "IATA": "UNG",
      "icao_code": "AYKI",
      "airport_name": "Kiunga Airport"
    },
    {
      "city": "Kivalina",
      "country": "United States",
      "IATA": "KVL",
      "icao_code": "PAVL",
      "airport_name": "Kivalina Airport"
    },
    {
      "city": "Klagenfurt",
      "country": "Austria",
      "IATA": "KLU",
      "icao_code": "LOWK",
      "airport_name": "Klagenfurt Airport "
    },
    {
      "city": "Klaipeda",
      "country": "Lithuania",
      "IATA": "KLJ",
      "airport_name": "Klaipeda Airport"
    },
    {
      "city": "Klamath Falls",
      "country": "United States",
      "IATA": "LMT",
      "icao_code": "KLMT",
      "airport_name": "Crater Lake- Klamath Regional Airport"
    },
    {
      "city": "Klawock",
      "country": "United States",
      "IATA": "KLW",
      "icao_code": "PAKW",
      "airport_name": "Klawock Airport "
    },
    {
      "city": "Kleiat",
      "country": "Lebanon",
      "IATA": "KYE",
      "icao_code": "OLKA",
      "airport_name": "Rene Mouawad Air Base "
    },
    {
      "city": "Kleinsee",
      "country": "South Africa",
      "IATA": "KLZ",
      "airport_name": "Kleinzee Airport"
    },
    {
      "city": "Klerksdorp",
      "country": "South Africa",
      "IATA": "KXE",
      "icao_code": "FAKD",
      "airport_name": "Klerksdorp Airport "
    },
    {
      "city": "Knobnoster",
      "country": "United States",
      "IATA": "SZL",
      "airport_name": "Whiteman Air Force Base"
    },
    {
      "city": "Knoxville",
      "country": "United States",
      "IATA": "TYS",
      "icao_code": "KTYS",
      "airport_name": "McGhee Tyson Airport"
    },
    {
      "city": "Ko Samui",
      "country": "Thailand",
      "IATA": "USM",
      "icao_code": "VTSM",
      "airport_name": "Samui International Airport"
    },
    {
      "city": "Kobe",
      "country": "Japan",
      "IATA": "UKB",
      "icao_code": "RJBE",
      "airport_name": "Kobe Airport"
    },
    {
      "city": "Kobuk",
      "country": "United States",
      "IATA": "OBU",
      "icao_code": "PAOB",
      "airport_name": "Kobuk Airport"
    },
    {
      "city": "Kochi",
      "country": "India",
      "IATA": "COK",
      "icao_code": "VOCI",
      "airport_name": "Cochin International Airport "
    },
    {
      "city": "Kochi",
      "country": "Japan",
      "IATA": "KCZ",
      "icao_code": "RJOK",
      "airport_name": "Kōchi Airport"
    },
    {
      "city": "Kodiak",
      "country": "United States",
      "IATA": "ADQ",
      "icao_code": "PADQ",
      "airport_name": "Kodiak Airport"
    },
    {
      "city": "Koethen",
      "country": "Germany",
      "IATA": "KOQ",
      "airport_name": "Koethen Airport"
    },
    {
      "city": "Kogalym",
      "country": "Russia",
      "IATA": "KGP",
      "icao_code": "USRK",
      "airport_name": "Kogalym International Airport"
    },
    {
      "city": "Koggala",
      "country": "Sri Lanka",
      "IATA": "KCT",
      "icao_code": "VCCK",
      "airport_name": "Koggala Airport"
    },
    {
      "city": "Kokomo",
      "country": "United States",
      "IATA": "OKK",
      "icao_code": "KOKK",
      "airport_name": "Kokomo Municipal Airport"
    },
    {
      "city": "Kokonau",
      "country": "Indonesia",
      "IATA": "KOX",
      "icao_code": "WABN",
      "airport_name": "Kokonao Airport"
    },
    {
      "city": "Kokshetau",
      "country": "Kazakhstan",
      "IATA": "KOV",
      "icao_code": "UACK",
      "airport_name": "Kokshetau Airport"
    },
    {
      "city": "Kolda",
      "country": "Senegal",
      "IATA": "KDA",
      "icao_code": "GOGK",
      "airport_name": "Kolda North Airport"
    },
    {
      "city": "Kolhapur",
      "country": "India",
      "IATA": "KLH",
      "icao_code": "VAKP",
      "airport_name": "Kolhapur Airport"
    },
    {
      "city": "Koliganek",
      "country": "United States",
      "IATA": "KGK",
      "icao_code": "PAJZ",
      "airport_name": "Koliganek Airport "
    },
    {
      "city": "Kolkata",
      "country": "India",
      "IATA": "CCU",
      "icao_code": "VECC",
      "airport_name": "Netaji Subhas Chandra Bose International Airport"
    },
    {
      "city": "Kolwezi",
      "country": "Congo (Kinshasa)",
      "IATA": "KWZ",
      "icao_code": "FZQM",
      "airport_name": "Kolwezi Airport"
    },
    {
      "city": "Kompong Chnang",
      "country": "Cambodia",
      "IATA": "KZC",
      "icao_code": "VDKH",
      "airport_name": "Kampong Chhnang Airport"
    },
    {
      "city": "Komsomolsk-on-Amur",
      "country": "Russia",
      "IATA": "KXK",
      "icao_code": "UHKK",
      "airport_name": "Komsomolsk-on-Amur Airport"
    },
    {
      "city": "Kona",
      "country": "United States",
      "IATA": "KOA",
      "icao_code": "PHKO",
      "airport_name": "Kona International Airport"
    },
    {
      "city": "Kone",
      "country": "New Caledonia",
      "IATA": "KNQ",
      "icao_code": "NWWD",
      "airport_name": "Koné Airport"
    },
    {
      "city": "Kongiganak",
      "country": "United States",
      "IATA": "KKH",
      "icao_code": "PADY",
      "airport_name": "Kongiganak Airport "
    },
    {
      "city": "Kongolo",
      "country": "Congo (Kinshasa)",
      "IATA": "KOO",
      "icao_code": "FZRQ",
      "airport_name": "Kongolo Airport"
    },
    {
      "city": "Konya",
      "country": "Turkey",
      "IATA": "KYA",
      "icao_code": "LTAN",
      "airport_name": "Konya Airport"
    },
    {
      "city": "Kooddoo",
      "country": "Maldives",
      "IATA": "GKK",
      "icao_code": "VRMO",
      "airport_name": "Kooddoo Airport"
    },
    {
      "city": "Korhogo",
      "country": "Cote d'Ivoire",
      "IATA": "HGO",
      "icao_code": "DIKO",
      "airport_name": "Korhogo Airport"
    },
    {
      "city": "Korla",
      "country": "China",
      "IATA": "KRL",
      "icao_code": "ZWKL",
      "airport_name": "Korla Airport"
    },
    {
      "city": "Koro Island",
      "country": "Fiji",
      "IATA": "KXF",
      "icao_code": "NFNO",
      "airport_name": "Koro Airport"
    },
    {
      "city": "Kortrijk-vevelgem",
      "country": "Belgium",
      "IATA": "KJK",
      "icao_code": "EBKT",
      "airport_name": "Flanders International Airport"
    },
    {
      "city": "Kos",
      "country": "Greece",
      "IATA": "KGS",
      "icao_code": "LGKO",
      "airport_name": "Kos Island International Airport"
    },
    {
      "city": "Kosice",
      "country": "Slovakia",
      "IATA": "KSC",
      "icao_code": "LZKZ",
      "airport_name": "Košice International Airport "
    },
    {
      "city": "Kosrae",
      "country": "Micronesia",
      "IATA": "KSA",
      "icao_code": "PTSA",
      "airport_name": "Kosrae International Airport "
    },
    {
      "city": "Kostanay",
      "country": "Kazakhstan",
      "IATA": "KSN",
      "icao_code": "UAUU",
      "airport_name": "Kostanay International Airport "
    },
    {
      "city": "Kostroma",
      "country": "Russia",
      "IATA": "KMW",
      "icao_code": "UUBD",
      "airport_name": "Kostroma Airport"
    },
    {
      "city": "Kota",
      "country": "India",
      "IATA": "KTU",
      "icao_code": "VIKO",
      "airport_name": "Kota Airport"
    },
    {
      "city": "Kota Bahru",
      "country": "Malaysia",
      "IATA": "KBR",
      "icao_code": "WMKC",
      "airport_name": "Sultan Ismail Petra Airport"
    },
    {
      "city": "Kota Kinabalu",
      "country": "Malaysia",
      "IATA": "BKI",
      "icao_code": "WBKK",
      "airport_name": "Kota Kinabalu International Airport"
    },
    {
      "city": "Kotakoli",
      "country": "Congo (Kinshasa)",
      "IATA": "KLI",
      "icao_code": "FZFP",
      "airport_name": "Kota Koli Airport"
    },
    {
      "city": "Kotlas",
      "country": "Russia",
      "IATA": "KSZ",
      "icao_code": "ULKK",
      "airport_name": "Kotlas Airport"
    },
    {
      "city": "Kotlik",
      "country": "United States",
      "IATA": "KOT",
      "icao_code": "PFKO",
      "airport_name": "Kotlik Airport "
    },
    {
      "city": "Kotzebue",
      "country": "United States",
      "IATA": "OTZ",
      "icao_code": "PAOT",
      "airport_name": "Ralph Wien Memorial Airport"
    },
    {
      "city": "Koulamoutou",
      "country": "Gabon",
      "IATA": "KOU",
      "icao_code": "FOGK",
      "airport_name": "Koulamoutou Airport"
    },
    {
      "city": "Koumac",
      "country": "New Caledonia",
      "IATA": "KOC",
      "icao_code": "NWWK",
      "airport_name": "Koumac Airport"
    },
    {
      "city": "Kowanyama",
      "country": "Australia",
      "IATA": "KWM",
      "icao_code": "YKOW",
      "airport_name": "Kowanyama Airport"
    },
    {
      "city": "Koyuk",
      "country": "United States",
      "IATA": "KKA",
      "icao_code": "PAKK",
      "airport_name": "Koyuk Alfred Adams Airport"
    },
    {
      "city": "Koyukuk",
      "country": "United States",
      "IATA": "KYU",
      "icao_code": "PFKU",
      "airport_name": "Koyukuk Airport"
    },
    {
      "city": "Kozani",
      "country": "Greece",
      "IATA": "KZI",
      "icao_code": "LGKZ",
      "airport_name": "Kozani National Airport "
    },
    {
      "city": "Krabi",
      "country": "Thailand",
      "IATA": "KBV",
      "icao_code": "VTSG",
      "airport_name": "Krabi International Airport"
    },
    {
      "city": "Krakow",
      "country": "Poland",
      "IATA": "KRK",
      "icao_code": "EPKK",
      "airport_name": "John Paul II International Airport Kraków–Balice"
    },
    {
      "city": "Kralendijk",
      "country": "Netherlands Antilles",
      "IATA": "BON",
      "icao_code": "TNCB",
      "airport_name": "Flamingo International Airport"
    },
    {
      "city": "Kramatorsk",
      "country": "Ukraine",
      "IATA": "KRQ",
      "icao_code": "UKCK",
      "airport_name": "Kramatorsk Airport"
    },
    {
      "city": "Kramfors",
      "country": "Sweden",
      "IATA": "KRF",
      "icao_code": "ESNK",
      "airport_name": "Höga Kusten Airport"
    },
    {
      "city": "Krasnodar",
      "country": "Russia",
      "IATA": "KRR",
      "icao_code": "URKK",
      "airport_name": "Krasnodar International Airport"
    },
    {
      "city": "Krasnovodsk",
      "country": "Turkmenistan",
      "IATA": "KRW",
      "icao_code": "UTAK",
      "airport_name": "Turkmenbashi International Airport"
    },
    {
      "city": "Krasnoyarsk",
      "country": "Russia",
      "IATA": "KJA",
      "icao_code": "UNKL",
      "airport_name": "Yemelyanovo International Airport"
    },
    {
      "city": "Kratie",
      "country": "Cambodia",
      "IATA": "KTI",
      "icao_code": "VDKT",
      "airport_name": "Kratie Airport"
    },
    {
      "city": "Kristiansand",
      "country": "Norway",
      "IATA": "KRS",
      "icao_code": "ENCN",
      "airport_name": "Kristiansand Airport, Kjevik"
    },
    {
      "city": "Kristianstad",
      "country": "Sweden",
      "IATA": "KID",
      "icao_code": "ESMK",
      "airport_name": "Kristianstad Airport"
    },
    {
      "city": "Kristiansund",
      "country": "Norway",
      "IATA": "KSU",
      "icao_code": "ENKB",
      "airport_name": "Kristiansund Airport, Kvernberget"
    },
    {
      "city": "Krivoy Rog",
      "country": "Ukraine",
      "IATA": "KWG",
      "icao_code": "UKDR",
      "airport_name": "Kryvyi Rih International Airport"
    },
    {
      "city": "Kruunupyy",
      "country": "Finland",
      "IATA": "KOK",
      "icao_code": "EFKK",
      "airport_name": "Kokkola-Pietarsaari Airport"
    },
    {
      "city": "Kuala Lumpur",
      "country": "Malaysia",
      "IATA": "SZB",
      "icao_code": "WMSA",
      "airport_name": "Sultan Abdul Aziz Shah Airport"
    },
    {
      "city": "Kuala Lumpur",
      "country": "Malaysia",
      "IATA": "KUL",
      "icao_code": "WMKK",
      "airport_name": "Kuala Lumpur International Airport"
    },
    {
      "city": "Kuala Terengganu",
      "country": "Malaysia",
      "IATA": "TGG",
      "icao_code": "WMKN",
      "airport_name": "Sultan Mahmud Airport"
    },
    {
      "city": "Kuantan",
      "country": "Malaysia",
      "IATA": "KUA",
      "icao_code": "WMKD",
      "airport_name": "Sultan Haji Ahmad Shah Airport "
    },
    {
      "city": "Kubin",
      "country": "Australia",
      "IATA": "KUG",
      "icao_code": "YKUB",
      "airport_name": "Kubin Airport"
    },
    {
      "city": "Kuching",
      "country": "Malaysia",
      "IATA": "KCH",
      "icao_code": "WBGG",
      "airport_name": "Kuching International Airport"
    },
    {
      "city": "Kudat",
      "country": "Malaysia",
      "IATA": "KUD",
      "icao_code": "WBKT",
      "airport_name": "Kudat Airport"
    },
    {
      "city": "Kufra",
      "country": "Libya",
      "IATA": "AKF",
      "icao_code": "HLKF",
      "airport_name": "Kufra Airport"
    },
    {
      "city": "Kuito",
      "country": "Angola",
      "IATA": "SVP",
      "icao_code": "FNKU",
      "airport_name": "Kuito Airport"
    },
    {
      "city": "Kulu",
      "country": "India",
      "IATA": "KUU",
      "icao_code": "VIBR",
      "airport_name": "Kullu-Manali Airport "
    },
    {
      "city": "Kulyab",
      "country": "Tajikistan",
      "IATA": "TJU",
      "icao_code": "UTDK",
      "airport_name": "Kulob Airport"
    },
    {
      "city": "Kumamoto",
      "country": "Japan",
      "IATA": "KMJ",
      "icao_code": "RJFT",
      "airport_name": "Kumamoto Airport"
    },
    {
      "city": "Kumasi",
      "country": "Ghana",
      "IATA": "KMS",
      "icao_code": "DGSI",
      "airport_name": "Kumasi International Airport"
    },
    {
      "city": "Kumejima",
      "country": "Japan",
      "IATA": "UEO",
      "icao_code": "ROKJ",
      "airport_name": "Kumejima Airport"
    },
    {
      "city": "Kundiawa",
      "country": "Papua New Guinea",
      "IATA": "CMU",
      "icao_code": "AYCH",
      "airport_name": "Chimbu Airport"
    },
    {
      "city": "Kunduz",
      "country": "Afghanistan",
      "IATA": "UND",
      "icao_code": "OAUZ",
      "airport_name": "Kunduz Airport"
    },
    {
      "city": "Kunming",
      "country": "China",
      "IATA": "KMG",
      "icao_code": "ZPPP",
      "airport_name": "Kunming Changshui International Airport"
    },
    {
      "city": "Kunovice",
      "country": "Czech Republic",
      "IATA": "UHE",
      "icao_code": "LKKU",
      "airport_name": "Kunovice Airport"
    },
    {
      "city": "Kunsan",
      "country": "South Korea",
      "IATA": "KUV",
      "icao_code": "RKJK",
      "airport_name": "Gunsan Airport"
    },
    {
      "city": "Kununurra",
      "country": "Australia",
      "IATA": "KNX",
      "icao_code": "YPKU",
      "airport_name": "East Kimberley Regional Airport"
    },
    {
      "city": "Kuopio",
      "country": "Finland",
      "IATA": "KUO",
      "icao_code": "EFKU",
      "airport_name": "Kuopio Airport"
    },
    {
      "city": "Kupang",
      "country": "Indonesia",
      "IATA": "KOE",
      "icao_code": "WRKK",
      "airport_name": "Eltari Airport"
    },
    {
      "city": "Kuparuk",
      "country": "United States",
      "IATA": "UUK",
      "airport_name": "Ugnu-Kuparuk Airport"
    },
    {
      "city": "Kuqa",
      "country": "China",
      "IATA": "KCA",
      "icao_code": "ZWKC",
      "airport_name": "Kuqa Qiuci Airport"
    },
    {
      "city": "Kuressaare",
      "country": "Estonia",
      "IATA": "URE",
      "icao_code": "EEKE",
      "airport_name": "Kuressaare Airport"
    },
    {
      "city": "Kurgan",
      "country": "Russia",
      "IATA": "KRO",
      "icao_code": "USUU",
      "airport_name": "Kurgan Airport"
    },
    {
      "city": "Kurgan Tyube",
      "country": "Tajikistan",
      "IATA": "KQT",
      "icao_code": "UTDT",
      "airport_name": "Qurghonteppa International Airport"
    },
    {
      "city": "Kuria",
      "country": "Kiribati",
      "IATA": "KUC",
      "airport_name": "Kuria Airport"
    },
    {
      "city": "Kursk",
      "country": "Russia",
      "IATA": "URS",
      "icao_code": "UUOK",
      "airport_name": "Kursk Vostochny Airport"
    },
    {
      "city": "Kuruman",
      "country": "South Africa",
      "IATA": "KMH",
      "icao_code": "FAKU",
      "airport_name": "Johan Pienaar Airport"
    },
    {
      "city": "Kushiro",
      "country": "Japan",
      "IATA": "KUH",
      "icao_code": "RJCK",
      "airport_name": "Kushiro Airport"
    },
    {
      "city": "Kutahya",
      "country": "Turkey",
      "IATA": "KZR",
      "airport_name": "Zafer Airport"
    },
    {
      "city": "Kutaisi",
      "country": "Georgia",
      "IATA": "KUT",
      "icao_code": "UGKO",
      "airport_name": "David the Builder Kutaisi International Airport"
    },
    {
      "city": "Kuujjuarapik",
      "country": "Canada",
      "IATA": "YGW",
      "icao_code": "CYGW",
      "airport_name": "Kuujjuarapik Airport"
    },
    {
      "city": "Kuusamo",
      "country": "Finland",
      "IATA": "KAO",
      "icao_code": "EFKS",
      "airport_name": "Kuusamo Airport"
    },
    {
      "city": "Kuwait",
      "country": "Kuwait",
      "IATA": "KWI",
      "icao_code": "OKBK",
      "airport_name": "Kuwait International Airport"
    },
    {
      "city": "Kwajalein",
      "country": "Marshall Islands",
      "IATA": "KWA",
      "icao_code": "PKWA",
      "airport_name": "Bucholz Army Airfield"
    },
    {
      "city": "Kwangju",
      "country": "South Korea",
      "IATA": "KWJ",
      "icao_code": "RKJJ",
      "airport_name": "Gwangju Airport"
    },
    {
      "city": "Kwethluk",
      "country": "United States",
      "IATA": "KWT",
      "icao_code": "PFKW",
      "airport_name": "Kwethluk Airport"
    },
    {
      "city": "Kwigillingok",
      "country": "United States",
      "IATA": "KWK",
      "icao_code": "PAGG",
      "airport_name": "Kwigillingok Airport"
    },
    {
      "city": "Kyaukpyu",
      "country": "Burma",
      "IATA": "KYP",
      "icao_code": "VYKP",
      "airport_name": "Kyaukpyu Airport"
    },
    {
      "city": "Kyzyl",
      "country": "Russia",
      "IATA": "KYZ",
      "icao_code": "UNKY",
      "airport_name": "Kyzyl Airport"
    },
    {
      "city": "Kzyl-Orda",
      "country": "Kazakhstan",
      "IATA": "KZO",
      "icao_code": "UAOO",
      "airport_name": "Kyzylorda Airport"
    },
    {
      "city": "LINFEN",
      "country": "China",
      "IATA": "LFQ",
      "icao_code": "ZBLF",
      "airport_name": "Linfen Qiaoli Airport"
    },
    {
      "city": "La Baule",
      "country": "France",
      "IATA": "LBY",
      "icao_code": "LFRE",
      "airport_name": "Aerodrome of La Baule-Escoublac"
    },
    {
      "city": "La Ceiba",
      "country": "Honduras",
      "IATA": "LCE",
      "icao_code": "MHLC",
      "airport_name": "Golosón International Airport"
    },
    {
      "city": "La Coloma",
      "country": "Cuba",
      "IATA": "LCL",
      "icao_code": "MULM",
      "airport_name": "La Coloma Airport"
    },
    {
      "city": "La Coruna",
      "country": "Spain",
      "IATA": "LCG",
      "icao_code": "LECO",
      "airport_name": "A Coruña Airport"
    },
    {
      "city": "La Crosse",
      "country": "United States",
      "IATA": "LSE",
      "icao_code": "KLSE",
      "airport_name": "La Crosse Regional Airport"
    },
    {
      "city": "La Dorada",
      "country": "Colombia",
      "IATA": "PAL",
      "airport_name": "Captain Germán Olano Moreno Air Base"
    },
    {
      "city": "La Fortuna/San Carlos",
      "country": "Costa Rica",
      "IATA": "FON",
      "icao_code": "MRAN",
      "airport_name": "La Fortuna Arenal Airport"
    },
    {
      "city": "La Fria",
      "country": "Venezuela",
      "IATA": "LFR",
      "icao_code": "SVLF",
      "airport_name": "La Fria Airport"
    },
    {
      "city": "La Gomera",
      "country": "Spain",
      "IATA": "GMZ",
      "icao_code": "GCGM",
      "airport_name": "La Gomera Airport"
    },
    {
      "city": "La Grande",
      "country": "United States",
      "IATA": "LGD",
      "icao_code": "KLGD",
      "airport_name": "La Grande/Union County Airport"
    },
    {
      "city": "La Grande Riviere",
      "country": "Canada",
      "IATA": "YGL",
      "icao_code": "CYGL",
      "airport_name": "La Grande Rivière  Airport"
    },
    {
      "city": "La Grande-4",
      "country": "Canada",
      "IATA": "YAH",
      "airport_name": "La Grande-4 Airport"
    },
    {
      "city": "La Isabela",
      "country": "Dominican Republic",
      "IATA": "JBQ",
      "icao_code": "MDJB",
      "airport_name": "La Isabela International Airport"
    },
    {
      "city": "La Mina",
      "country": "Colombia",
      "IATA": "MCJ",
      "icao_code": "SKLM",
      "airport_name": "La Mina Airport"
    },
    {
      "city": "La Môle",
      "country": "France",
      "IATA": "LTT",
      "icao_code": "LFTZ",
      "airport_name": "La Môle – Saint-Tropez Airport"
    },
    {
      "city": "La Palma",
      "country": "Panama",
      "IATA": "PLP",
      "icao_code": "MPLP",
      "airport_name": "Captain Ramon Xatruch Airport"
    },
    {
      "city": "La Paz",
      "country": "Bolivia",
      "IATA": "LPB",
      "icao_code": "SLLP",
      "airport_name": "El Alto International Airport"
    },
    {
      "city": "La Paz",
      "country": "Mexico",
      "IATA": "LAP",
      "icao_code": "MMLP",
      "airport_name": "Manuel Márquez de León International Airport"
    },
    {
      "city": "La Pedrera",
      "country": "Colombia",
      "IATA": "LPD",
      "icao_code": "SKLP",
      "airport_name": "La Pedrera Airport"
    },
    {
      "city": "La Plata",
      "country": "Argentina",
      "IATA": "LPG",
      "icao_code": "SADL",
      "airport_name": "Durango–La Plata County Airport"
    },
    {
      "city": "La Rioja",
      "country": "Argentina",
      "IATA": "IRJ",
      "icao_code": "SANL",
      "airport_name": "Capitán Vicente Almandos Almonacid Airport"
    },
    {
      "city": "La Roche-sur-yon",
      "country": "France",
      "IATA": "EDM",
      "icao_code": "LFRI",
      "airport_name": "La Roche-sur-Yon Airport"
    },
    {
      "city": "La Rochelle",
      "country": "France",
      "IATA": "LRH",
      "icao_code": "LFBH",
      "airport_name": "La Rochelle – Île de Ré Airport"
    },
    {
      "city": "La Romaine",
      "country": "Canada",
      "IATA": "ZGS",
      "airport_name": "La Romaine Airport"
    },
    {
      "city": "La Romana",
      "country": "Dominican Republic",
      "IATA": "LRM",
      "icao_code": "MDLR",
      "airport_name": "La Romana International Airport "
    },
    {
      "city": "La Ronge",
      "country": "Canada",
      "IATA": "YVC",
      "icao_code": "CYVC",
      "airport_name": "La Ronge Airport"
    },
    {
      "city": "La Serena",
      "country": "Chile",
      "IATA": "LSC",
      "icao_code": "SCSE",
      "airport_name": "La Florida Airport"
    },
    {
      "city": "La Tabatière",
      "country": "Canada",
      "IATA": "ZLT",
      "icao_code": "CTU5",
      "airport_name": "La Tabatière Airport"
    },
    {
      "city": "La Toma (Catamayo)",
      "country": "Ecuador",
      "IATA": "LOH",
      "icao_code": "SELO",
      "airport_name": "Ciudad de Catamayo Airport"
    },
    {
      "city": "La Verne",
      "country": "United States",
      "IATA": "POC",
      "airport_name": "Brackett Field"
    },
    {
      "city": "LaGrange",
      "country": "United States",
      "IATA": "LGC",
      "icao_code": "KLGC",
      "airport_name": "LaGrange-Callaway Airport"
    },
    {
      "city": "Laage",
      "country": "Germany",
      "IATA": "RLG",
      "icao_code": "ETNL",
      "airport_name": "Rostock-Laage Airport"
    },
    {
      "city": "Laamu Atoll",
      "country": "Maldives",
      "IATA": "KDO",
      "icao_code": "VRMT",
      "airport_name": "Kadhdhoo Airport"
    },
    {
      "city": "Labe",
      "country": "Guinea",
      "IATA": "LEK",
      "icao_code": "GULB",
      "airport_name": "Tata Airport "
    },
    {
      "city": "Labrea",
      "country": "Brazil",
      "IATA": "LBR",
      "icao_code": "SWLB",
      "airport_name": "Lábrea Airport"
    },
    {
      "city": "Labuan",
      "country": "Malaysia",
      "IATA": "LBU",
      "icao_code": "WBKL",
      "airport_name": "Labuan Airport"
    },
    {
      "city": "Labuhan Bajo",
      "country": "Indonesia",
      "IATA": "LBJ",
      "icao_code": "WRKO",
      "airport_name": "Komodo Airport "
    },
    {
      "city": "Lac Brochet",
      "country": "Canada",
      "IATA": "XLB",
      "airport_name": "Lac Brochet Airport"
    },
    {
      "city": "Ladysmith",
      "country": "South Africa",
      "IATA": "LAY",
      "icao_code": "FALY",
      "airport_name": "Ladysmith Airport"
    },
    {
      "city": "Laeso",
      "country": "Denmark",
      "IATA": "BYR",
      "airport_name": "Læsø Airport"
    },
    {
      "city": "Lafayette",
      "country": "United States",
      "IATA": "LAF",
      "airport_name": "Purdue University Airport"
    },
    {
      "city": "Lafayette",
      "country": "United States",
      "IATA": "LFT",
      "icao_code": "KLFT",
      "airport_name": "Lafayette Regional Airport"
    },
    {
      "city": "Laghouat",
      "country": "Algeria",
      "IATA": "LOO",
      "icao_code": "DAUL",
      "airport_name": "L'Mekrareg Airport "
    },
    {
      "city": "Lagos",
      "country": "Nigeria",
      "IATA": "LOS",
      "icao_code": "DNMM",
      "airport_name": "Murtala Mohammed International Airport"
    },
    {
      "city": "Lahad Datu",
      "country": "Malaysia",
      "IATA": "LDU",
      "icao_code": "WBKD",
      "airport_name": "Lahad Datu Airport"
    },
    {
      "city": "Lahania-kapalua",
      "country": "United States",
      "IATA": "JHM",
      "icao_code": "PHJH",
      "airport_name": "Kapalua Airport"
    },
    {
      "city": "Lahore",
      "country": "Pakistan",
      "IATA": "LHE",
      "icao_code": "OPLA",
      "airport_name": "Allama Iqbal International Airport"
    },
    {
      "city": "Lahr",
      "country": "Germany",
      "IATA": "LHA",
      "icao_code": "EDTL",
      "airport_name": "Flughafen Lahr"
    },
    {
      "city": "Lajes",
      "country": "Brazil",
      "IATA": "LAJ",
      "icao_code": "SBLJ",
      "airport_name": "Lages Airport"
    },
    {
      "city": "Lajes (terceira Island)",
      "country": "Portugal",
      "IATA": "TER",
      "icao_code": "LPLA",
      "airport_name": "Lajes Field"
    },
    {
      "city": "Lake Charles",
      "country": "United States",
      "IATA": "LCH",
      "icao_code": "KLCH",
      "airport_name": "Lake Charles Regional Airport"
    },
    {
      "city": "Lake City",
      "country": "United States",
      "IATA": "LCQ",
      "icao_code": "KLCQ",
      "airport_name": "Lake City Gateway Airport"
    },
    {
      "city": "Lake Evella",
      "country": "Australia",
      "IATA": "LEL",
      "icao_code": "YLEV",
      "airport_name": "Lake Evella Airport"
    },
    {
      "city": "Lake Havasu City",
      "country": "United States",
      "IATA": "HII",
      "icao_code": "KHII",
      "airport_name": "Lake Havasu City Airport"
    },
    {
      "city": "Lake Macquarie",
      "country": "Australia",
      "IATA": "BEO",
      "icao_code": "YPEC",
      "airport_name": "Belmont Airport"
    },
    {
      "city": "Lake Manyara",
      "country": "Tanzania",
      "IATA": "LKY",
      "icao_code": "HTLM",
      "airport_name": "Lake Manyara Airport"
    },
    {
      "city": "Lake Minchumina",
      "country": "United States",
      "IATA": "LMA",
      "airport_name": "Minchumina Airport"
    },
    {
      "city": "Lake Placid",
      "country": "United States",
      "IATA": "LKP",
      "icao_code": "KLKP",
      "airport_name": "Lake Placid Airport"
    },
    {
      "city": "Lakeba Island",
      "country": "Fiji",
      "IATA": "LKB",
      "icao_code": "NFNK",
      "airport_name": "Lakeba Island Airport"
    },
    {
      "city": "Lakehurst",
      "country": "United States",
      "IATA": "NEL",
      "airport_name": "Lakehurst Maxfield Field"
    },
    {
      "city": "Lakeland",
      "country": "United States",
      "IATA": "LAL",
      "icao_code": "KLAL",
      "airport_name": "Lakeland Linder Regional Airport"
    },
    {
      "city": "Lakenheath",
      "country": "United Kingdom",
      "IATA": "LKZ",
      "airport_name": "RAF Lakenheath"
    },
    {
      "city": "Lakeview",
      "country": "United States",
      "IATA": "LKV",
      "icao_code": "KLKV",
      "airport_name": "Lake County Airport"
    },
    {
      "city": "Lakselv",
      "country": "Norway",
      "IATA": "LKL",
      "icao_code": "ENNA",
      "airport_name": "Lakselv Airport, Banak"
    },
    {
      "city": "Lalibella",
      "country": "Ethiopia",
      "IATA": "LLI",
      "icao_code": "HALL",
      "airport_name": "Lalibela Airport"
    },
    {
      "city": "Lamap",
      "country": "Vanuatu",
      "IATA": "LPM",
      "icao_code": "NVSL",
      "airport_name": "Malekoula Airport"
    },
    {
      "city": "Lamar",
      "country": "United States",
      "IATA": "LAA",
      "icao_code": "KLAA",
      "airport_name": "Lamar Municipal Airport"
    },
    {
      "city": "Lambarene",
      "country": "Gabon",
      "IATA": "LBQ",
      "icao_code": "FOGR",
      "airport_name": "Lambaréné Airport"
    },
    {
      "city": "Lambasa",
      "country": "Fiji",
      "IATA": "LBS",
      "icao_code": "NFNL",
      "airport_name": "Labasa Airport"
    },
    {
      "city": "Lamen Bay",
      "country": "Vanuatu",
      "IATA": "LNB",
      "icao_code": "NVSM",
      "airport_name": "Lamen Bay Airport"
    },
    {
      "city": "Lamerd",
      "country": "Iran",
      "IATA": "LFM",
      "icao_code": "OISR",
      "airport_name": "Lamerd Airport"
    },
    {
      "city": "Lamezia",
      "country": "Italy",
      "IATA": "SUF",
      "icao_code": "LICA",
      "airport_name": "Lamezia Terme International Airport"
    },
    {
      "city": "Lamidanda",
      "country": "Nepal",
      "IATA": "LDN",
      "icao_code": "VNLD",
      "airport_name": "Lamidanda Airport"
    },
    {
      "city": "Lampang",
      "country": "Thailand",
      "IATA": "LPT",
      "icao_code": "VTCL",
      "airport_name": "Lampang Airport"
    },
    {
      "city": "Lampedusa",
      "country": "Italy",
      "IATA": "LMP",
      "icao_code": "LICD",
      "airport_name": "Lampedusa Airport"
    },
    {
      "city": "Lamu",
      "country": "Kenya",
      "IATA": "LAU",
      "icao_code": "HKLU",
      "airport_name": "Manda Airport"
    },
    {
      "city": "Lanai",
      "country": "United States",
      "IATA": "LNY",
      "icao_code": "PHNY",
      "airport_name": "Lanai Airport"
    },
    {
      "city": "Lancang Lahu",
      "country": "China",
      "IATA": "JMJ",
      "icao_code": "ZPJM",
      "airport_name": "Lancang Jingmai Airport"
    },
    {
      "city": "Lancaster",
      "country": "United States",
      "IATA": "WJF",
      "airport_name": "General William J. Fox Airfield"
    },
    {
      "city": "Lancaster",
      "country": "United States",
      "IATA": "LNS",
      "icao_code": "KLNS",
      "airport_name": "Lancaster Airport"
    },
    {
      "city": "Land's End",
      "country": "United Kingdom",
      "IATA": "LEQ",
      "icao_code": "EGHC",
      "airport_name": "Land's End Airport"
    },
    {
      "city": "Landivisiau",
      "country": "France",
      "IATA": "LDV",
      "airport_name": "Landivisiau Airport"
    },
    {
      "city": "Langebaanweg",
      "country": "South Africa",
      "IATA": "SDB",
      "icao_code": "FASD",
      "airport_name": "Saldanha /Vredenburg Airport"
    },
    {
      "city": "Langeoog",
      "country": "Germany",
      "IATA": "LGO",
      "airport_name": "Airfield Langeoog"
    },
    {
      "city": "Langgur-Kei Islands",
      "country": "Indonesia",
      "IATA": "LUV",
      "icao_code": "WAPL",
      "airport_name": "Dumatubin Airport"
    },
    {
      "city": "Langkawi",
      "country": "Malaysia",
      "IATA": "LGK",
      "icao_code": "WMKL",
      "airport_name": "Langkawi International Airport"
    },
    {
      "city": "Langley Township",
      "country": "Canada",
      "IATA": "YLY",
      "airport_name": "Langley Regional Airport"
    },
    {
      "city": "Lankaran",
      "country": "Azerbaijan",
      "IATA": "LLK",
      "icao_code": "UBBL",
      "airport_name": "Lankaran International Airport"
    },
    {
      "city": "Lannion",
      "country": "France",
      "IATA": "LAI",
      "icao_code": "LFRO",
      "airport_name": "Lannion – Côte de Granit Airport"
    },
    {
      "city": "Lansdowne House",
      "country": "Canada",
      "IATA": "YLH",
      "icao_code": "CYLH",
      "airport_name": "Lansdowne House Airport"
    },
    {
      "city": "Lansing",
      "country": "United States",
      "IATA": "LAN",
      "icao_code": "KLAN",
      "airport_name": "Capital Region International Airport"
    },
    {
      "city": "Lanyu",
      "country": "Taiwan",
      "IATA": "KYD",
      "icao_code": "RCLY",
      "airport_name": "Lanyu Airport"
    },
    {
      "city": "Lanzhou",
      "country": "China",
      "IATA": "LHW",
      "icao_code": "ZLLL",
      "airport_name": "Lanzhou Zhongchuan International Airport"
    },
    {
      "city": "Laoag",
      "country": "Philippines",
      "IATA": "LAO",
      "icao_code": "RPLI",
      "airport_name": "Laoag International Airport"
    },
    {
      "city": "Lappeenranta",
      "country": "Finland",
      "IATA": "LPP",
      "icao_code": "EFLP",
      "airport_name": "Lappeenranta Airport"
    },
    {
      "city": "Lar",
      "country": "Iran",
      "IATA": "LRR",
      "icao_code": "OISL",
      "airport_name": "Larestan International Airport"
    },
    {
      "city": "Laramie",
      "country": "United States",
      "IATA": "LAR",
      "icao_code": "KLAR",
      "airport_name": "Laramie Regional Airport"
    },
    {
      "city": "Laredo",
      "country": "United States",
      "IATA": "LRD",
      "icao_code": "KLRD",
      "airport_name": "Laredo International Airport"
    },
    {
      "city": "Larissa",
      "country": "Greece",
      "IATA": "LRA",
      "icao_code": "LGLR",
      "airport_name": "Larissa National Airport"
    },
    {
      "city": "Larnaca",
      "country": "Cyprus",
      "IATA": "LCA",
      "icao_code": "LCLK",
      "airport_name": "Larnaca International Airport"
    },
    {
      "city": "Larsen Bay",
      "country": "United States",
      "IATA": "KLN",
      "icao_code": "PALB",
      "airport_name": "Larsen Bay Airport "
    },
    {
      "city": "Las Cruces",
      "country": "United States",
      "IATA": "LRU",
      "icao_code": "KLRU",
      "airport_name": "Las Cruces International Airport"
    },
    {
      "city": "Las Heras",
      "country": "Argentina",
      "IATA": "LHS",
      "icao_code": "SAVH",
      "airport_name": "Colonia Las Heras Airport"
    },
    {
      "city": "Las Tunas",
      "country": "Cuba",
      "IATA": "VTU",
      "icao_code": "MUVT",
      "airport_name": "Hermanos Ameijeiras Airport"
    },
    {
      "city": "Las Vegas",
      "country": "United States",
      "IATA": "VGT",
      "icao_code": "KVGT",
      "airport_name": "North Las Vegas Airport"
    },
    {
      "city": "Las Vegas",
      "country": "United States",
      "IATA": "LVS",
      "icao_code": "KLVS",
      "airport_name": "Las Vegas Municipal Airport"
    },
    {
      "city": "Las Vegas",
      "country": "United States",
      "IATA": "LAS",
      "icao_code": "KLAS",
      "airport_name": "McCarran International Airport"
    },
    {
      "city": "Las Vegas",
      "country": "United States",
      "IATA": "LSV",
      "icao_code": "KLSV",
      "airport_name": "Nellis Air Force Base"
    },
    {
      "city": "Lasham",
      "country": "United Kingdom",
      "IATA": "QLA",
      "icao_code": "EGHL",
      "airport_name": "Lasham Airfield"
    },
    {
      "city": "Lashio",
      "country": "Burma",
      "IATA": "LSH",
      "icao_code": "VYLS",
      "airport_name": "Lashio Airport"
    },
    {
      "city": "Lashkar Gah",
      "country": "Afghanistan",
      "IATA": "BST",
      "icao_code": "OABT",
      "airport_name": "Bost Airport"
    },
    {
      "city": "Latacunga",
      "country": "Ecuador",
      "IATA": "LTX",
      "airport_name": "Cotopaxi International Airport"
    },
    {
      "city": "Latakia",
      "country": "Syria",
      "IATA": "LTK",
      "icao_code": "OSLK",
      "airport_name": "Bassel Al-Assad International Airport"
    },
    {
      "city": "Latina",
      "country": "Italy",
      "IATA": "QLT",
      "airport_name": "Latina Airport"
    },
    {
      "city": "Latrobe",
      "country": "United States",
      "IATA": "LBE",
      "icao_code": "KLBE",
      "airport_name": "Arnold Palmer Regional Airport"
    },
    {
      "city": "Laucala",
      "country": "Fiji",
      "IATA": "LUC",
      "icao_code": "NFNH",
      "airport_name": "Laucala Island Airport"
    },
    {
      "city": "Launceston",
      "country": "Australia",
      "IATA": "LST",
      "icao_code": "YMLT",
      "airport_name": "Launceston Airport"
    },
    {
      "city": "Lausanne",
      "country": "Switzerland",
      "IATA": "QLS",
      "icao_code": "LSGL",
      "airport_name": "Lausanne Airport"
    },
    {
      "city": "Laval",
      "country": "France",
      "IATA": "LVA",
      "icao_code": "LFOV",
      "airport_name": "Airfield Laval-Entrammes"
    },
    {
      "city": "Lavan Island",
      "country": "Iran",
      "IATA": "LVP",
      "airport_name": "Lavan Airport"
    },
    {
      "city": "Laverton",
      "country": "Australia",
      "IATA": "LVO",
      "icao_code": "YLTN",
      "airport_name": "Laverton Airport"
    },
    {
      "city": "Lawas",
      "country": "Malaysia",
      "IATA": "LWY",
      "icao_code": "WBGW",
      "airport_name": "Lawas Airport"
    },
    {
      "city": "Lawrence",
      "country": "United States",
      "IATA": "LWC",
      "icao_code": "KLWC",
      "airport_name": "Lawrence Municipal Airport"
    },
    {
      "city": "Lawrence",
      "country": "United States",
      "IATA": "LWM",
      "icao_code": "KLWM",
      "airport_name": "Lawrence Municipal Airport"
    },
    {
      "city": "Lawrenceville",
      "country": "United States",
      "IATA": "LZU",
      "icao_code": "KLZU",
      "airport_name": "Gwinnett County Airport"
    },
    {
      "city": "Lawton",
      "country": "United States",
      "IATA": "LAW",
      "icao_code": "KLAW",
      "airport_name": "Lawton-Fort Sill Regional Airport"
    },
    {
      "city": "Lazard Cardenas",
      "country": "Mexico",
      "IATA": "LZC",
      "icao_code": "MMLC",
      "airport_name": "Lázaro Cárdenas Airport"
    },
    {
      "city": "Le Castellet",
      "country": "France",
      "IATA": "CTT",
      "icao_code": "LFMQ",
      "airport_name": "Le Castellet Airport "
    },
    {
      "city": "Le Havre",
      "country": "France",
      "IATA": "LEH",
      "icao_code": "LFOH",
      "airport_name": "Le Havre Octeville Airport"
    },
    {
      "city": "Le Mans",
      "country": "France",
      "IATA": "LME",
      "icao_code": "LFRM",
      "airport_name": "Le Mans Arnage Airport"
    },
    {
      "city": "Le Puy",
      "country": "France",
      "IATA": "LPY",
      "icao_code": "LFHP",
      "airport_name": "Le Puy – Loudes Airport"
    },
    {
      "city": "Le Tourquet",
      "country": "France",
      "IATA": "LTQ",
      "icao_code": "LFAT",
      "airport_name": "Le Touquet – Côte d'Opale Airport"
    },
    {
      "city": "Leaf Rapids",
      "country": "Canada",
      "IATA": "YLR",
      "icao_code": "CYLR",
      "airport_name": "Leaf Rapids Airport"
    },
    {
      "city": "Learmonth",
      "country": "Australia",
      "IATA": "LEA",
      "icao_code": "YPLM",
      "airport_name": "RAAF Learmonth"
    },
    {
      "city": "Lebanon",
      "country": "United States",
      "IATA": "LEB",
      "icao_code": "KLEB",
      "airport_name": "Lebanon Municipal Airport"
    },
    {
      "city": "Lecce",
      "country": "Italy",
      "IATA": "LCC",
      "icao_code": "LIBN",
      "airport_name": "Lecce Airport"
    },
    {
      "city": "Leeds",
      "country": "United Kingdom",
      "IATA": "LBA",
      "icao_code": "EGNM",
      "airport_name": "Leeds Bradford International Airport"
    },
    {
      "city": "Leesburg",
      "country": "United States",
      "IATA": "LEE",
      "icao_code": "KLEE",
      "airport_name": "Leesburg International Airport"
    },
    {
      "city": "Leeuwarden",
      "country": "Netherlands",
      "IATA": "LWR",
      "icao_code": "EHLW",
      "airport_name": "Leeuwarden Air Base"
    },
    {
      "city": "Legazpi",
      "country": "Philippines",
      "IATA": "LGP",
      "icao_code": "RPLP",
      "airport_name": "Legazpi International Airport"
    },
    {
      "city": "Leh",
      "country": "India",
      "IATA": "IXL",
      "icao_code": "VILH",
      "airport_name": "Leh Kushok Bakula Rimpochee Airport"
    },
    {
      "city": "Leigh Creek",
      "country": "Australia",
      "IATA": "LGH",
      "icao_code": "YLEC",
      "airport_name": "Leigh Creek Airport"
    },
    {
      "city": "Leinster",
      "country": "Australia",
      "IATA": "LER",
      "icao_code": "YLST",
      "airport_name": "Leinster Airport"
    },
    {
      "city": "Leipzig",
      "country": "Germany",
      "IATA": "LEJ",
      "icao_code": "EDDP",
      "airport_name": "Leipzig/Halle Airport"
    },
    {
      "city": "Leknes",
      "country": "Norway",
      "IATA": "LKN",
      "icao_code": "ENLK",
      "airport_name": "Leknes Airport"
    },
    {
      "city": "Lelystad",
      "country": "Netherlands",
      "IATA": "LEY",
      "airport_name": "Lelystad Airport"
    },
    {
      "city": "Lemoore",
      "country": "United States",
      "IATA": "NLC",
      "icao_code": "KNLC",
      "airport_name": "Naval Air Station Lemoore"
    },
    {
      "city": "Lensk",
      "country": "Russia",
      "IATA": "ULK",
      "icao_code": "UERL",
      "airport_name": "Lensk Airport"
    },
    {
      "city": "Lençóis",
      "country": "Brazil",
      "IATA": "LEC",
      "icao_code": "SBLE",
      "airport_name": "Coronel Horácio de Mattos Airport"
    },
    {
      "city": "Leon",
      "country": "Spain",
      "IATA": "LEN",
      "icao_code": "LELN",
      "airport_name": "León Airport"
    },
    {
      "city": "Leonora",
      "country": "Australia",
      "IATA": "LNO",
      "icao_code": "YLEO",
      "airport_name": "Leonora Airport"
    },
    {
      "city": "Leros",
      "country": "Greece",
      "IATA": "LRS",
      "icao_code": "LGLE",
      "airport_name": "Leros Municipal Airport"
    },
    {
      "city": "Lerwick",
      "country": "United Kingdom",
      "IATA": "LWK",
      "icao_code": "EGET",
      "airport_name": "Tingwall Airport"
    },
    {
      "city": "Les Saintes",
      "country": "Guadeloupe",
      "IATA": "LSS",
      "airport_name": "Terre-de-Haut Airport"
    },
    {
      "city": "Lethbridge",
      "country": "Canada",
      "IATA": "YQL",
      "icao_code": "CYQL",
      "airport_name": "Lethbridge Airport"
    },
    {
      "city": "Lethem",
      "country": "Guyana",
      "IATA": "LTM",
      "icao_code": "SYLT",
      "airport_name": "Lethem Airport"
    },
    {
      "city": "Leticia",
      "country": "Colombia",
      "IATA": "LET",
      "icao_code": "SKLT",
      "airport_name": "Alfredo Vásquez Cobo International Airport"
    },
    {
      "city": "Leuchars",
      "country": "United Kingdom",
      "IATA": "ADX",
      "icao_code": "EGQL",
      "airport_name": "Leuchars Station"
    },
    {
      "city": "Levuka",
      "country": "Fiji",
      "IATA": "LEV",
      "icao_code": "NFNB",
      "airport_name": "Levuka Airfield"
    },
    {
      "city": "Lewisburg",
      "country": "United States",
      "IATA": "LWB",
      "icao_code": "KLWB",
      "airport_name": "Greenbrier Valley Airport"
    },
    {
      "city": "Lewiston",
      "country": "United States",
      "IATA": "LEW",
      "icao_code": "KLEW",
      "airport_name": "Auburn/Lewiston Municipal Airport"
    },
    {
      "city": "Lewiston",
      "country": "United States",
      "IATA": "LWS",
      "icao_code": "KLWS",
      "airport_name": "Lewiston-Nez Perce County Airport"
    },
    {
      "city": "Lewistown",
      "country": "United States",
      "IATA": "LWT",
      "icao_code": "KLWT",
      "airport_name": "Lewistown Municipal Airport"
    },
    {
      "city": "Lexington",
      "country": "United States",
      "IATA": "LXN",
      "icao_code": "KLXN",
      "airport_name": "Jim Kelly Field"
    },
    {
      "city": "Lexington KY",
      "country": "United States",
      "IATA": "LEX",
      "icao_code": "KLEX",
      "airport_name": "Blue Grass Airport"
    },
    {
      "city": "Lhasa",
      "country": "China",
      "IATA": "LXA",
      "icao_code": "ZULS",
      "airport_name": "Lhasa Gonggar Airport"
    },
    {
      "city": "Lhok Seumawe-Sumatra Island",
      "country": "Indonesia",
      "IATA": "LSW",
      "icao_code": "WITM",
      "airport_name": "Malikus Saleh Airport"
    },
    {
      "city": "Lhok Sukon",
      "country": "Indonesia",
      "IATA": "LSX",
      "icao_code": "WITL",
      "airport_name": "Lhok Sukon Airport"
    },
    {
      "city": "Lianyungang",
      "country": "China",
      "IATA": "LYG",
      "icao_code": "ZSLG",
      "airport_name": "Lianyungang Baitabu Airport"
    },
    {
      "city": "Liberal",
      "country": "United States",
      "IATA": "LBL",
      "icao_code": "KLBL",
      "airport_name": "Liberal Mid-America Regional Airport"
    },
    {
      "city": "Liberia",
      "country": "Costa Rica",
      "IATA": "LIR",
      "icao_code": "MRLB",
      "airport_name": "Daniel Oduber Quirós International Airport"
    },
    {
      "city": "Libo",
      "country": "China",
      "IATA": "LLB",
      "icao_code": "ZULB",
      "airport_name": "Libo Airport"
    },
    {
      "city": "Libreville",
      "country": "Gabon",
      "IATA": "LBV",
      "icao_code": "FOOL",
      "airport_name": "Libreville Leon M'ba International Airport"
    },
    {
      "city": "Lichinga",
      "country": "Mozambique",
      "IATA": "VXC",
      "icao_code": "FQLC",
      "airport_name": "Lichinga Airport"
    },
    {
      "city": "Lidkoping",
      "country": "Sweden",
      "IATA": "LDK",
      "airport_name": "Lidköping-Hovby Airport"
    },
    {
      "city": "Liege",
      "country": "Belgium",
      "IATA": "LGG",
      "icao_code": "EBLG",
      "airport_name": "Liege Airport "
    },
    {
      "city": "Liepaja",
      "country": "Latvia",
      "IATA": "LPX",
      "icao_code": "EVLA",
      "airport_name": "Liepāja International Airport"
    },
    {
      "city": "Lifou",
      "country": "New Caledonia",
      "IATA": "LIF",
      "icao_code": "NWWL",
      "airport_name": "Ouanaham Airport"
    },
    {
      "city": "Lifuka",
      "country": "Tonga",
      "IATA": "HPA",
      "icao_code": "NFTL",
      "airport_name": "Lifuka Island Airport"
    },
    {
      "city": "Lightning Ridge",
      "country": "Australia",
      "IATA": "LHG",
      "icao_code": "YLRD",
      "airport_name": "Lightning Ridge Airport"
    },
    {
      "city": "Lihue",
      "country": "United States",
      "IATA": "LIH",
      "icao_code": "PHLI",
      "airport_name": "Lihue Airport"
    },
    {
      "city": "Lijiang",
      "country": "China",
      "IATA": "LJG",
      "icao_code": "ZPLJ",
      "airport_name": "Lijiang Sanyi Airport "
    },
    {
      "city": "Likoma Island",
      "country": "Malawi",
      "IATA": "LIX",
      "icao_code": "FWLK",
      "airport_name": "Likoma Island Airport "
    },
    {
      "city": "Lilabari",
      "country": "India",
      "IATA": "IXI",
      "icao_code": "VELR",
      "airport_name": "Lilabari Airport "
    },
    {
      "city": "Lille",
      "country": "France",
      "IATA": "LIL",
      "icao_code": "LFQQ",
      "airport_name": "Lille Airport"
    },
    {
      "city": "Lilongwe",
      "country": "Malawi",
      "IATA": "LLW",
      "icao_code": "FWLI",
      "airport_name": "Lilongwe International Airport "
    },
    {
      "city": "Lima",
      "country": "United States",
      "IATA": "AOH",
      "icao_code": "KAOH",
      "airport_name": "Lima Allen County Airport"
    },
    {
      "city": "Lima",
      "country": "Peru",
      "IATA": "LIM",
      "icao_code": "SPIM",
      "airport_name": "Jorge Chávez International Airport"
    },
    {
      "city": "Limbang",
      "country": "Malaysia",
      "IATA": "LMN",
      "icao_code": "WBGJ",
      "airport_name": "Limbang Airport"
    },
    {
      "city": "Lime Acres",
      "country": "South Africa",
      "IATA": "LMR",
      "icao_code": "FALC",
      "airport_name": "Finsch Mine Airport"
    },
    {
      "city": "Limnos",
      "country": "Greece",
      "IATA": "LXS",
      "icao_code": "LGLM",
      "airport_name": "Lemnos International Airport"
    },
    {
      "city": "Limoges",
      "country": "France",
      "IATA": "LIG",
      "icao_code": "LFBL",
      "airport_name": "Limoges – Bellegarde Airport"
    },
    {
      "city": "Limon",
      "country": "Costa Rica",
      "IATA": "LIO",
      "icao_code": "MRLM",
      "airport_name": "Limón International Airport"
    },
    {
      "city": "Lincang",
      "country": "China",
      "IATA": "LNJ",
      "icao_code": "ZPLC",
      "airport_name": "Lincang Airport"
    },
    {
      "city": "Lincoln",
      "country": "United States",
      "IATA": "LNK",
      "icao_code": "KLNK",
      "airport_name": "Lincoln Airport"
    },
    {
      "city": "Lindau",
      "country": "Germany",
      "IATA": "LND",
      "icao_code": "KLND",
      "airport_name": " Hunt Field Airport"
    },
    {
      "city": "Linden",
      "country": "United States",
      "IATA": "LDJ",
      "icao_code": "KLDJ",
      "airport_name": "Linden Airport"
    },
    {
      "city": "Lindi",
      "country": "Tanzania",
      "IATA": "LDI",
      "airport_name": "Lindi Airport"
    },
    {
      "city": "Linkoeping",
      "country": "Sweden",
      "IATA": "LPI",
      "icao_code": "ESSL",
      "airport_name": "Linköping City Airport"
    },
    {
      "city": "Lins",
      "country": "Brazil",
      "IATA": "LIP",
      "icao_code": "SBLN",
      "airport_name": "Gov. Lucas Nogueira Garcez Airport"
    },
    {
      "city": "Linton-on-ouse",
      "country": "United Kingdom",
      "IATA": "HRT",
      "icao_code": "KHRT",
      "airport_name": "RAF Linton-on-Ouse"
    },
    {
      "city": "Linyi",
      "country": "China",
      "IATA": "LYI",
      "icao_code": "ZSLY",
      "airport_name": "Linyi Shubuling Airport"
    },
    {
      "city": "Linz",
      "country": "Austria",
      "IATA": "LNZ",
      "icao_code": "LOWL",
      "airport_name": "Linz Airport "
    },
    {
      "city": "Lipetsk",
      "country": "Russia",
      "IATA": "LPK",
      "icao_code": "UUOL",
      "airport_name": "Lipetsk Airport "
    },
    {
      "city": "Liping",
      "country": "China",
      "IATA": "HZH",
      "icao_code": "ZUNP",
      "airport_name": "Liping Airport"
    },
    {
      "city": "Lisala",
      "country": "Congo (Kinshasa)",
      "IATA": "LIQ",
      "icao_code": "FZGA",
      "airport_name": "Lisala Airport"
    },
    {
      "city": "Lisbon",
      "country": "Portugal",
      "IATA": "LIS",
      "icao_code": "LPPT",
      "airport_name": "Lisbon Portela Airport"
    },
    {
      "city": "Lismore",
      "country": "Australia",
      "IATA": "LSY",
      "icao_code": "YLIS",
      "airport_name": "Lismore Airport"
    },
    {
      "city": "Little Cayman",
      "country": "Cayman Islands",
      "IATA": "LYB",
      "icao_code": "MWCL",
      "airport_name": "Edward Bodden Airfield"
    },
    {
      "city": "Little Grand Rapids",
      "country": "Canada",
      "IATA": "ZGR",
      "icao_code": "CZGR",
      "airport_name": "Little Grand Rapids Airport"
    },
    {
      "city": "Little Rock",
      "country": "United States",
      "IATA": "LIT",
      "icao_code": "KLIT",
      "airport_name": "Clinton National Airport"
    },
    {
      "city": "Liupanshui",
      "country": "China",
      "IATA": "LPF",
      "icao_code": "ZUPS",
      "airport_name": "Liupanshui Yuezhao Airport"
    },
    {
      "city": "Liuzhou",
      "country": "China",
      "IATA": "LZH",
      "icao_code": "ZGZH",
      "airport_name": "Liuzhou Bailian Airport"
    },
    {
      "city": "Livermore",
      "country": "United States",
      "IATA": "LVK",
      "icao_code": "KLVK",
      "airport_name": "Livermore Municipal Airport"
    },
    {
      "city": "Liverpool",
      "country": "United Kingdom",
      "IATA": "LPL",
      "icao_code": "EGGP",
      "airport_name": "Liverpool John Lennon Airport"
    },
    {
      "city": "Livingston-Montana",
      "country": "United States",
      "IATA": "LVM",
      "icao_code": "KLVM",
      "airport_name": "Mission Field"
    },
    {
      "city": "Livingstone",
      "country": "Zambia",
      "IATA": "LVI",
      "icao_code": "FLLI",
      "airport_name": "Harry Mwanga Nkumbula International Airport"
    },
    {
      "city": "Lizard Island",
      "country": "Australia",
      "IATA": "LZR",
      "icao_code": "YLZI",
      "airport_name": "Lizard Island Airport"
    },
    {
      "city": "Ljubljana",
      "country": "Slovenia",
      "IATA": "LJU",
      "icao_code": "LJLJ",
      "airport_name": "Ljubljana Jože Pučnik Airport"
    },
    {
      "city": "Lleida",
      "country": "Spain",
      "IATA": "ILD",
      "icao_code": "LEDA",
      "airport_name": "Lleida-Alguaire Airport"
    },
    {
      "city": "Lloydminster",
      "country": "Canada",
      "IATA": "YLL",
      "icao_code": "CYLL",
      "airport_name": "Lloydminster Airport"
    },
    {
      "city": "Locarno",
      "country": "Switzerland",
      "IATA": "ZJI",
      "airport_name": "Locarno Airport"
    },
    {
      "city": "Lock Haven",
      "country": "United States",
      "IATA": "LHV",
      "icao_code": "KLHV",
      "airport_name": "William T. Piper Memorial Airport"
    },
    {
      "city": "Lockhart River",
      "country": "Australia",
      "IATA": "IRG",
      "icao_code": "YLHR",
      "airport_name": "Lockhart River Airport"
    },
    {
      "city": "Lockport",
      "country": "United States",
      "IATA": "LOT",
      "icao_code": "KLOT",
      "airport_name": "Lewis University Airport"
    },
    {
      "city": "Lodja",
      "country": "Congo (Kinshasa)",
      "IATA": "LJA",
      "icao_code": "FZVA",
      "airport_name": "Lodja Airport"
    },
    {
      "city": "Lodwar",
      "country": "Kenya",
      "IATA": "LOK",
      "icao_code": "HKLO",
      "airport_name": "Lodwar Airport"
    },
    {
      "city": "Lodz",
      "country": "Poland",
      "IATA": "LCJ",
      "icao_code": "EPLL",
      "airport_name": "Łódź Władysław Reymont Airport"
    },
    {
      "city": "Loei",
      "country": "Thailand",
      "IATA": "LOE",
      "icao_code": "VTUL",
      "airport_name": "Loei Airport"
    },
    {
      "city": "Logan",
      "country": "United States",
      "IATA": "LGU",
      "icao_code": "KLGU",
      "airport_name": "Logan-Cache Airport"
    },
    {
      "city": "Logroño-Agoncillo",
      "country": "Spain",
      "IATA": "RJL",
      "icao_code": "LELO",
      "airport_name": "Logroño–Agoncillo Airport"
    },
    {
      "city": "Loh/Linua",
      "country": "Vanuatu",
      "IATA": "TOH",
      "icao_code": "NVSD",
      "airport_name": "Torres Airstrip"
    },
    {
      "city": "Loikaw",
      "country": "Burma",
      "IATA": "LIW",
      "icao_code": "VYLK",
      "airport_name": "Loikaw Airport"
    },
    {
      "city": "Lokichoggio",
      "country": "Kenya",
      "IATA": "LKG",
      "icao_code": "HKLK",
      "airport_name": "Lokichoggio Airport"
    },
    {
      "city": "Lome",
      "country": "Togo",
      "IATA": "LFW",
      "icao_code": "DXXX",
      "airport_name": "Lomé–Tokoin Airport"
    },
    {
      "city": "Lompoc",
      "country": "United States",
      "IATA": "LPC",
      "icao_code": "KLPC",
      "airport_name": "Lompoc Airport"
    },
    {
      "city": "Lompoc",
      "country": "United States",
      "IATA": "VBG",
      "airport_name": "Vandenberg Air Force Base"
    },
    {
      "city": "London",
      "country": "United States",
      "IATA": "LOZ",
      "icao_code": "KLOZ",
      "airport_name": "London-Corbin Airport "
    },
    {
      "city": "London",
      "country": "United Kingdom",
      "IATA": "STN",
      "icao_code": "EGSS",
      "airport_name": "London Stansted Airport"
    },
    {
      "city": "London",
      "country": "United Kingdom",
      "IATA": "LHR",
      "icao_code": "EGLL",
      "airport_name": "London Heathrow Airport"
    },
    {
      "city": "London",
      "country": "United Kingdom",
      "IATA": "LCY",
      "icao_code": "EGLC",
      "airport_name": "London City Airport"
    },
    {
      "city": "London",
      "country": "United Kingdom",
      "IATA": "LGW",
      "icao_code": "EGKK",
      "airport_name": "London Gatwick Airport"
    },
    {
      "city": "London",
      "country": "United Kingdom",
      "IATA": "LTN",
      "icao_code": "EGGW",
      "airport_name": "London Luton Airport"
    },
    {
      "city": "London",
      "country": "Canada",
      "IATA": "YXU",
      "icao_code": "CYXU",
      "airport_name": "London International Airport"
    },
    {
      "city": "Londonderry",
      "country": "United Kingdom",
      "IATA": "LDY",
      "icao_code": "EGAE",
      "airport_name": "City of Derry Airport "
    },
    {
      "city": "Londrina",
      "country": "Brazil",
      "IATA": "LDB",
      "icao_code": "SBLO",
      "airport_name": "Londrina – Gov. José Richa Airport"
    },
    {
      "city": "Lone Rock",
      "country": "United States",
      "IATA": "LNR",
      "icao_code": "KLNR",
      "airport_name": "Tri-County Regional Airport"
    },
    {
      "city": "Long Akah",
      "country": "Malaysia",
      "IATA": "LKH",
      "icao_code": "WBGL",
      "airport_name": "Long Akah Airport"
    },
    {
      "city": "Long Apung-Borneo Island",
      "country": "Indonesia",
      "IATA": "LPU",
      "icao_code": "WRLP",
      "airport_name": "Long Apung Airport"
    },
    {
      "city": "Long Bawan-Borneo Island",
      "country": "Indonesia",
      "IATA": "LBW",
      "icao_code": "WRLB",
      "airport_name": "Juvai Semaring Airport "
    },
    {
      "city": "Los Angeles, Long Beach",
      "country": "United States",
      "IATA": "LGB",
      "icao_code": "KLGB",
      "airport_name": "Long Beach Airport"
    },
    {
      "city": "Long Datih",
      "country": "Malaysia",
      "IATA": "LGL",
      "icao_code": "WBGF",
      "airport_name": "Long Lellang Airport"
    },
    {
      "city": "Long Seridan",
      "country": "Malaysia",
      "IATA": "ODN",
      "icao_code": "WBGI",
      "airport_name": "Long Seridan Airport"
    },
    {
      "city": "Longana",
      "country": "Vanuatu",
      "IATA": "LOD",
      "icao_code": "NVSG",
      "airport_name": "Longana Airport "
    },
    {
      "city": "Longnan",
      "country": "China",
      "IATA": "LNL",
      "icao_code": "ZLLN",
      "airport_name": "Longnan Chengzhou Airport"
    },
    {
      "city": "Longreach",
      "country": "Australia",
      "IATA": "LRE",
      "icao_code": "YBLR",
      "airport_name": "Longreach Airport"
    },
    {
      "city": "Longview",
      "country": "United States",
      "IATA": "GGG",
      "icao_code": "KGGG",
      "airport_name": "East Texas Regional Airport"
    },
    {
      "city": "Longwood",
      "country": "United Kingdom",
      "IATA": "HLE",
      "icao_code": "FHSH",
      "airport_name": "St Helena Airport"
    },
    {
      "city": "Longyan",
      "country": "China",
      "IATA": "LCX",
      "airport_name": "Longyan Guanzhishan Airport"
    },
    {
      "city": "Lonorore",
      "country": "Vanuatu",
      "IATA": "LNE",
      "icao_code": "NVSO",
      "airport_name": "Lonorore Airport"
    },
    {
      "city": "Lopez",
      "country": "United States",
      "IATA": "LPS",
      "airport_name": "Lopez Island Airport "
    },
    {
      "city": "Lord Howe Island",
      "country": "Australia",
      "IATA": "LDH",
      "icao_code": "YLHI",
      "airport_name": "Lord Howe Island Airport"
    },
    {
      "city": "Loreto",
      "country": "Mexico",
      "IATA": "LTO",
      "icao_code": "MMLT",
      "airport_name": "Loreto International Airport"
    },
    {
      "city": "Lorient",
      "country": "France",
      "IATA": "LRT",
      "icao_code": "LFRH",
      "airport_name": "Lorient South Brittany Airport"
    },
    {
      "city": "Los Alamos",
      "country": "United States",
      "IATA": "LAM",
      "icao_code": "KLAM",
      "airport_name": "Los Alamos County Airport"
    },
    {
      "city": "Los Angeles",
      "country": "United States",
      "IATA": "WHP",
      "icao_code": "KWHP",
      "airport_name": "Whiteman Airport"
    },
    {
      "city": "Los Angeles",
      "country": "United States",
      "IATA": "LAX",
      "icao_code": "KLAX",
      "airport_name": "Los Angeles International Airport"
    },
    {
      "city": "Los Angeles",
      "country": "Chile",
      "IATA": "LSQ",
      "icao_code": "SCGE",
      "airport_name": "María Dolores Airport"
    },
    {
      "city": "Los Chiles",
      "country": "Costa Rica",
      "IATA": "LSL",
      "icao_code": "MRLC",
      "airport_name": "Los Chiles Airport"
    },
    {
      "city": "Los Mochis",
      "country": "Mexico",
      "IATA": "LMM",
      "icao_code": "MMLM",
      "airport_name": "Los Mochis International Airport"
    },
    {
      "city": "Los Roques",
      "country": "Venezuela",
      "IATA": "LRV",
      "icao_code": "SVRS",
      "airport_name": "Los Roques Airport"
    },
    {
      "city": "Lossiemouth",
      "country": "United Kingdom",
      "IATA": "LMO",
      "icao_code": "EGQS",
      "airport_name": "Royal Air Force Lossiemouth"
    },
    {
      "city": "Loubomo",
      "country": "Congo (Brazzaville)",
      "IATA": "DIS",
      "icao_code": "FCPL",
      "airport_name": "Dolisie Airport"
    },
    {
      "city": "Louisiana",
      "country": "United States",
      "IATA": "ARA",
      "icao_code": "KARA",
      "airport_name": "Acadiana Regional Airport"
    },
    {
      "city": "Louisville",
      "country": "United States",
      "IATA": "SDF",
      "icao_code": "KSDF",
      "airport_name": "Louisville International Airport "
    },
    {
      "city": "Louisville",
      "country": "United States",
      "IATA": "LOU",
      "icao_code": "KLOU",
      "airport_name": "Bowman Field"
    },
    {
      "city": "Lourdes-De-Blanc-Sablon",
      "country": "Canada",
      "IATA": "YBX",
      "icao_code": "CYBX",
      "airport_name": "Lourdes-de-Blanc-Sablon Airport"
    },
    {
      "city": "Lovelock",
      "country": "United States",
      "IATA": "LOL",
      "icao_code": "KLOL",
      "airport_name": "Derby Field"
    },
    {
      "city": "Luanda",
      "country": "Angola",
      "IATA": "LAD",
      "icao_code": "FNLU",
      "airport_name": "Quatro de Fevereiro International Airport"
    },
    {
      "city": "Luang Namtha",
      "country": "Laos",
      "IATA": "LXG",
      "icao_code": "VLLN",
      "airport_name": "Louangnamtha Airport"
    },
    {
      "city": "Luang Prabang",
      "country": "Laos",
      "IATA": "LPQ",
      "icao_code": "VLLB",
      "airport_name": "Luang Prabang International Airport"
    },
    {
      "city": "Lubang",
      "country": "Philippines",
      "IATA": "LBX",
      "airport_name": "Lubang Airport"
    },
    {
      "city": "Lubango",
      "country": "Angola",
      "IATA": "SDD",
      "icao_code": "FNBU",
      "airport_name": "Lubango Airport"
    },
    {
      "city": "Lubbock",
      "country": "United States",
      "IATA": "LBB",
      "icao_code": "KLBB",
      "airport_name": "Lubbock Preston Smith International Airport"
    },
    {
      "city": "Lublin",
      "country": "Poland",
      "IATA": "LUZ",
      "airport_name": "Lushan Airport"
    },
    {
      "city": "Lubumashi",
      "country": "Congo (Kinshasa)",
      "IATA": "FBM",
      "icao_code": "FZQA",
      "airport_name": "Lubumbashi International Airport"
    },
    {
      "city": "Lucapa",
      "country": "Angola",
      "IATA": "LBZ",
      "icao_code": "FNLK",
      "airport_name": "Lukapa Airport"
    },
    {
      "city": "Lucknow",
      "country": "India",
      "IATA": "LKO",
      "icao_code": "VILK",
      "airport_name": "Chaudhary Charan Singh International Airport "
    },
    {
      "city": "Luderitz",
      "country": "Namibia",
      "IATA": "LUD",
      "icao_code": "FYLZ",
      "airport_name": "Luderitz Airport "
    },
    {
      "city": "Ludhiaha",
      "country": "India",
      "IATA": "LUH",
      "icao_code": "VILD",
      "airport_name": "Sahnewal Airport"
    },
    {
      "city": "Ludington",
      "country": "United States",
      "IATA": "LDM",
      "icao_code": "KLDM",
      "airport_name": "Mason County Airport"
    },
    {
      "city": "Luebeck",
      "country": "Germany",
      "IATA": "LBC",
      "icao_code": "EDHL",
      "airport_name": "Lübeck Airport"
    },
    {
      "city": "Luena",
      "country": "Angola",
      "IATA": "LUO",
      "icao_code": "FNUE",
      "airport_name": "Luena Airport"
    },
    {
      "city": "Lufkin",
      "country": "United States",
      "IATA": "LFK",
      "icao_code": "KLFK",
      "airport_name": "Angelina County Airport"
    },
    {
      "city": "Lugano",
      "country": "Switzerland",
      "IATA": "LUG",
      "icao_code": "LSZA",
      "airport_name": "Lugano Airport"
    },
    {
      "city": "Lugansk",
      "country": "Ukraine",
      "IATA": "VSG",
      "icao_code": "UKCW",
      "airport_name": "Luhansk International Airport"
    },
    {
      "city": "Lukla",
      "country": "Nepal",
      "IATA": "LUA",
      "icao_code": "VNLK",
      "airport_name": "Tenzing–Hillary Airport "
    },
    {
      "city": "Lulea",
      "country": "Sweden",
      "IATA": "LLA",
      "icao_code": "ESPA",
      "airport_name": "Luleå Airport "
    },
    {
      "city": "Lumberton",
      "country": "United States",
      "IATA": "LBT",
      "icao_code": "KLBT",
      "airport_name": "Lumberton Municipal Airport"
    },
    {
      "city": "Lumbo",
      "country": "Mozambique",
      "IATA": "LFB",
      "airport_name": "Lumbo Airport"
    },
    {
      "city": "Luoyang",
      "country": "China",
      "IATA": "LYA",
      "icao_code": "ZHLY",
      "airport_name": "Luoyang Beijiao Airport"
    },
    {
      "city": "Lusaka",
      "country": "Zambia",
      "IATA": "LUN",
      "icao_code": "FLLS",
      "airport_name": "Kenneth Kaunda International Airport"
    },
    {
      "city": "Lutselk'e",
      "country": "Canada",
      "IATA": "YSG",
      "airport_name": "Lutselk'e Airport"
    },
    {
      "city": "Lutsk",
      "country": "Ukraine",
      "IATA": "UCK",
      "icao_code": "UKLC",
      "airport_name": "Lutsk Airport"
    },
    {
      "city": "Luwuk",
      "country": "Indonesia",
      "IATA": "LUW",
      "icao_code": "WAMW",
      "airport_name": "Bubung Airport"
    },
    {
      "city": "Luxemburg",
      "country": "Luxembourg",
      "IATA": "LUX",
      "icao_code": "ELLX",
      "airport_name": "Luxembourg-Findel International Airport"
    },
    {
      "city": "Luxi",
      "country": "China",
      "IATA": "LUM",
      "icao_code": "ZPLX",
      "airport_name": "Dehong Mangshi Airport "
    },
    {
      "city": "Luxor",
      "country": "Egypt",
      "IATA": "LXR",
      "icao_code": "HELX",
      "airport_name": "Luxor International Airport"
    },
    {
      "city": "Luzhou",
      "country": "China",
      "IATA": "LZO",
      "icao_code": "ZULZ",
      "airport_name": "Luzhou Lantian Airport"
    },
    {
      "city": "Lvliang",
      "country": "China",
      "IATA": "LLV",
      "icao_code": "ZBLL",
      "airport_name": "Lvliang Airport"
    },
    {
      "city": "Lvov",
      "country": "Ukraine",
      "IATA": "LWO",
      "icao_code": "UKLL",
      "airport_name": "Lviv Danylo Halytskyi  International Airport "
    },
    {
      "city": "Lycksele",
      "country": "Sweden",
      "IATA": "LYC",
      "icao_code": "ESNL",
      "airport_name": "Lycksele Airport"
    },
    {
      "city": "Lydd",
      "country": "United Kingdom",
      "IATA": "LYX",
      "icao_code": "EGMD",
      "airport_name": "Lydd Airport"
    },
    {
      "city": "Lympne",
      "country": "United Kingdom",
      "IATA": "LYM",
      "airport_name": "Lympne Airport"
    },
    {
      "city": "Lynchburg",
      "country": "United States",
      "IATA": "LYH",
      "icao_code": "KLYH",
      "airport_name": "Lynchburg Regional Airport "
    },
    {
      "city": "Lyneham",
      "country": "United Kingdom",
      "IATA": "LYE",
      "icao_code": "EGDL",
      "airport_name": "RAF Lyneham"
    },
    {
      "city": "Lynn Lake",
      "country": "Canada",
      "IATA": "YYL",
      "icao_code": "CYYL",
      "airport_name": "Lynn Lake Airport"
    },
    {
      "city": "Lyon",
      "country": "France",
      "IATA": "LYN",
      "icao_code": "LFLY",
      "airport_name": "Lyon Bron Airport"
    },
    {
      "city": "Lyon",
      "country": "France",
      "IATA": "LYS",
      "icao_code": "LFLL",
      "airport_name": "Lyon-Saint Exupéry Airport"
    },
    {
      "city": "M'banza-congo",
      "country": "Angola",
      "IATA": "SSY",
      "icao_code": "FNBC",
      "airport_name": "Mbanza Congo Airport"
    },
    {
      "city": "MONTGOMERY",
      "country": "United States",
      "IATA": "MGM",
      "icao_code": "KMGM",
      "airport_name": "Montgomery Regional Airport "
    },
    {
      "city": "Maamigili",
      "country": "Maldives",
      "IATA": "VAM",
      "icao_code": "VRMV",
      "airport_name": "Villa International Airport Maamigili"
    },
    {
      "city": "Maastricht",
      "country": "Netherlands",
      "IATA": "MST",
      "icao_code": "EHBK",
      "airport_name": "Maastricht Aachen Airport"
    },
    {
      "city": "Mabaruma",
      "country": "Guyana",
      "IATA": "USI",
      "icao_code": "SYMB",
      "airport_name": "Mabaruma Airport"
    },
    {
      "city": "Mabuiag Island",
      "country": "Australia",
      "IATA": "UBB",
      "icao_code": "YMAA",
      "airport_name": "Mabuiag Island Airport"
    },
    {
      "city": "Macapa",
      "country": "Brazil",
      "IATA": "MCP",
      "icao_code": "SBMQ",
      "airport_name": "Macapá-Alberto Alcolumbre International Airport"
    },
    {
      "city": "Macara",
      "country": "Ecuador",
      "IATA": "MRR",
      "icao_code": "SEMA",
      "airport_name": "José María Velasco Ibarra Airport"
    },
    {
      "city": "Macas",
      "country": "Ecuador",
      "IATA": "XMS",
      "icao_code": "SEMC",
      "airport_name": "Edmundo Carvajal Airport"
    },
    {
      "city": "Macau",
      "country": "Macau",
      "IATA": "MFM",
      "icao_code": "VMMC",
      "airport_name": "Macau International Airport"
    },
    {
      "city": "Macaé",
      "country": "Brazil",
      "IATA": "MEA",
      "icao_code": "SBME",
      "airport_name": "Benedito Lacerda Airport"
    },
    {
      "city": "Maceio",
      "country": "Brazil",
      "IATA": "MCZ",
      "icao_code": "SBMO",
      "airport_name": "Maceió/Zumbi dos Palmares International Airport"
    },
    {
      "city": "Machala",
      "country": "Ecuador",
      "IATA": "MCH",
      "icao_code": "SEMH",
      "airport_name": "General Manuel Serrano Airport"
    },
    {
      "city": "Mackay",
      "country": "Australia",
      "IATA": "MKY",
      "icao_code": "YBMK",
      "airport_name": "Mackay Airport"
    },
    {
      "city": "Macon",
      "country": "United States",
      "IATA": "WRB",
      "airport_name": "Robins AFB"
    },
    {
      "city": "Macon",
      "country": "United States",
      "IATA": "MCN",
      "icao_code": "KMCN",
      "airport_name": "Middle Georgia Regional Airport"
    },
    {
      "city": "Macon",
      "country": "United States",
      "IATA": "MNM",
      "icao_code": "KMNM",
      "airport_name": "Menominee-Marinette Twin County Airport"
    },
    {
      "city": "Madang",
      "country": "Papua New Guinea",
      "IATA": "MAG",
      "icao_code": "AYMD",
      "airport_name": "Madang Airport"
    },
    {
      "city": "Madera",
      "country": "United States",
      "IATA": "MAE",
      "icao_code": "KMAE",
      "airport_name": "Madera Municipal Airport"
    },
    {
      "city": "Madinah",
      "country": "Saudi Arabia",
      "IATA": "MED",
      "icao_code": "OEMA",
      "airport_name": "Prince Mohammad Bin Abdulaziz Airport"
    },
    {
      "city": "Madison",
      "country": "United States",
      "IATA": "MSN",
      "icao_code": "KMSN",
      "airport_name": "Dane County Regional Airport "
    },
    {
      "city": "Madras",
      "country": "India",
      "IATA": "MAA",
      "icao_code": "VOMM",
      "airport_name": "Chennai International Airport"
    },
    {
      "city": "Madrid",
      "country": "Spain",
      "IATA": "ECV",
      "airport_name": "Cuatro Vientos Airport"
    },
    {
      "city": "Madrid",
      "country": "Spain",
      "IATA": "TOJ",
      "icao_code": "LETO",
      "airport_name": "Madrid–Torrejón Airport"
    },
    {
      "city": "Madrid",
      "country": "Spain",
      "IATA": "MAD",
      "icao_code": "LEMD",
      "airport_name": "Adolfo Suárez Madrid–Barajas Airport"
    },
    {
      "city": "Madurai",
      "country": "India",
      "IATA": "IXM",
      "icao_code": "VOMD",
      "airport_name": "Madurai Airport"
    },
    {
      "city": "Mae Hong Son",
      "country": "Thailand",
      "IATA": "HGN",
      "icao_code": "VTCH",
      "airport_name": "Mae Hong Son Airport"
    },
    {
      "city": "Maewo Island",
      "country": "Vanuatu",
      "IATA": "MWF",
      "icao_code": "NVSN",
      "airport_name": "Maewo-Naone Airport"
    },
    {
      "city": "Mafeking",
      "country": "South Africa",
      "IATA": "MBD",
      "icao_code": "FAMM",
      "airport_name": "Mahikeng Airport"
    },
    {
      "city": "Mafia Island",
      "country": "Tanzania",
      "IATA": "MFA",
      "icao_code": "HTMA",
      "airport_name": "Mafia Airport"
    },
    {
      "city": "Mafraq",
      "country": "Jordan",
      "IATA": "OMF",
      "icao_code": "OJMF",
      "airport_name": "King Hussein Air College"
    },
    {
      "city": "Magadan",
      "country": "Russia",
      "IATA": "GDX",
      "icao_code": "UHMM",
      "airport_name": "Sokol Airport"
    },
    {
      "city": "Magangue",
      "country": "Colombia",
      "IATA": "MGN",
      "airport_name": "Harbor Springs Municipal  Airport"
    },
    {
      "city": "Magas",
      "country": "Russia",
      "IATA": "IGT",
      "icao_code": "URMS",
      "airport_name": "Magas Airport"
    },
    {
      "city": "Magdeburg",
      "country": "Germany",
      "IATA": "ZMG",
      "airport_name": "Magdeburg Airport"
    },
    {
      "city": "Magnetiogorsk",
      "country": "Russia",
      "IATA": "MQF",
      "icao_code": "USCM",
      "airport_name": "Magnitogorsk International Airport"
    },
    {
      "city": "Magwe",
      "country": "Burma",
      "IATA": "MWQ",
      "icao_code": "VYMW",
      "airport_name": "Magway Airport"
    },
    {
      "city": "Mahajanga",
      "country": "Madagascar",
      "IATA": "MJN",
      "icao_code": "FMNM",
      "airport_name": "Amborovy Airport"
    },
    {
      "city": "Mahdia",
      "country": "Guyana",
      "IATA": "MHA",
      "icao_code": "SYMD",
      "airport_name": "Mahdia Airport"
    },
    {
      "city": "Mahe",
      "country": "Seychelles",
      "IATA": "SEZ",
      "icao_code": "FSIA",
      "airport_name": "Seychelles International Airport"
    },
    {
      "city": "Maiana",
      "country": "Kiribati",
      "IATA": "MNK",
      "airport_name": "Maiana Airport"
    },
    {
      "city": "Maiduguri",
      "country": "Nigeria",
      "IATA": "MIU",
      "icao_code": "DNMA",
      "airport_name": "Maiduguri International Airport"
    },
    {
      "city": "Maimama",
      "country": "Afghanistan",
      "IATA": "MMZ",
      "icao_code": "OAMN",
      "airport_name": "Maymana Airport"
    },
    {
      "city": "Maintirano",
      "country": "Madagascar",
      "IATA": "MXT",
      "icao_code": "FMMO",
      "airport_name": "Maintirano Airport"
    },
    {
      "city": "Maio",
      "country": "Cape Verde",
      "IATA": "MMO",
      "icao_code": "GVMA",
      "airport_name": "Maio Airport"
    },
    {
      "city": "Maitland",
      "country": "Australia",
      "IATA": "MTL",
      "icao_code": "YMND",
      "airport_name": "Maitland Airport"
    },
    {
      "city": "Majuro",
      "country": "Marshall Islands",
      "IATA": "MAJ",
      "icao_code": "PKMJ",
      "airport_name": "Marshall Islands International Airport "
    },
    {
      "city": "Makale",
      "country": "Indonesia",
      "IATA": "TTR",
      "airport_name": "Pongtiku Airport"
    },
    {
      "city": "Makale",
      "country": "Ethiopia",
      "IATA": "MQX",
      "icao_code": "HAMK",
      "airport_name": "Alula Aba Nega Airport"
    },
    {
      "city": "Makemo",
      "country": "French Polynesia",
      "IATA": "MKP",
      "icao_code": "NTGM",
      "airport_name": "Makemo Airport"
    },
    {
      "city": "Makhachkala",
      "country": "Russia",
      "IATA": "MCX",
      "icao_code": "URML",
      "airport_name": "Makhachkala Uytash Airport"
    },
    {
      "city": "Makin",
      "country": "Kiribati",
      "IATA": "MTK",
      "airport_name": "Makin Island Airport"
    },
    {
      "city": "Makkovik",
      "country": "Canada",
      "IATA": "YMN",
      "icao_code": "CYFT",
      "airport_name": "Makkovik Airport"
    },
    {
      "city": "Makokou",
      "country": "Gabon",
      "IATA": "MKU",
      "icao_code": "FOOK",
      "airport_name": "Makokou Airport"
    },
    {
      "city": "Makung",
      "country": "Taiwan",
      "IATA": "MZG",
      "icao_code": "RCQC",
      "airport_name": "Magong Airport"
    },
    {
      "city": "Makurdi",
      "country": "Nigeria",
      "IATA": "MDI",
      "icao_code": "DNMK",
      "airport_name": "Makurdi Airport"
    },
    {
      "city": "Malabo",
      "country": "Equatorial Guinea",
      "IATA": "SSG",
      "icao_code": "FGSL",
      "airport_name": "Malabo Airport "
    },
    {
      "city": "Malacca",
      "country": "Malaysia",
      "IATA": "MKZ",
      "icao_code": "WMKM",
      "airport_name": "Malacca International Airport"
    },
    {
      "city": "Malaga",
      "country": "Spain",
      "IATA": "AGP",
      "icao_code": "LEMG",
      "airport_name": "Málaga–Costa del Sol Airport"
    },
    {
      "city": "Malakal",
      "country": "Sudan",
      "IATA": "MAK",
      "icao_code": "HSSM",
      "airport_name": "Malakal Airport"
    },
    {
      "city": "Malalane",
      "country": "South Africa",
      "IATA": "LLE",
      "airport_name": "Malelane Airport"
    },
    {
      "city": "Malamala",
      "country": "South Africa",
      "IATA": "AAM",
      "icao_code": "FAMD",
      "airport_name": "Mala Mala Airport"
    },
    {
      "city": "Malang",
      "country": "Indonesia",
      "IATA": "MLG",
      "icao_code": "WIAS",
      "airport_name": "Abdul Rachman Saleh Airport"
    },
    {
      "city": "Malanje",
      "country": "Angola",
      "IATA": "MEG",
      "icao_code": "FNMA",
      "airport_name": "Malanje Airport"
    },
    {
      "city": "Malargue",
      "country": "Argentina",
      "IATA": "LGS",
      "icao_code": "SAMM",
      "airport_name": "Comodoro D. Ricardo Salomon Airport"
    },
    {
      "city": "Malatya",
      "country": "Turkey",
      "IATA": "MLX",
      "icao_code": "LTAT",
      "airport_name": "Malatya Erhaç Airport"
    },
    {
      "city": "Male",
      "country": "Maldives",
      "IATA": "MLE",
      "icao_code": "VRMM",
      "airport_name": "Ibrahim Nasir International Airport"
    },
    {
      "city": "Malekula Island",
      "country": "Vanuatu",
      "IATA": "SWJ",
      "icao_code": "NVSX",
      "airport_name": "Southwest Bay Airport"
    },
    {
      "city": "Mali Losinj",
      "country": "Croatia",
      "IATA": "LSZ",
      "icao_code": "LDLO",
      "airport_name": "Lošinj Airport"
    },
    {
      "city": "Malindi",
      "country": "Kenya",
      "IATA": "MYD",
      "icao_code": "HKML",
      "airport_name": "Malindi Airport"
    },
    {
      "city": "Mallacoota",
      "country": "Australia",
      "IATA": "XMC",
      "icao_code": "YMCO",
      "airport_name": "Mallacoota Airport"
    },
    {
      "city": "Malmoe",
      "country": "Sweden",
      "IATA": "MMX",
      "icao_code": "ESMS",
      "airport_name": "Malmö Airport"
    },
    {
      "city": "Malolo Lailai Island",
      "country": "Fiji",
      "IATA": "PTF",
      "icao_code": "NFFO",
      "airport_name": "Malolo Lailai Airport"
    },
    {
      "city": "Malta",
      "country": "Malta",
      "IATA": "MLA",
      "icao_code": "LMML",
      "airport_name": "Malta International Airport "
    },
    {
      "city": "Mamburao",
      "country": "Philippines",
      "IATA": "MBO",
      "airport_name": "Mamburao Airport"
    },
    {
      "city": "Mammoth Lakes",
      "country": "United States",
      "IATA": "MMH",
      "icao_code": "KMMH",
      "airport_name": "Mammoth Yosemite Airport"
    },
    {
      "city": "Mampikony",
      "country": "Madagascar",
      "IATA": "WMP",
      "airport_name": "Mampikony Airport"
    },
    {
      "city": "Man",
      "country": "Cote d'Ivoire",
      "IATA": "MJC",
      "icao_code": "DIMN",
      "airport_name": "Man Airport"
    },
    {
      "city": "Mana Island",
      "country": "Fiji",
      "IATA": "MNF",
      "icao_code": "NFMA",
      "airport_name": "Mountain View Airport"
    },
    {
      "city": "Manado",
      "country": "Indonesia",
      "IATA": "MDC",
      "icao_code": "WAMM",
      "airport_name": "Sam Ratulangi International Airport"
    },
    {
      "city": "Managua",
      "country": "Nicaragua",
      "IATA": "MGA",
      "icao_code": "MNMG",
      "airport_name": "Augusto C. Sandino International Airport"
    },
    {
      "city": "Manakara",
      "country": "Madagascar",
      "IATA": "WVK",
      "airport_name": "Manakara Airport"
    },
    {
      "city": "Mananara",
      "country": "Madagascar",
      "IATA": "WMR",
      "icao_code": "FMNC",
      "airport_name": "Mananara Nord Airport"
    },
    {
      "city": "Manang",
      "country": "Nepal",
      "IATA": "NGX",
      "icao_code": "VNMA",
      "airport_name": "Manang Airport"
    },
    {
      "city": "Mananjary",
      "country": "Madagascar",
      "IATA": "MNJ",
      "icao_code": "FMSM",
      "airport_name": "Mananjary Airport"
    },
    {
      "city": "Manapouri",
      "country": "New Zealand",
      "IATA": "TEU",
      "airport_name": "Te Anau Airport"
    },
    {
      "city": "Manassas",
      "country": "United States",
      "IATA": "MNZ",
      "airport_name": "Manassas Regional Airport"
    },
    {
      "city": "Manaus",
      "country": "Brazil",
      "IATA": "PLL",
      "airport_name": "Ponta Pelada Airport"
    },
    {
      "city": "Manaus",
      "country": "Brazil",
      "IATA": "MAO",
      "icao_code": "SBEG",
      "airport_name": "Eduardo Gomes International Airport"
    },
    {
      "city": "Manchester",
      "country": "United Kingdom",
      "IATA": "MAN",
      "icao_code": "EGCC",
      "airport_name": "Manchester Airport"
    },
    {
      "city": "Manchester NH",
      "country": "United States",
      "IATA": "MHT",
      "icao_code": "KMHT",
      "airport_name": "Manchester–Boston Regional Airport"
    },
    {
      "city": "Mandalay",
      "country": "Burma",
      "IATA": "MDL",
      "icao_code": "VYCZ",
      "airport_name": "Mandalay International Airport"
    },
    {
      "city": "Mandritsara",
      "country": "Madagascar",
      "IATA": "WMA",
      "airport_name": "Mandritsara Airport"
    },
    {
      "city": "Mangaia Island",
      "country": "Cook Islands",
      "IATA": "MGS",
      "icao_code": "NCGO",
      "airport_name": "Mangaia Island Airport"
    },
    {
      "city": "Mangalore",
      "country": "India",
      "IATA": "IXE",
      "icao_code": "VOML",
      "airport_name": "Mangaluru Airport"
    },
    {
      "city": "Mangla",
      "country": "Pakistan",
      "IATA": "XJM",
      "airport_name": "Mangla Airport"
    },
    {
      "city": "Mangochi",
      "country": "Malawi",
      "IATA": "MAI",
      "airport_name": "Mangochi Airport"
    },
    {
      "city": "Manhattan",
      "country": "United States",
      "IATA": "MHK",
      "icao_code": "KMHK",
      "airport_name": "Manhattan Regional Airport"
    },
    {
      "city": "Manicore",
      "country": "Brazil",
      "IATA": "MNX",
      "icao_code": "SBMY",
      "airport_name": "Manicoré Airport"
    },
    {
      "city": "Manihi",
      "country": "French Polynesia",
      "IATA": "XMH",
      "icao_code": "NTGI",
      "airport_name": "Manihi Airport"
    },
    {
      "city": "Manihiki Island",
      "country": "Cook Islands",
      "IATA": "MHX",
      "icao_code": "NCMH",
      "airport_name": "Manihiki Island Airport"
    },
    {
      "city": "Maniitsoq",
      "country": "Greenland",
      "IATA": "JSU",
      "icao_code": "BGMQ",
      "airport_name": "Maniitsoq Airport"
    },
    {
      "city": "Manila",
      "country": "Philippines",
      "IATA": "MNL",
      "icao_code": "RPLL",
      "airport_name": "Ninoy Aquino International Airport"
    },
    {
      "city": "Maningrida",
      "country": "Australia",
      "IATA": "MNG",
      "icao_code": "YMGD",
      "airport_name": "Maningrida Airport"
    },
    {
      "city": "Manistee",
      "country": "United States",
      "IATA": "MBL",
      "icao_code": "KMBL",
      "airport_name": "Manistee County Blacker Airport"
    },
    {
      "city": "Manitouwadge",
      "country": "Canada",
      "IATA": "YMG",
      "icao_code": "CYMG",
      "airport_name": "Manitouwadge Airport"
    },
    {
      "city": "Manitowaning",
      "country": "Canada",
      "IATA": "YEM",
      "icao_code": "CYEM",
      "airport_name": "Manitowaning/Manitoulin East Municipal Airport"
    },
    {
      "city": "Maniwaki",
      "country": "Canada",
      "IATA": "YMW",
      "icao_code": "CYMW",
      "airport_name": "Maniwaki Airport"
    },
    {
      "city": "Manizales",
      "country": "Colombia",
      "IATA": "MZL",
      "icao_code": "SKMZ",
      "airport_name": "La Nubia Airport"
    },
    {
      "city": "Manja",
      "country": "Madagascar",
      "IATA": "MJA",
      "icao_code": "FMSJ",
      "airport_name": "Manja Airport"
    },
    {
      "city": "Manley Hot Springs",
      "country": "United States",
      "IATA": "MLY",
      "icao_code": "PAML",
      "airport_name": "Manley Hot Springs Airport"
    },
    {
      "city": "Mannheim",
      "country": "Germany",
      "IATA": "MHG",
      "icao_code": "EDFM",
      "airport_name": "Mannheim City Airport"
    },
    {
      "city": "Manokotak",
      "country": "United States",
      "IATA": "KMO",
      "icao_code": "PAMB",
      "airport_name": "Manokotak Airport "
    },
    {
      "city": "Manokwari",
      "country": "Indonesia",
      "IATA": "MKW",
      "icao_code": "WASR",
      "airport_name": "Rendani Airport"
    },
    {
      "city": "Mansa",
      "country": "Zambia",
      "IATA": "MNS",
      "icao_code": "FLMA",
      "airport_name": "Kamra Airport"
    },
    {
      "city": "Mansfield",
      "country": "United States",
      "IATA": "MFD",
      "icao_code": "KMFD",
      "airport_name": "Mansfield Lahm Regional Airport"
    },
    {
      "city": "Manston",
      "country": "United Kingdom",
      "IATA": "MSE",
      "airport_name": "Kent International Airport"
    },
    {
      "city": "Manta",
      "country": "Ecuador",
      "IATA": "MEC",
      "icao_code": "SEMT",
      "airport_name": "Eloy Alfaro International Airport"
    },
    {
      "city": "Manteo",
      "country": "United States",
      "IATA": "MEO",
      "airport_name": "Dare County Regional Airport"
    },
    {
      "city": "Manzanillo",
      "country": "Cuba",
      "IATA": "MZO",
      "icao_code": "MUMZ",
      "airport_name": "Sierra Maestra Airport"
    },
    {
      "city": "Manzanillo",
      "country": "Mexico",
      "IATA": "ZLO",
      "icao_code": "MMZO",
      "airport_name": "Playa de Oro International Airport"
    },
    {
      "city": "Manzhouli",
      "country": "China",
      "IATA": "NZH",
      "airport_name": "Manzhouli Xijiao Airport"
    },
    {
      "city": "Manzini",
      "country": "Swaziland",
      "IATA": "SHO",
      "airport_name": "Sokcho Airport"
    },
    {
      "city": "Manzini",
      "country": "Swaziland",
      "IATA": "MTS",
      "icao_code": "FDMS",
      "airport_name": "Matsapha Airport"
    },
    {
      "city": "Maputo",
      "country": "Mozambique",
      "IATA": "MPM",
      "icao_code": "FQMA",
      "airport_name": "Maputo International Airport"
    },
    {
      "city": "Mar Del Plata",
      "country": "Argentina",
      "IATA": "MDQ",
      "icao_code": "SAZM",
      "airport_name": "Astor Piazzolla  International Airport"
    },
    {
      "city": "Maraba",
      "country": "Brazil",
      "IATA": "MAB",
      "icao_code": "SBMA",
      "airport_name": "João Correa da Rocha Airport"
    },
    {
      "city": "Maracaibo",
      "country": "Venezuela",
      "IATA": "MAR",
      "icao_code": "SVMC",
      "airport_name": "La Chinita International Airport"
    },
    {
      "city": "Maracay",
      "country": "Venezuela",
      "IATA": "MYC",
      "icao_code": "SVBS",
      "airport_name": "Mariscal Sucre Airport"
    },
    {
      "city": "Maradi",
      "country": "Niger",
      "IATA": "MFQ",
      "icao_code": "DRRM",
      "airport_name": "Maradi Airport"
    },
    {
      "city": "Maragheh",
      "country": "Iran",
      "IATA": "ACP",
      "icao_code": "OITM",
      "airport_name": "Sahand Airport"
    },
    {
      "city": "Marakei",
      "country": "Kiribati",
      "IATA": "MZK",
      "airport_name": "Marakei Airport"
    },
    {
      "city": "Marana",
      "country": "United States",
      "IATA": "MZJ",
      "icao_code": "KMZJ",
      "airport_name": "Pinal Airpark"
    },
    {
      "city": "Marathon",
      "country": "Canada",
      "IATA": "YSP",
      "icao_code": "CYSP",
      "airport_name": "Marathon Aerodrome"
    },
    {
      "city": "Marathon",
      "country": "United States",
      "IATA": "MTH",
      "icao_code": "KMTH",
      "airport_name": "Florida Keys Marathon International Airport"
    },
    {
      "city": "Marau",
      "country": "Solomon Islands",
      "IATA": "RUS",
      "icao_code": "AGGU",
      "airport_name": "Marau Airport"
    },
    {
      "city": "Marco Island Airport",
      "country": "United States",
      "IATA": "MRK",
      "icao_code": "KMKY",
      "airport_name": "Marco Island Airport"
    },
    {
      "city": "Mardin",
      "country": "Turkey",
      "IATA": "MQM",
      "icao_code": "LTCR",
      "airport_name": "Mardin Airport"
    },
    {
      "city": "Mare",
      "country": "New Caledonia",
      "IATA": "MEE",
      "icao_code": "NWWR",
      "airport_name": "Maré Airport"
    },
    {
      "city": "Marfa",
      "country": "United States",
      "IATA": "MRF",
      "icao_code": "KMRF",
      "airport_name": "Marfa Municipal Airport"
    },
    {
      "city": "Margate",
      "country": "South Africa",
      "IATA": "MGH",
      "icao_code": "FAMG",
      "airport_name": "Margate Airport"
    },
    {
      "city": "Marham",
      "country": "United Kingdom",
      "IATA": "KNF",
      "icao_code": "EGYM",
      "airport_name": "Royal Air Force Marham"
    },
    {
      "city": "Maribo",
      "country": "Denmark",
      "IATA": "MRW",
      "airport_name": "Lolland Falster Airport"
    },
    {
      "city": "Maribor",
      "country": "Slovenia",
      "IATA": "MBX",
      "icao_code": "LJMB",
      "airport_name": "Maribor Edvard Rusjan Airport"
    },
    {
      "city": "Mariehamn",
      "country": "Finland",
      "IATA": "MHQ",
      "icao_code": "EFMA",
      "airport_name": "Mariehamn Airport"
    },
    {
      "city": "Marietta",
      "country": "United States",
      "IATA": "MGE",
      "airport_name": "Dobbins Air Reserve Base "
    },
    {
      "city": "Marina Di Campo",
      "country": "Italy",
      "IATA": "EBA",
      "icao_code": "LIRJ",
      "airport_name": "Marina di Campo Airport"
    },
    {
      "city": "Maringa",
      "country": "Brazil",
      "IATA": "MGF",
      "icao_code": "SBMG",
      "airport_name": "Sílvio Name Júnior Regional Airport"
    },
    {
      "city": "Marion",
      "country": "United States",
      "IATA": "MWA",
      "icao_code": "KMWA",
      "airport_name": "Williamson County Regional Airport"
    },
    {
      "city": "Maripasoula",
      "country": "French Guiana",
      "IATA": "MPY",
      "airport_name": "Maripasoula Airport"
    },
    {
      "city": "Mariposa",
      "country": "United States",
      "IATA": "RMY",
      "icao_code": "KMPI",
      "airport_name": "Brooks Field"
    },
    {
      "city": "Mariquita",
      "country": "Colombia",
      "IATA": "MQU",
      "icao_code": "SKQU",
      "airport_name": "Mariquita Airport"
    },
    {
      "city": "Mariscal Estigarribia",
      "country": "Paraguay",
      "IATA": "ESG",
      "icao_code": "SGME",
      "airport_name": "Dr. Luis María Argaña International Airport"
    },
    {
      "city": "Mariupol International",
      "country": "Ukraine",
      "IATA": "MPW",
      "icao_code": "UKCM",
      "airport_name": "Mariupol International Airport"
    },
    {
      "city": "Markovo",
      "country": "Russia",
      "IATA": "KVM",
      "airport_name": "Markovo Airport"
    },
    {
      "city": "Maroantsetra",
      "country": "Madagascar",
      "IATA": "WMN",
      "icao_code": "FMNR",
      "airport_name": "Maroantsetra Airport"
    },
    {
      "city": "Maroochydore",
      "country": "Australia",
      "IATA": "MCY",
      "icao_code": "YBMC",
      "airport_name": "Sunshine Coast Airport"
    },
    {
      "city": "Maroua",
      "country": "Cameroon",
      "IATA": "MVR",
      "icao_code": "FKKL",
      "airport_name": "Salak Airport"
    },
    {
      "city": "Marrakech",
      "country": "Morocco",
      "IATA": "RAK",
      "icao_code": "GMMX",
      "airport_name": "Marrakesh Menara Airport"
    },
    {
      "city": "Marsa Alam",
      "country": "Egypt",
      "IATA": "RMF",
      "icao_code": "HEMA",
      "airport_name": "Marsa Alam International Airport"
    },
    {
      "city": "Marsa Brega",
      "country": "Libya",
      "IATA": "LMQ",
      "icao_code": "HLMB",
      "airport_name": "Marsa Brega Airport"
    },
    {
      "city": "Marseille",
      "country": "France",
      "IATA": "MRS",
      "icao_code": "LFML",
      "airport_name": "Marseille Provence Airport"
    },
    {
      "city": "Marsh Harbor",
      "country": "Bahamas",
      "IATA": "MHH",
      "icao_code": "MYAM",
      "airport_name": "Leonard M. Thompson International Airport"
    },
    {
      "city": "Marshall",
      "country": "United States",
      "IATA": "MLL",
      "icao_code": "PADM",
      "airport_name": "Marshall Don Hunter Sr. Airport "
    },
    {
      "city": "Marshfield",
      "country": "United States",
      "IATA": "MFI",
      "airport_name": "Marshfield Municipal Airport"
    },
    {
      "city": "Martinsburg",
      "country": "United States",
      "IATA": "MRB",
      "icao_code": "KMRB",
      "airport_name": "Eastern WV Regional Airport "
    },
    {
      "city": "Marudi",
      "country": "Malaysia",
      "IATA": "MUR",
      "icao_code": "WBGM",
      "airport_name": "Marudi Airport"
    },
    {
      "city": "Mary",
      "country": "Turkmenistan",
      "IATA": "MYP",
      "icao_code": "UTAM",
      "airport_name": "Mary International  Airport"
    },
    {
      "city": "Mary's Harbour",
      "country": "Canada",
      "IATA": "YMH",
      "icao_code": "CYMH",
      "airport_name": "Mary's Harbour Airport"
    },
    {
      "city": "Maryborough",
      "country": "Australia",
      "IATA": "MBH",
      "icao_code": "YMYB",
      "airport_name": "Maryborough Airport"
    },
    {
      "city": "Marysville",
      "country": "United States",
      "IATA": "BAB",
      "icao_code": "KBAB",
      "airport_name": "Beale Air Force Base"
    },
    {
      "city": "Marília",
      "country": "Brazil",
      "IATA": "MII",
      "icao_code": "SBML",
      "airport_name": "Frank Miloye Milenkowichi–Marília State Airport "
    },
    {
      "city": "Masai Mara",
      "country": "Kenya",
      "IATA": "MRE",
      "airport_name": "Mara Serena Airport"
    },
    {
      "city": "Masamba",
      "country": "Indonesia",
      "IATA": "MXB",
      "icao_code": "WAWM",
      "airport_name": "Andi Jemma Airport"
    },
    {
      "city": "Masbate",
      "country": "Philippines",
      "IATA": "MBT",
      "icao_code": "RPVJ",
      "airport_name": "Moises R. Espinosa Airport"
    },
    {
      "city": "Maseru",
      "country": "Lesotho",
      "IATA": "MSU",
      "icao_code": "FXMM",
      "airport_name": "Moshoeshoe I International Airport"
    },
    {
      "city": "Mashhad",
      "country": "Iran",
      "IATA": "MHD",
      "icao_code": "OIMM",
      "airport_name": "Shahid Hashemi Nejad Airport"
    },
    {
      "city": "Masirah",
      "country": "Oman",
      "IATA": "MSH",
      "icao_code": "OOMA",
      "airport_name": "RAFO Masirah"
    },
    {
      "city": "Mason City",
      "country": "United States",
      "IATA": "MCW",
      "icao_code": "KMCW",
      "airport_name": "Mason City Municipal Airport"
    },
    {
      "city": "Massawa",
      "country": "Eritrea",
      "IATA": "MSW",
      "icao_code": "HHMS",
      "airport_name": "Massawa International Airport"
    },
    {
      "city": "Massena",
      "country": "United States",
      "IATA": "MSS",
      "icao_code": "KMSS",
      "airport_name": "Massena International Airport "
    },
    {
      "city": "Masset",
      "country": "Canada",
      "IATA": "ZMT",
      "icao_code": "CZMT",
      "airport_name": "Masset Airport"
    },
    {
      "city": "Masterton",
      "country": "New Zealand",
      "IATA": "MRO",
      "icao_code": "NZMS",
      "airport_name": "Base Marambio Island´s Airport"
    },
    {
      "city": "Masvingo",
      "country": "Zimbabwe",
      "IATA": "MVZ",
      "icao_code": "FVMV",
      "airport_name": "Masvingo Airport"
    },
    {
      "city": "Matadi",
      "country": "Congo (Kinshasa)",
      "IATA": "MAT",
      "icao_code": "FZAM",
      "airport_name": "Tshimpi Airport"
    },
    {
      "city": "Matagami",
      "country": "Canada",
      "IATA": "YNM",
      "icao_code": "CYNM",
      "airport_name": "Matagami Airport"
    },
    {
      "city": "Mataiva",
      "country": "French Polynesia",
      "IATA": "MVT",
      "icao_code": "NTGV",
      "airport_name": "Mataiva Airport"
    },
    {
      "city": "Matam",
      "country": "Senegal",
      "IATA": "MAX",
      "icao_code": "GOSM",
      "airport_name": "Ouro Sogui Airport"
    },
    {
      "city": "Matamoros",
      "country": "Mexico",
      "IATA": "MAM",
      "icao_code": "MMMA",
      "airport_name": "General Servando Canales International Airport"
    },
    {
      "city": "Matane",
      "country": "Canada",
      "IATA": "YME",
      "icao_code": "CYME",
      "airport_name": "Matane Airport"
    },
    {
      "city": "Mataram",
      "country": "Indonesia",
      "IATA": "AMI",
      "airport_name": "Selaparang Airport"
    },
    {
      "city": "Matei",
      "country": "Fiji",
      "IATA": "TVU",
      "icao_code": "NFNM",
      "airport_name": "Matei Airport"
    },
    {
      "city": "Matsu Islands",
      "country": "Taiwan",
      "IATA": "MFK",
      "icao_code": "RCMT",
      "airport_name": "Matsu Beigan Airport"
    },
    {
      "city": "Matsu Islands",
      "country": "Taiwan",
      "IATA": "LZN",
      "icao_code": "RCFG",
      "airport_name": "Matsu Nangan Airport"
    },
    {
      "city": "Matsumoto",
      "country": "Japan",
      "IATA": "MMJ",
      "icao_code": "RJAF",
      "airport_name": "Matsumoto Airport"
    },
    {
      "city": "Matsuyama",
      "country": "Japan",
      "IATA": "MYJ",
      "icao_code": "RJOM",
      "airport_name": "Matsuyama Airport"
    },
    {
      "city": "Mattala",
      "country": "Sri Lanka",
      "IATA": "HRI",
      "airport_name": "Mattala Rajapaksa International Airport"
    },
    {
      "city": "Matthew Town",
      "country": "Bahamas",
      "IATA": "IGA",
      "icao_code": "MYIG",
      "airport_name": "Matthew Town Airport "
    },
    {
      "city": "Maturin",
      "country": "Venezuela",
      "IATA": "MUN",
      "icao_code": "SVMT",
      "airport_name": "José Tadeo Monagas International Airport"
    },
    {
      "city": "Maubeuge",
      "country": "France",
      "IATA": "XME",
      "airport_name": "Maubeuge-Élesmes Airport"
    },
    {
      "city": "Maues",
      "country": "Brazil",
      "IATA": "MBZ",
      "icao_code": "SWMW",
      "airport_name": "Maués Airport"
    },
    {
      "city": "Mauke Island",
      "country": "Cook Islands",
      "IATA": "MUK",
      "icao_code": "NCMK",
      "airport_name": "Mauke Airport"
    },
    {
      "city": "Maumere",
      "country": "Indonesia",
      "IATA": "MOF",
      "icao_code": "WRKC",
      "airport_name": "Frans Seda Airport"
    },
    {
      "city": "Maun",
      "country": "Botswana",
      "IATA": "MUB",
      "icao_code": "FBMN",
      "airport_name": "Maun Airport"
    },
    {
      "city": "Maupiti",
      "country": "French Polynesia",
      "IATA": "MAU",
      "icao_code": "NTTP",
      "airport_name": "Maupiti Airport"
    },
    {
      "city": "Mawlamyine",
      "country": "Burma",
      "IATA": "MNU",
      "icao_code": "VYMM",
      "airport_name": "Mawlamyaing Airport"
    },
    {
      "city": "Mayaguana",
      "country": "Bahamas",
      "IATA": "MYG",
      "icao_code": "MYMM",
      "airport_name": "Mayaguana Airport"
    },
    {
      "city": "Mayaguez",
      "country": "Puerto Rico",
      "IATA": "MAZ",
      "icao_code": "TJMZ",
      "airport_name": "Eugenio María de Hostos Airport"
    },
    {
      "city": "Mayo",
      "country": "Canada",
      "IATA": "YMA",
      "icao_code": "CYMA",
      "airport_name": "Mayo Airport"
    },
    {
      "city": "Mayport",
      "country": "United States",
      "IATA": "NRB",
      "airport_name": "Naval Station Mayport"
    },
    {
      "city": "Mayumba",
      "country": "Gabon",
      "IATA": "MYB",
      "icao_code": "FOOY",
      "airport_name": "Mayumba Airport"
    },
    {
      "city": "Mazar-i-sharif",
      "country": "Afghanistan",
      "IATA": "MZR",
      "icao_code": "OAMS",
      "airport_name": "Mazar-e Sharif International Airport"
    },
    {
      "city": "Mazatlan",
      "country": "Mexico",
      "IATA": "MZT",
      "icao_code": "MMMZ",
      "airport_name": "General Rafael Buelna International Airport"
    },
    {
      "city": "Mazuffarpur",
      "country": "India",
      "IATA": "MZU",
      "icao_code": "VEMZ",
      "airport_name": "Muzzafarpur Airport"
    },
    {
      "city": "Mbambanakira",
      "country": "Solomon Islands",
      "IATA": "MBU",
      "airport_name": "Babanakira Airport"
    },
    {
      "city": "Mbandaka",
      "country": "Congo (Kinshasa)",
      "IATA": "MDK",
      "icao_code": "FZEA",
      "airport_name": "Mbandaka Airport"
    },
    {
      "city": "Mbeya",
      "country": "Tanzania",
      "IATA": "MBI",
      "icao_code": "HTMB",
      "airport_name": "Mbeya Airport"
    },
    {
      "city": "Mbuji-mayi",
      "country": "Congo (Kinshasa)",
      "IATA": "MJM",
      "icao_code": "FZWA",
      "airport_name": "Mbuji Mayi Airport"
    },
    {
      "city": "Mc Comb",
      "country": "United States",
      "IATA": "MCB",
      "airport_name": "McComb-Pike County Airport "
    },
    {
      "city": "McArthur River Mine",
      "country": "Australia",
      "IATA": "MCV",
      "icao_code": "YMHU",
      "airport_name": "McArthur River Mine Airport"
    },
    {
      "city": "McCall",
      "country": "United States",
      "IATA": "MYL",
      "icao_code": "KMYL",
      "airport_name": "McCall Municipal Airport"
    },
    {
      "city": "McCook",
      "country": "United States",
      "IATA": "MCK",
      "icao_code": "KMCK",
      "airport_name": "McCook Ben Nelson Regional Airport"
    },
    {
      "city": "McKinley Park",
      "country": "United States",
      "IATA": "MCL",
      "airport_name": "McKinley National Park Airport"
    },
    {
      "city": "Mcalester",
      "country": "United States",
      "IATA": "MLC",
      "icao_code": "KMLC",
      "airport_name": "McAlester Regional Airport"
    },
    {
      "city": "Mcallen",
      "country": "United States",
      "IATA": "MFE",
      "icao_code": "KMFE",
      "airport_name": "McAllen-Miller International Airport"
    },
    {
      "city": "Mcgrath",
      "country": "United States",
      "IATA": "MCG",
      "icao_code": "PAMC",
      "airport_name": "McGrath Airport"
    },
    {
      "city": "Meadow Lake",
      "country": "Canada",
      "IATA": "YLJ",
      "icao_code": "CYLJ",
      "airport_name": "Meadow Lake Airport"
    },
    {
      "city": "Mecheria",
      "country": "Algeria",
      "IATA": "MZW",
      "icao_code": "DAAY",
      "airport_name": "Mécheria Airport"
    },
    {
      "city": "Medan",
      "country": "Indonesia",
      "IATA": "KNO",
      "airport_name": "Knokke-Heist Westkapelle Heliport"
    },
    {
      "city": "Medan",
      "country": "Indonesia",
      "IATA": "MES",
      "icao_code": "WIMM",
      "airport_name": "Polonia International Airport"
    },
    {
      "city": "Medellin",
      "country": "Colombia",
      "IATA": "EOH",
      "icao_code": "SKMD",
      "airport_name": "Olaya Herrera Airport"
    },
    {
      "city": "Medford",
      "country": "United States",
      "IATA": "MFR",
      "icao_code": "KMFR",
      "airport_name": "Rogue Valley International-Medford Airport"
    },
    {
      "city": "Medicine Hat",
      "country": "Canada",
      "IATA": "YXH",
      "icao_code": "CYXH",
      "airport_name": "Medicine Hat Airport"
    },
    {
      "city": "Meekatharra",
      "country": "Australia",
      "IATA": "MKR",
      "icao_code": "YMEK",
      "airport_name": "Meekatharra Airport"
    },
    {
      "city": "Meghauli",
      "country": "Nepal",
      "IATA": "MEY",
      "icao_code": "VNMG",
      "airport_name": "Meghauli Airport"
    },
    {
      "city": "Mehamn",
      "country": "Norway",
      "IATA": "MEH",
      "icao_code": "ENMR",
      "airport_name": "Mehamn Airport"
    },
    {
      "city": "Meixian",
      "country": "China",
      "IATA": "MXZ",
      "icao_code": "ZGMX",
      "airport_name": "Meixian Airport"
    },
    {
      "city": "Mekane Selam",
      "country": "Ethiopia",
      "IATA": "MKS",
      "icao_code": "HAMA",
      "airport_name": "Mekane Selam Airport"
    },
    {
      "city": "Meknes",
      "country": "Morocco",
      "IATA": "MEK",
      "icao_code": "GMFM",
      "airport_name": "Bassatine Airport"
    },
    {
      "city": "Mekoryuk",
      "country": "United States",
      "IATA": "MYU",
      "icao_code": "PAMY",
      "airport_name": "Mekoryuk Airport"
    },
    {
      "city": "Melbourne",
      "country": "United States",
      "IATA": "MLB",
      "icao_code": "KMLB",
      "airport_name": "Melbourne Orlando International Airport"
    },
    {
      "city": "Melbourne",
      "country": "Australia",
      "IATA": "MEL",
      "icao_code": "YMML",
      "airport_name": "Melbourne Airport"
    },
    {
      "city": "Melbourne",
      "country": "Australia",
      "IATA": "MBW",
      "icao_code": "YMMB",
      "airport_name": "Moorabbin (Harry Hawker) Airport"
    },
    {
      "city": "Melbourne",
      "country": "Australia",
      "IATA": "MEB",
      "icao_code": "YMEN",
      "airport_name": "Laurinburg-Maxton Airport"
    },
    {
      "city": "Melilla",
      "country": "Spain",
      "IATA": "MLN",
      "icao_code": "GEML",
      "airport_name": "Melilla Airport"
    },
    {
      "city": "Melo",
      "country": "Uruguay",
      "IATA": "MLZ",
      "icao_code": "SUMO",
      "airport_name": "Cerro Largo International Airport"
    },
    {
      "city": "Melonguane",
      "country": "Indonesia",
      "IATA": "MNA",
      "icao_code": "WAMN",
      "airport_name": "Melangguane Airport"
    },
    {
      "city": "Memanbetsu",
      "country": "Japan",
      "IATA": "MMB",
      "icao_code": "RJCM",
      "airport_name": "Memanbetsu Airport"
    },
    {
      "city": "Memmingen",
      "country": "Germany",
      "IATA": "FMM",
      "icao_code": "EDJA",
      "airport_name": "Memmingen Airport"
    },
    {
      "city": "Memphis",
      "country": "United States",
      "IATA": "MEM",
      "icao_code": "KMEM",
      "airport_name": "Memphis International Airport"
    },
    {
      "city": "Mende",
      "country": "France",
      "IATA": "MEN",
      "icao_code": "LFNB",
      "airport_name": "Mende-Brenoux Airport"
    },
    {
      "city": "Mendi",
      "country": "Papua New Guinea",
      "IATA": "MDU",
      "icao_code": "AYMN",
      "airport_name": "Mendi Airport"
    },
    {
      "city": "Mendoza",
      "country": "Argentina",
      "IATA": "MDZ",
      "icao_code": "SAME",
      "airport_name": "Gov. Francisco Gabrielli International Airport "
    },
    {
      "city": "Mengnai",
      "country": "China",
      "IATA": "HTT",
      "icao_code": "ZLHX",
      "airport_name": "Mengnai Hotan Airport"
    },
    {
      "city": "Menongue",
      "country": "Angola",
      "IATA": "SPP",
      "icao_code": "FNME",
      "airport_name": "Menongue Airport"
    },
    {
      "city": "Menorca",
      "country": "Spain",
      "IATA": "MAH",
      "icao_code": "LEMH",
      "airport_name": "Menorca Airport"
    },
    {
      "city": "Merauke",
      "country": "Indonesia",
      "IATA": "MKQ",
      "icao_code": "WAKK",
      "airport_name": "Mopah International Airport"
    },
    {
      "city": "Merced",
      "country": "United States",
      "IATA": "MCE",
      "icao_code": "KMCE",
      "airport_name": "Merced Regional Airport"
    },
    {
      "city": "Merced",
      "country": "United States",
      "IATA": "MER",
      "icao_code": "KMER",
      "airport_name": "Castle Airport"
    },
    {
      "city": "Mercury",
      "country": "United States",
      "IATA": "DRA",
      "icao_code": "KDRA",
      "airport_name": "Desert Rock Airport"
    },
    {
      "city": "Merida",
      "country": "Venezuela",
      "IATA": "MRD",
      "icao_code": "SVMD",
      "airport_name": "Alberto Carnevalli Airport"
    },
    {
      "city": "Merida",
      "country": "Mexico",
      "IATA": "MID",
      "icao_code": "MMMD",
      "airport_name": "Mérida-Rejón Airport"
    },
    {
      "city": "Meridian",
      "country": "United States",
      "IATA": "MEI",
      "icao_code": "KMEI",
      "airport_name": "Meridian Regional Airport"
    },
    {
      "city": "Merimbula",
      "country": "Australia",
      "IATA": "MIM",
      "icao_code": "YMER",
      "airport_name": "Merimbula Airport"
    },
    {
      "city": "Merowe",
      "country": "Sudan",
      "IATA": "MWE",
      "icao_code": "HSMR",
      "airport_name": "Merowe Airport"
    },
    {
      "city": "Mersa-matruh",
      "country": "Egypt",
      "IATA": "MUH",
      "icao_code": "HEMM",
      "airport_name": "Marsa Matruh International Airport"
    },
    {
      "city": "Meru National Park",
      "country": "Kenya",
      "IATA": "JJM",
      "icao_code": "HKMK",
      "airport_name": "Mulika Lodge Airport"
    },
    {
      "city": "Merville",
      "country": "France",
      "IATA": "HZB",
      "icao_code": "LFQT",
      "airport_name": "Merville - Calonne Airport"
    },
    {
      "city": "Merzifon",
      "country": "Turkey",
      "IATA": "MZH",
      "icao_code": "LTAP",
      "airport_name": "Amasya Merzifon Airport"
    },
    {
      "city": "Mesa",
      "country": "United States",
      "IATA": "MSC",
      "airport_name": "Falcon Field"
    },
    {
      "city": "Mesa",
      "country": "United States",
      "IATA": "AZA",
      "icao_code": "KIWA",
      "airport_name": "Phoenix-Mesa Gateway Airport "
    },
    {
      "city": "Metakatla",
      "country": "United States",
      "IATA": "MTM",
      "icao_code": "PAMM",
      "airport_name": "Metlakatla Seaplane Base"
    },
    {
      "city": "Metz",
      "country": "France",
      "IATA": "MZM",
      "airport_name": "Metz-Frescaty Air Base"
    },
    {
      "city": "Metz",
      "country": "France",
      "IATA": "ETZ",
      "icao_code": "LFJL",
      "airport_name": "Metz–Nancy–Lorraine Airport"
    },
    {
      "city": "Metzada",
      "country": "Israel",
      "IATA": "MTZ",
      "icao_code": "LLMZ",
      "airport_name": "Bar Yehuda Airfield"
    },
    {
      "city": "Mexicali",
      "country": "Mexico",
      "IATA": "MXL",
      "icao_code": "MMML",
      "airport_name": "General Rodolfo Sánchez Taboada International Airport"
    },
    {
      "city": "Mexico City",
      "country": "Mexico",
      "IATA": "MEX",
      "icao_code": "MMMX",
      "airport_name": "Aeropuerto Internacional Benito Juárez"
    },
    {
      "city": "Mfuwe",
      "country": "Zambia",
      "IATA": "MFU",
      "icao_code": "FLMF",
      "airport_name": "Mfuwe Airport"
    },
    {
      "city": "South Florida, Miami",
      "country": "United States",
      "IATA": "OPF",
      "icao_code": "KOPF",
      "airport_name": "Miami-Opa Locka Executive Airport"
    },
    {
      "city": "South Florida, Opa-Locka",
      "country": "United States",
      "IATA": "OPF",
      "icao_code": "KOPF",
      "airport_name": "Miami-Opa Locka Executive Airport"
    },
    {
      "city": "South Florida, Miami",
      "country": "United States",
      "IATA": "TNT",
      "icao_code": "KTNT",
      "airport_name": "Dade-Collier Training and Transition Airport"
    },
    {
      "city": "South Florida, Miami",
      "country": "United States",
      "IATA": "MIA",
      "icao_code": "KMIA",
      "airport_name": "Miami International Airport"
    },
    {
      "city": "Miandrivazo",
      "country": "Madagascar",
      "IATA": "ZVA",
      "airport_name": "Miandrivazo Airport"
    },
    {
      "city": "Mianwali",
      "country": "Pakistan",
      "IATA": "MWD",
      "icao_code": "OPMI",
      "airport_name": "PAF Base M.M. Alam"
    },
    {
      "city": "Mianyang",
      "country": "China",
      "IATA": "MIG",
      "icao_code": "EDPN",
      "airport_name": "Mianyang Nanjiao Airport"
    },
    {
      "city": "Michigan City",
      "country": "United States",
      "IATA": "MGC",
      "icao_code": "KMGC",
      "airport_name": "Michigan City Municipal Airport"
    },
    {
      "city": "Middle Caicos",
      "country": "Turks and Caicos Islands",
      "IATA": "MDS",
      "icao_code": "MBMC",
      "airport_name": "Middle Caicos Airport"
    },
    {
      "city": "Midland",
      "country": "United States",
      "IATA": "MAF",
      "icao_code": "KMAF",
      "airport_name": "Midland International Air and Space Port"
    },
    {
      "city": "Midway",
      "country": "Midway Islands",
      "IATA": "MDY",
      "icao_code": "PMDY",
      "airport_name": "Henderson Field"
    },
    {
      "city": "Miho",
      "country": "Japan",
      "IATA": "YGJ",
      "icao_code": "RJOH",
      "airport_name": "Miho Yonago Airport"
    },
    {
      "city": "Mikkeli",
      "country": "Finland",
      "IATA": "MIK",
      "icao_code": "EFMI",
      "airport_name": "Mikkeli Airport"
    },
    {
      "city": "Milan",
      "country": "Italy",
      "IATA": "LIN",
      "icao_code": "LIML",
      "airport_name": "Milan Linate Airport"
    },
    {
      "city": "Milano",
      "country": "Italy",
      "IATA": "MXP",
      "icao_code": "LIMC",
      "airport_name": "Milan–Malpensa Airport"
    },
    {
      "city": "Mildenhall",
      "country": "United Kingdom",
      "IATA": "MHZ",
      "airport_name": "Royal Air Force Mildenhall Mildenhall"
    },
    {
      "city": "Mildura",
      "country": "Australia",
      "IATA": "MQL",
      "icao_code": "YMIA",
      "airport_name": "Mildura Airport"
    },
    {
      "city": "Miles City",
      "country": "United States",
      "IATA": "MLS",
      "icao_code": "KMLS",
      "airport_name": "Miles City Airport "
    },
    {
      "city": "Milford Sound",
      "country": "New Zealand",
      "IATA": "MFN",
      "icao_code": "NZMF",
      "airport_name": "Milford Sound Airport"
    },
    {
      "city": "Mili Island",
      "country": "Marshall Islands",
      "IATA": "MIJ",
      "airport_name": "Mili Airport"
    },
    {
      "city": "Milingimbi",
      "country": "Australia",
      "IATA": "MGT",
      "icao_code": "YMGB",
      "airport_name": "Milingimbi Airport"
    },
    {
      "city": "Millington",
      "country": "United States",
      "IATA": "NQA",
      "icao_code": "KNQA",
      "airport_name": "Millington Regional Jetport"
    },
    {
      "city": "Millville",
      "country": "United States",
      "IATA": "MIV",
      "icao_code": "KMIV",
      "airport_name": "Millville Municipal Airport"
    },
    {
      "city": "Milos",
      "country": "Greece",
      "IATA": "MLO",
      "icao_code": "LGML",
      "airport_name": "Milos Island National Airport"
    },
    {
      "city": "Milton",
      "country": "United States",
      "IATA": "NSE",
      "airport_name": "Naval Air Station Whiting Field – North"
    },
    {
      "city": "Milwaukee",
      "country": "United States",
      "IATA": "MWC",
      "icao_code": "KMWC",
      "airport_name": "Lawrence J. Timmerman Airport"
    },
    {
      "city": "Milwaukee",
      "country": "United States",
      "IATA": "MKE",
      "icao_code": "KMKE",
      "airport_name": "General Mitchell International Airport"
    },
    {
      "city": "Minacu",
      "country": "Brazil",
      "IATA": "MQH",
      "icao_code": "SBMC",
      "airport_name": "Minaçu Airport"
    },
    {
      "city": "Minami Daito",
      "country": "Japan",
      "IATA": "MMD",
      "icao_code": "ROMD",
      "airport_name": "Minami Daito Airport"
    },
    {
      "city": "Minami Tori Shima",
      "country": "Japan",
      "IATA": "MUS",
      "airport_name": "Minami Torishima Airport"
    },
    {
      "city": "Minatitlan",
      "country": "Mexico",
      "IATA": "MTT",
      "icao_code": "MMMT",
      "airport_name": "Minatitlán/Coatzacoalcos International Airport"
    },
    {
      "city": "Mineral Wells",
      "country": "United States",
      "IATA": "MWL",
      "icao_code": "KMWL",
      "airport_name": "Mineral Wells Airport"
    },
    {
      "city": "Mineralnye Vody",
      "country": "Russia",
      "IATA": "MRV",
      "icao_code": "URMM",
      "airport_name": "Mineralnye Vody Airport"
    },
    {
      "city": "Minhad AB",
      "country": "United Arab Emirates",
      "IATA": "NHD",
      "airport_name": "Al Minhad Air Base"
    },
    {
      "city": "Minna",
      "country": "Nigeria",
      "IATA": "MXJ",
      "icao_code": "DNMN",
      "airport_name": "Minna Airport"
    },
    {
      "city": "Minneapolis",
      "country": "United States",
      "IATA": "MSP",
      "icao_code": "KMSP",
      "airport_name": "Minneapolis-Saint Paul International Airport "
    },
    {
      "city": "Minocqua - Woodruff",
      "country": "United States",
      "IATA": "ARV",
      "icao_code": "KARV",
      "airport_name": "Lakeland Airport "
    },
    {
      "city": "Minot",
      "country": "United States",
      "IATA": "MIB",
      "airport_name": "Minot Air Force Base"
    },
    {
      "city": "Minot",
      "country": "United States",
      "IATA": "MOT",
      "icao_code": "KMOT",
      "airport_name": "Minot International Airport"
    },
    {
      "city": "Minsk",
      "country": "Belarus",
      "IATA": "MHP",
      "icao_code": "UMMM",
      "airport_name": "Minsk-1 Airport"
    },
    {
      "city": "Minsk 2",
      "country": "Belarus",
      "IATA": "MSQ",
      "icao_code": "UMMS",
      "airport_name": "Minsk National Airport"
    },
    {
      "city": "Miquelon",
      "country": "Saint Pierre and Miquelon",
      "IATA": "MQC",
      "icao_code": "LFVM",
      "airport_name": "Miquelon Airport"
    },
    {
      "city": "Miramar",
      "country": "United States",
      "IATA": "NKX",
      "airport_name": "Marine Corps Air Station Miramar"
    },
    {
      "city": "Miri",
      "country": "Malaysia",
      "IATA": "MYY",
      "icao_code": "WBGR",
      "airport_name": "Miri Airport"
    },
    {
      "city": "Mirnyj",
      "country": "Russia",
      "IATA": "MJZ",
      "icao_code": "UERR",
      "airport_name": "Mirny Airport"
    },
    {
      "city": "Misawa",
      "country": "Japan",
      "IATA": "MSJ",
      "icao_code": "RJSM",
      "airport_name": "Misawa Air Base"
    },
    {
      "city": "Misima Island",
      "country": "Papua New Guinea",
      "IATA": "MIS",
      "icao_code": "AYMS",
      "airport_name": "Misima Island Airport"
    },
    {
      "city": "Missoula",
      "country": "United States",
      "IATA": "MSO",
      "icao_code": "KMSO",
      "airport_name": "Missoula International Airport"
    },
    {
      "city": "Mitchell",
      "country": "United States",
      "IATA": "MHE",
      "icao_code": "KMHE",
      "airport_name": "Mitchell Municipal Airport"
    },
    {
      "city": "Mitiaro Island",
      "country": "Cook Islands",
      "IATA": "MOI",
      "icao_code": "NCMR",
      "airport_name": "Mitiaro Island Airport"
    },
    {
      "city": "Mitu",
      "country": "Colombia",
      "IATA": "MVP",
      "icao_code": "SKMU",
      "airport_name": "Fabio Alberto León Bentley Airport"
    },
    {
      "city": "Miyakejima",
      "country": "Japan",
      "IATA": "MYE",
      "icao_code": "RJTQ",
      "airport_name": "Miyakejima Airport"
    },
    {
      "city": "Miyako",
      "country": "Japan",
      "IATA": "MMY",
      "icao_code": "ROMY",
      "airport_name": "Miyako Airport"
    },
    {
      "city": "Miyazaki",
      "country": "Japan",
      "IATA": "KMI",
      "icao_code": "RJFM",
      "airport_name": "Miyazaki Airport"
    },
    {
      "city": "Mizan Teferi",
      "country": "Ethiopia",
      "IATA": "MTF",
      "icao_code": "HAMT",
      "airport_name": "Mizan Teferi Airport"
    },
    {
      "city": "Mkuze",
      "country": "South Africa",
      "IATA": "MZQ",
      "airport_name": "Mkuze Airport"
    },
    {
      "city": "Moa",
      "country": "Cuba",
      "IATA": "MOA",
      "icao_code": "MUMO",
      "airport_name": "Orestes Acosta Airport"
    },
    {
      "city": "Moab",
      "country": "United States",
      "IATA": "CNY",
      "icao_code": "KCNY",
      "airport_name": "Canyonlands Field"
    },
    {
      "city": "Moala",
      "country": "Fiji",
      "IATA": "MFJ",
      "icao_code": "NFMO",
      "airport_name": "Moala Airport"
    },
    {
      "city": "Mobile",
      "country": "United States",
      "IATA": "MOB",
      "icao_code": "KMOB",
      "airport_name": "Mobile Regional Airport"
    },
    {
      "city": "Mobile",
      "country": "United States",
      "IATA": "BFM",
      "icao_code": "KBFM",
      "airport_name": "Mobile Downtown Airport"
    },
    {
      "city": "Mobridge",
      "country": "United States",
      "IATA": "MBG",
      "icao_code": "KMBG",
      "airport_name": "Mobridge Municipal Airport"
    },
    {
      "city": "Mocamedes",
      "country": "Angola",
      "IATA": "MSZ",
      "icao_code": "FNMO",
      "airport_name": "Namibe Airport"
    },
    {
      "city": "Mocimboa Da Praia",
      "country": "Mozambique",
      "IATA": "MZB",
      "icao_code": "FQMP",
      "airport_name": "Mocimboa da Praia Airport"
    },
    {
      "city": "Mocord",
      "country": "Brazil",
      "IATA": "MVF",
      "icao_code": "SBMW",
      "airport_name": "Gov. Dix-Sept Rosado Airport"
    },
    {
      "city": "Modesto",
      "country": "United States",
      "IATA": "MOD",
      "icao_code": "KMOD",
      "airport_name": "Modesto City-County Airport "
    },
    {
      "city": "Moenchengladbach",
      "country": "Germany",
      "IATA": "MGL",
      "icao_code": "EDLN",
      "airport_name": "Düsseldorf Mönchengladbach Airport"
    },
    {
      "city": "Moengo",
      "country": "Suriname",
      "IATA": "MOJ",
      "airport_name": "Moengo International Airport"
    },
    {
      "city": "Moenjodaro",
      "country": "Pakistan",
      "IATA": "MJD",
      "icao_code": "OPMJ",
      "airport_name": "Picayune Municipal Airport"
    },
    {
      "city": "Mogadishu",
      "country": "Somalia",
      "IATA": "MGQ",
      "icao_code": "HCMM",
      "airport_name": "Aden Adde International Airport"
    },
    {
      "city": "Mogilev",
      "country": "Belarus",
      "IATA": "MVQ",
      "icao_code": "UMOO",
      "airport_name": "Mogilev Airport"
    },
    {
      "city": "Mohanbari",
      "country": "India",
      "IATA": "DIB",
      "icao_code": "VEMN",
      "airport_name": "Dibrugarh Airport "
    },
    {
      "city": "Mohe County",
      "country": "China",
      "IATA": "OHE",
      "icao_code": "ZYMH",
      "airport_name": "Mohe Gulian Airport"
    },
    {
      "city": "Mohed",
      "country": "Sweden",
      "IATA": "SQO",
      "icao_code": "ESUD",
      "airport_name": "Storuman Airport"
    },
    {
      "city": "Moheli",
      "country": "Comoros",
      "IATA": "NWA",
      "icao_code": "FMCI",
      "airport_name": "Mohéli Bandar Es Eslam Airport"
    },
    {
      "city": "Mojave",
      "country": "United States",
      "IATA": "MHV",
      "icao_code": "KMHV",
      "airport_name": "Mojave Air and Space Port"
    },
    {
      "city": "Mokpo",
      "country": "South Korea",
      "IATA": "MPK",
      "airport_name": "Mokpo Airport"
    },
    {
      "city": "Mokuti Lodge",
      "country": "Namibia",
      "IATA": "OKU",
      "icao_code": "FYMO",
      "airport_name": "Mokuti Lodge Airport"
    },
    {
      "city": "Molde",
      "country": "Norway",
      "IATA": "MOL",
      "icao_code": "ENML",
      "airport_name": "Molde Airport, Årø"
    },
    {
      "city": "Moline",
      "country": "United States",
      "IATA": "MLI",
      "icao_code": "KMLI",
      "airport_name": "Quad City International Airport"
    },
    {
      "city": "Molokai",
      "country": "United States",
      "IATA": "LUP",
      "icao_code": "PHLU",
      "airport_name": "Kalaupapa Airport"
    },
    {
      "city": "Molokai",
      "country": "United States",
      "IATA": "MKK",
      "icao_code": "PHMK",
      "airport_name": "Molokai Airport"
    },
    {
      "city": "Mombasa",
      "country": "Kenya",
      "IATA": "MBA",
      "icao_code": "HKMO",
      "airport_name": "Moi International Airport"
    },
    {
      "city": "Momeik",
      "country": "Burma",
      "IATA": "MOE",
      "icao_code": "VYMO",
      "airport_name": "Momeik Airport"
    },
    {
      "city": "Momote",
      "country": "Papua New Guinea",
      "IATA": "MAS",
      "icao_code": "AYMO",
      "airport_name": "Momote Airport"
    },
    {
      "city": "Monastir",
      "country": "Tunisia",
      "IATA": "MIR",
      "icao_code": "DTMB",
      "airport_name": "Monastir Habib Bourguiba International Airport"
    },
    {
      "city": "Monbetsu",
      "country": "Japan",
      "IATA": "MBE",
      "icao_code": "RJEB",
      "airport_name": "Monbetsu Airport"
    },
    {
      "city": "Monclova",
      "country": "Mexico",
      "IATA": "LOV",
      "icao_code": "MMMV",
      "airport_name": "Venustiano Carranza International Airport"
    },
    {
      "city": "Moncton",
      "country": "Canada",
      "IATA": "YQM",
      "icao_code": "CYQM",
      "airport_name": "Greater Moncton International Airport"
    },
    {
      "city": "Mong Hsat",
      "country": "Burma",
      "IATA": "MOG",
      "icao_code": "VYMS",
      "airport_name": "Monghsat Airport"
    },
    {
      "city": "Mongu",
      "country": "Zambia",
      "IATA": "MNR",
      "icao_code": "FLMG",
      "airport_name": "Mongu Airport"
    },
    {
      "city": "Monkey Bay",
      "country": "Malawi",
      "IATA": "MYZ",
      "icao_code": "FWMY",
      "airport_name": "Marysville Municipal Airport"
    },
    {
      "city": "Monroe",
      "country": "United States",
      "IATA": "MLU",
      "icao_code": "KMLU",
      "airport_name": "Monroe Regional Airport"
    },
    {
      "city": "Monrovia",
      "country": "Liberia",
      "IATA": "ROB",
      "icao_code": "GLRB",
      "airport_name": "Roberts International Airport"
    },
    {
      "city": "Monrovia",
      "country": "Liberia",
      "IATA": "MLW",
      "icao_code": "GLMR",
      "airport_name": "Spriggs Payne Airport"
    },
    {
      "city": "Mont Joli",
      "country": "Canada",
      "IATA": "YYY",
      "icao_code": "CYYY",
      "airport_name": "Mont-Joli Airport"
    },
    {
      "city": "Mont-Tremblant",
      "country": "Canada",
      "IATA": "YTM",
      "icao_code": "CYFJ",
      "airport_name": "Mont Tremblant International Airport"
    },
    {
      "city": "Montauban",
      "country": "France",
      "IATA": "XMW",
      "airport_name": "Montauban Airport"
    },
    {
      "city": "Montauk",
      "country": "United States",
      "IATA": "MTP",
      "icao_code": "KMTP",
      "airport_name": "Montauk Airport"
    },
    {
      "city": "Monte Caseros",
      "country": "Argentina",
      "IATA": "MCS",
      "icao_code": "SARM",
      "airport_name": "Monte Caseros Airport"
    },
    {
      "city": "Monte Real",
      "country": "Portugal",
      "IATA": "QLR",
      "airport_name": "Monte Real Airport"
    },
    {
      "city": "Montego Bay",
      "country": "Jamaica",
      "IATA": "MBJ",
      "icao_code": "MKJS",
      "airport_name": "Sangster International Airport"
    },
    {
      "city": "Monterey",
      "country": "United States",
      "IATA": "MRY",
      "icao_code": "KMRY",
      "airport_name": "Monterey Regional Airport"
    },
    {
      "city": "Monteria",
      "country": "Colombia",
      "IATA": "MTR",
      "icao_code": "SKMR",
      "airport_name": "Los Garzones Airport"
    },
    {
      "city": "Monterrey",
      "country": "Mexico",
      "IATA": "MTY",
      "icao_code": "MMMY",
      "airport_name": "Monterrey International Airport"
    },
    {
      "city": "Monterrey",
      "country": "Mexico",
      "IATA": "NTR",
      "icao_code": "MMAN",
      "airport_name": "Del Norte International Airport"
    },
    {
      "city": "Montes Claros",
      "country": "Brazil",
      "IATA": "MOC",
      "icao_code": "SBMK",
      "airport_name": "Montes Claros/Mário Ribeiro Airport"
    },
    {
      "city": "Montevideo",
      "country": "Uruguay",
      "IATA": "MVD",
      "icao_code": "SUMU",
      "airport_name": "Carrasco International Airport"
    },
    {
      "city": "Montgomery",
      "country": "United States",
      "IATA": "MGJ",
      "icao_code": "KMGJ",
      "airport_name": "Orange County Airport"
    },
    {
      "city": "Montgomery",
      "country": "United States",
      "IATA": "MXF",
      "icao_code": "KMXF",
      "airport_name": "Maxwell Air Force Base"
    },
    {
      "city": "Montlucon-gueret",
      "country": "France",
      "IATA": "MCU",
      "icao_code": "LFLT",
      "airport_name": "Montluçon-Domérat Airport"
    },
    {
      "city": "Montpelier",
      "country": "United States",
      "IATA": "MPV",
      "icao_code": "KMPV",
      "airport_name": "Edward F. Knapp State Airport"
    },
    {
      "city": "Montpellier",
      "country": "France",
      "IATA": "MPL",
      "icao_code": "LFMT",
      "airport_name": "Montpellier–Méditerranée Airport"
    },
    {
      "city": "Montreal",
      "country": "Canada",
      "IATA": "YUL",
      "icao_code": "CYUL",
      "airport_name": "Montreal-Pierre Elliott Trudeau International Airport"
    },
    {
      "city": "Montreal",
      "country": "Canada",
      "IATA": "YMX",
      "icao_code": "CYMX",
      "airport_name": "Montreal-Mirabel International Airport"
    },
    {
      "city": "Montreal",
      "country": "Canada",
      "IATA": "YHU",
      "icao_code": "CYHU",
      "airport_name": "Montreal/St-Hubert Airport"
    },
    {
      "city": "Montrose CO",
      "country": "United States",
      "IATA": "MTJ",
      "icao_code": "KMTJ",
      "airport_name": "Montrose Regional Airport"
    },
    {
      "city": "Monument Valley",
      "country": "United States",
      "IATA": "GMV",
      "airport_name": "Monument Valley Airport "
    },
    {
      "city": "Monywa",
      "country": "Burma",
      "IATA": "NYW",
      "icao_code": "VYMY",
      "airport_name": "Monywa Airport"
    },
    {
      "city": "Moomba",
      "country": "Australia",
      "IATA": "MOO",
      "icao_code": "YOOM",
      "airport_name": "Moomba Airport"
    },
    {
      "city": "Moorea",
      "country": "French Polynesia",
      "IATA": "MOZ",
      "icao_code": "NTTM",
      "airport_name": "Moorea Airport "
    },
    {
      "city": "Moose Jaw",
      "country": "Canada",
      "IATA": "YMJ",
      "icao_code": "CYMJ",
      "airport_name": "Canadian Forces Base Moose Jaw"
    },
    {
      "city": "Moosonee",
      "country": "Canada",
      "IATA": "YMO",
      "icao_code": "CYMO",
      "airport_name": "Moosonee Airport"
    },
    {
      "city": "Mopti",
      "country": "Mali",
      "IATA": "MZI",
      "icao_code": "GAMB",
      "airport_name": "Mopti Airport"
    },
    {
      "city": "Mora",
      "country": "Sweden",
      "IATA": "MXX",
      "icao_code": "ESKM",
      "airport_name": "Mora–Siljan Airport"
    },
    {
      "city": "Morafenobe",
      "country": "Madagascar",
      "IATA": "TVA",
      "airport_name": "Morafenobe Airport"
    },
    {
      "city": "Moranbah",
      "country": "Australia",
      "IATA": "MOV",
      "icao_code": "YMRB",
      "airport_name": "Moranbah Airport"
    },
    {
      "city": "Moree",
      "country": "Australia",
      "IATA": "MRZ",
      "icao_code": "YMOR",
      "airport_name": "Moree Airport"
    },
    {
      "city": "Morelia",
      "country": "Mexico",
      "IATA": "MLM",
      "icao_code": "MMMM",
      "airport_name": "General Francisco J. Mujica International Airport"
    },
    {
      "city": "Morganton",
      "country": "United States",
      "IATA": "MRN",
      "icao_code": "KMRN",
      "airport_name": "Foothills Regional Airport"
    },
    {
      "city": "Morgantown",
      "country": "United States",
      "IATA": "MGW",
      "icao_code": "KMGW",
      "airport_name": "Morgantown Municipal Airport "
    },
    {
      "city": "Morlaix",
      "country": "France",
      "IATA": "MXN",
      "icao_code": "LFRU",
      "airport_name": "Morlaix Ploujean Airport"
    },
    {
      "city": "Mornington Island",
      "country": "Australia",
      "IATA": "ONG",
      "icao_code": "YMTI",
      "airport_name": "Mornington Island Airport"
    },
    {
      "city": "Moro",
      "country": "Papua New Guinea",
      "IATA": "MXH",
      "icao_code": "AYMR",
      "airport_name": "Moro Airport"
    },
    {
      "city": "Morombe",
      "country": "Madagascar",
      "IATA": "MXM",
      "icao_code": "FMSR",
      "airport_name": "Morombe Airport"
    },
    {
      "city": "Morondava",
      "country": "Madagascar",
      "IATA": "MOQ",
      "icao_code": "FMMV",
      "airport_name": "Morondava Airport"
    },
    {
      "city": "Moroni",
      "country": "Comoros",
      "IATA": "YVA",
      "icao_code": "FMCN",
      "airport_name": "Iconi Airport"
    },
    {
      "city": "Moroni",
      "country": "Comoros",
      "IATA": "HAH",
      "icao_code": "FMCH",
      "airport_name": "Prince Said Ibrahim International Airport"
    },
    {
      "city": "Morotai Island",
      "country": "Indonesia",
      "IATA": "OTI",
      "icao_code": "WAMR",
      "airport_name": "Leo Wattimena Airport"
    },
    {
      "city": "Morrisville",
      "country": "United States",
      "IATA": "MVL",
      "icao_code": "KMVL",
      "airport_name": "Morrisville-Stowe State Airport"
    },
    {
      "city": "Moruya",
      "country": "Australia",
      "IATA": "MYA",
      "icao_code": "YMRY",
      "airport_name": "Moruya Airport"
    },
    {
      "city": "Morwell",
      "country": "Australia",
      "IATA": "TGN",
      "airport_name": "Latrobe Regional Airport"
    },
    {
      "city": "Moscow",
      "country": "Russia",
      "IATA": "OSF",
      "icao_code": "UUMO",
      "airport_name": "Sheremetyevo International Airport"
    },
    {
      "city": "Moscow",
      "country": "Russia",
      "IATA": "BKA",
      "airport_name": "Bykovo Airport"
    },
    {
      "city": "Moscow",
      "country": "Russia",
      "IATA": "DME",
      "icao_code": "UUDD",
      "airport_name": "Domodedovo International Airport"
    },
    {
      "city": "Moscow",
      "country": "Russia",
      "IATA": "VKO",
      "icao_code": "UUWW",
      "airport_name": "Vnukovo International Airport"
    },
    {
      "city": "Moscow",
      "country": "Russia",
      "IATA": "SVO",
      "icao_code": "UUEE",
      "airport_name": "Sheremetyevo International Airport"
    },
    {
      "city": "Moshi",
      "country": "Tanzania",
      "IATA": "QSI",
      "airport_name": "Moshi Airport"
    },
    {
      "city": "Mosjoen",
      "country": "Norway",
      "IATA": "MJF",
      "icao_code": "ENMS",
      "airport_name": "Mosjøen Airport, Kjærstad"
    },
    {
      "city": "Mostar",
      "country": "Bosnia and Herzegovina",
      "IATA": "OMO",
      "icao_code": "LQMO",
      "airport_name": "Mostar Airport"
    },
    {
      "city": "Mosul",
      "country": "Iraq",
      "IATA": "OSM",
      "icao_code": "ORBM",
      "airport_name": "Mosul International Airport"
    },
    {
      "city": "Motueka",
      "country": "New Zealand",
      "IATA": "MZP",
      "airport_name": "Motueka Aerodrome"
    },
    {
      "city": "Mouila",
      "country": "Gabon",
      "IATA": "MJL",
      "icao_code": "FOGM",
      "airport_name": "Mouilla Ville Airport"
    },
    {
      "city": "Moundou",
      "country": "Chad",
      "IATA": "MQQ",
      "icao_code": "FTTD",
      "airport_name": "Moundou Airport"
    },
    {
      "city": "Mount Clemens",
      "country": "United States",
      "IATA": "MTC",
      "icao_code": "KMTC",
      "airport_name": "Selfridge Air National Guard Base"
    },
    {
      "city": "Mount Cook",
      "country": "New Zealand",
      "IATA": "MON",
      "icao_code": "NZGT",
      "airport_name": "Mount Cook Aerodrome"
    },
    {
      "city": "Mount Gambier",
      "country": "Australia",
      "IATA": "MGB",
      "icao_code": "YMTG",
      "airport_name": "Mount Gambier Airport"
    },
    {
      "city": "Mount Hagen",
      "country": "Papua New Guinea",
      "IATA": "HGU",
      "icao_code": "AYMH",
      "airport_name": "Mount Hagen Airport"
    },
    {
      "city": "Mount Holly",
      "country": "United States",
      "IATA": "LLY",
      "airport_name": "South Jersey Regional Airport "
    },
    {
      "city": "Mount Hotham",
      "country": "Australia",
      "IATA": "MHU",
      "icao_code": "YHOT",
      "airport_name": "Mount Hotham Airport"
    },
    {
      "city": "Mount Isa",
      "country": "Australia",
      "IATA": "ISA",
      "icao_code": "YBMA",
      "airport_name": "Mount Isa Airport"
    },
    {
      "city": "Mount Keith",
      "country": "Australia",
      "IATA": "WME",
      "icao_code": "YMNE",
      "airport_name": "Mount Keith Airport"
    },
    {
      "city": "Mount Magnet",
      "country": "Australia",
      "IATA": "MMG",
      "icao_code": "YMOG",
      "airport_name": "Mount Magnet Airport"
    },
    {
      "city": "Mount Pleasant",
      "country": "Falkland Islands",
      "IATA": "MPN",
      "icao_code": "EGYP",
      "airport_name": "RAF Mount Pleasant"
    },
    {
      "city": "Mount Pocono",
      "country": "United States",
      "IATA": "MPO",
      "icao_code": "KMPO",
      "airport_name": "Pocono Mountains Municipal Airport"
    },
    {
      "city": "Mountain Home",
      "country": "United States",
      "IATA": "WMH",
      "icao_code": "KBPK",
      "airport_name": "Ozark Regional Airport"
    },
    {
      "city": "Mountain Home",
      "country": "United States",
      "IATA": "MUO",
      "icao_code": "KMUO",
      "airport_name": "Mountain Home Air Force Base"
    },
    {
      "city": "Mountain View",
      "country": "United States",
      "IATA": "NUQ",
      "icao_code": "KNUQ",
      "airport_name": "Moffett Federal Airfield"
    },
    {
      "city": "Mountain Village",
      "country": "United States",
      "IATA": "MOU",
      "icao_code": "PAMO",
      "airport_name": "Mountain Village Airport"
    },
    {
      "city": "Moyale",
      "country": "Kenya",
      "IATA": "OYL",
      "icao_code": "HKMY",
      "airport_name": "Moyale Lower Airport"
    },
    {
      "city": "Mpacha",
      "country": "Namibia",
      "IATA": "MPA",
      "icao_code": "FAMP",
      "airport_name": "Katima Mulilo Airport"
    },
    {
      "city": "Mpumalanga",
      "country": "South Africa",
      "IATA": "MQP",
      "icao_code": "FAKN",
      "airport_name": "Kruger Mpumalanga International Airport"
    },
    {
      "city": "Mtwara",
      "country": "Tanzania",
      "IATA": "MYW",
      "icao_code": "HTMT",
      "airport_name": "Mtwara Airport"
    },
    {
      "city": "Muan",
      "country": "South Korea",
      "IATA": "MWX",
      "icao_code": "RKJB",
      "airport_name": "Muan International Airport"
    },
    {
      "city": "Muanda",
      "country": "Congo (Kinshasa)",
      "IATA": "MNB",
      "icao_code": "FZAG",
      "airport_name": "Muanda Airport"
    },
    {
      "city": "Muang Xay",
      "country": "Laos",
      "IATA": "ODY",
      "icao_code": "VLOS",
      "airport_name": "Oudomsay Airport"
    },
    {
      "city": "Muara Bungo",
      "country": "Indonesia",
      "IATA": "BUU",
      "icao_code": "WIPI",
      "airport_name": "Buyo Airport"
    },
    {
      "city": "Mucuri",
      "country": "Brazil",
      "IATA": "MVS",
      "icao_code": "SNMU",
      "airport_name": "Mucuri Airport"
    },
    {
      "city": "Mudanjiang",
      "country": "China",
      "IATA": "MDG",
      "icao_code": "ZYMD",
      "airport_name": "Mudanjiang Hailang Airport"
    },
    {
      "city": "Mudgee",
      "country": "Australia",
      "IATA": "DGE",
      "icao_code": "YMDG",
      "airport_name": "Mudgee Airport"
    },
    {
      "city": "Mueda",
      "country": "Mozambique",
      "IATA": "MUD",
      "airport_name": "Mueda Airport"
    },
    {
      "city": "Muir",
      "country": "United States",
      "IATA": "MUI",
      "icao_code": "KMUI",
      "airport_name": "Muir Army Airfield"
    },
    {
      "city": "Mukah",
      "country": "Malaysia",
      "IATA": "MKM",
      "icao_code": "WBGK",
      "airport_name": "Mukah Airport"
    },
    {
      "city": "Mukalla",
      "country": "Yemen",
      "IATA": "RIY",
      "icao_code": "OYRN",
      "airport_name": "Riyan Airport"
    },
    {
      "city": "Mulhouse",
      "country": "France",
      "IATA": "BSL",
      "icao_code": "_BSL",
      "airport_name": "EuroAirport Basel-Mulhouse-Freiburg Airport"
    },
    {
      "city": "Mulia",
      "country": "Indonesia",
      "IATA": "LII",
      "icao_code": "WAJM",
      "airport_name": "Mulia Airport"
    },
    {
      "city": "Multan",
      "country": "Pakistan",
      "IATA": "MUX",
      "icao_code": "OPMT",
      "airport_name": "Multan International Airport"
    },
    {
      "city": "Mulu",
      "country": "Malaysia",
      "IATA": "MZV",
      "icao_code": "WBFC",
      "airport_name": "Mulu Airport"
    },
    {
      "city": "Mumbai",
      "country": "India",
      "IATA": "BOM",
      "icao_code": "VABB",
      "airport_name": "Chhatrapati Shivaji Maharaj International Airport"
    },
    {
      "city": "Muncie",
      "country": "United States",
      "IATA": "MIE",
      "airport_name": "Delaware County Regional Airport "
    },
    {
      "city": "Munda",
      "country": "Solomon Islands",
      "IATA": "MUA",
      "icao_code": "AGGM",
      "airport_name": "Munda Airport"
    },
    {
      "city": "Munich",
      "country": "Germany",
      "IATA": "MUC",
      "icao_code": "EDDM",
      "airport_name": "Munich Airport "
    },
    {
      "city": "Munster",
      "country": "Germany",
      "IATA": "FMO",
      "icao_code": "EDDG",
      "airport_name": "Munster Osnabruck International Airport"
    },
    {
      "city": "Murcia",
      "country": "Spain",
      "IATA": "RMU",
      "icao_code": "LEMI",
      "airport_name": "Murcia-San Javier Airport"
    },
    {
      "city": "Murcia",
      "country": "Spain",
      "IATA": "MJV",
      "icao_code": "LELC",
      "airport_name": "Murcia-San Javier Airport"
    },
    {
      "city": "Muren",
      "country": "Mongolia",
      "IATA": "MXV",
      "icao_code": "ZMMN",
      "airport_name": "Mörön Airport"
    },
    {
      "city": "Murmansk",
      "country": "Russia",
      "IATA": "MMK",
      "icao_code": "ULMM",
      "airport_name": "Murmansk Airport"
    },
    {
      "city": "Murray Island",
      "country": "Australia",
      "IATA": "MYI",
      "icao_code": "YMUI",
      "airport_name": "Murray Island Airport"
    },
    {
      "city": "Murrieta-Temecula",
      "country": "United States",
      "IATA": "RBK",
      "airport_name": "French Valley Airport"
    },
    {
      "city": "Mus",
      "country": "Turkey",
      "IATA": "MSR",
      "icao_code": "LTCK",
      "airport_name": "Muş Airport"
    },
    {
      "city": "Muscat",
      "country": "Oman",
      "IATA": "MCT",
      "icao_code": "OOMS",
      "airport_name": "Seeb International Airport"
    },
    {
      "city": "Muscle Shoals",
      "country": "United States",
      "IATA": "MSL",
      "icao_code": "KMSL",
      "airport_name": "Northwest Alabama Regional Airport"
    },
    {
      "city": "Muskegon",
      "country": "United States",
      "IATA": "MKG",
      "icao_code": "KMKG",
      "airport_name": "Muskegon County Airport"
    },
    {
      "city": "Muskoka",
      "country": "Canada",
      "IATA": "YQA",
      "icao_code": "CYQA",
      "airport_name": "Muskoka Airport"
    },
    {
      "city": "Muskrat Dam",
      "country": "Canada",
      "IATA": "MSA",
      "icao_code": "CZMD",
      "airport_name": "Muskrat Dam Airport"
    },
    {
      "city": "Musoma",
      "country": "Tanzania",
      "IATA": "MUZ",
      "icao_code": "HTMU",
      "airport_name": "Musoma Airport"
    },
    {
      "city": "Musselwhite Mine",
      "country": "Canada",
      "IATA": "YBS",
      "airport_name": "Musselwhite Mine Airport"
    },
    {
      "city": "Mustique",
      "country": "Saint Vincent and the Grenadines",
      "IATA": "MQS",
      "icao_code": "TVSM",
      "airport_name": "Mustique Airport"
    },
    {
      "city": "Mutare",
      "country": "Zimbabwe",
      "IATA": "UTA",
      "icao_code": "FVMU",
      "airport_name": "Tunica Municipal Airport"
    },
    {
      "city": "Muzaffarabad",
      "country": "Pakistan",
      "IATA": "MFG",
      "icao_code": "OPMF",
      "airport_name": "Muzaffarabad Airport"
    },
    {
      "city": "Mwanza",
      "country": "Tanzania",
      "IATA": "MWZ",
      "icao_code": "HTMW",
      "airport_name": "Mwanza Airport"
    },
    {
      "city": "Myeik",
      "country": "Burma",
      "IATA": "MGZ",
      "icao_code": "VYME",
      "airport_name": "Myeik Airport"
    },
    {
      "city": "Myitkyina",
      "country": "Burma",
      "IATA": "MYT",
      "icao_code": "VYMK",
      "airport_name": "Myitkyina Airport"
    },
    {
      "city": "Mykonos",
      "country": "Greece",
      "IATA": "JMK",
      "icao_code": "LGMK",
      "airport_name": "Mykonos Island National Airport"
    },
    {
      "city": "Myrtle Beach",
      "country": "United States",
      "IATA": "MYR",
      "icao_code": "KMYR",
      "airport_name": "Myrtle Beach International Airport"
    },
    {
      "city": "Mysore",
      "country": "India",
      "IATA": "MYQ",
      "icao_code": "VOMY",
      "airport_name": "Mysore Airport"
    },
    {
      "city": "Mytilini",
      "country": "Greece",
      "IATA": "MJT",
      "icao_code": "LGMT",
      "airport_name": "Mytilene International Airport"
    },
    {
      "city": "Myvatn",
      "country": "Iceland",
      "IATA": "MVA",
      "icao_code": "BIRL",
      "airport_name": "Mývatn Airport"
    },
    {
      "city": "Mzuzu",
      "country": "Malawi",
      "IATA": "ZZU",
      "icao_code": "FWUU",
      "airport_name": "Mzuzu Airport"
    },
    {
      "city": "N'djamena",
      "country": "Chad",
      "IATA": "NDJ",
      "icao_code": "FTTJ",
      "airport_name": "N'Djamena International Airport "
    },
    {
      "city": "N'gaoundere",
      "country": "Cameroon",
      "IATA": "NGE",
      "icao_code": "FKKN",
      "airport_name": "Ngaoundéré Airport"
    },
    {
      "city": "NAPIER",
      "country": "New Zealand",
      "IATA": "NPE",
      "icao_code": "NZNR",
      "airport_name": "Hawke's Bay Airport"
    },
    {
      "city": "NYERI",
      "country": "Kenya",
      "IATA": "NYE",
      "icao_code": "HKNI",
      "airport_name": "Nyeri Airport"
    },
    {
      "city": "Nabire",
      "country": "Indonesia",
      "IATA": "NBX",
      "icao_code": "WABI",
      "airport_name": "Nabire Airport"
    },
    {
      "city": "Nacala",
      "country": "Mozambique",
      "IATA": "MNC",
      "airport_name": "Nacala Airport"
    },
    {
      "city": "Nadunumu",
      "country": "Papua New Guinea",
      "IATA": "NDN",
      "airport_name": "Nadunumu Airport"
    },
    {
      "city": "Nadym",
      "country": "Russia",
      "IATA": "NYM",
      "icao_code": "USMM",
      "airport_name": "Nadym Airport"
    },
    {
      "city": "Nadzab",
      "country": "Papua New Guinea",
      "IATA": "LAE",
      "icao_code": "AYNZ",
      "airport_name": "Lae Nadzab Airport"
    },
    {
      "city": "Naga",
      "country": "Philippines",
      "IATA": "WNP",
      "icao_code": "RPUN",
      "airport_name": "Naga Airport "
    },
    {
      "city": "Nagan Raya",
      "country": "Indonesia",
      "IATA": "MEQ",
      "icao_code": "WITC",
      "airport_name": "Cut Nyak Dhien Airport"
    },
    {
      "city": "Nagasaki",
      "country": "Japan",
      "IATA": "NGS",
      "icao_code": "RJFU",
      "airport_name": "Nagasaki Airport"
    },
    {
      "city": "Nagoya",
      "country": "Japan",
      "IATA": "NKM",
      "icao_code": "RJNA",
      "airport_name": "Nagoya Airfield"
    },
    {
      "city": "Nagoya",
      "country": "Japan",
      "IATA": "NGO",
      "icao_code": "RJGG",
      "airport_name": "Chūbu Centrair International Airport"
    },
    {
      "city": "Nagpur",
      "country": "India",
      "IATA": "NAG",
      "icao_code": "VANP",
      "airport_name": "Dr. Babasaheb Ambedkar International Airport"
    },
    {
      "city": "Naha",
      "country": "Indonesia",
      "IATA": "NAH",
      "icao_code": "WAMH",
      "airport_name": "Naha Airport"
    },
    {
      "city": "Nain",
      "country": "Canada",
      "IATA": "YDP",
      "icao_code": "CYDP",
      "airport_name": "Nain Airport"
    },
    {
      "city": "Nainital",
      "country": "India",
      "IATA": "PGH",
      "icao_code": "VIPT",
      "airport_name": "Pantnagar Airport"
    },
    {
      "city": "Nairobi",
      "country": "Kenya",
      "IATA": "NBO",
      "icao_code": "HKJK",
      "airport_name": "Jomo Kenyatta International Airport"
    },
    {
      "city": "Nairobi",
      "country": "Kenya",
      "IATA": "WIL",
      "icao_code": "HKNW",
      "airport_name": "Wilson Airport"
    },
    {
      "city": "Najaf",
      "country": "Iraq",
      "IATA": "NJF",
      "icao_code": "ORNI",
      "airport_name": "Al Najaf International Airport"
    },
    {
      "city": "Nakashibetsu",
      "country": "Japan",
      "IATA": "SHB",
      "icao_code": "RJCN",
      "airport_name": "Nakashibetsu Airport"
    },
    {
      "city": "Nakhchivan",
      "country": "Azerbaijan",
      "IATA": "NAJ",
      "icao_code": "UBBN",
      "airport_name": "Nakhchivan International Airport"
    },
    {
      "city": "Nakhon Pathom",
      "country": "Thailand",
      "IATA": "KDT",
      "airport_name": "Kamphaeng Saen Airport"
    },
    {
      "city": "Nakhon Phanom",
      "country": "Thailand",
      "IATA": "KOP",
      "icao_code": "VTUW",
      "airport_name": "Nakhon Phanom Airport"
    },
    {
      "city": "Nakhon Ratchasima",
      "country": "Thailand",
      "IATA": "NAK",
      "icao_code": "VTUQ",
      "airport_name": "Nakhon Ratchasima Airport"
    },
    {
      "city": "Nakhon Sawan",
      "country": "Thailand",
      "IATA": "TKH",
      "airport_name": "Takhli Royal Thai Air Force Base"
    },
    {
      "city": "Nakhon Si Thammarat",
      "country": "Thailand",
      "IATA": "NST",
      "icao_code": "VTSN",
      "airport_name": "Nakhon Si Thammarat Airport"
    },
    {
      "city": "Nakina",
      "country": "Canada",
      "IATA": "YQN",
      "icao_code": "CYQN",
      "airport_name": "Nakina Airport"
    },
    {
      "city": "Nalchik",
      "country": "Russia",
      "IATA": "NAL",
      "icao_code": "URMN",
      "airport_name": "Nalchik Airport"
    },
    {
      "city": "Namangan",
      "country": "Uzbekistan",
      "IATA": "NMA",
      "icao_code": "UTKN",
      "airport_name": "Namangan Airport"
    },
    {
      "city": "Namlea",
      "country": "Indonesia",
      "IATA": "NAM",
      "icao_code": "WAPR",
      "airport_name": "Namlea Airport"
    },
    {
      "city": "Nampula",
      "country": "Mozambique",
      "IATA": "APL",
      "icao_code": "FQNP",
      "airport_name": "Nampula Airport"
    },
    {
      "city": "Namsang",
      "country": "Burma",
      "IATA": "NMS",
      "icao_code": "VYNS",
      "airport_name": "Namsang Airport"
    },
    {
      "city": "Namsos",
      "country": "Norway",
      "IATA": "OSY",
      "icao_code": "ENNM",
      "airport_name": "Namsos Airport, Høknesøra"
    },
    {
      "city": "Nan",
      "country": "Thailand",
      "IATA": "NNT",
      "icao_code": "VTCN",
      "airport_name": "Nan Nakhon Airport"
    },
    {
      "city": "Nanaimo",
      "country": "Canada",
      "IATA": "ZNA",
      "icao_code": "CAC8",
      "airport_name": "Nanaimo Harbour Water Airport"
    },
    {
      "city": "Nanaimo",
      "country": "Canada",
      "IATA": "YCD",
      "icao_code": "CYCD",
      "airport_name": "Nanaimo Airport"
    },
    {
      "city": "Nanchang",
      "country": "China",
      "IATA": "KHN",
      "icao_code": "ZSCN",
      "airport_name": "Nanchang Changbei International Airport"
    },
    {
      "city": "Nanchong",
      "country": "China",
      "IATA": "NAO",
      "icao_code": "ZUNC",
      "airport_name": "Nanchong Gaoping Airport"
    },
    {
      "city": "Nancy",
      "country": "France",
      "IATA": "ENC",
      "icao_code": "LFSN",
      "airport_name": "Nancy-Essey Airport"
    },
    {
      "city": "Nandayure",
      "country": "Costa Rica",
      "IATA": "PBP",
      "icao_code": "MRIA",
      "airport_name": "Punta Islita Airport"
    },
    {
      "city": "Nanded",
      "country": "India",
      "IATA": "NDC",
      "icao_code": "VAND",
      "airport_name": "Shri Guru Gobind Singh Ji Airport, Nanded"
    },
    {
      "city": "Nandi",
      "country": "Fiji",
      "IATA": "NAN",
      "icao_code": "NFFN",
      "airport_name": "Nadi International Airport"
    },
    {
      "city": "Nangapinoh",
      "country": "Indonesia",
      "IATA": "NPO",
      "icao_code": "WIOG",
      "airport_name": "Nanga Pinoh  Airport"
    },
    {
      "city": "Nanisivik",
      "country": "Canada",
      "IATA": "YSR",
      "airport_name": "Nanisivik Airport"
    },
    {
      "city": "Nanjing",
      "country": "China",
      "IATA": "NKG",
      "icao_code": "ZSNJ",
      "airport_name": "Nanjing Lukou International Airport"
    },
    {
      "city": "Nanki-shirahama",
      "country": "Japan",
      "IATA": "SHM",
      "icao_code": "RJBD",
      "airport_name": "Nanki Shirahama Airport"
    },
    {
      "city": "Nanning",
      "country": "China",
      "IATA": "NNG",
      "icao_code": "ZGNN",
      "airport_name": "Nanning Wuxu Airport"
    },
    {
      "city": "Nanortalik",
      "country": "Greenland",
      "IATA": "JNN",
      "icao_code": "BGNN",
      "airport_name": "Nanortalik Heliport"
    },
    {
      "city": "Nantes",
      "country": "France",
      "IATA": "NTE",
      "icao_code": "LFRS",
      "airport_name": "Nantes Atlantique Airport"
    },
    {
      "city": "Nantucket",
      "country": "United States",
      "IATA": "ACK",
      "icao_code": "KACK",
      "airport_name": "Nantucket Memorial Airport"
    },
    {
      "city": "Nanyang",
      "country": "China",
      "IATA": "NNY",
      "icao_code": "ZHNY",
      "airport_name": "Nanyang Jiangying Airport"
    },
    {
      "city": "Nanyuki",
      "country": "Kenya",
      "IATA": "NYK",
      "icao_code": "HKNY",
      "airport_name": "Nanyuki Airport"
    },
    {
      "city": "Napa",
      "country": "United States",
      "IATA": "APC",
      "icao_code": "KAPC",
      "airport_name": "Napa County Airport"
    },
    {
      "city": "Napakiak",
      "country": "United States",
      "IATA": "WNA",
      "icao_code": "PANA",
      "airport_name": "Napakiak Airport"
    },
    {
      "city": "Napaskiak",
      "country": "United States",
      "IATA": "PKA",
      "icao_code": "PAPK",
      "airport_name": "Napaskiak Airport"
    },
    {
      "city": "Naples",
      "country": "United States",
      "IATA": "APF",
      "icao_code": "KAPF",
      "airport_name": "Naples Municipal Airport"
    },
    {
      "city": "Naples",
      "country": "Italy",
      "IATA": "NAP",
      "icao_code": "LIRN",
      "airport_name": "Naples International Airport"
    },
    {
      "city": "Napuka",
      "country": "French Polynesia",
      "IATA": "NAU",
      "icao_code": "NTGN",
      "airport_name": "Napuka Airport"
    },
    {
      "city": "Naracoorte",
      "country": "Australia",
      "IATA": "NAC",
      "icao_code": "YNRC",
      "airport_name": "Naracoorte Airport"
    },
    {
      "city": "Narathiwat",
      "country": "Thailand",
      "IATA": "NAW",
      "icao_code": "VTSC",
      "airport_name": "Narathiwat Airport"
    },
    {
      "city": "Narrabri",
      "country": "Australia",
      "IATA": "NAA",
      "icao_code": "YNBR",
      "airport_name": "Narrabri Airport"
    },
    {
      "city": "Narrandera",
      "country": "Australia",
      "IATA": "NRA",
      "icao_code": "YNAR",
      "airport_name": "Narrandera Airport"
    },
    {
      "city": "Narsaq",
      "country": "Greenland",
      "IATA": "JNS",
      "icao_code": "BGNS",
      "airport_name": "Narsaq Heliport"
    },
    {
      "city": "Narssarssuaq",
      "country": "Greenland",
      "IATA": "UAK",
      "icao_code": "BGBW",
      "airport_name": "Narsarsuaq Airport"
    },
    {
      "city": "Narvik",
      "country": "Norway",
      "IATA": "NVK",
      "icao_code": "ENNK",
      "airport_name": "Narvik Airport, Framnes"
    },
    {
      "city": "Naryan-Mar",
      "country": "Russia",
      "IATA": "NNM",
      "icao_code": "ULAM",
      "airport_name": "Naryan-Mar Airport"
    },
    {
      "city": "Nashua",
      "country": "United States",
      "IATA": "ASH",
      "icao_code": "KASH",
      "airport_name": "Nashua Airport"
    },
    {
      "city": "Nashville",
      "country": "United States",
      "IATA": "BNA",
      "icao_code": "KBNA",
      "airport_name": "Nashville International Airport"
    },
    {
      "city": "Nasik Road",
      "country": "India",
      "IATA": "ISK",
      "icao_code": "VANR",
      "airport_name": "Gandhinagar Airport"
    },
    {
      "city": "Nassau",
      "country": "Bahamas",
      "IATA": "PID",
      "airport_name": "Paradise Island Airport"
    },
    {
      "city": "Nassau",
      "country": "Bahamas",
      "IATA": "NAS",
      "icao_code": "MYNN",
      "airport_name": "Nassau International Airport"
    },
    {
      "city": "Natal",
      "country": "Brazil",
      "IATA": "NAT",
      "icao_code": "SBNT",
      "airport_name": "Augusto Severo International Airport"
    },
    {
      "city": "Natashquan",
      "country": "Canada",
      "IATA": "YNA",
      "icao_code": "CYNA",
      "airport_name": "Natashquan Airport"
    },
    {
      "city": "Natuashish",
      "country": "Canada",
      "IATA": "YNP",
      "airport_name": "Natuashish Airport"
    },
    {
      "city": "Nauru",
      "country": "Nauru",
      "IATA": "INU",
      "icao_code": "ANAU",
      "airport_name": "Nauru International Airport"
    },
    {
      "city": "Nausori",
      "country": "Fiji",
      "IATA": "SUV",
      "icao_code": "NFNA",
      "airport_name": "Nausori International Airport"
    },
    {
      "city": "Navegantes",
      "country": "Brazil",
      "IATA": "NVT",
      "icao_code": "SBNF",
      "airport_name": "Navegantes–Ministro Victor Konder International Airport"
    },
    {
      "city": "Navoi",
      "country": "Uzbekistan",
      "IATA": "NVI",
      "icao_code": "UTSA",
      "airport_name": "Navoi International Airport"
    },
    {
      "city": "Nawabshah",
      "country": "Pakistan",
      "IATA": "WNS",
      "icao_code": "OPNH",
      "airport_name": "Nawabshah Airport"
    },
    {
      "city": "Naypyidaw",
      "country": "Burma",
      "IATA": "NMT",
      "icao_code": "VNMT",
      "airport_name": "Namtu Airport"
    },
    {
      "city": "Naypyidaw",
      "country": "Burma",
      "IATA": "NYT",
      "icao_code": "VYNT",
      "airport_name": " Nay Pyi Taw International Airport"
    },
    {
      "city": "Nazca",
      "country": "Peru",
      "IATA": "NZC",
      "airport_name": "Naval Air Station Cecil Field"
    },
    {
      "city": "Ndola",
      "country": "Zambia",
      "IATA": "NLA",
      "icao_code": "FLND",
      "airport_name": "Simon Mwansa Kapwepwe International Airport"
    },
    {
      "city": "Nea Anghialos",
      "country": "Greece",
      "IATA": "VOL",
      "icao_code": "LGBL",
      "airport_name": "Nea Anchialos National Airport"
    },
    {
      "city": "Necochea",
      "country": "Argentina",
      "IATA": "NEC",
      "airport_name": "Necochea Airport"
    },
    {
      "city": "Needles",
      "country": "United States",
      "IATA": "EED",
      "icao_code": "KEED",
      "airport_name": "Needles Airport"
    },
    {
      "city": "Neerlerit Inaat",
      "country": "Greenland",
      "IATA": "CNP",
      "icao_code": "BGCO",
      "airport_name": "Nerlerit Inaat Airport "
    },
    {
      "city": "Nefteyugansk",
      "country": "Russia",
      "IATA": "NFG",
      "icao_code": "USRN",
      "airport_name": "Nefteyugansk Airport"
    },
    {
      "city": "Negage",
      "country": "Angola",
      "IATA": "GXG",
      "icao_code": "FNNG",
      "airport_name": "Negage Airport"
    },
    {
      "city": "Negril",
      "country": "Jamaica",
      "IATA": "NEG",
      "airport_name": "Negril Aerodrome"
    },
    {
      "city": "Neiva",
      "country": "Colombia",
      "IATA": "NVA",
      "icao_code": "SKNV",
      "airport_name": "Benito Salas Airport"
    },
    {
      "city": "Nejran",
      "country": "Saudi Arabia",
      "IATA": "EAM",
      "icao_code": "OENG",
      "airport_name": "Najran Domestic Airport"
    },
    {
      "city": "Nelson",
      "country": "New Zealand",
      "IATA": "NSN",
      "icao_code": "NZNS",
      "airport_name": "Nelson Airport"
    },
    {
      "city": "Nelson Lagoon",
      "country": "United States",
      "IATA": "NLG",
      "icao_code": "PAOU",
      "airport_name": "Nelson Lagoon Airport "
    },
    {
      "city": "Nelspruit",
      "country": "South Africa",
      "IATA": "NLP",
      "icao_code": "FANS",
      "airport_name": "Nelspruit Airport"
    },
    {
      "city": "Nema",
      "country": "Mauritania",
      "IATA": "EMN",
      "icao_code": "GQNI",
      "airport_name": "Nema Airport"
    },
    {
      "city": "Nemiscau",
      "country": "Canada",
      "IATA": "YNS",
      "icao_code": "CYHH",
      "airport_name": "Nemiscau Airport"
    },
    {
      "city": "Nenana",
      "country": "United States",
      "IATA": "ENN",
      "icao_code": "PANN",
      "airport_name": "Nenana Municipal Airport"
    },
    {
      "city": "Nepalgunj",
      "country": "Nepal",
      "IATA": "KEP",
      "icao_code": "VNNG",
      "airport_name": "Nepalgunj Airport "
    },
    {
      "city": "Neryungri",
      "country": "Russia",
      "IATA": "NER",
      "icao_code": "UELL",
      "airport_name": "Chulman Neryungri Airport"
    },
    {
      "city": "Neubrandenburg",
      "country": "Germany",
      "IATA": "FNB",
      "icao_code": "ETNU",
      "airport_name": "Neubrandenburg Airport"
    },
    {
      "city": "Neuchatel",
      "country": "Switzerland",
      "IATA": "QNC",
      "airport_name": "Neuchatel Airport"
    },
    {
      "city": "Neumuenster",
      "country": "Germany",
      "IATA": "EUM",
      "airport_name": "Neumuenster Airport"
    },
    {
      "city": "Neuquen",
      "country": "Argentina",
      "IATA": "NQN",
      "icao_code": "SAZN",
      "airport_name": "Presidente Perón International Airport"
    },
    {
      "city": "Nevatim",
      "country": "Israel",
      "IATA": "VTM",
      "icao_code": "LLNV",
      "airport_name": "Nevatim Airbase"
    },
    {
      "city": "Nevers",
      "country": "France",
      "IATA": "NVS",
      "icao_code": "LFQG",
      "airport_name": "Nevers-Fourchambault Airport"
    },
    {
      "city": "Nevsehir",
      "country": "Turkey",
      "IATA": "NAV",
      "icao_code": "LTAZ",
      "airport_name": "Nevşehir Kapadokya Airport"
    },
    {
      "city": "New Bedford",
      "country": "United States",
      "IATA": "EWB",
      "icao_code": "KEWB",
      "airport_name": "New Bedford Regional Airport"
    },
    {
      "city": "New Bern",
      "country": "United States",
      "IATA": "EWN",
      "icao_code": "KEWN",
      "airport_name": "Coastal Carolina Regional Airport"
    },
    {
      "city": "New Haven",
      "country": "United States",
      "IATA": "HVN",
      "icao_code": "KHVN",
      "airport_name": "Tweed New Haven Airport"
    },
    {
      "city": "New Orleans",
      "country": "United States",
      "IATA": "NEW",
      "icao_code": "KNEW",
      "airport_name": "Lakefront Airport"
    },
    {
      "city": "New Orleans",
      "country": "United States",
      "IATA": "MSY",
      "icao_code": "KMSY",
      "airport_name": "Louis Armstrong New Orleans International Airport "
    },
    {
      "city": "New Orleans",
      "country": "United States",
      "IATA": "NBG",
      "airport_name": "Naval Air Station Joint Reserve Base New Orleans"
    },
    {
      "city": "New Philadelpha",
      "country": "United States",
      "IATA": "PHD",
      "airport_name": "Harry Clever Field"
    },
    {
      "city": "New Plymouth",
      "country": "New Zealand",
      "IATA": "NPL",
      "icao_code": "NZNP",
      "airport_name": "New Plymouth Airport"
    },
    {
      "city": "New Stuyahok",
      "country": "United States",
      "IATA": "KNW",
      "icao_code": "PANW",
      "airport_name": "New Stuyahok Airport"
    },
    {
      "city": "New York, All Airports",
      "country": "United States",
      "IATA": "NYALL",
      "airport_name": "New York Metropolitan Airports"
    },
    {
      "city": "New York, NYC",
      "country": "United States",
      "IATA": "JRA",
      "icao_code": "KJRA",
      "airport_name": "West 30th Street Heliport"
    },
    {
      "city": "New York, NYC",
      "country": "United States",
      "IATA": "JRB",
      "icao_code": "KJRB",
      "airport_name": "Downtown Manhattan Heliport"
    },
    {
      "city": "New York, NYC",
      "country": "United States",
      "IATA": "JFK",
      "icao_code": "KJFK",
      "airport_name": "John F. Kennedy International Airport"
    },
    {
      "city": "New York, NYC",
      "country": "United States",
      "IATA": "LGA",
      "icao_code": "KLGA",
      "airport_name": "LaGuardia Airport"
    },
    {
      "city": "New York, Newark",
      "country": "United States",
      "IATA": "EWR",
      "icao_code": "KEWR",
      "airport_name": "Newark Liberty International Airport"
    },
    {
      "city": "New York, Monmouth",
      "country": "United States",
      "IATA": "BLM",
      "icao_code": "KBLM",
      "airport_name": "Monmouth Executive Airport"
    },
    {
      "city": "Newburgh",
      "country": "United States",
      "IATA": "SWF",
      "icao_code": "KSWF",
      "airport_name": "Stewart International Airport"
    },
    {
      "city": "Newcastle",
      "country": "Australia",
      "IATA": "NTL",
      "icao_code": "YWLM",
      "airport_name": " RAAF Base Williamtown"
    },
    {
      "city": "Newcastle",
      "country": "South Africa",
      "IATA": "NCS",
      "icao_code": "FANC",
      "airport_name": "Newcastle Airport"
    },
    {
      "city": "Newcastle",
      "country": "United Kingdom",
      "IATA": "NCL",
      "icao_code": "EGNT",
      "airport_name": "Newcastle International Airport"
    },
    {
      "city": "Newman",
      "country": "Australia",
      "IATA": "ZNE",
      "icao_code": "YNWM",
      "airport_name": "Newman Airport"
    },
    {
      "city": "Newport",
      "country": "United States",
      "IATA": "ONP",
      "icao_code": "KONP",
      "airport_name": "Newport Municipal Airport"
    },
    {
      "city": "Newport News",
      "country": "United States",
      "IATA": "PHF",
      "icao_code": "KPHF",
      "airport_name": "Newport News Williamsburg International Airport"
    },
    {
      "city": "Newquai",
      "country": "United Kingdom",
      "IATA": "NQY",
      "icao_code": "EGDG",
      "airport_name": "Cornwall Airport Newquay"
    },
    {
      "city": "Newton",
      "country": "United States",
      "IATA": "EWK",
      "icao_code": "KEWK",
      "airport_name": "Newton City/County Airport"
    },
    {
      "city": "Ngau",
      "country": "Fiji",
      "IATA": "NGI",
      "icao_code": "NFNG",
      "airport_name": "Gau Airport"
    },
    {
      "city": "Ngawa",
      "country": "China",
      "IATA": "AHJ",
      "icao_code": "ZUHY",
      "airport_name": "Ngawa Airport"
    },
    {
      "city": "Nha Trang",
      "country": "Vietnam",
      "IATA": "CXR",
      "icao_code": "VVCR",
      "airport_name": "Cam Ranh International Airport"
    },
    {
      "city": "Nhatrang",
      "country": "Vietnam",
      "IATA": "NHA",
      "icao_code": "VVNT",
      "airport_name": "Nha Trang Air Base"
    },
    {
      "city": "Niagara Falls",
      "country": "United States",
      "IATA": "IAG",
      "icao_code": "KIAG",
      "airport_name": "Niagara Falls International Airport"
    },
    {
      "city": "Niamey",
      "country": "Niger",
      "IATA": "NIM",
      "icao_code": "DRRN",
      "airport_name": "Diori Hamani International Airport"
    },
    {
      "city": "Niatougou",
      "country": "Togo",
      "IATA": "LRL",
      "icao_code": "DXNG",
      "airport_name": "Niamtougou International Airport"
    },
    {
      "city": "Niau",
      "country": "French Polynesia",
      "IATA": "NIU",
      "icao_code": "NTKN",
      "airport_name": "Niau Airport"
    },
    {
      "city": "Nice",
      "country": "France",
      "IATA": "NCE",
      "icao_code": "LFMN",
      "airport_name": "Côte d'Azur International Airport"
    },
    {
      "city": "Nicosia",
      "country": "Cyprus",
      "IATA": "ECN",
      "icao_code": "LCEN",
      "airport_name": "Ercan International Airport"
    },
    {
      "city": "Nicoya",
      "country": "Costa Rica",
      "IATA": "TMU",
      "icao_code": "MRTR",
      "airport_name": "Tambor Airport"
    },
    {
      "city": "Nieuw Nickerie",
      "country": "Suriname",
      "IATA": "ICK",
      "airport_name": "Majoor Henry Fernandes Airport"
    },
    {
      "city": "Nightmute",
      "country": "United States",
      "IATA": "NME",
      "icao_code": "PAGT",
      "airport_name": "Nightmute Airport "
    },
    {
      "city": "Niigata",
      "country": "Japan",
      "IATA": "KIJ",
      "icao_code": "RJSN",
      "airport_name": "Niigata Airport"
    },
    {
      "city": "Nikolai",
      "country": "United States",
      "IATA": "NIB",
      "icao_code": "PAFS",
      "airport_name": "Nikolai Airport "
    },
    {
      "city": "Nikolayev",
      "country": "Ukraine",
      "IATA": "NLV",
      "icao_code": "UKON",
      "airport_name": "Mykolaiv International Airport"
    },
    {
      "city": "Nikolayevsk-na-Amure Airport",
      "country": "Russia",
      "IATA": "NLI",
      "icao_code": "UHNN",
      "airport_name": "Nikolayevsk-na-Amure Airport"
    },
    {
      "city": "Nikolski",
      "country": "United States",
      "IATA": "IKO",
      "airport_name": "Nikolski Air Station"
    },
    {
      "city": "Nikunau",
      "country": "Kiribati",
      "IATA": "NIG",
      "airport_name": "Nikunau Airport"
    },
    {
      "city": "Nimes",
      "country": "France",
      "IATA": "FNI",
      "icao_code": "LFTW",
      "airport_name": "Nîmes–Alès–Camargue–Cévennes Airport"
    },
    {
      "city": "Ninbo",
      "country": "China",
      "IATA": "NGB",
      "icao_code": "ZSNB",
      "airport_name": "Ningbo Lishe International Airport"
    },
    {
      "city": "Ninglang",
      "country": "China",
      "IATA": "NLH",
      "icao_code": "ZPNL",
      "airport_name": "Ninglang Airport"
    },
    {
      "city": "Nioki",
      "country": "Congo (Kinshasa)",
      "IATA": "NIO",
      "icao_code": "FZBI",
      "airport_name": "Nioki Airport"
    },
    {
      "city": "Niort",
      "country": "France",
      "IATA": "NIT",
      "icao_code": "LFBN",
      "airport_name": "Aérodrome Niort - Marais Poitevin"
    },
    {
      "city": "Nis",
      "country": "Serbia",
      "IATA": "INI",
      "icao_code": "LYNI",
      "airport_name": "Nis Constantine the Great Airport"
    },
    {
      "city": "Niuatoputapu",
      "country": "Tonga",
      "IATA": "NTT",
      "icao_code": "NFTP",
      "airport_name": "Niuatoputapu Airport"
    },
    {
      "city": "Nizhnekamsk",
      "country": "Russia",
      "IATA": "NBC",
      "icao_code": "KNBC",
      "airport_name": "Begishevo Airport"
    },
    {
      "city": "Nizhnevartovsk",
      "country": "Russia",
      "IATA": "NJC",
      "icao_code": "USNN",
      "airport_name": "Nizhnevartovsk Airport"
    },
    {
      "city": "Nizhniy Novgorod",
      "country": "Russia",
      "IATA": "GOJ",
      "icao_code": "UWGG",
      "airport_name": "Strigino Nizhny Novgorod International Airport"
    },
    {
      "city": "Noatak",
      "country": "United States",
      "IATA": "WTK",
      "icao_code": "PAWN",
      "airport_name": "Noatak Airport"
    },
    {
      "city": "Nogales",
      "country": "United States",
      "IATA": "OLS",
      "icao_code": "KOLS",
      "airport_name": "Nogales International Airport"
    },
    {
      "city": "Nogales",
      "country": "Mexico",
      "IATA": "NOG",
      "airport_name": "Nogales International Airport"
    },
    {
      "city": "Nogliki",
      "country": "Russia",
      "IATA": "NGK",
      "airport_name": "Nogliki Airport"
    },
    {
      "city": "Nome",
      "country": "United States",
      "IATA": "OME",
      "icao_code": "PAOM",
      "airport_name": "Nome Airport"
    },
    {
      "city": "Nondalton",
      "country": "United States",
      "IATA": "NNL",
      "icao_code": "PANO",
      "airport_name": "Nondalton Airport "
    },
    {
      "city": "Nonouti",
      "country": "Kiribati",
      "IATA": "NON",
      "airport_name": "Nonouti Airport"
    },
    {
      "city": "Noonkanbah",
      "country": "Australia",
      "IATA": "NKB",
      "airport_name": "Noonkanbah Airport"
    },
    {
      "city": "Noorvik",
      "country": "United States",
      "IATA": "ORV",
      "icao_code": "PFNO",
      "airport_name": "Robert (Bob) Curtis Memorial Airport"
    },
    {
      "city": "Norden",
      "country": "Germany",
      "IATA": "NOD",
      "airport_name": "Airfield Norddeich"
    },
    {
      "city": "Norderney",
      "country": "Germany",
      "IATA": "NRD",
      "airport_name": "Airfield Norderney"
    },
    {
      "city": "Nordfjordur",
      "country": "Iceland",
      "IATA": "NOR",
      "icao_code": "BINF",
      "airport_name": "Norðfjörður Airport"
    },
    {
      "city": "Nordholz",
      "country": "Germany",
      "IATA": "FCN",
      "airport_name": "Nordholz Naval Airbase"
    },
    {
      "city": "Norfolk",
      "country": "United States",
      "IATA": "NGU",
      "icao_code": "KNGU",
      "airport_name": "Naval Station Norfolk"
    },
    {
      "city": "Norfolk",
      "country": "United States",
      "IATA": "ORF",
      "icao_code": "KORF",
      "airport_name": "Norfolk International Airport"
    },
    {
      "city": "Norfolk Island",
      "country": "Norfolk Island",
      "IATA": "NLK",
      "icao_code": "YSNF",
      "airport_name": "Norfolk Island Airport"
    },
    {
      "city": "Norfolk Nebraska",
      "country": "United States",
      "IATA": "OFK",
      "icao_code": "KOFK",
      "airport_name": "Norfolk Regional Airport"
    },
    {
      "city": "Norilsk",
      "country": "Russia",
      "IATA": "NSK",
      "icao_code": "UOOO",
      "airport_name": "Alykel Airport"
    },
    {
      "city": "Norman Wells",
      "country": "Canada",
      "IATA": "YVQ",
      "icao_code": "CYVQ",
      "airport_name": "Norman Wells Airport"
    },
    {
      "city": "Norman's Cay",
      "country": "Bahamas",
      "IATA": "NMC",
      "icao_code": "MYEN",
      "airport_name": "Norman's Cay Airport"
    },
    {
      "city": "Normanton",
      "country": "Australia",
      "IATA": "NTN",
      "icao_code": "YNTN",
      "airport_name": "Normanton Airport"
    },
    {
      "city": "Norrkoeping",
      "country": "Sweden",
      "IATA": "NRK",
      "icao_code": "ESSP",
      "airport_name": "Norrköping Airport"
    },
    {
      "city": "Norsup",
      "country": "Vanuatu",
      "IATA": "NUS",
      "icao_code": "NVSP",
      "airport_name": "Nuussuaq Heliport"
    },
    {
      "city": "North Battleford",
      "country": "Canada",
      "IATA": "YQW",
      "icao_code": "CYQW",
      "airport_name": "North Battleford Airport"
    },
    {
      "city": "North Bay",
      "country": "Canada",
      "IATA": "YYB",
      "icao_code": "CYYB",
      "airport_name": "North Bay/Jack Garland Airport"
    },
    {
      "city": "North Bend",
      "country": "United States",
      "IATA": "OTH",
      "icao_code": "KOTH",
      "airport_name": "Southwest Oregon Regional Airport"
    },
    {
      "city": "North Caicos",
      "country": "Turks and Caicos Islands",
      "IATA": "NCA",
      "icao_code": "MBNC",
      "airport_name": "North Caicos Airport"
    },
    {
      "city": "North Connel",
      "country": "United Kingdom",
      "IATA": "OBN",
      "icao_code": "EGEO",
      "airport_name": "Oban Airport"
    },
    {
      "city": "North Eleuthera",
      "country": "Bahamas",
      "IATA": "ELH",
      "icao_code": "MYEH",
      "airport_name": "North Eleuthera Airport"
    },
    {
      "city": "North Kingstown",
      "country": "United States",
      "IATA": "NCO",
      "icao_code": "KOQU",
      "airport_name": "Quonset State Airport"
    },
    {
      "city": "North Myrtle Beach",
      "country": "United States",
      "IATA": "CRE",
      "icao_code": "KCRE",
      "airport_name": "Grand Strand Airport"
    },
    {
      "city": "North Platte",
      "country": "United States",
      "IATA": "LBF",
      "icao_code": "KLBF",
      "airport_name": "North Platte Regional Airport "
    },
    {
      "city": "North Ronaldsay",
      "country": "United Kingdom",
      "IATA": "NRL",
      "icao_code": "EGEN",
      "airport_name": "North Ronaldsay Airport"
    },
    {
      "city": "North Spirit Lake",
      "country": "Canada",
      "IATA": "YNO",
      "icao_code": "CKQ3",
      "airport_name": "North Spirit Lake Airport"
    },
    {
      "city": "North Wilkesboro",
      "country": "United States",
      "IATA": "IKB",
      "airport_name": "Wilkes County Airport "
    },
    {
      "city": "Northolt",
      "country": "United Kingdom",
      "IATA": "NHT",
      "icao_code": "EGWU",
      "airport_name": "RAF Northolt"
    },
    {
      "city": "Northway",
      "country": "United States",
      "IATA": "ORT",
      "icao_code": "PAOR",
      "airport_name": "Northway Airport"
    },
    {
      "city": "Norway House",
      "country": "Canada",
      "IATA": "YNE",
      "airport_name": "Norway House Airport"
    },
    {
      "city": "Norwich",
      "country": "United Kingdom",
      "IATA": "NWI",
      "icao_code": "EGSH",
      "airport_name": "Norwich International Airport"
    },
    {
      "city": "Norwood",
      "country": "United States",
      "IATA": "OWD",
      "icao_code": "KOWD",
      "airport_name": "Norwood Memorial Airport"
    },
    {
      "city": "Nosara Beach",
      "country": "Costa Rica",
      "IATA": "NOB",
      "icao_code": "MRNS",
      "airport_name": "Nosara Airport"
    },
    {
      "city": "Noshahr",
      "country": "Iran",
      "IATA": "NSH",
      "icao_code": "OINN",
      "airport_name": "Noshahr Airport"
    },
    {
      "city": "Nosy-be",
      "country": "Madagascar",
      "IATA": "NOS",
      "icao_code": "FMNN",
      "airport_name": "Fascene Airport"
    },
    {
      "city": "Notodden",
      "country": "Norway",
      "IATA": "NTB",
      "icao_code": "ENNO",
      "airport_name": "Notodden Airport, Tuven"
    },
    {
      "city": "Nottingham",
      "country": "United Kingdom",
      "IATA": "NQT",
      "icao_code": "EGBN",
      "airport_name": "Nottingham Airport"
    },
    {
      "city": "Nouadhibou",
      "country": "Mauritania",
      "IATA": "NDB",
      "icao_code": "GQPP",
      "airport_name": "Nouadhibou International Airport"
    },
    {
      "city": "Nouakschott",
      "country": "Mauritania",
      "IATA": "NKC",
      "icao_code": "GQNN",
      "airport_name": "Nouakchott International Airport"
    },
    {
      "city": "Noumea",
      "country": "New Caledonia",
      "IATA": "NOU",
      "icao_code": "NWWW",
      "airport_name": "La Tontouta International Airport"
    },
    {
      "city": "Noumea",
      "country": "New Caledonia",
      "IATA": "GEA",
      "icao_code": "NWWM",
      "airport_name": "Nouméa Magenta Airport"
    },
    {
      "city": "Nova Iguacu",
      "country": "Brazil",
      "IATA": "QNV",
      "airport_name": "Aeroclube Airport"
    },
    {
      "city": "Novato",
      "country": "United States",
      "IATA": "NOT",
      "airport_name": "Marin County Airport"
    },
    {
      "city": "Novi Sad",
      "country": "Serbia",
      "IATA": "QND",
      "airport_name": "Novi Sad Airport"
    },
    {
      "city": "Novo Aripuana",
      "country": "Brazil",
      "IATA": "NVP",
      "icao_code": "SWNA",
      "airport_name": "Novo Aripuanã Airport"
    },
    {
      "city": "Novo Progresso",
      "country": "Brazil",
      "IATA": "NPR",
      "icao_code": "SJNP",
      "airport_name": "Novo Progresso Airport"
    },
    {
      "city": "Novokuznetsk",
      "country": "Russia",
      "IATA": "NOZ",
      "icao_code": "UNWW",
      "airport_name": "Spichenkovo Airport"
    },
    {
      "city": "Novosibirsk",
      "country": "Russia",
      "IATA": "OVB",
      "icao_code": "UNNT",
      "airport_name": "Novosibirsk Tolmachevo Airport"
    },
    {
      "city": "Novy Urengoy",
      "country": "Russia",
      "IATA": "NUX",
      "icao_code": "USMU",
      "airport_name": "Novy Urengoy Airport"
    },
    {
      "city": "Nowra",
      "country": "Australia",
      "IATA": "NOA",
      "icao_code": "YSNW",
      "airport_name": "HMAS Albatross"
    },
    {
      "city": "Noyabrsk",
      "country": "Russia",
      "IATA": "NOJ",
      "icao_code": "USRO",
      "airport_name": "Noyabrsk Airport"
    },
    {
      "city": "Nuernberg",
      "country": "Germany",
      "IATA": "NUE",
      "icao_code": "EDDN",
      "airport_name": "Nuremberg Airport"
    },
    {
      "city": "Nueva Gerona",
      "country": "Cuba",
      "IATA": "GER",
      "icao_code": "MUNG",
      "airport_name": "Rafael Cabrera Airport"
    },
    {
      "city": "Nuevo Casas Grandes",
      "country": "Mexico",
      "IATA": "NCG",
      "airport_name": "Nuevo Casas Grandes Municipal Airport"
    },
    {
      "city": "Nuevo Laredo",
      "country": "Mexico",
      "IATA": "NLD",
      "icao_code": "MMNL",
      "airport_name": "Quetzalcóatl International Airport"
    },
    {
      "city": "Nuiqsut",
      "country": "United States",
      "IATA": "NUI",
      "icao_code": "PAQT",
      "airport_name": "Nuiqsut Airport "
    },
    {
      "city": "Nuku Hiva",
      "country": "French Polynesia",
      "IATA": "NHV",
      "icao_code": "NTMD",
      "airport_name": "Nuku Hiva Airport"
    },
    {
      "city": "Nukus",
      "country": "Uzbekistan",
      "IATA": "NCU",
      "icao_code": "UTNN",
      "airport_name": "Nukus Airport"
    },
    {
      "city": "Nukutavake",
      "country": "French Polynesia",
      "IATA": "NUK",
      "icao_code": "NTGW",
      "airport_name": "Nukutavake Airport"
    },
    {
      "city": "Nulato",
      "country": "United States",
      "IATA": "NUL",
      "icao_code": "PANU",
      "airport_name": "Nulato Airport"
    },
    {
      "city": "Null",
      "country": "United States",
      "IATA": "SUS",
      "icao_code": "KSUS",
      "airport_name": "Spirit of St. Louis Airport"
    },
    {
      "city": "Null",
      "country": "Bosnia and Herzegovina",
      "IATA": "TZL",
      "icao_code": "LQTZ",
      "airport_name": "Tuzla International Airport"
    },
    {
      "city": "Nunukan-Nunukan Island",
      "country": "Indonesia",
      "IATA": "NNX",
      "icao_code": "WRLF",
      "airport_name": "Nunukan Airport"
    },
    {
      "city": "Nuquí",
      "country": "Colombia",
      "IATA": "NQU",
      "icao_code": "SKNQ",
      "airport_name": "Reyes Murillo Airport"
    },
    {
      "city": "Nusawiru",
      "country": "Indonesia",
      "IATA": "CJN",
      "airport_name": "Nusawiru Airport"
    },
    {
      "city": "Nyagan",
      "country": "Russia",
      "IATA": "NYA",
      "icao_code": "USHN",
      "airport_name": "Nyagan Airport"
    },
    {
      "city": "Nyala",
      "country": "Sudan",
      "IATA": "UYL",
      "icao_code": "HSNL",
      "airport_name": "Nyala Airport"
    },
    {
      "city": "Nyingchi",
      "country": "China",
      "IATA": "LZY",
      "icao_code": "ZUNZ",
      "airport_name": "Nyingchi Mainling Airport"
    },
    {
      "city": "Nyurba",
      "country": "Russia",
      "IATA": "NYR",
      "airport_name": "Nyurba Airport"
    },
    {
      "city": "Nzagi",
      "country": "Angola",
      "IATA": "NZA",
      "icao_code": "FNZG",
      "airport_name": "Maria Reiche Neuman Airport"
    },
    {
      "city": "Nzerekore",
      "country": "Guinea",
      "IATA": "NZE",
      "icao_code": "GUNZ",
      "airport_name": "Nzérékoré Airport"
    },
    {
      "city": "Oakey",
      "country": "Australia",
      "IATA": "OKY",
      "icao_code": "YBOK",
      "airport_name": "Oakey Army Aviation Centre"
    },
    {
      "city": "Oakland",
      "country": "United States",
      "IATA": "OAK",
      "icao_code": "KOAK",
      "airport_name": "Oakland International Airport"
    },
    {
      "city": "Oakley",
      "country": "United States",
      "IATA": "OEL",
      "icao_code": "UUOR",
      "airport_name": "Oakley Municipal Airport"
    },
    {
      "city": "Oamaru",
      "country": "New Zealand",
      "IATA": "OAM",
      "icao_code": "NZOU",
      "airport_name": "Oamaru Airport"
    },
    {
      "city": "Oaxaca",
      "country": "Mexico",
      "IATA": "OAX",
      "icao_code": "MMOX",
      "airport_name": "Xoxocotlán International Airport"
    },
    {
      "city": "Oberpfaffenhofen",
      "country": "Germany",
      "IATA": "OBF",
      "icao_code": "EDMO",
      "airport_name": "Oberpfaffenhofen Airport"
    },
    {
      "city": "Obihiro",
      "country": "Japan",
      "IATA": "OBO",
      "icao_code": "RJCB",
      "airport_name": "Tokachi-Obihiro Airport"
    },
    {
      "city": "Obock",
      "country": "Djibouti",
      "IATA": "OBC",
      "icao_code": "HDOB",
      "airport_name": "Obock Airport"
    },
    {
      "city": "Ocala",
      "country": "United States",
      "IATA": "OCF",
      "airport_name": "Ocala International Airport "
    },
    {
      "city": "Ocana",
      "country": "Colombia",
      "IATA": "OCV",
      "icao_code": "SKOC",
      "airport_name": "Aguas Claras Airport"
    },
    {
      "city": "Ocean Reef Club Airport",
      "country": "United States",
      "IATA": "OCA",
      "airport_name": "Ocean Reef Club Airport"
    },
    {
      "city": "Oceana",
      "country": "United States",
      "IATA": "NTU",
      "icao_code": "KNTU",
      "airport_name": "Naval Air Station Oceana"
    },
    {
      "city": "Ocho Rios",
      "country": "Jamaica",
      "IATA": "OCJ",
      "airport_name": "Ian Fleming International Airport"
    },
    {
      "city": "Odate Noshiro",
      "country": "Japan",
      "IATA": "ONJ",
      "icao_code": "RJSR",
      "airport_name": "Odate-Noshiro Airport"
    },
    {
      "city": "Odense",
      "country": "Denmark",
      "IATA": "ODE",
      "icao_code": "EKOD",
      "airport_name": "Hans Christian Andersen Airport"
    },
    {
      "city": "Odessa",
      "country": "Ukraine",
      "IATA": "ODS",
      "icao_code": "UKOO",
      "airport_name": "Odessa International Airport"
    },
    {
      "city": "Odienne",
      "country": "Cote d'Ivoire",
      "IATA": "KEO",
      "icao_code": "DIOD",
      "airport_name": "Odienné Airport"
    },
    {
      "city": "Odiham",
      "country": "United Kingdom",
      "IATA": "ODH",
      "icao_code": "EGVO",
      "airport_name": "Royal Air Force Odiham"
    },
    {
      "city": "Ogden",
      "country": "United States",
      "IATA": "OGD",
      "icao_code": "KOGD",
      "airport_name": "Ogden-Hinckley Airport"
    },
    {
      "city": "Ogden",
      "country": "United States",
      "IATA": "HIF",
      "airport_name": "Hill Air Force Base"
    },
    {
      "city": "Ogdensburg",
      "country": "United States",
      "IATA": "OGS",
      "icao_code": "KOGS",
      "airport_name": "Ogdensburg International Airport"
    },
    {
      "city": "Ogoki Post",
      "country": "Canada",
      "IATA": "YOG",
      "icao_code": "CNT3",
      "airport_name": "Ogoki Post Airport"
    },
    {
      "city": "Ohakea",
      "country": "New Zealand",
      "IATA": "OHA",
      "icao_code": "NZOH",
      "airport_name": "RNZAF Base Ohakea"
    },
    {
      "city": "Ohrid",
      "country": "Macedonia",
      "IATA": "OHD",
      "icao_code": "LWOH",
      "airport_name": "Ohrid \"St. Paul the Apostle\" Airport"
    },
    {
      "city": "Oioiapoque",
      "country": "Brazil",
      "IATA": "OYK",
      "icao_code": "SBOI",
      "airport_name": "Oiapoque Airport"
    },
    {
      "city": "Oita",
      "country": "Japan",
      "IATA": "OIT",
      "icao_code": "RJFO",
      "airport_name": "Oita Airport"
    },
    {
      "city": "Okaukuejo",
      "country": "Namibia",
      "IATA": "OKF",
      "airport_name": "Okaukuejo Airport"
    },
    {
      "city": "Okayama",
      "country": "Japan",
      "IATA": "OKJ",
      "icao_code": "RJOB",
      "airport_name": "Okayama Airport"
    },
    {
      "city": "Okeechobee",
      "country": "United States",
      "IATA": "OBE",
      "icao_code": "KOBE",
      "airport_name": "Okeechobee County Airport"
    },
    {
      "city": "Okhotsk",
      "country": "Russia",
      "IATA": "OHO",
      "icao_code": "UHOO",
      "airport_name": "Okhotsk Airport"
    },
    {
      "city": "Oki Island",
      "country": "Japan",
      "IATA": "OKI",
      "icao_code": "RJNO",
      "airport_name": "Oki Airport"
    },
    {
      "city": "Okierabu",
      "country": "Japan",
      "IATA": "OKE",
      "icao_code": "RJKB",
      "airport_name": "Okinoerabu Airport"
    },
    {
      "city": "Okinawa",
      "country": "Japan",
      "IATA": "OKA",
      "icao_code": "ROAH",
      "airport_name": "Naha Airport"
    },
    {
      "city": "Oklahoma City",
      "country": "United States",
      "IATA": "PWA",
      "icao_code": "KPWA",
      "airport_name": "Wiley Post Airport"
    },
    {
      "city": "Oklahoma City",
      "country": "United States",
      "IATA": "OKC",
      "icao_code": "KOKC",
      "airport_name": "Will Rogers World Airport"
    },
    {
      "city": "Oklahoma City",
      "country": "United States",
      "IATA": "TIK",
      "icao_code": "KTIK",
      "airport_name": "Tinker Air Force Base"
    },
    {
      "city": "Okmulgee",
      "country": "United States",
      "IATA": "OKM",
      "icao_code": "KOKM",
      "airport_name": "Okmulgee Regional Airport"
    },
    {
      "city": "Okondja",
      "country": "Gabon",
      "IATA": "OKN",
      "icao_code": "FOGQ",
      "airport_name": "Okondja Airport"
    },
    {
      "city": "Oksibil",
      "country": "Indonesia",
      "IATA": "OKL",
      "icao_code": "WAJO",
      "airport_name": "Oksibil Airport"
    },
    {
      "city": "Okushiri",
      "country": "Japan",
      "IATA": "OIR",
      "icao_code": "RJEO",
      "airport_name": "Okushiri Airport"
    },
    {
      "city": "Olathe",
      "country": "United States",
      "IATA": "OJC",
      "icao_code": "KOJC",
      "airport_name": "Johnson County Executive Airport"
    },
    {
      "city": "Olathe",
      "country": "United States",
      "IATA": "JCI",
      "icao_code": "KIXD",
      "airport_name": "New Century AirCenter "
    },
    {
      "city": "Olavarria",
      "country": "Argentina",
      "IATA": "OVR",
      "icao_code": "SAZF",
      "airport_name": "Olavarria Airport"
    },
    {
      "city": "Olbia",
      "country": "Italy",
      "IATA": "OLB",
      "icao_code": "LIEO",
      "airport_name": "Olbia-Costa Smeralda Airport"
    },
    {
      "city": "Old Crow",
      "country": "Canada",
      "IATA": "YOC",
      "icao_code": "CYOC",
      "airport_name": "Old Crow Airport"
    },
    {
      "city": "Olekminsk",
      "country": "Russia",
      "IATA": "OLZ",
      "airport_name": "North West Santo Airport"
    },
    {
      "city": "Olenyok",
      "country": "Russia",
      "IATA": "ONK",
      "airport_name": "Olenyok Airport"
    },
    {
      "city": "Olgii",
      "country": "Mongolia",
      "IATA": "ULG",
      "icao_code": "ZMUL",
      "airport_name": "Ölgii Airport"
    },
    {
      "city": "Olive Branch",
      "country": "United States",
      "IATA": "OLV",
      "icao_code": "KOLV",
      "airport_name": "Olive Branch Airport"
    },
    {
      "city": "Olongapo City",
      "country": "Philippines",
      "IATA": "SFS",
      "icao_code": "RPLB",
      "airport_name": "Subic Bay International Airport"
    },
    {
      "city": "Olpoi",
      "country": "Vanuatu",
      "IATA": "OLJ",
      "icao_code": "NVSZ",
      "airport_name": "Olpoi Airport"
    },
    {
      "city": "Olympia",
      "country": "United States",
      "IATA": "OLM",
      "icao_code": "KOLM",
      "airport_name": "Olympia Regional Airport"
    },
    {
      "city": "Olympic Dam",
      "country": "Australia",
      "IATA": "OLP",
      "icao_code": "YOLD",
      "airport_name": "Olympic Dam Airport"
    },
    {
      "city": "Omaha",
      "country": "United States",
      "IATA": "OFF",
      "icao_code": "KOFF",
      "airport_name": "Offutt Air Force Base"
    },
    {
      "city": "Omaha",
      "country": "United States",
      "IATA": "OMA",
      "icao_code": "KOMA",
      "airport_name": "Eppley Airfield"
    },
    {
      "city": "Omboue Hospial",
      "country": "Gabon",
      "IATA": "OMB",
      "icao_code": "FOOH",
      "airport_name": "Omboué Hospital Airport"
    },
    {
      "city": "Omidyeh",
      "country": "Iran",
      "IATA": "OMI",
      "airport_name": "Omidiyeh Air Base"
    },
    {
      "city": "Omsk",
      "country": "Russia",
      "IATA": "OMS",
      "icao_code": "UNOO",
      "airport_name": "Omsk Tsentralny Airport"
    },
    {
      "city": "Ondangwa",
      "country": "Namibia",
      "IATA": "OND",
      "icao_code": "FAOA",
      "airport_name": "Ondangwa Airport"
    },
    {
      "city": "Ondjiva",
      "country": "Angola",
      "IATA": "VPE",
      "icao_code": "FNGI",
      "airport_name": "Ondjiva Pereira Airport"
    },
    {
      "city": "Onslow",
      "country": "Australia",
      "IATA": "ONS",
      "icao_code": "YOLW",
      "airport_name": "Onslow Airport"
    },
    {
      "city": "Ontario",
      "country": "United States",
      "IATA": "ONO",
      "icao_code": "KONO",
      "airport_name": "Ontario Municipal Airport"
    },
    {
      "city": "Los Angeles, Ontario",
      "country": "United States",
      "IATA": "ONT",
      "icao_code": "KONT",
      "airport_name": "Ontario International Airport"
    },
    {
      "city": "Opolu",
      "country": "United States",
      "IATA": "UPP",
      "icao_code": "PHUP",
      "airport_name": "Upolu Airport"
    },
    {
      "city": "Oradea",
      "country": "Romania",
      "IATA": "OMR",
      "icao_code": "LROD",
      "airport_name": "Oradea International Airport"
    },
    {
      "city": "Oran",
      "country": "Argentina",
      "IATA": "ORA",
      "icao_code": "SASO",
      "airport_name": "Oran Airport"
    },
    {
      "city": "Oran",
      "country": "Algeria",
      "IATA": "ORN",
      "icao_code": "DAOO",
      "airport_name": "Es-Sénia – Ahmed Ben Bella Airport"
    },
    {
      "city": "Oran",
      "country": "Algeria",
      "IATA": "TAF",
      "icao_code": "DAOL",
      "airport_name": "Oran Tafraoui Airport"
    },
    {
      "city": "Orange",
      "country": "Australia",
      "IATA": "OAG",
      "icao_code": "YORG",
      "airport_name": "Orange Regional Airport "
    },
    {
      "city": "Orange",
      "country": "France",
      "IATA": "XOG",
      "airport_name": "Orange-Caritat Air Base"
    },
    {
      "city": "Orangeburg",
      "country": "United States",
      "IATA": "OGB",
      "icao_code": "KOGB",
      "airport_name": "Orangeburg Municipal Airport"
    },
    {
      "city": "Oranjemund",
      "country": "Namibia",
      "IATA": "OMD",
      "icao_code": "FYOG",
      "airport_name": "Oranjemund Airport"
    },
    {
      "city": "Oranjestad",
      "country": "Netherlands Antilles",
      "IATA": "EUX",
      "icao_code": "TNCE",
      "airport_name": "F. D. Roosevelt Airport"
    },
    {
      "city": "Oranjestad",
      "country": "Aruba",
      "IATA": "AUA",
      "icao_code": "TNCA",
      "airport_name": "Queen Beatrix International Airport"
    },
    {
      "city": "Orapa",
      "country": "Botswana",
      "IATA": "ORP",
      "icao_code": "FBOR",
      "airport_name": "Orapa Airport"
    },
    {
      "city": "Ordu-Giresun",
      "country": "Turkey",
      "IATA": "OGU",
      "icao_code": "LTCB",
      "airport_name": "Ordu-Giresun Airport"
    },
    {
      "city": "Orebro",
      "country": "Sweden",
      "IATA": "ORB",
      "icao_code": "ESOE",
      "airport_name": "Örebro Airport"
    },
    {
      "city": "Orenburg",
      "country": "Russia",
      "IATA": "REN",
      "icao_code": "UWOO",
      "airport_name": "Orenburg Tsentralny Airport"
    },
    {
      "city": "Orinduik",
      "country": "Guyana",
      "IATA": "ORJ",
      "icao_code": "SYOR",
      "airport_name": "Orinduik Airport"
    },
    {
      "city": "Oristano",
      "country": "Italy",
      "IATA": "FNU",
      "airport_name": "Oristano-Fenosu Airport"
    },
    {
      "city": "Oriximina",
      "country": "Brazil",
      "IATA": "ORX",
      "icao_code": "SNOX",
      "airport_name": "Oriximiná Airport"
    },
    {
      "city": "Oriximina",
      "country": "Brazil",
      "IATA": "TMT",
      "icao_code": "SBTB",
      "airport_name": "Porto Trombetas Airport"
    },
    {
      "city": "Orland",
      "country": "Norway",
      "IATA": "OLA",
      "icao_code": "ENOL",
      "airport_name": "Ørland Airport"
    },
    {
      "city": "Orlando",
      "country": "United States",
      "IATA": "MCO",
      "icao_code": "KMCO",
      "airport_name": "Orlando International Airport"
    },
    {
      "city": "Orlando",
      "country": "United States",
      "IATA": "ORL",
      "icao_code": "KORL",
      "airport_name": "Orlando Executive Airport"
    },
    {
      "city": "Orleans",
      "country": "France",
      "IATA": "ORE",
      "airport_name": "Orléans – Bricy Air Base"
    },
    {
      "city": "Ormara Raik",
      "country": "Pakistan",
      "IATA": "ORW",
      "icao_code": "OPOR",
      "airport_name": "Ormara Airport"
    },
    {
      "city": "Ormoc City",
      "country": "Philippines",
      "IATA": "OMC",
      "icao_code": "RPVO",
      "airport_name": "Ormoc Airport"
    },
    {
      "city": "Ornskoldsvik",
      "country": "Sweden",
      "IATA": "OER",
      "icao_code": "ESNO",
      "airport_name": "Örnsköldsvik Airport"
    },
    {
      "city": "Orsk",
      "country": "Russia",
      "IATA": "OSW",
      "icao_code": "UWOR",
      "airport_name": "Orsk Airport"
    },
    {
      "city": "Oruro",
      "country": "Bolivia",
      "IATA": "ORU",
      "icao_code": "SLOR",
      "airport_name": "Juan Mendoza Airport"
    },
    {
      "city": "Osaka",
      "country": "Japan",
      "IATA": "KIX",
      "icao_code": "RJBB",
      "airport_name": "Kansai International Airport"
    },
    {
      "city": "Osaka",
      "country": "Japan",
      "IATA": "ITM",
      "icao_code": "RJOO",
      "airport_name": "Osaka International Airport "
    },
    {
      "city": "Osan",
      "country": "South Korea",
      "IATA": "OSN",
      "icao_code": "RKSO",
      "airport_name": "Osan Air Base"
    },
    {
      "city": "Oscoda",
      "country": "United States",
      "IATA": "OSC",
      "icao_code": "KOSC",
      "airport_name": "Oscoda-Wurtsmith Airport"
    },
    {
      "city": "Osh",
      "country": "Kyrgyzstan",
      "IATA": "OSS",
      "icao_code": "UAFO",
      "airport_name": "Osh International Airport"
    },
    {
      "city": "Oshawa",
      "country": "Canada",
      "IATA": "YOO",
      "icao_code": "CYOO",
      "airport_name": "Oshawa Executive Airport"
    },
    {
      "city": "Oshima",
      "country": "Japan",
      "IATA": "OIM",
      "icao_code": "RJTO",
      "airport_name": "Oshima Airport"
    },
    {
      "city": "Oshkosh",
      "country": "United States",
      "IATA": "OSH",
      "icao_code": "KOSH",
      "airport_name": "Wittman Regional Airport"
    },
    {
      "city": "Osijek",
      "country": "Croatia",
      "IATA": "OSI",
      "icao_code": "LDOS",
      "airport_name": "Osijek Airport"
    },
    {
      "city": "Oskarshamn",
      "country": "Sweden",
      "IATA": "OSK",
      "icao_code": "ESMO",
      "airport_name": "Oskarshamn Airport"
    },
    {
      "city": "Oslo",
      "country": "Norway",
      "IATA": "OSL",
      "icao_code": "ENGM",
      "airport_name": "Oslo Airport, Gardermoen "
    },
    {
      "city": "Osorno",
      "country": "Chile",
      "IATA": "ZOS",
      "icao_code": "SCJO",
      "airport_name": "Cañal Bajo Carlos Hott Siebert Airport"
    },
    {
      "city": "Ostend",
      "country": "Belgium",
      "IATA": "OST",
      "icao_code": "EBOS",
      "airport_name": "Ostend-Bruges International Airport"
    },
    {
      "city": "Ostrava",
      "country": "Czech Republic",
      "IATA": "OSR",
      "icao_code": "LKMT",
      "airport_name": "Ostrava-Mošnov International  Airport"
    },
    {
      "city": "Osubi",
      "country": "Nigeria",
      "IATA": "QRW",
      "icao_code": "DNSU",
      "airport_name": "Warri Airport"
    },
    {
      "city": "Otjiwarongo",
      "country": "Namibia",
      "IATA": "OTJ",
      "airport_name": "Otjiwarongo Airport"
    },
    {
      "city": "Ottawa",
      "country": "Canada",
      "IATA": "YRO",
      "icao_code": "CYRO",
      "airport_name": "Ottawa/Rockcliffe Airport"
    },
    {
      "city": "Ottawa",
      "country": "Canada",
      "IATA": "YOW",
      "icao_code": "CYOW",
      "airport_name": "Ottawa/Macdonald-Cartier International Airport"
    },
    {
      "city": "Ottumwa",
      "country": "United States",
      "IATA": "OTM",
      "icao_code": "KOTM",
      "airport_name": "Ottumwa Regional Airport"
    },
    {
      "city": "Otu",
      "country": "Colombia",
      "IATA": "OTU",
      "airport_name": "Otú Airport"
    },
    {
      "city": "Ouagadougou",
      "country": "Burkina Faso",
      "IATA": "OUA",
      "icao_code": "DFFD",
      "airport_name": "Ouagadougou International Airport"
    },
    {
      "city": "Ouargla",
      "country": "Algeria",
      "IATA": "OGX",
      "icao_code": "DAUU",
      "airport_name": "Ain Beida Airport"
    },
    {
      "city": "Ouarzazate",
      "country": "Morocco",
      "IATA": "OZZ",
      "icao_code": "GMMZ",
      "airport_name": "Ouarzazate Airport"
    },
    {
      "city": "Oudtshoorn",
      "country": "South Africa",
      "IATA": "OUH",
      "icao_code": "FAOH",
      "airport_name": "Oudtshoorn Airport"
    },
    {
      "city": "Ouesso",
      "country": "Congo (Kinshasa)",
      "IATA": "OUE",
      "icao_code": "FCOU",
      "airport_name": "Ouesso Airport"
    },
    {
      "city": "Oujda",
      "country": "Morocco",
      "IATA": "OUD",
      "icao_code": "GMFO",
      "airport_name": "Oujda Angads Airport"
    },
    {
      "city": "Oulu",
      "country": "Finland",
      "IATA": "OUL",
      "icao_code": "EFOU",
      "airport_name": "Oulu Airport"
    },
    {
      "city": "Ourilandia do Norte",
      "country": "Brazil",
      "IATA": "OIA",
      "icao_code": "SDOW",
      "airport_name": "Ourilândia do Norte Airport"
    },
    {
      "city": "Outer Skerries",
      "country": "United Kingdom",
      "IATA": "OUK",
      "airport_name": " Outer Skerries Airport"
    },
    {
      "city": "Ouvea",
      "country": "New Caledonia",
      "IATA": "UVE",
      "icao_code": "NWWV",
      "airport_name": "Ouvéa Airport"
    },
    {
      "city": "Ovda",
      "country": "Israel",
      "IATA": "VDA",
      "icao_code": "LLOV",
      "airport_name": "Ovda Airport"
    },
    {
      "city": "Overberg",
      "country": "South Africa",
      "IATA": "OVG",
      "icao_code": "FAOB",
      "airport_name": "Air Force Base Overberg"
    },
    {
      "city": "Owando",
      "country": "Congo (Kinshasa)",
      "IATA": "FTX",
      "airport_name": "Owando Airport"
    },
    {
      "city": "Owen Sound",
      "country": "Canada",
      "IATA": "YOS",
      "icao_code": "CYOS",
      "airport_name": "Owen Sound/Billy Bishop Regional Airport"
    },
    {
      "city": "Owensboro",
      "country": "United States",
      "IATA": "OWB",
      "icao_code": "KOWB",
      "airport_name": "Owensboro–Daviess County Regional Airport"
    },
    {
      "city": "Oxford",
      "country": "United States",
      "IATA": "UOX",
      "icao_code": "KUOX",
      "airport_name": "University-Oxford Airport"
    },
    {
      "city": "Oxford",
      "country": "United States",
      "IATA": "OXC",
      "icao_code": "KOXC",
      "airport_name": "Waterbury-Oxford Airport"
    },
    {
      "city": "Oxford",
      "country": "United Kingdom",
      "IATA": "OXF",
      "icao_code": "EGTK",
      "airport_name": "London Oxford Airport"
    },
    {
      "city": "Oxford House",
      "country": "Canada",
      "IATA": "YOH",
      "icao_code": "CYOH",
      "airport_name": "Oxford House Airport"
    },
    {
      "city": "Oxnard",
      "country": "United States",
      "IATA": "OXR",
      "icao_code": "KOXR",
      "airport_name": "Oxnard Airport"
    },
    {
      "city": "Oyem",
      "country": "Gabon",
      "IATA": "OYE",
      "icao_code": "FOGO",
      "airport_name": "Oyem Airport"
    },
    {
      "city": "Oyo",
      "country": "Congo (Brazzaville)",
      "IATA": "OLL",
      "airport_name": "Oyo Airport"
    },
    {
      "city": "Ozamis",
      "country": "Philippines",
      "IATA": "OZC",
      "icao_code": "RPMO",
      "airport_name": "Labo Airport"
    },
    {
      "city": "Ozona",
      "country": "United States",
      "IATA": "OZA",
      "airport_name": "Ozona Municipal Airport"
    },
    {
      "city": "PADUCAH",
      "country": "United States",
      "IATA": "PAH",
      "icao_code": "KPAH",
      "airport_name": "Barkley Regional Airport"
    },
    {
      "city": "PARKERSBURG",
      "country": "United States",
      "IATA": "PKB",
      "icao_code": "KPKB",
      "airport_name": "Mid-Ohio Valley Regional Airport"
    },
    {
      "city": "Paama Island",
      "country": "Vanuatu",
      "IATA": "PBJ",
      "icao_code": "NVSI",
      "airport_name": "Tavie Airport"
    },
    {
      "city": "Paamiut",
      "country": "Greenland",
      "IATA": "JFR",
      "icao_code": "BGFH",
      "airport_name": "Paamiut Airport"
    },
    {
      "city": "Padang",
      "country": "Indonesia",
      "IATA": "PDG",
      "icao_code": "WIMG",
      "airport_name": "Minangkabau International Airport"
    },
    {
      "city": "Padang Sidempuan",
      "country": "Indonesia",
      "IATA": "AEG",
      "icao_code": "WIME",
      "airport_name": "Aek Godang Airport"
    },
    {
      "city": "Paderborn",
      "country": "Germany",
      "IATA": "PAD",
      "icao_code": "EDLP",
      "airport_name": "Paderborn Lippstadt Airport"
    },
    {
      "city": "Padova",
      "country": "Italy",
      "IATA": "QPA",
      "airport_name": "Padua “Gino Allegri” Airport "
    },
    {
      "city": "Pagadian",
      "country": "Philippines",
      "IATA": "PAG",
      "icao_code": "RPMP",
      "airport_name": "Pagadian Airport"
    },
    {
      "city": "Page",
      "country": "United States",
      "IATA": "PGA",
      "icao_code": "KPGA",
      "airport_name": "Page Municipal Airport"
    },
    {
      "city": "Pago Pago",
      "country": "American Samoa",
      "IATA": "PPG",
      "icao_code": "NSTU",
      "airport_name": "Pago Pago International Airport"
    },
    {
      "city": "Pahokee",
      "country": "United States",
      "IATA": "PHK",
      "airport_name": "Palm Beach County Glades Airport"
    },
    {
      "city": "Pai",
      "country": "Thailand",
      "IATA": "PYY",
      "icao_code": "VTCI",
      "airport_name": "Pai Airport"
    },
    {
      "city": "Pailton",
      "country": "United Kingdom",
      "IATA": "GBA",
      "airport_name": "Big Bay Airport"
    },
    {
      "city": "Pajala",
      "country": "Sweden",
      "IATA": "PJA",
      "icao_code": "ESUP",
      "airport_name": "Pajala Airport"
    },
    {
      "city": "Pakhokku",
      "country": "Burma",
      "IATA": "PKK",
      "icao_code": "VYPU",
      "airport_name": "Pakhokku Airport"
    },
    {
      "city": "Pakse",
      "country": "Laos",
      "IATA": "PKZ",
      "icao_code": "VLPS",
      "airport_name": "Pakse International Airport"
    },
    {
      "city": "Palacios",
      "country": "United States",
      "IATA": "PSX",
      "icao_code": "KPSX",
      "airport_name": "Palacios Municipal Airport"
    },
    {
      "city": "Palanga",
      "country": "Lithuania",
      "IATA": "PLQ",
      "icao_code": "EYPA",
      "airport_name": "Palanga International Airport"
    },
    {
      "city": "Palangkaraya",
      "country": "Indonesia",
      "IATA": "PKY",
      "icao_code": "WRBP",
      "airport_name": "Tjilik Riwut Airport"
    },
    {
      "city": "Palembang",
      "country": "Indonesia",
      "IATA": "PLM",
      "icao_code": "WIPP",
      "airport_name": "Sultan Mahmud Badaruddin II International Airport"
    },
    {
      "city": "Palenque",
      "country": "Mexico",
      "IATA": "PQM",
      "icao_code": "MMPQ",
      "airport_name": "Palenque International Airport"
    },
    {
      "city": "Palermo",
      "country": "Italy",
      "IATA": "PMO",
      "icao_code": "LICJ",
      "airport_name": "Falcone–Borsellino Airport"
    },
    {
      "city": "Palm Island",
      "country": "Australia",
      "IATA": "PMK",
      "icao_code": "YPAM",
      "airport_name": "Palm Island Airport"
    },
    {
      "city": "Palm Springs",
      "country": "United States",
      "IATA": "UDD",
      "icao_code": "KUDD",
      "airport_name": "Bermuda Dunes Airport"
    },
    {
      "city": "Palm Springs",
      "country": "United States",
      "IATA": "TRM",
      "icao_code": "KTRM",
      "airport_name": "Jacqueline Cochran Regional Airport"
    },
    {
      "city": "Palm Springs",
      "country": "United States",
      "IATA": "PSP",
      "icao_code": "KPSP",
      "airport_name": "Palm Springs International Airport"
    },
    {
      "city": "Palma de Mallorca",
      "country": "Spain",
      "IATA": "PMI",
      "icao_code": "LEPA",
      "airport_name": "Palma de Mallorca Airport "
    },
    {
      "city": "Palmar Sur",
      "country": "Costa Rica",
      "IATA": "PMZ",
      "icao_code": "MRPM",
      "airport_name": "Palmar Sur Airport"
    },
    {
      "city": "Palmarito",
      "country": "Venezuela",
      "IATA": "PTM",
      "airport_name": "Palmarito Airport"
    },
    {
      "city": "Palmas",
      "country": "Brazil",
      "IATA": "PMW",
      "icao_code": "SWPJ",
      "airport_name": "Palmas–Brigadeiro Lysias Rodrigues Airport"
    },
    {
      "city": "Palmdale",
      "country": "United States",
      "IATA": "PMD",
      "airport_name": "Palmdale Regional Airport"
    },
    {
      "city": "Palmer",
      "country": "United States",
      "IATA": "PAQ",
      "icao_code": "PAAQ",
      "airport_name": "Palmer Municipal Airport"
    },
    {
      "city": "Palmerston North",
      "country": "New Zealand",
      "IATA": "PMR",
      "icao_code": "NZPM",
      "airport_name": "Palmerston North Airport"
    },
    {
      "city": "Palmyra",
      "country": "Syria",
      "IATA": "PMS",
      "icao_code": "OSPR",
      "airport_name": "Palmyra Airport"
    },
    {
      "city": "Palo Alto",
      "country": "United States",
      "IATA": "PAO",
      "icao_code": "KPAO",
      "airport_name": "Palo Alto Airport"
    },
    {
      "city": "Paloemeu",
      "country": "Suriname",
      "IATA": "OEM",
      "airport_name": "Vincent Fayks Airport"
    },
    {
      "city": "Palu",
      "country": "Indonesia",
      "IATA": "PLW",
      "icao_code": "WAML",
      "airport_name": "Mutiara SIS Al-Jufrie Airport"
    },
    {
      "city": "Pampa",
      "country": "United States",
      "IATA": "PPA",
      "airport_name": "Perry Lefors Field"
    },
    {
      "city": "Pamplona",
      "country": "Spain",
      "IATA": "PNA",
      "icao_code": "LEPP",
      "airport_name": "Pamplona Airport"
    },
    {
      "city": "Panama",
      "country": "Panama",
      "IATA": "PAC",
      "icao_code": "MPMG",
      "airport_name": "Albrook \"Marcos A. Gelabert\" International Airport"
    },
    {
      "city": "Panama City",
      "country": "United States",
      "IATA": "ECP",
      "icao_code": "KECP",
      "airport_name": "Northwest Florida Beaches International Airport"
    },
    {
      "city": "Panama City",
      "country": "United States",
      "IATA": "PFN",
      "airport_name": "Panama City-Bay County International Airport"
    },
    {
      "city": "Panama City",
      "country": "United States",
      "IATA": "PAM",
      "airport_name": "Tyndall Air Force Base"
    },
    {
      "city": "Panama City",
      "country": "Panama",
      "IATA": "PTY",
      "icao_code": "MPTO",
      "airport_name": "Tocumen International Airport"
    },
    {
      "city": "Panevezys",
      "country": "Lithuania",
      "IATA": "PNV",
      "icao_code": "EYPP",
      "airport_name": "Panevėžys Air Base"
    },
    {
      "city": "Pangkal Pinang",
      "country": "Indonesia",
      "IATA": "PGK",
      "icao_code": "WIKK",
      "airport_name": "Pangkal Pinang  Airport"
    },
    {
      "city": "Pangkalan Bun",
      "country": "Indonesia",
      "IATA": "PKN",
      "icao_code": "WRBI",
      "airport_name": "Iskandar Airport"
    },
    {
      "city": "Pangkor Island",
      "country": "Malaysia",
      "IATA": "PKG",
      "icao_code": "WMPA",
      "airport_name": "Pangkor Airport"
    },
    {
      "city": "Pangnirtung",
      "country": "Canada",
      "IATA": "YXP",
      "icao_code": "CYXP",
      "airport_name": "Pangnirtung Airport"
    },
    {
      "city": "Panjgur",
      "country": "Pakistan",
      "IATA": "PJG",
      "icao_code": "OPPG",
      "airport_name": "Panjgur Airport"
    },
    {
      "city": "Pantelleria",
      "country": "Italy",
      "IATA": "PNL",
      "icao_code": "LICG",
      "airport_name": "Pantelleria Airport"
    },
    {
      "city": "Panzhihua",
      "country": "China",
      "IATA": "PZI",
      "icao_code": "ZUZH",
      "airport_name": "Panzhihua Bao'anying Airport"
    },
    {
      "city": "Papa Westray",
      "country": "United Kingdom",
      "IATA": "PPW",
      "icao_code": "EGEP",
      "airport_name": "Papa Westray Airport"
    },
    {
      "city": "Papeete",
      "country": "French Polynesia",
      "IATA": "PPT",
      "icao_code": "NTAA",
      "airport_name": "Fa'a'a International Airport"
    },
    {
      "city": "Paphos",
      "country": "Cyprus",
      "IATA": "PFO",
      "icao_code": "LCPH",
      "airport_name": "Paphos International Airport"
    },
    {
      "city": "Paraburdoo",
      "country": "Australia",
      "IATA": "PBO",
      "icao_code": "APPB",
      "airport_name": "Paraburdoo Airport"
    },
    {
      "city": "Parachinar",
      "country": "Pakistan",
      "IATA": "PAJ",
      "airport_name": "Parachinar Airport"
    },
    {
      "city": "Paraguana",
      "country": "Venezuela",
      "IATA": "LSP",
      "icao_code": "SVJC",
      "airport_name": "Josefa Camejo International Airport"
    },
    {
      "city": "Parakou",
      "country": "Benin",
      "IATA": "PKO",
      "icao_code": "DBBP",
      "airport_name": "Parakou Airport"
    },
    {
      "city": "Paramaribo",
      "country": "Suriname",
      "IATA": "ORG",
      "airport_name": "Zorg en Hoop Airport"
    },
    {
      "city": "Parana",
      "country": "Argentina",
      "IATA": "PRA",
      "icao_code": "SAAP",
      "airport_name": "General Justo Jose de Urquiza Airport"
    },
    {
      "city": "Paraparaumu",
      "country": "New Zealand",
      "IATA": "PPQ",
      "icao_code": "NZPP",
      "airport_name": "Kapiti Coast Airport"
    },
    {
      "city": "Parauapebas",
      "country": "Brazil",
      "IATA": "CKS",
      "icao_code": "SBCJ",
      "airport_name": "Carajás Airport"
    },
    {
      "city": "Parchim",
      "country": "Germany",
      "IATA": "SZW",
      "airport_name": "Schwerin-Parchim International Airport"
    },
    {
      "city": "Pardubice",
      "country": "Czech Republic",
      "IATA": "PED",
      "icao_code": "LKPD",
      "airport_name": "Pardubice Airport"
    },
    {
      "city": "Parintins",
      "country": "Brazil",
      "IATA": "PIN",
      "icao_code": "SWPI",
      "airport_name": "Parintins  Airport"
    },
    {
      "city": "Paris",
      "country": "United States",
      "IATA": "PRX",
      "icao_code": "KPRX",
      "airport_name": "Cox Field"
    },
    {
      "city": "Paris",
      "country": "France",
      "IATA": "ORY",
      "icao_code": "LFPO",
      "airport_name": "Paris Orly Airport"
    },
    {
      "city": "Paris",
      "country": "France",
      "IATA": "CDG",
      "icao_code": "LFPG",
      "airport_name": "Charles de Gaulle International Airport"
    },
    {
      "city": "Paris",
      "country": "France",
      "IATA": "LBG",
      "icao_code": "LFPB",
      "airport_name": "Paris - Le Bourget Airport"
    },
    {
      "city": "Parkes",
      "country": "Australia",
      "IATA": "PKE",
      "icao_code": "YPKS",
      "airport_name": "Parkes Airport"
    },
    {
      "city": "Parma",
      "country": "Italy",
      "IATA": "PMF",
      "icao_code": "LIMP",
      "airport_name": "Parma Airport "
    },
    {
      "city": "Parnaiba",
      "country": "Brazil",
      "IATA": "PHB",
      "icao_code": "SBPB",
      "airport_name": "Parnaíba-Prefeito Dr. João Silva Filho International Airport"
    },
    {
      "city": "Parnu",
      "country": "Estonia",
      "IATA": "EPU",
      "icao_code": "EEPU",
      "airport_name": " Pärnu Airport "
    },
    {
      "city": "Paros",
      "country": "Greece",
      "IATA": "PAS",
      "icao_code": "LGPA",
      "airport_name": "Paros National Airport"
    },
    {
      "city": "Parry Sound",
      "country": "Canada",
      "IATA": "YPD",
      "airport_name": "Parry Sound Airport"
    },
    {
      "city": "Parsabad",
      "country": "Iran",
      "IATA": "PFQ",
      "icao_code": "OITP",
      "airport_name": "Parsabad-Moghan Airport "
    },
    {
      "city": "Parsons",
      "country": "United States",
      "IATA": "PPF",
      "icao_code": "KPPF",
      "airport_name": "Tri-City Airport"
    },
    {
      "city": "Pasco",
      "country": "United States",
      "IATA": "PSC",
      "icao_code": "KPSC",
      "airport_name": "Tri-Cities Regional Airport"
    },
    {
      "city": "Pasighat",
      "country": "India",
      "IATA": "IXT",
      "icao_code": "VEPG",
      "airport_name": "Pasighat Airport "
    },
    {
      "city": "Pasir Pangaraian",
      "country": "Indonesia",
      "IATA": "PPR",
      "icao_code": "WIDE",
      "airport_name": "Pasir Pangaraan Airport"
    },
    {
      "city": "Pasni",
      "country": "Pakistan",
      "IATA": "PSI",
      "airport_name": "Pasni Airport"
    },
    {
      "city": "Paso De Los Libres",
      "country": "Argentina",
      "IATA": "AOL",
      "icao_code": "SARL",
      "airport_name": "Paso de los Libres Airport"
    },
    {
      "city": "Paso Robles",
      "country": "United States",
      "IATA": "PRB",
      "icao_code": "KPRB",
      "airport_name": "Paso Robles Municipal Airport"
    },
    {
      "city": "Passo Fundo",
      "country": "Brazil",
      "IATA": "PFB",
      "icao_code": "SBPF",
      "airport_name": "Lauro Kurtz Airport "
    },
    {
      "city": "Pastaza",
      "country": "Ecuador",
      "IATA": "PTZ",
      "icao_code": "SEPA",
      "airport_name": "Rio Amazonas Airport"
    },
    {
      "city": "Pasto",
      "country": "Colombia",
      "IATA": "PSO",
      "icao_code": "SKPS",
      "airport_name": "Antonio Nariño Airport"
    },
    {
      "city": "Pathankot",
      "country": "India",
      "IATA": "IXP",
      "icao_code": "VIPK",
      "airport_name": "Pathankot Airport"
    },
    {
      "city": "Pathein",
      "country": "Burma",
      "IATA": "BSX",
      "icao_code": "VYPN",
      "airport_name": "Pathein Airport"
    },
    {
      "city": "Patina",
      "country": "India",
      "IATA": "PAT",
      "icao_code": "VEPT",
      "airport_name": "Jay Prakash Narayan International Airport"
    },
    {
      "city": "Patos de Minas",
      "country": "Brazil",
      "IATA": "POJ",
      "icao_code": "SNPD",
      "airport_name": "Patos de Minas Airport"
    },
    {
      "city": "Patras",
      "country": "Greece",
      "IATA": "GPA",
      "icao_code": "LGRX",
      "airport_name": "Araxos Airport"
    },
    {
      "city": "Patreksfjordur",
      "country": "Iceland",
      "IATA": "PFJ",
      "icao_code": "BIPA",
      "airport_name": "Patreksfjörður Airport"
    },
    {
      "city": "Pattani",
      "country": "Thailand",
      "IATA": "PAN",
      "icao_code": "VTSK",
      "airport_name": "Pattani Airport"
    },
    {
      "city": "Pattaya",
      "country": "Thailand",
      "IATA": "UTP",
      "icao_code": "VTBU",
      "airport_name": "U-Tapao International Airport"
    },
    {
      "city": "Patuxent River",
      "country": "United States",
      "IATA": "NHK",
      "airport_name": "Naval Air Station Patuxent River"
    },
    {
      "city": "Pau",
      "country": "France",
      "IATA": "PUF",
      "icao_code": "LFBP",
      "airport_name": "Pau Pyrénées Airport"
    },
    {
      "city": "Paulatuk",
      "country": "Canada",
      "IATA": "YPC",
      "icao_code": "CYPC",
      "airport_name": "Paulatuk (Nora Aliqatchialuk Ruben) Airport"
    },
    {
      "city": "Paulo Alfonso",
      "country": "Brazil",
      "IATA": "PAV",
      "icao_code": "SBUF",
      "airport_name": "Paulo Afonso Airport"
    },
    {
      "city": "Pavlodar",
      "country": "Kazakhstan",
      "IATA": "PWQ",
      "icao_code": "UASP",
      "airport_name": "Pavlodar International Airport"
    },
    {
      "city": "Paya Lebar",
      "country": "Singapore",
      "IATA": "QPG",
      "icao_code": "WSAP",
      "airport_name": "Paya Lebar Airbase"
    },
    {
      "city": "Paysandu",
      "country": "Uruguay",
      "IATA": "PDU",
      "icao_code": "SUPU",
      "airport_name": "Tydeo Larre Borges International Airport"
    },
    {
      "city": "Paz De Ariporo",
      "country": "Colombia",
      "IATA": "PZA",
      "airport_name": "Paz De Ariporo Airport"
    },
    {
      "city": "Peace River",
      "country": "Canada",
      "IATA": "YPE",
      "icao_code": "CYPE",
      "airport_name": "Peace River Airport"
    },
    {
      "city": "Peawanuck",
      "country": "Canada",
      "IATA": "YPO",
      "icao_code": "CYPO",
      "airport_name": "Peawanuck Airport"
    },
    {
      "city": "Pechora",
      "country": "Russia",
      "IATA": "PEX",
      "airport_name": "Pechora Airport"
    },
    {
      "city": "Pecos",
      "country": "United States",
      "IATA": "PEQ",
      "icao_code": "KPEQ",
      "airport_name": "Pecos Municipal Airport"
    },
    {
      "city": "Pedro Juan Caballero",
      "country": "Paraguay",
      "IATA": "PJC",
      "icao_code": "SGPJ",
      "airport_name": "Dr. Augusto Roberto Fuster International Airport"
    },
    {
      "city": "Peenemunde",
      "country": "Germany",
      "IATA": "PEF",
      "airport_name": "Peenemünde Airfield "
    },
    {
      "city": "Pehuajo",
      "country": "Argentina",
      "IATA": "PEH",
      "icao_code": "SAZP",
      "airport_name": "Comodoro Pedro Zanni Airport"
    },
    {
      "city": "Pekanbaru",
      "country": "Indonesia",
      "IATA": "PKU",
      "icao_code": "WIBB",
      "airport_name": "Sultan Syarif Kasim II International Airport"
    },
    {
      "city": "Pellston",
      "country": "United States",
      "IATA": "PLN",
      "icao_code": "KPLN",
      "airport_name": "Pellston Regional Airport"
    },
    {
      "city": "Pelly Bay",
      "country": "Canada",
      "IATA": "YBB",
      "icao_code": "CYBB",
      "airport_name": "Kugaaruk Airport"
    },
    {
      "city": "Pelotas",
      "country": "Brazil",
      "IATA": "PET",
      "icao_code": "SBPK",
      "airport_name": "Pelotas International Airport"
    },
    {
      "city": "Pemba",
      "country": "Tanzania",
      "IATA": "PMA",
      "icao_code": "HTPE",
      "airport_name": "Pemba Airport"
    },
    {
      "city": "Pemba",
      "country": "Mozambique",
      "IATA": "POL",
      "icao_code": "FQPB",
      "airport_name": "Pemba Airport"
    },
    {
      "city": "Pembina",
      "country": "United States",
      "IATA": "PMB",
      "icao_code": "KPMB",
      "airport_name": "Pembina Municipal Airport"
    },
    {
      "city": "Pembroke",
      "country": "Canada",
      "IATA": "YTA",
      "icao_code": "CYTA",
      "airport_name": "Pembroke Airport"
    },
    {
      "city": "Penang",
      "country": "Malaysia",
      "IATA": "PEN",
      "icao_code": "WMKP",
      "airport_name": "Penang International Airport"
    },
    {
      "city": "Pendicherry",
      "country": "India",
      "IATA": "PNY",
      "icao_code": "VOPC",
      "airport_name": "Pondicherry Airport"
    },
    {
      "city": "Pendleton",
      "country": "United States",
      "IATA": "PDT",
      "icao_code": "KPDT",
      "airport_name": "Eastern Oregon Regional Airport"
    },
    {
      "city": "Penneshaw",
      "country": "Australia",
      "IATA": "PEA",
      "icao_code": "YPSH",
      "airport_name": "Penneshaw Airport"
    },
    {
      "city": "Penrhyn Island",
      "country": "Cook Islands",
      "IATA": "PYE",
      "airport_name": "Tongareva Airport"
    },
    {
      "city": "Pensacola",
      "country": "United States",
      "IATA": "PNS",
      "icao_code": "KPNS",
      "airport_name": "Pensacola International Airport"
    },
    {
      "city": "Pensacola",
      "country": "United States",
      "IATA": "NPA",
      "icao_code": "KNPA",
      "airport_name": "Naval Air Station Pensacola"
    },
    {
      "city": "Pentecost Island",
      "country": "Vanuatu",
      "IATA": "SSR",
      "icao_code": "NVSH",
      "airport_name": "Sara Airport"
    },
    {
      "city": "Penticton",
      "country": "Canada",
      "IATA": "YYF",
      "icao_code": "CYYF",
      "airport_name": "Penticton Regional Airport"
    },
    {
      "city": "Penza",
      "country": "Russia",
      "IATA": "PEZ",
      "icao_code": "UWPP",
      "airport_name": "Penza Airport"
    },
    {
      "city": "Penzance",
      "country": "United Kingdom",
      "IATA": "PZE",
      "airport_name": "Penzance Heliport"
    },
    {
      "city": "Peoria",
      "country": "United States",
      "IATA": "PIA",
      "icao_code": "KPIA",
      "airport_name": "General Wayne A. Downing Peoria International Airport"
    },
    {
      "city": "Pereira",
      "country": "Colombia",
      "IATA": "PEI",
      "icao_code": "SKPE",
      "airport_name": "Matecaña International Airport"
    },
    {
      "city": "Perigueux",
      "country": "France",
      "IATA": "PGX",
      "icao_code": "LFBX",
      "airport_name": "Périgueux Bassillac Airport"
    },
    {
      "city": "Perito Moreno",
      "country": "Argentina",
      "IATA": "PMQ",
      "icao_code": "SAWP",
      "airport_name": "Perito Moreno Airport"
    },
    {
      "city": "Perm",
      "country": "Russia",
      "IATA": "PEE",
      "icao_code": "USPP",
      "airport_name": "Perm International Airport"
    },
    {
      "city": "Perpignan",
      "country": "France",
      "IATA": "PGF",
      "icao_code": "LFMP",
      "airport_name": "Perpignan–Rivesaltes Airport"
    },
    {
      "city": "Perryville",
      "country": "United States",
      "IATA": "KPV",
      "icao_code": "PAPE",
      "airport_name": "Perryville Airport "
    },
    {
      "city": "Perth",
      "country": "United Kingdom",
      "IATA": "PSL",
      "icao_code": "EGPT",
      "airport_name": "Perth Airport"
    },
    {
      "city": "Perth",
      "country": "Australia",
      "IATA": "PER",
      "icao_code": "YPPH",
      "airport_name": "Perth Airport"
    },
    {
      "city": "Perth",
      "country": "Australia",
      "IATA": "JAD",
      "icao_code": "YPJT",
      "airport_name": "Jandakot Airport"
    },
    {
      "city": "Peru",
      "country": "United States",
      "IATA": "VYS",
      "airport_name": "Illinois Valley Regional Airport "
    },
    {
      "city": "Peru",
      "country": "United States",
      "IATA": "GUS",
      "icao_code": "KGUS",
      "airport_name": "Grissom Air Reserve Base"
    },
    {
      "city": "Perugia",
      "country": "Italy",
      "IATA": "PEG",
      "icao_code": "LIRZ",
      "airport_name": "Perugia San Francesco d'Assisi – Umbria International Airport"
    },
    {
      "city": "Pescara",
      "country": "Italy",
      "IATA": "PSR",
      "icao_code": "LIBP",
      "airport_name": "Abruzzo Airport"
    },
    {
      "city": "Peshawar",
      "country": "Pakistan",
      "IATA": "PEW",
      "icao_code": "OPPS",
      "airport_name": "Bacha Khan International Airport"
    },
    {
      "city": "Petawawa",
      "country": "Canada",
      "IATA": "YWA",
      "icao_code": "CYWA",
      "airport_name": "Petawawa Airport"
    },
    {
      "city": "Peterborough",
      "country": "Canada",
      "IATA": "YPQ",
      "icao_code": "CYPQ",
      "airport_name": "Peterborough Airport"
    },
    {
      "city": "Petermann",
      "country": "Australia",
      "IATA": "KCS",
      "icao_code": "YKCS",
      "airport_name": "Kings Creek Airport"
    },
    {
      "city": "Petersburg",
      "country": "United States",
      "IATA": "PTB",
      "icao_code": "KPTB",
      "airport_name": "Dinwiddie County Airport"
    },
    {
      "city": "Petersburg",
      "country": "United States",
      "IATA": "PSG",
      "icao_code": "PAPG",
      "airport_name": "Petersburg James A. Johnson Airport"
    },
    {
      "city": "Petrolina",
      "country": "Brazil",
      "IATA": "PNZ",
      "icao_code": "SBPL",
      "airport_name": "Petrolina–Senador Nilo Coelho Airport"
    },
    {
      "city": "Petropavlosk",
      "country": "Kazakhstan",
      "IATA": "PPK",
      "icao_code": "UACP",
      "airport_name": "Yelizovo Airport"
    },
    {
      "city": "Petropavlovsk",
      "country": "Russia",
      "IATA": "PKC",
      "icao_code": "UHPP",
      "airport_name": "Yelizovo Airport"
    },
    {
      "city": "Petrozavodsk",
      "country": "Russia",
      "IATA": "PES",
      "icao_code": "ULPB",
      "airport_name": "Petrozavodsk Airport"
    },
    {
      "city": "Pevek",
      "country": "Russia",
      "IATA": "PWE",
      "icao_code": "UHMP",
      "airport_name": "Pevek Airport"
    },
    {
      "city": "Phalaborwa",
      "country": "South Africa",
      "IATA": "PHW",
      "icao_code": "FAPH",
      "airport_name": "Hendrik Van Eck Airport"
    },
    {
      "city": "Phan Rang",
      "country": "Vietnam",
      "IATA": "PHA",
      "airport_name": "Phan Rang Air Base"
    },
    {
      "city": "Phaplu",
      "country": "Nepal",
      "IATA": "PPL",
      "icao_code": "VNPL",
      "airport_name": "Phaplu Airport"
    },
    {
      "city": "Phetchabun",
      "country": "Thailand",
      "IATA": "PHY",
      "icao_code": "VTPB",
      "airport_name": "Phetchabun Airport"
    },
    {
      "city": "Philadelphia",
      "country": "United States",
      "IATA": "BBX",
      "icao_code": "KLOM",
      "airport_name": "Wings Field "
    },
    {
      "city": "Philadelphia",
      "country": "United States",
      "IATA": "PHL",
      "icao_code": "KPHL",
      "airport_name": "Philadelphia International Airport"
    },
    {
      "city": "Philadelphia",
      "country": "United States",
      "IATA": "PNE",
      "icao_code": "KPNE",
      "airport_name": "Northeast Philadelphia Airport"
    },
    {
      "city": "St Maarten",
      "country": "Netherlands Antilles",
      "IATA": "SXM",
      "icao_code": "TNCM",
      "airport_name": "Princess Juliana International Airport"
    },
    {
      "city": "Phinda",
      "country": "South Africa",
      "IATA": "PZL",
      "icao_code": "FADQ",
      "airport_name": "Zulu Inyala Airport"
    },
    {
      "city": "Phitsanulok",
      "country": "Thailand",
      "IATA": "PHS",
      "icao_code": "VTPP",
      "airport_name": "Phitsanulok Airport"
    },
    {
      "city": "Phnom-penh",
      "country": "Cambodia",
      "IATA": "PNH",
      "icao_code": "VDPP",
      "airport_name": "Phnom Penh International Airport"
    },
    {
      "city": "Phoenix",
      "country": "United States",
      "IATA": "DVT",
      "icao_code": "KDVT",
      "airport_name": "Phoenix Deer Valley Airport"
    },
    {
      "city": "Phoenix",
      "country": "United States",
      "IATA": "LUF",
      "airport_name": "Luke Air Force Base"
    },
    {
      "city": "Phoenix",
      "country": "United States",
      "IATA": "PHX",
      "icao_code": "KPHX",
      "airport_name": "Sky Harbor International Airport"
    },
    {
      "city": "Phon Savan",
      "country": "Laos",
      "IATA": "XKH",
      "icao_code": "VLXK",
      "airport_name": "Xieng Khouang Airport"
    },
    {
      "city": "Phongsaly",
      "country": "Laos",
      "IATA": "PCQ",
      "airport_name": "Bounneau Airport"
    },
    {
      "city": "Phrae",
      "country": "Thailand",
      "IATA": "PRH",
      "icao_code": "VTCP",
      "airport_name": "Phrae Airport"
    },
    {
      "city": "Phucat",
      "country": "Vietnam",
      "IATA": "UIH",
      "icao_code": "VVQN",
      "airport_name": "Phu Cat Airport"
    },
    {
      "city": "Phuket",
      "country": "Thailand",
      "IATA": "HKT",
      "icao_code": "VTSP",
      "airport_name": "Phuket International Airport"
    },
    {
      "city": "Phuquoc",
      "country": "Vietnam",
      "IATA": "PQC",
      "icao_code": "VVPQ",
      "airport_name": "Phu Quoc International  Airport"
    },
    {
      "city": "Pickle Lake",
      "country": "Canada",
      "IATA": "YPL",
      "icao_code": "CYPL",
      "airport_name": "Pickle Lake Airport"
    },
    {
      "city": "Pico",
      "country": "Portugal",
      "IATA": "PIX",
      "icao_code": "LPPI",
      "airport_name": "Pico Airport"
    },
    {
      "city": "Picos",
      "country": "Brazil",
      "IATA": "PCS",
      "icao_code": "SNPC",
      "airport_name": "Picos Airport"
    },
    {
      "city": "Picton",
      "country": "New Zealand",
      "IATA": "PCN",
      "icao_code": "NZPN",
      "airport_name": "Picton Aerodrome"
    },
    {
      "city": "Piedras Negras",
      "country": "Mexico",
      "IATA": "PDS",
      "airport_name": "Piedras Negras International Airport"
    },
    {
      "city": "Pierre",
      "country": "United States",
      "IATA": "PIR",
      "icao_code": "KPIR",
      "airport_name": "Pierre Regional Airport"
    },
    {
      "city": "Piestany",
      "country": "Slovakia",
      "IATA": "PZY",
      "airport_name": "Piešťany Airport"
    },
    {
      "city": "Pietermaritzburg",
      "country": "South Africa",
      "IATA": "PZB",
      "icao_code": "FAPM",
      "airport_name": "Pietermaritzburg Airport"
    },
    {
      "city": "Pikangikum",
      "country": "Canada",
      "IATA": "YPM",
      "icao_code": "CYPM",
      "airport_name": "Pikangikum Airport"
    },
    {
      "city": "Pikeville",
      "country": "United States",
      "IATA": "PVL",
      "icao_code": "KPBX",
      "airport_name": "Pike County Airport"
    },
    {
      "city": "Pikwitonei",
      "country": "Canada",
      "IATA": "PIW",
      "icao_code": "CZMN",
      "airport_name": "Pikwitonei Airport"
    },
    {
      "city": "Pilanesberg",
      "country": "South Africa",
      "IATA": "NTY",
      "icao_code": "FAPN",
      "airport_name": "Pilanesberg International Airport"
    },
    {
      "city": "Pilar",
      "country": "Paraguay",
      "IATA": "PIL",
      "icao_code": "SGPI",
      "airport_name": "Carlos Miguel Gimenez Airport"
    },
    {
      "city": "Pilot Point",
      "country": "United States",
      "IATA": "PIP",
      "icao_code": "PAPN",
      "airport_name": "Pilot Point Airport "
    },
    {
      "city": "Pimenta Bueno",
      "country": "Brazil",
      "IATA": "PBQ",
      "icao_code": "SWPM",
      "airport_name": "Pimenta Bueno Airport"
    },
    {
      "city": "Pinar Del Rio",
      "country": "Cuba",
      "IATA": "SNJ",
      "airport_name": "San Julián Air Base"
    },
    {
      "city": "Pinar Del Rio Norte",
      "country": "Cuba",
      "IATA": "QPD",
      "airport_name": "Pinar Del Rio Airport"
    },
    {
      "city": "Pincher Creek",
      "country": "Canada",
      "IATA": "WPC",
      "airport_name": "Pincher Creek Airport"
    },
    {
      "city": "Pine Mountain",
      "country": "United States",
      "IATA": "PIM",
      "icao_code": "KPIM",
      "airport_name": "Harris County Airport"
    },
    {
      "city": "Pinedale",
      "country": "United States",
      "IATA": "PWY",
      "icao_code": "KPNA",
      "airport_name": "Ralph Wenz Field"
    },
    {
      "city": "Pinehurst-Southern Pines",
      "country": "United States",
      "IATA": "SOP",
      "icao_code": "KSOP",
      "airport_name": "Moore County Airport"
    },
    {
      "city": "Pingtung",
      "country": "Taiwan",
      "IATA": "PIF",
      "icao_code": "RCDC",
      "airport_name": "Pingtung Airport"
    },
    {
      "city": "Piracununga",
      "country": "Brazil",
      "IATA": "QPS",
      "airport_name": "Campo Fontenelle "
    },
    {
      "city": "Pirlangimpi",
      "country": "Australia",
      "IATA": "GPN",
      "icao_code": "YGPT",
      "airport_name": "Garden Point Airport"
    },
    {
      "city": "Pisa",
      "country": "Italy",
      "IATA": "PSA",
      "icao_code": "LIRP",
      "airport_name": "Pisa International Airport"
    },
    {
      "city": "Pisco",
      "country": "Peru",
      "IATA": "PIO",
      "icao_code": "SPSO",
      "airport_name": "Capitán FAP Renán Elías Olivera Airport"
    },
    {
      "city": "Pitalito",
      "country": "Colombia",
      "IATA": "PTX",
      "icao_code": "SKPI",
      "airport_name": "Pitalito Airport"
    },
    {
      "city": "Pittsburgh",
      "country": "United States",
      "IATA": "AGC",
      "icao_code": "KAGC",
      "airport_name": "Allegheny County Airport"
    },
    {
      "city": "Pittsburgh",
      "country": "United States",
      "IATA": "PIT",
      "icao_code": "KPIT",
      "airport_name": "Pittsburgh International Airport"
    },
    {
      "city": "Piura",
      "country": "Peru",
      "IATA": "PIU",
      "icao_code": "SPUR",
      "airport_name": "FAP Captain Guillermo Concha Iberico International Airport"
    },
    {
      "city": "Plaisance",
      "country": "Mauritius",
      "IATA": "MRU",
      "icao_code": "FIMP",
      "airport_name": "Sir Seewoosagur Ramgoolam International Airport"
    },
    {
      "city": "Plattsburgh",
      "country": "United States",
      "IATA": "PBG",
      "icao_code": "KPBG",
      "airport_name": "Plattsburgh International Airport"
    },
    {
      "city": "Pleiku",
      "country": "Vietnam",
      "IATA": "PXU",
      "icao_code": "VVPK",
      "airport_name": "Pleiku Airport"
    },
    {
      "city": "Plettenberg Bay",
      "country": "South Africa",
      "IATA": "PBZ",
      "icao_code": "FAPG",
      "airport_name": "Plettenberg Bay Airport"
    },
    {
      "city": "Plovdiv",
      "country": "Bulgaria",
      "IATA": "PDV",
      "icao_code": "LBPD",
      "airport_name": "Plovdiv  Airport"
    },
    {
      "city": "Plymouth",
      "country": "United States",
      "IATA": "PYM",
      "icao_code": "KPYM",
      "airport_name": "Plymouth Municipal Airport"
    },
    {
      "city": "Plymouth",
      "country": "United Kingdom",
      "IATA": "PLH",
      "airport_name": "Plymouth City Airport"
    },
    {
      "city": "Pocatello",
      "country": "United States",
      "IATA": "PIH",
      "icao_code": "KPIH",
      "airport_name": "Pocatello Regional Airport"
    },
    {
      "city": "Pococi",
      "country": "Costa Rica",
      "IATA": "BCL",
      "icao_code": "MRBC",
      "airport_name": "Barra del Colorado Airport"
    },
    {
      "city": "Pocos De Caldas",
      "country": "Brazil",
      "IATA": "POO",
      "icao_code": "SBPC",
      "airport_name": "Poços de Caldas Airport"
    },
    {
      "city": "Podgorica",
      "country": "Montenegro",
      "IATA": "TGD",
      "icao_code": "LYPG",
      "airport_name": "Podgorica Airport"
    },
    {
      "city": "Pohang",
      "country": "South Korea",
      "IATA": "KPO",
      "icao_code": "RKTH",
      "airport_name": "Pohang Airport"
    },
    {
      "city": "Pohnpei",
      "country": "Micronesia",
      "IATA": "PNI",
      "icao_code": "PTPN",
      "airport_name": "Pohnpei International Airport"
    },
    {
      "city": "Point Barrow",
      "country": "United States",
      "IATA": "STG",
      "icao_code": "PAPB",
      "airport_name": "St. George Airport "
    },
    {
      "city": "Point Lay",
      "country": "United States",
      "IATA": "PIZ",
      "icao_code": "PPIZ",
      "airport_name": "Point Lay LRRS Airport"
    },
    {
      "city": "Point Mugu",
      "country": "United States",
      "IATA": "NTD",
      "icao_code": "KNTD",
      "airport_name": "Naval Air Station Point Mugu"
    },
    {
      "city": "Point Salines",
      "country": "Grenada",
      "IATA": "GND",
      "icao_code": "TGPY",
      "airport_name": "Maurice Bishop International Airport"
    },
    {
      "city": "Pointe-a-pitre",
      "country": "Guadeloupe",
      "IATA": "PTP",
      "icao_code": "TFFR",
      "airport_name": "Pointe-à-Pitre International Airport"
    },
    {
      "city": "Pointe-noire",
      "country": "Congo (Brazzaville)",
      "IATA": "PNR",
      "icao_code": "FCPP",
      "airport_name": "Antonio Agostinho Neto International Airpor"
    },
    {
      "city": "Points North Landing",
      "country": "Canada",
      "IATA": "YNL",
      "icao_code": "CYNL",
      "airport_name": "Points North Landing Airport"
    },
    {
      "city": "Poitiers",
      "country": "France",
      "IATA": "PIS",
      "icao_code": "LFBI",
      "airport_name": "Poitiers - Biard Airport"
    },
    {
      "city": "Pokhara",
      "country": "Nepal",
      "IATA": "PKR",
      "icao_code": "VNPK",
      "airport_name": "Pokhara Airport"
    },
    {
      "city": "Polonnaruwa Town",
      "country": "Sri Lanka",
      "IATA": "HIM",
      "icao_code": "VCCH",
      "airport_name": "Polonnaruwa Airport"
    },
    {
      "city": "Poltava",
      "country": "Ukraine",
      "IATA": "PLV",
      "icao_code": "UKHP",
      "airport_name": "Suprunovka Airport"
    },
    {
      "city": "Pompano Beach",
      "country": "United States",
      "IATA": "PPM",
      "icao_code": "KPMP",
      "airport_name": "Pompano Beach Airpark "
    },
    {
      "city": "Ponca City",
      "country": "United States",
      "IATA": "PNC",
      "icao_code": "KPNC",
      "airport_name": "Ponca City Regional Airport"
    },
    {
      "city": "Ponce",
      "country": "Puerto Rico",
      "IATA": "PSE",
      "icao_code": "TJPS",
      "airport_name": "Mercedita Airport"
    },
    {
      "city": "Pond Inlet",
      "country": "Canada",
      "IATA": "YIO",
      "icao_code": "CYIO",
      "airport_name": "Pond Inlet Airport"
    },
    {
      "city": "Ponta Delgada",
      "country": "Portugal",
      "IATA": "PDL",
      "icao_code": "LPPD",
      "airport_name": "João Paulo II Airport "
    },
    {
      "city": "Ponta Grossa",
      "country": "Brazil",
      "IATA": "PGZ",
      "icao_code": "SSZW",
      "airport_name": "Ponta Grossa Airport"
    },
    {
      "city": "Ponta Pora",
      "country": "Brazil",
      "IATA": "PMG",
      "icao_code": "SBPP",
      "airport_name": "Ponta Porã International Airport"
    },
    {
      "city": "Pontiac",
      "country": "United States",
      "IATA": "PTK",
      "icao_code": "KPTK",
      "airport_name": "Oakland County International Airport"
    },
    {
      "city": "Pontianak",
      "country": "Indonesia",
      "IATA": "PNK",
      "icao_code": "WIOO",
      "airport_name": "Supadio International Airport"
    },
    {
      "city": "Pontoise",
      "country": "France",
      "IATA": "POX",
      "icao_code": "LFPT",
      "airport_name": "Pontoise – Cormeilles Aerodrome"
    },
    {
      "city": "Popayan",
      "country": "Colombia",
      "IATA": "PPN",
      "icao_code": "SKPP",
      "airport_name": "Guillermo León Valencia Airport"
    },
    {
      "city": "Poplar Bluff",
      "country": "United States",
      "IATA": "POF",
      "airport_name": "Poplar Bluff Municipal Airport"
    },
    {
      "city": "Poplar Hill",
      "country": "Canada",
      "IATA": "YHP",
      "icao_code": "CPV7",
      "airport_name": "Poplar Hill Airport"
    },
    {
      "city": "Poplar River",
      "country": "Canada",
      "IATA": "XPP",
      "airport_name": "Poplar River Airport"
    },
    {
      "city": "Poprad",
      "country": "Slovakia",
      "IATA": "TAT",
      "icao_code": "LZTT",
      "airport_name": "Poprad-Tatry Airport"
    },
    {
      "city": "Porbandar",
      "country": "India",
      "IATA": "PBD",
      "icao_code": "VAPR",
      "airport_name": "Porbandar Airport"
    },
    {
      "city": "Pori",
      "country": "Finland",
      "IATA": "POR",
      "icao_code": "EFPO",
      "airport_name": "Pori Airport"
    },
    {
      "city": "Porlamar",
      "country": "Venezuela",
      "IATA": "PMV",
      "icao_code": "SVMG",
      "airport_name": "Santiago Mariño Caribbean International Airport"
    },
    {
      "city": "Pormpuraaw",
      "country": "Australia",
      "IATA": "EDR",
      "icao_code": "YPMP",
      "airport_name": "Edward River Airport"
    },
    {
      "city": "Port Angeles",
      "country": "United States",
      "IATA": "CLM",
      "icao_code": "KCLM",
      "airport_name": "William R. Fairchild International Airport"
    },
    {
      "city": "Port Antonio",
      "country": "Jamaica",
      "IATA": "POT",
      "airport_name": "Ken Jones Aerodrome"
    },
    {
      "city": "Port Bergé",
      "country": "Madagascar",
      "IATA": "WPB",
      "airport_name": "Port Bergé Airport"
    },
    {
      "city": "Port Blair",
      "country": "India",
      "IATA": "IXZ",
      "icao_code": "VOPB",
      "airport_name": "Veer Savarkar International Airport  "
    },
    {
      "city": "Port Clarence",
      "country": "United States",
      "IATA": "KPC",
      "icao_code": "PAPC",
      "airport_name": "Port Clarence Coast Guard Station"
    },
    {
      "city": "Port Elizabeth",
      "country": "South Africa",
      "IATA": "PLZ",
      "icao_code": "FAPE",
      "airport_name": "Port Elizabeth International Airport"
    },
    {
      "city": "Port Gentil",
      "country": "Gabon",
      "IATA": "POG",
      "icao_code": "FOOG",
      "airport_name": "Port-Gentil International Airport"
    },
    {
      "city": "Port Hardy",
      "country": "Canada",
      "IATA": "YZT",
      "icao_code": "CYZT",
      "airport_name": "Port Hardy Airport"
    },
    {
      "city": "Port Hartcourt",
      "country": "Nigeria",
      "IATA": "PHC",
      "icao_code": "DNPO",
      "airport_name": "Port Harcourt International Airport "
    },
    {
      "city": "Port Hawkesbury",
      "country": "Canada",
      "IATA": "YPS",
      "icao_code": "CYPD",
      "airport_name": "Port Hawkesbury Airport"
    },
    {
      "city": "Port Hedland",
      "country": "Australia",
      "IATA": "PHE",
      "icao_code": "YPPD",
      "airport_name": "Port Hedland International Airport"
    },
    {
      "city": "Port Heiden",
      "country": "United States",
      "IATA": "PTH",
      "icao_code": "PAPH",
      "airport_name": "Port Heiden Airport"
    },
    {
      "city": "Port Hope Simpson",
      "country": "Canada",
      "IATA": "YHA",
      "icao_code": "CYHA",
      "airport_name": "Port Hope Simpson Airport"
    },
    {
      "city": "Port Huron",
      "country": "United States",
      "IATA": "PHN",
      "icao_code": "KPHN",
      "airport_name": "St. Clair County International Airport"
    },
    {
      "city": "Port Lincoln",
      "country": "Australia",
      "IATA": "PLO",
      "icao_code": "YPLC",
      "airport_name": "Port Lincoln Airport"
    },
    {
      "city": "Port Macquarie",
      "country": "Australia",
      "IATA": "PQQ",
      "icao_code": "YPMQ",
      "airport_name": "Port Macquarie Airport"
    },
    {
      "city": "Port Menier",
      "country": "Canada",
      "IATA": "YPN",
      "icao_code": "CYPN",
      "airport_name": "Port-Menier Airport"
    },
    {
      "city": "Port Moller",
      "country": "United States",
      "IATA": "PTU",
      "icao_code": "PAPM",
      "airport_name": "Platinum Airport"
    },
    {
      "city": "Port Moresby",
      "country": "Papua New Guinea",
      "IATA": "POM",
      "icao_code": "AYPY",
      "airport_name": "Jacksons International Airport "
    },
    {
      "city": "Port Nelson",
      "country": "Bahamas",
      "IATA": "RCY",
      "icao_code": "MYRP",
      "airport_name": "Port Nelson Airport"
    },
    {
      "city": "Port Said",
      "country": "Egypt",
      "IATA": "PSD",
      "icao_code": "HEPS",
      "airport_name": "Port Said Airport"
    },
    {
      "city": "Port Saint Johns",
      "country": "South Africa",
      "IATA": "JOH",
      "icao_code": "FAPJ",
      "airport_name": "Port Saint Johns Airport"
    },
    {
      "city": "Port Sudan",
      "country": "Sudan",
      "IATA": "PZU",
      "icao_code": "HSSP",
      "airport_name": "Port Sudan New International Airport"
    },
    {
      "city": "Port alsworth",
      "country": "United States",
      "IATA": "PTA",
      "icao_code": "PALJ",
      "airport_name": "Port Alsworth Airport "
    },
    {
      "city": "Port-au-prince",
      "country": "Haiti",
      "IATA": "PAP",
      "icao_code": "MTPP",
      "airport_name": "Toussaint Louverture International Airport"
    },
    {
      "city": "Port-de-Paix",
      "country": "Haiti",
      "IATA": "PAX",
      "airport_name": "Port-de-Paix Airport"
    },
    {
      "city": "Port-of-spain",
      "country": "Trinidad and Tobago",
      "IATA": "POS",
      "icao_code": "TTPP",
      "airport_name": "Piarco International Airport"
    },
    {
      "city": "Port-vila",
      "country": "Vanuatu",
      "IATA": "VLI",
      "icao_code": "NVVV",
      "airport_name": "Bauerfield International Airport"
    },
    {
      "city": "Portage-la-prairie",
      "country": "Canada",
      "IATA": "YPG",
      "icao_code": "CYPG",
      "airport_name": "Portage La Prairie/Southport Airport"
    },
    {
      "city": "Portimao",
      "country": "Portugal",
      "IATA": "PRM",
      "icao_code": "LPPM",
      "airport_name": "Portimão Airport"
    },
    {
      "city": "Portland",
      "country": "Australia",
      "IATA": "PTJ",
      "icao_code": "YPOR",
      "airport_name": "Portland Airport"
    },
    {
      "city": "Portland",
      "country": "United States",
      "IATA": "PWM",
      "icao_code": "KPWM",
      "airport_name": "Portland International Jetport"
    },
    {
      "city": "Portland",
      "country": "United States",
      "IATA": "PDX",
      "icao_code": "KPDX",
      "airport_name": "Portland International Airport"
    },
    {
      "city": "Porto",
      "country": "Portugal",
      "IATA": "OPO",
      "icao_code": "LPPR",
      "airport_name": "Francisco de Sá Carneiro Airport"
    },
    {
      "city": "Porto Alegre",
      "country": "Brazil",
      "IATA": "POA",
      "icao_code": "SBPA",
      "airport_name": "Salgado Filho International Airport"
    },
    {
      "city": "Porto Amboim",
      "country": "Angola",
      "IATA": "PBN",
      "icao_code": "FNPA",
      "airport_name": "Porto Amboim Airport"
    },
    {
      "city": "Porto Heli",
      "country": "Greece",
      "IATA": "PKH",
      "icao_code": "LGHL",
      "airport_name": "Porto Cheli Airport"
    },
    {
      "city": "Porto Nacional",
      "country": "Brazil",
      "IATA": "PNB",
      "icao_code": "SBPN",
      "airport_name": "Porto Nacional Airport"
    },
    {
      "city": "Porto Santo",
      "country": "Portugal",
      "IATA": "PXO",
      "icao_code": "LPPS",
      "airport_name": "Porto Santo Airport"
    },
    {
      "city": "Porto Seguro",
      "country": "Brazil",
      "IATA": "BPS",
      "icao_code": "SBPS",
      "airport_name": "Porto Seguro Airport"
    },
    {
      "city": "Porto Velho",
      "country": "Brazil",
      "IATA": "PVH",
      "icao_code": "SBPV",
      "airport_name": "Governador Jorge Teixeira de Oliveira International Airport"
    },
    {
      "city": "Portoroz",
      "country": "Slovenia",
      "IATA": "POW",
      "icao_code": "LJPZ",
      "airport_name": "Portorož Airport"
    },
    {
      "city": "Portoviejo",
      "country": "Ecuador",
      "IATA": "PVO",
      "icao_code": "SEPV",
      "airport_name": "Reales Tamarindos Airport"
    },
    {
      "city": "Portsmouth",
      "country": "United States",
      "IATA": "PMH",
      "icao_code": "KPMH",
      "airport_name": "Greater Portsmouth Regional Airport"
    },
    {
      "city": "Portsmouth",
      "country": "United States",
      "IATA": "PSM",
      "icao_code": "KPSM",
      "airport_name": "Portsmouth International Airport at Pease"
    },
    {
      "city": "Porvenir",
      "country": "Chile",
      "IATA": "WPR",
      "airport_name": "Capitán Fuentes Martínez Airport"
    },
    {
      "city": "Posadas",
      "country": "Argentina",
      "IATA": "PSS",
      "icao_code": "SARP",
      "airport_name": "Libertador General José de San Martín Airport"
    },
    {
      "city": "Poso",
      "country": "Indonesia",
      "IATA": "PSJ",
      "icao_code": "WAMP",
      "airport_name": "Kasiguncu Airport"
    },
    {
      "city": "Postville",
      "country": "Canada",
      "IATA": "YSO",
      "icao_code": "CCD4",
      "airport_name": "Postville Airport"
    },
    {
      "city": "Potchefstroom",
      "country": "South Africa",
      "IATA": "PCF",
      "airport_name": "Potchefstroom Airport"
    },
    {
      "city": "Potgietersrus",
      "country": "South Africa",
      "IATA": "PTG",
      "icao_code": "FAPI",
      "airport_name": "Polokwane International Airport"
    },
    {
      "city": "Potosi",
      "country": "Bolivia",
      "IATA": "POI",
      "icao_code": "SLPO",
      "airport_name": "Capitan Nicolas Rojas Airport"
    },
    {
      "city": "Poughkeepsie",
      "country": "United States",
      "IATA": "POU",
      "icao_code": "KPOU",
      "airport_name": "Dutchess County Airport"
    },
    {
      "city": "Pouso Alegre",
      "country": "Brazil",
      "IATA": "PPY",
      "icao_code": "SNZA",
      "airport_name": "Pouso Alegre Airport"
    },
    {
      "city": "Powell River",
      "country": "Canada",
      "IATA": "YPW",
      "icao_code": "CYPW",
      "airport_name": "Powell River Airport"
    },
    {
      "city": "Poza Rico",
      "country": "Mexico",
      "IATA": "PAZ",
      "icao_code": "MMPA",
      "airport_name": "El Tajín National Airport"
    },
    {
      "city": "Poznan",
      "country": "Poland",
      "IATA": "POZ",
      "icao_code": "EPPO",
      "airport_name": "Poznań–Ławica Henryk Wieniawski Airport"
    },
    {
      "city": "Prachuap Khiri Khan",
      "country": "Thailand",
      "IATA": "HHQ",
      "icao_code": "VTPH",
      "airport_name": "Hua Hin Airport"
    },
    {
      "city": "Prague",
      "country": "Czech Republic",
      "IATA": "PRG",
      "icao_code": "LKPR",
      "airport_name": "Václav Havel Airport Prague"
    },
    {
      "city": "Prairie du Chien",
      "country": "United States",
      "IATA": "PCD",
      "icao_code": "KPDC",
      "airport_name": "Prairie du Chien Municipal Airport "
    },
    {
      "city": "Praslin",
      "country": "Seychelles",
      "IATA": "PRI",
      "icao_code": "FSPP",
      "airport_name": "Praslin Island Airport"
    },
    {
      "city": "Pratt",
      "country": "United States",
      "IATA": "PTT",
      "icao_code": "KPTT",
      "airport_name": "Pratt Regional Airport"
    },
    {
      "city": "Praya",
      "country": "Indonesia",
      "IATA": "LOP",
      "icao_code": "WADL",
      "airport_name": "Lombok International Airport"
    },
    {
      "city": "Prerov",
      "country": "Czech Republic",
      "IATA": "PRV",
      "icao_code": "LKPO",
      "airport_name": "Přerov Air Base"
    },
    {
      "city": "Prescott",
      "country": "United States",
      "IATA": "PRC",
      "icao_code": "KPRC",
      "airport_name": "Ernest A. Love Field"
    },
    {
      "city": "Presidencia R.s.pena",
      "country": "Argentina",
      "IATA": "PRQ",
      "airport_name": "Termal Airport "
    },
    {
      "city": "President Prudente",
      "country": "Brazil",
      "IATA": "PPB",
      "icao_code": "SBDN",
      "airport_name": "Presidente Prudente Airport"
    },
    {
      "city": "Presque Isle",
      "country": "United States",
      "IATA": "PQI",
      "icao_code": "KPQI",
      "airport_name": "Northern Maine Regional Airport at Presque Isle"
    },
    {
      "city": "Prestwick",
      "country": "United Kingdom",
      "IATA": "PIK",
      "icao_code": "EGPK",
      "airport_name": "Glasgow Prestwick Airport"
    },
    {
      "city": "Pretoria",
      "country": "South Africa",
      "IATA": "PRY",
      "icao_code": "FAWB",
      "airport_name": "Wonderboom Airport"
    },
    {
      "city": "Preveza",
      "country": "Greece",
      "IATA": "PVK",
      "icao_code": "LGPZ",
      "airport_name": "Aktion National Airport"
    },
    {
      "city": "Price",
      "country": "United States",
      "IATA": "PUC",
      "icao_code": "KPUC",
      "airport_name": "Carbon County Regional Airport/Buck Davis Field"
    },
    {
      "city": "Prince Albert",
      "country": "Canada",
      "IATA": "YPA",
      "icao_code": "CYPA",
      "airport_name": "Prince Albert (Glass Field) Airport"
    },
    {
      "city": "Prince George",
      "country": "Canada",
      "IATA": "YXS",
      "icao_code": "CYXS",
      "airport_name": "Prince George Airport"
    },
    {
      "city": "Prince Pupert",
      "country": "Canada",
      "IATA": "YPR",
      "icao_code": "CYPR",
      "airport_name": "Prince Rupert Airport"
    },
    {
      "city": "Prince Rupert",
      "country": "Canada",
      "IATA": "ZSW",
      "icao_code": "CZSW",
      "airport_name": "Prince Rupert/Seal Cove Water Airport"
    },
    {
      "city": "Principe",
      "country": "Sao Tome and Principe",
      "IATA": "PCP",
      "icao_code": "FPPR",
      "airport_name": "Príncipe Airport"
    },
    {
      "city": "Prineville",
      "country": "United States",
      "IATA": "PRZ",
      "airport_name": "Prineville Airport "
    },
    {
      "city": "Pristina",
      "country": "Serbia",
      "IATA": "PRN",
      "icao_code": "LYPR",
      "airport_name": "Pristina International Airport"
    },
    {
      "city": "Prominent Hill",
      "country": "Australia",
      "IATA": "PXH",
      "icao_code": "YPMH",
      "airport_name": "Prominent Hill Airport"
    },
    {
      "city": "Propriano",
      "country": "France",
      "IATA": "PRP",
      "airport_name": "Propriano Airport"
    },
    {
      "city": "Prospect Creek",
      "country": "United States",
      "IATA": "PPC",
      "icao_code": "PAPR",
      "airport_name": "Prospect Creek Airport"
    },
    {
      "city": "Prosserpine",
      "country": "Australia",
      "IATA": "PPP",
      "icao_code": "YBPN",
      "airport_name": "Proserpine Airport"
    },
    {
      "city": "Providence",
      "country": "United States",
      "IATA": "PVD",
      "icao_code": "KPVD",
      "airport_name": "Theodore Francis Green Memorial State Airport"
    },
    {
      "city": "Providencia",
      "country": "Colombia",
      "IATA": "PVA",
      "icao_code": "SKPV",
      "airport_name": "El Embrujo Airport"
    },
    {
      "city": "Providenciales",
      "country": "Turks and Caicos Islands",
      "IATA": "PLS",
      "icao_code": "MBPV",
      "airport_name": "Providenciales International Airport"
    },
    {
      "city": "Provideniya Bay",
      "country": "Russia",
      "IATA": "PVS",
      "icao_code": "UHMD",
      "airport_name": "Provideniya Bay Airport"
    },
    {
      "city": "Provincetown",
      "country": "United States",
      "IATA": "PVC",
      "icao_code": "KPVC",
      "airport_name": "Provincetown Municipal Airport"
    },
    {
      "city": "Provo",
      "country": "United States",
      "IATA": "PVU",
      "icao_code": "KPVU",
      "airport_name": "Provo Municipal Airport"
    },
    {
      "city": "Pskov",
      "country": "Russia",
      "IATA": "PKV",
      "icao_code": "ULOO",
      "airport_name": "Pskov Airport"
    },
    {
      "city": "Pucallpa",
      "country": "Peru",
      "IATA": "PCL",
      "icao_code": "SPCL",
      "airport_name": "FAP Captain David Abensur Rengifo International Airport"
    },
    {
      "city": "Pucon",
      "country": "Chile",
      "IATA": "ZPC",
      "icao_code": "SCPC",
      "airport_name": "Pucón Airport"
    },
    {
      "city": "Puebla",
      "country": "Mexico",
      "IATA": "PBC",
      "icao_code": "MMPB",
      "airport_name": "Hermanos Serdán International  Airport "
    },
    {
      "city": "Pueblo",
      "country": "United States",
      "IATA": "PUB",
      "icao_code": "KPUB",
      "airport_name": "Pueblo Memorial Airport"
    },
    {
      "city": "Puerto Asis",
      "country": "Colombia",
      "IATA": "PUU",
      "icao_code": "SKAS",
      "airport_name": "Tres de Mayo Airport"
    },
    {
      "city": "Puerto Ayacucho",
      "country": "Venezuela",
      "IATA": "PYH",
      "icao_code": "SVPA",
      "airport_name": "Cacique Aramare Airport"
    },
    {
      "city": "Puerto Barrios",
      "country": "Guatemala",
      "IATA": "PBR",
      "icao_code": "MGPB",
      "airport_name": "Puerto Barrios Airport"
    },
    {
      "city": "Puerto Cabello",
      "country": "Venezuela",
      "IATA": "PBL",
      "icao_code": "SVPC",
      "airport_name": "General Bartolomé Salom Airport"
    },
    {
      "city": "Puerto Cabezas",
      "country": "Nicaragua",
      "IATA": "PUZ",
      "icao_code": "MNPC",
      "airport_name": "Puerto Cabezas Airport"
    },
    {
      "city": "Puerto Carreno",
      "country": "Colombia",
      "IATA": "PCR",
      "icao_code": "SKPC",
      "airport_name": "German Olano Airport"
    },
    {
      "city": "Puerto Deseado",
      "country": "Argentina",
      "IATA": "PUD",
      "icao_code": "SAWD",
      "airport_name": "Puerto Deseado Airport"
    },
    {
      "city": "Puerto Escondido",
      "country": "Mexico",
      "IATA": "PXM",
      "icao_code": "MMPS",
      "airport_name": "Puerto Escondido International Airport"
    },
    {
      "city": "Puerto Inírida",
      "country": "Colombia",
      "IATA": "PDA",
      "icao_code": "SKPD",
      "airport_name": "Obando Airport"
    },
    {
      "city": "Puerto Jimenez",
      "country": "Costa Rica",
      "IATA": "PJM",
      "icao_code": "MRPJ",
      "airport_name": "Puerto Jiménez Airport"
    },
    {
      "city": "Puerto Leguízamo",
      "country": "Colombia",
      "IATA": "LQM",
      "icao_code": "SKLG",
      "airport_name": "Caucayá Airport"
    },
    {
      "city": "Puerto Lempira",
      "country": "Honduras",
      "IATA": "PEU",
      "icao_code": "MHPL",
      "airport_name": "Puerto Lempira Airport"
    },
    {
      "city": "Puerto Madryn",
      "country": "Argentina",
      "IATA": "PMY",
      "icao_code": "SAVY",
      "airport_name": "El Tehuelche Airport"
    },
    {
      "city": "Puerto Maldonado",
      "country": "Peru",
      "IATA": "PEM",
      "icao_code": "SPTU",
      "airport_name": "Padre Aldamiz International Airport"
    },
    {
      "city": "Puerto Montt",
      "country": "Chile",
      "IATA": "PMC",
      "icao_code": "SCTE",
      "airport_name": "El Tepual International Airport"
    },
    {
      "city": "Puerto Natales",
      "country": "Chile",
      "IATA": "PNT",
      "icao_code": "SCNT",
      "airport_name": "Teniente Julio Gallardo Airport"
    },
    {
      "city": "Puerto Obaldia",
      "country": "Panama",
      "IATA": "PUE",
      "icao_code": "MPOA",
      "airport_name": "Puerto Obaldia Airport"
    },
    {
      "city": "Puerto Plata",
      "country": "Dominican Republic",
      "IATA": "POP",
      "icao_code": "MDPP",
      "airport_name": "Gregorio Luperón International Airport"
    },
    {
      "city": "Puerto Princesa",
      "country": "Philippines",
      "IATA": "PPS",
      "icao_code": "RPVP",
      "airport_name": "Puerto Princesa International Airport"
    },
    {
      "city": "Puerto Rico/Manuripi",
      "country": "Bolivia",
      "IATA": "PUR",
      "icao_code": "SLPR",
      "airport_name": "Puerto Rico Airport"
    },
    {
      "city": "Puerto Suarez",
      "country": "Bolivia",
      "IATA": "PSZ",
      "icao_code": "SLPS",
      "airport_name": "Puerto Suárez International Airport"
    },
    {
      "city": "Puerto Vallarta",
      "country": "Mexico",
      "IATA": "PVR",
      "icao_code": "MMPR",
      "airport_name": "Lic. Gustavo Díaz Ordaz International Airport"
    },
    {
      "city": "Puerto Williams",
      "country": "Chile",
      "IATA": "WPU",
      "airport_name": "Guardiamarina Zañartu Airport"
    },
    {
      "city": "Puka Puka",
      "country": "French Polynesia",
      "IATA": "PKP",
      "icao_code": "NTGP",
      "airport_name": "Puka Puka Airport"
    },
    {
      "city": "Pukaki",
      "country": "New Zealand",
      "IATA": "TWZ",
      "airport_name": "Pukaki Airport"
    },
    {
      "city": "Pukarua",
      "country": "French Polynesia",
      "IATA": "PUK",
      "icao_code": "NTGQ",
      "airport_name": "Pukarua Airport"
    },
    {
      "city": "Pukatawagan",
      "country": "Canada",
      "IATA": "XPK",
      "icao_code": "CZFG",
      "airport_name": "Pukatawagan Airport"
    },
    {
      "city": "Pula",
      "country": "Croatia",
      "IATA": "PUY",
      "icao_code": "LDPL",
      "airport_name": "Pula Airport"
    },
    {
      "city": "Pullman",
      "country": "United States",
      "IATA": "PUW",
      "icao_code": "KPUW",
      "airport_name": "Pullman-Moscow Regional Airport"
    },
    {
      "city": "Pune",
      "country": "India",
      "IATA": "PNQ",
      "icao_code": "VAPO",
      "airport_name": "Pune Airport"
    },
    {
      "city": "Punta Arenas",
      "country": "Chile",
      "IATA": "PUQ",
      "icao_code": "SCCI",
      "airport_name": "Presidente Carlos Ibáñez del Campo International Airport"
    },
    {
      "city": "Punta Cana",
      "country": "Dominican Republic",
      "IATA": "PUJ",
      "icao_code": "MDPC",
      "airport_name": "Punta Cana International Airport"
    },
    {
      "city": "Punta Gorda",
      "country": "United States",
      "IATA": "PGD",
      "icao_code": "KPGD",
      "airport_name": "Punta Gorda Airport"
    },
    {
      "city": "Punta Penasco",
      "country": "Mexico",
      "IATA": "PPE",
      "airport_name": "Punta Penasco Airport"
    },
    {
      "city": "Punta del Este",
      "country": "Uruguay",
      "IATA": "PDP",
      "icao_code": "SUPE",
      "airport_name": "Capitán de Corbeta Carlos A. Curbelo International Airport"
    },
    {
      "city": "Puntarenas",
      "country": "Costa Rica",
      "IATA": "DRK",
      "icao_code": "MRDK",
      "airport_name": "Drake Bay Airport"
    },
    {
      "city": "Putao",
      "country": "Burma",
      "IATA": "PBU",
      "icao_code": "VYPT",
      "airport_name": "Putao Airport"
    },
    {
      "city": "Puttaparthi",
      "country": "India",
      "IATA": "PUT",
      "icao_code": "VOPN",
      "airport_name": "Puttaparthi Airport"
    },
    {
      "city": "Putussibau-Borneo Island",
      "country": "Indonesia",
      "IATA": "PSU",
      "icao_code": "WIOP",
      "airport_name": "Pangsuma Airport"
    },
    {
      "city": "Puvirnituq",
      "country": "Canada",
      "IATA": "YPX",
      "icao_code": "CYPX",
      "airport_name": "Puvirnituq Airport"
    },
    {
      "city": "Pyay",
      "country": "Burma",
      "IATA": "PRU",
      "icao_code": "VYPY",
      "airport_name": "Pyay Airport"
    },
    {
      "city": "Pyongyang",
      "country": "North Korea",
      "IATA": "FNJ",
      "icao_code": "ZKPY",
      "airport_name": "Pyongyang Sunan International Airport"
    },
    {
      "city": "Pécs-Pogány",
      "country": "Hungary",
      "IATA": "PEV",
      "icao_code": "LHPP",
      "airport_name": "Pécs-Pogány International Airport"
    },
    {
      "city": "Qaanaaq",
      "country": "Greenland",
      "IATA": "NAQ",
      "icao_code": "BGQQ",
      "airport_name": "Qaanaaq Airport"
    },
    {
      "city": "Qabala",
      "country": "Azerbaijan",
      "IATA": "GBB",
      "icao_code": "UBBQ",
      "airport_name": "Gara Djebilet Airport "
    },
    {
      "city": "Qaqortoq",
      "country": "Greenland",
      "IATA": "JJU",
      "icao_code": "BGJH",
      "airport_name": "Qaqortoq Heliport"
    },
    {
      "city": "Qasigiannguit",
      "country": "Greenland",
      "IATA": "JCH",
      "icao_code": "BGCH",
      "airport_name": "Qasigiannguit Heliport"
    },
    {
      "city": "Qayyarah",
      "country": "Iraq",
      "IATA": "RQW",
      "airport_name": "Qayyarah Airport"
    },
    {
      "city": "Qeqertaq",
      "country": "Greenland",
      "IATA": "QQT",
      "icao_code": "BGQE",
      "airport_name": "Qeqertaq Heliport"
    },
    {
      "city": "Qeqertarsuaq Airport",
      "country": "Greenland",
      "IATA": "JGO",
      "icao_code": "BGGN",
      "airport_name": "Qeqertarsuaq Heliport"
    },
    {
      "city": "Qianjiang",
      "country": "China",
      "IATA": "JIQ",
      "airport_name": "Qianjiang Wulingshan Airport"
    },
    {
      "city": "Qiemo",
      "country": "China",
      "IATA": "IQM",
      "icao_code": "ZWCM",
      "airport_name": "Qiemo Airport"
    },
    {
      "city": "Qingdao",
      "country": "China",
      "IATA": "TAO",
      "icao_code": "ZSQD",
      "airport_name": "Qingdao Liuting International Airport"
    },
    {
      "city": "Qingyang",
      "country": "China",
      "IATA": "IQN",
      "icao_code": "ZLQY",
      "airport_name": "Qingyang Airport"
    },
    {
      "city": "Qinhuangdao",
      "country": "China",
      "IATA": "SHP",
      "icao_code": "ZBSH",
      "airport_name": "Qinhuangdao Shanhaiguan Airport "
    },
    {
      "city": "Qionghai",
      "country": "China",
      "IATA": "BAR",
      "airport_name": "Baker Army Airfield "
    },
    {
      "city": "Qiqihar",
      "country": "China",
      "IATA": "NDG",
      "icao_code": "ZYQQ",
      "airport_name": "Qiqihar Sanjiazi Airport"
    },
    {
      "city": "Quakertown",
      "country": "United States",
      "IATA": "UKT",
      "icao_code": "KUKT",
      "airport_name": "Quakertown Airport"
    },
    {
      "city": "Quanzhou",
      "country": "China",
      "IATA": "JJN",
      "icao_code": "ZSQZ",
      "airport_name": "Quanzhou Jinjiang International Airport"
    },
    {
      "city": "Quaqtaq",
      "country": "Canada",
      "IATA": "YQC",
      "icao_code": "CUHA",
      "airport_name": "Quaqtaq Airport"
    },
    {
      "city": "Quebec",
      "country": "Canada",
      "IATA": "YQB",
      "icao_code": "CYQB",
      "airport_name": "Québec City Jean Lesage International Airport"
    },
    {
      "city": "Queensbury",
      "country": "United States",
      "IATA": "GFL",
      "icao_code": "KGFL",
      "airport_name": "Floyd Bennett Memorial Airport"
    },
    {
      "city": "Queenstown",
      "country": "South Africa",
      "IATA": "UTW",
      "icao_code": "FAQT",
      "airport_name": "Queenstown Airport"
    },
    {
      "city": "Queenstown International",
      "country": "New Zealand",
      "IATA": "ZQN",
      "icao_code": "NZQN",
      "airport_name": "Queenstown Airport"
    },
    {
      "city": "Quelimane",
      "country": "Mozambique",
      "IATA": "UEL",
      "icao_code": "FQQL",
      "airport_name": "Quelimane Airport"
    },
    {
      "city": "Quepos",
      "country": "Costa Rica",
      "IATA": "XQP",
      "icao_code": "MRQP",
      "airport_name": "Quepos La Managua Airport"
    },
    {
      "city": "Queretaro",
      "country": "Mexico",
      "IATA": "QRO",
      "icao_code": "MMQT",
      "airport_name": "Querétaro International Airport"
    },
    {
      "city": "Quesnel",
      "country": "Canada",
      "IATA": "YQZ",
      "icao_code": "CYQZ",
      "airport_name": "Quesnel Airport"
    },
    {
      "city": "Quetta",
      "country": "Pakistan",
      "IATA": "UET",
      "icao_code": "OPQT",
      "airport_name": "Quetta International Airport"
    },
    {
      "city": "Quezaltenango",
      "country": "Guatemala",
      "IATA": "AAZ",
      "icao_code": "MGQZ",
      "airport_name": "Quetzaltenango Airport"
    },
    {
      "city": "Quibdo",
      "country": "Colombia",
      "IATA": "UIB",
      "icao_code": "SKUI",
      "airport_name": "El Caraño Airport"
    },
    {
      "city": "Quilpie",
      "country": "Australia",
      "IATA": "ULP",
      "icao_code": "YQLP",
      "airport_name": "Quilpie Airport"
    },
    {
      "city": "Quimper",
      "country": "France",
      "IATA": "UIP",
      "icao_code": "LFRQ",
      "airport_name": "Quimper - Cornouaille Airport"
    },
    {
      "city": "Quincy",
      "country": "United States",
      "IATA": "UIN",
      "icao_code": "KUIN",
      "airport_name": "Quincy Regional Airport "
    },
    {
      "city": "Quinhagak",
      "country": "United States",
      "IATA": "KWN",
      "icao_code": "PAQH",
      "airport_name": "Quinhagak Airport "
    },
    {
      "city": "Quito",
      "country": "Ecuador",
      "IATA": "UIO",
      "icao_code": "SEQU",
      "airport_name": "Mariscal Sucre International Airport"
    },
    {
      "city": "Quujjuaq",
      "country": "Canada",
      "IATA": "YVP",
      "icao_code": "CYVP",
      "airport_name": "Kuujjuaq Airport"
    },
    {
      "city": "Quzhou",
      "country": "China",
      "IATA": "JUZ",
      "icao_code": "ZSJU",
      "airport_name": "Quzhou Airport"
    },
    {
      "city": "RADOM",
      "country": "Poland",
      "IATA": "RDO",
      "icao_code": "EPRA",
      "airport_name": "Radom Airport"
    },
    {
      "city": "Rabat",
      "country": "Morocco",
      "IATA": "RBA",
      "icao_code": "GMME",
      "airport_name": "Rabat–Salé Airport"
    },
    {
      "city": "Rach Gia",
      "country": "Vietnam",
      "IATA": "VKG",
      "icao_code": "VVRG",
      "airport_name": "Rach Gia Airport"
    },
    {
      "city": "Racine",
      "country": "United States",
      "IATA": "RAC",
      "airport_name": "John H. Batten Airport"
    },
    {
      "city": "Raduzhnyi",
      "country": "Russia",
      "IATA": "RAT",
      "airport_name": "Raduzhnyi Airport"
    },
    {
      "city": "Rafha",
      "country": "Saudi Arabia",
      "IATA": "RAH",
      "icao_code": "OERF",
      "airport_name": "Rafha Domestic Airport"
    },
    {
      "city": "Rafsanjan",
      "country": "Iran",
      "IATA": "RJN",
      "icao_code": "OIKR",
      "airport_name": "Rafsanjan Airport"
    },
    {
      "city": "Raha",
      "country": "Indonesia",
      "IATA": "RAQ",
      "icao_code": "WAWR",
      "airport_name": "Sugimanuru Airport"
    },
    {
      "city": "Rahim Yar Khan",
      "country": "Pakistan",
      "IATA": "RYK",
      "icao_code": "OPRK",
      "airport_name": "Shaikh Zayed International Airport"
    },
    {
      "city": "Raiatea Island",
      "country": "French Polynesia",
      "IATA": "RFP",
      "icao_code": "NTTR",
      "airport_name": "Raiatea Airport"
    },
    {
      "city": "Rainbow Lake",
      "country": "Canada",
      "IATA": "YOP",
      "icao_code": "CYOP",
      "airport_name": "Rainbow Lake Airport"
    },
    {
      "city": "Raipur",
      "country": "India",
      "IATA": "RPR",
      "icao_code": "VARP",
      "airport_name": "SSwami Vivekananda International  Airport"
    },
    {
      "city": "Raivavae",
      "country": "French Polynesia",
      "IATA": "RVV",
      "icao_code": "NTAV",
      "airport_name": "Raivavae Airport"
    },
    {
      "city": "Rajahmundry",
      "country": "India",
      "IATA": "RJA",
      "icao_code": "VORY",
      "airport_name": "Rajahmundry Airport"
    },
    {
      "city": "Rajbiraj",
      "country": "Nepal",
      "IATA": "RJB",
      "icao_code": "VNRB",
      "airport_name": "Rajbiraj Airport"
    },
    {
      "city": "Rajkot",
      "country": "India",
      "IATA": "RAJ",
      "icao_code": "VARK",
      "airport_name": "Rajkot Airport"
    },
    {
      "city": "Rajshahi",
      "country": "Bangladesh",
      "IATA": "RJH",
      "icao_code": "VGRJ",
      "airport_name": "Shah Makhdum Airport"
    },
    {
      "city": "Raleigh-durham",
      "country": "United States",
      "IATA": "RDU",
      "icao_code": "KRDU",
      "airport_name": "Raleigh-Durham International Airport"
    },
    {
      "city": "Ramata",
      "country": "Solomon Islands",
      "IATA": "RBV",
      "icao_code": "AGRM",
      "airport_name": "Ramata Airport"
    },
    {
      "city": "Ramechhap",
      "country": "Nepal",
      "IATA": "RHP",
      "icao_code": "VNRC",
      "airport_name": "Western Carolina Regional Airport"
    },
    {
      "city": "Ramenskoe",
      "country": "Russia",
      "IATA": "ZIA",
      "icao_code": "UUBW",
      "airport_name": "Trento / Mattarello Airport"
    },
    {
      "city": "Ramingining",
      "country": "Australia",
      "IATA": "RAM",
      "icao_code": "YRNG",
      "airport_name": "Ramingining Airport"
    },
    {
      "city": "Ramon",
      "country": "Israel",
      "IATA": "MIP",
      "icao_code": "LLMR",
      "airport_name": "Mitzpe Ramon Airport"
    },
    {
      "city": "Ramsar",
      "country": "Iran",
      "IATA": "RZR",
      "icao_code": "OINR",
      "airport_name": "Ramsar International Airport"
    },
    {
      "city": "Ramstein",
      "country": "Germany",
      "IATA": "RMS",
      "icao_code": "ETAR",
      "airport_name": "Ramstein Air Base"
    },
    {
      "city": "Ranai-Natuna Besar Island",
      "country": "Indonesia",
      "IATA": "NTX",
      "icao_code": "WION",
      "airport_name": "Ranai Airport"
    },
    {
      "city": "Rancagua",
      "country": "Chile",
      "IATA": "QRC",
      "airport_name": "Rancagua de la Independencia Airport"
    },
    {
      "city": "Ranchi",
      "country": "India",
      "IATA": "IXR",
      "icao_code": "VERC",
      "airport_name": "Birsa Munda Airport "
    },
    {
      "city": "Rangiroa",
      "country": "French Polynesia",
      "IATA": "RGI",
      "icao_code": "NTTG",
      "airport_name": "Rangiroa Airport"
    },
    {
      "city": "Rankin Inlet",
      "country": "Canada",
      "IATA": "YRT",
      "icao_code": "CYRT",
      "airport_name": "Rankin Inlet Airport"
    },
    {
      "city": "Ranong",
      "country": "Thailand",
      "IATA": "UNN",
      "icao_code": "VTSR",
      "airport_name": "Ranong Airport"
    },
    {
      "city": "Rapid City",
      "country": "United States",
      "IATA": "RAP",
      "icao_code": "KRAP",
      "airport_name": "Rapid City Regional Airport"
    },
    {
      "city": "Rapid City",
      "country": "United States",
      "IATA": "RCA",
      "airport_name": "Ellsworth Air Force Base"
    },
    {
      "city": "Raroia",
      "country": "French Polynesia",
      "IATA": "RRR",
      "icao_code": "NTKO",
      "airport_name": "Raroia Airport"
    },
    {
      "city": "Ras Al Khaimah",
      "country": "United Arab Emirates",
      "IATA": "RKT",
      "icao_code": "OMRK",
      "airport_name": "Ras Al Khaimah International Airport"
    },
    {
      "city": "Rasht",
      "country": "Iran",
      "IATA": "RAS",
      "icao_code": "OIGG",
      "airport_name": "Mustang Beach Airport"
    },
    {
      "city": "Ratanakiri",
      "country": "Cambodia",
      "IATA": "RBE",
      "icao_code": "VDRK",
      "airport_name": "Ratanakiri Airport"
    },
    {
      "city": "Raton",
      "country": "United States",
      "IATA": "RTN",
      "airport_name": "Raton Municipal Airport "
    },
    {
      "city": "Ravensthorpe",
      "country": "Australia",
      "IATA": "RVT",
      "icao_code": "YNRV",
      "airport_name": "Ravensthorpe Airport"
    },
    {
      "city": "Rawala Kot",
      "country": "Pakistan",
      "IATA": "RAZ",
      "icao_code": "OPRT",
      "airport_name": "Rawalakot Airport"
    },
    {
      "city": "Rawlins",
      "country": "United States",
      "IATA": "RWL",
      "icao_code": "KFWL",
      "airport_name": "Rawlins Municipal Airport "
    },
    {
      "city": "Reading",
      "country": "United States",
      "IATA": "RDG",
      "icao_code": "KRDG",
      "airport_name": "Reading Regional Airport "
    },
    {
      "city": "Reao",
      "country": "French Polynesia",
      "IATA": "REA",
      "icao_code": "NTGE",
      "airport_name": "Reao Airport"
    },
    {
      "city": "Rechlin-laerz",
      "country": "Germany",
      "IATA": "REB",
      "icao_code": "EDAX",
      "airport_name": "Rechlin-Laerz Airport"
    },
    {
      "city": "Recife",
      "country": "Brazil",
      "IATA": "REC",
      "icao_code": "SBRF",
      "airport_name": "Recife/Guararapes–Gilberto Freyre International Airport"
    },
    {
      "city": "Reconquista",
      "country": "Argentina",
      "IATA": "RCQ",
      "icao_code": "SATR",
      "airport_name": "Reconquista Airport"
    },
    {
      "city": "Red Bluff",
      "country": "United States",
      "IATA": "RBL",
      "icao_code": "KRBL",
      "airport_name": "Red Bluff Municipal Airport"
    },
    {
      "city": "Red Deer Industrial",
      "country": "Canada",
      "IATA": "YQF",
      "icao_code": "CYQF",
      "airport_name": "Red Deer Regional Airport"
    },
    {
      "city": "Red Dog",
      "country": "United States",
      "IATA": "RDB",
      "icao_code": "PADG",
      "airport_name": "Red Dog Airport"
    },
    {
      "city": "Red Lake",
      "country": "Canada",
      "IATA": "YRL",
      "icao_code": "CYRL",
      "airport_name": "Red Lake Airport"
    },
    {
      "city": "Red River",
      "country": "United States",
      "IATA": "RDR",
      "airport_name": "Grand Forks AFB"
    },
    {
      "city": "Red Sucker Lake",
      "country": "Canada",
      "IATA": "YRS",
      "icao_code": "CYRS",
      "airport_name": "Red Sucker Lake Airport"
    },
    {
      "city": "Redang",
      "country": "Malaysia",
      "IATA": "RDN",
      "icao_code": "WMPR",
      "airport_name": "Redang Airport"
    },
    {
      "city": "Redcliffe",
      "country": "Vanuatu",
      "IATA": "RCL",
      "icao_code": "NVSR",
      "airport_name": "Redcliffe Airport"
    },
    {
      "city": "Redding",
      "country": "United States",
      "IATA": "RDD",
      "icao_code": "KRDD",
      "airport_name": "Redding Municipal Airport"
    },
    {
      "city": "Redencao",
      "country": "Brazil",
      "IATA": "RDC",
      "icao_code": "SNDC",
      "airport_name": "Redencao Airport"
    },
    {
      "city": "Redhill",
      "country": "United Kingdom",
      "IATA": "KRH",
      "icao_code": "EGKR",
      "airport_name": "Redhill Aerodrome"
    },
    {
      "city": "Redmond-Bend",
      "country": "United States",
      "IATA": "RDM",
      "icao_code": "KRDM",
      "airport_name": "Roberts Field"
    },
    {
      "city": "Redstone",
      "country": "United States",
      "IATA": "HUA",
      "airport_name": "Redstone Army Airfield"
    },
    {
      "city": "Redwood Falls",
      "country": "United States",
      "IATA": "RWF",
      "airport_name": "Redwood Falls Municipal Airport"
    },
    {
      "city": "Reggio Calabria",
      "country": "Italy",
      "IATA": "REG",
      "icao_code": "LICR",
      "airport_name": "Reggio di Calabria \"Tito Minniti\" Airport"
    },
    {
      "city": "Regina",
      "country": "Canada",
      "IATA": "YQR",
      "icao_code": "CYQR",
      "airport_name": "Regina International Airport"
    },
    {
      "city": "Reims",
      "country": "France",
      "IATA": "RHE",
      "icao_code": "LFSR",
      "airport_name": "Reims Champagne Airport"
    },
    {
      "city": "Rengat",
      "country": "Indonesia",
      "IATA": "RGT",
      "icao_code": "WIPR",
      "airport_name": "Japura Airport"
    },
    {
      "city": "Renmark",
      "country": "Australia",
      "IATA": "RMK",
      "icao_code": "YREN",
      "airport_name": "Renmark Airport"
    },
    {
      "city": "Rennell Island",
      "country": "Solomon Islands",
      "IATA": "RNL",
      "icao_code": "AGGR",
      "airport_name": "Rennell/Tingoa Airport"
    },
    {
      "city": "Rennes",
      "country": "France",
      "IATA": "RNS",
      "icao_code": "LFRN",
      "airport_name": "Rennes–Saint-Jacques Airport"
    },
    {
      "city": "Reno",
      "country": "United States",
      "IATA": "RNO",
      "icao_code": "KRNO",
      "airport_name": "Reno-Tahoe International Airport"
    },
    {
      "city": "Rensselaer",
      "country": "United States",
      "IATA": "RNZ",
      "icao_code": "KRZL",
      "airport_name": "Jasper County Airport"
    },
    {
      "city": "Renton",
      "country": "United States",
      "IATA": "RNT",
      "icao_code": "KRNT",
      "airport_name": "Renton Municipal Airport"
    },
    {
      "city": "Repulse Bay",
      "country": "Canada",
      "IATA": "YUT",
      "icao_code": "CYUT",
      "airport_name": "Repulse Bay Airport"
    },
    {
      "city": "Rerrenabaque",
      "country": "Bolivia",
      "IATA": "RBQ",
      "icao_code": "SLRQ",
      "airport_name": "Rurrenabaque Airport"
    },
    {
      "city": "Resende",
      "country": "Brazil",
      "IATA": "REZ",
      "airport_name": "Resende Airport"
    },
    {
      "city": "Resistencia",
      "country": "Argentina",
      "IATA": "RES",
      "icao_code": "SARE",
      "airport_name": "Resistencia International Airport"
    },
    {
      "city": "Resolute",
      "country": "Canada",
      "IATA": "YRB",
      "icao_code": "CYRB",
      "airport_name": "Resolute Bay Airport"
    },
    {
      "city": "Retalhuleu",
      "country": "Guatemala",
      "IATA": "RER",
      "icao_code": "MGRT",
      "airport_name": "Retalhuleu Airport"
    },
    {
      "city": "Reus",
      "country": "Spain",
      "IATA": "REU",
      "icao_code": "LERS",
      "airport_name": "Reus Airport"
    },
    {
      "city": "Revelstoke",
      "country": "Canada",
      "IATA": "YRV",
      "airport_name": "Revelstoke Airport"
    },
    {
      "city": "Reyes",
      "country": "Bolivia",
      "IATA": "REY",
      "icao_code": "SLRY",
      "airport_name": "Reyes Airport"
    },
    {
      "city": "Reykjavik",
      "country": "Iceland",
      "IATA": "RKV",
      "icao_code": "BIRK",
      "airport_name": "Reykjavík Airport"
    },
    {
      "city": "Reynosa",
      "country": "Mexico",
      "IATA": "REX",
      "icao_code": "MMRX",
      "airport_name": "General Lucio Blanco International Airport"
    },
    {
      "city": "Rhinelander",
      "country": "United States",
      "IATA": "RHI",
      "icao_code": "KRHI",
      "airport_name": "Rhinelander–Oneida County Airport"
    },
    {
      "city": "Rhodos",
      "country": "Greece",
      "IATA": "RHO",
      "icao_code": "LGRP",
      "airport_name": "Rhodes International Airport "
    },
    {
      "city": "Ribeirao Preto",
      "country": "Brazil",
      "IATA": "RAO",
      "icao_code": "SBRP",
      "airport_name": "Dr. Leite Lopes–Ribeirão Preto State Airport"
    },
    {
      "city": "Riberalta",
      "country": "Bolivia",
      "IATA": "RIB",
      "icao_code": "SLRI",
      "airport_name": "Riberalta Airport"
    },
    {
      "city": "Richard's Bay",
      "country": "South Africa",
      "IATA": "RCB",
      "icao_code": "FARB",
      "airport_name": "Richards Bay Airport"
    },
    {
      "city": "Richmond",
      "country": "Australia",
      "IATA": "RCM",
      "icao_code": "YSRI",
      "airport_name": "Richmond Airport"
    },
    {
      "city": "Richmond",
      "country": "United States",
      "IATA": "RIC",
      "icao_code": "KRIC",
      "airport_name": "Richmond International Airport"
    },
    {
      "city": "Richmond",
      "country": "Australia",
      "IATA": "XRH",
      "airport_name": "Richmond Airport"
    },
    {
      "city": "Richmond Heights",
      "country": "United States",
      "IATA": "CGF",
      "airport_name": "Cuyahoga County Airport"
    },
    {
      "city": "Riesa",
      "country": "Germany",
      "IATA": "IES",
      "airport_name": "Riesa Gohlis Airport"
    },
    {
      "city": "Rifle",
      "country": "United States",
      "IATA": "RIL",
      "icao_code": "KRIL",
      "airport_name": "Garfield County Regional Airport"
    },
    {
      "city": "Riga",
      "country": "Latvia",
      "IATA": "RIX",
      "icao_code": "EVRA",
      "airport_name": "Riga International Airport"
    },
    {
      "city": "Rigolet",
      "country": "Canada",
      "IATA": "YRG",
      "icao_code": "CCZ2",
      "airport_name": "Rigolet Airport"
    },
    {
      "city": "Rijeka",
      "country": "Croatia",
      "IATA": "RJK",
      "icao_code": "LDRI",
      "airport_name": "Rijeka Airport"
    },
    {
      "city": "Rimatara",
      "country": "French Polynesia",
      "IATA": "RMT",
      "icao_code": "NTAM",
      "airport_name": "Rimatara Airport"
    },
    {
      "city": "Rimini",
      "country": "Italy",
      "IATA": "RMI",
      "icao_code": "LIPR",
      "airport_name": "Federico Fellini International Airport"
    },
    {
      "city": "Rimouski",
      "country": "Canada",
      "IATA": "YXK",
      "icao_code": "CYXK",
      "airport_name": "Rimouski Airport"
    },
    {
      "city": "Rincon de los Sauces",
      "country": "Argentina",
      "IATA": "RDS",
      "icao_code": "SAHS",
      "airport_name": "Rincon De Los Sauces Airport"
    },
    {
      "city": "Ringi Cove",
      "country": "Solomon Islands",
      "IATA": "RIN",
      "icao_code": "AGRC",
      "airport_name": "Ringi Cove Airport"
    },
    {
      "city": "Rio Branco",
      "country": "Brazil",
      "IATA": "RBR",
      "icao_code": "SBRB",
      "airport_name": "Rio Branco International Airport "
    },
    {
      "city": "Rio Cuarto",
      "country": "Argentina",
      "IATA": "RCU",
      "icao_code": "SAOC",
      "airport_name": "Las Higueras Airport"
    },
    {
      "city": "Rio De Janeiro",
      "country": "Brazil",
      "IATA": "SNZ",
      "airport_name": "Santa Cruz Air Force Base"
    },
    {
      "city": "Rio De Janeiro",
      "country": "Brazil",
      "IATA": "SDU",
      "icao_code": "SBRJ",
      "airport_name": "Santos Dumont Airport"
    },
    {
      "city": "Rio De Janeiro",
      "country": "Brazil",
      "IATA": "GIG",
      "icao_code": "SBGL",
      "airport_name": "Rio de Janeiro–Galeão International Airport"
    },
    {
      "city": "Rio Gallegos",
      "country": "Argentina",
      "IATA": "RGL",
      "icao_code": "SAWG",
      "airport_name": "Piloto Civil Norberto Fernandez International Airport"
    },
    {
      "city": "Rio Grande",
      "country": "Argentina",
      "IATA": "RGA",
      "icao_code": "SAWE",
      "airport_name": "Hermes Quijada International Airport"
    },
    {
      "city": "Rio Hacha",
      "country": "Colombia",
      "IATA": "RCH",
      "icao_code": "SKRH",
      "airport_name": "Almirante Padilla Airport"
    },
    {
      "city": "Rio Hato",
      "country": "Panama",
      "IATA": "RIH",
      "icao_code": "MPRH",
      "airport_name": "Scarlett Martínez International Airport"
    },
    {
      "city": "Rio Hondo",
      "country": "Argentina",
      "IATA": "RHD",
      "icao_code": "SANH",
      "airport_name": "Las Termas Airport"
    },
    {
      "city": "Rio Negro",
      "country": "Colombia",
      "IATA": "MDE",
      "icao_code": "SKRG",
      "airport_name": "José María Córdova  International Airport"
    },
    {
      "city": "Rio Verde",
      "country": "Brazil",
      "IATA": "RVD",
      "icao_code": "SWLC",
      "airport_name": "Rio Verde Airport"
    },
    {
      "city": "Rioja",
      "country": "Peru",
      "IATA": "RIJ",
      "icao_code": "SPJA",
      "airport_name": "Juan Simons Vela Airport"
    },
    {
      "city": "Rishiri Island",
      "country": "Japan",
      "IATA": "RIS",
      "icao_code": "RJER",
      "airport_name": "Rishiri Airport"
    },
    {
      "city": "Rivera",
      "country": "Uruguay",
      "IATA": "RVY",
      "icao_code": "SURV",
      "airport_name": "Pres. Gral. Óscar D. Gestido International Airport"
    },
    {
      "city": "Riverside",
      "country": "United States",
      "IATA": "RIV",
      "airport_name": "March Air Reserve Base"
    },
    {
      "city": "Riverside",
      "country": "United States",
      "IATA": "RAL",
      "icao_code": "KRAL",
      "airport_name": "Riverside Municipal Airport"
    },
    {
      "city": "Riverton WY",
      "country": "United States",
      "IATA": "RIW",
      "icao_code": "KRIW",
      "airport_name": "Riverton Regional Airport"
    },
    {
      "city": "Riviere Du Loup",
      "country": "Canada",
      "IATA": "YRI",
      "icao_code": "CYRI",
      "airport_name": "Rivière-du-Loup Airport"
    },
    {
      "city": "Rivne",
      "country": "Ukraine",
      "IATA": "RWN",
      "icao_code": "UKLR",
      "airport_name": "Arens Field Airport"
    },
    {
      "city": "Riyadh",
      "country": "Saudi Arabia",
      "IATA": "XXN",
      "airport_name": "Riyadh Air Base"
    },
    {
      "city": "Riyadh",
      "country": "Saudi Arabia",
      "IATA": "RUH",
      "icao_code": "OERK",
      "airport_name": "King Khalid International Airport"
    },
    {
      "city": "Rizhao",
      "country": "China",
      "IATA": "RIZ",
      "airport_name": "Rizhao Shanzihe Airport"
    },
    {
      "city": "Roanne",
      "country": "France",
      "IATA": "RNE",
      "icao_code": "LFLO",
      "airport_name": " Roanne-Renaison Airport"
    },
    {
      "city": "Roanoke VA",
      "country": "United States",
      "IATA": "ROA",
      "icao_code": "KROA",
      "airport_name": "Roanoke Regional Airport "
    },
    {
      "city": "Roatan",
      "country": "Honduras",
      "IATA": "RTB",
      "icao_code": "MHRO",
      "airport_name": "Juan Manuel Gálvez International Airport"
    },
    {
      "city": "Robertson",
      "country": "South Africa",
      "IATA": "ROD",
      "airport_name": "Robertson Airport"
    },
    {
      "city": "Roberval",
      "country": "Canada",
      "IATA": "YRJ",
      "icao_code": "CYRJ",
      "airport_name": "Roberval Airport"
    },
    {
      "city": "Rochefort",
      "country": "France",
      "IATA": "RCO",
      "icao_code": "LFDN",
      "airport_name": "Rochefort-Saint-Agnant Airport"
    },
    {
      "city": "Rochester",
      "country": "United Kingdom",
      "IATA": "RCS",
      "icao_code": "EGTO",
      "airport_name": "Rochester Airport"
    },
    {
      "city": "Rochester",
      "country": "United States",
      "IATA": "RST",
      "icao_code": "KRST",
      "airport_name": "Rochester International Airport"
    },
    {
      "city": "Rochester",
      "country": "United States",
      "IATA": "ROC",
      "icao_code": "KROC",
      "airport_name": "Greater Rochester International Airport"
    },
    {
      "city": "Rock Hill",
      "country": "United States",
      "IATA": "RKH",
      "icao_code": "KUZA",
      "airport_name": "Rock Hill York Co Bryant Airport"
    },
    {
      "city": "Rock Sound",
      "country": "Bahamas",
      "IATA": "RSD",
      "icao_code": "MYER",
      "airport_name": "Rock Sound International Airport"
    },
    {
      "city": "Rock Springs",
      "country": "United States",
      "IATA": "RKS",
      "icao_code": "KRKS",
      "airport_name": "Rock Springs-Sweetwater County Airport"
    },
    {
      "city": "Rockford",
      "country": "United States",
      "IATA": "RFD",
      "icao_code": "KRFD",
      "airport_name": "Chicago Rockford International Airport "
    },
    {
      "city": "Rockhampton",
      "country": "Australia",
      "IATA": "ROK",
      "icao_code": "YBRK",
      "airport_name": "Rockhampton Airport"
    },
    {
      "city": "Rockland",
      "country": "United States",
      "IATA": "RKD",
      "icao_code": "KRKD",
      "airport_name": "Knox County Regional Airport"
    },
    {
      "city": "Rockport",
      "country": "United States",
      "IATA": "RKP",
      "icao_code": "KRKP",
      "airport_name": "Aransas County Airport"
    },
    {
      "city": "Rocky Mount",
      "country": "United States",
      "IATA": "RWI",
      "icao_code": "KRWI",
      "airport_name": "Rocky Mount–Wilson Regional Airport"
    },
    {
      "city": "Rocky Mountain House",
      "country": "Canada",
      "IATA": "YRM",
      "icao_code": "CYRM",
      "airport_name": "Rocky Mountain House Airport"
    },
    {
      "city": "Rodez",
      "country": "France",
      "IATA": "RDZ",
      "icao_code": "LFCR",
      "airport_name": "Rodez–Marcillac Airport"
    },
    {
      "city": "Rodriguez Island",
      "country": "Mauritius",
      "IATA": "RRG",
      "icao_code": "FIMR",
      "airport_name": "Sir Gaëtan Duval Airport "
    },
    {
      "city": "Roi Et",
      "country": "Thailand",
      "IATA": "ROI",
      "icao_code": "VTUV",
      "airport_name": "Roi Et Airport"
    },
    {
      "city": "Roma",
      "country": "Australia",
      "IATA": "RMA",
      "icao_code": "YROM",
      "airport_name": "Roma Airport"
    },
    {
      "city": "Romblon",
      "country": "Philippines",
      "IATA": "TBH",
      "icao_code": "RPVU",
      "airport_name": "Tugdan Airport "
    },
    {
      "city": "Romblon",
      "country": "Philippines",
      "IATA": "GES",
      "icao_code": "RPWB",
      "airport_name": "General Santos International Airport"
    },
    {
      "city": "Rome",
      "country": "United States",
      "IATA": "RMG",
      "icao_code": "KRMG",
      "airport_name": "Richard B. Russell Regional Airport"
    },
    {
      "city": "Rome",
      "country": "United States",
      "IATA": "RME",
      "icao_code": "KRME",
      "airport_name": "Griffiss International Airport"
    },
    {
      "city": "Rome",
      "country": "Italy",
      "IATA": "FCO",
      "icao_code": "LIRF",
      "airport_name": "Fiumicino – Leonardo Da Vinci International Airport"
    },
    {
      "city": "Rome",
      "country": "Italy",
      "IATA": "CIA",
      "icao_code": "LIRA",
      "airport_name": "Ciampino–G. B. Pastine International Airport"
    },
    {
      "city": "Ronchi De Legionari",
      "country": "Italy",
      "IATA": "TRS",
      "icao_code": "LIPQ",
      "airport_name": "Trieste – Friuli Venezia Giulia Airport"
    },
    {
      "city": "Rondonopolis",
      "country": "Brazil",
      "IATA": "ROO",
      "icao_code": "SWRD",
      "airport_name": "Rondonópolis Airport"
    },
    {
      "city": "Ronne",
      "country": "Denmark",
      "IATA": "RNN",
      "icao_code": "EKRN",
      "airport_name": "Bornholm Airport"
    },
    {
      "city": "Ronneby",
      "country": "Sweden",
      "IATA": "RNB",
      "icao_code": "ESDF",
      "airport_name": "Ronneby Airport"
    },
    {
      "city": "Roper Bar",
      "country": "Australia",
      "IATA": "RPB",
      "airport_name": "Belleville Municipal Airport"
    },
    {
      "city": "Roros",
      "country": "Norway",
      "IATA": "RRS",
      "icao_code": "ENRO",
      "airport_name": "Røros Airport"
    },
    {
      "city": "Rosario",
      "country": "Argentina",
      "IATA": "ROS",
      "icao_code": "SAAR",
      "airport_name": "Rosario - Islas Malvinas International Airport"
    },
    {
      "city": "Rosecrans",
      "country": "United States",
      "IATA": "STJ",
      "icao_code": "KSTJ",
      "airport_name": "Rosecrans Memorial Airport"
    },
    {
      "city": "Rosh Pina",
      "country": "Israel",
      "IATA": "RPN",
      "icao_code": "LLIB",
      "airport_name": "Rosh Pina Airport"
    },
    {
      "city": "Rosita",
      "country": "Nicaragua",
      "IATA": "RFS",
      "icao_code": "MNRT",
      "airport_name": "Rosita Airport"
    },
    {
      "city": "Ross River",
      "country": "Canada",
      "IATA": "XRR",
      "airport_name": "Ross River Airport"
    },
    {
      "city": "Rostov",
      "country": "Russia",
      "IATA": "ROV",
      "icao_code": "URRR",
      "airport_name": "Rostov-on-Don Airport"
    },
    {
      "city": "Roswell",
      "country": "United States",
      "IATA": "ROW",
      "icao_code": "KROW",
      "airport_name": "Roswell International Air Center"
    },
    {
      "city": "Rota",
      "country": "Northern Mariana Islands",
      "IATA": "ROP",
      "icao_code": "PGRO",
      "airport_name": "Rota International Airport"
    },
    {
      "city": "Rota",
      "country": "Spain",
      "IATA": "ROZ",
      "airport_name": "Rota Naval Station Airport"
    },
    {
      "city": "Rotorua",
      "country": "New Zealand",
      "IATA": "ROT",
      "icao_code": "NZRO",
      "airport_name": "Rotorua Regional Airport"
    },
    {
      "city": "Rotterdam",
      "country": "Netherlands",
      "IATA": "RTM",
      "icao_code": "EHRD",
      "airport_name": "Rotterdam The Hague Airport"
    },
    {
      "city": "Rottnest Island",
      "country": "Australia",
      "IATA": "RTS",
      "icao_code": "YRTI",
      "airport_name": "Rottnest Island Airport"
    },
    {
      "city": "Rotuma",
      "country": "Fiji",
      "IATA": "RTA",
      "icao_code": "NFNR",
      "airport_name": "Rotuma Airport"
    },
    {
      "city": "Rouen",
      "country": "France",
      "IATA": "URO",
      "icao_code": "LFOP",
      "airport_name": "Rouen Airport"
    },
    {
      "city": "Round Lake",
      "country": "Canada",
      "IATA": "ZRJ",
      "icao_code": "CZRJ",
      "airport_name": "Round Lake (Weagamow Lake) Airport"
    },
    {
      "city": "Rourkela",
      "country": "India",
      "IATA": "RRK",
      "icao_code": "VERK",
      "airport_name": "Rourkela Airport"
    },
    {
      "city": "Rouyn",
      "country": "Canada",
      "IATA": "YUY",
      "icao_code": "CYUY",
      "airport_name": "Rouyn-Noranda Airport"
    },
    {
      "city": "Rovaniemi",
      "country": "Finland",
      "IATA": "RVN",
      "icao_code": "EFRO",
      "airport_name": "Rovaniemi Airport"
    },
    {
      "city": "Roxana",
      "country": "Costa Rica",
      "IATA": "TTQ",
      "icao_code": "MRAO",
      "airport_name": "Tortuguero Airport"
    },
    {
      "city": "Roxas City",
      "country": "Philippines",
      "IATA": "RXS",
      "icao_code": "RPVR",
      "airport_name": "Roxas Airport"
    },
    {
      "city": "Royan",
      "country": "France",
      "IATA": "RYN",
      "icao_code": "LFCY",
      "airport_name": "Ryan Airfield"
    },
    {
      "city": "Rubelsanto",
      "country": "Guatemala",
      "IATA": "RUV",
      "icao_code": "MGRB",
      "airport_name": "Rubelsanto Airport"
    },
    {
      "city": "Ruby",
      "country": "United States",
      "IATA": "RBY",
      "icao_code": "PARY",
      "airport_name": "Ruby Airport"
    },
    {
      "city": "Ruegen",
      "country": "Germany",
      "IATA": "GTI",
      "airport_name": "Rügen Airport"
    },
    {
      "city": "Rugao",
      "country": "China",
      "IATA": "RUG",
      "icao_code": "ZSRG",
      "airport_name": "Rugby Municipal Airport"
    },
    {
      "city": "Ruidoso",
      "country": "United States",
      "IATA": "RUI",
      "airport_name": "Sierra Blanca Regional Airport"
    },
    {
      "city": "Rukumkot",
      "country": "Nepal",
      "IATA": "RUK",
      "icao_code": "VNRK",
      "airport_name": "Chaurjahari  Airport"
    },
    {
      "city": "Rumbek",
      "country": "Sudan",
      "IATA": "RBX",
      "icao_code": "HSMK",
      "airport_name": "Rumbek Airport"
    },
    {
      "city": "Rumjatar",
      "country": "Nepal",
      "IATA": "RUM",
      "icao_code": "VNRT",
      "airport_name": "Rumjatar Airport"
    },
    {
      "city": "Rundu",
      "country": "Namibia",
      "IATA": "NDU",
      "icao_code": "FYRU",
      "airport_name": "Rundu Airport"
    },
    {
      "city": "Rurutu",
      "country": "French Polynesia",
      "IATA": "RUR",
      "icao_code": "NTAR",
      "airport_name": "Rurutu Airport"
    },
    {
      "city": "Russell",
      "country": "United States",
      "IATA": "RSL",
      "icao_code": "KRSL",
      "airport_name": "Russell Municipal Airport"
    },
    {
      "city": "Russian Mission",
      "country": "United States",
      "IATA": "RSH",
      "icao_code": "PARS",
      "airport_name": "Russian Mission Airport"
    },
    {
      "city": "Ruteng",
      "country": "Indonesia",
      "IATA": "RTG",
      "airport_name": "Satar Tacik Airport"
    },
    {
      "city": "Rutland",
      "country": "United States",
      "IATA": "RUT",
      "icao_code": "KRUT",
      "airport_name": "Rutland–Southern Vermont Regional Airport"
    },
    {
      "city": "Rybinsk",
      "country": "Russia",
      "IATA": "RYB",
      "icao_code": "UUBK",
      "airport_name": "Staroselye Airport"
    },
    {
      "city": "Rzeszow",
      "country": "Poland",
      "IATA": "RZE",
      "icao_code": "EPRZ",
      "airport_name": "Rzeszów-Jasionka Airport"
    },
    {
      "city": "Røst",
      "country": "Norway",
      "IATA": "RET",
      "icao_code": "ENRS",
      "airport_name": "Røst Airport"
    },
    {
      "city": "SARATOGA",
      "country": "United States",
      "IATA": "SAA",
      "airport_name": "Shively Field"
    },
    {
      "city": "ST MARY'S",
      "country": "United Kingdom",
      "IATA": "ISC",
      "icao_code": "EGHE",
      "airport_name": "St Mary's Airport"
    },
    {
      "city": "Saarbruecken",
      "country": "Germany",
      "IATA": "SCN",
      "icao_code": "EDDR",
      "airport_name": "Saarbrücken Airport"
    },
    {
      "city": "Saba",
      "country": "Netherlands Antilles",
      "IATA": "SAB",
      "icao_code": "TNCS",
      "airport_name": "Juancho E. Yrausquin Airport"
    },
    {
      "city": "Sabadell",
      "country": "Spain",
      "IATA": "QSA",
      "airport_name": "Sabadell Airport"
    },
    {
      "city": "Sabang",
      "country": "Indonesia",
      "IATA": "SBG",
      "icao_code": "WITB",
      "airport_name": "Maimun Saleh Airport"
    },
    {
      "city": "Sabetta",
      "country": "Russia",
      "IATA": "SBT",
      "icao_code": "USDA",
      "airport_name": "Sabetta Airport"
    },
    {
      "city": "Sabzevar",
      "country": "Iran",
      "IATA": "AFZ",
      "icao_code": "OIMS",
      "airport_name": "Sabzevar  Airport"
    },
    {
      "city": "Sacheon",
      "country": "South Korea",
      "IATA": "HIN",
      "icao_code": "RKPS",
      "airport_name": "Sacheon Airport"
    },
    {
      "city": "Sachigo Lake",
      "country": "Canada",
      "IATA": "ZPB",
      "icao_code": "CZPB",
      "airport_name": "Sachigo Lake Airport"
    },
    {
      "city": "Sachs Harbour",
      "country": "Canada",
      "IATA": "YSY",
      "icao_code": "CYSY",
      "airport_name": "Sachs Harbour Airport"
    },
    {
      "city": "Sacramento",
      "country": "United States",
      "IATA": "SMF",
      "icao_code": "KSMF",
      "airport_name": "Sacramento International Airport"
    },
    {
      "city": "Sacramento",
      "country": "United States",
      "IATA": "MCC",
      "airport_name": "McClellan Airfield"
    },
    {
      "city": "Sacramento",
      "country": "United States",
      "IATA": "SAC",
      "icao_code": "KSAC",
      "airport_name": "Sacramento Executive Airport"
    },
    {
      "city": "Sacramento",
      "country": "United States",
      "IATA": "MHR",
      "icao_code": "KMHR",
      "airport_name": "Sacramento Mather Airport"
    },
    {
      "city": "Sado",
      "country": "Japan",
      "IATA": "SDS",
      "icao_code": "RJSD",
      "airport_name": "Sado Airport"
    },
    {
      "city": "Safford",
      "country": "United States",
      "IATA": "SAD",
      "icao_code": "KSAD",
      "airport_name": "Safford Regional Airport"
    },
    {
      "city": "Saga",
      "country": "Japan",
      "IATA": "HSG",
      "icao_code": "RJFS",
      "airport_name": "Saga Airport"
    },
    {
      "city": "Saginaw",
      "country": "United States",
      "IATA": "MBS",
      "icao_code": "KMBS",
      "airport_name": "MBS International Airport"
    },
    {
      "city": "Saibai Island",
      "country": "Australia",
      "IATA": "SBR",
      "airport_name": "Saibai Island Airport"
    },
    {
      "city": "Saidpur",
      "country": "Bangladesh",
      "IATA": "SPD",
      "icao_code": "VGSD",
      "airport_name": "Saidpur Airport"
    },
    {
      "city": "Saidu Sharif",
      "country": "Pakistan",
      "IATA": "SDT",
      "icao_code": "OPSS",
      "airport_name": "Saidu Sharif Airport"
    },
    {
      "city": "Saint Catherines",
      "country": "Canada",
      "IATA": "YCM",
      "icao_code": "CYSN",
      "airport_name": "St. Catharines/Niagara District Airport"
    },
    {
      "city": "Saint Cloud",
      "country": "United States",
      "IATA": "STC",
      "icao_code": "KSTC",
      "airport_name": "St. Cloud Regional Airport"
    },
    {
      "city": "Saint George",
      "country": "United States",
      "IATA": "SGU",
      "icao_code": "KSGU",
      "airport_name": "St. George Regional Airport"
    },
    {
      "city": "Saint-Laurent-du-Maroni",
      "country": "French Guiana",
      "IATA": "LDX",
      "airport_name": "St-Laurent du Maroni Airport"
    },
    {
      "city": "Sainte Marie",
      "country": "Madagascar",
      "IATA": "SMS",
      "icao_code": "FMMS",
      "airport_name": "Sainte Marie Airport"
    },
    {
      "city": "Sainyabuli",
      "country": "Laos",
      "IATA": "ZBY",
      "icao_code": "VLSB",
      "airport_name": "Sayaboury Airport"
    },
    {
      "city": "Saipan",
      "country": "Northern Mariana Islands",
      "IATA": "SPN",
      "icao_code": "PGSN",
      "airport_name": "Saipan International Airport"
    },
    {
      "city": "Sakon Nakhon",
      "country": "Thailand",
      "IATA": "SNO",
      "icao_code": "VTUI",
      "airport_name": "Sakon Nakhon Airport"
    },
    {
      "city": "Salalah",
      "country": "Oman",
      "IATA": "SLL",
      "icao_code": "OOSA",
      "airport_name": "Salalah International Airport"
    },
    {
      "city": "Salamanca",
      "country": "Spain",
      "IATA": "SLM",
      "icao_code": "LESA",
      "airport_name": "Salamanca Airport"
    },
    {
      "city": "Sale",
      "country": "Australia",
      "IATA": "SXE",
      "icao_code": "YMES",
      "airport_name": "West Sale Airport"
    },
    {
      "city": "Salekhard",
      "country": "Russia",
      "IATA": "SLY",
      "icao_code": "USDD",
      "airport_name": "Salekhard Airport"
    },
    {
      "city": "Salem",
      "country": "United States",
      "IATA": "SLE",
      "icao_code": "KSLE",
      "airport_name": "McNary Field"
    },
    {
      "city": "Salem",
      "country": "India",
      "IATA": "SXV",
      "icao_code": "VOSM",
      "airport_name": "Salem Airport"
    },
    {
      "city": "Salerno",
      "country": "Italy",
      "IATA": "QSR",
      "icao_code": "LIRI",
      "airport_name": "Salerno Costa d'Amalfi Airport"
    },
    {
      "city": "Salina",
      "country": "United States",
      "IATA": "SLN",
      "icao_code": "KSLN",
      "airport_name": "Salina Regional Airport"
    },
    {
      "city": "Salinas",
      "country": "United States",
      "IATA": "SNS",
      "icao_code": "KSNS",
      "airport_name": "Salinas Municipal Airport"
    },
    {
      "city": "Salinas",
      "country": "Ecuador",
      "IATA": "SNC",
      "icao_code": "SESA",
      "airport_name": "General Ulpiano Paez Airport"
    },
    {
      "city": "Salisbury",
      "country": "United States",
      "IATA": "SBY",
      "icao_code": "KSBY",
      "airport_name": "Salisbury–Ocean City–Wicomico Regional Airport"
    },
    {
      "city": "Salluit",
      "country": "Canada",
      "IATA": "YZG",
      "icao_code": "CYZG",
      "airport_name": "Salluit Airport"
    },
    {
      "city": "Salmon",
      "country": "United States",
      "IATA": "SMN",
      "icao_code": "KSMN",
      "airport_name": "Lemhi County Airport"
    },
    {
      "city": "Salmon Arm",
      "country": "Canada",
      "IATA": "YSN",
      "icao_code": "CZAM",
      "airport_name": "St. Catharines/Niagara District Airport"
    },
    {
      "city": "Salt Cay",
      "country": "Turks and Caicos Islands",
      "IATA": "SLX",
      "icao_code": "MBSY",
      "airport_name": "Salt Cay Airport"
    },
    {
      "city": "Salt Lake City",
      "country": "United States",
      "IATA": "SLC",
      "icao_code": "KSLC",
      "airport_name": "Salt Lake City International Airport"
    },
    {
      "city": "Salta",
      "country": "Argentina",
      "IATA": "SLA",
      "icao_code": "SASA",
      "airport_name": "Martin Miguel De Guemes International Airport"
    },
    {
      "city": "Saltillo",
      "country": "Mexico",
      "IATA": "SLW",
      "icao_code": "MMIO",
      "airport_name": "Plan de Guadalupe International Airport"
    },
    {
      "city": "Salto",
      "country": "Uruguay",
      "IATA": "STY",
      "icao_code": "SUSO",
      "airport_name": "Nueva Hesperides International Airport"
    },
    {
      "city": "Saltsy",
      "country": "Moldova",
      "IATA": "BZY",
      "airport_name": "Bălți International Airport"
    },
    {
      "city": "Salvador",
      "country": "Brazil",
      "IATA": "SSA",
      "icao_code": "SBSV",
      "airport_name": "Deputado Luís Eduardo Magalhães International Airport"
    },
    {
      "city": "Salzburg",
      "country": "Austria",
      "IATA": "SZG",
      "icao_code": "LOWS",
      "airport_name": "Salzburg Airport"
    },
    {
      "city": "Sam Neua",
      "country": "Laos",
      "IATA": "NEU",
      "icao_code": "VLSN",
      "airport_name": "Nathong Airport "
    },
    {
      "city": "Samana",
      "country": "Dominican Republic",
      "IATA": "AZS",
      "icao_code": "MDZY",
      "airport_name": "Samaná El Catey International Airport"
    },
    {
      "city": "Samara",
      "country": "Russia",
      "IATA": "KUF",
      "icao_code": "UWWW",
      "airport_name": "Kurumoch International Airport"
    },
    {
      "city": "Samarinda",
      "country": "Indonesia",
      "IATA": "SRI",
      "icao_code": "WALS",
      "airport_name": "Temindung Airport"
    },
    {
      "city": "Samarkand",
      "country": "Uzbekistan",
      "IATA": "SKD",
      "icao_code": "UTSS",
      "airport_name": "Samarkand International  Airport"
    },
    {
      "city": "Sambava",
      "country": "Madagascar",
      "IATA": "SVB",
      "icao_code": "FMNS",
      "airport_name": "Sambava Airport"
    },
    {
      "city": "Samburu South",
      "country": "Kenya",
      "IATA": "UAS",
      "icao_code": "HKSB",
      "airport_name": "Samburu South Airport"
    },
    {
      "city": "Samedan",
      "country": "Switzerland",
      "IATA": "SMV",
      "icao_code": "LSZS",
      "airport_name": " Samedan Airport"
    },
    {
      "city": "Samos",
      "country": "Greece",
      "IATA": "SMI",
      "icao_code": "LGSM",
      "airport_name": "Samos International Airport"
    },
    {
      "city": "Sampit-Borneo Island",
      "country": "Indonesia",
      "IATA": "SMQ",
      "icao_code": "WAOS",
      "airport_name": "Sampit Airport"
    },
    {
      "city": "Samsun",
      "country": "Turkey",
      "IATA": "SZF",
      "icao_code": "LTFH",
      "airport_name": "Samsun-Çarşamba Airport"
    },
    {
      "city": "San Andres Island",
      "country": "Colombia",
      "IATA": "ADZ",
      "icao_code": "SKSP",
      "airport_name": "Gustavo Rojas Pinilla International Airport"
    },
    {
      "city": "San Andros",
      "country": "Bahamas",
      "IATA": "SAQ",
      "airport_name": "San Andros Airport"
    },
    {
      "city": "San Angelo",
      "country": "United States",
      "IATA": "SJT",
      "icao_code": "KSJT",
      "airport_name": "San Angelo Regional Airport "
    },
    {
      "city": "San Antonio",
      "country": "United States",
      "IATA": "RND",
      "airport_name": "Randolph Air Force Base"
    },
    {
      "city": "San Antonio",
      "country": "United States",
      "IATA": "SKF",
      "airport_name": "Kelly Field Annex"
    },
    {
      "city": "San Antonio",
      "country": "United States",
      "IATA": "SAT",
      "icao_code": "KSAT",
      "airport_name": "San Antonio International Airport"
    },
    {
      "city": "San Antonio",
      "country": "Venezuela",
      "IATA": "SVZ",
      "icao_code": "SVSA",
      "airport_name": "Juan Vicente Gómez International Airport"
    },
    {
      "city": "San Antonio Oeste",
      "country": "Argentina",
      "IATA": "OES",
      "icao_code": "SAVN",
      "airport_name": "Antoine De St Exupery Airport"
    },
    {
      "city": "San Antonio de Pale",
      "country": "Equatorial Guinea",
      "IATA": "NBN",
      "airport_name": "San Antonio de Pale Airport"
    },
    {
      "city": "San Bernardino",
      "country": "United States",
      "IATA": "SBD",
      "icao_code": "KSBD",
      "airport_name": "San Bernardino International Airport"
    },
    {
      "city": "San Borja",
      "country": "Bolivia",
      "IATA": "SRJ",
      "icao_code": "SLSB",
      "airport_name": "Capitán Germán Quiroga Guardia Airport"
    },
    {
      "city": "San Carlos",
      "country": "United States",
      "IATA": "SQL",
      "icao_code": "KSQL",
      "airport_name": "San Carlos Airport"
    },
    {
      "city": "San Carlos",
      "country": "Nicaragua",
      "IATA": "NCR",
      "icao_code": "MNSC",
      "airport_name": "San Carlos Airport "
    },
    {
      "city": "San Carlos De Bariloche",
      "country": "Argentina",
      "IATA": "BRC",
      "icao_code": "SAZS",
      "airport_name": " San Carlos De Bariloche Airport"
    },
    {
      "city": "San Cristobal",
      "country": "Venezuela",
      "IATA": "SCI",
      "icao_code": "SVPM",
      "airport_name": "Paramillo Airport"
    },
    {
      "city": "San Cristobal de las Casas",
      "country": "Mexico",
      "IATA": "SZT",
      "airport_name": "San Cristóbal de las Casas National Airport"
    },
    {
      "city": "San Cristóbal",
      "country": "Ecuador",
      "IATA": "SCY",
      "icao_code": "SPSF",
      "airport_name": "Airport Isla San Cristobal "
    },
    {
      "city": "San Diego",
      "country": "United States",
      "IATA": "SDM",
      "icao_code": "KSDM",
      "airport_name": "Brown Field Municipal Airport"
    },
    {
      "city": "San Diego",
      "country": "United States",
      "IATA": "SAN",
      "icao_code": "KSAN",
      "airport_name": "San Diego International Airport"
    },
    {
      "city": "San Diego",
      "country": "United States",
      "IATA": "NZY",
      "icao_code": "KNZY",
      "airport_name": "Naval Air Station North Island"
    },
    {
      "city": "San Felipe",
      "country": "Venezuela",
      "IATA": "SNF",
      "icao_code": "SVSP",
      "airport_name": "Sub Teniente Nestor Arias Airport"
    },
    {
      "city": "San Fernando",
      "country": "Philippines",
      "IATA": "SFE",
      "airport_name": "San Fernando Airport"
    },
    {
      "city": "San Fernando",
      "country": "Argentina",
      "IATA": "FDO",
      "airport_name": "San Fernando Airport"
    },
    {
      "city": "San Fernando De Apure",
      "country": "Venezuela",
      "IATA": "SFD",
      "icao_code": "SVSR",
      "airport_name": "Las Flecheras Airport"
    },
    {
      "city": "San Filipe",
      "country": "Mexico",
      "IATA": "SFH",
      "icao_code": "SVSP",
      "airport_name": "San Felipe International Airport"
    },
    {
      "city": "San Francisco",
      "country": "United States",
      "IATA": "SFO",
      "icao_code": "KSFO",
      "airport_name": "San Francisco International Airport"
    },
    {
      "city": "San Jose",
      "country": "United States",
      "IATA": "RHV",
      "icao_code": "KRHV",
      "airport_name": "Reid–Hillview Airport"
    },
    {
      "city": "San Jose",
      "country": "Costa Rica",
      "IATA": "SYQ",
      "icao_code": "MRPV",
      "airport_name": "Tobías Bolaños International Airport"
    },
    {
      "city": "San Jose",
      "country": "United States",
      "IATA": "SJC",
      "icao_code": "KSJC",
      "airport_name": "Norman Y. Mineta San José International Airport"
    },
    {
      "city": "San Jose",
      "country": "Philippines",
      "IATA": "EUQ",
      "airport_name": "Evelio Javier  Airport"
    },
    {
      "city": "San Jose",
      "country": "Philippines",
      "IATA": "SJI",
      "icao_code": "RPVS",
      "airport_name": "San Jose Airport (Mindoro)"
    },
    {
      "city": "San Jose",
      "country": "Costa Rica",
      "IATA": "SJO",
      "icao_code": "MROC",
      "airport_name": "Juan Santamaría International Airport"
    },
    {
      "city": "San Jose",
      "country": "Guatemala",
      "IATA": "GSJ",
      "icao_code": "MGSJ",
      "airport_name": "San José Airport"
    },
    {
      "city": "Cabo San Lucas, All Airports",
      "country": "Mexico",
      "IATA": "CSLALL",
      "airport_name": "Los Cabos International Airport"
    },
    {
      "city": "Cabo San Lucas, Mexico",
      "country": "Mexico",
      "IATA": "SJD",
      "icao_code": "MMSD",
      "airport_name": "Los Cabos International Airport"
    },
    {
      "city": "Cabo San Lucas, Mexico",
      "country": "Mexico",
      "IATA": "CSL",
      "airport_name": "O'Sullivan Army Heliport"
    },
    {
      "city": "San Jose Del Guaviare",
      "country": "Colombia",
      "IATA": "SJE",
      "icao_code": "SKSJ",
      "airport_name": "Jorge Enrique González Torres  Airport"
    },
    {
      "city": "San Juan",
      "country": "Puerto Rico",
      "IATA": "SJU",
      "icao_code": "TJSJ",
      "airport_name": "Luis Muñoz Marín International Airport"
    },
    {
      "city": "San Juan",
      "country": "Puerto Rico",
      "IATA": "SIG",
      "icao_code": "TJIG",
      "airport_name": "Fernando Luis Ribas Dominicci Airport"
    },
    {
      "city": "San Juan",
      "country": "Argentina",
      "IATA": "UAQ",
      "icao_code": "SANU",
      "airport_name": "Domingo Faustino Sarmiento Airport"
    },
    {
      "city": "San Julian",
      "country": "Argentina",
      "IATA": "ULA",
      "icao_code": "SAWJ",
      "airport_name": "Capitan D Daniel Vazquez Airport"
    },
    {
      "city": "San Luis",
      "country": "Argentina",
      "IATA": "LUQ",
      "icao_code": "SAOU",
      "airport_name": "Brigadier Mayor César Raúl Ojeda Airport"
    },
    {
      "city": "San Luis Obispo",
      "country": "United States",
      "IATA": "SBP",
      "icao_code": "KSBP",
      "airport_name": "San Luis Obispo County Regional Airport"
    },
    {
      "city": "San Luis Potosi",
      "country": "Mexico",
      "IATA": "SLP",
      "icao_code": "MMSP",
      "airport_name": "Ponciano Arriaga International Airport"
    },
    {
      "city": "San Martin Des Andes",
      "country": "Argentina",
      "IATA": "CPC",
      "icao_code": "SAZY",
      "airport_name": "Aviador Carlos Campos Airport"
    },
    {
      "city": "San Nicolás",
      "country": "Cuba",
      "IATA": "QSN",
      "airport_name": "San Nicolas De Bari Airport"
    },
    {
      "city": "San Pedro",
      "country": "Belize",
      "IATA": "SPR",
      "airport_name": "John Greif II Airport "
    },
    {
      "city": "San Pedro",
      "country": "Cote d'Ivoire",
      "IATA": "SPY",
      "icao_code": "DISP",
      "airport_name": "San Pedro Airport"
    },
    {
      "city": "San Pedro Sula",
      "country": "Honduras",
      "IATA": "SAP",
      "icao_code": "MHLM",
      "airport_name": "Ramón Villeda Morales International Airport"
    },
    {
      "city": "San Rafael",
      "country": "Argentina",
      "IATA": "AFA",
      "icao_code": "SAMR",
      "airport_name": "San Rafael Airport"
    },
    {
      "city": "San Salvador",
      "country": "El Salvador",
      "IATA": "SAL",
      "icao_code": "MSLP",
      "airport_name": "Monseñor Óscar Arnulfo Romero International Airport"
    },
    {
      "city": "San Sebastian",
      "country": "Spain",
      "IATA": "EAS",
      "icao_code": "LESO",
      "airport_name": "San Sebastián Airport "
    },
    {
      "city": "San Tome",
      "country": "Venezuela",
      "IATA": "SOM",
      "icao_code": "SVST",
      "airport_name": "San Tome Airport"
    },
    {
      "city": "San Vincente De Caguan",
      "country": "Colombia",
      "IATA": "SVI",
      "icao_code": "SKSV",
      "airport_name": "Eduardo Falla Solano Airport"
    },
    {
      "city": "San Vito De Jaba",
      "country": "Costa Rica",
      "IATA": "TOO",
      "icao_code": "MRSV",
      "airport_name": "San Vito De Java Airport"
    },
    {
      "city": "Sanaa",
      "country": "Yemen",
      "IATA": "SAH",
      "icao_code": "OYSN",
      "airport_name": "Sana'a International Airport"
    },
    {
      "city": "Sanana",
      "country": "Indonesia",
      "IATA": "SQN",
      "icao_code": "WAPN",
      "airport_name": "Emalamo Sanana Airport"
    },
    {
      "city": "Sanandaj",
      "country": "Iran",
      "IATA": "SDG",
      "icao_code": "OICS",
      "airport_name": "Sanandaj Airport"
    },
    {
      "city": "Sancti Spiritus",
      "country": "Cuba",
      "IATA": "USS",
      "airport_name": "Sancti Spiritus Airport"
    },
    {
      "city": "Sand Point",
      "country": "United States",
      "IATA": "SDP",
      "icao_code": "PASD",
      "airport_name": "Sand Point Airport"
    },
    {
      "city": "Sandakan",
      "country": "Malaysia",
      "IATA": "SDK",
      "icao_code": "WBKS",
      "airport_name": "Sandakan Airport"
    },
    {
      "city": "Sandane",
      "country": "Norway",
      "IATA": "SDN",
      "icao_code": "ENSD",
      "airport_name": "Sandane Airport, Anda"
    },
    {
      "city": "Sanday",
      "country": "United Kingdom",
      "IATA": "NDY",
      "icao_code": "EGES",
      "airport_name": "Sanday Airport"
    },
    {
      "city": "Sandnessjoen",
      "country": "Norway",
      "IATA": "SSJ",
      "icao_code": "ENST",
      "airport_name": "Sandnessjøen Airport, Stokka "
    },
    {
      "city": "Sandspit",
      "country": "Canada",
      "IATA": "YZP",
      "icao_code": "CYZP",
      "airport_name": "Sandspit Airport"
    },
    {
      "city": "Sandusky",
      "country": "United States",
      "IATA": "SKY",
      "airport_name": "Griffing Sandusky Airport"
    },
    {
      "city": "Sandy Lake",
      "country": "Canada",
      "IATA": "ZSJ",
      "icao_code": "CZSJ",
      "airport_name": "Sandy Lake Airport"
    },
    {
      "city": "Sanford",
      "country": "United States",
      "IATA": "SFB",
      "icao_code": "KSFB",
      "airport_name": "Orlando Sanford International Airport"
    },
    {
      "city": "Sanga Sanga",
      "country": "Philippines",
      "IATA": "TWT",
      "airport_name": "Sanga Sanga Airport"
    },
    {
      "city": "Sangafa",
      "country": "Vanuatu",
      "IATA": "EAE",
      "icao_code": "NVSE",
      "airport_name": "Siwo Airport"
    },
    {
      "city": "Sanggata",
      "country": "Indonesia",
      "IATA": "SGQ",
      "icao_code": "WRLA",
      "airport_name": "Sanggata Airport"
    },
    {
      "city": "Sangley Point",
      "country": "Philippines",
      "IATA": "SUG",
      "icao_code": "RPWS",
      "airport_name": "Surigao Airport"
    },
    {
      "city": "Sanikiluaq",
      "country": "Canada",
      "IATA": "YSK",
      "icao_code": "CYSK",
      "airport_name": "Sanikiluaq Airport"
    },
    {
      "city": "Sankt Peter-Ording",
      "country": "Germany",
      "IATA": "PSH",
      "airport_name": "Sankt Peter-Ording Airport"
    },
    {
      "city": "Sankt-Augustin",
      "country": "Germany",
      "IATA": "BNJ",
      "airport_name": "Bonn-Hangelar Airport"
    },
    {
      "city": "Sanliurfa",
      "country": "Turkey",
      "IATA": "GNY",
      "icao_code": "LTCH",
      "airport_name": "Şanlıurfa GAP Airport"
    },
    {
      "city": "Sanliurfa",
      "country": "Turkey",
      "IATA": "SFQ",
      "icao_code": "LTCH",
      "airport_name": "Şanlıurfa GAP Airport"
    },
    {
      "city": "Sanming",
      "country": "China",
      "IATA": "SQJ",
      "icao_code": "ZSSM",
      "airport_name": "Sanming Shengzhou Airport"
    },
    {
      "city": "Santa Ana",
      "country": "Solomon Islands",
      "IATA": "NNB",
      "icao_code": "AGGT",
      "airport_name": "Santa Ana Airport"
    },
    {
      "city": "Santa Ana",
      "country": "United States",
      "IATA": "NZJ",
      "airport_name": "Marine Corps Air Station El Toro"
    },
    {
      "city": "Los Angeles, Santa Ana",
      "country": "United States",
      "IATA": "SNA",
      "icao_code": "KSNA",
      "airport_name": "John Wayne International Airport "
    },
    {
      "city": "Santa Ana",
      "country": "Bolivia",
      "IATA": "SBL",
      "icao_code": "SLSA",
      "airport_name": "Santa Ana del Yacuma Airport"
    },
    {
      "city": "Santa Ana De Uairen",
      "country": "Venezuela",
      "IATA": "SNV",
      "airport_name": "Santa Elena de Uairen Airport"
    },
    {
      "city": "Santa Barbara",
      "country": "United States",
      "IATA": "SBA",
      "icao_code": "KSBA",
      "airport_name": "Santa Barbara Municipal Airport"
    },
    {
      "city": "Santa Barbara",
      "country": "Venezuela",
      "IATA": "STB",
      "icao_code": "SVSZ",
      "airport_name": "Miguel Urdaneta Fernández Airport"
    },
    {
      "city": "Santa Barbara",
      "country": "Venezuela",
      "IATA": "SBB",
      "icao_code": "SVSB",
      "airport_name": "Santa Bárbara de Barinas Airport"
    },
    {
      "city": "Santa Clara",
      "country": "Cuba",
      "IATA": "SNU",
      "icao_code": "MUSC",
      "airport_name": "Abel Santamaria Airport"
    },
    {
      "city": "Santa Cruz",
      "country": "Bolivia",
      "IATA": "SRZ",
      "icao_code": "SLET",
      "airport_name": "El Trompillo Airport"
    },
    {
      "city": "Santa Cruz",
      "country": "Bolivia",
      "IATA": "VVI",
      "icao_code": "SLVR",
      "airport_name": "Viru Viru International Airport"
    },
    {
      "city": "Santa Cruz",
      "country": "Argentina",
      "IATA": "RZA",
      "icao_code": "SAWU",
      "airport_name": "Santa Cruz Airport"
    },
    {
      "city": "Santa Cruz De La Palma",
      "country": "Spain",
      "IATA": "SPC",
      "icao_code": "GCLA",
      "airport_name": "La Palma Airport"
    },
    {
      "city": "Santa Cruz des Quiche",
      "country": "Guatemala",
      "IATA": "AQB",
      "icao_code": "MGQC",
      "airport_name": "Quiché Airport"
    },
    {
      "city": "Santa Cruz/Graciosa Bay/Luova",
      "country": "Solomon Islands",
      "IATA": "SCZ",
      "icao_code": "AGGL",
      "airport_name": "Santa Cruz/Graciosa Bay/Luova Airport"
    },
    {
      "city": "Santa Fe",
      "country": "United States",
      "IATA": "SAF",
      "icao_code": "KSAF",
      "airport_name": "Santa Fe Municipal Airport"
    },
    {
      "city": "Santa Fe",
      "country": "Argentina",
      "IATA": "SFN",
      "icao_code": "SAAV",
      "airport_name": "Sauce Viejo Airport"
    },
    {
      "city": "Santa Isabel do Rio Negro",
      "country": "Brazil",
      "IATA": "IRZ",
      "icao_code": "SWTP",
      "airport_name": "Tapuruquara Airport"
    },
    {
      "city": "Santa Maria",
      "country": "Brazil",
      "IATA": "RIA",
      "icao_code": "SBSM",
      "airport_name": "Santa Maria Airport"
    },
    {
      "city": "Santa Maria",
      "country": "United States",
      "IATA": "SMX",
      "icao_code": "KSMX",
      "airport_name": "Santa Maria Public Airport "
    },
    {
      "city": "Santa Maria (island)",
      "country": "Portugal",
      "IATA": "SMA",
      "icao_code": "LPAZ",
      "airport_name": "Santa Maria Public Airport"
    },
    {
      "city": "Santa Marta",
      "country": "Colombia",
      "IATA": "SMR",
      "icao_code": "SKSM",
      "airport_name": "Simón Bolívar International Airport"
    },
    {
      "city": "Santa Monica",
      "country": "United States",
      "IATA": "SMO",
      "icao_code": "KSMO",
      "airport_name": "Santa Monica Airport"
    },
    {
      "city": "Santa Rosa",
      "country": "United States",
      "IATA": "STS",
      "icao_code": "KSTS",
      "airport_name": "Charles M. Schulz Sonoma County Airport"
    },
    {
      "city": "Santa Rosa",
      "country": "Brazil",
      "IATA": "SRA",
      "icao_code": "SSZR",
      "airport_name": "Santa Rosa Airport"
    },
    {
      "city": "Santa Rosa",
      "country": "Ecuador",
      "IATA": "ETR",
      "icao_code": "LFFY",
      "airport_name": "Coronel Artilleria Victor Larrea Airport"
    },
    {
      "city": "Santa Rosa",
      "country": "Argentina",
      "IATA": "RSA",
      "icao_code": "SAZR",
      "airport_name": "Santa Rosa Airport"
    },
    {
      "city": "Santa Teresita",
      "country": "Argentina",
      "IATA": "SST",
      "airport_name": "Santa Teresita Airport"
    },
    {
      "city": "Santa Terezinha",
      "country": "Brazil",
      "IATA": "STZ",
      "icao_code": "SWST",
      "airport_name": "Santa Terezinha Airport"
    },
    {
      "city": "Santa Ynez",
      "country": "United States",
      "IATA": "SQA",
      "icao_code": "KIZA",
      "airport_name": "Santa Ynez Airport"
    },
    {
      "city": "Santana do Araguaia",
      "country": "Brazil",
      "IATA": "CMP",
      "icao_code": "SNKE",
      "airport_name": "Campo Alegre Airport"
    },
    {
      "city": "Santander",
      "country": "Spain",
      "IATA": "SDR",
      "icao_code": "LEXJ",
      "airport_name": "Santander Airport"
    },
    {
      "city": "Santarem",
      "country": "Brazil",
      "IATA": "STM",
      "icao_code": "SBSN",
      "airport_name": "Santarém-Maestro Wilson Fonseca Airport"
    },
    {
      "city": "Santiago",
      "country": "Chile",
      "IATA": "SCL",
      "icao_code": "SCEL",
      "airport_name": "Arturo Merino Benitez International Airport"
    },
    {
      "city": "Santiago",
      "country": "Panama",
      "IATA": "SYP",
      "icao_code": "MPSA",
      "airport_name": "Ruben Cantu Airport"
    },
    {
      "city": "Santiago",
      "country": "Dominican Republic",
      "IATA": "STI",
      "icao_code": "MDST",
      "airport_name": "Cibao International Airport"
    },
    {
      "city": "Santiago",
      "country": "Spain",
      "IATA": "SCQ",
      "icao_code": "LEST",
      "airport_name": "Santiago de Compostela Airport"
    },
    {
      "city": "Santiago De Cuba",
      "country": "Cuba",
      "IATA": "SCU",
      "icao_code": "MUCU",
      "airport_name": "Antonio Maceo Airport"
    },
    {
      "city": "Santiago Del Estero",
      "country": "Argentina",
      "IATA": "SDE",
      "icao_code": "SANE",
      "airport_name": "Vicecomodoro Angel D. La Paz Aragonés Airport"
    },
    {
      "city": "Santo",
      "country": "Vanuatu",
      "IATA": "SON",
      "icao_code": "NVSS",
      "airport_name": "Santo Pekoa International Airport"
    },
    {
      "city": "Santo Angelo",
      "country": "Brazil",
      "IATA": "GEL",
      "icao_code": "SBNM",
      "airport_name": "Santo Ângelo Airport"
    },
    {
      "city": "Santo Domingo",
      "country": "Dominican Republic",
      "IATA": "HEX",
      "airport_name": "Hermanos Mirabal International Airport"
    },
    {
      "city": "Santo Domingo",
      "country": "Venezuela",
      "IATA": "STD",
      "icao_code": "SVSO",
      "airport_name": "Mayor Buenaventura Vivas Airport"
    },
    {
      "city": "Santo Domingo",
      "country": "Dominican Republic",
      "IATA": "SDQ",
      "icao_code": "MDSD",
      "airport_name": "Las Américas International Airport"
    },
    {
      "city": "Santos",
      "country": "Brazil",
      "IATA": "SSZ",
      "icao_code": "SBST",
      "airport_name": "Santos Air Base"
    },
    {
      "city": "Sanya",
      "country": "China",
      "IATA": "SYX",
      "icao_code": "ZJSY",
      "airport_name": "Sanya Phoenix International Airport"
    },
    {
      "city": "Sao Carlos",
      "country": "Brazil",
      "IATA": "QSC",
      "airport_name": "São Carlos Airport"
    },
    {
      "city": "Sao Felix do Araguaia",
      "country": "Brazil",
      "IATA": "SXO",
      "icao_code": "SWFX",
      "airport_name": "São Félix do Araguaia Airport"
    },
    {
      "city": "Sao Felix do Xingu",
      "country": "Brazil",
      "IATA": "SXX",
      "icao_code": "SNFX",
      "airport_name": "Sao Felix do Xingu Airport"
    },
    {
      "city": "Sao Gabriel",
      "country": "Brazil",
      "IATA": "SJL",
      "icao_code": "SBUA",
      "airport_name": "Sao Gabriel da Cachoeira Airport"
    },
    {
      "city": "Sao Jorge Island",
      "country": "Portugal",
      "IATA": "SJZ",
      "icao_code": "LPSJ",
      "airport_name": "São Jorge Airport"
    },
    {
      "city": "Sao Jose Do Rio Preto",
      "country": "Brazil",
      "IATA": "SJP",
      "icao_code": "SBSR",
      "airport_name": "Prof. Eribelto Manoel Reino–São José do Rio Preto State Airport"
    },
    {
      "city": "Sao Jose Dos Campos",
      "country": "Brazil",
      "IATA": "SJK",
      "icao_code": "SBSJ",
      "airport_name": "São José dos Campos-Professor Urbano Ernesto Stumpf Airport"
    },
    {
      "city": "Sao Luis",
      "country": "Brazil",
      "IATA": "SLZ",
      "icao_code": "SBSL",
      "airport_name": "Marechal Cunha Machado International Airport"
    },
    {
      "city": "Sao Miguel do Oeste",
      "country": "Brazil",
      "IATA": "SQX",
      "icao_code": "SSOE",
      "airport_name": "São Miguel do Oeste Airport"
    },
    {
      "city": "Sao Nocolau Island",
      "country": "Cape Verde",
      "IATA": "SNE",
      "icao_code": "GVSN",
      "airport_name": "Preguiça Airport"
    },
    {
      "city": "Sao Paulo",
      "country": "Brazil",
      "IATA": "CGH",
      "icao_code": "SBSP",
      "airport_name": "São Paulo–Congonhas Airport"
    },
    {
      "city": "Sao Paulo",
      "country": "Brazil",
      "IATA": "GRU",
      "icao_code": "SBGR",
      "airport_name": "São Paulo/Guarulhos–Governador André Franco Montoro International Airport"
    },
    {
      "city": "Sao Paulo de Olivenca",
      "country": "Brazil",
      "IATA": "OLC",
      "airport_name": "São Paulo de Olivença Airport"
    },
    {
      "city": "Sao Tome",
      "country": "Sao Tome and Principe",
      "IATA": "TMS",
      "icao_code": "FPST",
      "airport_name": "São Tomé International Airport"
    },
    {
      "city": "Sao Vicente Island",
      "country": "Cape Verde",
      "IATA": "VXE",
      "icao_code": "GVSV",
      "airport_name": "Cesária Évora Airport"
    },
    {
      "city": "Sapporo",
      "country": "Japan",
      "IATA": "OKD",
      "icao_code": "RJCO",
      "airport_name": "Okadama Airport"
    },
    {
      "city": "Sapporo",
      "country": "Japan",
      "IATA": "CTS",
      "icao_code": "RJCC",
      "airport_name": "New Chitose Airport"
    },
    {
      "city": "Sarajevo",
      "country": "Bosnia and Herzegovina",
      "IATA": "SJJ",
      "icao_code": "LQSA",
      "airport_name": "Sarajevo International Airport"
    },
    {
      "city": "Sarakhs",
      "country": "Iran",
      "IATA": "CKT",
      "icao_code": "OIMC",
      "airport_name": "Sarakhs Airport"
    },
    {
      "city": "Saranac Lake",
      "country": "United States",
      "IATA": "SLK",
      "icao_code": "KSLK",
      "airport_name": "Adirondack Regional Airport"
    },
    {
      "city": "Saransk",
      "country": "Russia",
      "IATA": "SKX",
      "icao_code": "UWPS",
      "airport_name": "Taos Regional Airport"
    },
    {
      "city": "Sarasota",
      "country": "United States",
      "IATA": "SRQ",
      "icao_code": "KSRQ",
      "airport_name": "Sarasota-Bradenton International Airport"
    },
    {
      "city": "Saratov",
      "country": "Russia",
      "IATA": "RTW",
      "icao_code": "UWSS",
      "airport_name": "Saratov Tsentralny Airport"
    },
    {
      "city": "Saravane",
      "country": "Laos",
      "IATA": "VNA",
      "icao_code": "VLSV",
      "airport_name": "Saravane Airport"
    },
    {
      "city": "Saravena",
      "country": "Colombia",
      "IATA": "RVE",
      "icao_code": "SKSA",
      "airport_name": "Los Colonizadores Airport"
    },
    {
      "city": "Sargodha",
      "country": "Pakistan",
      "IATA": "SGI",
      "icao_code": "OPSR",
      "airport_name": "PAF Base Mushaf"
    },
    {
      "city": "Sarh",
      "country": "Chad",
      "IATA": "SRH",
      "icao_code": "FTTA",
      "airport_name": "Sarh Airport "
    },
    {
      "city": "Sarnia",
      "country": "Canada",
      "IATA": "YZR",
      "icao_code": "CYZR",
      "airport_name": "Sarnia Chris Hadfield Airport"
    },
    {
      "city": "Sarzana (SP)",
      "country": "Italy",
      "IATA": "QLP",
      "airport_name": "Sarzana Luni Airport"
    },
    {
      "city": "Sasereme",
      "country": "Papua New Guinea",
      "IATA": "TDS",
      "airport_name": "Sasereme Airport"
    },
    {
      "city": "Saskatoon",
      "country": "Canada",
      "IATA": "YXE",
      "icao_code": "CYXE",
      "airport_name": "Saskatoon John G. Diefenbaker International Airport"
    },
    {
      "city": "Saskylakh",
      "country": "Russia",
      "IATA": "SYS",
      "airport_name": "Saskylakh Airport"
    },
    {
      "city": "Satna",
      "country": "India",
      "IATA": "TNI",
      "icao_code": "VIST",
      "airport_name": "Tinian International Airport"
    },
    {
      "city": "Satu Mare",
      "country": "Romania",
      "IATA": "SUJ",
      "icao_code": "LRSM",
      "airport_name": "Satu Mare International Airport"
    },
    {
      "city": "Saudarkrokur",
      "country": "Iceland",
      "IATA": "SAK",
      "icao_code": "BIKR",
      "airport_name": "Sauðárkrókur Airport"
    },
    {
      "city": "Saul",
      "country": "French Guiana",
      "IATA": "XAU",
      "airport_name": "Saul Airport"
    },
    {
      "city": "Sault Sainte Marie",
      "country": "Canada",
      "IATA": "YAM",
      "icao_code": "CYAM",
      "airport_name": "Sault Ste. Marie Airport"
    },
    {
      "city": "Sault Ste Marie",
      "country": "United States",
      "IATA": "CIU",
      "icao_code": "KCIU",
      "airport_name": "Chippewa County International Airport"
    },
    {
      "city": "Saumlaki",
      "country": "Indonesia",
      "IATA": "SXK",
      "icao_code": "WAPI",
      "airport_name": "Saumlaki Airport"
    },
    {
      "city": "Saurimo",
      "country": "Angola",
      "IATA": "VHC",
      "icao_code": "FNSA",
      "airport_name": "Saurimo Airport"
    },
    {
      "city": "Savaii Island",
      "country": "Samoa",
      "IATA": "MXS",
      "icao_code": "NSMA",
      "airport_name": "Maota Airport"
    },
    {
      "city": "Savannah",
      "country": "United States",
      "IATA": "SAV",
      "icao_code": "KSAV",
      "airport_name": "Savannah/Hilton Head International Airport"
    },
    {
      "city": "Savannakhet",
      "country": "Laos",
      "IATA": "ZVK",
      "icao_code": "VLSK",
      "airport_name": "Savannakhet Airport"
    },
    {
      "city": "Savonlinna",
      "country": "Finland",
      "IATA": "SVL",
      "icao_code": "EFSA",
      "airport_name": "Savonlinna Airport"
    },
    {
      "city": "Savoonga",
      "country": "United States",
      "IATA": "SVA",
      "icao_code": "PASA",
      "airport_name": "Savoonga Airport"
    },
    {
      "city": "Savusavu",
      "country": "Fiji",
      "IATA": "SVU",
      "icao_code": "NFNS",
      "airport_name": "Savusavu Airport"
    },
    {
      "city": "Sayun Intl",
      "country": "Yemen",
      "IATA": "GXF",
      "icao_code": "OYSY",
      "airport_name": "Seiyun Airport"
    },
    {
      "city": "Scammon Bay",
      "country": "United States",
      "IATA": "SCM",
      "icao_code": "PACM",
      "airport_name": "Scammon Bay Airport"
    },
    {
      "city": "Scampton",
      "country": "United Kingdom",
      "IATA": "SQZ",
      "icao_code": "EGXP",
      "airport_name": "RAF Scampton"
    },
    {
      "city": "Scarborough",
      "country": "Trinidad and Tobago",
      "IATA": "TAB",
      "icao_code": "TTCP",
      "airport_name": "Arthur Napoleon Raymond Robinson International Airport"
    },
    {
      "city": "Scatsta",
      "country": "United Kingdom",
      "IATA": "SCS",
      "icao_code": "EGPM",
      "airport_name": "Scatsta Airport"
    },
    {
      "city": "Schefferville",
      "country": "Canada",
      "IATA": "YKL",
      "icao_code": "CYKL",
      "airport_name": "Schefferville Airport"
    },
    {
      "city": "Schleswig",
      "country": "Germany",
      "IATA": "WBG",
      "icao_code": "ETNS",
      "airport_name": "Schleswig Airport "
    },
    {
      "city": "Schoenhagen",
      "country": "Germany",
      "IATA": "QXH",
      "airport_name": "Schönhagen Airport"
    },
    {
      "city": "Scone",
      "country": "Australia",
      "IATA": "NSO",
      "icao_code": "YSCO",
      "airport_name": "Scone Airport"
    },
    {
      "city": "Scotia NY",
      "country": "United States",
      "IATA": "SCH",
      "icao_code": "KSCH",
      "airport_name": "Schenectady County Airport"
    },
    {
      "city": "Scottsbluff",
      "country": "United States",
      "IATA": "BFF",
      "icao_code": "KBFF",
      "airport_name": "Western Nebraska Regional Airport "
    },
    {
      "city": "Scottsdale",
      "country": "United States",
      "IATA": "SCF",
      "icao_code": "KSDL",
      "airport_name": "Scottsdale Airport"
    },
    {
      "city": "Scranton",
      "country": "United States",
      "IATA": "AVP",
      "icao_code": "KAVP",
      "airport_name": "Wilkes-Barre/Scranton International Airport"
    },
    {
      "city": "Seattle",
      "country": "United States",
      "IATA": "BFI",
      "icao_code": "KBFI",
      "airport_name": "King County International Airport"
    },
    {
      "city": "Seattle",
      "country": "United States",
      "IATA": "SEA",
      "icao_code": "KSEA",
      "airport_name": "Seattle-Tacoma International Airport"
    },
    {
      "city": "Sebha",
      "country": "Libya",
      "IATA": "SEB",
      "icao_code": "HLLS",
      "airport_name": "Sebha International Airport"
    },
    {
      "city": "Sebring",
      "country": "United States",
      "IATA": "SEF",
      "icao_code": "KSEF",
      "airport_name": "Sebring Regional Airport"
    },
    {
      "city": "Secunda",
      "country": "South Africa",
      "IATA": "ZEC",
      "icao_code": "FASC",
      "airport_name": "Secunda Airport"
    },
    {
      "city": "Sedona",
      "country": "United States",
      "IATA": "SDX",
      "icao_code": "KSEZ",
      "airport_name": "Sedona Airport"
    },
    {
      "city": "Sege",
      "country": "Solomon Islands",
      "IATA": "EGM",
      "icao_code": "AGGS",
      "airport_name": "Sege Airport"
    },
    {
      "city": "Sehwan Sharif",
      "country": "Pakistan",
      "IATA": "SYW",
      "icao_code": "OPSN",
      "airport_name": "Sehwan Sharif Airport"
    },
    {
      "city": "Seinäjoki / Ilmajoki",
      "country": "Finland",
      "IATA": "SJY",
      "icao_code": "EFIL",
      "airport_name": "Seinäjoki Airport"
    },
    {
      "city": "Selawik",
      "country": "United States",
      "IATA": "WLK",
      "icao_code": "PASK",
      "airport_name": "Selawik Airport"
    },
    {
      "city": "Seldovia",
      "country": "United States",
      "IATA": "SOV",
      "icao_code": "PASO",
      "airport_name": "Seldovia Airport"
    },
    {
      "city": "Selebi-phikwe",
      "country": "Botswana",
      "IATA": "PKW",
      "icao_code": "FBSP",
      "airport_name": "Selebi Phikwe Airport"
    },
    {
      "city": "Selibabi",
      "country": "Mauritania",
      "IATA": "SEY",
      "icao_code": "GQNS",
      "airport_name": "Sélibaby Airport"
    },
    {
      "city": "Selma",
      "country": "United States",
      "IATA": "SEM",
      "icao_code": "KSEM",
      "airport_name": "Craig Field"
    },
    {
      "city": "Semarang",
      "country": "Indonesia",
      "IATA": "SRG",
      "icao_code": "WIIS",
      "airport_name": "Achmad Yani International Airport"
    },
    {
      "city": "Semiplatinsk",
      "country": "Kazakhstan",
      "IATA": "PLX",
      "icao_code": "UASS",
      "airport_name": "Semey Airport"
    },
    {
      "city": "Sendai",
      "country": "Japan",
      "IATA": "SDJ",
      "icao_code": "RJSS",
      "airport_name": "Sendai Airport"
    },
    {
      "city": "Senggeh-Papua Island",
      "country": "Indonesia",
      "IATA": "SEH",
      "icao_code": "WAJS",
      "airport_name": "Senggeh Airport"
    },
    {
      "city": "Seo De Urgel",
      "country": "Spain",
      "IATA": "LEU",
      "icao_code": "LESU",
      "airport_name": "Andorra–La Seu d'Urgell Airport"
    },
    {
      "city": "Seogwipo",
      "country": "South Korea",
      "IATA": "JDG",
      "icao_code": "RKPD",
      "airport_name": "Seogwipo Airport"
    },
    {
      "city": "Seosan",
      "country": "South Korea",
      "IATA": "HMY",
      "airport_name": "Seosan Airport"
    },
    {
      "city": "Seoul",
      "country": "South Korea",
      "IATA": "ICN",
      "icao_code": "RKSI",
      "airport_name": "Incheon International Airport"
    },
    {
      "city": "Seoul",
      "country": "South Korea",
      "IATA": "GMP",
      "icao_code": "RKSS",
      "airport_name": "Gimpo International Airport "
    },
    {
      "city": "Seoul East",
      "country": "South Korea",
      "IATA": "SSN",
      "icao_code": "RKSM",
      "airport_name": "Seoul Airbase"
    },
    {
      "city": "Sept-iles",
      "country": "Canada",
      "IATA": "YZV",
      "icao_code": "CYZV",
      "airport_name": "Sept-Îles Airport"
    },
    {
      "city": "Seronera",
      "country": "Tanzania",
      "IATA": "SEU",
      "icao_code": "HTSN",
      "airport_name": "Seronera Airport"
    },
    {
      "city": "Setif",
      "country": "Algeria",
      "IATA": "QSF",
      "icao_code": "DAAS",
      "airport_name": "Ain Arnat Airport"
    },
    {
      "city": "Sevastopol",
      "country": "Ukraine",
      "IATA": "UKS",
      "airport_name": "Sevastopol International Airport"
    },
    {
      "city": "Sevilla",
      "country": "Spain",
      "IATA": "SVQ",
      "icao_code": "LEZL",
      "airport_name": "Seville Airport"
    },
    {
      "city": "Sevilla",
      "country": "Spain",
      "IATA": "OZP",
      "icao_code": "LEMO",
      "airport_name": "Morón Air Base"
    },
    {
      "city": "Sewanee",
      "country": "United States",
      "IATA": "UOS",
      "icao_code": "KUOS",
      "airport_name": "Franklin County Airport"
    },
    {
      "city": "Seward",
      "country": "United States",
      "IATA": "SWD",
      "airport_name": "Seward Airport"
    },
    {
      "city": "Sfax",
      "country": "Tunisia",
      "IATA": "SFA",
      "icao_code": "DTTX",
      "airport_name": "Sfax–Thyna International Airport"
    },
    {
      "city": "Shageluk",
      "country": "United States",
      "IATA": "SHX",
      "icao_code": "PAHX",
      "airport_name": "Shageluk Airport"
    },
    {
      "city": "Shahre Kord",
      "country": "Iran",
      "IATA": "CQD",
      "icao_code": "OIFS",
      "airport_name": "Shahrekord International Airport"
    },
    {
      "city": "Shakawe",
      "country": "Botswana",
      "IATA": "SWX",
      "icao_code": "FBSW",
      "airport_name": "Shakawe Airport"
    },
    {
      "city": "Shakhtyorsk",
      "country": "Russia",
      "IATA": "EKS",
      "icao_code": "KEKS",
      "airport_name": "Shakhtyorsk Airport"
    },
    {
      "city": "Shaktoolik",
      "country": "United States",
      "IATA": "SKK",
      "icao_code": "PFSH",
      "airport_name": "Shaktoolik Airport "
    },
    {
      "city": "Shamattawa",
      "country": "Canada",
      "IATA": "ZTM",
      "icao_code": "CZTM",
      "airport_name": "Shamattawa Airport"
    },
    {
      "city": "Shanghai",
      "country": "China",
      "IATA": "PVG",
      "icao_code": "ZSPD",
      "airport_name": "Shanghai Pudong International Airport"
    },
    {
      "city": "Shanghai",
      "country": "China",
      "IATA": "SHA",
      "icao_code": "ZSSS",
      "airport_name": "Shanghai Hongqiao International Airport"
    },
    {
      "city": "Shangrao",
      "country": "China",
      "IATA": "SQD",
      "icao_code": "ZSSR",
      "airport_name": "San Francisco Airport"
    },
    {
      "city": "Shangri-La",
      "country": "China",
      "IATA": "DIG",
      "icao_code": "ZPDQ",
      "airport_name": "Diqing Shangri-La Airport"
    },
    {
      "city": "Shank",
      "country": "Afghanistan",
      "IATA": "OAA",
      "airport_name": "Shank Air Base"
    },
    {
      "city": "Shannon",
      "country": "Ireland",
      "IATA": "SNN",
      "icao_code": "EINN",
      "airport_name": "Shannon Airport"
    },
    {
      "city": "Shanshan",
      "country": "China",
      "IATA": "SXJ",
      "icao_code": "ZWSS",
      "airport_name": "Shanshan Airport"
    },
    {
      "city": "Shantou",
      "country": "China",
      "IATA": "SWA",
      "icao_code": "ZGOW",
      "airport_name": "Jieyang Chaoshan International Airport "
    },
    {
      "city": "Shaoyang",
      "country": "China",
      "IATA": "WGN",
      "airport_name": "Shaoyang Wugang Airport"
    },
    {
      "city": "Sharjah",
      "country": "United Arab Emirates",
      "IATA": "SHJ",
      "icao_code": "OMSJ",
      "airport_name": "Sharjah International Airport"
    },
    {
      "city": "Shark Bay",
      "country": "Australia",
      "IATA": "MJK",
      "icao_code": "YSHK",
      "airport_name": "Shark Bay Airport"
    },
    {
      "city": "Sharm El Sheikh",
      "country": "Egypt",
      "IATA": "SSH",
      "icao_code": "HESH",
      "airport_name": "Sharm el-Sheikh International Airport"
    },
    {
      "city": "Sharona",
      "country": "Afghanistan",
      "IATA": "OAS",
      "airport_name": "Sharana Airstrip"
    },
    {
      "city": "Sharq Al-Owainat",
      "country": "Egypt",
      "IATA": "GSQ",
      "icao_code": "HEOW",
      "airport_name": "Sharq Al-Owainat Airport"
    },
    {
      "city": "Sharurah",
      "country": "Saudi Arabia",
      "IATA": "SHW",
      "icao_code": "OESH",
      "airport_name": "Sharurah Domestic Airport"
    },
    {
      "city": "Shchyolkovo",
      "country": "Russia",
      "IATA": "CKL",
      "icao_code": "UUMU",
      "airport_name": "Chkalovsky Airport"
    },
    {
      "city": "Sheboygan",
      "country": "United States",
      "IATA": "SBM",
      "icao_code": "KSBM",
      "airport_name": "Sheboygan County Memorial Airport"
    },
    {
      "city": "Shelton",
      "country": "United States",
      "IATA": "SHN",
      "icao_code": "KSHN",
      "airport_name": "Sanderson Field"
    },
    {
      "city": "Shemya",
      "country": "United States",
      "IATA": "SYA",
      "airport_name": "Eareckson Air Station"
    },
    {
      "city": "Shennongjia",
      "country": "China",
      "IATA": "HPG",
      "icao_code": "ZHSN",
      "airport_name": "Shennongjia Airport"
    },
    {
      "city": "Shenyang",
      "country": "China",
      "IATA": "SHE",
      "icao_code": "ZYTX",
      "airport_name": "Shenyang Taoxian International Airport"
    },
    {
      "city": "Shenzhen",
      "country": "China",
      "IATA": "SZX",
      "icao_code": "ZGSZ",
      "airport_name": "Shenzhen Bao’an International Airport"
    },
    {
      "city": "Shepparton",
      "country": "Australia",
      "IATA": "SHT",
      "icao_code": "YSHT",
      "airport_name": "Shepparton Airport"
    },
    {
      "city": "Sherbrooke",
      "country": "Canada",
      "IATA": "YSC",
      "icao_code": "CYSC",
      "airport_name": "Sherbrooke Airport"
    },
    {
      "city": "Sheridan",
      "country": "United States",
      "IATA": "SHR",
      "icao_code": "KSHR",
      "airport_name": "Sheridan County Airport"
    },
    {
      "city": "Shigatse",
      "country": "China",
      "IATA": "RKZ",
      "icao_code": "ZURK",
      "airport_name": "Shigatse Peace Airport"
    },
    {
      "city": "Shijiazhuang",
      "country": "China",
      "IATA": "SJW",
      "icao_code": "ZBSJ",
      "airport_name": "Shijiazhuang Zhengding International Airport"
    },
    {
      "city": "Shillong",
      "country": "India",
      "IATA": "SHL",
      "icao_code": "VEBI",
      "airport_name": "Shillong Airport"
    },
    {
      "city": "Shimla",
      "country": "India",
      "IATA": "SLV",
      "icao_code": "VISM",
      "airport_name": "Shimla Airport"
    },
    {
      "city": "Shimojishima",
      "country": "Japan",
      "IATA": "SHI",
      "icao_code": "RORS",
      "airport_name": "Shimojishima Airport"
    },
    {
      "city": "Shindand",
      "country": "Afghanistan",
      "IATA": "OAH",
      "airport_name": "Shindand Air Base"
    },
    {
      "city": "Shinyanga",
      "country": "Tanzania",
      "IATA": "SHY",
      "icao_code": "HTSY",
      "airport_name": "Shinyanga Airport"
    },
    {
      "city": "Shiquanhe",
      "country": "China",
      "IATA": "NGQ",
      "icao_code": "ZUAL",
      "airport_name": "Ngari Gunsa Airport"
    },
    {
      "city": "Shiraz",
      "country": "Iran",
      "IATA": "SYZ",
      "icao_code": "OISS",
      "airport_name": "Shiraz International Airport"
    },
    {
      "city": "Shirdi",
      "country": "India",
      "IATA": "SAG",
      "icao_code": "VASD",
      "airport_name": "Sagwon Airport"
    },
    {
      "city": "Shishmaref",
      "country": "United States",
      "IATA": "SHH",
      "icao_code": "PASH",
      "airport_name": "Shishmaref Airport"
    },
    {
      "city": "Shiyan",
      "country": "China",
      "IATA": "WDS",
      "icao_code": "ZHSY",
      "airport_name": "Shiyan Wudangshan Airport"
    },
    {
      "city": "Sholapur",
      "country": "India",
      "IATA": "SSE",
      "icao_code": "VASL",
      "airport_name": "Solapur Airport"
    },
    {
      "city": "Shonai",
      "country": "Japan",
      "IATA": "SYO",
      "icao_code": "RJSY",
      "airport_name": "Shonai Airport"
    },
    {
      "city": "Shoreham By Sea",
      "country": "United Kingdom",
      "IATA": "ESH",
      "icao_code": "EGKA",
      "airport_name": "Shoreham Airport"
    },
    {
      "city": "Show Low",
      "country": "United States",
      "IATA": "SOW",
      "icao_code": "KSOW",
      "airport_name": "Show Low Regional Airport"
    },
    {
      "city": "Shreveport",
      "country": "United States",
      "IATA": "DTN",
      "icao_code": "KDTN",
      "airport_name": "Shreveport Downtown Airport"
    },
    {
      "city": "Shreveport",
      "country": "United States",
      "IATA": "BAD",
      "airport_name": "Barksdale Air Force Base"
    },
    {
      "city": "Shreveport",
      "country": "United States",
      "IATA": "SHV",
      "icao_code": "KSHV",
      "airport_name": "Shreveport Regional Airport"
    },
    {
      "city": "Shungnak",
      "country": "United States",
      "IATA": "SHG",
      "icao_code": "PAGH",
      "airport_name": "Shungnak Airport"
    },
    {
      "city": "Shute Harbour",
      "country": "Australia",
      "IATA": "JHQ",
      "airport_name": "Shute Harbour Airport"
    },
    {
      "city": "Sialkot",
      "country": "Pakistan",
      "IATA": "SKT",
      "icao_code": "OPST",
      "airport_name": "Sialkot International Airport"
    },
    {
      "city": "Siargao",
      "country": "Philippines",
      "IATA": "IAO",
      "icao_code": "RPNS",
      "airport_name": "Siargao Airport"
    },
    {
      "city": "Siauliai",
      "country": "Lithuania",
      "IATA": "SQQ",
      "icao_code": "EYSA",
      "airport_name": "Šiauliai International Airport"
    },
    {
      "city": "Sibiu",
      "country": "Romania",
      "IATA": "SBZ",
      "icao_code": "LRSB",
      "airport_name": "Sibiu International Airport"
    },
    {
      "city": "Sibolga",
      "country": "Indonesia",
      "IATA": "FLZ",
      "icao_code": "WIMS\r\n",
      "airport_name": "Sibolga Pinang Sore Airport"
    },
    {
      "city": "Siborong-Borong",
      "country": "Indonesia",
      "IATA": "DTB",
      "icao_code": "WIMN",
      "airport_name": "Siborong-Borong Airport"
    },
    {
      "city": "Sibu",
      "country": "Malaysia",
      "IATA": "SBW",
      "icao_code": "WBGS",
      "airport_name": "Sibu Airport"
    },
    {
      "city": "Sidi Bel Abbes",
      "country": "Algeria",
      "IATA": "BFW",
      "icao_code": "DAOS",
      "airport_name": "Sidi Bel Abbes Airport"
    },
    {
      "city": "Sidney",
      "country": "United States",
      "IATA": "SNY",
      "icao_code": "KSNY",
      "airport_name": "Sidney Municipal Airport "
    },
    {
      "city": "Sidney",
      "country": "United States",
      "IATA": "SDY",
      "icao_code": "KSDY",
      "airport_name": "Sidney-Richland Municipal Airport"
    },
    {
      "city": "Siegerland",
      "country": "Germany",
      "IATA": "SGE",
      "airport_name": "Siegerland Airport"
    },
    {
      "city": "Siem-reap",
      "country": "Cambodia",
      "IATA": "REP",
      "icao_code": "VDSR",
      "airport_name": "Siem Reap International Airport"
    },
    {
      "city": "Siena",
      "country": "Italy",
      "IATA": "SAY",
      "icao_code": "LIQS",
      "airport_name": "Siena-Ampugnano Airport"
    },
    {
      "city": "Sierra Grande",
      "country": "Argentina",
      "IATA": "SGV",
      "icao_code": "SAVS",
      "airport_name": "Sierra Grande Airport"
    },
    {
      "city": "Sigiriya",
      "country": "Sri Lanka",
      "IATA": "GIU",
      "icao_code": "VCCS",
      "airport_name": "Sigiriya Airport"
    },
    {
      "city": "Siglufjordur",
      "country": "Iceland",
      "IATA": "SIJ",
      "icao_code": "BISI",
      "airport_name": "Siglufjörður Airport"
    },
    {
      "city": "Sigonella",
      "country": "Italy",
      "IATA": "NSY",
      "icao_code": "LICZ",
      "airport_name": "Naval Air Station Sigonella "
    },
    {
      "city": "Siguanea",
      "country": "Cuba",
      "IATA": "SZJ",
      "icao_code": "MUSN",
      "airport_name": "Siguanea Airport"
    },
    {
      "city": "Siguiri",
      "country": "Guinea",
      "IATA": "GII",
      "icao_code": "GUSI",
      "airport_name": "Siguiri Airport"
    },
    {
      "city": "Sihanoukville",
      "country": "Cambodia",
      "IATA": "KOS",
      "icao_code": "VDSV",
      "airport_name": "Sihanouk International Airport"
    },
    {
      "city": "Siirt",
      "country": "Turkey",
      "IATA": "SXZ",
      "icao_code": "LTCL",
      "airport_name": "Siirt Airport"
    },
    {
      "city": "Sikasso",
      "country": "Mali",
      "IATA": "KSS",
      "icao_code": "GASK",
      "airport_name": "Sikasso Airport"
    },
    {
      "city": "Sikeston",
      "country": "United States",
      "IATA": "SIK",
      "icao_code": "KSIK",
      "airport_name": "Sikeston Memorial Municipal Airport"
    },
    {
      "city": "Silchar",
      "country": "India",
      "IATA": "IXS",
      "icao_code": "VEKU",
      "airport_name": "Silchar Airport "
    },
    {
      "city": "Simao",
      "country": "China",
      "IATA": "SYM",
      "icao_code": "ZPSM",
      "airport_name": "Pu'er Simao Airport"
    },
    {
      "city": "Simara",
      "country": "Nepal",
      "IATA": "SIF",
      "icao_code": "VNSI",
      "airport_name": "Simara Airport"
    },
    {
      "city": "Simberi Island",
      "country": "Papua New Guinea",
      "IATA": "NIS",
      "airport_name": "Simberi Island Airport"
    },
    {
      "city": "Simferopol",
      "country": "Ukraine",
      "IATA": "SIP",
      "icao_code": "UKFF",
      "airport_name": "Simferopol International Airport"
    },
    {
      "city": "Simikot",
      "country": "Nepal",
      "IATA": "IMK",
      "icao_code": "VNST",
      "airport_name": "Simikot Airport"
    },
    {
      "city": "Sindal",
      "country": "Denmark",
      "IATA": "CNL",
      "icao_code": "EKSN",
      "airport_name": "Sindal Airport"
    },
    {
      "city": "Sindh",
      "country": "Pakistan",
      "IATA": "RZS",
      "airport_name": "Sawan Airport"
    },
    {
      "city": "Singapore",
      "country": "Singapore",
      "IATA": "SIN",
      "icao_code": "WSSS",
      "airport_name": "Singapore Changi Airport"
    },
    {
      "city": "Singapore",
      "country": "Singapore",
      "IATA": "XSP",
      "icao_code": "WSSL",
      "airport_name": "Seletar Airport"
    },
    {
      "city": "Singkep",
      "country": "Indonesia",
      "IATA": "SIQ",
      "icao_code": "WIKS",
      "airport_name": "Dabo Singkep Airport"
    },
    {
      "city": "Sinop",
      "country": "Brazil",
      "IATA": "OPS",
      "icao_code": "SWSI",
      "airport_name": "Sinop Airport"
    },
    {
      "city": "Sinop",
      "country": "Turkey",
      "IATA": "NOP",
      "airport_name": "Sinop Airport"
    },
    {
      "city": "Sintang-Borneo Island",
      "country": "Indonesia",
      "IATA": "SQG",
      "icao_code": "WIOS",
      "airport_name": "Sintang Airport"
    },
    {
      "city": "Sion",
      "country": "Switzerland",
      "IATA": "SIR",
      "icao_code": "LSGS",
      "airport_name": "Sion Airport"
    },
    {
      "city": "Sioux City",
      "country": "United States",
      "IATA": "SUX",
      "icao_code": "KSUX",
      "airport_name": "Sioux Gateway Airport "
    },
    {
      "city": "Sioux Falls",
      "country": "United States",
      "IATA": "FSD",
      "icao_code": "KFSD",
      "airport_name": "Sioux Falls Regional Airport "
    },
    {
      "city": "Sioux Lookout",
      "country": "Canada",
      "IATA": "YXL",
      "icao_code": "CYXL",
      "airport_name": "Sioux Lookout Airport"
    },
    {
      "city": "Sipora",
      "country": "Indonesia",
      "IATA": "RKO",
      "airport_name": "Rokot Airport"
    },
    {
      "city": "Sir Bani Yas Island",
      "country": "United Arab Emirates",
      "IATA": "XSB",
      "airport_name": "Sir Bani Yas Island Airport"
    },
    {
      "city": "Siri Island",
      "country": "Iran",
      "IATA": "SXI",
      "icao_code": "OIBS",
      "airport_name": "Sirri Island Airport"
    },
    {
      "city": "Sirjan",
      "country": "Iran",
      "IATA": "SYJ",
      "icao_code": "OIKY",
      "airport_name": "Sirjan Airport"
    },
    {
      "city": "Sirt",
      "country": "Libya",
      "IATA": "SRX",
      "icao_code": "HLGD",
      "airport_name": "Gardabya Air Base"
    },
    {
      "city": "Sishen",
      "country": "South Africa",
      "IATA": "SIS",
      "icao_code": "FASS",
      "airport_name": "Sishen Airport"
    },
    {
      "city": "Sisimiut",
      "country": "Greenland",
      "IATA": "JHS",
      "icao_code": "BGHB",
      "airport_name": "Sisimiut Airport"
    },
    {
      "city": "Sitia",
      "country": "Greece",
      "IATA": "JSH",
      "icao_code": "LGST",
      "airport_name": "Sitia Public Airport"
    },
    {
      "city": "Sitka",
      "country": "United States",
      "IATA": "SIT",
      "icao_code": "PASI",
      "airport_name": "Sitka Rocky Gutierrez Airport"
    },
    {
      "city": "Sittwe",
      "country": "Burma",
      "IATA": "AKY",
      "icao_code": "VYSW",
      "airport_name": "Sittwe Airport"
    },
    {
      "city": "Siuna",
      "country": "Nicaragua",
      "IATA": "SIU",
      "icao_code": "MNSI",
      "airport_name": "Siuna Airport"
    },
    {
      "city": "Sivas",
      "country": "Turkey",
      "IATA": "VAS",
      "icao_code": "LTAR",
      "airport_name": "Sivas Nuri Demirag Airport"
    },
    {
      "city": "Skagit",
      "country": "United States",
      "IATA": "MVW",
      "icao_code": "KBVS",
      "airport_name": "Skagit Regional Airport"
    },
    {
      "city": "Skagway",
      "country": "United States",
      "IATA": "SGY",
      "icao_code": "PAGY",
      "airport_name": "Skagway Airport"
    },
    {
      "city": "Skardu",
      "country": "Pakistan",
      "IATA": "KDU",
      "icao_code": "OPSD",
      "airport_name": "Skardu Airport"
    },
    {
      "city": "Skelleftea",
      "country": "Sweden",
      "IATA": "SFT",
      "icao_code": "ESNS",
      "airport_name": " Skellefteå Airport"
    },
    {
      "city": "Skiathos",
      "country": "Greece",
      "IATA": "JSI",
      "icao_code": "LGSK",
      "airport_name": "Skiathos Island National Airport"
    },
    {
      "city": "Skien",
      "country": "Norway",
      "IATA": "SKE",
      "icao_code": "ENSN",
      "airport_name": "Skien Airport, Geiteryggen"
    },
    {
      "city": "Skiros",
      "country": "Greece",
      "IATA": "SKU",
      "icao_code": "LGSY",
      "airport_name": "Skyros Island National Airport"
    },
    {
      "city": "Skive",
      "country": "Denmark",
      "IATA": "SQW",
      "icao_code": "EKSV",
      "airport_name": "Skive Airport"
    },
    {
      "city": "Skopje",
      "country": "Macedonia",
      "IATA": "SKP",
      "icao_code": "LWSK",
      "airport_name": "Skopje \"Alexander the Great\" Airport"
    },
    {
      "city": "Skovde",
      "country": "Sweden",
      "IATA": "KVB",
      "icao_code": "ESGR",
      "airport_name": "Skövde Airport"
    },
    {
      "city": "Skrydstrup",
      "country": "Denmark",
      "IATA": "SKS",
      "icao_code": "EKSP",
      "airport_name": "Skrydstrup Airport"
    },
    {
      "city": "Skukuza",
      "country": "South Africa",
      "IATA": "SZK",
      "airport_name": "Skukuza Airport"
    },
    {
      "city": "Slave Lake",
      "country": "Canada",
      "IATA": "YZH",
      "icao_code": "CYZH",
      "airport_name": "Slave Lake Airport"
    },
    {
      "city": "Sleetmute",
      "country": "United States",
      "IATA": "SLQ",
      "icao_code": "PASL",
      "airport_name": "Sleetmute Airport"
    },
    {
      "city": "Sliac",
      "country": "Slovakia",
      "IATA": "SLD",
      "icao_code": "LZSL",
      "airport_name": "Sliač Airport"
    },
    {
      "city": "Sligo",
      "country": "Ireland",
      "IATA": "SXL",
      "icao_code": "EISG",
      "airport_name": "Sligo Airport"
    },
    {
      "city": "Slupsk",
      "country": "Poland",
      "IATA": "OSP",
      "airport_name": "Slupsk Airport"
    },
    {
      "city": "Smara",
      "country": "Western Sahara",
      "IATA": "SMW",
      "icao_code": "GMMA",
      "airport_name": "Smara Airport"
    },
    {
      "city": "Smithers",
      "country": "Canada",
      "IATA": "YYD",
      "icao_code": "CYYD",
      "airport_name": "Smithers Regional Airport"
    },
    {
      "city": "Smithfield",
      "country": "United States",
      "IATA": "SFZ",
      "icao_code": "KSFZ",
      "airport_name": "North Central State Airport"
    },
    {
      "city": "Smiths Falls",
      "country": "Canada",
      "IATA": "YSH",
      "icao_code": "CYSH",
      "airport_name": "Smiths Falls-Montague Airport"
    },
    {
      "city": "Smolensk",
      "country": "Russia",
      "IATA": "LNX",
      "icao_code": "UUBS",
      "airport_name": "Smolensk Airport"
    },
    {
      "city": "Smyrna",
      "country": "United States",
      "IATA": "MQY",
      "icao_code": "KMQY",
      "airport_name": "Smyrna Airport"
    },
    {
      "city": "Soalala",
      "country": "Madagascar",
      "IATA": "DWB",
      "airport_name": "Soalala Airport"
    },
    {
      "city": "Sochi",
      "country": "Russia",
      "IATA": "AER",
      "icao_code": "URSS",
      "airport_name": "Sochi International Airport"
    },
    {
      "city": "Socotra",
      "country": "Yemen",
      "IATA": "SCT",
      "icao_code": "ODSQ",
      "airport_name": "Socotra Airport"
    },
    {
      "city": "Sodankyla",
      "country": "Finland",
      "IATA": "SOT",
      "icao_code": "EFSO",
      "airport_name": "Sodankylä Airfield"
    },
    {
      "city": "Soderhamn",
      "country": "Sweden",
      "IATA": "SOO",
      "icao_code": "ESCL",
      "airport_name": "Söderhamn Airport"
    },
    {
      "city": "Soenderborg",
      "country": "Denmark",
      "IATA": "SGD",
      "icao_code": "EKSB",
      "airport_name": "Sønderborg Airport"
    },
    {
      "city": "Soesterberg",
      "country": "Netherlands",
      "IATA": "UTC",
      "icao_code": "EHSB",
      "airport_name": "Soesterberg Airbase"
    },
    {
      "city": "Sofia",
      "country": "Bulgaria",
      "IATA": "SOF",
      "icao_code": "LBSF",
      "airport_name": "Sofia Airport"
    },
    {
      "city": "Sogndal",
      "country": "Norway",
      "IATA": "SOG",
      "icao_code": "ENSG",
      "airport_name": "Sogndal Airport, Haukåsen"
    },
    {
      "city": "Sohag",
      "country": "Egypt",
      "IATA": "HMB",
      "icao_code": "HEMK",
      "airport_name": "Sohag International Airport"
    },
    {
      "city": "Sohar",
      "country": "Oman",
      "IATA": "OHS",
      "icao_code": "OOSH",
      "airport_name": "Sohar Airport"
    },
    {
      "city": "Sokcho / Gangneung",
      "country": "South Korea",
      "IATA": "YNY",
      "icao_code": "RKNY",
      "airport_name": "Yangyang International Airport"
    },
    {
      "city": "Sokoto",
      "country": "Nigeria",
      "IATA": "SKO",
      "icao_code": "DNSO",
      "airport_name": "Sadiq Abubakar III International Airport"
    },
    {
      "city": "Sola",
      "country": "Vanuatu",
      "IATA": "SLH",
      "icao_code": "NVSC",
      "airport_name": "Cheboygan County Airport"
    },
    {
      "city": "Soldotna",
      "country": "United States",
      "IATA": "SXQ",
      "airport_name": "Soldotna Airport"
    },
    {
      "city": "Solenzara",
      "country": "France",
      "IATA": "SOZ",
      "airport_name": "Air Base 126 Solenzara"
    },
    {
      "city": "Solo City",
      "country": "Indonesia",
      "IATA": "SOC",
      "icao_code": "WRSQ",
      "airport_name": "Adisumarmo International Airport  "
    },
    {
      "city": "Solomon",
      "country": "Australia",
      "IATA": "SLJ",
      "airport_name": "Hagler Army Airfield Airport"
    },
    {
      "city": "Solovetsky Islands",
      "country": "Russia",
      "IATA": "CSH",
      "icao_code": "ULAS",
      "airport_name": "Solovki Airport"
    },
    {
      "city": "Somerset",
      "country": "United States",
      "IATA": "SME",
      "icao_code": "KSME",
      "airport_name": "Lake Cumberland Regional Airport "
    },
    {
      "city": "Son-La",
      "country": "Vietnam",
      "IATA": "SQH",
      "icao_code": "VVNS",
      "airport_name": "Na-San Airport"
    },
    {
      "city": "Sondrestrom",
      "country": "Greenland",
      "IATA": "SFJ",
      "icao_code": "BGSF",
      "airport_name": "Kangerlussuaq Airport"
    },
    {
      "city": "Songea",
      "country": "Tanzania",
      "IATA": "SGX",
      "icao_code": "HTSO",
      "airport_name": "Songea Airport"
    },
    {
      "city": "Songkhla",
      "country": "Thailand",
      "IATA": "SGZ",
      "icao_code": "VTSH",
      "airport_name": "Songkhla Airport"
    },
    {
      "city": "Songyuan",
      "country": "China",
      "IATA": "YSQ",
      "airport_name": "Songyuan Airport"
    },
    {
      "city": "Sorkjosen",
      "country": "Norway",
      "IATA": "SOJ",
      "icao_code": "ENSR",
      "airport_name": "Sørkjosen Airport"
    },
    {
      "city": "Soroako",
      "country": "Indonesia",
      "IATA": "SQR",
      "icao_code": "WAWS",
      "airport_name": "Soroako Airport"
    },
    {
      "city": "Sorocaba",
      "country": "Brazil",
      "IATA": "SOD",
      "icao_code": "SDCO",
      "airport_name": "Sorocaba Airport"
    },
    {
      "city": "Sorong",
      "country": "Indonesia",
      "IATA": "SOQ",
      "icao_code": "WASS",
      "airport_name": "Dominique Edward Osok Airport"
    },
    {
      "city": "Soroti",
      "country": "Uganda",
      "IATA": "SRT",
      "icao_code": "HUSO",
      "airport_name": "Soroti Airport"
    },
    {
      "city": "Sorriso",
      "country": "Brazil",
      "IATA": "SMT",
      "airport_name": "Sun Moon Lake Airport"
    },
    {
      "city": "Soure",
      "country": "Brazil",
      "IATA": "SFK",
      "airport_name": "Satipo Airport "
    },
    {
      "city": "South Bend",
      "country": "United States",
      "IATA": "SBN",
      "icao_code": "KSBN",
      "airport_name": "South Bend International Airport"
    },
    {
      "city": "South Caicos",
      "country": "Turks and Caicos Islands",
      "IATA": "XSC",
      "icao_code": "MBSC",
      "airport_name": "South Caicos Airport"
    },
    {
      "city": "South Indian Lake",
      "country": "Canada",
      "IATA": "XSI",
      "icao_code": "CZSN",
      "airport_name": "South Indian Lake Airport"
    },
    {
      "city": "South Lake Tahoe",
      "country": "United States",
      "IATA": "TVL",
      "icao_code": "KTVL",
      "airport_name": "Lake Tahoe Airport"
    },
    {
      "city": "South Naknek",
      "country": "United States",
      "IATA": "WSN",
      "icao_code": "PFWS",
      "airport_name": "South Naknek Airport"
    },
    {
      "city": "Southampton",
      "country": "United Kingdom",
      "IATA": "SOU",
      "icao_code": "EGHI",
      "airport_name": "Southampton Airport"
    },
    {
      "city": "Southdowns",
      "country": "Zambia",
      "IATA": "KIW",
      "icao_code": "FLSO",
      "airport_name": "Southdowns Airport"
    },
    {
      "city": "Southend",
      "country": "United Kingdom",
      "IATA": "SEN",
      "icao_code": "EGMC",
      "airport_name": "London Southend Airport"
    },
    {
      "city": "Sovetskaya Gavan",
      "country": "Russia",
      "IATA": "GVN",
      "airport_name": "Sovetskaya Gavan Airport"
    },
    {
      "city": "Sovetskiy",
      "country": "Russia",
      "IATA": "OVS",
      "icao_code": "USHS",
      "airport_name": "Sovetsky Airport"
    },
    {
      "city": "Sowa",
      "country": "Botswana",
      "IATA": "SXN",
      "icao_code": "FBSN",
      "airport_name": "Sua Pan Airport"
    },
    {
      "city": "Soyo",
      "country": "Angola",
      "IATA": "SZA",
      "icao_code": "FNSO",
      "airport_name": "Soyo Airport"
    },
    {
      "city": "Spangdahlem",
      "country": "Germany",
      "IATA": "SPM",
      "icao_code": "ETAD",
      "airport_name": "Spangdahlem Air Base"
    },
    {
      "city": "Spanish Town",
      "country": "British Virgin Islands",
      "IATA": "VIJ",
      "icao_code": "TUPW",
      "airport_name": "Virgin Gorda Airport"
    },
    {
      "city": "Sparrevohn",
      "country": "United States",
      "IATA": "SVW",
      "icao_code": "PASV",
      "airport_name": "Sparrevohn LRRS Airport"
    },
    {
      "city": "Spartangurg",
      "country": "United States",
      "IATA": "SPA",
      "icao_code": "KSPA",
      "airport_name": "Spartanburg Downtown Memorial Airport"
    },
    {
      "city": "Sparti",
      "country": "Greece",
      "IATA": "SPJ",
      "icao_code": "LGSP",
      "airport_name": "Sparti Airport"
    },
    {
      "city": "Spearfish-South Dakota",
      "country": "United States",
      "IATA": "SPF",
      "icao_code": "KSPF",
      "airport_name": "Black Hills Airport "
    },
    {
      "city": "Spence Bay",
      "country": "Canada",
      "IATA": "YYH",
      "icao_code": "CYYH",
      "airport_name": "Taloyoak Airport"
    },
    {
      "city": "Spencer",
      "country": "United States",
      "IATA": "SPW",
      "icao_code": "KSPW",
      "airport_name": "Spencer Municipal Airport"
    },
    {
      "city": "Split",
      "country": "Croatia",
      "IATA": "SPU",
      "icao_code": "LDSP",
      "airport_name": "Split Airport"
    },
    {
      "city": "Spokane",
      "country": "United States",
      "IATA": "SFF",
      "icao_code": "KSFF",
      "airport_name": "Felts Field"
    },
    {
      "city": "Spokane",
      "country": "United States",
      "IATA": "SKA",
      "airport_name": "Fairchild Air Force Base"
    },
    {
      "city": "Spokane",
      "country": "United States",
      "IATA": "GEG",
      "icao_code": "KGEG",
      "airport_name": "Spokane International Airport"
    },
    {
      "city": "Spring Point",
      "country": "Bahamas",
      "IATA": "AXP",
      "icao_code": "MYAP",
      "airport_name": "Spring Point Airport"
    },
    {
      "city": "Springbok",
      "country": "South Africa",
      "IATA": "SBU",
      "icao_code": "FASB",
      "airport_name": "Springbok Airport"
    },
    {
      "city": "Springfield",
      "country": "United States",
      "IATA": "SGH",
      "airport_name": "Springfield–Beckley Municipal Airport"
    },
    {
      "city": "Springfield",
      "country": "United States",
      "IATA": "SGF",
      "icao_code": "KSGF",
      "airport_name": "Springfield-Branson National Airport"
    },
    {
      "city": "Springfield",
      "country": "United States",
      "IATA": "SPI",
      "icao_code": "KSPI",
      "airport_name": "Abraham Lincoln Capital Airport"
    },
    {
      "city": "Squamish",
      "country": "Canada",
      "IATA": "YSE",
      "icao_code": "CWSK",
      "airport_name": "Squamish Airport"
    },
    {
      "city": "Srednekolymsk",
      "country": "Russia",
      "IATA": "SEK",
      "airport_name": "Srednekolymsk Airport"
    },
    {
      "city": "Srinagar",
      "country": "India",
      "IATA": "SXR",
      "icao_code": "VISR",
      "airport_name": "Srinagar International Airport"
    },
    {
      "city": "St George",
      "country": "Australia",
      "IATA": "SGO",
      "icao_code": "YSGE",
      "airport_name": "St George Airport"
    },
    {
      "city": "St Leonard",
      "country": "Canada",
      "IATA": "YSL",
      "icao_code": "CYSL",
      "airport_name": "Saint-Léonard Airport"
    },
    {
      "city": "St Mary's",
      "country": "United States",
      "IATA": "KSM",
      "icao_code": "PASM",
      "airport_name": "St. Mary's Airport"
    },
    {
      "city": "St Thomas",
      "country": "Canada",
      "IATA": "YQS",
      "icao_code": "CYQS",
      "airport_name": "St. Thomas Municipal Airport"
    },
    {
      "city": "St-Augustin",
      "country": "Canada",
      "IATA": "YIF",
      "icao_code": "CYIF",
      "airport_name": "Saint-Augustine Airport"
    },
    {
      "city": "St-Etienne",
      "country": "France",
      "IATA": "EBU",
      "icao_code": "LFMH",
      "airport_name": "Saint-Étienne–Bouthéon Airport"
    },
    {
      "city": "St-François",
      "country": "Guadeloupe",
      "IATA": "SFC",
      "airport_name": "St-François Airport"
    },
    {
      "city": "St. Anthony",
      "country": "Canada",
      "IATA": "YAY",
      "icao_code": "CYAY",
      "airport_name": "St. Anthony Airport"
    },
    {
      "city": "St. Augustine Airport",
      "country": "United States",
      "IATA": "UST",
      "icao_code": "KSGJ",
      "airport_name": "St. Augustine Airport"
    },
    {
      "city": "St. Catherine",
      "country": "Egypt",
      "IATA": "SKV",
      "airport_name": "St. Catherine International Airport"
    },
    {
      "city": "St. Croix Island",
      "country": "Virgin Islands",
      "IATA": "STX",
      "icao_code": "TISX",
      "airport_name": "Henry E. Rohlsen Airport"
    },
    {
      "city": "St. Jean",
      "country": "Canada",
      "IATA": "YJN",
      "icao_code": "CYJN",
      "airport_name": "St-Jean Airport"
    },
    {
      "city": "St. John",
      "country": "Canada",
      "IATA": "YSJ",
      "icao_code": "CYSJ",
      "airport_name": "Saint John Airport"
    },
    {
      "city": "St. John's",
      "country": "Canada",
      "IATA": "YYT",
      "icao_code": "CYYT",
      "airport_name": "St. John's International Airport"
    },
    {
      "city": "St. Lewis",
      "country": "Canada",
      "IATA": "YFX",
      "icao_code": "CCK4",
      "airport_name": "St. Lewis (Fox Harbour) Airport"
    },
    {
      "city": "St. Louis",
      "country": "United States",
      "IATA": "STL",
      "icao_code": "KSTL",
      "airport_name": "Lambert-Saint Louis International Airport"
    },
    {
      "city": "St. Louis",
      "country": "Senegal",
      "IATA": "XLS",
      "icao_code": "GOSS",
      "airport_name": "Saint-Louis Airport"
    },
    {
      "city": "St. Martin",
      "country": "Guadeloupe",
      "IATA": "SFG",
      "icao_code": "TFFG",
      "airport_name": "L'Espérance Airport"
    },
    {
      "city": "St. Michael",
      "country": "United States",
      "IATA": "SMK",
      "icao_code": "PAMK",
      "airport_name": "St. Michael Airport"
    },
    {
      "city": "St. Paul",
      "country": "United States",
      "IATA": "STP",
      "icao_code": "KSTP",
      "airport_name": "St. Paul Downtown Airport "
    },
    {
      "city": "St. Paul Island",
      "country": "United States",
      "IATA": "SNP",
      "icao_code": "PASN",
      "airport_name": "St. Paul Island Airport"
    },
    {
      "city": "St. Petersburg",
      "country": "United States",
      "IATA": "PIE",
      "icao_code": "KPIE",
      "airport_name": "St. Pete–Clearwater International Airport"
    },
    {
      "city": "St. Petersburg",
      "country": "United States",
      "IATA": "SPG",
      "icao_code": "KSPG",
      "airport_name": "Albert Whitted Airport"
    },
    {
      "city": "St. Petersburg",
      "country": "Russia",
      "IATA": "LED",
      "icao_code": "ULLI",
      "airport_name": "Pulkovo Airport"
    },
    {
      "city": "St. Theresa Point",
      "country": "Canada",
      "IATA": "YST",
      "icao_code": "CYST",
      "airport_name": "St. Theresa Point Airport"
    },
    {
      "city": "St. Thomas",
      "country": "Virgin Islands",
      "IATA": "STT",
      "icao_code": "TIST",
      "airport_name": "Cyril E. King Airport"
    },
    {
      "city": "St.-brieuc Armor",
      "country": "France",
      "IATA": "SBK",
      "icao_code": "LFRT",
      "airport_name": "Saint-Brieuc - Armor Airport"
    },
    {
      "city": "St.-denis",
      "country": "Reunion",
      "IATA": "RUN",
      "icao_code": "FMEE",
      "airport_name": "Roland Garros Airport"
    },
    {
      "city": "St.-georges Oyapock",
      "country": "French Guiana",
      "IATA": "OYP",
      "airport_name": "St.-Georges Oyapock Airport"
    },
    {
      "city": "St.-nazaire",
      "country": "France",
      "IATA": "SNR",
      "icao_code": "LFRZ",
      "airport_name": "Saint-Nazaire Montoir Airport"
    },
    {
      "city": "St.-pierre",
      "country": "Saint Pierre and Miquelon",
      "IATA": "FSP",
      "icao_code": "LFVP",
      "airport_name": "Saint Pierre Airport"
    },
    {
      "city": "St.-pierre",
      "country": "Reunion",
      "IATA": "ZSE",
      "icao_code": "KZSE",
      "airport_name": "Pierrefonds Airport"
    },
    {
      "city": "St.-yan",
      "country": "France",
      "IATA": "SYT",
      "icao_code": "LFLN",
      "airport_name": "Saint-Yan Airport"
    },
    {
      "city": "Staniel Cay",
      "country": "Bahamas",
      "IATA": "TYM",
      "airport_name": "Staniel Cay Airport"
    },
    {
      "city": "Stanley",
      "country": "Falkland Islands",
      "IATA": "PSY",
      "icao_code": "SFAL",
      "airport_name": "Port Stanley Airport"
    },
    {
      "city": "Stara Zagora",
      "country": "Bulgaria",
      "IATA": "SZR",
      "airport_name": "Stara Zagora Airport"
    },
    {
      "city": "State College Pennsylvania",
      "country": "United States",
      "IATA": "SCE",
      "icao_code": "KUNV",
      "airport_name": "University Park Airport"
    },
    {
      "city": "Statesboro",
      "country": "United States",
      "IATA": "TBR",
      "airport_name": "Statesboro-Bulloch County Airport"
    },
    {
      "city": "Statesville",
      "country": "United States",
      "IATA": "SVH",
      "icao_code": "KSVH",
      "airport_name": "Statesville Regional Airport"
    },
    {
      "city": "Stauning",
      "country": "Denmark",
      "IATA": "STA",
      "icao_code": "EKVJ",
      "airport_name": "Stauning Vestjylland Airport"
    },
    {
      "city": "Stavanger",
      "country": "Norway",
      "IATA": "SVG",
      "icao_code": "ENZV",
      "airport_name": "Stavanger Airport, Sola"
    },
    {
      "city": "Stavropol",
      "country": "Russia",
      "IATA": "STW",
      "icao_code": "URMT",
      "airport_name": "Stavropol Shpakovskoye Airport"
    },
    {
      "city": "Steamboat Springs",
      "country": "United States",
      "IATA": "SBS",
      "icao_code": "KSBS",
      "airport_name": "Steamboat Springs Airport"
    },
    {
      "city": "Stella Maris",
      "country": "Bahamas",
      "IATA": "SML",
      "icao_code": "MYLS",
      "airport_name": "Stella Maris Airport"
    },
    {
      "city": "Stephenville",
      "country": "United States",
      "IATA": "SEP",
      "airport_name": "Clark Field Municipal Airport"
    },
    {
      "city": "Stephenville",
      "country": "Canada",
      "IATA": "YJT",
      "icao_code": "CYJT",
      "airport_name": "Stephenville International Airport"
    },
    {
      "city": "Sterling",
      "country": "United States",
      "IATA": "STK",
      "airport_name": "Sterling Municipal Airport"
    },
    {
      "city": "Stevens Point",
      "country": "United States",
      "IATA": "STE",
      "icao_code": "KSTE",
      "airport_name": "Stevens Point Municipal Airport"
    },
    {
      "city": "Stewart",
      "country": "Canada",
      "IATA": "ZST",
      "icao_code": "CZST",
      "airport_name": "Stewart Aerodrome"
    },
    {
      "city": "Stewart Island",
      "country": "New Zealand",
      "IATA": "SZS",
      "icao_code": "NZRC",
      "airport_name": "Ryans Creek Aerodrome"
    },
    {
      "city": "Stillwater",
      "country": "United States",
      "IATA": "SWO",
      "icao_code": "KSWO",
      "airport_name": "Stillwater Regional Airpor"
    },
    {
      "city": "Stinson",
      "country": "United States",
      "IATA": "SSF",
      "icao_code": "KSSF",
      "airport_name": "Stinson Municipal Airport"
    },
    {
      "city": "Stirling Island",
      "country": "Solomon Islands",
      "IATA": "MNY",
      "icao_code": "AGGO",
      "airport_name": "Mono Airport"
    },
    {
      "city": "Stockholm",
      "country": "Sweden",
      "IATA": "BMA",
      "icao_code": "ESSB",
      "airport_name": "Stockholm-Bromma Airport"
    },
    {
      "city": "Stockholm",
      "country": "Sweden",
      "IATA": "ARN",
      "icao_code": "ESSA",
      "airport_name": "Stockholm-Arlanda Airport"
    },
    {
      "city": "Stockholm",
      "country": "Sweden",
      "IATA": "NYO",
      "icao_code": "ESKN",
      "airport_name": "Stockholm Skavsta Airport"
    },
    {
      "city": "Stockton",
      "country": "United States",
      "IATA": "SCK",
      "icao_code": "KSCK",
      "airport_name": "Stockton Metropolitan Airport"
    },
    {
      "city": "Stoelmans Eiland",
      "country": "Suriname",
      "IATA": "SMZ",
      "airport_name": "Stoelmans Eiland Airstrip"
    },
    {
      "city": "Stokmarknes",
      "country": "Norway",
      "IATA": "SKN",
      "icao_code": "ENSK",
      "airport_name": "Stokmarknes Airport, Skagen"
    },
    {
      "city": "Stony Rapids",
      "country": "Canada",
      "IATA": "YSF",
      "icao_code": "CYSF",
      "airport_name": "Stony Rapids Airport"
    },
    {
      "city": "Stord",
      "country": "Norway",
      "IATA": "SRP",
      "icao_code": "ENSO",
      "airport_name": "Stord Airport, Sørstokken"
    },
    {
      "city": "Stornoway",
      "country": "United Kingdom",
      "IATA": "SYY",
      "icao_code": "EGPO",
      "airport_name": "Stornoway Airport"
    },
    {
      "city": "Strahan",
      "country": "Australia",
      "IATA": "SRN",
      "icao_code": "YSRN",
      "airport_name": "Strahan Airport"
    },
    {
      "city": "Strasbourg",
      "country": "France",
      "IATA": "SXB",
      "icao_code": "LFST",
      "airport_name": "Strasbourg International Airport"
    },
    {
      "city": "Stratford",
      "country": "United States",
      "IATA": "BDR",
      "icao_code": "KBDR",
      "airport_name": "Sikorsky Memorial Airport"
    },
    {
      "city": "Straubing",
      "country": "Germany",
      "IATA": "RBM",
      "icao_code": "KRBM",
      "airport_name": "Straubing Wallmühle Airport"
    },
    {
      "city": "Strezhevoy",
      "country": "Russia",
      "IATA": "SWT",
      "icao_code": "UNSS",
      "airport_name": "Seward Municipal Airport"
    },
    {
      "city": "Stronsay",
      "country": "United Kingdom",
      "IATA": "SOY",
      "icao_code": "EGER",
      "airport_name": "Sioux Center Municipal Airport"
    },
    {
      "city": "Stuart",
      "country": "United States",
      "IATA": "SUA",
      "icao_code": "KSUA",
      "airport_name": "Witham Field "
    },
    {
      "city": "Stung Treng",
      "country": "Cambodia",
      "IATA": "TNX",
      "icao_code": "VDST",
      "airport_name": "Stung Treng Airport"
    },
    {
      "city": "Stuttgart",
      "country": "Germany",
      "IATA": "STR",
      "icao_code": "EDDS",
      "airport_name": "Stuttgart Airport"
    },
    {
      "city": "Suai",
      "country": "East Timor",
      "IATA": "UAI",
      "airport_name": "Suai Airport"
    },
    {
      "city": "Suavanao",
      "country": "Solomon Islands",
      "IATA": "VAO",
      "icao_code": "AGGV",
      "airport_name": "Suavanao Airport"
    },
    {
      "city": "Suceava",
      "country": "Romania",
      "IATA": "SCV",
      "icao_code": "LRSV",
      "airport_name": "Suceava Ștefan cel Mare International Airport"
    },
    {
      "city": "Sucre",
      "country": "Bolivia",
      "IATA": "SRE",
      "icao_code": "SLSU",
      "airport_name": "Juana Azurduy de Padilla International Airport "
    },
    {
      "city": "Sudbury",
      "country": "Canada",
      "IATA": "YSB",
      "icao_code": "CYSB",
      "airport_name": "Sudbury Airport"
    },
    {
      "city": "Sue Islet",
      "country": "Australia",
      "IATA": "SYU",
      "icao_code": "YWBS",
      "airport_name": "Warraber Island Airport"
    },
    {
      "city": "Suffield",
      "country": "Canada",
      "IATA": "YSD",
      "airport_name": "CFB Suffield Heliport"
    },
    {
      "city": "Sugapa-Papua Island",
      "country": "Indonesia",
      "IATA": "UGU",
      "airport_name": "Sugapa-Papua Island Airport"
    },
    {
      "city": "Sugar Land",
      "country": "United States",
      "IATA": "SGR",
      "icao_code": "KSGR",
      "airport_name": "Sugar Land Regional Airport"
    },
    {
      "city": "Sui",
      "country": "Pakistan",
      "IATA": "SUL",
      "icao_code": "OPSU",
      "airport_name": "Sui Airport"
    },
    {
      "city": "Sukhothai",
      "country": "Thailand",
      "IATA": "THS",
      "icao_code": "VTPO",
      "airport_name": "Sukhothai Airport"
    },
    {
      "city": "Sukhumi",
      "country": "Georgia",
      "IATA": "SUI",
      "icao_code": "UGSS",
      "airport_name": "Sukhumi Babushara Airport"
    },
    {
      "city": "Sukkur",
      "country": "Pakistan",
      "IATA": "SKZ",
      "icao_code": "OPSK",
      "airport_name": "Sukkur Airport"
    },
    {
      "city": "Sulawesi Tenggara",
      "country": "Indonesia",
      "IATA": "TQQ",
      "airport_name": "Sulawesi Tenggara Airport"
    },
    {
      "city": "Sulayel",
      "country": "Saudi Arabia",
      "IATA": "SLF",
      "icao_code": "OESL",
      "airport_name": "Sulayel Airport"
    },
    {
      "city": "Sulaymaniyah",
      "country": "Iraq",
      "IATA": "ISU",
      "icao_code": "ORSU",
      "airport_name": "Sulaimaniyah International Airport"
    },
    {
      "city": "Sumbawa Island",
      "country": "Indonesia",
      "IATA": "SWQ",
      "icao_code": "WADS",
      "airport_name": "Sultan Muhammad Kaharuddin III Airport"
    },
    {
      "city": "Sumbe",
      "country": "Angola",
      "IATA": "NDD",
      "icao_code": "FNSU",
      "airport_name": "Sumbe Airport"
    },
    {
      "city": "Sumburgh",
      "country": "United Kingdom",
      "IATA": "LSI",
      "icao_code": "EGPB",
      "airport_name": "Sumburgh Airport"
    },
    {
      "city": "Sumenep",
      "country": "Indonesia",
      "IATA": "SUP",
      "icao_code": "WART",
      "airport_name": "Trunojoyo Airport"
    },
    {
      "city": "Summer Beaver",
      "country": "Canada",
      "IATA": "SUR",
      "icao_code": "CJV7",
      "airport_name": "Summer Beaver Airport"
    },
    {
      "city": "Summerside",
      "country": "Canada",
      "IATA": "YSU",
      "icao_code": "CYSU",
      "airport_name": "Summerside Airport"
    },
    {
      "city": "Sumter",
      "country": "United States",
      "IATA": "SSC",
      "airport_name": "Shaw Air Force Base"
    },
    {
      "city": "Sunchales",
      "country": "Argentina",
      "IATA": "NCJ",
      "airport_name": "Sunchales Aeroclub Airport"
    },
    {
      "city": "Sundsvall",
      "country": "Sweden",
      "IATA": "SDL",
      "icao_code": "ESNN",
      "airport_name": "Sundsvall-Timrå Airport"
    },
    {
      "city": "Suntar",
      "country": "Russia",
      "IATA": "SUY",
      "airport_name": "Suntar Airport"
    },
    {
      "city": "Sunyani",
      "country": "Ghana",
      "IATA": "NYI",
      "icao_code": "DGSN",
      "airport_name": "Sunyani Airport"
    },
    {
      "city": "Surabaya",
      "country": "Indonesia",
      "IATA": "SUB",
      "icao_code": "WRSJ",
      "airport_name": "Juanda International Airport"
    },
    {
      "city": "Surallah",
      "country": "Philippines",
      "IATA": "AAV",
      "airport_name": "Allah Valley Airport"
    },
    {
      "city": "Surat",
      "country": "India",
      "IATA": "STV",
      "icao_code": "VASU",
      "airport_name": "Surat Airport"
    },
    {
      "city": "Surat Thani",
      "country": "Thailand",
      "IATA": "URT",
      "icao_code": "VTSB",
      "airport_name": "Surat Thani Airport"
    },
    {
      "city": "Surgut",
      "country": "Russia",
      "IATA": "SGC",
      "icao_code": "USRR",
      "airport_name": "Surgut International Airport"
    },
    {
      "city": "Surin",
      "country": "Thailand",
      "IATA": "PXR",
      "airport_name": "Surin Airport"
    },
    {
      "city": "Surkhet",
      "country": "Nepal",
      "IATA": "SKH",
      "icao_code": "VNSK",
      "airport_name": "Surkhet Airport"
    },
    {
      "city": "Suwon",
      "country": "South Korea",
      "IATA": "SWU",
      "icao_code": "RKSW",
      "airport_name": "Suwon Air Base"
    },
    {
      "city": "Suzhou",
      "country": "China",
      "IATA": "SZV",
      "icao_code": "ZSSZ",
      "airport_name": "Suzhou Guangfu Airport"
    },
    {
      "city": "Sveg",
      "country": "Sweden",
      "IATA": "EVG",
      "icao_code": "ESND",
      "airport_name": "Sveg Airport"
    },
    {
      "city": "Svolvær",
      "country": "Norway",
      "IATA": "SVJ",
      "icao_code": "ENSH",
      "airport_name": "Svolvær Airport, Helle"
    },
    {
      "city": "Swakopmund",
      "country": "Namibia",
      "IATA": "SWP",
      "airport_name": "Swakopmund Airport"
    },
    {
      "city": "Swan Hill",
      "country": "Australia",
      "IATA": "SWH",
      "icao_code": "YSWH",
      "airport_name": "Swan Hill Airport"
    },
    {
      "city": "Swan River",
      "country": "Canada",
      "IATA": "ZJN",
      "icao_code": "CZJN",
      "airport_name": "Swan River Airport"
    },
    {
      "city": "Swansea",
      "country": "United Kingdom",
      "IATA": "SWS",
      "icao_code": "EGFH",
      "airport_name": "Swansea Airport"
    },
    {
      "city": "Swift Current",
      "country": "Canada",
      "IATA": "YYN",
      "icao_code": "CYYN",
      "airport_name": "Swift Current Airport"
    },
    {
      "city": "Syangboche",
      "country": "Nepal",
      "IATA": "SYH",
      "airport_name": "Syangboche Airport"
    },
    {
      "city": "Sydney",
      "country": "Australia",
      "IATA": "SYD",
      "icao_code": "YSSY",
      "airport_name": "Sydney (Kingsford Smith) Airport"
    },
    {
      "city": "Sydney",
      "country": "Australia",
      "IATA": "BWU",
      "icao_code": "YSBK",
      "airport_name": "Bankstown Airport"
    },
    {
      "city": "Sydney",
      "country": "Canada",
      "IATA": "YQY",
      "icao_code": "CYQY",
      "airport_name": "Sydney J.A. Douglas McCurdy Airport"
    },
    {
      "city": "Syktyvkar",
      "country": "Russia",
      "IATA": "SCW",
      "icao_code": "UUYY",
      "airport_name": "Syktyvkar Airport"
    },
    {
      "city": "Sylhet Osmani",
      "country": "Bangladesh",
      "IATA": "ZYL",
      "icao_code": "VGSY",
      "airport_name": "Osmani International Airport"
    },
    {
      "city": "Syracuse",
      "country": "United States",
      "IATA": "SYR",
      "icao_code": "KSYR",
      "airport_name": "Syracuse Hancock International Airport"
    },
    {
      "city": "Syros Island",
      "country": "Greece",
      "IATA": "JSY",
      "icao_code": "LGSO",
      "airport_name": "Syros Island Airport"
    },
    {
      "city": "Szczecin",
      "country": "Poland",
      "IATA": "SZZ",
      "icao_code": "EPSC",
      "airport_name": "Solidarity Szczecin–Goleniów Airport"
    },
    {
      "city": "Szczytno-Szymany",
      "country": "Poland",
      "IATA": "SZY",
      "icao_code": "EPSY",
      "airport_name": "Szczytno-Szymany Airport"
    },
    {
      "city": "Sármellék",
      "country": "Hungary",
      "IATA": "SOB",
      "icao_code": "LHSM",
      "airport_name": "Hévíz–Balaton Airport"
    },
    {
      "city": "Taba",
      "country": "Egypt",
      "IATA": "TCP",
      "icao_code": "HETB",
      "airport_name": "Taba International Airport"
    },
    {
      "city": "Tabarka",
      "country": "Tunisia",
      "IATA": "TBJ",
      "icao_code": "DTKA",
      "airport_name": "Tabarka–Aïn Draham International Airport"
    },
    {
      "city": "Tabas",
      "country": "Iran",
      "IATA": "TCX",
      "icao_code": "OIMT",
      "airport_name": "Tabas Airport"
    },
    {
      "city": "Tabatinga",
      "country": "Brazil",
      "IATA": "TBT",
      "icao_code": "SBTT",
      "airport_name": "Tabatinga International Airport"
    },
    {
      "city": "Tabiteuea",
      "country": "Kiribati",
      "IATA": "TSU",
      "airport_name": "Tabiteuea South Airport"
    },
    {
      "city": "Tabiteuea North",
      "country": "Kiribati",
      "IATA": "TBF",
      "airport_name": "Tabiteuea North Airport"
    },
    {
      "city": "Tabora",
      "country": "Tanzania",
      "IATA": "TBO",
      "icao_code": "HTTB",
      "airport_name": "Tabora Airport"
    },
    {
      "city": "Tabriz",
      "country": "Iran",
      "IATA": "TBZ",
      "icao_code": "OITT",
      "airport_name": "Tabriz International Airport"
    },
    {
      "city": "Tabubil",
      "country": "Papua New Guinea",
      "IATA": "TBG",
      "icao_code": "AYTB",
      "airport_name": "Tabubil Airport"
    },
    {
      "city": "Tabuk",
      "country": "Saudi Arabia",
      "IATA": "TUU",
      "icao_code": "OETB",
      "airport_name": "Prince Sultan bin Abdul Aziz Airport"
    },
    {
      "city": "Tacheng",
      "country": "China",
      "IATA": "TCG",
      "icao_code": "SPCH",
      "airport_name": "Tacheng Airport"
    },
    {
      "city": "Tachilek",
      "country": "Burma",
      "IATA": "THL",
      "icao_code": "VYTL",
      "airport_name": "Tachilek Airport"
    },
    {
      "city": "Tacloban",
      "country": "Philippines",
      "IATA": "TAC",
      "icao_code": "RPVA",
      "airport_name": "Daniel Z. Romualdez Airport "
    },
    {
      "city": "Tacna",
      "country": "Peru",
      "IATA": "TCQ",
      "icao_code": "SPTN",
      "airport_name": "Coronel FAP Carlos Ciriani Santa Rosa International Airport"
    },
    {
      "city": "Tacoma",
      "country": "United States",
      "IATA": "TIW",
      "icao_code": "KTIW",
      "airport_name": "Tacoma Narrows Airport"
    },
    {
      "city": "Tacoma",
      "country": "United States",
      "IATA": "TCM",
      "icao_code": "KTCM",
      "airport_name": "McChord AFB"
    },
    {
      "city": "Tadjoura",
      "country": "Djibouti",
      "IATA": "TDJ",
      "icao_code": "HDTJ",
      "airport_name": "Tadjoura Airport"
    },
    {
      "city": "Tadoule Lake",
      "country": "Canada",
      "IATA": "XTL",
      "icao_code": "CYBQ",
      "airport_name": "Tadoule Lake Airport"
    },
    {
      "city": "Taegu",
      "country": "South Korea",
      "IATA": "TAE",
      "icao_code": "RKTN",
      "airport_name": "Daegu International Airport"
    },
    {
      "city": "Taganrog",
      "country": "Russia",
      "IATA": "TGK",
      "icao_code": "URRT",
      "airport_name": "Taganrog Airport"
    },
    {
      "city": "Tagbilaran",
      "country": "Philippines",
      "IATA": "TAG",
      "icao_code": "RPVT",
      "airport_name": "Tagbilaran Airport"
    },
    {
      "city": "Tahoua",
      "country": "Niger",
      "IATA": "THZ",
      "icao_code": "DRRT",
      "airport_name": "Tahoua Airport"
    },
    {
      "city": "Taichung",
      "country": "Taiwan",
      "IATA": "TXG",
      "airport_name": "Taichung Ching Chuang Kang Airport"
    },
    {
      "city": "Taichung",
      "country": "Taiwan",
      "IATA": "RMQ",
      "icao_code": "RCMQ",
      "airport_name": "Taichung International Airport"
    },
    {
      "city": "Taif",
      "country": "Saudi Arabia",
      "IATA": "TIF",
      "icao_code": "OETF",
      "airport_name": "Ta’if Regional Airport"
    },
    {
      "city": "Tainan",
      "country": "Taiwan",
      "IATA": "TNN",
      "icao_code": "RCNN",
      "airport_name": "Tainan Airport "
    },
    {
      "city": "Taipei",
      "country": "Taiwan",
      "IATA": "TPE",
      "icao_code": "RCTP",
      "airport_name": "Taiwan Taoyuan International Airport"
    },
    {
      "city": "Taipei",
      "country": "Taiwan",
      "IATA": "TSA",
      "icao_code": "RCSS",
      "airport_name": "Taipei Songshan Airport"
    },
    {
      "city": "Taiyuan",
      "country": "China",
      "IATA": "TYN",
      "icao_code": "ZBYN",
      "airport_name": "Taiyuan Wusu International Airport"
    },
    {
      "city": "Taiz",
      "country": "Yemen",
      "IATA": "TAI",
      "icao_code": "OYTZ",
      "airport_name": "Taiz International Airport"
    },
    {
      "city": "Tak",
      "country": "Thailand",
      "IATA": "TKT",
      "icao_code": "VTPT",
      "airport_name": "Tak Airport"
    },
    {
      "city": "Tak",
      "country": "Thailand",
      "IATA": "MAQ",
      "icao_code": "VTPM",
      "airport_name": "Mae Sot Airport"
    },
    {
      "city": "Takaka",
      "country": "New Zealand",
      "IATA": "KTF",
      "icao_code": "NZTK",
      "airport_name": "Takaka Airport"
    },
    {
      "city": "Takamatsu",
      "country": "Japan",
      "IATA": "TAK",
      "icao_code": "RJOT",
      "airport_name": "Takamatsu Airport"
    },
    {
      "city": "Takapoto",
      "country": "French Polynesia",
      "IATA": "TKP",
      "icao_code": "NTGT",
      "airport_name": "Takapoto Airport"
    },
    {
      "city": "Takaroa",
      "country": "French Polynesia",
      "IATA": "TKX",
      "icao_code": "NTKR",
      "airport_name": "Takaroa Airport"
    },
    {
      "city": "Taketomi",
      "country": "Japan",
      "IATA": "HTR",
      "icao_code": "RORH",
      "airport_name": "Hateruma Airport"
    },
    {
      "city": "Takoradi",
      "country": "Ghana",
      "IATA": "TKD",
      "icao_code": "DGTK",
      "airport_name": "Takoradi Airport"
    },
    {
      "city": "Talakan",
      "country": "Russia",
      "IATA": "TLK",
      "icao_code": "UECT",
      "airport_name": "Talakan Airport"
    },
    {
      "city": "Talang Gudang-Sumatra Island",
      "country": "Indonesia",
      "IATA": "PDO",
      "icao_code": "WIPQ",
      "airport_name": "Pendopo Airport"
    },
    {
      "city": "Talara",
      "country": "Peru",
      "IATA": "TYL",
      "icao_code": "SPYL",
      "airport_name": "Capitán FAP Víctor Montes Arias Airport"
    },
    {
      "city": "Talca",
      "country": "Chile",
      "IATA": "TLX",
      "icao_code": "SCTL",
      "airport_name": "Panguilemo Airport"
    },
    {
      "city": "Talhar",
      "country": "Pakistan",
      "IATA": "BDN",
      "icao_code": "OPTH",
      "airport_name": "Talhar Airport"
    },
    {
      "city": "Talkeetna",
      "country": "United States",
      "IATA": "TKA",
      "icao_code": "PATK",
      "airport_name": "Talkeetna Airport"
    },
    {
      "city": "Talladega",
      "country": "United States",
      "IATA": "ASN",
      "icao_code": "KASN",
      "airport_name": "Talladega Municipal Airport"
    },
    {
      "city": "Tallahassee",
      "country": "United States",
      "IATA": "TLH",
      "icao_code": "KTLH",
      "airport_name": "Tallahassee International Airport"
    },
    {
      "city": "Tallinn-ulemiste International",
      "country": "Estonia",
      "IATA": "TLL",
      "icao_code": "EETN",
      "airport_name": "Lennart Meri Tallinn Airport"
    },
    {
      "city": "Taltal",
      "country": "Chile",
      "IATA": "TTC",
      "airport_name": "Las Breas Airport"
    },
    {
      "city": "Tamale",
      "country": "Ghana",
      "IATA": "TML",
      "icao_code": "DGLE",
      "airport_name": "Tamale Airport"
    },
    {
      "city": "Tamana",
      "country": "Kiribati",
      "IATA": "TMN",
      "airport_name": "Tamana Island Airport"
    },
    {
      "city": "Tamanrasset",
      "country": "Algeria",
      "IATA": "TMR",
      "icao_code": "DAAT",
      "airport_name": "Aguenar – Hadj Bey Akhamok Airport"
    },
    {
      "city": "Tambacounda",
      "country": "Senegal",
      "IATA": "TUD",
      "icao_code": "GOTT",
      "airport_name": "Tambacounda Airport"
    },
    {
      "city": "Tambohorano",
      "country": "Madagascar",
      "IATA": "WTA",
      "airport_name": "Tambohorano Airport"
    },
    {
      "city": "Tambow",
      "country": "Russia",
      "IATA": "TBW",
      "icao_code": "UUOT",
      "airport_name": "Tambov Donskoye Airport"
    },
    {
      "city": "Tamchy",
      "country": "Kyrgyzstan",
      "IATA": "IKU",
      "icao_code": "UAFL",
      "airport_name": "Tamchy Airport"
    },
    {
      "city": "Tame",
      "country": "Colombia",
      "IATA": "TME",
      "icao_code": "SKTM",
      "airport_name": "Gabriel Vargas Santos Airport"
    },
    {
      "city": "Tampa",
      "country": "United States",
      "IATA": "TPF",
      "icao_code": "KTPF",
      "airport_name": "Peter O. Knight Airport"
    },
    {
      "city": "Tampa",
      "country": "United States",
      "IATA": "MCF",
      "icao_code": "KMCF",
      "airport_name": "MacDill Air Force Base"
    },
    {
      "city": "Tampa",
      "country": "United States",
      "IATA": "TPA",
      "icao_code": "KTPA",
      "airport_name": "Tampa International Airport"
    },
    {
      "city": "Tampere",
      "country": "Finland",
      "IATA": "TMP",
      "icao_code": "EFTP",
      "airport_name": "Tampere-Pirkkala Airport"
    },
    {
      "city": "Tampico",
      "country": "Mexico",
      "IATA": "TAM",
      "icao_code": "MMTM",
      "airport_name": "General Francisco Javier Mina International Airport"
    },
    {
      "city": "Tamuin",
      "country": "Mexico",
      "IATA": "TSL",
      "icao_code": "MMTN",
      "airport_name": "Tamuín National Airport"
    },
    {
      "city": "Tamworth",
      "country": "Australia",
      "IATA": "TMW",
      "icao_code": "YSTW",
      "airport_name": "Tamworth Airport"
    },
    {
      "city": "Tan Tan",
      "country": "Morocco",
      "IATA": "TTA",
      "icao_code": "GMAT",
      "airport_name": "Tan Tan Airport "
    },
    {
      "city": "Tandag",
      "country": "Philippines",
      "IATA": "TDG",
      "airport_name": "Tandag Airport"
    },
    {
      "city": "Tandil",
      "country": "Argentina",
      "IATA": "TDL",
      "icao_code": "SAZT",
      "airport_name": "Tandil Airport"
    },
    {
      "city": "Tanegashima",
      "country": "Japan",
      "IATA": "TNE",
      "icao_code": "RJFG",
      "airport_name": "New Tanegashima Airport"
    },
    {
      "city": "Tanga",
      "country": "Tanzania",
      "IATA": "TGT",
      "icao_code": "HTTG",
      "airport_name": "Tanga Airport"
    },
    {
      "city": "Tangara da Serra",
      "country": "Brazil",
      "IATA": "TGQ",
      "airport_name": "Tangara da Serra Airport"
    },
    {
      "city": "Tanger",
      "country": "Morocco",
      "IATA": "TNG",
      "icao_code": "GMTT",
      "airport_name": "Ibn Batouta International Airport"
    },
    {
      "city": "Tanjore",
      "country": "India",
      "IATA": "TJV",
      "icao_code": "VOTJ",
      "airport_name": "Tanjore Air Force Station"
    },
    {
      "city": "Tanjung Balai Karimun",
      "country": "Indonesia",
      "IATA": "TJB",
      "icao_code": "WIBT",
      "airport_name": "Sei Bati Airport"
    },
    {
      "city": "Tanjung Pandan",
      "country": "Indonesia",
      "IATA": "TJQ",
      "icao_code": "WIKD",
      "airport_name": "H.A.S. Hanandjoeddin/ Buluh Tumbang Airport"
    },
    {
      "city": "Tanjung Pinang",
      "country": "Indonesia",
      "IATA": "TNJ",
      "icao_code": "WIKN",
      "airport_name": "Kijang Airport"
    },
    {
      "city": "Tanjung Redep-Borneo Island",
      "country": "Indonesia",
      "IATA": "BEJ",
      "icao_code": "WALK",
      "airport_name": "Kalimarau Airport"
    },
    {
      "city": "Tanjung Santan",
      "country": "Indonesia",
      "IATA": "TSX",
      "icao_code": "WALT",
      "airport_name": "Tanjung Santan Airport"
    },
    {
      "city": "Tanjung Selor-Borneo Island",
      "country": "Indonesia",
      "IATA": "TJS",
      "icao_code": "WALG",
      "airport_name": "Tanjung Harapan Airport"
    },
    {
      "city": "Tanjung-Borneo Island",
      "country": "Indonesia",
      "IATA": "TJG",
      "icao_code": "WAON",
      "airport_name": "Warukin Airport"
    },
    {
      "city": "Tanna",
      "country": "Vanuatu",
      "IATA": "TAH",
      "icao_code": "NVVW",
      "airport_name": "Tanna Airport"
    },
    {
      "city": "Taos",
      "country": "United States",
      "IATA": "TSM",
      "icao_code": "KSKX",
      "airport_name": "Taos Regional Airport"
    },
    {
      "city": "Tapachula",
      "country": "Mexico",
      "IATA": "TAP",
      "icao_code": "MMTP",
      "airport_name": "Tapachula International Airport"
    },
    {
      "city": "Taplejung",
      "country": "Nepal",
      "IATA": "TPJ",
      "icao_code": "VNTJ",
      "airport_name": "Taplejung Airport"
    },
    {
      "city": "Taraken",
      "country": "Indonesia",
      "IATA": "TRK",
      "icao_code": "WALR",
      "airport_name": "Truckee Tahoe Airport"
    },
    {
      "city": "Tarama",
      "country": "Japan",
      "IATA": "TRA",
      "icao_code": "RORT",
      "airport_name": "Tarama Airport"
    },
    {
      "city": "Tarapoa",
      "country": "Ecuador",
      "IATA": "TPC",
      "icao_code": "SETR",
      "airport_name": "Tarapoa Airport"
    },
    {
      "city": "Tarapoto",
      "country": "Peru",
      "IATA": "TPP",
      "icao_code": "SPST",
      "airport_name": "Cadete FAP Guillermo Del Castillo Paredes Airport"
    },
    {
      "city": "Tarauaca",
      "country": "Brazil",
      "IATA": "TRQ",
      "icao_code": "SBTK",
      "airport_name": "José Galera dos Santos Airport"
    },
    {
      "city": "Tarawa",
      "country": "Kiribati",
      "IATA": "TRW",
      "icao_code": "NGTA",
      "airport_name": "Bonriki International Airport"
    },
    {
      "city": "Tarbes",
      "country": "France",
      "IATA": "LDE",
      "icao_code": "LFBT",
      "airport_name": "Tarbes - Lourdes Pyrenees Airport"
    },
    {
      "city": "Taree",
      "country": "Australia",
      "IATA": "TRO",
      "icao_code": "YTRE",
      "airport_name": "Taree Airport"
    },
    {
      "city": "Tari",
      "country": "Papua New Guinea",
      "IATA": "TIZ",
      "icao_code": "AYTA",
      "airport_name": "Tari Airport"
    },
    {
      "city": "Tarija",
      "country": "Bolivia",
      "IATA": "TJA",
      "icao_code": "SLTJ",
      "airport_name": "Capitán Oriel Lea Plaza  Airport"
    },
    {
      "city": "Tarin Kowt",
      "country": "Afghanistan",
      "IATA": "TII",
      "icao_code": "OATN",
      "airport_name": "Tarin Kowt Airport"
    },
    {
      "city": "Tarko-Sale",
      "country": "Russia",
      "IATA": "TQL",
      "icao_code": "USDS",
      "airport_name": "Tarko-Sale Airport"
    },
    {
      "city": "Taroom",
      "country": "Australia",
      "IATA": "XTO",
      "icao_code": "YTAM",
      "airport_name": "Taroom Airport"
    },
    {
      "city": "Tartagal",
      "country": "Argentina",
      "IATA": "TTG",
      "icao_code": "SAST",
      "airport_name": "Tartagal \"General Enrique Mosconi\" Airport"
    },
    {
      "city": "Tartu",
      "country": "Estonia",
      "IATA": "TAY",
      "icao_code": "EETU",
      "airport_name": "Tartu Airport"
    },
    {
      "city": "Tashkent",
      "country": "Uzbekistan",
      "IATA": "TAS",
      "icao_code": "UTTT",
      "airport_name": "Tashkent International Airport"
    },
    {
      "city": "Tasikmalaya",
      "country": "Indonesia",
      "IATA": "TSY",
      "icao_code": "WIAM",
      "airport_name": "Tasikmalaya Airport"
    },
    {
      "city": "Tasiujaq",
      "country": "Canada",
      "IATA": "YTQ",
      "icao_code": "CYTQ",
      "airport_name": "Tasiujaq Airport"
    },
    {
      "city": "Tatakoto",
      "country": "French Polynesia",
      "IATA": "TKV",
      "icao_code": "NTGO",
      "airport_name": "Tatakoto Airport"
    },
    {
      "city": "Tatalina",
      "country": "United States",
      "IATA": "TLJ",
      "icao_code": "PATL",
      "airport_name": "Tatalina Lrrs Airport"
    },
    {
      "city": "Taubaté",
      "country": "Brazil",
      "IATA": "QHP",
      "airport_name": " Base de Aviação de Taubaté Airport"
    },
    {
      "city": "Taupo",
      "country": "New Zealand",
      "IATA": "TUO",
      "icao_code": "NZAP",
      "airport_name": "Taupo Airport"
    },
    {
      "city": "Tauranga",
      "country": "New Zealand",
      "IATA": "TRG",
      "icao_code": "NZTG",
      "airport_name": "Tauranga Airport"
    },
    {
      "city": "Tawau",
      "country": "Malaysia",
      "IATA": "TWU",
      "icao_code": "WBKW",
      "airport_name": "Tawau Airport"
    },
    {
      "city": "Taytay",
      "country": "Philippines",
      "IATA": "RZP",
      "airport_name": "Taytay Airport"
    },
    {
      "city": "Tbilisi",
      "country": "Georgia",
      "IATA": "TBS",
      "icao_code": "UGGG",
      "airport_name": "Tbilisi International Airport"
    },
    {
      "city": "Tchibanga",
      "country": "Gabon",
      "IATA": "TCH",
      "icao_code": "FOOT",
      "airport_name": "Tchibanga Airport"
    },
    {
      "city": "Tebessa",
      "country": "Algeria",
      "IATA": "TEE",
      "icao_code": "DABS",
      "airport_name": "Cheikh Larbi Tébessa Airport"
    },
    {
      "city": "Teesside",
      "country": "United Kingdom",
      "IATA": "MME",
      "icao_code": "EGNV",
      "airport_name": "Durham Tees Valley Airport"
    },
    {
      "city": "Tefe",
      "country": "Brazil",
      "IATA": "TFF",
      "icao_code": "SBTF",
      "airport_name": "Tefé Airport"
    },
    {
      "city": "Tegucigalpa",
      "country": "Honduras",
      "IATA": "TGU",
      "icao_code": "MHTG",
      "airport_name": "Toncontín International Airport"
    },
    {
      "city": "Teheran",
      "country": "Iran",
      "IATA": "THR",
      "icao_code": "OIII",
      "airport_name": "Mehrabad International Airport"
    },
    {
      "city": "Tehran",
      "country": "Iran",
      "IATA": "IKA",
      "airport_name": "Tehran Imam Khomeini International Airport"
    },
    {
      "city": "Tehuacan",
      "country": "Mexico",
      "IATA": "TCN",
      "icao_code": "MMHC",
      "airport_name": "Tehuacán Airport"
    },
    {
      "city": "Teixeira de Freitas",
      "country": "Brazil",
      "IATA": "TXF",
      "airport_name": "Teixeira de Freitas Airport"
    },
    {
      "city": "Tel-aviv",
      "country": "Israel",
      "IATA": "SDV",
      "icao_code": "LLSD",
      "airport_name": "Sde Dov Airport"
    },
    {
      "city": "Tel-aviv",
      "country": "Israel",
      "IATA": "TLV",
      "icao_code": "LLBG",
      "airport_name": "Ben Gurion International Airport"
    },
    {
      "city": "Tela",
      "country": "Honduras",
      "IATA": "TEA",
      "icao_code": "MHTE",
      "airport_name": "Tela Airport"
    },
    {
      "city": "Telemaco Borba",
      "country": "Brazil",
      "IATA": "TEC",
      "icao_code": "SBTL",
      "airport_name": "Telêmaco Borba Airport"
    },
    {
      "city": "Telfer",
      "country": "Australia",
      "IATA": "TEF",
      "icao_code": "YTEF",
      "airport_name": "Telfer Airport"
    },
    {
      "city": "Teller",
      "country": "United States",
      "IATA": "TLA",
      "icao_code": "PATE",
      "airport_name": "Teller Airport"
    },
    {
      "city": "Telluride",
      "country": "United States",
      "IATA": "TEX",
      "icao_code": "KTEX",
      "airport_name": "Telluride Regional Airport"
    },
    {
      "city": "Temora",
      "country": "Australia",
      "IATA": "TEM",
      "icao_code": "YTEM",
      "airport_name": "Temora Airport"
    },
    {
      "city": "Temple",
      "country": "United States",
      "IATA": "TPL",
      "icao_code": "KTPL",
      "airport_name": "Draughon-Miller Central Texas Regional Airport"
    },
    {
      "city": "Temuco",
      "country": "Chile",
      "IATA": "ZCO",
      "icao_code": "SCQP",
      "airport_name": "La Araucanía International Airport"
    },
    {
      "city": "Temuco",
      "country": "Chile",
      "IATA": "PZS",
      "airport_name": "Temuco Airport"
    },
    {
      "city": "Tena",
      "country": "Ecuador",
      "IATA": "TNW",
      "airport_name": "Tin Creek Airport"
    },
    {
      "city": "Tenerife",
      "country": "Spain",
      "IATA": "TFN",
      "icao_code": "GCXO",
      "airport_name": "Tenerife North Airport "
    },
    {
      "city": "Tenerife",
      "country": "Spain",
      "IATA": "TFS",
      "icao_code": "GCTS",
      "airport_name": "Tenerife South Airport "
    },
    {
      "city": "Tengah",
      "country": "Singapore",
      "IATA": "TGA",
      "airport_name": "Tengah Air Base"
    },
    {
      "city": "Tengchong",
      "country": "China",
      "IATA": "TCZ",
      "icao_code": "ZUTC",
      "airport_name": "Tengchong Tuofeng Airport"
    },
    {
      "city": "Tennant Creek",
      "country": "Australia",
      "IATA": "TCA",
      "icao_code": "YTNK",
      "airport_name": "Tennant Creek Airport"
    },
    {
      "city": "Teofilo Otoni",
      "country": "Brazil",
      "IATA": "TFL",
      "icao_code": "SNTO",
      "airport_name": "Juscelino Kubitscheck Airport"
    },
    {
      "city": "Tepic",
      "country": "Mexico",
      "IATA": "TPQ",
      "icao_code": "MMEP",
      "airport_name": "Amado Nervo International Airport"
    },
    {
      "city": "Teresina",
      "country": "Brazil",
      "IATA": "THE",
      "icao_code": "SBTE",
      "airport_name": "Teresina–Senador Petrônio Portella Airport"
    },
    {
      "city": "Termez",
      "country": "Uzbekistan",
      "IATA": "TMJ",
      "icao_code": "UTST",
      "airport_name": "Termez Airport"
    },
    {
      "city": "Ternate",
      "country": "Indonesia",
      "IATA": "TTE",
      "icao_code": "WAMT",
      "airport_name": "Sultan Babullah Airport"
    },
    {
      "city": "Terrace",
      "country": "Canada",
      "IATA": "YXT",
      "icao_code": "CYXT",
      "airport_name": "Northwest Regional Airport Terrace-Kitimat"
    },
    {
      "city": "Teruel",
      "country": "Spain",
      "IATA": "TEV",
      "icao_code": "LETL",
      "airport_name": "Teruel Airport"
    },
    {
      "city": "Teslin",
      "country": "Canada",
      "IATA": "YZW",
      "icao_code": "CYZW",
      "airport_name": "Teslin Airport"
    },
    {
      "city": "Tete",
      "country": "Mozambique",
      "IATA": "TET",
      "icao_code": "FQTT",
      "airport_name": "Chingozi Airport"
    },
    {
      "city": "New York, Teterboro NYC",
      "country": "United States",
      "IATA": "TEB",
      "icao_code": "KTEB",
      "airport_name": "Teterboro Airport"
    },
    {
      "city": "Tetiaroa",
      "country": "French Polynesia",
      "IATA": "TTI",
      "icao_code": "NTTE",
      "airport_name": "Tetiaroa Airport"
    },
    {
      "city": "Tetouan",
      "country": "Morocco",
      "IATA": "TTU",
      "icao_code": "GMTN",
      "airport_name": "Sania Ramel Airport"
    },
    {
      "city": "Texada",
      "country": "Canada",
      "IATA": "YGB",
      "icao_code": "CYGB",
      "airport_name": "Texada/Gillies Bay Airport"
    },
    {
      "city": "Texarkana",
      "country": "United States",
      "IATA": "TXK",
      "icao_code": "KTXK",
      "airport_name": "Texarkana Regional Airport "
    },
    {
      "city": "Tezpur",
      "country": "India",
      "IATA": "TEZ",
      "icao_code": "VETZ",
      "airport_name": "Tezpur Airport"
    },
    {
      "city": "Tezu",
      "country": "India",
      "IATA": "TEI",
      "icao_code": "VETJ",
      "airport_name": "Tezu Airport"
    },
    {
      "city": "Thandwe",
      "country": "Burma",
      "IATA": "SNW",
      "icao_code": "VYTD",
      "airport_name": "Thandwe Airport"
    },
    {
      "city": "Thargomindah",
      "country": "Australia",
      "IATA": "XTG",
      "icao_code": "YTGM",
      "airport_name": "Thargomindah Airport"
    },
    {
      "city": "The Dalles",
      "country": "United States",
      "IATA": "DLS",
      "icao_code": "KDLS",
      "airport_name": "Columbia Gorge Regional Airport "
    },
    {
      "city": "The Pas",
      "country": "Canada",
      "IATA": "YQD",
      "icao_code": "CYQD",
      "airport_name": "The Pas Airport"
    },
    {
      "city": "The Valley",
      "country": "Anguilla",
      "IATA": "AXA",
      "icao_code": "TQPF",
      "airport_name": "Clayton J. Lloyd International Airport"
    },
    {
      "city": "Theodore",
      "country": "Australia",
      "IATA": "TDR",
      "icao_code": "YTDR",
      "airport_name": "Theodore Airport"
    },
    {
      "city": "Thessaloniki",
      "country": "Greece",
      "IATA": "SKG",
      "icao_code": "LGTS",
      "airport_name": "Thessaloniki International Airport"
    },
    {
      "city": "Thicket Portage",
      "country": "Canada",
      "IATA": "YTD",
      "icao_code": "CZLQ",
      "airport_name": "Thicket Portage Airport"
    },
    {
      "city": "Thief River Falls",
      "country": "United States",
      "IATA": "TVF",
      "icao_code": "KTVF",
      "airport_name": "Thief River Falls Regional Airport"
    },
    {
      "city": "Thimarafushi",
      "country": "Maldives",
      "IATA": "TMF",
      "icao_code": "VRNT",
      "airport_name": "Thimarafushi Airport"
    },
    {
      "city": "Thimphu",
      "country": "Bhutan",
      "IATA": "PBH",
      "icao_code": "VQPR",
      "airport_name": "Paro International Airport"
    },
    {
      "city": "Thira",
      "country": "Greece",
      "IATA": "JTR",
      "icao_code": "LGSR",
      "airport_name": "Santorini (Thira) National Airport"
    },
    {
      "city": "Thisted",
      "country": "Denmark",
      "IATA": "TED",
      "icao_code": "EKTS",
      "airport_name": "Thisted Airport"
    },
    {
      "city": "Thomasville",
      "country": "United States",
      "IATA": "TVI",
      "icao_code": "KTVI",
      "airport_name": "Thomasville Regional Airport"
    },
    {
      "city": "Thompson",
      "country": "Canada",
      "IATA": "YTH",
      "icao_code": "CYTH",
      "airport_name": "Thompson Airport"
    },
    {
      "city": "Thorshofn",
      "country": "Iceland",
      "IATA": "THO",
      "icao_code": "BITN",
      "airport_name": "Þórshöfn Airport"
    },
    {
      "city": "Thule",
      "country": "Greenland",
      "IATA": "THU",
      "icao_code": "BGTL",
      "airport_name": "Thule Air Base"
    },
    {
      "city": "Thumrait",
      "country": "Oman",
      "IATA": "TTH",
      "icao_code": "OOTH",
      "airport_name": "RAFO Thumrait"
    },
    {
      "city": "Thunder Bay",
      "country": "Canada",
      "IATA": "YQT",
      "icao_code": "CYQT",
      "airport_name": "Thunder Bay International Airport"
    },
    {
      "city": "Tianjin",
      "country": "China",
      "IATA": "TSN",
      "icao_code": "ZBTJ",
      "airport_name": "Tianjin Binhai International Airport"
    },
    {
      "city": "Tianshui",
      "country": "China",
      "IATA": "THQ",
      "icao_code": "ZLTS",
      "airport_name": "Tianshui Maijishan Airport"
    },
    {
      "city": "Tiaret",
      "country": "Algeria",
      "IATA": "TID",
      "icao_code": "DAOB",
      "airport_name": "Abdelhafid Boussouf Bou Chekif Airport"
    },
    {
      "city": "Tidjikja",
      "country": "Mauritania",
      "IATA": "TIY",
      "icao_code": "GQND",
      "airport_name": "Tidjikja Airport"
    },
    {
      "city": "Tifton",
      "country": "United States",
      "IATA": "TMA",
      "icao_code": "KTMA",
      "airport_name": "Henry Tift Myers Airport"
    },
    {
      "city": "Tiga",
      "country": "New Caledonia",
      "IATA": "TGJ",
      "icao_code": "NWWA",
      "airport_name": "Tiga Airport"
    },
    {
      "city": "Tijuana",
      "country": "Mexico",
      "IATA": "TIJ",
      "icao_code": "MMTJ",
      "airport_name": "Tijuana International Airport"
    },
    {
      "city": "Tikehau",
      "country": "French Polynesia",
      "IATA": "TIH",
      "icao_code": "NTGC",
      "airport_name": "Tikehau Airport"
    },
    {
      "city": "Tiko",
      "country": "Cameroon",
      "IATA": "TKC",
      "icao_code": "FKKC",
      "airport_name": "Tiko Airport"
    },
    {
      "city": "Tiksi",
      "country": "Russia",
      "IATA": "IKS",
      "icao_code": "UEST",
      "airport_name": "Tiksi Airport"
    },
    {
      "city": "Tillamook",
      "country": "United States",
      "IATA": "OTK",
      "airport_name": "Tillamook Airport"
    },
    {
      "city": "Tilrempt",
      "country": "Algeria",
      "IATA": "HRM",
      "icao_code": "DAFH",
      "airport_name": "Hassi R'Mel Airport"
    },
    {
      "city": "Timaru",
      "country": "New Zealand",
      "IATA": "TIU",
      "icao_code": "NZTU",
      "airport_name": "Richard Pearse Airport"
    },
    {
      "city": "Timika",
      "country": "Indonesia",
      "IATA": "TIM",
      "icao_code": "WABP",
      "airport_name": "Mozes Kilangin International Airport"
    },
    {
      "city": "Timimoun",
      "country": "Algeria",
      "IATA": "TMX",
      "icao_code": "DAUT",
      "airport_name": "Timimoun Airport"
    },
    {
      "city": "Timisoara",
      "country": "Romania",
      "IATA": "TSR",
      "icao_code": "LRTR",
      "airport_name": "Timișoara Traian Vuia International Airport"
    },
    {
      "city": "Timmins",
      "country": "Canada",
      "IATA": "YTS",
      "icao_code": "CYTS",
      "airport_name": "Timmins/Victor M. Power Airport"
    },
    {
      "city": "Tin City",
      "country": "United States",
      "IATA": "TNC",
      "icao_code": "PATC",
      "airport_name": "Tin City LRRS Airport"
    },
    {
      "city": "Tindouf",
      "country": "Algeria",
      "IATA": "TIN",
      "icao_code": "DAOF",
      "airport_name": "Tindouf Airport"
    },
    {
      "city": "Tingo Maria",
      "country": "Peru",
      "IATA": "TGI",
      "icao_code": "SPGM",
      "airport_name": "Tangier Island Airport"
    },
    {
      "city": "Tioman",
      "country": "Malaysia",
      "IATA": "TOD",
      "icao_code": "WMBT",
      "airport_name": "Tioman Airport"
    },
    {
      "city": "Tippi",
      "country": "Ethiopia",
      "IATA": "TIE",
      "icao_code": "HATP",
      "airport_name": "Tippi Airport"
    },
    {
      "city": "Tiputini",
      "country": "Ecuador",
      "IATA": "TPN",
      "icao_code": "SETI",
      "airport_name": "Tiputini Airport"
    },
    {
      "city": "Tirana",
      "country": "Albania",
      "IATA": "TIA",
      "icao_code": "LATI",
      "airport_name": "Tirana International Airport"
    },
    {
      "city": "Tiree",
      "country": "United Kingdom",
      "IATA": "TRE",
      "icao_code": "EGPU",
      "airport_name": "Tiree Airport"
    },
    {
      "city": "Tirgu Mures",
      "country": "Romania",
      "IATA": "TGM",
      "icao_code": "LRTM",
      "airport_name": "Târgu Mureș Transylvania Airport"
    },
    {
      "city": "Tiruchirappalli",
      "country": "India",
      "IATA": "TRZ",
      "icao_code": "VOTR",
      "airport_name": "Tiruchirappalli International Airport "
    },
    {
      "city": "Tirupeti",
      "country": "India",
      "IATA": "TIR",
      "icao_code": "VOTP",
      "airport_name": "Tirupati Airport"
    },
    {
      "city": "Titusville",
      "country": "United States",
      "IATA": "TIX",
      "icao_code": "KTIX",
      "airport_name": "Space Coast Regional Airport"
    },
    {
      "city": "Tivat",
      "country": "Montenegro",
      "IATA": "TIV",
      "icao_code": "LYTV",
      "airport_name": "Tivat Airport"
    },
    {
      "city": "Tlemcen",
      "country": "Algeria",
      "IATA": "TLM",
      "icao_code": "DAON",
      "airport_name": "Zenata – Messali El Hadj Airport"
    },
    {
      "city": "Toamasina",
      "country": "Madagascar",
      "IATA": "TMM",
      "icao_code": "FMMT",
      "airport_name": "Toamasina Airport"
    },
    {
      "city": "Tobruk",
      "country": "Libya",
      "IATA": "TOB",
      "icao_code": "HLGN",
      "airport_name": "Dodge Center Airport"
    },
    {
      "city": "Toccoa",
      "country": "United States",
      "IATA": "TOC",
      "icao_code": "KTOC",
      "airport_name": "Toccoa Airport"
    },
    {
      "city": "Tocopilla",
      "country": "Chile",
      "IATA": "TOQ",
      "airport_name": "Barriles Airport"
    },
    {
      "city": "Tofino",
      "country": "Canada",
      "IATA": "YAZ",
      "icao_code": "CYAZ",
      "airport_name": "Tofino/Long Beach Airport"
    },
    {
      "city": "Togiak Village",
      "country": "United States",
      "IATA": "TOG",
      "icao_code": "PATG",
      "airport_name": "Togiak Airport"
    },
    {
      "city": "Tok",
      "country": "United States",
      "IATA": "TKJ",
      "icao_code": "PATJ",
      "airport_name": "Tok Airport"
    },
    {
      "city": "Tokat",
      "country": "Turkey",
      "IATA": "TJK",
      "icao_code": "LTAW",
      "airport_name": "Tokat Airport"
    },
    {
      "city": "Toksook Bay",
      "country": "United States",
      "IATA": "OOK",
      "icao_code": "PAOO",
      "airport_name": "Toksook Bay Airport"
    },
    {
      "city": "Tokua",
      "country": "Papua New Guinea",
      "IATA": "RAB",
      "icao_code": "AYTK",
      "airport_name": "Rabaul Airport"
    },
    {
      "city": "Tokunoshima",
      "country": "Japan",
      "IATA": "TKN",
      "icao_code": "RJKN",
      "airport_name": "Tokunoshima Airport"
    },
    {
      "city": "Tokushima",
      "country": "Japan",
      "IATA": "TKS",
      "icao_code": "RJOS",
      "airport_name": "Tokushima Airport"
    },
    {
      "city": "Tokyo",
      "country": "Japan",
      "IATA": "HND",
      "icao_code": "RJTT",
      "airport_name": "Tokyo International Airport "
    },
    {
      "city": "Tokyo",
      "country": "Japan",
      "IATA": "NRT",
      "icao_code": "RJAA",
      "airport_name": "Narita International Airport "
    },
    {
      "city": "Tola",
      "country": "Nicaragua",
      "IATA": "ECI",
      "airport_name": "Tola Airport"
    },
    {
      "city": "Tolagnaro",
      "country": "Madagascar",
      "IATA": "FTU",
      "icao_code": "FMSD",
      "airport_name": "Tôlanaro Airport"
    },
    {
      "city": "Toledo",
      "country": "Brazil",
      "IATA": "TOW",
      "icao_code": "SBTD",
      "airport_name": "Toledo Airport"
    },
    {
      "city": "Toledo",
      "country": "United States",
      "IATA": "TOL",
      "icao_code": "KTOL",
      "airport_name": "Toledo Express Airport"
    },
    {
      "city": "Toli-Toli",
      "country": "Indonesia",
      "IATA": "TLI",
      "airport_name": "Sultan Bantilan Airport"
    },
    {
      "city": "Toliara",
      "country": "Madagascar",
      "IATA": "TLE",
      "icao_code": "FMST",
      "airport_name": "Toliara Airport"
    },
    {
      "city": "Tolu",
      "country": "Colombia",
      "IATA": "TLU",
      "icao_code": "SKTL",
      "airport_name": "Golfo de Morrosquillo Airport"
    },
    {
      "city": "Toluca",
      "country": "Mexico",
      "IATA": "TLC",
      "icao_code": "MMTO",
      "airport_name": " Licenciado Adolfo López Mateos International Airport"
    },
    {
      "city": "Tomanggong",
      "country": "Malaysia",
      "IATA": "TMG",
      "icao_code": "WBKM",
      "airport_name": "Tomanggong Airport"
    },
    {
      "city": "Tombouctou",
      "country": "Mali",
      "IATA": "TOM",
      "icao_code": "GATB",
      "airport_name": "Timbuktu Airport"
    },
    {
      "city": "Tomsk",
      "country": "Russia",
      "IATA": "TOF",
      "icao_code": "UNTT",
      "airport_name": "Tomsk Bogashevo Airport "
    },
    {
      "city": "Tongatapu",
      "country": "Tonga",
      "IATA": "TBU",
      "icao_code": "NFTF",
      "airport_name": "Fuaʻamotu International Airport "
    },
    {
      "city": "Tonghua",
      "country": "China",
      "IATA": "TNH",
      "icao_code": "ZYTN",
      "airport_name": "Tonghua Sanyuanpu Airport"
    },
    {
      "city": "Tongliao",
      "country": "China",
      "IATA": "TGO",
      "icao_code": "ZBTL",
      "airport_name": "Tongliao Airport"
    },
    {
      "city": "Tongoa Island",
      "country": "Vanuatu",
      "IATA": "TGH",
      "icao_code": "NVST",
      "airport_name": "Tongoa Airport"
    },
    {
      "city": "Tongren",
      "country": "China",
      "IATA": "TEN",
      "icao_code": "ZUTR",
      "airport_name": "Tongren Fenghuang Airport"
    },
    {
      "city": "Tonopah",
      "country": "United States",
      "IATA": "TPH",
      "icao_code": "KTPH",
      "airport_name": "Tonopah Airport"
    },
    {
      "city": "Tonopah",
      "country": "United States",
      "IATA": "XSD",
      "icao_code": "KTNX",
      "airport_name": "Tonopah Test Range Airport"
    },
    {
      "city": "Toowoomba",
      "country": "Australia",
      "IATA": "WTB",
      "icao_code": "YBWW",
      "airport_name": "Toowoomba Airport"
    },
    {
      "city": "Toowoomba",
      "country": "Australia",
      "IATA": "TWB",
      "icao_code": "YTWB",
      "airport_name": "Toowoomba Airport"
    },
    {
      "city": "Topeka",
      "country": "United States",
      "IATA": "TOP",
      "icao_code": "KTOP",
      "airport_name": "Philip Billard Municipal Airport"
    },
    {
      "city": "Topeka",
      "country": "United States",
      "IATA": "FOE",
      "icao_code": "KFOE",
      "airport_name": "Topeka Regional Airport"
    },
    {
      "city": "Topel",
      "country": "Turkey",
      "IATA": "KCO",
      "icao_code": "LTBQ",
      "airport_name": "Cengiz Topel Naval Air Station"
    },
    {
      "city": "Toranagallu",
      "country": "India",
      "IATA": "VDY",
      "icao_code": "VOJV",
      "airport_name": "Vidyanagar Airport"
    },
    {
      "city": "Torino",
      "country": "Italy",
      "IATA": "TRN",
      "icao_code": "LIMF",
      "airport_name": "Sandro Pertini Airport "
    },
    {
      "city": "Toronto",
      "country": "Canada",
      "IATA": "YZD",
      "airport_name": "Downsview Airport"
    },
    {
      "city": "Toronto",
      "country": "Canada",
      "IATA": "YYZ",
      "icao_code": "CYYZ",
      "airport_name": "Toronto Pearson International Airport"
    },
    {
      "city": "Toronto",
      "country": "Canada",
      "IATA": "YTZ",
      "icao_code": "CYTZ",
      "airport_name": "Billy Bishop Toronto City Airport"
    },
    {
      "city": "Toronto",
      "country": "Canada",
      "IATA": "YKZ",
      "icao_code": "CYKZ",
      "airport_name": "Buttonville Municipal Airport"
    },
    {
      "city": "Torrance",
      "country": "United States",
      "IATA": "TOA",
      "icao_code": "KTOA",
      "airport_name": "Zamperini Field Airport"
    },
    {
      "city": "Torreon",
      "country": "Mexico",
      "IATA": "TRC",
      "icao_code": "MMTC",
      "airport_name": "Francisco Sarabia International Airport "
    },
    {
      "city": "Torsby",
      "country": "Sweden",
      "IATA": "TYF",
      "icao_code": "ESST",
      "airport_name": "Torsby Airport"
    },
    {
      "city": "Tortola",
      "country": "British Virgin Islands",
      "IATA": "EIS",
      "icao_code": "TUPJ",
      "airport_name": "Terrance B. Lettsome International Airport"
    },
    {
      "city": "Tortoli",
      "country": "Italy",
      "IATA": "TTB",
      "icao_code": "LIET",
      "airport_name": "Tortolì Airport"
    },
    {
      "city": "Totegegie",
      "country": "French Polynesia",
      "IATA": "GMR",
      "icao_code": "NTGJ",
      "airport_name": "Totegegie Airport "
    },
    {
      "city": "Totness",
      "country": "Suriname",
      "IATA": "TOT",
      "airport_name": "Totness Airstrip"
    },
    {
      "city": "Tottori",
      "country": "Japan",
      "IATA": "TTJ",
      "icao_code": "RJOR",
      "airport_name": "Tottori Airport"
    },
    {
      "city": "Touggourt",
      "country": "Algeria",
      "IATA": "TGR",
      "icao_code": "DAUK",
      "airport_name": "Sidi Mahdi Airport"
    },
    {
      "city": "Touho",
      "country": "New Caledonia",
      "IATA": "TOU",
      "icao_code": "NWWU",
      "airport_name": "Touho Airport"
    },
    {
      "city": "Toulouse",
      "country": "France",
      "IATA": "TLS",
      "icao_code": "LFBO",
      "airport_name": "Toulouse–Blagnac Airport"
    },
    {
      "city": "Tours",
      "country": "France",
      "IATA": "TUF",
      "icao_code": "LFOT",
      "airport_name": "Tours Val de Loire Airport"
    },
    {
      "city": "Toussous-le-noble",
      "country": "France",
      "IATA": "TNF",
      "icao_code": "LFPN",
      "airport_name": "Toussus-le-Noble Airport"
    },
    {
      "city": "Townsville",
      "country": "Australia",
      "IATA": "TSV",
      "icao_code": "YBTL",
      "airport_name": "Townsville Airport"
    },
    {
      "city": "Toyama",
      "country": "Japan",
      "IATA": "TOY",
      "icao_code": "RJNT",
      "airport_name": "Toyama Airport"
    },
    {
      "city": "Toyooka",
      "country": "Japan",
      "IATA": "TJH",
      "icao_code": "RJBT",
      "airport_name": "Tajima Airport"
    },
    {
      "city": "Tozeur",
      "country": "Tunisia",
      "IATA": "TOE",
      "icao_code": "DTTZ",
      "airport_name": "Tozeur Nefta International Airport"
    },
    {
      "city": "Trabzon",
      "country": "Turkey",
      "IATA": "TZX",
      "icao_code": "LTCG",
      "airport_name": "Trabzon Airport"
    },
    {
      "city": "Trail",
      "country": "Canada",
      "IATA": "YZZ",
      "icao_code": "CAD4",
      "airport_name": "Trail Airport"
    },
    {
      "city": "Trang",
      "country": "Thailand",
      "IATA": "TST",
      "icao_code": "VTST",
      "airport_name": "Trang Airport"
    },
    {
      "city": "Trapani",
      "country": "Italy",
      "IATA": "TPS",
      "icao_code": "LICT",
      "airport_name": "Vincenzo Florio Airport Trapani–Birgi"
    },
    {
      "city": "Trat",
      "country": "Thailand",
      "IATA": "TDX",
      "icao_code": "VTBO",
      "airport_name": "Trat Airport"
    },
    {
      "city": "Traverse City",
      "country": "United States",
      "IATA": "TVC",
      "icao_code": "KTVC",
      "airport_name": "Cherry Capital Airport"
    },
    {
      "city": "Treasure Cay",
      "country": "Bahamas",
      "IATA": "TCB",
      "icao_code": "MYAT",
      "airport_name": "Treasure Cay Airport"
    },
    {
      "city": "Trelew",
      "country": "Argentina",
      "IATA": "REL",
      "icao_code": "SAVT",
      "airport_name": "Almirante Marco Andres Zar Airport"
    },
    {
      "city": "Trenton",
      "country": "United States",
      "IATA": "TTN",
      "icao_code": "KTTN",
      "airport_name": "Trenton-Mercer Airport"
    },
    {
      "city": "Trenton",
      "country": "Canada",
      "IATA": "YTR",
      "icao_code": "CYTR",
      "airport_name": "Trenton–Mercer Airport"
    },
    {
      "city": "Tres Arroyos",
      "country": "Argentina",
      "IATA": "OYO",
      "icao_code": "SAZH",
      "airport_name": "Tres Arroyos Airport"
    },
    {
      "city": "Tres Esquinas",
      "country": "Colombia",
      "IATA": "TQS",
      "icao_code": "SKTQ",
      "airport_name": "Captain Ernesto Esguerra Cubides Air Base"
    },
    {
      "city": "Tres Lagoas",
      "country": "Brazil",
      "IATA": "TJL",
      "icao_code": "SSTL",
      "airport_name": "Tres Lagoas Airport"
    },
    {
      "city": "Treviso",
      "country": "Italy",
      "IATA": "TSF",
      "icao_code": "LIPH",
      "airport_name": "Treviso Airport"
    },
    {
      "city": "Trinciomalee",
      "country": "Sri Lanka",
      "IATA": "TRR",
      "icao_code": "VCCT",
      "airport_name": "China Bay Airport"
    },
    {
      "city": "Trinidad",
      "country": "Cuba",
      "IATA": "TND",
      "icao_code": "MUTD",
      "airport_name": "Alberto Delgado Airport"
    },
    {
      "city": "Trinidad",
      "country": "Bolivia",
      "IATA": "TDD",
      "icao_code": "SLTR",
      "airport_name": "Teniente Jorge Henrich Arauz Airport"
    },
    {
      "city": "Tripoli",
      "country": "Libya",
      "IATA": "MJI",
      "icao_code": "HLLM",
      "airport_name": "Mitiga International Airport"
    },
    {
      "city": "Tripoli",
      "country": "Libya",
      "IATA": "TIP",
      "icao_code": "HLLT",
      "airport_name": "Tripoli International Airport"
    },
    {
      "city": "Trivandrum",
      "country": "India",
      "IATA": "TRV",
      "icao_code": "VOTV",
      "airport_name": "Trivandrum International Airport"
    },
    {
      "city": "Trois Rivieres",
      "country": "Canada",
      "IATA": "YRQ",
      "icao_code": "CYRQ",
      "airport_name": "Trois-Rivières Airport"
    },
    {
      "city": "Trollhattan",
      "country": "Sweden",
      "IATA": "THN",
      "icao_code": "ESGT",
      "airport_name": "Trollhättan-Vänersborg Airport"
    },
    {
      "city": "Trondheim",
      "country": "Norway",
      "IATA": "TRD",
      "icao_code": "ENVA",
      "airport_name": "Trondheim Airport, Værnes"
    },
    {
      "city": "Troutdale",
      "country": "United States",
      "IATA": "TTD",
      "icao_code": "KTTD",
      "airport_name": "Portland-Troutdale Airport"
    },
    {
      "city": "Troy",
      "country": "United States",
      "IATA": "TOI",
      "icao_code": "KTOI",
      "airport_name": "Troy Municipal Airport"
    },
    {
      "city": "Truckee",
      "country": "United States",
      "IATA": "TKF",
      "icao_code": "KTRK",
      "airport_name": "Truckee Tahoe Airport"
    },
    {
      "city": "Trujillo",
      "country": "Peru",
      "IATA": "TRU",
      "icao_code": "SPRU",
      "airport_name": "FAP Captain Carlos Martínez de Pinillos International Airport"
    },
    {
      "city": "Trujillo",
      "country": "Honduras",
      "IATA": "TJI",
      "airport_name": "Trujillo Airport"
    },
    {
      "city": "Truth Or Consequences",
      "country": "United States",
      "IATA": "TCS",
      "icao_code": "KTCS",
      "airport_name": "Truth or Consequences Municipal Airport"
    },
    {
      "city": "Tselinograd",
      "country": "Kazakhstan",
      "IATA": "TSE",
      "icao_code": "UACC",
      "airport_name": "Astana International Airport"
    },
    {
      "city": "Tshikapa",
      "country": "Congo (Kinshasa)",
      "IATA": "TSH",
      "icao_code": "FZUK",
      "airport_name": "Tshikapa Airport"
    },
    {
      "city": "Tsiroanomandidy",
      "country": "Madagascar",
      "IATA": "WTS",
      "airport_name": "Tsiroanomandidy Airport"
    },
    {
      "city": "Tsumeb",
      "country": "Namibia",
      "IATA": "TSB",
      "icao_code": "FYTM",
      "airport_name": "Tsumeb Airport"
    },
    {
      "city": "Tsushima",
      "country": "Japan",
      "IATA": "TSJ",
      "icao_code": "RJDT",
      "airport_name": "Tsushima Airport"
    },
    {
      "city": "Tubuai",
      "country": "French Polynesia",
      "IATA": "TUB",
      "icao_code": "NTAT",
      "airport_name": "Tubuai – Mataura  Airport"
    },
    {
      "city": "Tucson",
      "country": "United States",
      "IATA": "AVW",
      "icao_code": "KAVQ",
      "airport_name": "Marana Regional Airport "
    },
    {
      "city": "Tucson",
      "country": "United States",
      "IATA": "TUS",
      "icao_code": "KTUS",
      "airport_name": "Tucson International Airport"
    },
    {
      "city": "Tucson",
      "country": "United States",
      "IATA": "DMA",
      "icao_code": "KDMA",
      "airport_name": "Davis–Monthan Air Force Base"
    },
    {
      "city": "Tucuman",
      "country": "Argentina",
      "IATA": "TUC",
      "icao_code": "SANT",
      "airport_name": "Teniente General Benjamín Matienzo International Airport"
    },
    {
      "city": "Tucumcari",
      "country": "United States",
      "IATA": "TCC",
      "icao_code": "KTCC",
      "airport_name": "Tucumcari Municipal Airport"
    },
    {
      "city": "Tucupita",
      "country": "Venezuela",
      "IATA": "TUV",
      "icao_code": "SVTC",
      "airport_name": "San Rafael Airport"
    },
    {
      "city": "Tucurui",
      "country": "Brazil",
      "IATA": "TUR",
      "icao_code": "SBTU",
      "airport_name": "Tucuruí Airport"
    },
    {
      "city": "Tuguegarao",
      "country": "Philippines",
      "IATA": "TUG",
      "icao_code": "RPUT",
      "airport_name": "Tuguegarao Airport"
    },
    {
      "city": "Tuktoyaktuk",
      "country": "Canada",
      "IATA": "YUB",
      "icao_code": "CYUB",
      "airport_name": "Tuktoyaktuk/James Gruben Airport"
    },
    {
      "city": "Tulcan",
      "country": "Ecuador",
      "IATA": "TUA",
      "icao_code": "SETU",
      "airport_name": "Teniente Coronel Luis a Mantilla International Airport"
    },
    {
      "city": "Tulcea",
      "country": "Romania",
      "IATA": "TCE",
      "icao_code": "LRTC",
      "airport_name": "Tulcea Danube Delta Airport "
    },
    {
      "city": "Tuli Lodge",
      "country": "Botswana",
      "IATA": "TLD",
      "icao_code": "FBTL",
      "airport_name": "Limpopo Valley Airport"
    },
    {
      "city": "Tulita",
      "country": "Canada",
      "IATA": "ZFN",
      "icao_code": "CZFN",
      "airport_name": "Tulita Airport"
    },
    {
      "city": "Tulsa",
      "country": "United States",
      "IATA": "RVS",
      "icao_code": "KRVS",
      "airport_name": "Richard Lloyd Jones Jr. Airport"
    },
    {
      "city": "Tulsa",
      "country": "United States",
      "IATA": "TUL",
      "icao_code": "KTUL",
      "airport_name": "Tulsa International Airport"
    },
    {
      "city": "Tulua",
      "country": "Colombia",
      "IATA": "ULQ",
      "icao_code": "SKUL",
      "airport_name": "Heriberto Gíl Martínez Airport"
    },
    {
      "city": "Tumaco",
      "country": "Colombia",
      "IATA": "TCO",
      "icao_code": "SKCO",
      "airport_name": "La Florida Airport"
    },
    {
      "city": "Tumbes",
      "country": "Peru",
      "IATA": "TBP",
      "icao_code": "SPME",
      "airport_name": "Cap. FAP Pedro Canga Rodríguez Airport"
    },
    {
      "city": "Tumeremo",
      "country": "Venezuela",
      "IATA": "TMO",
      "icao_code": "SVTM",
      "airport_name": "Tumeremo Airport"
    },
    {
      "city": "Tumling Tar",
      "country": "Nepal",
      "IATA": "TMI",
      "icao_code": "VNTR",
      "airport_name": "Tumling Tar Airport"
    },
    {
      "city": "Tunica",
      "country": "United States",
      "IATA": "UTM",
      "icao_code": "KUTA",
      "airport_name": "Tunica Municipal Airport"
    },
    {
      "city": "Tunis",
      "country": "Tunisia",
      "IATA": "TUN",
      "icao_code": "DTTA",
      "airport_name": "Tunis-Carthage International Airport"
    },
    {
      "city": "Tupelo",
      "country": "United States",
      "IATA": "TUP",
      "icao_code": "KTUP",
      "airport_name": "Tupelo Regional Airport"
    },
    {
      "city": "Turaif",
      "country": "Saudi Arabia",
      "IATA": "TUI",
      "icao_code": "OETR",
      "airport_name": "Turaif Domestic Airport"
    },
    {
      "city": "Turbat",
      "country": "Pakistan",
      "IATA": "TUK",
      "icao_code": "OPTU",
      "airport_name": "Turbat International Airport"
    },
    {
      "city": "Tureia",
      "country": "French Polynesia",
      "IATA": "ZTA",
      "icao_code": "NTGY",
      "airport_name": "Tureia Airport"
    },
    {
      "city": "Turku",
      "country": "Finland",
      "IATA": "TKU",
      "icao_code": "EFTU",
      "airport_name": "Turku Airport"
    },
    {
      "city": "Turpan",
      "country": "China",
      "IATA": "TLQ",
      "airport_name": "Turpan Jiaohe Airport"
    },
    {
      "city": "Turtle Island",
      "country": "Fiji",
      "IATA": "TTL",
      "airport_name": "Turtle Island Airport"
    },
    {
      "city": "Turukhansk",
      "country": "Russia",
      "IATA": "THX",
      "icao_code": "UOTT",
      "airport_name": "Turukhansk Airport"
    },
    {
      "city": "Tuscaloosa AL",
      "country": "United States",
      "IATA": "TCL",
      "icao_code": "KTCL",
      "airport_name": "Tuscaloosa Regional Airport"
    },
    {
      "city": "Tuxtla Gutierrez",
      "country": "Mexico",
      "IATA": "TGZ",
      "icao_code": "MMTG",
      "airport_name": "Angel Albino Corzo International Airport"
    },
    {
      "city": "Tuy Hoa",
      "country": "Vietnam",
      "IATA": "TBB",
      "icao_code": "VVTH",
      "airport_name": "Dong Tac Airport"
    },
    {
      "city": "Tver",
      "country": "Russia",
      "IATA": "KLD",
      "icao_code": "UUEM",
      "airport_name": "Migalovo Airbase"
    },
    {
      "city": "Twin Falls",
      "country": "United States",
      "IATA": "TWF",
      "icao_code": "KTWF",
      "airport_name": "Magic Valley Regional Airport"
    },
    {
      "city": "Tyler",
      "country": "United States",
      "IATA": "TYR",
      "icao_code": "KTYR",
      "airport_name": "Tyler Pounds Regional Airport"
    },
    {
      "city": "Tyumen",
      "country": "Russia",
      "IATA": "TJM",
      "icao_code": "USTR",
      "airport_name": "Roshchino International Airport"
    },
    {
      "city": "Tzaneen",
      "country": "South Africa",
      "IATA": "LTA",
      "icao_code": "FATZ",
      "airport_name": "Tzaneen Airport "
    },
    {
      "city": "Tête-à-la-Baleine",
      "country": "Canada",
      "IATA": "ZTB",
      "icao_code": "CTB6",
      "airport_name": "Tête-à-la-Baleine Airport"
    },
    {
      "city": "Ua Huka",
      "country": "French Polynesia",
      "IATA": "UAH",
      "icao_code": "NTMU",
      "airport_name": "Ua Huka Airport"
    },
    {
      "city": "Ua Pou",
      "country": "French Polynesia",
      "IATA": "UAP",
      "icao_code": "NTMP",
      "airport_name": "Ua Pou Airport"
    },
    {
      "city": "Ubatuba",
      "country": "Brazil",
      "IATA": "UBT",
      "icao_code": "SDUB",
      "airport_name": "Ubatuba Airport"
    },
    {
      "city": "Uberaba",
      "country": "Brazil",
      "IATA": "UBA",
      "icao_code": "SBUR",
      "airport_name": "Uberaba–Mário de Almeida Franco Airport "
    },
    {
      "city": "Uberlandia",
      "country": "Brazil",
      "IATA": "UDI",
      "icao_code": "SBUL",
      "airport_name": "Uberlândia–Ten. Cel. Av. César Bombonato Airport"
    },
    {
      "city": "Ubon Ratchathani",
      "country": "Thailand",
      "IATA": "UBP",
      "icao_code": "VTUU",
      "airport_name": "Ubon Ratchathani Airport"
    },
    {
      "city": "Udaipur",
      "country": "India",
      "IATA": "UDR",
      "icao_code": "VAUD",
      "airport_name": "Maharana Pratap Airport"
    },
    {
      "city": "Udon Thani",
      "country": "Thailand",
      "IATA": "UTH",
      "icao_code": "VTUD",
      "airport_name": "Udon Thani International Airport"
    },
    {
      "city": "Ufa",
      "country": "Russia",
      "IATA": "UFA",
      "icao_code": "UWUU",
      "airport_name": "Ufa International Airport"
    },
    {
      "city": "Uige",
      "country": "Angola",
      "IATA": "UGO",
      "icao_code": "FNUG",
      "airport_name": "Uige Airport"
    },
    {
      "city": "Ujae Atoll",
      "country": "Marshall Islands",
      "IATA": "UJE",
      "airport_name": "Ujae Airport"
    },
    {
      "city": "Ujung Pandang",
      "country": "Indonesia",
      "IATA": "UPG",
      "icao_code": "WAAA",
      "airport_name": "Sultan Hasanuddin International Airport"
    },
    {
      "city": "Ukhta",
      "country": "Russia",
      "IATA": "UCT",
      "icao_code": "UUYH",
      "airport_name": "Ukhta Airport"
    },
    {
      "city": "Ukiah",
      "country": "United States",
      "IATA": "UKI",
      "icao_code": "KUKI",
      "airport_name": "Ukiah Municipal Airport"
    },
    {
      "city": "Ukunda",
      "country": "Kenya",
      "IATA": "UKA",
      "icao_code": "HKUK",
      "airport_name": "Ukunda Airstrip"
    },
    {
      "city": "Ulaangom",
      "country": "Mongolia",
      "IATA": "ULO",
      "icao_code": "ZMUG",
      "airport_name": "Ulaangom Airport"
    },
    {
      "city": "Ulan Bator",
      "country": "Mongolia",
      "IATA": "ULN",
      "icao_code": "ZMUB",
      "airport_name": "Chinggis Khaan International Airport"
    },
    {
      "city": "Ulan-ude",
      "country": "Russia",
      "IATA": "UUD",
      "icao_code": "UIUU",
      "airport_name": "Baikal International Airport"
    },
    {
      "city": "Ulanhot",
      "country": "China",
      "IATA": "HLH",
      "icao_code": "ZBUL",
      "airport_name": "Ulanhot Airport"
    },
    {
      "city": "Ulawa",
      "country": "Solomon Islands",
      "IATA": "RNA",
      "icao_code": "AGAR",
      "airport_name": "Ulawa Airport"
    },
    {
      "city": "Uliastai",
      "country": "Mongolia",
      "IATA": "ULZ",
      "airport_name": "Jibhalanta Airport"
    },
    {
      "city": "Ulsan",
      "country": "South Korea",
      "IATA": "USN",
      "icao_code": "RKPU",
      "airport_name": "Ulsan Airport"
    },
    {
      "city": "Ulundi",
      "country": "South Africa",
      "IATA": "ULD",
      "icao_code": "FAUL",
      "airport_name": "Ulundi Airport"
    },
    {
      "city": "Uluru",
      "country": "Australia",
      "IATA": "AYQ",
      "icao_code": "YAYE",
      "airport_name": "Ayers Rock Airport"
    },
    {
      "city": "Ulyanovsk",
      "country": "Russia",
      "IATA": "ULV",
      "icao_code": "UWLL",
      "airport_name": "Ulyanovsk Baratayevka Airport"
    },
    {
      "city": "Ulyanovsk",
      "country": "Russia",
      "IATA": "ULY",
      "icao_code": "UWLW",
      "airport_name": "Ulyanovsk Vostochny Airport"
    },
    {
      "city": "Umea",
      "country": "Sweden",
      "IATA": "UME",
      "icao_code": "ESNU",
      "airport_name": "Umeå Airport"
    },
    {
      "city": "Umiujaq",
      "country": "Canada",
      "IATA": "YUD",
      "icao_code": "CYMU",
      "airport_name": "Umiujaq Airport"
    },
    {
      "city": "Umtata",
      "country": "South Africa",
      "IATA": "UTT",
      "icao_code": "FAUT",
      "airport_name": "Mthatha Airport"
    },
    {
      "city": "Umuarama",
      "country": "Brazil",
      "IATA": "UMU",
      "icao_code": "SSUM",
      "airport_name": "Umuarama Airport"
    },
    {
      "city": "Una",
      "country": "Brazil",
      "IATA": "UNA",
      "icao_code": "SBTC",
      "airport_name": "Una-Comandatuba Airport"
    },
    {
      "city": "Unalakleet",
      "country": "United States",
      "IATA": "UNK",
      "icao_code": "PAUN",
      "airport_name": "Unalakleet Airport"
    },
    {
      "city": "Unalaska",
      "country": "United States",
      "IATA": "DUT",
      "icao_code": "PADU",
      "airport_name": "Tom Madsen (Dutch Harbor) Airport"
    },
    {
      "city": "Union Island",
      "country": "Saint Vincent and the Grenadines",
      "IATA": "UNI",
      "airport_name": "Union Island Airport"
    },
    {
      "city": "Unst",
      "country": "United Kingdom",
      "IATA": "UNT",
      "airport_name": "Unst Airport"
    },
    {
      "city": "Upala",
      "country": "Costa Rica",
      "IATA": "UPL",
      "icao_code": "MRUP",
      "airport_name": "Upala Airport"
    },
    {
      "city": "Upernavik",
      "country": "Greenland",
      "IATA": "JUV",
      "icao_code": "BGUK",
      "airport_name": "Upernavik Airport"
    },
    {
      "city": "Upington",
      "country": "South Africa",
      "IATA": "UTN",
      "icao_code": "FAUP",
      "airport_name": "Upington Airport"
    },
    {
      "city": "Upland",
      "country": "United States",
      "IATA": "CCB",
      "icao_code": "KCCB",
      "airport_name": "Cable Airport"
    },
    {
      "city": "Uraj",
      "country": "Russia",
      "IATA": "URJ",
      "icao_code": "USHU",
      "airport_name": "Uray Airport"
    },
    {
      "city": "Uralsk",
      "country": "Kazakhstan",
      "IATA": "URA",
      "icao_code": "UARR",
      "airport_name": "Oral Ak Zhol Airport"
    },
    {
      "city": "Uranium City",
      "country": "Canada",
      "IATA": "YBE",
      "icao_code": "CYBE",
      "airport_name": "Uranium City Airport"
    },
    {
      "city": "Urgench",
      "country": "Uzbekistan",
      "IATA": "UGC",
      "icao_code": "UTNU",
      "airport_name": "Urgench International  Airport"
    },
    {
      "city": "Uromiyeh",
      "country": "Iran",
      "IATA": "OMH",
      "icao_code": "OITR",
      "airport_name": "John Wayne Airport"
    },
    {
      "city": "Uruapan",
      "country": "Mexico",
      "IATA": "UPN",
      "icao_code": "MMPN",
      "airport_name": "Uruapan International Airport"
    },
    {
      "city": "Uruguaiana",
      "country": "Brazil",
      "IATA": "URG",
      "icao_code": "SBUG",
      "airport_name": "Ruben Berta International Airport"
    },
    {
      "city": "Urumqi",
      "country": "China",
      "IATA": "URC",
      "icao_code": "ZWWW",
      "airport_name": "Ürümqi Diwopu International Airport"
    },
    {
      "city": "Urzhar",
      "country": "Kazakhstan",
      "IATA": "UZR",
      "airport_name": "Urzhar Airport"
    },
    {
      "city": "Usak",
      "country": "Turkey",
      "IATA": "USQ",
      "icao_code": "LTBO",
      "airport_name": "Uşak Airport"
    },
    {
      "city": "Ushuaia",
      "country": "Argentina",
      "IATA": "USH",
      "icao_code": "SAWH",
      "airport_name": "Ushuaia - Malvinas Argentinas International Airport"
    },
    {
      "city": "Usinsk",
      "country": "Russia",
      "IATA": "USK",
      "icao_code": "UUYS",
      "airport_name": "Usinsk Airport"
    },
    {
      "city": "Ust Ilimsk",
      "country": "Russia",
      "IATA": "UIK",
      "icao_code": "UIBS",
      "airport_name": "Ust-Ilimsk Airport"
    },
    {
      "city": "Ust Kamenogorsk",
      "country": "Kazakhstan",
      "IATA": "UKK",
      "icao_code": "UASK",
      "airport_name": "Oskemen Airport"
    },
    {
      "city": "Ust-Kut",
      "country": "Russia",
      "IATA": "UKX",
      "icao_code": "UITT",
      "airport_name": "Ust-Kut Airport"
    },
    {
      "city": "Ust-Kuyga",
      "country": "Russia",
      "IATA": "UKG",
      "airport_name": "Ust-Kuyga Airport"
    },
    {
      "city": "Ust-Maya",
      "country": "Russia",
      "IATA": "UMS",
      "airport_name": "Ust-Maya Airport"
    },
    {
      "city": "Ust-Nera",
      "country": "Russia",
      "IATA": "USR",
      "airport_name": "Ust-Nera Airport"
    },
    {
      "city": "Ust-Tsylma",
      "country": "Russia",
      "IATA": "UTS",
      "icao_code": "UUYX",
      "airport_name": "Ust-Tsilma Airport"
    },
    {
      "city": "Utila",
      "country": "Honduras",
      "IATA": "UII",
      "icao_code": "MHUT",
      "airport_name": "Utila Airport"
    },
    {
      "city": "Utirik Island",
      "country": "Marshall Islands",
      "IATA": "UTK",
      "airport_name": "Utirik Airport"
    },
    {
      "city": "Utti",
      "country": "Finland",
      "IATA": "UTI",
      "icao_code": "EFUT",
      "airport_name": "Utti Airport"
    },
    {
      "city": "Uummannaq",
      "country": "Greenland",
      "IATA": "UMD",
      "icao_code": "BGUM",
      "airport_name": "Uummannaq Heliport"
    },
    {
      "city": "Uummannaq",
      "country": "Greenland",
      "IATA": "JQA",
      "icao_code": "BGUQ",
      "airport_name": "Qaarsut Airport "
    },
    {
      "city": "Uvalde",
      "country": "United States",
      "IATA": "UVA",
      "airport_name": "Garner Field"
    },
    {
      "city": "Uyo",
      "country": "Nigeria",
      "IATA": "QUO",
      "icao_code": "DNAI",
      "airport_name": "Akwa Ibom Airport"
    },
    {
      "city": "Uyuni",
      "country": "Bolivia",
      "IATA": "UYU",
      "airport_name": "Uyuni Airport"
    },
    {
      "city": "Uzhgorod",
      "country": "Ukraine",
      "IATA": "UDJ",
      "icao_code": "UKLU",
      "airport_name": "Uzhhorod International Airport"
    },
    {
      "city": "Vaasa",
      "country": "Finland",
      "IATA": "VAA",
      "icao_code": "EFVA",
      "airport_name": "Vaasa Airport"
    },
    {
      "city": "Vadsø",
      "country": "Norway",
      "IATA": "VDS",
      "icao_code": "ENVD",
      "airport_name": "Vadsø Airport"
    },
    {
      "city": "Vagar",
      "country": "Faroe Islands",
      "IATA": "FAE",
      "icao_code": "EKVG",
      "airport_name": "Vágar Airport"
    },
    {
      "city": "Vahitahi",
      "country": "French Polynesia",
      "IATA": "VHZ",
      "icao_code": "NTUV",
      "airport_name": "Vahitahi Airport"
    },
    {
      "city": "Vail",
      "country": "United States",
      "IATA": "EGE",
      "icao_code": "KEGE",
      "airport_name": "Eagle County Regional Airport"
    },
    {
      "city": "Val D'or",
      "country": "Canada",
      "IATA": "YVO",
      "icao_code": "CYVO",
      "airport_name": "Val-d'Or Airport"
    },
    {
      "city": "Valdez",
      "country": "United States",
      "IATA": "VDZ",
      "icao_code": "PAVD",
      "airport_name": "Valdez Airport"
    },
    {
      "city": "Valdivia",
      "country": "Chile",
      "IATA": "ZAL",
      "icao_code": "SCVD",
      "airport_name": "Pichoy Airport"
    },
    {
      "city": "Valdosta",
      "country": "United States",
      "IATA": "VLD",
      "icao_code": "KVLD",
      "airport_name": "Valdosta Regional Airport"
    },
    {
      "city": "Valdosta",
      "country": "United States",
      "IATA": "VAD",
      "airport_name": "Moody Air Force Base"
    },
    {
      "city": "Valenca",
      "country": "Brazil",
      "IATA": "VAL",
      "icao_code": "SNVB",
      "airport_name": "Valença Airport"
    },
    {
      "city": "Valence",
      "country": "France",
      "IATA": "VAF",
      "icao_code": "LFLU",
      "airport_name": "Valence-Chabeuil Airport"
    },
    {
      "city": "Valencia",
      "country": "Venezuela",
      "IATA": "VLN",
      "icao_code": "SVVA",
      "airport_name": "Arturo Michelena International Airport"
    },
    {
      "city": "Valencia",
      "country": "Spain",
      "IATA": "VLC",
      "icao_code": "LEVC",
      "airport_name": "Valencia Airport"
    },
    {
      "city": "Valenciennes",
      "country": "France",
      "IATA": "XVS",
      "airport_name": "Valenciennes-Denain Airport"
    },
    {
      "city": "Valentine",
      "country": "United States",
      "IATA": "VTN",
      "icao_code": "KVTN",
      "airport_name": "Miller Field"
    },
    {
      "city": "Valera",
      "country": "Venezuela",
      "IATA": "VLV",
      "icao_code": "SVVL",
      "airport_name": "Colonel Antonio Nicolás Briceño Airport "
    },
    {
      "city": "Valesdir",
      "country": "Vanuatu",
      "IATA": "VLS",
      "icao_code": "NVSV",
      "airport_name": "Valesdir Airport"
    },
    {
      "city": "Valkenburg",
      "country": "Netherlands",
      "IATA": "LID",
      "airport_name": "Valkenburg Airport"
    },
    {
      "city": "Valladolid",
      "country": "Spain",
      "IATA": "VLL",
      "icao_code": "LEVD",
      "airport_name": "Valladolid Airport"
    },
    {
      "city": "Valle De La Pascua",
      "country": "Venezuela",
      "IATA": "VDP",
      "icao_code": "SVVP",
      "airport_name": "Vamdrup Airport"
    },
    {
      "city": "Valledupar",
      "country": "Colombia",
      "IATA": "VUP",
      "icao_code": "SKVP",
      "airport_name": "Alfonso Lopez Pumarejo Airport"
    },
    {
      "city": "Vallenar",
      "country": "Chile",
      "IATA": "VLR",
      "icao_code": "SCLL",
      "airport_name": "Vallenar Airport"
    },
    {
      "city": "Valparaiso",
      "country": "United States",
      "IATA": "VPS",
      "icao_code": "KVPS",
      "airport_name": "Destin–Fort Walton Beach Airport"
    },
    {
      "city": "Valparaiso IN",
      "country": "United States",
      "IATA": "VPZ",
      "icao_code": "KVPZ",
      "airport_name": "Porter County Regional Airport"
    },
    {
      "city": "Van",
      "country": "Turkey",
      "IATA": "VAN",
      "icao_code": "LTCI",
      "airport_name": "Van Ferit Melen Airport"
    },
    {
      "city": "Los Angeles, Van Nuys",
      "country": "United States",
      "IATA": "VNY",
      "icao_code": "KVNY",
      "airport_name": "Van Nuys Airport"
    },
    {
      "city": "Vancouver",
      "country": "Canada",
      "IATA": "CXH",
      "icao_code": "CYHC",
      "airport_name": "Vancouver Harbour Flight Centre"
    },
    {
      "city": "Vancouver",
      "country": "Canada",
      "IATA": "YVR",
      "icao_code": "CYVR",
      "airport_name": "Vancouver International Airport"
    },
    {
      "city": "Vanimo",
      "country": "Papua New Guinea",
      "IATA": "VAI",
      "icao_code": "AYVN",
      "airport_name": "Vanimo Airport"
    },
    {
      "city": "Vannes",
      "country": "France",
      "IATA": "VNE",
      "icao_code": "LFRV",
      "airport_name": "Meucon Airport"
    },
    {
      "city": "Vanua Balavu",
      "country": "Fiji",
      "IATA": "VBV",
      "icao_code": "NFVB",
      "airport_name": "Vanua Balavu Airport"
    },
    {
      "city": "Varadero",
      "country": "Cuba",
      "IATA": "VRA",
      "icao_code": "MUVR",
      "airport_name": "Juan Gualberto Gómez Airport"
    },
    {
      "city": "Varanasi",
      "country": "India",
      "IATA": "VNS",
      "icao_code": "VIBN",
      "airport_name": "Lal Bahadur Shastri International Airport"
    },
    {
      "city": "Varginha",
      "country": "Brazil",
      "IATA": "VAG",
      "icao_code": "SBVG",
      "airport_name": "Major-Brigadeiro Trompowsky Airport"
    },
    {
      "city": "Varkaus",
      "country": "Finland",
      "IATA": "VRK",
      "icao_code": "EFVR",
      "airport_name": "Varkaus Airport"
    },
    {
      "city": "Varna",
      "country": "Bulgaria",
      "IATA": "VAR",
      "icao_code": "LBWN",
      "airport_name": "Varna Airport"
    },
    {
      "city": "Vasteras",
      "country": "Sweden",
      "IATA": "VST",
      "icao_code": "ESOW",
      "airport_name": "Stockholm-Västerås Airport"
    },
    {
      "city": "Vava'u",
      "country": "Tonga",
      "IATA": "VAV",
      "icao_code": "NFTV",
      "airport_name": "Vavaʻu International Airport"
    },
    {
      "city": "Vaxjo",
      "country": "Sweden",
      "IATA": "VXO",
      "icao_code": "ESMX",
      "airport_name": "Växjö Kronoberg Airport"
    },
    {
      "city": "Velikiye Luki",
      "country": "Russia",
      "IATA": "VLU",
      "icao_code": "ULOL",
      "airport_name": "Velikiye Luki Airport"
    },
    {
      "city": "Veliky Ustyug",
      "country": "Russia",
      "IATA": "VUS",
      "icao_code": "ULWU",
      "airport_name": "Velikiy Ustyug Airport"
    },
    {
      "city": "Venetie",
      "country": "United States",
      "IATA": "VEE",
      "icao_code": "PAVE",
      "airport_name": "Venetie Airport"
    },
    {
      "city": "Venice",
      "country": "United States",
      "IATA": "VNC",
      "icao_code": "KVNC",
      "airport_name": "Venice Municipal Airport"
    },
    {
      "city": "Venice",
      "country": "Italy",
      "IATA": "VCE",
      "icao_code": "LIPZ",
      "airport_name": "Venice Marco Polo Airport"
    },
    {
      "city": "Ventspils",
      "country": "Latvia",
      "IATA": "VNT",
      "icao_code": "EVVA",
      "airport_name": "Ventspils International Airport"
    },
    {
      "city": "Vera Cruz",
      "country": "Mexico",
      "IATA": "VER",
      "icao_code": "MMVR",
      "airport_name": "General Heriberto Jara International Airport"
    },
    {
      "city": "Verdun",
      "country": "France",
      "IATA": "MVV",
      "airport_name": "Megève Airport"
    },
    {
      "city": "Verkhnevilyuysk",
      "country": "Russia",
      "IATA": "VHV",
      "airport_name": "Verkhnevilyuysk Airport"
    },
    {
      "city": "Vermillion",
      "country": "Canada",
      "IATA": "YVG",
      "icao_code": "CYVG",
      "airport_name": "Vermilion Airport"
    },
    {
      "city": "Vernal",
      "country": "United States",
      "IATA": "VEL",
      "icao_code": "KVEL",
      "airport_name": "Vernal Regional Airport"
    },
    {
      "city": "Vernon",
      "country": "Canada",
      "IATA": "YVE",
      "icao_code": "CYVK",
      "airport_name": "Vernon Regional Airport"
    },
    {
      "city": "Vero Beach",
      "country": "United States",
      "IATA": "VRB",
      "icao_code": "KVRB",
      "airport_name": "Vero Beach Regional Airport"
    },
    {
      "city": "Vesivehmaa",
      "country": "Finland",
      "IATA": "QLF",
      "airport_name": "Lahti Vesivehmaa Airport"
    },
    {
      "city": "Vestmannaeyjar",
      "country": "Iceland",
      "IATA": "VEY",
      "icao_code": "BIVM",
      "airport_name": "Vestmannaeyjar Airport"
    },
    {
      "city": "Vicenza",
      "country": "Italy",
      "IATA": "VIC",
      "icao_code": "LIPT",
      "airport_name": "Vicenza Trissino Airport"
    },
    {
      "city": "Vichy",
      "country": "France",
      "IATA": "VHY",
      "icao_code": "LFLV",
      "airport_name": "Vichy-Charmeil Airport"
    },
    {
      "city": "Victoria",
      "country": "Chile",
      "IATA": "ZIC",
      "icao_code": "SCTO",
      "airport_name": "Victoria Airport"
    },
    {
      "city": "Victoria",
      "country": "United States",
      "IATA": "VCT",
      "icao_code": "KVCT",
      "airport_name": "Victoria Regional Airport"
    },
    {
      "city": "Victoria",
      "country": "Canada",
      "IATA": "YWH",
      "icao_code": "CYWH",
      "airport_name": "Victoria Inner Harbour Airport"
    },
    {
      "city": "Victoria",
      "country": "Canada",
      "IATA": "YYJ",
      "icao_code": "CYYJ",
      "airport_name": "Victoria International Airport"
    },
    {
      "city": "Victoria Falls",
      "country": "Zimbabwe",
      "IATA": "VFA",
      "icao_code": "FVFA",
      "airport_name": "Victoria Falls Airport"
    },
    {
      "city": "Victoria River Downs",
      "country": "Australia",
      "IATA": "VCD",
      "icao_code": "YVRD",
      "airport_name": "Victoria River Downs Airport"
    },
    {
      "city": "Victorville",
      "country": "United States",
      "IATA": "VCV",
      "icao_code": "KVCV",
      "airport_name": "Southern California Logistics Airport"
    },
    {
      "city": "Vidalia",
      "country": "United States",
      "IATA": "VDI",
      "airport_name": "Vidalia Regional Airport"
    },
    {
      "city": "Viedma",
      "country": "Argentina",
      "IATA": "VDM",
      "icao_code": "SAVV",
      "airport_name": "Gobernador Edgardo Castello Airport"
    },
    {
      "city": "Vienna",
      "country": "Austria",
      "IATA": "VIE",
      "icao_code": "LOWW",
      "airport_name": "Vienna International Airport"
    },
    {
      "city": "Vientiane",
      "country": "Laos",
      "IATA": "VTE",
      "icao_code": "VLVT",
      "airport_name": "Wattay International Airport"
    },
    {
      "city": "Vieques Island",
      "country": "Puerto Rico",
      "IATA": "VQS",
      "airport_name": "Antonio Rivera Rodríguez Airport"
    },
    {
      "city": "Vigo",
      "country": "Spain",
      "IATA": "VGO",
      "icao_code": "LEVX",
      "airport_name": "Vigo-Peinador Airport"
    },
    {
      "city": "Vijayawada",
      "country": "India",
      "IATA": "VGA",
      "icao_code": "VOBZ",
      "airport_name": "Vijayawada Airport"
    },
    {
      "city": "Vila Bela da Santissima Trindade",
      "country": "Brazil",
      "IATA": "MTG",
      "airport_name": "Vila Bela da Santissima Trindade Airport"
    },
    {
      "city": "Vila Real",
      "country": "Portugal",
      "IATA": "VRL",
      "icao_code": "LPVR",
      "airport_name": "Vila Real Airport"
    },
    {
      "city": "Vila Rica",
      "country": "Brazil",
      "IATA": "VLP",
      "icao_code": "SWVC",
      "airport_name": "Vila Rica Airport"
    },
    {
      "city": "Vilankulu",
      "country": "Mozambique",
      "IATA": "VNX",
      "icao_code": "FQVL",
      "airport_name": "Vilankulo Airport"
    },
    {
      "city": "Vilhelmina",
      "country": "Sweden",
      "IATA": "VHM",
      "icao_code": "ESNV",
      "airport_name": "Vilhelmina Airport"
    },
    {
      "city": "Vilhena",
      "country": "Brazil",
      "IATA": "BVH",
      "icao_code": "SBVH",
      "airport_name": "Vilhena Airport"
    },
    {
      "city": "Villa Dolores",
      "country": "Argentina",
      "IATA": "VDR",
      "icao_code": "SAOD",
      "airport_name": "Villa Dolores Airport"
    },
    {
      "city": "Villa Garzon",
      "country": "Colombia",
      "IATA": "VGZ",
      "icao_code": "SKVG",
      "airport_name": "Villa Garzón Airport"
    },
    {
      "city": "Villa Gesell",
      "country": "Argentina",
      "IATA": "VLG",
      "icao_code": "SAZV",
      "airport_name": "Villa Gesell Airport"
    },
    {
      "city": "Villa Montes",
      "country": "Bolivia",
      "IATA": "VLM",
      "icao_code": "SLVM",
      "airport_name": "Lieutenant Colonel Rafael Pabón Airport"
    },
    {
      "city": "Villa Reynolds",
      "country": "Argentina",
      "IATA": "VME",
      "icao_code": "SAOR",
      "airport_name": "Villa Mercedes Airport"
    },
    {
      "city": "Villacoublay",
      "country": "France",
      "IATA": "VIY",
      "airport_name": "Vélizy – Villacoublay Air Base"
    },
    {
      "city": "Villafranca",
      "country": "Italy",
      "IATA": "VRN",
      "icao_code": "LIPX",
      "airport_name": "Verona Villafranca Airport"
    },
    {
      "city": "Villahermosa",
      "country": "Mexico",
      "IATA": "VSA",
      "icao_code": "MMVA",
      "airport_name": "Carlos Rovirosa Pérez International Airport"
    },
    {
      "city": "Villavicencio",
      "country": "Colombia",
      "IATA": "VVC",
      "icao_code": "SKVV",
      "airport_name": "La Vanguardia Airport"
    },
    {
      "city": "Vilnius",
      "country": "Lithuania",
      "IATA": "VNO",
      "icao_code": "EYVI",
      "airport_name": "Vilnius International Airport"
    },
    {
      "city": "Vilyuisk",
      "country": "Russia",
      "IATA": "VYI",
      "airport_name": "Vilyuisk Airport"
    },
    {
      "city": "Vina del Mar",
      "country": "Chile",
      "IATA": "KNA",
      "icao_code": "SCVM",
      "airport_name": "Viña del Mar Airport"
    },
    {
      "city": "Vineyard Haven MA",
      "country": "United States",
      "IATA": "MVY",
      "icao_code": "KMVY",
      "airport_name": "Martha's Vineyard Airport"
    },
    {
      "city": "Vinh",
      "country": "Vietnam",
      "IATA": "VII",
      "icao_code": "VVVH",
      "airport_name": "Vinh Airport"
    },
    {
      "city": "Vinnitsa",
      "country": "Ukraine",
      "IATA": "VIN",
      "icao_code": "UKWW",
      "airport_name": "Havryshivka Vinnytsia International Airport"
    },
    {
      "city": "Virac",
      "country": "Philippines",
      "IATA": "VRC",
      "icao_code": "RPUV",
      "airport_name": "Virac Airport"
    },
    {
      "city": "Visalia",
      "country": "United States",
      "IATA": "VIS",
      "icao_code": "KVIS",
      "airport_name": "Visalia Municipal Airport"
    },
    {
      "city": "Visby",
      "country": "Sweden",
      "IATA": "VBY",
      "icao_code": "ESSV",
      "airport_name": "Visby Airport"
    },
    {
      "city": "Viseu",
      "country": "Portugal",
      "IATA": "VSE",
      "icao_code": "LPVZ",
      "airport_name": "Viseu Airport"
    },
    {
      "city": "Vishakhapatnam",
      "country": "India",
      "IATA": "VTZ",
      "icao_code": "VEVZ",
      "airport_name": "Visakhapatnam Airport"
    },
    {
      "city": "Vitebsk",
      "country": "Belarus",
      "IATA": "VTB",
      "icao_code": "UMII",
      "airport_name": "Vitebsk Vostochny Airport"
    },
    {
      "city": "Vitoria",
      "country": "Brazil",
      "IATA": "VIX",
      "icao_code": "SBVT",
      "airport_name": "Victoria - Eurico de Aguiar Salles Airport"
    },
    {
      "city": "Vitoria",
      "country": "Spain",
      "IATA": "VIT",
      "icao_code": "LEVT",
      "airport_name": "Vitoria Airport"
    },
    {
      "city": "Vitória Da Conquista",
      "country": "Brazil",
      "IATA": "VDC",
      "icao_code": "SBQV",
      "airport_name": "Vitória da Conquista Airport"
    },
    {
      "city": "Vladivostok",
      "country": "Russia",
      "IATA": "VVO",
      "icao_code": "UHWW",
      "airport_name": "Vladivostok International Airport "
    },
    {
      "city": "Vodochody",
      "country": "Czech Republic",
      "IATA": "VOD",
      "airport_name": "Vodochody Airport"
    },
    {
      "city": "Vohemar",
      "country": "Madagascar",
      "IATA": "VOH",
      "icao_code": "FMNV",
      "airport_name": "Vohimarina Airport"
    },
    {
      "city": "Volgograd",
      "country": "Russia",
      "IATA": "VOG",
      "icao_code": "URWW",
      "airport_name": "Volgograd International Airport"
    },
    {
      "city": "Vologda",
      "country": "Russia",
      "IATA": "VGD",
      "icao_code": "ULWW",
      "airport_name": "Vologda Airport"
    },
    {
      "city": "Vopnafjörður",
      "country": "Iceland",
      "IATA": "VPN",
      "icao_code": "BIVO",
      "airport_name": "Vopnafjörður Airport"
    },
    {
      "city": "Vorkuta",
      "country": "Russia",
      "IATA": "VKT",
      "icao_code": "UUYW",
      "airport_name": "Vorkuta Airport"
    },
    {
      "city": "Voronezh",
      "country": "Russia",
      "IATA": "VOZ",
      "icao_code": "UUOO",
      "airport_name": "Voronezh-Chertovitskoye International Airport"
    },
    {
      "city": "Vredendal",
      "country": "South Africa",
      "IATA": "VRE",
      "icao_code": "FAVR",
      "airport_name": "Vredendal Airport"
    },
    {
      "city": "Vryburg",
      "country": "South Africa",
      "IATA": "VRU",
      "icao_code": "FAVB",
      "airport_name": "Vryburg Airport"
    },
    {
      "city": "Vunisea",
      "country": "Fiji",
      "IATA": "KDV",
      "icao_code": "NFKD",
      "airport_name": "Vunisea Airport "
    },
    {
      "city": "Værøy",
      "country": "Norway",
      "IATA": "VRY",
      "airport_name": "Værøy Heliport"
    },
    {
      "city": "WEST BEND",
      "country": "United States",
      "IATA": "ETB",
      "icao_code": "KETB",
      "airport_name": "West Bend Municipal Airport"
    },
    {
      "city": "Waala",
      "country": "New Caledonia",
      "IATA": "BMY",
      "icao_code": "NWWC",
      "airport_name": "Île Art – Waala Airport "
    },
    {
      "city": "Wabush",
      "country": "Canada",
      "IATA": "YWK",
      "icao_code": "CYWK",
      "airport_name": "Wabush Airport"
    },
    {
      "city": "Waco",
      "country": "United States",
      "IATA": "ACT",
      "icao_code": "KACT",
      "airport_name": "Waco Regional Airport"
    },
    {
      "city": "Waco",
      "country": "United States",
      "IATA": "CNW",
      "icao_code": "KCNW",
      "airport_name": "TSTC Waco Airport"
    },
    {
      "city": "Waddington",
      "country": "United Kingdom",
      "IATA": "WTN",
      "icao_code": "EGXW",
      "airport_name": "RAF Waddington"
    },
    {
      "city": "Wadeye",
      "country": "Australia",
      "IATA": "PKT",
      "icao_code": "YPKT",
      "airport_name": "Port Keats Airfield"
    },
    {
      "city": "Wadi Halfa",
      "country": "Sudan",
      "IATA": "WHF",
      "icao_code": "HSSW",
      "airport_name": "Wadi Halfa Airport"
    },
    {
      "city": "Wadi-al-dawasir",
      "country": "Saudi Arabia",
      "IATA": "WAE",
      "icao_code": "OEWD",
      "airport_name": "Wadi al-Dawasir Airport"
    },
    {
      "city": "Wageningen",
      "country": "Suriname",
      "IATA": "AGI",
      "icao_code": "SMWA",
      "airport_name": "Wageningen Airstrip"
    },
    {
      "city": "Wagga Wagga",
      "country": "Australia",
      "IATA": "WGA",
      "icao_code": "YSWG",
      "airport_name": "Wagga Wagga Airport"
    },
    {
      "city": "Wahiawa",
      "country": "United States",
      "IATA": "HHI",
      "airport_name": "Wheeler Army Airfield"
    },
    {
      "city": "Waiheke Island",
      "country": "New Zealand",
      "IATA": "WIK",
      "icao_code": "NZKE",
      "airport_name": "Waiheke Island Aerodrome"
    },
    {
      "city": "Waikabubak-Sumba Island",
      "country": "Indonesia",
      "IATA": "TMC",
      "airport_name": "Tambolaka Airport"
    },
    {
      "city": "Waikoloa Village",
      "country": "United States",
      "IATA": "WKL",
      "airport_name": "Waikoloa Heliport"
    },
    {
      "city": "Waingapu",
      "country": "Indonesia",
      "IATA": "WGP",
      "icao_code": "WRRW",
      "airport_name": "Mau Hau Airport"
    },
    {
      "city": "Wainwright",
      "country": "United States",
      "IATA": "AIN",
      "icao_code": "PAWT",
      "airport_name": "Wainwright Airport "
    },
    {
      "city": "Wairoa",
      "country": "New Zealand",
      "IATA": "WIR",
      "airport_name": "Wairoa Airport"
    },
    {
      "city": "Wajima",
      "country": "Japan",
      "IATA": "NTQ",
      "icao_code": "RJNW",
      "airport_name": "Noto Airport"
    },
    {
      "city": "Wajir",
      "country": "Kenya",
      "IATA": "WJR",
      "icao_code": "HKWJ",
      "airport_name": "Wajir Airport"
    },
    {
      "city": "Wake island",
      "country": "Wake Island",
      "IATA": "AWK",
      "icao_code": "PWAK",
      "airport_name": "Wake Island Airfield"
    },
    {
      "city": "Wakkanai",
      "country": "Japan",
      "IATA": "WKJ",
      "icao_code": "RJCW",
      "airport_name": "Wakkanai Airport"
    },
    {
      "city": "Walaha",
      "country": "Vanuatu",
      "IATA": "WLH",
      "icao_code": "NVSW",
      "airport_name": "Walaha Airport"
    },
    {
      "city": "Wales",
      "country": "United States",
      "IATA": "WAA",
      "icao_code": "PAIW",
      "airport_name": "Wales Airport"
    },
    {
      "city": "Walgett",
      "country": "Australia",
      "IATA": "WGE",
      "icao_code": "YWLG",
      "airport_name": "Walgett Airport"
    },
    {
      "city": "Walker's Cay",
      "country": "Bahamas",
      "IATA": "WKR",
      "airport_name": "Abaco I Walker C Airport"
    },
    {
      "city": "Walla Walla",
      "country": "United States",
      "IATA": "ALW",
      "icao_code": "KALW",
      "airport_name": "Walla Walla Regional Airport"
    },
    {
      "city": "Wallis",
      "country": "Wallis and Futuna",
      "IATA": "WLS",
      "icao_code": "NLWW",
      "airport_name": "Hihifo Airport"
    },
    {
      "city": "Wallops Island",
      "country": "United States",
      "IATA": "WAL",
      "airport_name": "Wallops Flight Facility Airport"
    },
    {
      "city": "Walvis Bay",
      "country": "Namibia",
      "IATA": "WVB",
      "icao_code": "FYWB",
      "airport_name": "Walvis Bay Airport"
    },
    {
      "city": "Wamena",
      "country": "Indonesia",
      "IATA": "WMX",
      "icao_code": "WAJW",
      "airport_name": "Wamena Airport"
    },
    {
      "city": "Wana",
      "country": "Pakistan",
      "IATA": "WAF",
      "airport_name": "Wana Airport"
    },
    {
      "city": "Wanaka",
      "country": "New Zealand",
      "IATA": "WKA",
      "icao_code": "NZWF",
      "airport_name": "Wanaka Airport"
    },
    {
      "city": "Wang An",
      "country": "Taiwan",
      "IATA": "WOT",
      "icao_code": "RCWA",
      "airport_name": "Wangan Airport"
    },
    {
      "city": "Wanganui",
      "country": "New Zealand",
      "IATA": "WAG",
      "icao_code": "NZWU",
      "airport_name": "Wanganui Airport"
    },
    {
      "city": "Wangerooge",
      "country": "Germany",
      "IATA": "AGE",
      "airport_name": "Wangerooge Airport"
    },
    {
      "city": "Wanxian",
      "country": "China",
      "IATA": "WXN",
      "icao_code": "ZUWX",
      "airport_name": "Wanzhou Wuqiao Airport "
    },
    {
      "city": "Wapenamanda",
      "country": "Papua New Guinea",
      "IATA": "WBM",
      "icao_code": "AYWD",
      "airport_name": "Wapenamanda Airport"
    },
    {
      "city": "Waris-Papua Island",
      "country": "Indonesia",
      "IATA": "WAR",
      "icao_code": "WAJR",
      "airport_name": "Waris Airport"
    },
    {
      "city": "Warrnambool",
      "country": "Australia",
      "IATA": "WMB",
      "icao_code": "YWBL",
      "airport_name": "Warrnambool Airport"
    },
    {
      "city": "Warsaw",
      "country": "Poland",
      "IATA": "WMI",
      "icao_code": "EPMO",
      "airport_name": "Warsaw–ModlIn Mazovia Airport "
    },
    {
      "city": "Warsaw",
      "country": "Poland",
      "IATA": "WAW",
      "icao_code": "EPWA",
      "airport_name": "Warsaw Chopin Airport"
    },
    {
      "city": "Warton",
      "country": "United Kingdom",
      "IATA": "WRT",
      "icao_code": "EGNO",
      "airport_name": "Warton Aerodrome"
    },
    {
      "city": "Washabo",
      "country": "Suriname",
      "IATA": "WSO",
      "airport_name": "Washabo Airport"
    },
    {
      "city": "Washington",
      "country": "United States",
      "IATA": "OCW",
      "airport_name": "Warren Field Airport"
    },
    {
      "city": "Washington",
      "country": "United States",
      "IATA": "IAD",
      "icao_code": "KIAD",
      "airport_name": "Washington Dulles International Airport"
    },
    {
      "city": "Washington",
      "country": "United States",
      "IATA": "DCA",
      "icao_code": "KDCA",
      "airport_name": "Ronald Reagan Washington National Airport"
    },
    {
      "city": "Washington County",
      "country": "United States",
      "IATA": "WST",
      "icao_code": "KWST",
      "airport_name": "Westerly State Airport"
    },
    {
      "city": "Wasilla",
      "country": "United States",
      "IATA": "WWA",
      "airport_name": "Wasilla Airport"
    },
    {
      "city": "Wasior",
      "country": "Indonesia",
      "IATA": "WSR",
      "icao_code": "WASW",
      "airport_name": "Wasior Airport"
    },
    {
      "city": "Waskaganish",
      "country": "Canada",
      "IATA": "YKQ",
      "icao_code": "CYKQ",
      "airport_name": "Waskaganish Airport"
    },
    {
      "city": "Waspam",
      "country": "Nicaragua",
      "IATA": "WSP",
      "icao_code": "MNWP",
      "airport_name": "Waspam Airport"
    },
    {
      "city": "Wassau",
      "country": "United States",
      "IATA": "CWA",
      "icao_code": "KCWA",
      "airport_name": "Central Wisconsin Airport"
    },
    {
      "city": "Waterford",
      "country": "Ireland",
      "IATA": "WAT",
      "icao_code": "EIWF",
      "airport_name": "Waterford Airport"
    },
    {
      "city": "Waterkloof",
      "country": "South Africa",
      "IATA": "WKF",
      "airport_name": "Waterkloof Air Force Base"
    },
    {
      "city": "Waterloo",
      "country": "United States",
      "IATA": "ALO",
      "icao_code": "KALO",
      "airport_name": "Waterloo Regional Airport"
    },
    {
      "city": "Waterloo",
      "country": "Canada",
      "IATA": "YKF",
      "icao_code": "CYKF",
      "airport_name": "Region of Waterloo International Airport"
    },
    {
      "city": "Watertown",
      "country": "United States",
      "IATA": "ATY",
      "icao_code": "KATY",
      "airport_name": "Watertown Regional Airport"
    },
    {
      "city": "Watertown",
      "country": "United States",
      "IATA": "ART",
      "icao_code": "KART",
      "airport_name": "Watertown International Airport"
    },
    {
      "city": "Watson Lake",
      "country": "Canada",
      "IATA": "YQH",
      "icao_code": "CYQH",
      "airport_name": "Watson Lake Airport"
    },
    {
      "city": "Watsonville",
      "country": "United States",
      "IATA": "WVI",
      "icao_code": "KWVI",
      "airport_name": "Watsonville Municipal Airport"
    },
    {
      "city": "Wau",
      "country": "Sudan",
      "IATA": "WUU",
      "icao_code": "HSWW",
      "airport_name": "Wau Airport"
    },
    {
      "city": "Wausau",
      "country": "United States",
      "IATA": "AUW",
      "airport_name": "Wausau Downtown Airport"
    },
    {
      "city": "Wawa",
      "country": "Canada",
      "IATA": "YXZ",
      "icao_code": "CYXZ",
      "airport_name": "Wawa Airport"
    },
    {
      "city": "Waycross",
      "country": "United States",
      "IATA": "AYS",
      "icao_code": "KAYS",
      "airport_name": "Waycross-Ware County Airport"
    },
    {
      "city": "Webequie",
      "country": "Canada",
      "IATA": "YWP",
      "icao_code": "CYWP",
      "airport_name": "Webequie Airport"
    },
    {
      "city": "Weeze",
      "country": "Germany",
      "IATA": "NRN",
      "icao_code": "EDLV",
      "airport_name": "Weeze Airport "
    },
    {
      "city": "Weifang",
      "country": "China",
      "IATA": "WEF",
      "icao_code": "ZSWF",
      "airport_name": "Weifang Airport"
    },
    {
      "city": "Weihai",
      "country": "China",
      "IATA": "WEH",
      "icao_code": "ZSWH",
      "airport_name": "Weihai Dashuibo Airport"
    },
    {
      "city": "Weipa",
      "country": "Australia",
      "IATA": "WEI",
      "icao_code": "YBWP",
      "airport_name": "Weipa Airport"
    },
    {
      "city": "Wejh",
      "country": "Saudi Arabia",
      "IATA": "EJH",
      "icao_code": "OEWJ",
      "airport_name": "Al Wajh Domestic Airport"
    },
    {
      "city": "Wekweeti",
      "country": "Canada",
      "IATA": "YFJ",
      "icao_code": "CFJ2",
      "airport_name": "Wekweeti Airport"
    },
    {
      "city": "Wellington",
      "country": "New Zealand",
      "IATA": "WLG",
      "icao_code": "NZWN",
      "airport_name": "Wellington International Airport"
    },
    {
      "city": "Wemindji",
      "country": "Canada",
      "IATA": "YNC",
      "icao_code": "CYNC",
      "airport_name": "Wemindji Airport"
    },
    {
      "city": "Wenatchee",
      "country": "United States",
      "IATA": "EAT",
      "icao_code": "KEAT",
      "airport_name": "Pangborn Memorial Airport"
    },
    {
      "city": "Wendover",
      "country": "United States",
      "IATA": "ENV",
      "icao_code": "KENV",
      "airport_name": "Wendover Airport"
    },
    {
      "city": "Wenzhou",
      "country": "China",
      "IATA": "WNZ",
      "icao_code": "ZSWZ",
      "airport_name": "Wenzhou Longwan International Airport"
    },
    {
      "city": "West Chicago",
      "country": "United States",
      "IATA": "DPA",
      "icao_code": "KDPA",
      "airport_name": "Dupage Airport"
    },
    {
      "city": "South Florida, West Palm Beach",
      "country": "United States",
      "IATA": "PBI",
      "icao_code": "KPBI",
      "airport_name": "Palm Beach International Airport"
    },
    {
      "city": "West Palm Beach",
      "country": "United States",
      "IATA": "LNA",
      "icao_code": "KLNA",
      "airport_name": "Palm Beach County Park Airport"
    },
    {
      "city": "West Tinian",
      "country": "Northern Mariana Islands",
      "IATA": "TIQ",
      "icao_code": "PGWT",
      "airport_name": "Tinian International Airport"
    },
    {
      "city": "West Wyalong",
      "country": "Australia",
      "IATA": "WWY",
      "icao_code": "YWWL",
      "airport_name": "West Wyalong Airport"
    },
    {
      "city": "West Yellowstone",
      "country": "United States",
      "IATA": "WYS",
      "icao_code": "KWYS",
      "airport_name": "Yellowstone Airport"
    },
    {
      "city": "Westerland",
      "country": "Germany",
      "IATA": "GWT",
      "icao_code": "EDXW",
      "airport_name": "Sylt Airport "
    },
    {
      "city": "Westfield",
      "country": "United States",
      "IATA": "BAF",
      "icao_code": "KBAF",
      "airport_name": "Westfield-Barnes Regional Airport"
    },
    {
      "city": "Westport",
      "country": "New Zealand",
      "IATA": "WSZ",
      "icao_code": "NZWS",
      "airport_name": "Westport Airport"
    },
    {
      "city": "Westray",
      "country": "United Kingdom",
      "IATA": "WRY",
      "icao_code": "EGEW",
      "airport_name": "Westray Airport"
    },
    {
      "city": "Wewak",
      "country": "Papua New Guinea",
      "IATA": "WWK",
      "icao_code": "AYWK",
      "airport_name": "Wewak International Airport"
    },
    {
      "city": "Weyers Cave",
      "country": "United States",
      "IATA": "SHD",
      "icao_code": "KSHD",
      "airport_name": "Shenandoah Valley Regional Airport"
    },
    {
      "city": "Whakatane",
      "country": "New Zealand",
      "IATA": "WHK",
      "icao_code": "NZWK",
      "airport_name": "Whakatane Airport"
    },
    {
      "city": "Whale Cove",
      "country": "Canada",
      "IATA": "YXN",
      "icao_code": "CYXN",
      "airport_name": "Whale Cove Airport"
    },
    {
      "city": "Whangarei",
      "country": "New Zealand",
      "IATA": "WRE",
      "icao_code": "NZWR",
      "airport_name": "Whangarei Airport"
    },
    {
      "city": "Whatì",
      "country": "Canada",
      "IATA": "YLE",
      "icao_code": "CEM3",
      "airport_name": "Whatì Airport"
    },
    {
      "city": "Wheeling",
      "country": "United States",
      "IATA": "HLG",
      "airport_name": "Wheeling Ohio County Airport"
    },
    {
      "city": "Whidbey Island",
      "country": "United States",
      "IATA": "NUW",
      "airport_name": "Naval Air Station Whidbey Island"
    },
    {
      "city": "Whistler",
      "country": "Canada",
      "IATA": "YWS",
      "airport_name": "Whistler/Green Lake Water Aerodrome"
    },
    {
      "city": "White Mountain",
      "country": "United States",
      "IATA": "WMO",
      "icao_code": "PAWM",
      "airport_name": "White Mountain Airport"
    },
    {
      "city": "White Sands",
      "country": "United States",
      "IATA": "WSD",
      "airport_name": "Condron Army Airfield"
    },
    {
      "city": "Whitecourt",
      "country": "Canada",
      "IATA": "YZU",
      "icao_code": "CYZU",
      "airport_name": "Whitecourt Airport"
    },
    {
      "city": "Whitehorse",
      "country": "Canada",
      "IATA": "YXY",
      "icao_code": "CYXY",
      "airport_name": "Erik Nielsen Whitehorse International Airport"
    },
    {
      "city": "Whitianga",
      "country": "New Zealand",
      "IATA": "WTZ",
      "airport_name": "Whitianga Aerodrome"
    },
    {
      "city": "Whyalla",
      "country": "Australia",
      "IATA": "WYA",
      "icao_code": "YWHA",
      "airport_name": "Whyalla Airport"
    },
    {
      "city": "Wiarton",
      "country": "Canada",
      "IATA": "YVV",
      "icao_code": "CYVV",
      "airport_name": "Wiarton Airport"
    },
    {
      "city": "Wichita",
      "country": "United States",
      "IATA": "BEC",
      "icao_code": "KBEC",
      "airport_name": "Beech Factory Airport"
    },
    {
      "city": "Wichita",
      "country": "United States",
      "IATA": "IAB",
      "airport_name": "McConnell Air Force Base"
    },
    {
      "city": "Wichita",
      "country": "United States",
      "IATA": "ICT",
      "icao_code": "KICT",
      "airport_name": "Wichita Dwight D. Eisenhower National Airport"
    },
    {
      "city": "Wichita Falls",
      "country": "United States",
      "IATA": "SPS",
      "icao_code": "KSPS",
      "airport_name": " Wichita Falls Regional Airport"
    },
    {
      "city": "Wick",
      "country": "United Kingdom",
      "IATA": "WIC",
      "icao_code": "EGPC",
      "airport_name": "Wick John O' Groats Airport "
    },
    {
      "city": "Wide Awake",
      "country": "Saint Helena",
      "IATA": "ASI",
      "airport_name": "RAF Ascension"
    },
    {
      "city": "Wiesbaden",
      "country": "Germany",
      "IATA": "WIE",
      "icao_code": "ETOU",
      "airport_name": "Lucius D. Clay Kaserne "
    },
    {
      "city": "Wilcannia",
      "country": "Australia",
      "IATA": "WIO",
      "icao_code": "YWCA",
      "airport_name": "Wilcannia Airport"
    },
    {
      "city": "Wildwood",
      "country": "United States",
      "IATA": "WWD",
      "icao_code": "KWWD",
      "airport_name": "Cape May Airport"
    },
    {
      "city": "Wilhelmshaven",
      "country": "Germany",
      "IATA": "WVN",
      "airport_name": "JadeWeser Airport"
    },
    {
      "city": "Wilkes-Barre",
      "country": "United States",
      "IATA": "WBW",
      "icao_code": "KWBW",
      "airport_name": "Wilkes-Barre Wyoming Valley Airport"
    },
    {
      "city": "Willemstad",
      "country": "Netherlands Antilles",
      "IATA": "CUR",
      "icao_code": "TNCC",
      "airport_name": "Curaçao International Airport"
    },
    {
      "city": "Williams Harbour",
      "country": "Canada",
      "IATA": "YWM",
      "icao_code": "CCA6",
      "airport_name": "Williams Harbour Airport"
    },
    {
      "city": "Williams Lake",
      "country": "Canada",
      "IATA": "YWL",
      "icao_code": "CYWL",
      "airport_name": "Williams Lake Regional Airport"
    },
    {
      "city": "Williamsport",
      "country": "United States",
      "IATA": "IPT",
      "icao_code": "KIPT",
      "airport_name": "Williamsport Regional Airport"
    },
    {
      "city": "Williston",
      "country": "United States",
      "IATA": "ISN",
      "icao_code": "KISN",
      "airport_name": "Sloulin Field International Airport"
    },
    {
      "city": "Willoughby",
      "country": "United States",
      "IATA": "LNN",
      "airport_name": "Lost Nation Airport "
    },
    {
      "city": "Willow Grove",
      "country": "United States",
      "IATA": "NXX",
      "icao_code": "KNXX",
      "airport_name": "Naval Air Station Joint Reserve Base Willow Grove"
    },
    {
      "city": "Wilmington",
      "country": "United States",
      "IATA": "ILN",
      "icao_code": "KILN",
      "airport_name": "Airborne Airpark"
    },
    {
      "city": "Wilmington",
      "country": "United States",
      "IATA": "ILG",
      "icao_code": "KILG",
      "airport_name": "Wilmington Airport Delaware"
    },
    {
      "city": "Wilmington",
      "country": "United States",
      "IATA": "ILM",
      "icao_code": "KILM",
      "airport_name": "Wilmington International Airport"
    },
    {
      "city": "Wiluna",
      "country": "Australia",
      "IATA": "WUN",
      "icao_code": "YWLU",
      "airport_name": "Wiluna Airport"
    },
    {
      "city": "Winder",
      "country": "United States",
      "IATA": "WDR",
      "icao_code": "KWDR",
      "airport_name": "Barrow County Airport"
    },
    {
      "city": "Windhoek",
      "country": "Namibia",
      "IATA": "ERS",
      "icao_code": "FYWE",
      "airport_name": "Eros Airport"
    },
    {
      "city": "Windhoek",
      "country": "Namibia",
      "IATA": "WDH",
      "icao_code": "FYWH",
      "airport_name": "Hosea Kutako International Airport"
    },
    {
      "city": "Windorah",
      "country": "Australia",
      "IATA": "WNR",
      "icao_code": "YWDH",
      "airport_name": "Windorah Airport"
    },
    {
      "city": "Windsor",
      "country": "Canada",
      "IATA": "YQG",
      "icao_code": "CYQG",
      "airport_name": "Windsor International Airport"
    },
    {
      "city": "Windsor Locks",
      "country": "United States",
      "IATA": "BDL",
      "icao_code": "KBDL",
      "airport_name": "Bradley International Airport"
    },
    {
      "city": "Winfield",
      "country": "United States",
      "IATA": "WLD",
      "icao_code": "KWLD",
      "airport_name": "Strother Field"
    },
    {
      "city": "Wink",
      "country": "United States",
      "IATA": "INK",
      "icao_code": "KINK",
      "airport_name": "Winkler County Airport"
    },
    {
      "city": "Winnemucca",
      "country": "United States",
      "IATA": "WMC",
      "icao_code": "KWMC",
      "airport_name": "Winnemucca Municipal Airport"
    },
    {
      "city": "Winnipeg",
      "country": "Canada",
      "IATA": "YWG",
      "icao_code": "CYWG",
      "airport_name": "Winnipeg James Armstrong Richardson International Airport"
    },
    {
      "city": "Winslow",
      "country": "United States",
      "IATA": "INW",
      "icao_code": "KINW",
      "airport_name": "Winslow-Lindbergh Regional Airport"
    },
    {
      "city": "Winston-salem",
      "country": "United States",
      "IATA": "INT",
      "icao_code": "KINT",
      "airport_name": "Smith Reynolds Airport"
    },
    {
      "city": "Winter Haven",
      "country": "United States",
      "IATA": "GIF",
      "icao_code": "KGIF",
      "airport_name": "Winter Haven's Gilbert Airport"
    },
    {
      "city": "Winton",
      "country": "Australia",
      "IATA": "WIN",
      "icao_code": "YWTN",
      "airport_name": "Winton Airport"
    },
    {
      "city": "Wirawila",
      "country": "Sri Lanka",
      "IATA": "WRZ",
      "icao_code": "VCCW",
      "airport_name": "Weerawila International Airport "
    },
    {
      "city": "Wisconsin Rapids",
      "country": "United States",
      "IATA": "ISW",
      "airport_name": "South Wood County Airport"
    },
    {
      "city": "Woensdrecht",
      "country": "Netherlands",
      "IATA": "WOE",
      "icao_code": "EHWO",
      "airport_name": "Woensdrecht Air Base"
    },
    {
      "city": "Wolf Point",
      "country": "United States",
      "IATA": "OLF",
      "icao_code": "KOLF",
      "airport_name": "L. M. Clayton Airport"
    },
    {
      "city": "Wollaston Lake",
      "country": "Canada",
      "IATA": "ZWL",
      "icao_code": "CZWL",
      "airport_name": "Wollaston Lake Airport"
    },
    {
      "city": "Wollongong",
      "country": "Australia",
      "IATA": "WOL",
      "icao_code": "YWOL",
      "airport_name": "Illawarra Regional Airport"
    },
    {
      "city": "Wonju",
      "country": "South Korea",
      "IATA": "WJU",
      "icao_code": "RKNW",
      "airport_name": "Wonju Airport"
    },
    {
      "city": "Wonsan",
      "country": "North Korea",
      "IATA": "WOS",
      "airport_name": "Wonsan Airport"
    },
    {
      "city": "Wood Buffalo",
      "country": "Canada",
      "IATA": "HZP",
      "icao_code": "CYNR",
      "airport_name": "Fort Mackay / Horizon Airport"
    },
    {
      "city": "Woodbourne",
      "country": "New Zealand",
      "IATA": "BHE",
      "icao_code": "NZWB",
      "airport_name": "Woodbourne Airport"
    },
    {
      "city": "Woodward",
      "country": "United States",
      "IATA": "WWR",
      "icao_code": "KWWR",
      "airport_name": "West Woodward Airport"
    },
    {
      "city": "Woomera",
      "country": "Australia",
      "IATA": "UMR",
      "icao_code": "YPWR",
      "airport_name": "RAAF Woomera Airfield"
    },
    {
      "city": "Worcester",
      "country": "United States",
      "IATA": "ORH",
      "icao_code": "KORH",
      "airport_name": "Worcester Regional Airport"
    },
    {
      "city": "Worland",
      "country": "United States",
      "IATA": "WRL",
      "icao_code": "KWRL",
      "airport_name": "Worland Municipal Airport"
    },
    {
      "city": "Wrangell",
      "country": "United States",
      "IATA": "WRG",
      "icao_code": "PAWG",
      "airport_name": "Wrangell Airport"
    },
    {
      "city": "Wright",
      "country": "United States",
      "IATA": "LIY",
      "airport_name": "MidCoast Regional Airport at Wright Army Airfield "
    },
    {
      "city": "Wrightstown",
      "country": "United States",
      "IATA": "WRI",
      "airport_name": "Joint Base McGuire–Dix–Lakehurst"
    },
    {
      "city": "Wrigley",
      "country": "Canada",
      "IATA": "YWY",
      "icao_code": "CYWY",
      "airport_name": "Wrigley Airport"
    },
    {
      "city": "Wroclaw",
      "country": "Poland",
      "IATA": "WRO",
      "icao_code": "EPWR",
      "airport_name": "Wrocław–Copernicus Airport"
    },
    {
      "city": "Wudalianchi",
      "country": "China",
      "IATA": "DTU",
      "icao_code": "ZYDU",
      "airport_name": "Wudalianchi Airport"
    },
    {
      "city": "Wuhai",
      "country": "China",
      "IATA": "WUA",
      "icao_code": "ZBUH",
      "airport_name": "Wuhai Airport"
    },
    {
      "city": "Wuhan",
      "country": "China",
      "IATA": "WUH",
      "icao_code": "ZHHH",
      "airport_name": "Wuhan Tianhe International Airport"
    },
    {
      "city": "Wuhu",
      "country": "China",
      "IATA": "WHU",
      "icao_code": "ZSWU",
      "airport_name": "Wuhu Airport"
    },
    {
      "city": "Wulanchabu",
      "country": "China",
      "IATA": "UCB",
      "icao_code": "ZBUC",
      "airport_name": "Wulanchabu Airport"
    },
    {
      "city": "Wunnumin Lake",
      "country": "Canada",
      "IATA": "WNN",
      "icao_code": "CKL3",
      "airport_name": "Wunnummin Lake Airport"
    },
    {
      "city": "Wuxi",
      "country": "China",
      "IATA": "WUX",
      "icao_code": "ZSWX",
      "airport_name": "Sunan Shuofang International Airport "
    },
    {
      "city": "Wuyishan",
      "country": "China",
      "IATA": "WUS",
      "icao_code": "ZSWY",
      "airport_name": "Nanping Wuyishan Airport"
    },
    {
      "city": "Wuzhou",
      "country": "China",
      "IATA": "WUZ",
      "icao_code": "ZGWZ",
      "airport_name": "Wuzhou Changzhoudao Airport"
    },
    {
      "city": "Wycombe",
      "country": "United Kingdom",
      "IATA": "HYC",
      "airport_name": "RAF High Wycombe"
    },
    {
      "city": "Wyndham",
      "country": "Australia",
      "IATA": "WYN",
      "icao_code": "YWYM",
      "airport_name": "Wyndham Airport"
    },
    {
      "city": "Wyton",
      "country": "United Kingdom",
      "IATA": "QUY",
      "airport_name": "Royal Air Force Wyton"
    },
    {
      "city": "Xangongo",
      "country": "Angola",
      "IATA": "XGN",
      "icao_code": "FNXA",
      "airport_name": "Xangongo Airport"
    },
    {
      "city": "Xi'AN",
      "country": "China",
      "IATA": "SIA",
      "airport_name": "Xi'an Xiguan Airport"
    },
    {
      "city": "Xi'an",
      "country": "China",
      "IATA": "XIY",
      "icao_code": "ZLXY",
      "airport_name": "Xi'an Xianyang International Airport"
    },
    {
      "city": "Xiahe city",
      "country": "China",
      "IATA": "GXH",
      "icao_code": "ZLXH",
      "airport_name": "Xiahe City Airport"
    },
    {
      "city": "Xiamen",
      "country": "China",
      "IATA": "XMN",
      "icao_code": "ZSAM",
      "airport_name": "Xiamen Gaoqi International Airport"
    },
    {
      "city": "Xiangfan",
      "country": "China",
      "IATA": "XFN",
      "icao_code": "ZHXF",
      "airport_name": "Xiangyang Liuji Airport"
    },
    {
      "city": "Xichang",
      "country": "China",
      "IATA": "XIC",
      "icao_code": "ZUXC",
      "airport_name": "Xichang Qingshan Airport"
    },
    {
      "city": "Xilinhot",
      "country": "China",
      "IATA": "XIL",
      "icao_code": "ZBXH",
      "airport_name": "Xilinhot Airport"
    },
    {
      "city": "Xingyi",
      "country": "China",
      "IATA": "ACX",
      "airport_name": "Xingyi Wanfenglin Airport"
    },
    {
      "city": "Xining",
      "country": "China",
      "IATA": "XNN",
      "icao_code": "ZLXN",
      "airport_name": "Xining Caojiabu Airport"
    },
    {
      "city": "Xinyuan",
      "country": "China",
      "IATA": "NLT",
      "icao_code": "ZWNL",
      "airport_name": "Xinyuan Airport"
    },
    {
      "city": "Xinzhou",
      "country": "China",
      "IATA": "WUT",
      "icao_code": "ZBXZ",
      "airport_name": "Xinzhou Airport"
    },
    {
      "city": "Xuzhou",
      "country": "China",
      "IATA": "XUZ",
      "icao_code": "ZSXZ",
      "airport_name": "Xuzhou Guanyin International Airport"
    },
    {
      "city": "Yacuiba",
      "country": "Bolivia",
      "IATA": "BYC",
      "icao_code": "SLYA",
      "airport_name": "Yacuiba Airport"
    },
    {
      "city": "Yakataga",
      "country": "United States",
      "IATA": "CYT",
      "icao_code": "PACY",
      "airport_name": "Yakataga Airport"
    },
    {
      "city": "Yakima",
      "country": "United States",
      "IATA": "YKM",
      "icao_code": "KYKM",
      "airport_name": "Yakima Air Terminal "
    },
    {
      "city": "Yakushima",
      "country": "Japan",
      "IATA": "KUM",
      "icao_code": "RJFC",
      "airport_name": "Yakushima Airport"
    },
    {
      "city": "Yakutat",
      "country": "United States",
      "IATA": "YAK",
      "icao_code": "PAYA",
      "airport_name": "Yakutat Airport"
    },
    {
      "city": "Yakutia",
      "country": "Russia",
      "IATA": "PYJ",
      "icao_code": "UERP",
      "airport_name": "Polyarny Airport"
    },
    {
      "city": "Yakutsk",
      "country": "Russia",
      "IATA": "GYG",
      "airport_name": "Yakutsk Airport"
    },
    {
      "city": "Yakutsk",
      "country": "Russia",
      "IATA": "YKS",
      "icao_code": "UEEE",
      "airport_name": "Yakutsk Airport"
    },
    {
      "city": "Yalata",
      "country": "Australia",
      "IATA": "KYI",
      "icao_code": "YYTA",
      "airport_name": "Yalata Mission Airport"
    },
    {
      "city": "Yam Island",
      "country": "Australia",
      "IATA": "XMY",
      "icao_code": "YYMI",
      "airport_name": "Yam Island Airport"
    },
    {
      "city": "Yamagata",
      "country": "Japan",
      "IATA": "GAJ",
      "icao_code": "RJSC",
      "airport_name": "Yamagata Airport "
    },
    {
      "city": "Yamaguchi",
      "country": "Japan",
      "IATA": "UBJ",
      "icao_code": "RJDC",
      "airport_name": "Yamaguchi Ube Airport"
    },
    {
      "city": "Yambol",
      "country": "Bulgaria",
      "IATA": "JAM",
      "icao_code": "LBIA",
      "airport_name": "Bezmer Air Base"
    },
    {
      "city": "Yamoussoukro",
      "country": "Cote d'Ivoire",
      "IATA": "ASK",
      "icao_code": "DIYO",
      "airport_name": "Yamoussoukro Airport"
    },
    {
      "city": "Yan'an",
      "country": "China",
      "IATA": "ENY",
      "icao_code": "ZLYA",
      "airport_name": "Yan'an Ershilipu Airport "
    },
    {
      "city": "Yancheng",
      "country": "China",
      "IATA": "YNZ",
      "icao_code": "ZSYN",
      "airport_name": "Yancheng Nanyang International Airport"
    },
    {
      "city": "Yandina",
      "country": "Solomon Islands",
      "IATA": "XYA",
      "icao_code": "AGGY",
      "airport_name": "Yandina Airport"
    },
    {
      "city": "Yangon",
      "country": "Burma",
      "IATA": "RGN",
      "icao_code": "VYYY",
      "airport_name": "Yangon International Airport"
    },
    {
      "city": "Yangzhou",
      "country": "China",
      "IATA": "YTY",
      "icao_code": "ZSYA",
      "airport_name": "Yangzhou Taizhou Airport"
    },
    {
      "city": "Yanji",
      "country": "China",
      "IATA": "YNJ",
      "icao_code": "ZYYJ",
      "airport_name": "Yanji Chaoyangchuan Airport"
    },
    {
      "city": "Yankton",
      "country": "United States",
      "IATA": "YKN",
      "icao_code": "KYKN",
      "airport_name": "Chan Gurney Municipal Airport"
    },
    {
      "city": "Yantai",
      "country": "China",
      "IATA": "YNT",
      "icao_code": "ZSYT",
      "airport_name": "Yantai Penglai International Airport"
    },
    {
      "city": "Yaounde",
      "country": "Cameroon",
      "IATA": "NSI",
      "icao_code": "FKYS",
      "airport_name": "Yaoundé Nsimalen International Airport"
    },
    {
      "city": "Yaounde",
      "country": "Cameroon",
      "IATA": "YAO",
      "icao_code": "FKKY",
      "airport_name": "Yaoundé Nsimalen International Airport"
    },
    {
      "city": "Yap",
      "country": "Micronesia",
      "IATA": "YAP",
      "icao_code": "PTYA",
      "airport_name": "Yap International Airport"
    },
    {
      "city": "Yarkant",
      "country": "China",
      "IATA": "QSZ",
      "icao_code": "ZWSC",
      "airport_name": "Yarkant Airport"
    },
    {
      "city": "Yarmouth",
      "country": "Canada",
      "IATA": "YQI",
      "icao_code": "CYQI",
      "airport_name": "Yarmouth Airport"
    },
    {
      "city": "Yaroslavl",
      "country": "Russia",
      "IATA": "IAR",
      "icao_code": "UUDL",
      "airport_name": "Tunoshna Airport"
    },
    {
      "city": "Yasawa Island",
      "country": "Fiji",
      "IATA": "YAS",
      "airport_name": "Yasawa Island Airport"
    },
    {
      "city": "Yasuj",
      "country": "Iran",
      "IATA": "YES",
      "icao_code": "OISY",
      "airport_name": "Yasuj Airport"
    },
    {
      "city": "Yazd",
      "country": "Iran",
      "IATA": "AZD",
      "icao_code": "OIYY",
      "airport_name": "Shahid Sadooghi Airport"
    },
    {
      "city": "Ye",
      "country": "Burma",
      "IATA": "XYE",
      "icao_code": "VYYE",
      "airport_name": "Ye Airport"
    },
    {
      "city": "Yechon",
      "country": "South Korea",
      "IATA": "YEC",
      "icao_code": "RKTY",
      "airport_name": "Yecheon Air Base"
    },
    {
      "city": "Yekaterinburg",
      "country": "Russia",
      "IATA": "SVX",
      "icao_code": "USSS",
      "airport_name": "Koltsovo Airport"
    },
    {
      "city": "Yellowknife",
      "country": "Canada",
      "IATA": "YZF",
      "icao_code": "CYZF",
      "airport_name": "Yellowknife Airport"
    },
    {
      "city": "Yenbo",
      "country": "Saudi Arabia",
      "IATA": "YNB",
      "icao_code": "OEYN",
      "airport_name": " Prince Abdul Mohsin bin Abdulaziz Airport"
    },
    {
      "city": "Yengema",
      "country": "Sierra Leone",
      "IATA": "WYE",
      "icao_code": "GFYE",
      "airport_name": "Yengema Airport"
    },
    {
      "city": "Yenisehir",
      "country": "Turkey",
      "IATA": "YEI",
      "icao_code": "LTBR",
      "airport_name": "Yenişehir Airport"
    },
    {
      "city": "Yeniseysk",
      "country": "Russia",
      "IATA": "EIE",
      "icao_code": "UNII",
      "airport_name": "Yeniseysk Airport"
    },
    {
      "city": "Yeosu",
      "country": "South Korea",
      "IATA": "RSU",
      "icao_code": "RKJY",
      "airport_name": "Yeosu Airport"
    },
    {
      "city": "Yeovilton",
      "country": "United Kingdom",
      "IATA": "YEO",
      "icao_code": "EGDY",
      "airport_name": "Royal Naval Air Station Yeovilton"
    },
    {
      "city": "Yerbogachen",
      "country": "Russia",
      "IATA": "ERG",
      "icao_code": "UIKE",
      "airport_name": "Eromanga Airport"
    },
    {
      "city": "Yerevan",
      "country": "Armenia",
      "IATA": "EVN",
      "icao_code": "UGEE",
      "airport_name": "Zvartnots International Airport"
    },
    {
      "city": "Yibin",
      "country": "China",
      "IATA": "YBP",
      "icao_code": "ZUYB",
      "airport_name": "Yibin Caiba Airport  "
    },
    {
      "city": "Yichang",
      "country": "China",
      "IATA": "YIH",
      "icao_code": "ZHYC",
      "airport_name": "Yichang Sanxia Airport"
    },
    {
      "city": "Yichun",
      "country": "China",
      "IATA": "YIC",
      "icao_code": "ZSYC",
      "airport_name": "Yichun Mingyueshan Airport"
    },
    {
      "city": "Yinchun",
      "country": "China",
      "IATA": "LDS",
      "icao_code": "ZYLD",
      "airport_name": "Yinchun Airport"
    },
    {
      "city": "Yingkou",
      "country": "China",
      "IATA": "YKH",
      "icao_code": "ZYYK",
      "airport_name": "Yingkou Airport"
    },
    {
      "city": "Yining",
      "country": "China",
      "IATA": "YIN",
      "icao_code": "ZWYN",
      "airport_name": "Yining Airport"
    },
    {
      "city": "Yiwu",
      "country": "China",
      "IATA": "YIW",
      "icao_code": "ZSYW",
      "airport_name": "Yiwu Airport"
    },
    {
      "city": "Ylivieska-raudaskyla",
      "country": "Finland",
      "IATA": "YLI",
      "icao_code": "EFYL",
      "airport_name": "Ylivieska Airfield"
    },
    {
      "city": "Yogyakarta",
      "country": "Indonesia",
      "IATA": "JOG",
      "icao_code": "WIIJ",
      "airport_name": "Adisucipto International Airport"
    },
    {
      "city": "Yokota",
      "country": "Japan",
      "IATA": "OKO",
      "icao_code": "RJTY",
      "airport_name": "Yokota Air Base"
    },
    {
      "city": "Yola",
      "country": "Nigeria",
      "IATA": "YOL",
      "icao_code": "DNYO",
      "airport_name": "Yola Airport"
    },
    {
      "city": "Yonaguni Jima",
      "country": "Japan",
      "IATA": "OGN",
      "icao_code": "ROYN",
      "airport_name": "Yonaguni Airport"
    },
    {
      "city": "Yongzhou",
      "country": "China",
      "IATA": "LLF",
      "icao_code": "ZGLG",
      "airport_name": "Yongzhou Lingling Airport"
    },
    {
      "city": "Yopal",
      "country": "Colombia",
      "IATA": "EYP",
      "icao_code": "SKYP",
      "airport_name": "El Alcaraván Airport"
    },
    {
      "city": "York Landing",
      "country": "Canada",
      "IATA": "ZAC",
      "icao_code": "CZAC",
      "airport_name": "York Landing Airport"
    },
    {
      "city": "Yorke Island",
      "country": "Australia",
      "IATA": "OKR",
      "icao_code": "YYKI",
      "airport_name": "Yorke Island Airport"
    },
    {
      "city": "Yorkton",
      "country": "Canada",
      "IATA": "YQV",
      "icao_code": "CYQV",
      "airport_name": "Yorkton Municipal Airport"
    },
    {
      "city": "Yoron",
      "country": "Japan",
      "IATA": "RNJ",
      "icao_code": "RORY",
      "airport_name": "Yoron Airport"
    },
    {
      "city": "Yoshkar-Ola",
      "country": "Russia",
      "IATA": "JOK",
      "icao_code": "UWKJ",
      "airport_name": "Yoshkar-Ola Airport"
    },
    {
      "city": "Youngstown",
      "country": "United States",
      "IATA": "YNG",
      "icao_code": "KYNG",
      "airport_name": "Youngstown-Warren Regional Airport"
    },
    {
      "city": "Yuanmou",
      "country": "China",
      "IATA": "YUA",
      "airport_name": "Yuanmou Airport"
    },
    {
      "city": "Yuba City",
      "country": "United States",
      "IATA": "MYV",
      "icao_code": "KMYV",
      "airport_name": "Yuba County Airport"
    },
    {
      "city": "Yuendumu",
      "country": "Australia",
      "IATA": "YUE",
      "icao_code": "YYND",
      "airport_name": "Yuendumu Airport"
    },
    {
      "city": "Yulin",
      "country": "China",
      "IATA": "UYN",
      "icao_code": "ZLYL",
      "airport_name": "Yulin Yuyang Airport"
    },
    {
      "city": "Yuma",
      "country": "United States",
      "IATA": "YUM",
      "icao_code": "KNYL",
      "airport_name": "Yuma International Airport"
    },
    {
      "city": "Yuncheng",
      "country": "China",
      "IATA": "YCU",
      "icao_code": "ZBYC",
      "airport_name": "Yuncheng Guangong Airport"
    },
    {
      "city": "Yurimaguas",
      "country": "Peru",
      "IATA": "YMS",
      "icao_code": "SPMS",
      "airport_name": "Moisés Benzaquén Rengifo Airport"
    },
    {
      "city": "Yushu",
      "country": "China",
      "IATA": "YUS",
      "icao_code": "ZLYS",
      "airport_name": "Yushu Batang Airport"
    },
    {
      "city": "Yuzhno-Kurilsk",
      "country": "Russia",
      "IATA": "DEE",
      "icao_code": "UHSM",
      "airport_name": "Yuzhno-Kurilsk Mendeleyevo Airport"
    },
    {
      "city": "Yuzhno-sakhalinsk",
      "country": "Russia",
      "IATA": "UUS",
      "icao_code": "UHSS",
      "airport_name": "Yuzhno - Sakhalinsk Airport"
    },
    {
      "city": "ZAPALA",
      "country": "Argentina",
      "IATA": "APZ",
      "icao_code": "SAHZ",
      "airport_name": "Zapala Airport"
    },
    {
      "city": "Zabol",
      "country": "Iran",
      "IATA": "ACZ",
      "icao_code": "OIZB",
      "airport_name": "Zabol Airport"
    },
    {
      "city": "Zacatecas",
      "country": "Mexico",
      "IATA": "ZCL",
      "icao_code": "MMZC",
      "airport_name": "General Leobardo C. Ruiz International Airport "
    },
    {
      "city": "Zadar",
      "country": "Croatia",
      "IATA": "ZAD",
      "icao_code": "LDZD",
      "airport_name": "Zadar Airport"
    },
    {
      "city": "Zagora",
      "country": "Morocco",
      "IATA": "OZG",
      "airport_name": "Zagora Airport"
    },
    {
      "city": "Zagreb",
      "country": "Croatia",
      "IATA": "ZAG",
      "icao_code": "LDZA",
      "airport_name": "Zagreb Airport"
    },
    {
      "city": "Zahedan",
      "country": "Iran",
      "IATA": "ZAH",
      "icao_code": "OIZH",
      "airport_name": "Zahden Airport"
    },
    {
      "city": "Zakynthos",
      "country": "Greece",
      "IATA": "ZTH",
      "icao_code": "LGZA",
      "airport_name": "Zakynthos International Airport"
    },
    {
      "city": "Zamboanga",
      "country": "Philippines",
      "IATA": "ZAM",
      "icao_code": "RPMZ",
      "airport_name": "Zamboanga International Airport"
    },
    {
      "city": "Zamora",
      "country": "Mexico",
      "IATA": "ZMM",
      "airport_name": "Zamora Airport"
    },
    {
      "city": "Zandery",
      "country": "Suriname",
      "IATA": "PBM",
      "icao_code": "SMJP",
      "airport_name": "Johan Adolf Pengel International Airport"
    },
    {
      "city": "Zanesville",
      "country": "United States",
      "IATA": "ZZV",
      "icao_code": "KZZV",
      "airport_name": "Zanesville Municipal Airport"
    },
    {
      "city": "Zanjan",
      "country": "Iran",
      "IATA": "JWN",
      "icao_code": "OITZ",
      "airport_name": "Zanjan Airport"
    },
    {
      "city": "Zanzibar",
      "country": "Tanzania",
      "IATA": "ZNZ",
      "icao_code": "HTZA",
      "airport_name": "Abeid Amani Karume International Airport"
    },
    {
      "city": "Zaporozhye",
      "country": "Ukraine",
      "IATA": "OZH",
      "icao_code": "UKDE",
      "airport_name": "Zaporizhzhia International Airport"
    },
    {
      "city": "Zaqatala",
      "country": "Azerbaijan",
      "IATA": "ZTU",
      "icao_code": "UBBY",
      "airport_name": "Zaqatala International Airport"
    },
    {
      "city": "Zarafshan",
      "country": "Uzbekistan",
      "IATA": "AFS",
      "icao_code": "UTSN",
      "airport_name": "Sugraly Airport"
    },
    {
      "city": "Zaragoza",
      "country": "Spain",
      "IATA": "ZAZ",
      "icao_code": "LEZG",
      "airport_name": "Zaragoza Airport"
    },
    {
      "city": "Zaranj",
      "country": "Afghanistan",
      "IATA": "ZAJ",
      "icao_code": "OAZJ",
      "airport_name": "Zaranj Airport"
    },
    {
      "city": "Zaria",
      "country": "Nigeria",
      "IATA": "ZAR",
      "icao_code": "DNZA",
      "airport_name": "Zaria Airport"
    },
    {
      "city": "Zarzaitine",
      "country": "Algeria",
      "IATA": "IAM",
      "icao_code": "DAUZ",
      "airport_name": "In Amenas Airport"
    },
    {
      "city": "Zephyrhills",
      "country": "United States",
      "IATA": "ZPH",
      "icao_code": "KZPH",
      "airport_name": "Zephyrhills Municipal Airport"
    },
    {
      "city": "Zero",
      "country": "India",
      "IATA": "ZER",
      "icao_code": "VEZO",
      "airport_name": "Zero Airport"
    },
    {
      "city": "Zhalantun",
      "country": "China",
      "IATA": "NZL",
      "icao_code": "ZBZL",
      "airport_name": "Zhalantun Airport"
    },
    {
      "city": "Zhangjiakou",
      "country": "China",
      "IATA": "ZQZ",
      "airport_name": "Zhangjiakou Airport"
    },
    {
      "city": "Zhangye",
      "country": "China",
      "IATA": "YZY",
      "airport_name": "Zhangye Ganzhou Airport"
    },
    {
      "city": "Zhanjiang",
      "country": "China",
      "IATA": "ZHA",
      "icao_code": "ZGZJ",
      "airport_name": "Zhanjiang Airport"
    },
    {
      "city": "Zhaotong",
      "country": "China",
      "IATA": "ZAT",
      "icao_code": "ZPZT",
      "airport_name": "Zhaotong Airport"
    },
    {
      "city": "Zhengzhou",
      "country": "China",
      "IATA": "CGO",
      "icao_code": "ZHCC",
      "airport_name": "Zhengzhou Xinzheng International Airport"
    },
    {
      "city": "Zhezkazgan",
      "country": "Kazakhstan",
      "IATA": "DZN",
      "icao_code": "UAKD",
      "airport_name": "Zhezkazgan Airport"
    },
    {
      "city": "Zhigansk",
      "country": "Russia",
      "IATA": "ZIX",
      "airport_name": "Zhigansk Airport"
    },
    {
      "city": "Zhijiang",
      "country": "China",
      "IATA": "HJJ",
      "icao_code": "ZGCJ",
      "airport_name": "Huaihua Zhijiang Airport"
    },
    {
      "city": "Zhob",
      "country": "Pakistan",
      "IATA": "PZH",
      "icao_code": "OPZB",
      "airport_name": "Zhob Airport"
    },
    {
      "city": "Zhongwei",
      "country": "China",
      "IATA": "ZHY",
      "icao_code": "ZLZW",
      "airport_name": "Zhongwei Xiangshan Airport"
    },
    {
      "city": "Zhoushan",
      "country": "China",
      "IATA": "HSN",
      "icao_code": "ZSZS",
      "airport_name": "Zhoushan Putuoshan Airport"
    },
    {
      "city": "Zhuhai",
      "country": "China",
      "IATA": "ZUH",
      "icao_code": "ZGUH",
      "airport_name": "Zhuhai Jinwan Airport "
    },
    {
      "city": "Zhytomyr",
      "country": "Ukraine",
      "IATA": "ZTR",
      "icao_code": "UKKV",
      "airport_name": "Zhytomyr International Airport"
    },
    {
      "city": "Zielona Gora",
      "country": "Poland",
      "IATA": "IEG",
      "icao_code": "EPZG",
      "airport_name": "Zielona Góra Airport "
    },
    {
      "city": "Ziguinchor",
      "country": "Senegal",
      "IATA": "ZIG",
      "icao_code": "GOGG",
      "airport_name": "Ziguinchor Airport"
    },
    {
      "city": "Zihuatanejo",
      "country": "Mexico",
      "IATA": "ZIH",
      "icao_code": "MMZH",
      "airport_name": "Ixtapa-Zihuatanejo International Airport"
    },
    {
      "city": "Zinder",
      "country": "Niger",
      "IATA": "ZND",
      "icao_code": "DRZR",
      "airport_name": "Zinder Airport"
    },
    {
      "city": "Zintan",
      "country": "Libya",
      "IATA": "ZIS",
      "icao_code": "HLZN",
      "airport_name": "Zintan Airport"
    },
    {
      "city": "Zoersel",
      "country": "Belgium",
      "IATA": "OBL",
      "icao_code": "EBZR",
      "airport_name": "Oostmalle Airfield "
    },
    {
      "city": "Zonguldak",
      "country": "Turkey",
      "IATA": "ONQ",
      "icao_code": "LTAS",
      "airport_name": "Zonguldak Airport"
    },
    {
      "city": "Zouerat",
      "country": "Mauritania",
      "IATA": "OUZ",
      "icao_code": "GQPZ",
      "airport_name": "Tazadit Airport"
    },
    {
      "city": "Zunyi",
      "country": "China",
      "IATA": "WMT",
      "icao_code": "ZUMT",
      "airport_name": "Zunyi Airport"
    },
    {
      "city": "Zunyi",
      "country": "China",
      "IATA": "ZYI",
      "icao_code": "ZUZY",
      "airport_name": "Zunyi Airport"
    },
    {
      "city": "Zurich",
      "country": "Switzerland",
      "IATA": "ZRH",
      "icao_code": "LSZH",
      "airport_name": "Zürich Airport"
    },
    {
      "city": "Zweibruecken",
      "country": "Germany",
      "IATA": "ZQW",
      "icao_code": "EDRZ",
      "airport_name": "Zweibrücken Airport"
    },
    {
      "city": "Zyryanka",
      "country": "Russia",
      "IATA": "ZKP",
      "airport_name": "Kasompe Airport"
    },
    {
      "city": "Ängelholm",
      "country": "Sweden",
      "IATA": "AGH",
      "icao_code": "ESDB",
      "airport_name": "Ängelholm-Helsingborg Airport "
    },
    {
      "city": "Çorlu",
      "country": "Turkey",
      "IATA": "TEQ",
      "icao_code": "LTBU",
      "airport_name": "Tekirdağ Çorlu Airport"
    },
    {
      "city": "Île d'Yeu",
      "country": "France",
      "IATA": "IDY",
      "icao_code": "LFEY",
      "airport_name": "Île d'Yeu Airport"
    },
    {
      "city": "Île des Pins",
      "country": "New Caledonia",
      "IATA": "ILP",
      "icao_code": "NWEE",
      "airport_name": "Île des Pins Airport "
    },
    {
      "city": "Östersund",
      "country": "Sweden",
      "IATA": "OSD",
      "icao_code": "ESPC",
      "airport_name": "Åre Östersund Airport"
    },
    {
      "city": "Žilina",
      "country": "Slovakia",
      "IATA": "ILZ",
      "icao_code": "LZZI",
      "airport_name": "Žilina Airport"
    },
    {
      "city": "New York, East Hampton NYC",
      "country": "United States",
      "IATA": "HTO",
      "icao_code": "KHTO",
      "airport_name": "East Hampton Airport"
    },
    {
      "city": "New York, White Plains NYC",
      "country": "United States",
      "IATA": "HPN",
      "icao_code": "KHPN",
      "airport_name": "Westchester County Airport"
    },
    {
      "city": "New York, Farmingdale NYC",
      "country": "United States",
      "IATA": "FRG",
      "icao_code": "KFRG",
      "airport_name": "Republic Airport"
    },
    {
      "city": "New York, Morristown NYC",
      "country": "United States",
      "IATA": "MMU",
      "icao_code": "KMMU",
      "airport_name": "Morristown Municipal Airport"
    },
    {
      "city": "New York, Islip NYC",
      "country": "United States",
      "IATA": "ISP",
      "icao_code": "KISP",
      "airport_name": "Long Island MacArthur Airport"
    },
    {
      "city": "New York, Westhampton NYC",
      "country": "United States",
      "IATA": "FOK",
      "icao_code": "KFOK",
      "airport_name": "Francis S. Gabreski Airport"
    }
  ]
