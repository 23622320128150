import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import "../../pages/common.css"
const BarkAirRouteDescription = () => {
    return (
        <><Card style={{ marginTop: "2rem", padding: 0, overflowY: "scroll" }}>
            <CardBody>
                <Row>
                    <Col lg="12" md="12" sm="12" className="mb-2">
                        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Katana Partners with Bark Air to Offer Pet-Friendly Flights</h2>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12" md="12" sm="12">
                        Katana, known for its sleek and comfortable travel options, has teamed up with <a href={process.env.REACT_APP_DOMAIN + "/BARKAir"} style={{ color: "black" }}><strong>Bark Air</strong></a>, the airline designed with pets in mind, to bring an even more pet-friendly flying experience to travelers. This exciting partnership allows Katana’s clients to seamlessly book flights with Bark Air, providing a new level of comfort and convenience for those who want to travel with their furry friends.
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" className="mb-2">
                        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>A Perfect Match for Pet Owners
                        </h2>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12" md="12" sm="12">
                        The collaboration between Katana and Bark Air aims to enhance the travel experience by offering a dedicated, pet-centric service that goes beyond traditional pet travel options. With Bark Air’s in-cabin pet accommodations and Katana’s reputation for luxury and efficiency, the partnership guarantees that pets and their owners can travel together comfortably and with ease.
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" className="mb-2">
                        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Traveling Together, Stress-Free
                        </h2>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12" md="12" sm="12">
                        Flying with pets on <a href={process.env.REACT_APP_DOMAIN + "/BARKAir"} style={{ color: "black" }}><strong>Bark Air</strong></a> is designed to be as comfortable and stress-free as possible, catering specifically to pet owners who want to travel with their furry companions. Here's how it works:
                        <div className='BarkAirRouteDescription-olsection'>
                            <ol>
                                <li>
                                    <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Pet-Centric Travel Experience</h3>
                                    <p>
                                        Bark Air offers a completely pet-friendly flying experience. Pets
                                        travel in the cabin with you, so they stay close throughout the
                                        journey. The airline prioritizes your pet’s safety, comfort, and
                                        well-being from check-in to landing.
                                    </p>
                                </li>
                                <li>
                                    <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Booking a Flight</h3>
                                    <p>
                                        When booking, you select the option to fly with your pet. Bark Air
                                        makes it easy to reserve a spot for your pet, ensuring there is
                                        enough room in the cabin. The airline accommodates pets of all
                                        sizes, but they typically recommend smaller breeds or those who fit
                                        in a carrier for smoother travel.
                                    </p>
                                </li>
                                <li>
                                    <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Pre-Flight Requirements</h3>
                                    <p>
                                        Bark Air ensures all pets are healthy and ready for the flight.
                                        You’ll need to provide certain documents, like proof of vaccinations
                                        or health certifications, depending on the destination. The airline
                                        also recommends acclimating your pet to their travel carrier before
                                        the flight to reduce stress.
                                    </p>
                                </li>
                                <li>
                                    <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>In-Flight Comfort</h3>
                                    <p>
                                        Pets can travel in the cabin with you, either on your lap or in a
                                        personal carrier placed under the seat in front of you. Each pet’s
                                        comfort is taken into account, and the airline provides necessary
                                        amenities to make the journey as pleasant as possible. You can bring
                                        along your pet’s favorite blanket or toy for reassurance.
                                    </p>
                                </li>
                                <li>
                                    <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Specialized Crew</h3>
                                    <p>
                                        Bark Air's crew members are trained to handle pets on board,
                                        ensuring that both you and your pet feel comfortable throughout the
                                        flight. They are accustomed to managing pet-related needs and are
                                        available to assist with anything your pet might require.
                                    </p>
                                </li>
                                <li>
                                    <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Arriving with Ease</h3>
                                    <p>
                                        Once you land, Bark Air ensures a smooth disembarkation process.
                                        Your pet will be returned to you promptly, allowing for a quick exit
                                        from the airport. Bark Air is ideal for pet parents who want their animals to experience
                                        the same level of care and comfort while traveling as they do. From
                                        booking to landing, the entire process is tailored to make flying with
                                        pets as enjoyable as possible.
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        </>
    )
}

export default BarkAirRouteDescription