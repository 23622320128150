import { useEffect, useState, useRef } from "react";
import { Grid, Card, Select, MenuItem, useMediaQuery } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { useLocalStorage } from "../utils/useLocalStorage";
import PathArea from "../components/PathArea";
import Search from "../components/HomePage/Search";
import Book from "../components/HomePage/Book";
import FlightList from "../components/FlightList";
import "./HomePage.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import CardComponent from "../components/HomeCardsNew";
import BannerBottom from "../assets/images/backgroundPlane.png";
import { HiChevronDown } from 'react-icons/hi';
import { getFormatedDate } from "../utils/FormatedDate";
import FilterFlightDate from "../components/HomePage/filterFlightDate";
import { Button } from "antd";
import BarkAirRouteDescription from "../components/BarkAirContent/BarkAirRouteDescription";
function BarkAir() {
    const [user] = useLocalStorage("auth", {});
    const [pathType, setPathType] = useState("search");
    const [unregistedFlightsData, setFlightData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterValue, setFilterValue] = useState({ value: 0, date: "All", timePrice: "Departure_Time" })
    const [dateString, setDateString] = useState(getFormatedDate(new Date()))
    const [sidebarTop, setSidebarTop] = useState(undefined);
    const [searchClick, setSearchClick] = useState(true);
    var [headerHeight, setHeaderHeight] = useState(125);
    const scrollToRef = useRef(null);
    const cardRef = useRef(null);
    const isDesktop = useMediaQuery('(min-width:992px)'); // Bootstrap's lg breakpoint
    const isLgScreen = useMediaQuery('(min-width:768px)'); // Bootstrap's lg breakpoint

    useEffect(() => {
        const card = cardRef.current;
        if (card && card.scrollHeight > card.clientHeight) {
            card.style.height = "auto";
        }
    }, []); // E

    // Use effect to check if isSearchClick is set to true
    useEffect(() => {
        if (searchClick && scrollToRef.current) {
            // Get the top position of the scrollToRef element
            const topPosition =
                scrollToRef.current.getBoundingClientRect().top + window.pageYOffset;
            // Adjust for the header height
            const adjustedPosition = topPosition - headerHeight;
            // Scroll to the adjusted position
            window.scrollTo({
                top: adjustedPosition,
                behavior: "smooth",
            });
        }
    }, [searchClick]);

    useEffect(() => {
        if (searchClick) {
            const chatEl = document.querySelector(".sticky-component");
            if (chatEl) {
                const rect = chatEl.getBoundingClientRect();
                setSidebarTop(rect.top + window.pageYOffset);
            }
        }
    }, [searchClick]);

    useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [sidebarTop]);

    const isSticky = (e) => {
        const chatEl = document.querySelector(".sticky-component");
        const scrollTop = window.scrollY;
        const header = document.getElementById("top-header");

        if (header) {
            setHeaderHeight(header.offsetHeight);
            headerHeight = header.offsetHeight;
        }

        if (chatEl) {
            if (scrollTop >= sidebarTop - headerHeight) {
                chatEl.style.position = 'sticky';
                chatEl.style.top = `${headerHeight}px`;
                chatEl.style.width = '100%';
                chatEl.style.zIndex = '100';
                chatEl.style.backgroundColor = 'white';
            } else {
                chatEl.style.position = 'static';
                chatEl.style.top = 'auto';
            }
        }
    };

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        const h1 = document.createElement("h1");
        h1.innerText = "Book Shared Private Flights";
        h1.style.display = "none";
        body.insertBefore(h1, body.firstChild);
    }, []);

    useEffect(() => {
        setFilteredData(unregistedFlightsData);
    }, [unregistedFlightsData]);

    const handleFilterValueChange = () => {

        let newFilteredData = [...unregistedFlightsData];
        // conditon for filter when tab change seat =1 , Chater=2 ,interest=3
        switch (filterValue?.value) {
            case 1:
                newFilteredData = newFilteredData.filter(
                    (x) => x?.is_posted === true && x?.is_charter === false
                );
                break;
            case 2:
                newFilteredData = newFilteredData.filter((x) => x?.is_charter === true);
                break;
            case 3:
                newFilteredData = newFilteredData.filter((x) => x?.is_posted === false);
                break;
            default:
                // Case 0 or undefined fallback
                break;
        }
        // filter function for Departure_Time ,Price_Highest, Price lowest

        switch (filterValue?.timePrice) {
            case "Departure_Time":
                newFilteredData.sort((a, b) => {
                    // // Push items with total_price === 0 to the end
                    // if (a?.total_price === 0 && b?.total_price !== 0) return 1;
                    // if (b?.total_price === 0 && a?.total_price !== 0) return -1;

                    // sort by departure date and time
                    const dateA = new Date(`${a?.dep_date} ${a?.dep_time || "00:00"}`);
                    const dateB = new Date(`${b?.dep_date} ${b?.dep_time || "00:00"}`);
                    return dateA - dateB; // Sort by earliest departure time
                });
                break;
            case "Price_Highest":
                newFilteredData.sort((a, b) => (b?.total_price) - (a?.total_price))
                break;
            case "Price_Lowest":
                newFilteredData.sort((a, b) => (a?.total_price) - (b?.total_price));
                break;
            default:
                return 0;
        }
        // filter change for date
        if (filterValue?.date && filterValue?.date !== "All") {
            // get date 1/16/2025 to 2025-01-15
            const filterDateValue = new Date(filterValue?.date).toISOString().split("T")[0];
            newFilteredData = newFilteredData.filter((x) => {
                const depDate = new Date(x?.dep_date).toISOString().split("T")[0];
                return filterDateValue === depDate;
            });
        }
        newFilteredData.sort((a, b) => (a?.total_price === 0 ? 1 : b?.total_price === 0 ? -1 : 0))
        setFilteredData(newFilteredData);
    };


    useEffect(() => {
        handleFilterValueChange()
    }, [filterValue])

    return (
        <>
            <Helmet
                htmlAttributes={{ lang: "en" }}
                defaultTitle="Shared Private Jet Flights with Pets | Katana & BARK Air"
            >
                <meta
                    name="description"
                    content="Travel comfortably with shared private jet flights that welcome pets. Enjoy a stress-free journey with your furry companion. Book your pet-friendly flight today!"
                />
                <link rel="canonical" href="https://www.flykatana.com/"></link>
                <title>Shared Private Jet Flights with Pets | Katana & BARK Air</title>
            </Helmet>
            {pathType === "book" && (
                <>
                    <Row style={{ marginTop: "-2px" }}>
                        <Col
                            lg={{ size: 12 }}
                            md={{ size: 12 }}
                            sm="12"
                            className="text-center"
                        >
                            <Grid className="imgBackground">
                                <img className="BannerBottom-image" src={BannerBottom} alt="image" />
                                <div className="fullWidthHomeSearchSell m-3">
                                    <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                                        <Col
                                            lg={{ size: "7", }}
                                            md={{ size: "7", }}
                                            sm="12"
                                        >
                                            <Card style={{ backgroundColor: "#ffffffde" }} className="">
                                                {typeof user.userid !== "undefined" && (
                                                    <Row className="">
                                                        <Col lg="12" md="12" sm="12">
                                                            <PathArea
                                                                pathType={pathType}
                                                                setPathType={setPathType}
                                                                user={user}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Book pageName="HomePage" IsBarkAir={true} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Grid>
                        </Col>
                    </Row>
                </>
            )}
            {pathType === "search" && (
                <>
                    <Grid style={{ marginTop: "-2px" }} className="imgBackground" ref={cardRef}>
                        <img className="BannerBottom-image" src={BannerBottom} alt="image" />
                        <div className="fullWidthHomeSearch HomePage-Bannersection m-3 mb-6">
                            <h1
                                className=""
                                style={{ marginTop: "0.5rem", color: "white" }}
                            >
                                Fly Private With Pets, Book Empty Legs and Charters
                            </h1>
                            <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                                <Col
                                    lg={{ size: "8", }}
                                    md={{ size: "10" }}
                                    sm="12"
                                >
                                    <Card
                                        className="pb-3 overflow-visible"
                                        style={{ opacity: "1 !important", backgroundColor: "#ffffffde" }}
                                    >
                                        {/* {typeof user.userid !== "undefined" && ( */}
                                        <Row className="mt-2">
                                            <Col lg="12" md="12" sm="12">
                                                <PathArea
                                                    pathType={pathType}
                                                    user={user}
                                                    setPathType={setPathType}
                                                />
                                            </Col>
                                        </Row>
                                        {/* )} */}
                                        <Search
                                            unregistedFlightsData={unregistedFlightsData}
                                            setFlightData={setFlightData}
                                            setSearchClick={setSearchClick}
                                            IsBarkAir={true}
                                            setDateString={setDateString}
                                            setFilterValue={setFilterValue}
                                            IsFilterAirportDataByAll={true}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Grid>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-3"></Col>
                        {searchClick ? (
                            <Col lg="12" md="12" sm="12" className="mb-3" style={{ paddingLeft: isLgScreen ? "5.5rem" : "", paddingRight: isLgScreen ? "5.5rem" : "" }}>
                                <div className="sticky-component" ref={scrollToRef}>
                                    <Box sx={{ bgcolor: "background.paper", display: "flex", justifyContent: "space-between", flexDirection: isLgScreen ? "row" : "column-reverse" }}>
                                        <Tabs
                                            value={filterValue?.value}
                                            onChange={(event, newValue) => {
                                                setFilterValue((prev) => ({
                                                    ...prev,
                                                    value: newValue,
                                                }));
                                            }}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            textColor="#FFE600"
                                            aria-label="scrollable auto tabs example"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: "#FFE600",
                                                    height: 3,
                                                    textColor: "#FFE600",
                                                },
                                            }}
                                        >
                                            {["All", "Seats", "Charters", "Interests"].map((label, index) => (
                                                <Tab
                                                    key={index}
                                                    label={label}
                                                    sx={{
                                                        px: { xs: 0.6, md: 4.2 },
                                                        "&.Mui-selected": {
                                                            bgcolor: "#1976d214",
                                                            color: "#1976d2"
                                                        },
                                                    }}
                                                />
                                            ))}
                                        </Tabs>
                                        <div className="Filter-by-div">
                                            <div>Sort by
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    className="ml-2"
                                                    value={filterValue.timePrice}
                                                    onChange={(e) => {
                                                        setFilterValue((prev) => ({
                                                            ...prev, timePrice: e?.target?.value
                                                        }))
                                                    }}
                                                    label="Departure_Time"
                                                    IconComponent={HiChevronDown}
                                                >
                                                    <MenuItem value="Departure_Time">Departure Time</MenuItem>
                                                    <MenuItem value="Price_Highest">Price (Highest First)</MenuItem>
                                                    <MenuItem value="Price_Lowest">Price (Lowest First)</MenuItem>
                                                </Select></div>
                                            <div>
                                                <Button className="border-dark" onClick={() => {
                                                    setFilterValue({ value: 0, date: "All", timePrice: "Departure_Time" })
                                                }}>Clear FILTERS</Button>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                                <div className="date-filter-div">
                                    <FilterFlightDate dateString={dateString} setFilterValue={setFilterValue} filterValue={filterValue} />
                                    <div><Button className="border-dark" onClick={() => {
                                        setFilterValue({ value: 0, date: "All", timePrice: "Departure_Time" })
                                    }}>Clear FILTERS</Button>
                                    </div>
                                </div>
                                <FlightList
                                    flightData={filteredData}
                                    //setFlightData={setFilteredData}
                                    page="Home"
                                />
                                <BarkAirRouteDescription />
                            </Col>
                        ) : (
                            <>
                                <CardComponent />
                            </>
                        )}
                    </Row>
                </>
            )}
        </>
    );
}

export default BarkAir;
