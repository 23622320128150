import './common.css'
import { useState, useEffect } from "react";
import * as React from 'react';
import { authAndUserData } from '../data/AuthAndUserData';
import { Card, CardBody, Row, Col } from "reactstrap";
import Noimage from '../assets/press/Noimage.jpg'
import Ttopbanner from '../assets/press/top_banner.png'
import { BsArrowRightShort } from "react-icons/bs";
function Press() {
  const { GetPress } = authAndUserData();
  const [press, SetPress] = useState([]);

  async function GetPressList() {
    const response = await GetPress();
    SetPress(response);
  }

  useEffect(() => {
    GetPressList();
  }, []);
  const handelErrorImage = (event) => {
    event.target.src = Noimage;
  };
  const NewsCard = ({ title, image, link, date }) => (
    <div className="news-card-press">
      <a href={link} target="_blank">
        <div className='text-left'>
          <img src={image} alt={title} onError={handelErrorImage} />
        </div>
        <p className="date" style={{ marginBottom: "0px" }}>{date}</p>
        <h2>{title}</h2>
        <BsArrowRightShort className='news-card-icon' />
      </a>
    </div>
  );


  const NewsList = ({ news }) => (
    <div className="news-list pres-page-newsdiv">
      {news.map((item, index) => (
        <NewsCard
          key={index}
          title={item.headline}
          image={item.cover_image}
          link={item.link}
          date={item.date}
        />
      ))}
    </div>
  );

  return (
    <>
      <div className='press-the-news-section'>
        <div>
          <p>PRESS</p>

          <p>News worth sharing.....</p>
        </div>
        <img src={Ttopbanner} alt="" />
      </div>
      <div class="newsdiv">
        <NewsList
          news={press} />
      </div></>
  );
  //   return (
  //     <div class="row">
  //     {
  //       press.length > 0 && press.map((press) => (
  //         <div class="col-sm-6">
  //         <Card sx={{ maxWidth: 250 }}>
  //           <CardMedia
  //             sx={{ height: 140 }}
  //             image={press.cover_image}
  //             title={press.headline}
  //           />
  //           <CardContent>
  //             <Typography gutterBottom variant="h5" component="div">
  //               <a href={press.link} target="_blank">{press.headline}</a>
  //             </Typography>
  //           </CardContent>
  //           {/* <CardActions>
  //             <Button size="small">Share</Button>
  //             <Button size="small">Learn More</Button>
  //           </CardActions> */}
  //         </Card>
  //         </div>
  //       ))
  //     }
  //     </div>
  //   );
}
export default Press;
