import { useEffect, useState, useRef } from "react";
import { Grid, Card, MenuItem, Select, useMediaQuery } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { useLocalStorage } from "../utils/useLocalStorage";
import PathArea from "../components/PathArea";
import Search from "../components/HomePage/Search";
import Book from "../components/HomePage/Book";
import FlightList from "../components/FlightList";
import "./HomePage.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { myElementRef } from "../layouts/Header";
import { Helmet } from "react-helmet";
import HomePageCards from "../components/HomeCardsOld";
import CardComponentEmpty from "../components/HomeCardsNewEmpty";
import { HiChevronDown } from "react-icons/hi";
import BannerBottom from "../assets/images/backgroundPlane.png";
import { getFormatedDate } from "../utils/FormatedDate";
import FilterFlightDate from "../components/HomePage/filterFlightDate";
import { Button } from "antd";
function EmptyLegs() {
  const [user] = useLocalStorage("auth", {});
  const [pathType, setPathType] = useState("search");
  const [unregistedFlightsData, setFlightData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState({ value: 0, date: "All", timePrice: "Departure_Time" })
  const [dateString, setDateString] = useState(getFormatedDate(new Date()))
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const [searchClick, setSearchClick] = useState(false);
  const [ismobile, setIsmobile] = useState(false);
  var [headerHeight, setHeaderHeight] = useState(125);
  const scrollToRef = useRef(null);
  const cardRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width:992px)');
  const isLgScreen = useMediaQuery('(min-width:768px)'); // Bootstrap's lg breakpoint
  useEffect(() => {
    if (typeof window != "undefined") {
      setIsmobile(window?.innerWidth <= 768)
    }
    const card = cardRef.current;
    if (card && card.scrollHeight > card.clientHeight) {
      card.style.height = "auto";
    }
  }, []); // E

  // Use effect to check if isSearchClick is set to true
  useEffect(() => {
    if (searchClick && scrollToRef.current) {
      // Get the top position of the scrollToRef element
      const topPosition =
        scrollToRef.current.getBoundingClientRect().top + window.pageYOffset;
      // Adjust for the header height
      const adjustedPosition = topPosition - headerHeight;
      // Scroll to the adjusted position
      window.scrollTo({
        top: adjustedPosition,
        behavior: "smooth",
      });
    }
  }, [searchClick]);

  useEffect(() => {
    if (searchClick) {
      const chatEl = document
        .querySelector(".sticky-component")
        .getBoundingClientRect();
      setSidebarTop(chatEl.top);
    }
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);
  const isSticky = (e) => {
    const chatEl = document.querySelector(".sticky-component");
    const scrollTop = window.scrollY;
    setHeaderHeight(document.getElementById("top-header").offsetHeight);
    //      console.log("HeadHeight: "+document.getElementById('top-header').offsetHeight);
    headerHeight = document.getElementById("top-header").offsetHeight;
    // console.log("scrollTop: "+scrollTop);
    // console.log("sidebarTop: "+sidebarTop);
    // console.log("Header height: "+headerHeight);

    if (scrollTop >= sidebarTop - headerHeight) {
      document.documentElement.style.setProperty(
        "--sticky-top",
        headerHeight + "px"
      );
      chatEl.classList.add("is-sticky");
    } else {
      chatEl.classList.remove("is-sticky");
    }
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    const h1 = document.createElement("h1");
    h1.innerText = "Book Shared Private Flights";
    h1.style.display = "none";
    body.insertBefore(h1, body.firstChild);
  }, []);

  useEffect(() => {
    setFilteredData(unregistedFlightsData);
  }, [unregistedFlightsData]);
  useEffect(() => {
    handleFilterValueChange()
  }, [filterValue])

  const handleFilterValueChange = () => {

    let newFilteredData = [...unregistedFlightsData];
    // conditon for filter when tab change seat =1 , Chater=2 ,interest=3
    switch (filterValue?.value) {
      case 1:
        newFilteredData = newFilteredData.filter(
          (x) => x?.is_posted === true && x?.is_charter === false
        );
        break;
      case 2:
        newFilteredData = newFilteredData.filter((x) => x?.is_charter === true);
        break;
      case 3:
        newFilteredData = newFilteredData.filter((x) => x?.is_posted === false);
        break;
      default:
        // Case 0 or undefined fallback
        break;
    }
    switch (filterValue?.timePrice) {
      case "Departure_Time":
        newFilteredData.sort((a, b) => {
          // // Push items with total_price === 0 to the end
          // if (a?.total_price === 0 && b?.total_price !== 0) return 1;
          // if (b?.total_price === 0 && a?.total_price !== 0) return -1;

          // sort by departure date and time
          const dateA = new Date(`${a?.dep_date} ${a?.dep_time || "00:00"}`);
          const dateB = new Date(`${b?.dep_date} ${b?.dep_time || "00:00"}`);
          return dateA - dateB; // Sort by earliest departure time
        });
        break;
      case "Price_Highest":
        newFilteredData.sort((a, b) => (b?.total_price) - (a?.total_price))
        break;
      case "Price_Lowest":
        newFilteredData.sort((a, b) => (a?.total_price) - (b?.total_price));
        break;
      default:
        return 0;
    }
    // filter change for date
    if (filterValue?.date && filterValue?.date !== "All") {
      // get date 1/16/2025 to 2025-01-15
      const filterDateValue = new Date(filterValue?.date).toISOString().split("T")[0];
      newFilteredData = newFilteredData.filter((x) => {
        const depDate = new Date(x?.dep_date).toISOString().split("T")[0];
        return filterDateValue === depDate;
      });
    }
    newFilteredData.sort((a, b) => (a?.total_price === 0 ? 1 : b?.total_price === 0 ? -1 : 0))
    setFilteredData(newFilteredData);
  };
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        defaultTitle="Empty Leg Flights | Share a Plane"
      >
        <meta
          name="description"
          content="With empty leg flights, you can save up to 75% on the cost of a standard private jet charter. Fly Katana provides Empty Leg plane Flights services for a more efficient and cost-effective travel experience. Book your flight now!"
        />
        <link rel="canonical" href="https://www.flykatana.com/"></link>
        <title>Empty Leg Flights | Share a Plane</title>
      </Helmet>
      {pathType === "book" && (
        <>
          <Row className={ismobile ? `mb-5` : ""}>
            <Col
              lg={{ size: 12 }}
              md={{ size: 12 }}
              sm="12"
              className="text-center"
            >
              <Grid className="imgBackground">
                <img className="BannerBottom-image" src={BannerBottom} alt="image" />
                <div className="fullWidthHomeSearchSell m-3">
                  <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                    <Col
                      lg={{ size: "7" }}
                      md={{ size: "7" }}
                      sm="12"
                    >
                      <Card style={{ backgroundColor: "#ffffffde" }} className="">
                        {typeof user.userid !== "undefined" && (
                          <Row className="">
                            <Col lg="12" md="12" sm="12">
                              <PathArea
                                pathType={pathType}
                                setPathType={setPathType}
                                user={user}
                              />
                            </Col>
                          </Row>
                        )}
                        <Book pageName="HomePage" />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Grid>
            </Col>
          </Row>
        </>
      )}
      {pathType === "search" && (
        <>
          <Grid className="imgBackground" ref={cardRef}>
            <img className="BannerBottom-image" src={BannerBottom} alt="image" />

            <div className="fullWidthHomeSearch HomePage-Bannersection m-3 mb-6">
              <h1
                className="text-center"
                style={{ marginTop: "0.5rem", color: "white" }}
              >
                FIND YOUR EMPTY LEG SEGMENT NOW
              </h1>
              <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                <Col
                  lg={{ size: "8", }}
                  md={{ size: "10", }}
                  sm="12"
                >
                  <Card
                    className="pb-3 overflow-visible"
                    style={{ opacity: "1 !important", backgroundColor: "#ffffffde" }}
                  >
                    {/* {typeof user.userid !== "undefined" && ( */}
                    <Row className="mt-2">
                      <Col lg="12" md="12" sm="12">
                        <PathArea
                          pathType={pathType}
                          user={user}
                          setPathType={setPathType}
                        />
                      </Col>
                    </Row>
                    {/* )} */}
                    <Search
                      unregistedFlightsData={unregistedFlightsData}
                      setFlightData={setFlightData}
                      setSearchClick={setSearchClick}
                      setDateString={setDateString}
                      setFilterValue={setFilterValue}
                      IsFilterAirportDataByAll={true}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </Grid>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-3"></Col>
            {searchClick ? (
              <Col lg="12" md="12" sm="12" className="mb-3" style={{ paddingLeft: ismobile ? "" : "5.5rem", paddingRight: ismobile ? "" : "5.5rem" }}>
                <div className="sticky-component" ref={scrollToRef}>
                  <Box sx={{ bgcolor: "background.paper", display: "flex", justifyContent: "space-between", flexDirection: isLgScreen ? "row" : "column-reverse" }}>
                    <Tabs
                      value={filterValue?.value}
                      onChange={(event, newValue) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          value: newValue,
                        }));
                      }}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="#FFE600"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#FFE600",
                          height: 3,
                          textColor: "#FFE600",
                        },
                      }}
                    >
                      {["All", "Seats", "Charters", "Interests"].map((label, index) => (
                        <Tab
                          key={index}
                          label={label}
                          sx={{
                            px: { xs: 0.6, md: 4.2 },
                            "&.Mui-selected": {
                              bgcolor: "#1976d214",
                              color: "#1976d2"
                            },
                          }}
                        />
                      ))}
                    </Tabs>
                    <div className="Filter-by-div">
                      <div>
                        Sort by
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          className="ml-2"
                          value={filterValue.timePrice}
                          onChange={(e) => {
                            setFilterValue((prev) => ({
                              ...prev, timePrice: e?.target?.value
                            }))
                          }}
                          label="Departure_Time"
                          IconComponent={HiChevronDown}
                        >
                          <MenuItem value="Departure_Time">Departure Time</MenuItem>
                          <MenuItem value="Price_Highest">Price (Highest First)</MenuItem>
                          <MenuItem value="Price_Lowest">Price (Lowest First)</MenuItem>
                        </Select>
                      </div>
                      <div>
                        <Button className="border-dark" onClick={() => {
                          setFilterValue({ value: 0, date: "All", timePrice: "Departure_Time" })
                        }}>Clear FILTERS</Button>
                      </div>
                    </div>
                  </Box>
                </div>
                <div className="date-filter-div">
                  <FilterFlightDate dateString={dateString} setFilterValue={setFilterValue} filterValue={filterValue} />
                  <div><Button className="border-dark" onClick={() => {
                    setFilterValue({ value: 0, date: "All", timePrice: "Departure_Time" })
                  }}>Clear FILTERS</Button>
                  </div>
                </div>
                <FlightList
                  flightData={filteredData}
                  //setFlightData={setFilteredData}
                  page="Home"
                />
              </Col>
            ) : (
              <>
                <CardComponentEmpty />
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
}

export default EmptyLegs;
